import Mousetrap from "mousetrap";
import {useEffect, useState} from "react";
import {ModalPayvyBase} from "../../../modals";

const Key = ({children}) => {
  return <span className={'px-1 mx-1 bg-slate-300 inline rounded text-slate-500 font-bold'}>{children}</span>
}
export const ModalKeyboardShortcuts = () => {
  const [keyboardShortcutModalOpen, setKeyboardShortcutModalOpen] = useState(false);
  useEffect(() => {
    Mousetrap.bind("?", (e) => {
      e.preventDefault();
      setKeyboardShortcutModalOpen(true)
    });
  }, []);
  return <ModalPayvyBase
    noButtons={true}
    title={'Keyboard shortcuts'}
    isOpen={keyboardShortcutModalOpen}
    dismissButtonAction={() => setKeyboardShortcutModalOpen(false)}
    position={'top-1/5 left-1/3'}
    size={'sm:max-w-3xl'}
  >
    <div className={'text-xl font-bold pb-2'}>Navigation shortcuts</div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>D</Key></div>
        <div className={''}>Go to Dashboard</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>I</Key></div>
        <div className={''}>Go to Inbox</div>
      </div>
    </div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>R</Key></div>
        <div className={''}>Go to Receivables</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>P</Key></div>
        <div className={''}>Go to Payables</div>
      </div>
    </div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>C</Key></div>
        <div className={''}>Go to Contacts</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>T</Key></div>
        <div className={''}>Go to Transactions</div>
      </div>
    </div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>G</Key>then<Key>A</Key></div>
        <div className={''}>Go to PayvyGPT (AI)</div>
      </div>
    </div>
    <div className={'text-xl font-bold pb-2 pt-6'}>Action shortcuts</div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>N</Key>then<Key>I</Key></div>
        <div className={''}>New Invoice</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>N</Key>then<Key>B</Key></div>
        <div className={''}>New Bill</div>
      </div>
    </div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>N</Key>then<Key>C</Key></div>
        <div className={''}>New Customer</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>N</Key>then<Key>V</Key></div>
        <div className={''}>New Vendor</div>
      </div>
    </div>
    <div className={'text-xl font-bold pb-2 pt-6'}>Universal shortcuts</div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>CTRL+S</Key></div>
        <div className={''}>Save/Submit</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>Escape</Key></div>
        <div className={''}>Close/Cancel</div>
      </div>
    </div>
    <div className={'grid grid-cols-2 gap-4'}>
      <div className={'grid grid-cols-2 gap-1 border-r border-slate-200 pr-4'}>
        <div className={'font-light'}><Key>CTRL+Enter</Key></div>
        <div className={''}>Save/Submit</div>
      </div>
      <div className={'grid grid-cols-2 gap-1 pl-4'}>
        <div className={'font-light'}><Key>/</Key></div>
        <div className={''}>Focus search</div>
      </div>
    </div>
  </ModalPayvyBase>
};



