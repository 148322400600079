import React from 'react';
import OCRInformation from "./OCRInformation";
import PageContent from './PageContent';
import PageInformation from './PageInformation';
import Sidebar from './Sidebar';

const Page = ({
  inboxItem,
  inboxPermissions,
  setInboxItemPageId,
  currentPage,
  setCurrentPage,
  showDeletedPages = true,
  OCRMode,
}) => {
  let pages = inboxItem?.pages || [];
  if(!showDeletedPages) pages = pages.filter(page => !page.deleted);
  const [displayPageInformation, setDisplayPageInformation] = React.useState(false);
  React.useEffect(() => {
    setDisplayPageInformation(false);
    setTimeout(() => {
      setDisplayPageInformation(true);
    }, 10);
  }, [currentPage, inboxItem]);
  return <div className={'w-full flex-col md:flex-row flex h-full'}>
    <Sidebar
      inboxItem={inboxItem}
      pages={pages}
      inboxPermissions={inboxPermissions}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setInboxItemPageId={setInboxItemPageId}
      showDeletedPages={showDeletedPages}
    />
    <PageContent
      pages={pages}
      currentPage={currentPage}
    />
    {inboxPermissions.canAddBill && OCRMode &&
      <OCRInformation
        inboxItem={inboxItem}
        currentPage={currentPage}
        pages={pages}
      />}
    {!OCRMode && inboxPermissions.canAddBill && displayPageInformation ? <PageInformation
      inboxItem={inboxItem}
      pages={pages}
      currentPage={currentPage}
    /> : null}
  </div>;
};

export default Page;
