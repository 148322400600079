import {hyphensToCamelCase} from "../../../utils/Utility";

export function getActionName(action) {
  if(!action) return '';
  try {
    action = action.toString()
                   .toLowerCase();
  } catch(e) {
  }
  try {
    action = action.replace(/ /g, '-');
  } catch(e) {
  }
  return hyphensToCamelCase(action);
}
