import {createSlice} from '@reduxjs/toolkit';
import {PAYVY_API} from '../constants';
import Debugger from '../utils/Debug';
import {refreshAccessToken} from "./api";

export const initialState = {
  firstTime: true,
  loading: false,
  hasErrors: false,
  processing: false,
  classifications: [],
  exportFolder: '',
  lastSync: null,
};

const dwollaSlice = createSlice({
  name: 'dwolla',
  initialState,
  reducers: {
    getClassifications: (state) => {
      state.loading = true;
    },
    getClassificationSuccess: (state, {payload}) => {
      state.firstTime = false;
      state.loading = false;
      const industryClassifications = [];
      payload.forEach(classification => {
        const businessClassificationName = classification['name'];
        classification['industries'].forEach(industry => {
          industryClassifications.push({
            key: industry['dwolla_id'],
            text: `${businessClassificationName}: ${industry['name']}`,
            label: `${businessClassificationName}: ${industry['name']}`,
            value: industry['dwolla_id'],
          });
        });
      });
      state.classifications = industryClassifications;
    },
    getClassificationFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  getClassifications,
  getClassificationSuccess,
  getClassificationFailure,
  processingStart,
  processingFinish,
} = dwollaSlice.actions;
export const dwollaSelector = (state) => state.dwolla;
export default dwollaSlice.reducer;


export function getDwollaClassifications() {
  return async(dispatch) => {
    dispatch(getClassifications());
    try {
      const response = await fetch(`${PAYVY_API.V1.DWOLLA.CLASSIFICATION_LIST}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getClassificationSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getDwollaClassifications());
          }
        }
        dispatch(getClassificationFailure());
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(getClassificationFailure());
    }
  };
}
