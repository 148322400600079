import React from 'react';
import {FaAngleRight} from 'react-icons/fa';
import {NumericFormat} from 'react-number-format';
import {useSelector} from "react-redux";
import {OCRInformation} from "../../../comps/elements/OCRInformation/OCRInformation";

const TitleBar = ({bill}) => {
  const {
    loading: {item: loadingInboxItem},
  } = useSelector((state) => state.inbox);
  const {
    id,
    contact = {},
    status,
    name,
    amount,
    ocr_processed: ocrProcessed,
    ocr_progress: ocrProgress,
  } = bill;
  const {name: contactName} = contact;
  return <div className={'flex flex-col md:flex-row py-4 px-3 border-b border-neutral-500'}>
    <div className={'flex grow'}>
      <div className={'flex flex-row text-xl items-center'}>
        {contact && <>
          <div className={'flex text-neutral-100'}>{contactName}</div>
          <div className={'flex text-neutral-100 mt-1.5'}><FaAngleRight/></div>
        </>}
        {id && <>
          <div className={'flex text-neutral-100'}>{status}</div>
          <div className={'flex text-neutral-100 mt-1.5'}><FaAngleRight/></div>
          <div className={'flex font-bold'}>{name}</div>
        </>}
      </div>
    </div>
    <div className={'flex flex-row-reverse md:flex-row justify-end'}>
      <div className={'flex px-2'}>
        <OCRInformation ocrProcessed={ocrProcessed} ocrProgress={ocrProgress}/>
      </div>
      <div className={'flex md:ml-5 w-48'}>
        <div className={'w-full flex flex-row bg-red-100 border border-red-900 rounded shadow-lg px-4 py-2'}>
          <div className={'flex grow'}>Total</div>
          <div className={'flex'}>
            {loadingInboxItem ? '...' :
              <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} value={amount} prefix={'$'}
                             thousandSeparator={true} className={'value'}/>}
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default TitleBar;
