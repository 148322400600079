import {Field, useFormikContext} from 'formik';
import {noop} from 'lodash';
import React from 'react';

export const PayvyLabeledInput = ({
  label,
  name,
  as,
  component,
  disabled = false,
  loading = false,
  type = 'text',
  noStyling = undefined,
  className = 'my-0.5 px-1 py-2',
  appendRight = undefined,
  options = [],
  alwaysLabel = false,
  children,
  maxLength = 0,
  onChange = noop,
  LabelAs = 'label',
  placeholder = undefined,
  containerClassName = 'w-full',
  controlClassName = 'rounded-md border border-neutral-500',
  helpText = undefined,
  selectOnlyOption = true,
  readOnly = false,
}) => {
  const {
    values,
    errors,
    touched,
  } = useFormikContext();
  const value = values[name];
  const error = errors[name];
  const touchedError = touched[name];
  if(touchedError && error) className += ' border-2 border-red-900'; else className += ' border border-neutral-500';
  const customAsProps = !!as ? {
    loading,
    noStyling,
    containerClassName,
    controlClassName,
    helpText
  } : {};
  // check if error is object and has value key, if it has store the value key, if it's not an object display all
  const errorMessage = error?.value || error;
  return <div className={'py-1 w-full'}>
    <LabelAs>
        <span
          className={`flex text-left text-xs pl-1 pt-1 items-center ${touchedError && error ? 'text-red-900 font-bold' : ''}`}>
            {value || alwaysLabel ? label : '\u00A0'}
        </span>
      <div className={'flex flex-row'}>
        {!!children ? children : <Field
          as={as}
          component={component}
          name={name}
          type={type}
          disabled={disabled || loading}
          placeholder={placeholder || label}
          className={`${className} ${appendRight ? `${className} rounded-l-md` : `${className} rounded-md`}`}
          onChange={onChange}
          options={options}
          readOnly={readOnly}
          maxLength={maxLength}
          selectOnlyOption={selectOnlyOption}
          {...customAsProps}
        />}
        {appendRight ? <div className={`border-l-0 rounded-r-md ${className} px-10`}>{appendRight}</div> : null}
      </div>
      {touchedError && error && (
        <span className={'text-red-900 text-xs block text-left pl-1'}>
          {errorMessage.split('\n')
                       .map((line, index) => (
                         <React.Fragment key={index}>
                           {line}
                           <br/>
                         </React.Fragment>
                       ))}
        </span>
      )}
    </LabelAs>
  </div>;
};
