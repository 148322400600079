import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {PayvyPageHeader} from "../../comps/elements";
import {PAYVY_URL} from "../../constants";
import ContactCreateVendorForm from '../ContactCreateVendor/ContactCreateVendorForm';
import ContactCreateCustomerForm from './components/ContactCreateCustomerForm';

const ContactCreateCustomer = () => {
  const navigate = useNavigate();
  const {
    id: contactId,
    type,
  } = useParams();
  if(!type || !['customer', 'vendor'].includes(type.toLowerCase())) {
    navigate(PAYVY_URL.CONTACTS.LIST);
  }
  const updateMode = !!contactId;
  const isVendor = type === 'vendor';

  return <>
    <div className="container max-w-full border-b-2 border-gray-200">
      <PayvyPageHeader parents={[
        {
          name: 'Contacts',
          href: PAYVY_URL.CONTACTS.LIST
        }
      ]}>{updateMode ? 'Update' : 'Create New'} {isVendor ? 'Vendor' : 'Customer'}</PayvyPageHeader>
      <div className="container max-w-full bg-neutral-50 m-0 p-0 pb-10">
        {isVendor ? <ContactCreateVendorForm/> : <ContactCreateCustomerForm/>}
      </div>
    </div>
  </>;

};

export default ContactCreateCustomer;
