import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PayvyIconButton} from "../../comps/forms";
import {PAYVY_LOGOS, PAYVY_URL} from "../../constants";
import {refreshUserToken} from "../../slices/auth";
import Debugger from "../../utils/Debug";

const InactivityPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refreshToken, setRefreshToken] = useState(null);

  const handleFlushBrowserData = () => {
    setRefreshToken(localStorage.getItem('refreshToken'))
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    if(window.indexedDB) {
      const dbs = window.indexedDB.databases ? window.indexedDB.databases() : [];
      dbs.then(databases => databases.forEach(db => window.indexedDB.deleteDatabase(db.name)))
         .catch(error => Debugger.error('Error flushing local browser database:', error));
    }
  };

  const handleLogin = () => {
    dispatch(refreshUserToken({
      refreshToken,
      success: () => {
        navigate(PAYVY_URL.DASHBOARD)
      },
      failure: () => {
        navigate(PAYVY_URL.LOGIN)
      }
    }))
  };

  React.useEffect(() => {
    handleFlushBrowserData();
  }, []);

  return <div className="flex flex-col items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
    </div>
    <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
      <h1 className={'flex text-xl mb-2 font-bold justify-center'}>
        Your session has ended. You are now logged out.
      </h1>

      <p className="text-center text-sm text-neutral-600">
        Please click Quick Login to continue your session, or simply close the browser to fully log out.
      </p>
      <div className={'flex justify-center mt-4'}>
        <PayvyIconButton
          buttonText="Quick Login"
          onClick={handleLogin}
          shortcutKey={['ctrl+enter', 'ctrl+s']}
          type={'submit'}
        />
      </div>
    </div>
  </div>;
};

export default InactivityPage;
