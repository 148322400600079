import React from 'react';

export const BlockPayvyError = ({errors}) => {
  const hasErrors = errors && errors.length > 0;
  return hasErrors ? <div className={'mt-4 p-4 border border-red-950 bg-neutral-0 text-neutral-900 rounded-xl'}>
    <h2 className={'font-bold text-3xl'}>There were errors with your payment</h2>
    <ul>
      {errors.map((error, index) => <li key={index} className={'text-sm'}>{error.message}</li>)}
    </ul>
  </div> : null;
};
