import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {getBankAccounts, kycSelector} from '../../../slices/kyc';
import {CellStatus} from "./CellStatus";
import {CellSyncedActions} from "./CellSyncedActions";

export const SyncedBankAccounts = ({companyId}) => {
  const dispatch = useDispatch();
  const {
    loading,
    bankAccounts,
    processing,
    count,
  } = useSelector(kycSelector);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => {
    _setRefreshInfo(new Date());
  };
  useEffect(() => {
    dispatch(getBankAccounts({
      page: currentPage + 1,
      page_size: pageSize,
    }));
  }, [dispatch, currentPage, pageSize, refreshInfo, companyId]);
  const columns = React.useMemo(() => [
    {
      Header: 'Account Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Account Number',
      accessor: 'account_number',
      disableSortBy: true,
    },
    {
      Header: 'Account Type',
      accessor: 'type',
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'active',
      Cell: CellStatus,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      Cell: CellSyncedActions,
      RefreshInfo: setRefreshInfo,
      disableSortBy: true,
    },
  ], []);
  const fetchData = useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setCurrentPage(pageIndex);
    setPageSize(pageSize);
    setPageCount(Math.ceil(count / pageSize));
  }, [count]);
  return <>
    <div className={'text-sm text-payvyBlue-950 font-bold mt-4 -mb-9'}>
      <span>Synced Bank Accounts</span>
    </div>
    <PayvyTable
      columns={columns}
      data={bankAccounts}
      fetchData={fetchData}
      loading={loading || processing}
      count={count}
      pageCount={pageCount}
      defaultPageSize={pageSize}
      pageSize={pageSize}
      sizeOptions={[5, 20, 50]}
    />
  </>;
};
