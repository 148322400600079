import {addDays} from 'date-fns';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyPageHeader, PayvyTable} from '../../comps/elements';
import {CellCurrency} from "../../comps/elements/PayvyTable";
import {PayvyDateRangePicker, PayvySearchBar} from '../../comps/forms';
import {companiesSelector, hasPermission} from '../../slices/companies';
import {getTransactions} from '../../slices/newTransaction';
import {userSelector} from '../../slices/user';
import {CellActions} from "./components/CellActions";
import {CellDetailLink} from "./components/CellDetailLink";
import {ModalTransactionDownload} from './components/ModalTransactionDownload';

const TransactionListPage = () => {
  const dispatch = useDispatch();
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};
  const transactionCanDeletePermission = hasPermission(company, userId, 'transaction_can_delete');
  const {
    loading: {list: loading},
    numberOfItems,
    displayItems,
  } = useSelector((state) => state.transactions);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [customSorting, setCustomSorting] = React.useState('');
  const [q, setQueryTerm] = useState('');
  const [queryRange, setQueryRange] = useState([addDays(new Date(), -365), new Date()]);
  const [pageCount, setPageCount] = React.useState(0);
  const [refreshQuery, setRefreshQuery] = React.useState(0);
  // region Effects
  useEffect(() => {
    let fromDate = '';
    let toDate = '';
    if(queryRange?.length === 2) {
      fromDate = moment(queryRange[0])
      .startOf('day')
      .toISOString();
      toDate = moment(queryRange[1])
      .startOf('day')
      .toISOString();
    }

    dispatch(getTransactions({
      page: currentPage + 1,
      page_size: pageSize,
      from: fromDate,
      to: toDate,
      q: q,
      order_by: customSorting,
    }));
  }, [
    dispatch,
    currentPage,
    queryRange,
    q,
    customSorting,
    refreshQuery,
    pageSize,
    companyId,
  ]);
  // endregion

  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: CellDetailLink,
      sortDescFirst: true,
    }, {
      Header: 'Name',
      accessor: 'contact.name',
    }, {
      Header: 'Account',
      accessor: 'bank_account.name',
    }, {
      Header: 'Amount',
      accessor: 'paid_amount',
      Cell: CellCurrency,
      sortDescFirst: true,
    }, {
      Header: 'Status',
      accessor: 'status',
    }, {
      Header: 'Actions',
      Cell: CellActions,
      refreshList: setRefreshQuery,
      canDelete: transactionCanDeletePermission,
    },
  ], [transactionCanDeletePermission]);

  // region Callbacks
  const fetchData = React.useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setCurrentPage(pageIndex);
    setPageSize(pageSize);
    setPageCount(Math.ceil(numberOfItems / pageSize));
  }, [numberOfItems]);
  const handleSort = React.useCallback(sortBy => {
    setCustomSorting(sortBy.length > 0 ? sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id : '');
  }, []);
  // endregion
  return <>
    <PayvyPageHeader>Transactions</PayvyPageHeader>
    <div className={'bg-neutral-50 p-4 mb-2 w-full'}>
      <div className={'flex flex-row gap-x-2'}>
        <div className={'inline-flex grow'}>
          <PayvySearchBar
            loading={loading}
            onSearch={setQueryTerm}
            placeholder={'Search table by contact name, bill name or bank account'}
            autoComplete={'bill-search'}
          />
        </div>
        <div className={'flex flex-row'}>
          <ModalTransactionDownload type={'pdf'}/>
          <ModalTransactionDownload type={'csv'}/>
        </div>
      </div>
      <div className={'flex flex-row mt-2'}>
        <div className={'w-32'}>
          <PayvyDateRangePicker
            onChange={setQueryRange}
            value={queryRange}
          />
        </div>
      </div>
      <PayvyTable
        columns={columns}
        data={displayItems}
        fetchData={fetchData}
        loading={loading}
        onSort={handleSort}
        count={numberOfItems}
        pageCount={pageCount}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        currentPage={currentPage}
        sizeOptions={[20, 50, 100]}
      />
    </div>
  </>
};

export default TransactionListPage;
