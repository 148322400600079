import React from 'react';

export const PayvySubmenuLoadingIndicator = ({loading}) => {
  const [dots, setDots] = React.useState(0);
  React.useEffect(() => {
    if(loading) {
      const interval = setInterval(() => {
        setDots((dots) => (dots + 1) % 4);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);
  if(!loading) return;
  return <div className={'flex grow text-slate-400 text-xs self-center justify-end'}>
    <div className={"w-16"}>
      Loading{Array(dots)
    .fill('.')
    .join('')}
    </div>
  </div>;
}

export const PayvySubmenu = ({
  options,
  activeItem,
  setActiveItem,
  loading,
  extraCustomChildren,
}) => {
  return <div className={'flex flex-row w-full p-2'}>
    {options.map((option) => {
      if(option.disabled) return null;
      return <div
        key={option.value}
        className={`flex px-3 py-1 text-md whitespace-nowrap cursor-pointer -mb-0.5 ${activeItem === option.value ? 'text-slate-900 bg-slate-100 font-bold rounded-md' : 'hover:text-slate-600 font-light'}`}
        onClick={() => setActiveItem(option.value)}>
        {option.name}
      </div>;
    })}
    <PayvySubmenuLoadingIndicator loading={loading}/>
    {extraCustomChildren && <div className={'flex grow self-center justify-end'}>
      {extraCustomChildren}
    </div>}
  </div>;
};
