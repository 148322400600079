import React from "react";
import CurrencyFormat from "react-currency-format";
import CreatableSelect from "react-select/creatable";
import {
  PayvyDatePicker,
  PayvyInput,
  PayvyLabeledCheckbox,
  PayvyLabeledInput,
  PayvySelectComponent
} from "../../../comps/forms";
import {displayAmount} from "../../../utils/BillHelpers";
import RefreshContactFromAPIButton from "./RefreshContactFromAPIButton";

export const BasePaymentForm = ({
  recipientAccounts = [],
  formProps,
  extraPaymentFields,
  processing,
  loading,
  bankAccounts,
  amount,
  emailOptions,
  handleCreate,
  checkboxFieldsSet,
  addAndSelectVendor,
  addAndSelectUser,
}) => {
  return <div>
    <div className={'flex flex-col md:flex-row gap-2'}>
      <div className={'flex-inline w-full'}>
        <PayvyLabeledInput
          label={'Account'}
          component={PayvySelectComponent}
          selectOnlyOption={true}
          placeholder={'account'}
          name={'account'}
          options={bankAccounts}
        />
      </div>
      <div className={'flex-inline'}>
        <RefreshContactFromAPIButton currentPaymentType={formProps.values.activePaymentType}/>
      </div>
    </div>
    {recipientAccounts.length > 0 &&
      <div className={'flex flex-col md:flex-row gap-2'}>
        <div className={'flex-inline w-full'}>
          <PayvyLabeledInput
            label={'Recipient'}
            component={PayvySelectComponent}
            placeholder={'Recipient account'}
            name={'recipientAccount'}
            options={recipientAccounts}
          />
        </div>
      </div>
    }
    {extraPaymentFields}
    <div className={'flex flex-col md:flex-row gap-2'}>
      <div className={'flex-inline w-full md:w-1/3'}>
        <PayvyLabeledInput
          label={'Payment date'}
          name={'paymentDate'}
        >
          <PayvyDatePicker
            name={'paymentDate'}
            value={formProps.values.paymentDate}
            onChange={(value) => formProps.setFieldValue('paymentDate', value)}
          />
        </PayvyLabeledInput>
      </div>
      <div className={'flex-inline w-full md:w-1/3'}>
        <PayvyLabeledInput
          label={'Bill amount'}
          name={'billAmount'}
          alwaysLabel={true}
        >
          <CurrencyFormat
            thousandSeparator={true}
            prefix={'$'}
            disabled={true}
            value={displayAmount(amount)}
            className={'my-0.5 px-1 py-2.5 rounded-md w-full border border-neutral-500 bg-neutral-50 cursor-not-allowed text-neutral-500'}
          />
        </PayvyLabeledInput>
      </div>
      <div className={'flex-inline w-full md:w-1/3'}>
        <PayvyLabeledInput
          label={'Payment amount'}
          name={'paymentAmount'}
        >
          <CurrencyFormat
            thousandSeparator={true}
            prefix={'$'}
            placeholder={'Amount to pay'}
            value={formProps.values.paymentAmount}
            onValueChange={({value}) => formProps.setFieldValue('paymentAmount', value)}
            className={'my-0.5 px-1 py-2.5 rounded-md w-full border border-neutral-500'}
          />
        </PayvyLabeledInput>
      </div>
    </div>
    <div className={'flex flex-col md:flex-row gap-2'}>
      <div className={'flex-inline w-full'}>
        <PayvyLabeledInput
          label={'Internal description'}
          name={'internalDescription'}
          as={PayvyInput}
          maxLength={10}
        />
      </div>
    </div>
    <div className={'flex flex-col md:flex-row gap-2'}>
      <div className={'flex-inline w-full'}>
        <PayvyLabeledInput
          label={'Email confirmation to'}
          alwaysLabel={true}
        >
          <CreatableSelect
            isMulti
            className={'w-full'}
            placeholder="Email Addresses to notify"
            aria-label={'Email confirmation to'}
            isDisabled={processing || loading}
            isLoading={loading}
            value={formProps.values.emailConfirmationsTo}
            onCreateOption={(value) => handleCreate(formProps, value)}
            onChange={(options) => {
              formProps.setFieldValue('emailConfirmationsTo', options);
              checkboxFieldsSet(formProps, options);
            }}
            options={emailOptions}
          />
        </PayvyLabeledInput>
      </div>
    </div>
    <div className={'flex flex-col md:flex-row gap-2'}>
      <div className={'flex-inline w-2/5'}>
        <PayvyLabeledCheckbox
          name={'emailToVendor'}
          required={false}
          onChange={() => addAndSelectVendor(formProps, !formProps.values.emailToVendor)}
        >
          <span className={'text-xs'}>
             Email to vendor
          </span>
        </PayvyLabeledCheckbox>
        <PayvyLabeledCheckbox
          name={'emailToUser'}
          required={false}
          onChange={() => addAndSelectUser(formProps, !formProps.values.emailToUser)}
        >
          <span className={'text-xs'}>
             Email to me
          </span>
        </PayvyLabeledCheckbox>
      </div>
    </div>
  </div>
}
