import React from 'react';
import {Link} from "react-router-dom";
import {PAYVY_LOGOS, PAYVY_URL} from '../../constants';
import {FormLogin} from './components/FormLogin';
import {LoginLockOut} from './components/LoginLockOut';


const LoginPage = ({urlToNavigateAfterLogin}) => {
  const lockoutEnds = localStorage.getItem('lockoutEnds') || '1970-01-01';
  const isInvitedCompanyUserURL = new RegExp(`^${PAYVY_URL.INVITED_COMPANY_USER.replace('/:hash/', '/[^/]+/')}$`).test(urlToNavigateAfterLogin);
  localStorage.removeItem('lastInteractionTime');
  const [activeLockout, setActiveLockout] = React.useState(new Date(lockoutEnds) > new Date());
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <h1 className={'flex text-xl mb-2 font-bold justify-center'}>Login to your account</h1>
        {!isInvitedCompanyUserURL && urlToNavigateAfterLogin && urlToNavigateAfterLogin !== PAYVY_URL.LOGIN && urlToNavigateAfterLogin !== PAYVY_URL.DASHBOARD &&
          <p>After logging in you will be redirected to &quot;<span
            className={'text-red-300 font-bold'}>{urlToNavigateAfterLogin}</span>&quot;.</p>}
        {isInvitedCompanyUserURL && <p>
          You will be redirected to the company you have been invited to after logging in.<br/>
          If you don't have an account, please <Link
          to={PAYVY_URL.REGISTER}
          className={'text-red-300 hover:text-red-400 pl-1'}
        >
          Sign Up
        </Link> first.
        </p>}
        {activeLockout ? <LoginLockOut finishCallback={(bool) => setActiveLockout(bool)}/> : <>
          <FormLogin setActiveLockout={setActiveLockout}/>
          <div className={'text-neutral-700 flex flex-row justify-between mt-10'}>
            <div className={'flex'}>
              New to us?
              <Link
                to={PAYVY_URL.REGISTER}
                className={'text-red-300 hover:text-red-400 pl-1'}
              >
                Sign Up
              </Link>
            </div>
            <div className={'flex'}>
              <div className={'inline-flex whitespace-nowrap items-center'}>
                Forgot your password?
              </div>
              <Link
                to={PAYVY_URL.FORGOTTEN_PASSWORD}
                className={'text-red-300 hover:text-red-400 pl-1'}
              >
                Reset here
              </Link>
            </div>
          </div>
        </>
        }
      </div>
    </div>
  </div>;
};

export default LoginPage;
