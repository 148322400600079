import {noop} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {
  getUserBills,
  setBillCurrentPage,
  setBillFromDateStr,
  setBillOrderBy,
  setBillPageSize,
  setBillSearchQuery,
  setBillStatus,
  setBillToDateStr,
  setFilterAmountStr,
  setFilterContact
} from '../../../slices/newBill';

export const SearchBill = ({
  onChange,
  value,
  setValue,
  contactId = undefined,
}) => {
  setValue = setValue || noop;
  onChange = onChange || noop;
  const dispatch = useDispatch();
  const {
    displayItems,
    loading: {
      posting: processing,
      list: loading
    },
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const [valueObject, setValueObject] = React.useState(null);
  const [billList, setBillList] = useState([]);
  const [lastContactRefreshID, setLastContactRefreshID] = useState(0);
  useEffect(() => {
    if(lastContactRefreshID !== contactId) {
      setLastContactRefreshID(contactId);
      dispatch(setBillCurrentPage(1))
      dispatch(setBillPageSize(10))
      dispatch(setBillStatus('draft,awaiting payment,awaiting approval'))
      dispatch(setBillOrderBy('-created'))
      dispatch(setFilterAmountStr(''))
      dispatch(setFilterContact(contactId))
      dispatch(setBillSearchQuery(''))
      dispatch(setBillFromDateStr(''))
      dispatch(setBillToDateStr(''))
    }
  }, [dispatch, contactId, lastContactRefreshID]);
  useEffect(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    status,
    searchQuery
  ]);

  useEffect(() => {
    const newBillList = [];
    displayItems.map((billItem) => newBillList.push({
      key: billItem.id,
      text: billItem.name,
      label: billItem.name,
      value: billItem.id,
    }));
    setBillList(newBillList);
  }, [displayItems, setBillList]);

  useEffect(() => {
    if(!!value && value.length > 0) setValueObject(billList.find(option => option.value === value[0]) || null);
  }, [value, billList]);

  const handleChange = (value) => {
    setValue(value);
    onChange(value);
    setValueObject(value);
  };

  return <CreatableSelect
    className={'payvy-select'}
    placeholder="Search or add bill"
    isDisabled={processing || loading}
    isLoading={loading}
    value={valueObject}
    onChange={(option) => handleChange(option)}
    options={billList}
  />;
};
