import {capitalize} from 'lodash';
import React from 'react';
import {PAYVY_STATIC_IMAGES} from '../../../../constants';

const FullPageAccountHasNotEnoughPermissions = ({companyAccess = []}) => {
  return <div className={'flex flex-col h-dvh -mt-32 grow items-center justify-center'}>
    <img src={PAYVY_STATIC_IMAGES.STOP} alt={'Access is denied'} className={'h-64'}/>
    <h1 className={'font-bold text-2xl'}>Access is denied!</h1>
    <div className={'text-center'}>
      <p>You are missing required permissions to access this page:</p>
      <ol className={'list-none'}>
        {companyAccess.map((item, index) => {
          let [feature, access, ...rest] = item.split('.'); // eslint-disable-line no-unused-vars
          let formattedAccess = access;
          if(access === 'see') formattedAccess = 'reading';
          else if(access === 'add') formattedAccess = 'creating';
          else if(access === 'update') formattedAccess = 'editing';
          else if(access === 'delete') formattedAccess = 'removing';
          return <li key={index}>- {capitalize(formattedAccess)} access for <b>{capitalize(feature)}</b> objects.</li>;
        })}
      </ol>
    </div>
  </div>;
};

export default FullPageAccountHasNotEnoughPermissions;
