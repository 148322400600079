import React from 'react';
import {PayvyIconButton} from "../../../comps/forms";

export const SettingsBankingAddManualAccountModal = ({setPaymentTab}) => {
  const loading = false;
  const processing = false;
  return <div
    className={`service-details flex flex-col border border-neutral-300 rounded-lg p-2 my-2`}
  >
    <div className={'flex flex-row items-center'}>
      <div className={'font-bold text-lg'}>
        Add Manually
      </div>
      <PayvyIconButton
        buttonText={'Add'}
        onClick={() => setPaymentTab('mba')}
        wrapperClassName={'ml-auto'}
        disabled={loading || processing}
      />
    </div>
    <div className={'text-sm font-light'}>
      Enter your bank account details manually. You can add an account for tracking purposes, or qualify it for payments
      with additional verification.
    </div>
  </div>;
};
