import {Formik} from 'formik';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput, PayvyTextArea} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import {companiesSelector} from '../../../slices/companies';
import {build_error_message} from '../../../utils/Utility';

export const ModalVendorInvite = ({
  parentForm,
}) => {
  const {company} = useSelector(companiesSelector);
  const [modalOpen, setModalOpen] = useState(false);
  const submitForm = React.useRef(null);
  const [processing, setProcessing] = useState(false);
  return <>
    <PayvyIconButton
      buttonText={'Invite Company'}
      onClick={() => setModalOpen(true)}
    />
    <ModalPayvyBase
      title={`Invite vendor by email`}
      isOpen={modalOpen}
      confirmButtonText={'Send'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          subject: `${company.name} has set you up for payments, add a payment method`,
          body: `${company.name} has invited to share your payment details on Payvy to manage their invoices and make faster payments to you`,
        }}
        validationSchema={Yup.object({
          subject: Yup.string()
                      .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255}))
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          body: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          setProcessing(true);
          parentForm.setFieldValue('inviteBody', values.body);
          parentForm.setFieldValue('inviteSubject', values.subject);
          parentForm.setFieldValue('addPaymentMethod', 'invite');
          parentForm.handleSubmit();
          setProcessing(false);
          setModalOpen(false);
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'Subject'}
            name={'subject'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Body'}
            name={'body'}
            as={PayvyTextArea}
          />
          <button ref={submitForm} type="submit" className={'hidden'} onClick={props.handleSubmit}/>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
