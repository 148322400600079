import {openDB} from "idb";
import Debugger from "../utils/Debug";

export const DB_VERSION = 0.1
export const DB_NAME = `payvy-${DB_VERSION}`;
export const CACHE_EXPIRATION_TIME = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
export const DB_INBOX_STORAGE = 'inbox'
export const DB_BILL_STORAGE = 'bill'
export const DB_CONTACT_STORAGE = 'contact'
export const DB_TRANSACTION_STORAGE = 'transaction'

export const DB_INTEGRATION_STORAGE = 'integration'
export const DB_BOX_STORAGE = 'box'
export const DB_DROPBOX_STORAGE = 'dropbox'
export const DB_QUICKBOOKS_STORAGE = 'quickbooks'
export const DB_PAYVYGPT_STORAGE = 'payvygpt'

const storages = [
  DB_INBOX_STORAGE,
  DB_BILL_STORAGE,
  DB_BOX_STORAGE,
  DB_DROPBOX_STORAGE,
  DB_QUICKBOOKS_STORAGE,
  DB_INTEGRATION_STORAGE,
  DB_CONTACT_STORAGE,
  DB_TRANSACTION_STORAGE
]

const mockDB = {
  async getFromIndex() {
    return null;  // Mock the cache miss
  },
  async transaction() {
    return {
      objectStore() {
        return {
          put() { /* no-op */ },
          delete() { /* no-op */ },
          openCursor() { return null; /* Simulate end of cursor */ }
        };
      },
      done: Promise.resolve()
    };
  }
};

let dbPromise;

try {
  dbPromise = openDB(DB_NAME, 1, {
    upgrade(db) {
      storages.forEach((storeName) => {
        const store = db.createObjectStore(storeName, {keyPath: 'id'});
        store.createIndex('params', 'params');
      });
    },
  });
} catch(error) {
  Debugger.error('Failed to open IndexedDB, using mock DB instead.');
  dbPromise = Promise.resolve(mockDB);  // Fallback to mock DB
}

export async function handleCache(
  storeName, cacheKey, params, forceUpdate, cacheExpirationTime = CACHE_EXPIRATION_TIME) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Checking cache.');
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Params:', params);
  const db = await dbPromise;
  let cachedEntry;
  try {
    cachedEntry = await db.transaction(storeName, 'readonly')
                          .objectStore(storeName)
                          .get(cacheKey);
  } catch(error) {
    Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Error retrieving from cache.');
    return null;
  }

  const isCacheExpired =
    !cachedEntry ||
    (Date.now() - cachedEntry.timestamp > cacheExpirationTime);

  if(cachedEntry && !isCacheExpired && !forceUpdate) {
    Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Getting data from cache.');
    return cachedEntry.data;
  } else {
    Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Cache expired or force update. Fetching data from API.');
    return null;
  }
}

export async function updateCache(storeName, cacheKey, params, data) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Updating cache.');
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put({
    id: cacheKey,
    params: JSON.stringify(params),
    data,
    timestamp: Date.now()
  });
  await tx.done;
}

export async function resetListCacheForStore(storeName) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Resetting item cache.');
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  if(tx && typeof tx.objectStore === 'function') {
    const store = tx.objectStore(storeName);
    let cursor = await store.openCursor();
    while(cursor) {
      if(/^[a-zA-Z0-9]+-list-/.test(cursor.key)) {
        await cursor.delete();
      }
      cursor = await cursor.continue();
    }
  }
}

export async function resetCacheForStore(storeName) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Resetting item cache.');
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  if(tx && typeof tx.objectStore === 'function') {
    const store = tx.objectStore(storeName);
    await store.clear();
  }
  await tx.done;
}

export async function updateItemCache(storeName, cacheKey, id, data) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Updating item cache.');
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.put({
    id: cacheKey,
    data,
    timestamp: Date.now()
  });

  await tx.done;
}

export async function deleteFromCache(storeName, cacheKey) {
  Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, 'Deleting from cache.');
  const db = await dbPromise;
  const tx = db.transaction(storeName, 'readwrite');
  const store = tx.objectStore(storeName);
  await store.delete(cacheKey);

  await tx.done;
}
