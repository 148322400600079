import {noop} from "lodash";
import React from "react";
import {useSelector} from "react-redux";
import Select from "react-select";
import {companiesSelector} from "../../../slices/companies";
import {ModalResendInvitation} from "./ModalResendInvitation";

export const CompanyPendingMembers = ({
  company,
  refreshInfo
}) => {
  refreshInfo = refreshInfo || noop;
  const {permissionList} = useSelector(companiesSelector)
  const permissionOptions = permissionList.map((item, index) => ({
    key: index,
    value: item.name,
    label: item.name,
  }))
  return company?.pending?.map((pendingMember, index) =>
    <tr key={index} className={'bg-neutral-50 hover:bg-neutral-100 hover-border hover:border-neutral-300'}>
      <td className={'py-1 px-4 w-16'}></td>
      <td className={'py-1 px-4'}><span className={'font-bold'}>PENDING</span></td>
      <td className={'py-1 px-4'}>{pendingMember.email}</td>
      <td className={'py-1 px-4 w-64'}>
        <Select
          value={permissionOptions.find(item => item.value === pendingMember.permission.name)}
          options={permissionOptions}
          className={'member-role-select'}
          isDisabled={true}
        />
      </td>
      <td className={'py-1 px-4 w-32'}>
        <ModalResendInvitation email={pendingMember.email} refreshInfo={refreshInfo}/>
      </td>
    </tr>)
}
