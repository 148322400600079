import React from 'react';
import {PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {CompanyList} from "./components/CompanyList";

const SettingsTeamPage = () => {
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Team</PayvyPageHeader>
    <SettingsMenu active="team"/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2'}>
      <CompanyList/>
    </div>
  </>;
};

export default SettingsTeamPage;
