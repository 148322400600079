import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {PayvyIconButton} from "../../../comps/forms";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getPaymentServiceInformation, saveServiceConfiguration} from "../../../slices/paymentSystem";
import {AirwallexPaymentServiceForm} from "./AirwallexPaymentServiceForm";
import {DefaultPaymentServiceForm} from "./DefaultPaymentServiceForm";
import {GuidanceReplacer} from "./GuidanceReplacer";
import {RevolutPaymentServiceForm} from "./RevolutPaymentServiceForm";

const getPaymentServiceForm = (name) => {
  switch(name) {
    case 'revolut':
      return RevolutPaymentServiceForm;
    case 'airwallex':
      return AirwallexPaymentServiceForm;
    default:
      return DefaultPaymentServiceForm;
  }
}

export const LinkedService = ({
  service,
  loading
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    name,
    internal_name: internalName,
    active_configuration: activeConfiguration,
    description,
    linking_description: linkingDescription,
    logo,
    id,
  } = service;
  const {
    enabled = false,
  } = activeConfiguration || {};
  const [isOpen, setIsOpen] = useState(enabled);
  return <div
    className={`service-details flex flex-col border border-neutral-300 rounded-lg p-2 my-2 ${!isOpen && 'cursor-pointer hover:bg-neutral-100'}`}
    onClick={() => {
      if(!isOpen) setIsOpen(true)
    }}
  >
    <div className={'flex flex-row items-center'}>
      {logo && <div className={'w-10 h-10'}>
        <img
          src={logo}
          alt={name}
          className={'w-8 h-8'}
        />
      </div>}
      <div className={'font-bold text-lg'}>
        {name}
      </div>
      {isOpen && enabled && <PayvyIconButton
        buttonText={'Disable'}
        loading={loading}
        disabled={loading}
        onClick={() => {
          dispatch(saveServiceConfiguration({
            serviceId: id,
            configuration: {
              enabled: false,
            },
            success: () => {
              dispatch(getPaymentServiceInformation({}))
              setIsOpen(false);
            }
          }))
        }}
        wrapperClassName={'ml-auto'}
        mainColor={'red-800'}
      />}
      {isOpen && !enabled && <PayvyIconButton
        buttonText={'Close'}
        loading={loading}
        disabled={loading}
        onClick={() => {
          setIsOpen(false);
        }}
        wrapperClassName={'ml-auto'}
        mainColor={'red-800'}
      />}
    </div>
    <div className={'text-sm font-light'}>
      {description}
    </div>
    {isOpen && <>
      <div className={'text-sm text-neutral-300 font-light'}>
        <GuidanceReplacer text={linkingDescription}/>
      </div>
      {getPaymentServiceForm(internalName)({
        service,
        loading: false,
        formikRef
      })}
    </>}
  </div>;
};
