import React from 'react';
import {MdKeyboardArrowRight} from "react-icons/md";
import {TbLayoutSidebar} from "react-icons/tb";
import {useNavigate, useOutletContext} from "react-router-dom";

export const PayvyPageHeader = ({
  parents = [],
  children
}) => {
  const navigate = useNavigate();
  let toggleMinimized = () => {}; // Default function

  try {
    const outletContext = useOutletContext?.(); // Optional chaining to prevent errors
    toggleMinimized = outletContext?.toggleMinimized || toggleMinimized;
  } catch(e) {}
  return <div className={'flex w-full px-2 py-4 border-b-slate-400 border-b select-none font-light'}>
    <div className={'flex justify-center items-center pl-2'}>
      <TbLayoutSidebar onClick={() => toggleMinimized && toggleMinimized()}
                       className="cursor-pointer text-slate-500 hover:text-slate-400" size={20}/>
    </div>
    <div className="flex h-6 w-px bg-gray-200 mx-3"></div>
    {parents.map((parent, index) => {
      return <div key={index} className={`flex flex-row`}>
        <div className={`text-slate-500 ${parent.href && 'hover:text-slate-400 cursor-pointer'}`}
             onClick={() => parent.href && navigate(parent.href)}>{parent.name}</div>
        <MdKeyboardArrowRight size={20} className={'text-slate-500 self-center'}/>
      </div>
    })}
    {children && <div className={'flex text-slate-800'}>
      {children}
    </div>}
  </div>
};
