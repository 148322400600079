import React from 'react';
import {PayvyInput, PayvyLabeledInput} from "../../../comps/forms";

export const FormBankDetails = () => {
  return <>
    <h2 className={'text-2xl font-medium p-2 pb-12'}>Banking - Add your bank to receive payments</h2>
    <p className={'text-base'}>
      Add your bank details manually, please make sure all details are correct to ensure timely payment.
    </p>
    <div className={'w-full gap-5'}>
      <PayvyLabeledInput label={'Bank Name'} as={PayvyInput} name={'bank_name'}/>
    </div>
    <div className={'w-full gap-5'}>
      <PayvyLabeledInput label={'Account Number'} as={PayvyInput} name={'account_number'}/>
    </div>
    <div className={'w-full gap-5'}>
      <PayvyLabeledInput label={'Routing Number'} as={PayvyInput} name={'routing_number'}/>
    </div>
  </>;
};

export default FormBankDetails;
