import React from 'react';
import {PayvyIconButton, PayvyLabeledCheckbox} from "../../../comps/forms";
import TermsOfService from './TermsOfService';

export const ProcessAcceptPolicy = ({
  increaseStepCount,
  formProps,
}) => {
  return <>
    <h2 className={'p-2 text-3xl font-medium'}>Accept the terms of service to get started</h2>
    <div className={'max-w-screen-lg bg-neutral-70 h-144 rounded-3xl p-4'}>
      <div className={'overflow-y-auto text-payvyBlue-950 h-128'}>
        <TermsOfService/>
      </div>
      <div className={'mt-2 px-4'}>
        <PayvyLabeledCheckbox
          name={'accept_terms'}
          className={'pl-4 items-center'}
          onChange={() => formProps.setFieldValue('accept_terms', !formProps.values.accept_terms)}
        >
          <span className={'pl-2 text-xs'}>By checking this box you agree to our Terms of Service and Privacy Policy, as well as our partner Dwolla's Terms of Service and Privacy Policy.</span>
        </PayvyLabeledCheckbox>
      </div>
      <PayvyIconButton
        buttonText={'Get Started'}
        wrapperClassName={'mt-10 flex w-full justify-end'}
        onClick={() => {
          formProps.setFieldTouched('accept_terms');
          if(formProps.values.accept_terms) increaseStepCount();
        }}
        shortcutKey={['ctrl+enter', 'ctrl+s']}
        type={'submit'}
      />
    </div>
  </>
};

export default ProcessAcceptPolicy;
