import React from "react";
import {PAYVY_URL} from "../../../constants";
import {generate_full_url} from "../../../utils/Utility";

export const GuidanceReplacer = ({text}) => {
  const replacements = {
    '<revolut_guidance>': (
      <ol>
        <li>1. Create a <a
          href="https://developer.revolut.com/docs/guides/manage-accounts/get-started/make-your-first-api-request#1-add-your-certificate"
          target={"_blank"} rel="noreferrer" className={'text-blue-600 hover:underline'}>certificate</a>.
        </li>
        <li>2. Add the public certificate at <a href={"https://business.revolut.com/settings/api"} target={"_blank"}
                                                rel="noreferrer" className={'text-blue-600 hover:underline'}>Revolut
          Business API</a></li>
        <li>3. OAuth redirect URI should be "{generate_full_url(PAYVY_URL.LANDING_PAGES.REVOLUT_LINKING)}"</li>
        <li>4. Add your client ID below</li>
        <li>5. Add your <strong className={'text-red-300 font-bold'}>Private key</strong> below. It is used to sign
          requests in your name.
        </li>
        <li>6. Save your configuration then on the <a href={"https://business.revolut.com/settings/api"}
                                                      target={"_blank"} rel="noreferrer"
                                                      className={'text-blue-600 hover:underline'}>Revolut
          Business API</a> page click on <strong className={'text-red-300 font-bold'}>Enable Access</strong></li>
      </ol>
    ),
  };
  const regex = new RegExp(Object.keys(replacements)
                                 .join('|'), 'g');
  const parts = text.split(regex);
  const matches = text.match(regex);
  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {matches && replacements[matches[index]]}
        </React.Fragment>
      ))}
    </>
  );
};
