import React from 'react';
import {PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {IntegrationList} from "./components/IntegrationList";

const SettingsIntegrationsPage = () => {
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Integrations</PayvyPageHeader>
    <SettingsMenu active="integrations"/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2'}>
      <IntegrationList/>
    </div>
  </>;
};

export default SettingsIntegrationsPage;
