import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyPageHeader} from "../../comps/elements";
import {companiesSelector, getAnalytics} from '../../slices/companies';
import {BlockAnalyticsHolder} from './components/BlockAnalyticsHolder';
import {ListTab} from './components/ListTab';
import {MonthlySpendingGraph} from './components/MonthlySpendingGraph';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const {
    analytics,
    analyticsLastUpdate,
    analyticsLoading,
    company: {id: companyId} = {},
  } = useSelector(companiesSelector);
  const dataAlreadySet = analyticsLastUpdate !== 0;
  const [noAccess, setNoAccess] = useState(false);
  useEffect(() => {
    if(analyticsLastUpdate + 5 * 60 * 1000 < Date.now()) {
      dispatch(getAnalytics({
        id: companyId,
        backgroundSync: dataAlreadySet,
        failure: () => setNoAccess(true)
      }));
    }
  }, [dispatch, companyId, analyticsLastUpdate, dataAlreadySet]);
  return <>
    <PayvyPageHeader>Dashboard</PayvyPageHeader>
    {noAccess ? <>
      <p className={'text-center text-danger m-8'}>You don't have permission to see company analytics. Please contact
        your company's administrator to request access to analytics.</p>
    </> : <>
      <BlockAnalyticsHolder
        loading={analyticsLoading}
        analytics={analytics}
      />
      <MonthlySpendingGraph
        monthlySpendingData={analytics.monthly_spending}
        loading={analyticsLoading}
        companyId={companyId}
      />
    </>}
    <ListTab/>
  </>;
};

export default DashboardPage;
