import {createSlice} from '@reduxjs/toolkit';
import {PAYVY_API} from "../constants";
import {mergeItemIntoList, mergeItemsIntoList, orderItems} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_BILL_STORAGE, DB_INBOX_STORAGE, resetCacheForStore} from "./db";

export const inboxSliceInitialState = {
  loading: {
    list: false,
    item: false,
    posting: false,
    deleting: false,
    documentProcessing: false,
    notification: false
  },
  numberOfItems: {'inbox': 0},
  items: {
    'inbox': [],
    'all': []
  },
  displayItems: [],
  notificationsCount: 0,

  category: 'inbox',
  currentPage: 1,
  pageSize: 10,
  orderBy: '-received',
}

const inboxSlice = createSlice({
  name: 'inbox',
  initialState: inboxSliceInitialState,
  reducers: {
    resetInboxStateToInitial: (state) => {
      state.loading = inboxSliceInitialState.loading;
      state.numberOfItems = inboxSliceInitialState.numberOfItems;
      state.items = inboxSliceInitialState.items;
      state.displayItems = inboxSliceInitialState.displayItems;

      state.category = inboxSliceInitialState.category;
      state.currentPage = inboxSliceInitialState.currentPage;
      state.pageSize = inboxSliceInitialState.pageSize;
      state.orderBy = inboxSliceInitialState.orderBy;
    },
    setInboxCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setInboxPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setInboxCategory: (state, action) => {
      state.category = action.payload;
      state.currentPage = 1;
      if(!state.items[state.category]) state.items[state.category] = [];
      state.displayItems = state.items[state.category].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserInboxes.pending, (state) => {
      state.loading.list = true;
    })
    .addCase(getUserInboxes.fulfilled, (state, action) => {
      const tableView = action?.meta?.arg?.tableView;
      state.loading.list = false;
      const {
        category = inboxSliceInitialState.category,
        page = inboxSliceInitialState.currentPage,
        page_size = inboxSliceInitialState.pageSize,
        order_by = inboxSliceInitialState.orderBy
      } = action?.meta?.arg || {}
      const newItems = action.payload.results || [];

      if(!state.items[category]) state.items[category] = [];
      state.items[category] = mergeItemsIntoList(state.items[category], newItems, true);
      let items = state.items[category]
      // Order the filtered items based on the django syntax
      items = orderItems(items, order_by)
      if(category === 'starred') items = items.filter(item => item.starred)
      if(tableView) {
        // Slice items for table view
        const displayItems = items.slice((page - 1) * page_size, page * page_size);
        if(category === 'starred') displayItems.filter(item => item.starred)
        state.displayItems = displayItems
      } else {
        // Add items to displayItems while ensuring no duplicate ids
        const uniqueItems = items.filter(
          (item) => !state.displayItems.some((displayItem) => displayItem.id === item.id)
        );
        const displayItems = [...state.displayItems, ...uniqueItems];
        if(category === 'starred') displayItems.filter(item => item.starred)
        state.displayItems = displayItems
      }
      state.numberOfItems[category] = action.payload.count;
    })
    .addCase(getUserInboxes.rejected, (state) => {
      state.loading.list = false;
      if(!state.items[state.category]) state.items[state.category] = [];
      state.displayItems = state.items[state.category].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
      state.numberOfItems[state.category] = 0;
    });

    builder
    .addCase(getUserInboxItem.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getUserInboxItem.fulfilled, (state, action) => {
      state.loading.item = false;
      state.items = Object.keys(state.items)
                          .reduce(
                            (acc, category) => {
                              acc[category] = mergeItemIntoList(state.items[category], action.payload);
                              return acc;
                            },
                            {all: mergeItemIntoList(state.items['all'] || [], action.payload, true)}
                          );
      state.displayItems = mergeItemIntoList(state.displayItems, action.payload);
    })
    .addCase(getUserInboxItem.rejected, (state) => {
      state.loading.item = false;
    });

    builder
    .addCase(getInboxItemProcessingInformation.pending, (state) => {
      state.loading.documentProcessing = true;
    })
    .addCase(getInboxItemProcessingInformation.fulfilled, (state, action) => {
      state.loading.documentProcessing = false;
      state.items = Object.keys(state.items)
                          .reduce(
                            (acc, category) => {
                              acc[category] = mergeItemIntoList(state.items[category], action.payload, true);
                              return acc;
                            },
                            {all: mergeItemIntoList([], action.payload, true)}
                          );
      state.displayItems = mergeItemIntoList(state.displayItems, action.payload);
    })
    .addCase(getInboxItemProcessingInformation.rejected, (state) => {
      state.loading.documentProcessing = false;
    })

    builder
    .addCase(getNotificationsCount.pending, (state) => {
      state.loading.notification = true;
    })
    .addCase(getNotificationsCount.fulfilled, (state, action) => {
      state.loading.notification = false;
      state.notificationsCount = action.payload?.count;
    })
    .addCase(getNotificationsCount.rejected, (state) => {
      state.loading.notification = false;
    });

    builder
    .addCase(assignUserInboxItemToBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(assignUserInboxItemToBill.fulfilled, (state) => {
      state.loading.posting = false;
      state.items = {};
      state.displayItems = [];
    })
    .addCase(assignUserInboxItemToBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(createUserInboxItemBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createUserInboxItemBill.fulfilled, (state) => {
      state.loading.posting = false;
      state.items = {};
      state.displayItems = [];
    })
    .addCase(createUserInboxItemBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(createInboxItem.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createInboxItem.fulfilled, (state, action) => {
      state.loading.posting = false;
      const newItem = action.payload;
      state.items['inbox'] = mergeItemIntoList(state.items['inbox'], newItem, true, true);
      if(state.category === 'inbox') state.displayItems = mergeItemIntoList(state.displayItems, newItem, true, true);
      state.notificationsCount += 1;
    })
    .addCase(createInboxItem.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(removeInboxItem.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeInboxItem.fulfilled, (state, action) => {
      const inboxItemID = action?.meta?.arg?.id;
      if(inboxItemID) {
        state.items = Object.keys(state.items)
                            .reduce(
                              (acc, category) => {
                                acc[category] = state.items[category].filter((item) => item.id !== inboxItemID);
                                return acc;
                              },
                              {all: state.items['all'].filter((item) => item.id !== inboxItemID)}
                            );
        state.displayItems = state.displayItems.filter((item) => item.id !== inboxItemID);
        state.loading.deleting = false;
      }
    })
    .addCase(removeInboxItem.rejected, (state) => {
      state.loading.deleting = false;
    });

    builder
    .addCase(rotateInboxPage.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(rotateInboxPage.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(rotateInboxPage.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(toggleInboxItemStar.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(toggleInboxItemStar.fulfilled, (state, action) => {
      const id = action?.meta?.arg?.id;
      const starred = action?.payload?.starred;
      state.loading.posting = false;
      state.items = Object.keys(state.items)
                          .reduce(
                            (acc, category) => {
                              acc[category] = state.items[category].map((item) => {
                                if(item.id === id) item.starred = starred;
                                return item;
                              });
                              return acc;
                            },
                            {
                              all: state.items['all'].map((item) => {
                                if(item.id === id) item.starred = starred;
                                return item;
                              })
                            }
                          );

      if(!state.items['starred']) {
        state.items['starred'] = [];
      }
      state.items['starred'] = state.items['starred'].filter((item) => item.starred);
      state.displayItems = state.category === 'starred'
        ? state.displayItems.filter((item) => item.starred)
        : state.displayItems;
      state.displayItems = state.displayItems.map((item) => {
        if(item.id === id) item.starred = starred;
        return item;
      });
    })
    .addCase(toggleInboxItemStar.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(assignUserInboxItemPageToBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(assignUserInboxItemPageToBill.fulfilled, (state) => {
      state.loading.posting = false;
      state.items = {};
      state.displayItems = [];
    })
    .addCase(assignUserInboxItemPageToBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(removeInboxPage.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeInboxPage.fulfilled, (state) => {
      state.items = {};
      state.displayItems = [];
      state.loading.deleting = false;
    })
    .addCase(removeInboxPage.rejected, (state) => {
      state.loading.deleting = false;
    });

    builder
    .addCase(updateInboxPageData.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(updateInboxPageData.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(updateInboxPageData.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(markAsNonDuplicate.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(markAsNonDuplicate.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(markAsNonDuplicate.rejected, (state) => {
      state.loading.posting = false;
    });
  }
});

export const {
  resetInboxStateToInitial,
  setInboxCurrentPage,
  setInboxPageSize,
  setInboxCategory,
} = inboxSlice.actions;
export default inboxSlice.reducer;

// Specific Inbox Item Thunks
export const getUserInboxes = createApiThunk('inbox', 'list', `${PAYVY_API.V1.INBOX.ITEM_LIST}`, DB_INBOX_STORAGE, [], 'GET', 30 * 60 * 1000);
export const getUserInboxItem = createApiThunk('inbox', 'detail', PAYVY_API.V1.INBOX.ITEM, DB_INBOX_STORAGE, [], 'GET');
export const assignUserInboxItemToBill = createApiThunk('inbox', 'assignItemToBill', PAYVY_API.V1.INBOX.ITEM_ASSIGN_BILL, DB_INBOX_STORAGE, [
  DB_INBOX_STORAGE,
  DB_BILL_STORAGE
], 'POST');
export const createUserInboxItemBill = createApiThunk('inbox', 'createItemBill', PAYVY_API.V1.INBOX.ITEM_CREATE_BILL, DB_INBOX_STORAGE, [
  DB_INBOX_STORAGE,
  DB_BILL_STORAGE
], 'POST');
export const toggleInboxItemStar = createApiThunk('inbox', 'toggleStar', PAYVY_API.V1.INBOX.ITEM_STARRED, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'POST');
export const createInboxItem = createApiThunk('inbox', 'createItem', PAYVY_API.V1.INBOX.ITEM_LIST, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'POST');
export const removeInboxItem = createApiThunk('inbox', 'delete', PAYVY_API.V1.INBOX.ITEM, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'DELETE');
export const getInboxItemProcessingInformation = createApiThunk('inbox', 'processingInfo', PAYVY_API.V1.INBOX.ITEM_PROCESSING_INFO, DB_INBOX_STORAGE, [], 'GET');
export const getNotificationsCount = createApiThunk('inbox', 'notificationsCount', PAYVY_API.V1.INBOX.NOTIFICATIONS_COUNT, DB_INBOX_STORAGE, [], 'GET');
// Specific Item Page Thunks
export const rotateInboxPage = createApiThunk('inbox', 'rotatePage', PAYVY_API.V1.INBOX.PAGE_ROTATE, DB_INBOX_STORAGE, [], 'POST');
export const assignUserInboxItemPageToBill = createApiThunk('inbox', 'assignItemPageToBill', PAYVY_API.V1.INBOX.PAGE_ASSIGN_BILL, DB_INBOX_STORAGE, [
  DB_INBOX_STORAGE,
  DB_BILL_STORAGE
], 'POST');
export const removeInboxPage = createApiThunk('inbox', 'removePage', PAYVY_API.V1.INBOX.PAGE_REMOVE, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'DELETE');
export const updateInboxPageData = createApiThunk('inbox', 'updateInvoiceData', PAYVY_API.V1.INBOX.PAGE_UPDATE, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'PATCH');
export const markAsNonDuplicate = createApiThunk('inbox', 'markAsNonDuplicate', PAYVY_API.V1.INBOX.PAGE_MARK_AS_NON_DUPLICATE, DB_INBOX_STORAGE, [DB_INBOX_STORAGE], 'POST');

export const resetInboxToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetInboxStateToInitial());
    await resetCacheForStore(DB_INBOX_STORAGE);
  }
}
