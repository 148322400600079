import Mousetrap from "mousetrap";
import React, {useEffect, useRef, useState} from "react";
import {FaSearch, FaSpinner} from "react-icons/fa";

export const PayvySearchBar = ({
  onSearch,
  loading,
  autoComplete,
  placeholder
}) => {
  const [searchValue, setSearchValue] = useState("");
  const inputRef = useRef(null);

  const handleChange = (e) => setSearchValue(e.target.value);

  const handleKeyPress = (e) => {
    if(e.key === "Enter") onSearch(searchValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => onSearch(searchValue), 750);
    return () => clearTimeout(timer);
  }, [searchValue, onSearch]);

  useEffect(() => {
    Mousetrap.bind("/", (e) => {
      e.preventDefault();
      inputRef.current?.focus();
    });
  }, []);

  return (
    <div className="relative w-full">
      <input
        ref={inputRef}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={searchValue}
        onChange={handleChange}
        onKeyUp={handleKeyPress}
        className="w-full p-2 pr-7 bg-neutral-100 h-full rounded-md focus:outline-none focus:ring focus:border-blue-300"
      />
      <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
        {loading ? <FaSpinner className="animate-spin text-gray-500"/> : <FaSearch className="text-gray-500"/>}
      </div>
    </div>
  );
};
