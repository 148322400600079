import {Form, Formik} from 'formik';
import React, {useCallback, useRef, useState} from 'react';
import {BsPencilSquare} from 'react-icons/bs';
import {MdAddCircle} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  SearchContact,
  SelectApproval
} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, hasPermission} from '../../../slices/companies';
import {createBill, getUserBills, updateBill} from "../../../slices/newBill";
import {userSelector} from '../../../slices/user';
import {build_error_message, formatDateForAPItoISO, handleErrors, payvyToast} from '../../../utils/Utility';

export const ModalCreateOrUpdateBill = ({
  editIcon = false,
  buttonText = '',
  popupText = '',
  billId = 0,
  contactId = 0,
  name = '',
  created = '',
  dueDate = '',
  approvers = undefined,
}) => {
  const dispatch = useDispatch();
  const {
    loading: {posting: processing},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const {company} = useSelector(companiesSelector);
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const submitForm = useRef(null);
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const billCanAddPermission = hasPermission(company, userId, 'bill_can_add');
  const [modalOpen, setModalOpen] = useState(false);
  const updateBills = useCallback(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    status,
    searchQuery
  ]);
  if(!billCanAddPermission) return <></>;
  return <>
    <PayvyIconButton
      Icon={editIcon ? BsPencilSquare : MdAddCircle}
      onClick={() => setModalOpen(true)}
      tooltipContent={popupText ? popupText : !!billId ? `Edit this bill` : `Create a bill`}
      buttonText={buttonText}
    />
    <ModalPayvyBase
      title={!!billId ? `Editing ${name}` : `Create New Bill`}
      isOpen={modalOpen}
      confirmButtonText={!!billId ? `Edit bill` : `Create bill`}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          contactId: contactId,
          name: name,
          created: created,
          dueDate: dueDate,
          approvers: approvers,
        }}
        validationSchema={Yup.object({
          contactId: Yup.number()
                        .integer()
                        .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          name: Yup.string()
                   .max(21, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 21}))
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          created: Yup.date()
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          dueDate: Yup.date()
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          approvers: Yup.array(Yup.number()
                                  .integer()
                                  .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)),
        })}
        onSubmit={(values, helpers) => {
          if(!!billId) {
            dispatch(updateBill({
              id: billId,
              body: {
                name: values.name,
                contact_id: values.contactId,
                created: formatDateForAPItoISO(values.created),
                due_date: formatDateForAPItoISO(values.dueDate),
                approvers: values.approvers,
              },
              successCallback: () => {
                setModalOpen(false);
                payvyToast('Bill updated successfully', {appearance: 'success'})
                updateBills();
              },
              errorCallback: (error) => {
                const data = error.data || {};
                if('message' in error) {
                  payvyToast(error.message, {appearance: 'error'});
                }
                handleErrors(data, helpers);
              }
            }))
          } else {
            dispatch(createBill({
              body: {
                name: values.name,
                contact_id: values.contactId,
                created: formatDateForAPItoISO(values.created),
                due_date: formatDateForAPItoISO(values.dueDate),
                approvers: values.approvers,
              },
              successCallback: () => {
                setModalOpen(false);
                payvyToast('Bill created successfully', {appearance: 'success'})
                updateBills();
              },
              errorCallback: (error) => {
                const data = error.data || {};
                if('message' in error) {
                  payvyToast(error.message, {appearance: 'error'});
                }
                handleErrors(data, helpers);
              }
            }))
          }
        }}
      >
        {props => <Form onKeyDown={(e) => {
          if(e.ctrlKey && (e.key === 'Enter' || e.key === 's')) {
            e.preventDefault();
            submitForm.current.click();
          }
        }}>
          <PayvyLabeledInput
            label={'Contact'}
            name={'contactId'}
            alwaysLabel={true}
          >
            <SearchContact
              setField={({value}) => props.setFieldValue('contactId', value)}
              value={props.values.contactId}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Bill Name'}
            as={PayvyInput}
            name={'name'}
            placeholder={'Bill Name'}
          />
          <PayvyLabeledInput
            label={'Bill date'}
            name={'created'}
            alwaysLabel={true}
          >
            <PayvyDatePicker
              value={props.values.created}
              name={'created'}
              onChange={(created) => props.setFieldValue('created', created)}
              clearIcon={null}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Due date'}
            name={'dueDate'}
            alwaysLabel={true}
          >
            <PayvyDatePicker
              value={props.values.dueDate}
              name={'dueDate'}
              onChange={(dueDate) => props.setFieldValue('dueDate', dueDate)}
              clearIcon={null}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Select approver'}
            name={'approvers'}
            alwaysLabel={true}
          >
            <SelectApproval
              setField={value => props.setFieldValue('approvers', [value])}
              value={props.values.approvers}
            />
          </PayvyLabeledInput>
          <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
