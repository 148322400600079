import {noop} from 'lodash';
import React, {useEffect} from 'react';
import Select from 'react-select';

export const PayvySelectComponent = ({
  field,
  placeholder,
  form,
  options,
  onChange,
  selectOnlyOption = false,
  containerClassName = 'w-full',
  controlClassName = 'rounded-mdbg-neutral-50',
}) => {
  onChange = onChange || noop;
  const value = options ? options.find(option => option.value === field.value) : undefined;

  useEffect(() => {
    // Auto-select if selectOnlyOption is true, there's no value, and only one option
    if(selectOnlyOption && !value && options && options.length === 1) {
      form.setFieldValue(field.name, options[0].value);
      onChange(options[0].value);
    }
  }, [selectOnlyOption, value, options, field.name, form, onChange]);

  return <>
    <Select
      options={options}
      name={field.name}
      placeholder={`Select ${placeholder}...`}
      value={value}
      controlShouldRenderValue={!!value}
      onChange={(option) => {
        form.setFieldValue(field.name, option.value);
        onChange(option.value);
      }}
      onBlur={field.onBlur}
      classNames={{
        container: () => containerClassName,
        control: () => controlClassName,
      }}
    />
  </>;
};
