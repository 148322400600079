import React, {useEffect, useState} from 'react';
import {FaTrash} from "react-icons/fa";
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {companiesSelector} from '../../../slices/companies';
import {getUserPaymentMethods, removePaymentMethod, userSelector} from '../../../slices/user';
import {ModalAddPaymentMethod} from "./ModalAddPaymentMethod";

export const BillingPaymentMethod = () => {
  const dispatch = useDispatch();
  const {
    paymentMethods = [],
  } = useSelector(userSelector);
  const {company: {id: companyId}} = useSelector(companiesSelector);
  const [refreshList, setRefreshList] = useState(new Date());
  useEffect(() => {
    dispatch(getUserPaymentMethods());
  }, [dispatch, refreshList, companyId]);
  const handleCardRemoval = (paymentId) => {
    dispatch(removePaymentMethod({
      payment_method_id: paymentId,
      success: () => {
        setRefreshList(new Date());
      }
    }));
  };
  return <>
    <div className={'flex flex-row text-sm text-payvyBlue-950 font-bold mt-4'}>
      <div className={'flex grow items-center'}>
        <span className={'flex'}>Payment Method</span>
        {paymentMethods.length > 0 && <ModalAddPaymentMethod
          refreshList={setRefreshList}
        />}
      </div>
    </div>
    <div className={'flex flex-col'}>
      {paymentMethods.length > 0 ?
        <table className={'table-auto mt-2 drop-shadow-2xl w-full border border-red-300'}>
          <thead>
          <tr className={'border border-red-300 text-center bg-red-50'}>
            <th>
              <div className={'flex justify-center py-2 shadow-inner'}>Payment Type</div>
            </th>
            <th>
              <div className={'flex justify-center py-2 shadow-inner'}>Last 4 of Card</div>
            </th>
            <th>
              <div className={'flex justify-center py-2 shadow-inner'}>Zip Code</div>
            </th>
            <th className={'w-16'}></th>
          </tr>
          </thead>
          <tbody className={'bg-neutral-0'}>
          {paymentMethods.map((item, index) => <tr
            className={'hover:bg-red-100 hover-border hover:border-red-300'} key={index}>
            <td className={'px-4 py-2'}>
              {item.brand} {item.default_method && <span
              className={'inline-flex items-center rounded-md bg-red-800 px-2 py-1 text-xs font-medium text-neutral-0'}>Default</span>}
            </td>
            <td className={'px-4 py-2 text-center'}>•••• {item.last4}</td>
            <td className={'px-4 py-2 text-center'}>{item.postal_code}</td>
            <td className={'px-4 py-2 pr-10 text-right'}>
              <PayvyIconButton
                Icon={FaTrash}
                mainColor={'none'}
                iconColor={'red-300'}
                hoverTextColor={'red-900'}
                tooltipContent={'Remove Card'}
                onClick={() => handleCardRemoval(item.id)}
              />
            </td>
          </tr>)}
          </tbody>
        </table> :
        <div className={'bg-neutral-0 border border-red-300 text-red-300 items-center p-2 text-sm mb-2 flex'}>
          Oh no, you haven't added a card yet! You will only be billed once you decide to start your
          subscription
          <ModalAddPaymentMethod refreshList={setRefreshList}/>
        </div>}
    </div>
  </>
};
