import React from 'react';
import {PayvyIconButton} from '../../comps/forms';
import {PAYVY_LOGOS} from '../../constants';


const AccountVerificationPage = () => {
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <h1 className={'flex text-xl mb-3 font-bold justify-center'}>Confirm your account</h1>
        <p className={'text-center mb-3'}>
          Thanks for signing up with us. Your account is almost ready. <br/>
          Please check your inbox to confirm your email address.
        </p>
        <div className={'flex w-full justify-center py-1'}>
          <PayvyIconButton
            buttonText={'Check your email'}
            allowPointerEvents={false}
          />
        </div>
      </div>
    </div>
  </div>;
};

export default AccountVerificationPage;
