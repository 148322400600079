import {noop} from 'lodash';
import React from 'react';
import {BsPencilSquare} from 'react-icons/bs';
import {useNavigate} from 'react-router-dom';
import {PayvyIconButton} from '../../../comps/forms';
import {PAYVY_URL} from '../../../constants';
import {build_url} from '../../../utils/Utility';
import {ModalRemoveContact} from './ModalRemoveContact';

export function CellActions({
  column: {
    refreshList,
    canUpdate = false,
    canDelete = false,
  },
  row: {original},
}) {
  const navigate = useNavigate();
  refreshList = refreshList || noop;
  const {id} = original || {};
  return <>
    {canUpdate && <PayvyIconButton
      Icon={BsPencilSquare}
      onClick={() => navigate(`${build_url(PAYVY_URL.CONTACTS.DETAILS, {id})}?editMode=true`)}
      tooltipContent={'Edit Contact'}
    />}
    {canDelete && <ModalRemoveContact contact={original} refreshList={refreshList}/>}
  </>;
}
