import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {MdPayment} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getUserBills, retryBillPayment} from "../../../slices/newBill";
import {handleErrors, payvyToast} from '../../../utils/Utility';
import {SelectFailedTransactions} from './SelectFailedTransactions';

export const ModalRetryPayment = ({
  billId,
  detailStyle = false,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {posting: processing},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const submitForm = React.useRef(null);
  return <>
    <PayvyIconButton
      Icon={MdPayment}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Retry Payment'}
      mainColor={detailStyle ? 'none' : undefined}
      borderColor={detailStyle ? 'none' : undefined}
      iconColor={detailStyle ? 'neutral-700' : undefined}
      iconSize={detailStyle ? 22 : undefined}
      hoverTextColor={detailStyle ? 'red-300' : undefined}
    />
    <ModalPayvyBase
      title={`Retry failed payment`}
      isOpen={modalOpen}
      confirmButtonText={'Retry'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          transaction: null,
        }}
        validationSchema={Yup.object({
          transaction: Yup.number(FORM_ERROR_MESSAGES.VALID_ID)
                          .typeError(FORM_ERROR_MESSAGES.VALID_ID)
                          .required(FORM_ERROR_MESSAGES.VALID_ID)
                          .integer(FORM_ERROR_MESSAGES.VALID_ID)
                          .positive(FORM_ERROR_MESSAGES.VALID_ID),
        })}
        onSubmit={(values, helpers) => {
          dispatch(retryBillPayment({
            billId,
            transactionId: values.transaction,
            successCallback: () => {
              setModalOpen(false);
              payvyToast('Transaction has been retried.', {appearance: 'success'});
              dispatch(getUserBills({
                amount: filterAmountStr,
                contact: filterContact,
                from: fromDateStr,
                to: toDateStr,
                order_by: orderBy,
                page: currentPage,
                page_size: pageSize,
                status: status,
                q: searchQuery,
              }));
            },
            errorCallback: (error) => {
              const data = error.data || {};
              if('message' in error) {
                payvyToast(error.message, {appearance: 'error'});
              }
              handleErrors(data, helpers);
            }
          }))
        }}
      >
        {props => <Form>
          <p>Your transaction will be refunded after 48 hours automatically, if you do not wish to retry this
            transaction, just take no action.</p>
          <PayvyLabeledInput
            label={'Select payment to retry'}
            name={'transaction'}
            alwaysLabel={true}
          >
            <SelectFailedTransactions billId={billId} setValue={value => props.setFieldValue('transaction', value)}/>
          </PayvyLabeledInput>
          <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
