import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {BigCenteredLoadingBlock, PayvyPageHeader, SettingsMenu} from "../../comps/elements";
import {getIntegration} from '../../slices/newIntegration';
import {DefaultSettings} from './';
import {SettingComponents} from "./utility/SettingComponents";


const SettingsIntegrationDetailPage = () => {
  const dispatch = useDispatch();
  let {id} = useParams();
  const {
    loading: {item: loading},
    items
  } = useSelector((state) => state.integration);
  useEffect(() => {
    dispatch(getIntegration({id}));
  }, [dispatch, id]);
  const [integration, setIntegration] = React.useState({});
  useEffect(() => {
    if(items.length > 0) {
      setIntegration(items.find((item) => parseInt(item.id) === parseInt(id)) || {});
    }
  }, [items, id]);
  if(loading) return <BigCenteredLoadingBlock text={'Loading'}/>
  const IntegrationSettings = integration?.codename in SettingComponents ? SettingComponents[integration?.codename] : DefaultSettings;
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Integrations</PayvyPageHeader>
    <SettingsMenu active="integrations"/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2'}>
      <IntegrationSettings integration={integration}/>
    </div>
  </>;
};

export default SettingsIntegrationDetailPage;
