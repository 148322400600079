import React from 'react';

export const BigCenteredLoadingBlock = ({ text }) => {
    const [loadingIndicatorPeriods] = React.useState(['', '.', '..', '...']);
    const [loadingIndicatorIndex, setLoadingIndicatorIndex] = React.useState(0);
    const loadingIndicator = loadingIndicatorPeriods[loadingIndicatorIndex];

    React.useEffect(() => {
        const interval = setInterval(() => {
            setLoadingIndicatorIndex(loadingIndicatorIndex => (loadingIndicatorIndex + 1) % loadingIndicatorPeriods.length);
        }, 500);
        return () => {
            clearInterval(interval);
        };
    });

  return <div className={'flex items-center justify-center h-full'}>
        {text}{loadingIndicator}
    </div>;
};
