import React, {useEffect} from 'react';
import {FaRegStar, FaStar, FaTrash} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {PayvyIconButton} from '../../../comps/forms';
import {
  companiesSelector,
  getCompanyAddresses,
  removeCompanyAddress,
  setNewPrimaryAddress
} from '../../../slices/companies';
import {ModalCompanyAddress} from './ModalCompanyAddress';

export const CompanyAddress = ({companyId}) => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const {
    addresses,
    addressesLoading,
    processing,
  } = useSelector(companiesSelector);
  useEffect(() => {
    dispatch(getCompanyAddresses({id: companyId}));
  }, [dispatch, companyId]);
  return <div className={'flex flex-col'}>
    <div className={'flex flex-col gap-0.5'}>
      {addresses.map(address => {
        const {
          id,
          primary,
          address: addressDetail,
        } = address;
        const {
          line1,
          line2,
          zip,
          state,
          city,
        } = addressDetail || {};
        return <div className={'flex flex-row w-full bg-neutral-0 px-2 py-1'} key={id}>
          <div className={'flex grow'}>{line1} {line2} {city} {state} {zip}</div>
          <div className={`flex gap-1 `}>
            <PayvyIconButton
              Icon={primary ? FaStar : FaRegStar}
              tooltipContent={'Promote to Primary Address'}
              onClick={() => {
                if(primary) return;
                dispatch(setNewPrimaryAddress({
                  companyAddressId: id,
                  success: () => {
                    dispatch(getCompanyAddresses({id: companyId}));
                  },
                  failure: (error) => {
                    addToast(error.toString() || 'Page removal failed.', {appearance: 'error'});
                  }
                }));
              }}
              disabled={processing || addressesLoading}
              loading={processing || addressesLoading}
              type={'a'}
              iconSize={16}
              iconPaddingX={0.5}
              iconPaddingY={0.5}
              mainColor={'none'}
              borderColor={'none'}
              iconColor={'red-200'}
              hoverTextColor={'red-300'}
              allowPointerEvents={false}
              wrapperClassName={primary ? 'pointer-events-none' : ''}
            />
            <PayvyIconButton
              Icon={FaTrash}
              onClick={() => {
                dispatch(removeCompanyAddress({
                  companyAddressId: id,
                  success: () => {
                    dispatch(getCompanyAddresses({id: companyId}));
                  },
                  failure: (error) => {
                    addToast(error.toString() || 'Address removal failed.', {appearance: 'error'});
                  }
                }));
              }}
              tooltipContent={'Remove Address'}
              type={'a'}
              iconSize={16}
              iconPaddingX={0.5}
              iconPaddingY={0.5}
              mainColor={'none'}
              borderColor={'none'}
              iconColor={'neutral-700'}
              hoverTextColor={'red-300'}
            />
          </div>
        </div>;
      })}
    </div>
    <div className={'flex flex-row'}>
      <div className={'flex flex-col'}>
        <div className={'flex flex-row items-center'}><FaStar className={'text-red-200 flex-inline mr-2'}/> Primary
          Address
        </div>
        <div className={'flex flex-row items-center'}><FaRegStar className={'text-red-200 flex-inline mr-2'}/> Backup
          Address
        </div>
      </div>
      <div className={'flex flex-row grow justify-end items-center'}>
        <div className={'h-fit'}>
          <ModalCompanyAddress success={() => dispatch(getCompanyAddresses({id: companyId}))}/>
        </div>
      </div>
    </div>
  </div>;
};
