import React, {useEffect, useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {PayvyPageHeader} from "../../comps/elements";
import {PayvyIconButton} from '../../comps/forms';
import {acceptDispute, finixSelector, uploadEvidence} from '../../slices/finix';
import {getInvoiceDetails, receivablesSelector} from '../../slices/receivables';
import {EvidenceUploading} from "./components/EvidenceUploading";

const DisputeDetailPage = () => {
  const dispatch = useDispatch();
  const {
    processing: receivablesProcessing,
    loading: receivablesLoading,
    invoiceDetails,
  } = useSelector(receivablesSelector);
  const {
    processing: finixProcessing,
    loading: finixLoading,
  } = useSelector(finixSelector);
  const {
    invoiceId,
    disputeId
  } = useParams()
  const invoice = invoiceDetails[invoiceId];
  const {disputes = []} = invoice || {};
  const dispute = disputes.find(d => d.id === disputeId) || {};
  const {
    response_state,
    reason,
    state,
    amount = 0,
    message = null,
    evidences = [],
  } = dispute;
  const [activelyDisputing, setActivelyDisputing] = useState(false);
  const [files, setFiles] = useState([]);
  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => {
    _setRefreshInfo(new Date());
  };

  useEffect(() => {
    if(!!invoiceId) dispatch(getInvoiceDetails({invoiceId}));
  }, [dispatch, invoiceId, refreshInfo]);
  useEffect(() => {
    if(evidences.length > 0) setActivelyDisputing(true);
  }, [evidences]);
  if(receivablesLoading) return (<>Loading...</>);
  if(!invoice) return (<>Invoice not found</>);
  if(!dispute?.id) return (<>Dispute not found</>);
  const canRespond = response_state === 'NEEDS_RESPONSE';
  const inRespondingState = activelyDisputing && canRespond;
  return <div className={'flex flex-col h-full'}>
    <PayvyPageHeader>Invoice {invoice.invoice_number}</PayvyPageHeader>
    <div className={'bg-neutral-50 p-4 w-full h-full'}>
      <div className={'flex'}>
        The payment of <b className={'px-1'}><CurrencyFormat value={amount / 100} displayType={'text'}
                                                             thousandSeparator={true} prefix={'$'}/></b>has been
        disputed.
      </div>
      <div className={'flex'}>
        <b className={'px-1'}>Reason:</b> {reason}
      </div>
      {message && <div className={'flex'}>
        <b className={'px-1'}>Additional information:</b> {message}
      </div>}
      <div className={'flex flex-col'}>
        <b className={'flex px-1'}>Uploaded evidences:</b>
        {evidences.map(({
          id,
          file_name: fileName,
          state,
        }) => <div className={'flex flex-row px-1'} key={id}>- {fileName}
          <span className={'text-xs text-neutral-500 px-1 self-center'}>({state})</span></div>)}
        {evidences.length === 0 && <div>No evidence uploaded yet.</div>}
      </div>
      {!canRespond && <div className={'flex flex-col'}>
        <div className={'flex my-5 text-xl font-bold text-neutral-950'}>
          {state === 'INQUIRY' && 'The dispute is still in inquiry'}
          {state === 'PENDING' && 'The dispute is under review.'}
          {state === 'WON' && 'The dispute has been won.'}
          {state === 'LOST' && 'The dispute has been lost.'}
        </div>
      </div>}
      {inRespondingState && <div className={'flex flex-col'}>
        <div className={'flex'}>
          <EvidenceUploading
            disabled={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
            onDrop={(acceptedFiles) => {
              setFiles(acceptedFiles);
            }}
          />
        </div>
        <div className={'flex mt-6'}>
          <PayvyIconButton
            buttonText={'Submit evidences to Bank'}
            loading={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
            disabled={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
            onClick={() => dispatch(uploadEvidence({
              invoiceId,
              disputeId,
              files,
              success: setRefreshInfo,
              failure: setRefreshInfo, // TODO: display error for the user.
            }))}
            shortcutKey={['ctrl+enter', 'ctrl+s']}
            type={'submit'}
          />
          <PayvyIconButton
            buttonText={'Cancel dispute'}
            loading={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
            disabled={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
            onClick={() => setActivelyDisputing(false)}
            shortcutKey={'escape'}
          />
        </div>
      </div>}
      {canRespond && !inRespondingState && <div className={'flex mt-6'}>
        <PayvyIconButton
          buttonText={'I accept the dispute'}
          loading={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
          disabled={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
          onClick={() => dispatch(acceptDispute({
            invoiceId,
            disputeId,
            success: setRefreshInfo,
            failure: setRefreshInfo, // TODO: display error for the user.
          }))}
        />
        <PayvyIconButton
          buttonText={'Fight it and provide proof'}
          loading={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
          disabled={receivablesLoading || receivablesProcessing || finixLoading || finixProcessing}
          onClick={() => setActivelyDisputing(true)}
        />
      </div>}
    </div>
  </div>;
};

export default DisputeDetailPage;
