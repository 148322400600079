import {Form, Formik} from "formik";
import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import Select from "react-select";
import * as Yup from "yup";
import {PayvyIconButton, PayvyLabeledInput} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getPaymentServiceInformation, linkContactToServiceRecipient} from "../../../slices/paymentSystem";
import RefreshContactFromAPIButton from "./RefreshContactFromAPIButton";

export const ContactAssignmentFormRecipientField = ({
  contactId,
  service,
  value,
  setField
}) => {
  const [valueObject, setValueObject] = React.useState(null);
  const {active_configuration: {enabled = false}} = service;
  const recipientsFormatted = service.recipients
                                     ?.filter(recipient => recipient.contact === null || recipient.contact.id === contactId)
                                     .map((recipient) => {
                                       return {
                                         value: recipient.id,
                                         label: recipient.name
                                       }
                                     });

  useEffect(() => {
    if(value) {
      const recipient = recipientsFormatted.find((recipient) => recipient.value === value);
      if(JSON.stringify(recipient) !== JSON.stringify(valueObject)) {
        setValueObject(recipient || null);
      }
    } else if(valueObject) {
      setValueObject(null);
    }
  }, [value, recipientsFormatted, valueObject])

  if(!enabled) return null;

  const triggerLocalFieldChange = (option) => {
    setField(option?.value || null);
    setValueObject(option);
  };

  return <PayvyLabeledInput
    label={`${service.name} Recipient`}
    name={service.internal_name}
    alwaysLabel={true}
  >
    <Select
      className={'w-full'}
      placeholder={`${service.name} Recipient`}
      isDisabled={false}
      value={valueObject}
      isClearable={true}
      onChange={(option) => triggerLocalFieldChange(option)}
      options={recipientsFormatted}
    />
  </PayvyLabeledInput>
}

export const ContactAssignmentForm = ({
  contactId,
  services,
  internalName,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const servicesValidation = services.reduce((acc, service) => {
    const enabled = service?.active_configuration?.enabled;
    if(enabled) {
      acc[service.internal_name] = Yup.mixed()
                                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED);
    } else {
      acc[service.internal_name] = Yup.mixed()
                                      .nullable();
    }
    return acc;
  }, {});

  const servicesInitialValues = services.reduce((acc, service) => {
    const r = service.recipients?.find((recipient) => {
      return recipient.contact?.id === contactId;
    }) ?? null;
    const enabled = service?.active_configuration?.enabled;
    if(enabled) {
      acc[service.internal_name] = r?.id ?? null;
    }
    return acc;
  }, {});
  const servicesEnabled = services.filter((service) => service?.active_configuration?.enabled);
  const selectedService = services.find((service) => service.internal_name === internalName);
  return <div className={"mt-2"}>
    <div className={"text-red-800"}>Contact associated with this account not linked to {selectedService?.name}.
    </div>
    <Formik
      initialValues={servicesInitialValues}
      innerRef={formikRef}
      validationSchema={Yup.object(servicesValidation)}
      onSubmit={(values) => {
        const submitValues = {
          ...values,
          contact: contactId,
          success: () => {
            dispatch(getPaymentServiceInformation({}))
          }
        };
        dispatch(linkContactToServiceRecipient(submitValues))
      }}
      enableReinitialize={true}
    >
      {props => <Form>
        {servicesEnabled.map((service, index) => {
          return <div className={'flex flex-row md:flex-row gap-2'}>
            <div className={'flex-inline w-full'}>
              <ContactAssignmentFormRecipientField
                key={index}
                contactId={contactId}
                service={service}
                value={props.values[service.internal_name]}
                setField={(value) => props.setFieldValue(service.internal_name, value)}
              />
            </div>
            <div className={'flex-inline'}>
              <RefreshContactFromAPIButton
                currentPaymentType={service.internal_name}
              />
            </div>
          </div>
        })}
        <PayvyIconButton
          buttonText={'Link Contact'}
          fullWidth={true}
          wrapperClassName={'w-full'}
          type={'submit'}
        />
      </Form>
      }
    </Formik>
  </div>
}
