import {noop} from 'lodash';
import React, {useState} from 'react';
import {BsFillTrashFill} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {getContacts, removeContact} from '../../../slices/newContact';
import {payvyToast} from "../../../utils/Utility";

export const ModalRemoveContact = ({
  contact: {
    id,
    name,
  },
  success,
}) => {
  success = success || noop;

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {deleting: processing},
    currentPage,
    pageSize,
    orderBy,
    searchQuery,
    contactType,
  } = useSelector((state) => state.contact);
  const confirmRemoveContact = () => {
    dispatch(removeContact({
      id,
      successCallback: () => {
        dispatch(getContacts({
          page: currentPage,
          page_size: pageSize,
          q: searchQuery,
          order_by: orderBy,
          show_deleted: false,
          contact_type: contactType
        }));
        success();
        setModalOpen(false);
        payvyToast('Contact removed successfully.', {appearance: 'success'});
      },
      errorCallback: (error) => {
        if('message' in error) payvyToast(error.message || 'Error removing contact.', {appearance: 'error'});
      }
    }));
  };

  return <>
    <PayvyIconButton
      Icon={BsFillTrashFill}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Contact'}
    />
    <ModalPayvyBase
      title={`Removing "${name}" contact`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, remove contact'}
      confirmButtonAction={confirmRemoveContact}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to remove <b>"{name}"</b> contact?</p>
    </ModalPayvyBase>
  </>;
};
