import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PAYVY_URL} from '../../../constants';
import {companiesSelector, hasPermission} from '../../../slices/companies';
import {userSelector} from '../../../slices/user';
import {PayvySubmenu} from '../PayvySubmenu/PayvySubmenu';

export const SettingsMenu = ({active}) => {
  const navigate = useNavigate();
  const {company} = useSelector(companiesSelector);
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const companyAdministrationPermission = hasPermission(company, userId, 'company_administration');
  const integrationAdministrationPermission = hasPermission(company, userId, 'integration_administration');
  const hasAccountAccess = true;
  const hasBillingAccess = !!company && companyAdministrationPermission;
  const hasBankingAccess = !!company && companyAdministrationPermission;
  const hasNotificationAccess = !!company;
  const hasIntegrationsAccess = !!company && integrationAdministrationPermission;
  const hasTeamsAccess = !!company && companyAdministrationPermission;
  const options = [
    {
      name: 'Account',
      value: 'account',
      disabled: !hasAccountAccess,
      link: PAYVY_URL.SETTINGS.ACCOUNT,
    }, {
      name: 'Billing',
      value: 'billing',
      disabled: !hasBillingAccess,
      link: PAYVY_URL.SETTINGS.BILLING,
    }, {
      name: 'Banking',
      value: 'banking',
      disabled: !hasBankingAccess,
      link: PAYVY_URL.SETTINGS.BANKING,
    }, {
      name: 'Notifications',
      value: 'notifications',
      disabled: !hasNotificationAccess,
      link: PAYVY_URL.SETTINGS.NOTIFICATIONS,
    }, {
      name: 'Integrations',
      value: 'integrations',
      disabled: !hasIntegrationsAccess,
      link: PAYVY_URL.SETTINGS.INTEGRATIONS,
    }, {
      name: 'Team',
      value: 'team',
      disabled: !hasTeamsAccess,
      link: PAYVY_URL.SETTINGS.TEAM,
    },
  ];
  return <PayvySubmenu
    activeItem={active}
    setActiveItem={(value) => options.find(option => option.value === value) ? navigate(options.find(option => option.value === value).link) : null}
    options={options}
  />;
};
