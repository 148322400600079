import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {PAYVY_LOGOS} from '../../../constants';
import {ProcessBlockHolder} from './ProcessBlockHolder';

export const FormInviteUser = ({hash}) => {
  const {invitationSenderCompany} = useSelector((state) => state.contact);
  const maxStepCount = 4;
  const [currentStep, setCurrentStep] = useState(1);
  const increaseStepCount = () => setCurrentStep(currentStep < maxStepCount ? currentStep + 1 : maxStepCount);

  return <div className={'w-full h-full flex flex-col'}>
    <div className={'w-96 h-32 mt-8 self-center'}>
      <div
        className={`progress-bar w-full grid grid-cols-${maxStepCount - 1} gap-0 bg-neutral-0 rounded-xl border-2 border-neutral-300 max-w-sm h-9 overflow-hidden`}
      >
        {[...Array(currentStep - 1)].map((e, i) => <div className={'bg-neutral-300'} key={i}/>)}
      </div>
    </div>
    <div className={'flex flex-row'}>
      <div className={'w-1/3 px-12'}>
        <h1 className={'pt-4 text-3xl font-medium'}>
          {invitationSenderCompany} partners with Payvy to pay their vendors
        </h1>
      </div>
      <div className={'w-2/3 px-12'}>
        <ProcessBlockHolder
          currentStep={currentStep}
          increaseStepCount={increaseStepCount}
          setCurrentStep={setCurrentStep}
          hash={hash}
        />
      </div>
    </div>
    <img
      src={PAYVY_LOGOS.RED}
      alt={'logo'}
      className={'absolute bottom-0 left-0 mb-4 ml-12 w-20'}
    />
  </div>
};
