import React, {useState} from 'react';
import {BsFillTrashFill} from 'react-icons/bs';
import {FaUndo} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {PAYVY_URL} from "../../../constants";
import {getUserInboxItem, removeInboxPage} from '../../../slices/newInbox';
import {payvyToast} from "../../../utils/Utility";

export const ModalRemovePage = ({
  inboxItemId,
  page,
  deleted = false,
  noExtraStyles = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {loading: {deleting: processing}} = useSelector((state) => state.inbox)
  const confirmRemoveInboxItemPage = () => {
    dispatch(removeInboxPage({
      id: inboxItemId,
      page: page.id,
      successCallback: () => {
        dispatch(getUserInboxItem({
          id: inboxItemId,
          forceUpdate: true,
          successCallback: () => payvyToast('Item information is refreshed', {'appearance': 'success'}),
          errorCallback: () => navigate(PAYVY_URL.INBOX.RECEIVED)
        }))
        setModalOpen(false);
        payvyToast(`Page ${deleted ? 'restored' : 'removed'} successfully.`, {appearance: 'success'});
      },
      errorCallback: (error) => {
        if('message' in error) payvyToast(error.message || `Page ${deleted ? 'restoring' : 'removal'} failed.`, {appearance: 'error'});
      }
    }));
  };
  const text = deleted ? 'Restore Page' : 'Remove Page';
  const Icon = deleted ? FaUndo : BsFillTrashFill;
  return <>
    <PayvyIconButton
      Icon={Icon}
      onClick={() => setModalOpen(true)}
      tooltipContent={text}
      tooltipPlace={'right'}
      mainColor={noExtraStyles ? 'none' : undefined}
      type={noExtraStyles ? 'a' : undefined}
      iconSize={noExtraStyles ? 16 : undefined}
      iconPaddingX={noExtraStyles ? 0.5 : undefined}
      iconPaddingY={noExtraStyles ? 0.5 : undefined}
      borderColor={noExtraStyles ? 'none' : undefined}
      iconColor={noExtraStyles ? 'neutral-500' : undefined}
      hoverTextColor={noExtraStyles ? 'neutral-700' : undefined}
    />
    <ModalPayvyBase
      title={`${deleted ? 'Restoring' : 'Removing'} "Page ${page.page_number}"`}
      isOpen={modalOpen}
      confirmButtonText={`Yes, ${deleted ? 'Restore' : 'Remove'} page`}
      confirmButtonAction={confirmRemoveInboxItemPage}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to {deleted ? 'restore' : 'remove'} <b>"Page {page.page_number}"</b>?</p>
    </ModalPayvyBase>
  </>;
};
