import {clamp} from 'lodash';
import Mousetrap from 'mousetrap';
import React, {useEffect} from 'react';
import {AiOutlineLoading} from 'react-icons/ai';
import {MdAddCircle} from "react-icons/md";
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {createPageInformation, getBill, reorderBillPages} from "../../../slices/newBill";
import {payvyToast, relocateItemWithCopy} from "../../../utils/Utility";
import {SortableList} from "./SortableList";

export const PageList = ({
  bill,
  pages = [],
  currentPage,
  setCurrentPage,
  canUpdate = false,
  canDelete = false,
}) => {
  const dispatch = useDispatch();
  const {
    id: billId,
    status,
  } = bill;
  const {
    pageReordering,
    savingPageLoading,
  } = useSelector((state) => state.bill);

  // region Hotkeys
  useEffect(() => {
    Mousetrap.bind(['j', 'left'], () => setCurrentPage(clamp(currentPage - 1, 0, pages.length - 1)))
    Mousetrap.bind(['k', 'right'], () => setCurrentPage(clamp(currentPage + 1, 0, pages.length - 1)))
  }, [pages, currentPage, setCurrentPage]);
  // endregion

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }) => {
    const newSort = relocateItemWithCopy(pages, oldIndex, newIndex);
    dispatch(reorderBillPages({
      id: billId,
      body: {
        new_order: newSort.map(i => i.id),
      },
      successCallback: () => {
        dispatch(getBill({id: billId}));
        setCurrentPage(newIndex)
      },
      errorCallback: () => {
        payvyToast('Failed to reorder pages.', {appearance: 'error'});
      }
    }));
  };
  const createNewPage = () => {
    dispatch(createPageInformation({
      id: billId,
      body: {
        page_number: 1,
        invoice_number: '',
        total_amount: 0
      },
      successCallback: () => {
        dispatch(getBill({id: billId}));
        payvyToast('Page created successfully.', {appearance: 'success'});
      },
      errorCallback: () => {
        payvyToast('Failed to create page.', {appearance: 'error'});
      }
    }));
  };
  return <div
    className={`payvy-page-list ${pageReordering ? 'm-0 overflow-hidden overflow-y-hidden' : 'overflow-y-auto'}`}>
    {pageReordering ? <div className={'w-full h-full relative pt-5'}>
      <AiOutlineLoading className={'animate-spin centered text-center'} size={36}/>
    </div> : null}
    {pages.length === 0 && status !== 'Paid' &&
      <PayvyIconButton
        tooltipContent={'Add blank page'}
        buttonText={'Add blank page'}
        Icon={MdAddCircle}
        mainColor={'none'}
        borderColor={'none'}
        textColor={'neutral-900'}
        iconColor={'neutral-500'}
        hoverTextColor={'red-300'}
        hoverColor={'red-300'}
        className={'payvy-action payvy-primary'}
        content={'Add blank page'}
        onClick={createNewPage}
        loading={savingPageLoading}
        disabled={savingPageLoading}
      />}
    <SortableList
      items={pages}
      onSortEnd={onSortEnd}
      useDragHandle
      lockAxis={'y'}
      helperClass={'moving'}
      bill={bill}
      canUpdate={canUpdate}
      canDelete={canDelete}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  </div>;
};
