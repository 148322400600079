import React, {useEffect, useState} from 'react';
import {BigCenteredLoadingBlock} from "../../../comps/elements";

const PageContent = ({
  pages = [],
  currentPage
}) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [failedImageLoading, setFailedImageLoading] = useState(false);
  useEffect(() => {
    setImageLoading(true);
    setFailedImageLoading(false);
  }, [currentPage]);
  const handleImageLoaded = () => {
    setImageLoading(false);
    setFailedImageLoading(false);
  };
  const handleImageFailed = () => {
    setImageLoading(false);
    setFailedImageLoading(true);
  };
  const {page: {url} = {}} = pages[currentPage] || {};
  return <div className={'flex flex-col grow h-full overflow-y-auto w-full'}>
    {!url && <div className={'flex items-center justify-center h-full'}>There is no image for this page.</div>}
    {failedImageLoading && url &&
      <div className={'flex items-center justify-center h-full'}>
        Failed to load image. Please try again later. <br/>
        If the problem persists, contact the administrator.
      </div>}
    {!failedImageLoading && url && imageLoading && <BigCenteredLoadingBlock text={'Loading image'}/>}
    {url &&
      <img src={url} alt={'page'} onLoad={handleImageLoaded} onError={() => handleImageFailed()}
           className={(imageLoading || failedImageLoading) ? 'hidden' : ''}/>}
  </div>;
};

export default PageContent;
