import {configureStore} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from "react-dom/client";
import {Toaster} from "react-hot-toast";
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {ToastProvider} from 'react-toast-notifications';
import App from './App';
import {SENTRY_URL, STRIPE_PUBLIC_KEY} from './constants';
import rootReducer from './slices';
import './index.css';
import favicon from './static/media/favicon.ico'

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);


Sentry.init({
  dsn: SENTRY_URL,
  normalizeDepth: 10
});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});
const store = configureStore({
  reducer: rootReducer,
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers()
    .concat(sentryReduxEnhancer);
  },
});

function changeFavicon(url) {
  let link = document.querySelector("link[rel*='icon']");

  if(!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }

  link.href = url;
}

changeFavicon(favicon);

function FallbackComponent() {
  return (
    <div>An error has occurred</div>
  );
}

ReactDOM.createRoot(document.getElementById("root"))
        .render(
          <Provider store={store}>
            <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
              <Elements stripe={stripePromise}>
                <Toaster/>
                <ToastProvider autoDismiss={true} placement={'bottom-right'}>
                  <BrowserRouter>
                    <App/>
                  </BrowserRouter>
                </ToastProvider>
              </Elements>
            </Sentry.ErrorBoundary>
          </Provider>
        );
