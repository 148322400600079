import React, {useEffect, useState} from 'react';
import 'react-phone-number-input/style.css';
import {useDispatch, useSelector} from 'react-redux';
import {redirect, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AccessCheck} from "./comps/routes/AccessCheck/AccessCheck";
import {AnonRoute} from "./comps/routes/AnonRoute/AnonRoute";
import {MissingCompanyRoute} from "./comps/routes/MissingCompanyRoute/MissingCompanyRoute";
import {ProtectedRoute} from "./comps/routes/ProtectedRoute/ProtectedRoute";
import {RedirectToDashboardRoute} from "./comps/routes/RedirectToDashboardRoute/RedirectToDashboardRoute";
import {SideBarAndTopBarRoute} from "./comps/routes/SideBarAndTopBarRoute/SideBarAndTopBarRoute";
import {UnverifiedAccountRoute} from "./comps/routes/UnverifiedAccountRoute/UnverifiedAccountRoute";
import {PAYVY_URL} from "./constants";
import {useIsSiteLiveChecker} from "./hooks/useIsSiteLiveChecker";
import AccountVerificationPage from "./pages/AccountVerificationPage/AccountVerificationPage";
import AccountVerifyLandingPage from "./pages/AccountVerifyLandingPage/AccountVerifyLandingPage";
import BillCreatePage from "./pages/BillCreatePage/BillCreatePage";
import BillDetailPage from "./pages/BillDetailPage/BillDetailPage";
import BillListPage from "./pages/BillListPage/BillListPage";
import BillPayPage from "./pages/BillPayPage/BillPayPage";
import ContactCreateCustomer from "./pages/ContactCreateCustomer/ContactCreateCustomer";
import ContactDetailPage from "./pages/ContactDetailPage/ContactDetailPage";
import ContactListPage from "./pages/ContactListPage/ContactListPage";
import CreateCompanyPage from "./pages/CreateCompanyPage/CreateCompanyPage";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import DisputeDetailPage from "./pages/DisputeDetailPage/DisputeDetailPage";
import FullPageLoader from "./pages/FullPageLoader/FullPageLoader";
import InactivityPage from "./pages/InactivityPage/InactivityPage";
import InboxDetailPage from "./pages/InboxDetailPage/InboxDetailPage";
import InboxReceivedPage from "./pages/InboxReceivedPage/InboxReceivedPage";
import InvitedCompanyUserLandingPage from "./pages/InvitedCompanyUserLandingPage/InvitedCompanyUserLandingPage";
import InvitedUserLandingPage from "./pages/InvitedUserLandingPage/InvitedUserLandingPage";
import InvoiceCreatePage from "./pages/InvoiceCreatePage/InvoiceCreatePage";
import InvoiceDetailPage from "./pages/InvoiceDetailPage/InvoiceDetailPage";
import InvoiceLandingPage from "./pages/InvoiceLandingPage/InvoiceLandingPage";
import InvoiceListPage from "./pages/InvoiceListPage/InvoiceListPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import PasswordResetLandingPage from "./pages/PasswordResetLandingPage/PasswordResetLandingPage";
import './App.scss';
import PasswordResetPage from "./pages/PasswordResetPage/PasswordResetPage";
import RegisterAccountPage from "./pages/RegisterAccountPage/RegisterAccountPage";
import RevolutLinkingLandingPage from "./pages/RevolutLinkingLandingPage/RevolutLinkingLandingPage";
import SettingsAccountPage from "./pages/SettingsAccountPage/SettingsAccountPage";
import SettingsBankingPage from "./pages/SettingsBankingPage/SettingsBankingPage";
import SettingsBillingPage from "./pages/SettingsBillingPage/SettingsBillingPage";
import SettingsIntegrationDetailPage from "./pages/SettingsIntegrationDetailPage/SettingsIntegrationDetailPage";
import SettingsIntegrationsPage from "./pages/SettingsIntegrationsPage/SettingsIntegrationsPage";
import SettingsNotificationsPage from "./pages/SettingsNotificationsPage/SettingsNotificationsPage";
import SettingsTeamPage from "./pages/SettingsTeamPage/SettingsTeamPage";
import SettingsVirtualBoxPage from "./pages/SettingsVirtualBoxPage/SettingsVirtualBoxPage";
import SwitchCompanyPage from "./pages/SwitchCompanyPage/SwitchCompanyPage";
import TestingPage from "./pages/TestingPage/TestingPage";
import TransactionListPage from "./pages/TransactionListPage/TransactionListPage";
import WiseLinkingLandingPage from "./pages/WiseLinkingLandingPage/WiseLinkingLandingPage";
import {authSelector, refreshUserToken} from "./slices/auth";
import {companiesSelector, getCompanyDetails, getUserCompanies} from "./slices/companies";
import {getUser, userSelector} from "./slices/user";
import Debugger from "./utils/Debug";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useIsSiteLiveChecker();
  const {
    loginLoading,
    isAuthenticated
  } = useSelector(authSelector);
  const {
    loading: userLoading,
    user
  } = useSelector(userSelector);
  const {id: userId = null} = user;
  const {
    loading: companyLoading,
    loadingDetails,
    company: {id: companyId} = {},
    companies,
  } = useSelector(companiesSelector);
  const [lastIsAuthenticatedState, setLastIsAuthenticatedState] = useState(false);
  const [lastCompanyId, setLastCompanyId] = useState(null);
  const [authenticationChecked, setAuthenticationChecked] = useState(false);
  const [userChecked, setUserChecked] = useState(false);
  const [companyChecked, setCompanyChecked] = useState(false);
  const [companyDetailsChecked, setCompanyDetailsChecked] = useState(false);
  const [urlToNavigateAfterLogin, setUrlToNavigateAfterLogin] = useState(null);
  /* region Authentication checking */
  useEffect(() => {
    if(companyChecked && companyDetailsChecked && userChecked && authenticationChecked && isAuthenticated !== lastIsAuthenticatedState) {
      setAuthenticationChecked(false)
      setUserChecked(typeof userId === 'number')
      setCompanyChecked(companies.length > 0);
      setCompanyDetailsChecked(false)
      setLastIsAuthenticatedState(isAuthenticated)
    }
  }, [
    isAuthenticated,
    lastIsAuthenticatedState,
    companyChecked,
    companyDetailsChecked,
    userChecked,
    authenticationChecked,
    companies,
    userId
  ])
  useEffect(() => {
    if(companyChecked && companyDetailsChecked && userChecked && authenticationChecked && isAuthenticated && lastCompanyId !== companyId) {
      setCompanyDetailsChecked(false)
      setLastCompanyId(companyId)
    }
  }, [
    isAuthenticated,
    lastIsAuthenticatedState,
    companyChecked,
    companyDetailsChecked,
    userChecked,
    authenticationChecked,
    companyId,
    lastCompanyId
  ])
  useEffect(() => {
    if((!authenticationChecked && !loginLoading)) {
      dispatch(refreshUserToken({
        success: () => {
          Debugger.info('🌐 ROUTER', 'Authentication successful')
          setAuthenticationChecked(true)
        },
        failure: () => {
          Debugger.info('🌐 ROUTER', 'Authentication failed')
          setAuthenticationChecked(true)
          setUserChecked(true)
          setCompanyChecked(true);
          setCompanyDetailsChecked(true)
          if(location.pathname !== PAYVY_URL.LOGIN) {
            setUrlToNavigateAfterLogin(location.pathname)
            Debugger.info('🌐 ROUTER', `Saving "${location.pathname}" as URL to navigate after login.`)
          }
          redirect(PAYVY_URL.LOGIN)
        }
      }));
    }
  }, [dispatch, loginLoading, authenticationChecked, navigate, location])
  /* endregion */

  /* region User checking */
  useEffect(() => {
    if((authenticationChecked && !loginLoading && isAuthenticated) && (!userChecked && !userLoading && userId === null)) {
      dispatch(getUser({
        success: () => {
          Debugger.info('🌐 ROUTER', 'User found.')
          setUserChecked(true)
        },
        failure: () => {
          Debugger.info('🌐 ROUTER', 'No user found, redirecting to login page.')
          if(location.pathname !== PAYVY_URL.LOGIN) {
            setUrlToNavigateAfterLogin(location.pathname)
            Debugger.info('🌐 ROUTER', `Saving "${location.pathname}" as URL to navigate after login.`)
          }
          setUserChecked(true)
          redirect(PAYVY_URL.LOGIN)
        }
      }))
    }
  }, [
    dispatch,
    navigate,
    isAuthenticated,
    userChecked,
    userLoading,
    authenticationChecked,
    loginLoading,
    userId,
    location
  ]);
  /* endregion */

  /* region Company checking */
  useEffect(() => {
    if((authenticationChecked && isAuthenticated && !loginLoading) && (userChecked && !userLoading && typeof userId === 'number') && (!companyChecked && !companyLoading && !loadingDetails)) {
      dispatch(getUserCompanies({
        success: () => {
          Debugger.info('🌐 ROUTER', 'Companies found.')
          setCompanyChecked(true)
        },
        failure: () => {
          Debugger.info('🌐 ROUTER', 'No companies found, redirecting to company switch page.')
          setCompanyChecked(true)
        }
      }))
    }
  }, [
    dispatch,
    isAuthenticated,
    userChecked,
    userLoading,
    companyChecked,
    companyLoading,
    authenticationChecked,
    companyId,
    loadingDetails,
    loginLoading,
    userId
  ]);
  useEffect(() => {
    const isInvitedCompanyUserURL = (url) => new RegExp(`^${PAYVY_URL.INVITED_COMPANY_USER.replace('/:hash/', '/[^/]+/')}$`).test(url);
    if(isAuthenticated && companyChecked && !companyDetailsChecked) {
      if(typeof companyId === 'number') {
        Debugger.info('🌐 ROUTER', 'Company details received, checking for company details.')
        dispatch(getCompanyDetails({
          id: companyId,
          success: () => {
            Debugger.info('🌐 ROUTER', 'Company details found.')
            setCompanyDetailsChecked(true)
          },
          failure: () => {
            Debugger.info('🌐 ROUTER', 'No company details found.')
            setCompanyDetailsChecked(true)
            navigate(companies.length > 0 ? PAYVY_URL.COMPANY_SWITCH : PAYVY_URL.COMPANY_CREATE)
          }
        }));
      } else if(!(location.pathname === PAYVY_URL.COMPANY_SWITCH || location.pathname === PAYVY_URL.COMPANY_CREATE || isInvitedCompanyUserURL(location.pathname) || isInvitedCompanyUserURL(urlToNavigateAfterLogin))) {
        Debugger.info('🌐 ROUTER', 'Company list received, but no company is active. Navigating to Switch/Create based on company list.')
        setCompanyDetailsChecked(true)
        navigate(companies.length > 0 ? PAYVY_URL.COMPANY_SWITCH : PAYVY_URL.COMPANY_CREATE)
      } else {
        setCompanyDetailsChecked(true)
      }
    }
  }, [
    dispatch,
    companyChecked,
    companyDetailsChecked,
    companyId,
    location,
    navigate,
    companies,
    isAuthenticated,
    urlToNavigateAfterLogin
  ]);
  useEffect(() => {
    if(isAuthenticated && companyChecked && companyDetailsChecked && urlToNavigateAfterLogin) {
      navigate(urlToNavigateAfterLogin);
      setUrlToNavigateAfterLogin(null);
    }
  }, [isAuthenticated, companyChecked, companyDetailsChecked, urlToNavigateAfterLogin, navigate]);
  if((!authenticationChecked || !userChecked || !companyChecked || !companyDetailsChecked) || (loginLoading || userLoading || loadingDetails || companyLoading)) {
    return <FullPageLoader/>;
  }
  return <Routes>
    {/* region Public Landing URLs */}
    <Route
      path={PAYVY_URL.LANDING_PAGES.INVOICE_PAYMENT}
      element={<InvoiceLandingPage/>}
    />
    {/* endregion Public Landing URLs */}
    {/* region Login, Verifications, Registrations URLs */}
    <Route element={<AnonRoute/>}>
      <Route
        path={PAYVY_URL.REGISTER}
        element={<RegisterAccountPage urlToNavigateAfterLogin={urlToNavigateAfterLogin}/>}
      />
      <Route
        path={PAYVY_URL.LOGIN}
        element={<LoginPage urlToNavigateAfterLogin={urlToNavigateAfterLogin}/>}
      />
      <Route
        path={PAYVY_URL.INVITED_USER}
        element={<InvitedUserLandingPage/>}
      />
      <Route
        path={PAYVY_URL.FORGOTTEN_PASSWORD}
        element={<PasswordResetPage/>}
      />
      <Route
        path={PAYVY_URL.FORGOTTEN_PASSWORD_WITH_HASH}
        element={<PasswordResetLandingPage/>}
      />
      <Route
        path={PAYVY_URL.ACCOUNT_VERIFICATION_WITH_HASH}
        element={<AccountVerifyLandingPage/>}
      />
      <Route element={<UnverifiedAccountRoute/>}>
        <Route
          path={PAYVY_URL.ACCOUNT_VERIFICATION}
          element={<AccountVerificationPage/>}
        />
      </Route>
    </Route>
    {/* endregion Login, Verifications, Registrations URLs */}
    <Route element={<ProtectedRoute/>}>
      <Route element={<SideBarAndTopBarRoute/>}>
        <Route
          path={PAYVY_URL.DASHBOARD}
          element={<DashboardPage/>}
          handle={{payvyName: 'Dashboard'}}
        />
        <Route
          path={PAYVY_URL.LANDING_PAGES.WISE_LINKING}
          element={<WiseLinkingLandingPage/>}
        />
        <Route
          path={PAYVY_URL.LANDING_PAGES.REVOLUT_LINKING}
          element={<RevolutLinkingLandingPage/>}
        />
        <Route
          path={PAYVY_URL.TESTING}
          element={<TestingPage/>}
        />
        <Route
          path={PAYVY_URL.INBOX.RECEIVED}
          element={<AccessCheck company={['inbox.see']} feature={['receive_bills']}><InboxReceivedPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.INBOX.DETAILS}
          element={<AccessCheck company={['inbox.see']} feature={['receive_bills']}><InboxDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.INVOICE.LIST}
          element={<AccessCheck company={['receivables.see']}
                                feature={['receivables']}><InvoiceListPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.INVOICE.CREATE}
          element={<AccessCheck company={['receivables.see']}
                                feature={['receivables']}><InvoiceCreatePage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.INVOICE.EDIT}
          element={<AccessCheck company={['receivables.see']}
                                feature={['receivables']}><InvoiceCreatePage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.INVOICE.DETAILS}
          element={<AccessCheck company={['receivables.see']}
                                feature={['receivables']}><InvoiceDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.DISPUTES.DETAILS}
          element={<AccessCheck company={['receivables.see']}
                                feature={['receivables']}><DisputeDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.BILLS.LIST}
          element={<AccessCheck company={['bill.see']}
                                feature={['bills']}><BillListPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.BILLS.CREATE}
          element={<AccessCheck company={['bill.add']}
                                feature={['bills']}><BillCreatePage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.BILLS.DETAILS}
          element={<AccessCheck company={['bill.see']}
                                feature={['bills']}><BillDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.BILLS.PAY}
          element={<AccessCheck company={['bill.see']}
                                feature={['bills']}><BillPayPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.CONTACTS.LIST}
          element={<AccessCheck company={['contact.see']}
                                feature={['contacts']}><ContactListPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.CONTACTS.CREATE_CUSTOMER}
          element={<AccessCheck company={['contact.add']}
                                feature={['contacts']}><ContactCreateCustomer/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.CONTACTS.DETAILS}
          element={<AccessCheck company={['contact.see']}
                                feature={['contacts']}><ContactDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.TRANSACTION.LIST}
          element={<AccessCheck company={['transaction.see']}
                                feature={['transactions']}><TransactionListPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.ACCOUNT}
          element={<SettingsAccountPage/>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.BILLING}
          element={<AccessCheck company={['administration.company']}><SettingsBillingPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.BANKING}
          element={<AccessCheck company={['administration.company']}><SettingsBankingPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.NOTIFICATIONS}
          element={<SettingsNotificationsPage/>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.INTEGRATIONS}
          element={<AccessCheck company={['administration.integration']}><SettingsIntegrationsPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.INTEGRATION_DETAILS}
          element={<AccessCheck company={['administration.integration']}><SettingsIntegrationDetailPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.TEAM}
          element={<AccessCheck company={['administration.company']}><SettingsTeamPage/></AccessCheck>}
        />
        <Route
          path={PAYVY_URL.SETTINGS.VIRTUAL_BOX}
          element={<AccessCheck company={['administration.company']}><SettingsVirtualBoxPage/></AccessCheck>}
        />
      </Route>
      <Route
        path={PAYVY_URL.INVITED_COMPANY_USER}
        element={<InvitedCompanyUserLandingPage/>}
      />
      <Route element={<MissingCompanyRoute/>}>
        <Route
          path={PAYVY_URL.COMPANY_CREATE}
          element={<CreateCompanyPage/>}
        />
        <Route
          path={PAYVY_URL.COMPANY_SWITCH}
          element={<SwitchCompanyPage/>}
        />
      </Route>
      <Route
        path={PAYVY_URL.INACTIVITY}
        element={<InactivityPage/>}
      />
    </Route>
    <Route
      path="*"
      element={<RedirectToDashboardRoute/>}
    />
  </Routes>
};
export default App;
