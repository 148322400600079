import {addDays} from "date-fns";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyPageHeader, PayvySubmenu, PayvyTable} from '../../comps/elements';
import {CellFormattedDate} from '../../comps/elements/PayvyTable';
import {PayvyDateRangePicker, PayvySearchBar} from '../../comps/forms';
import {PAYVY_URL} from '../../constants';
import {companiesSelector, hasPermission} from '../../slices/companies';
import {
  getUserBills,
  setBillCurrentPage,
  setBillFromDateStr,
  setBillOrderBy,
  setBillPageSize,
  setBillSearchQuery,
  setBillStatus,
  setBillToDateStr,
  setFilterAmountStr,
  setFilterContact
} from "../../slices/newBill";
import {getLedgerInformation} from "../../slices/newIntegration";
import {userSelector} from '../../slices/user';
import {formatDateForAPItoISO, isSameDate} from "../../utils/Utility";
import {CellActions} from "./components/CellActions";
import {CellBillCurrency} from "./components/CellBillCurrency";
import {CellDetailNameAndLedgerInformation} from "./components/CellDetailNameAndLedgerInformation";
import {CellStatus} from "./components/CellStatus";
import {DropdownFilter} from './components/DropdownFilter';
import {ModalCreateOrUpdateBill} from './components/ModalCreateOrUpdateBill';


const BillListPage = () => {
  // region Redux Selectors and setting up sidebar highlight
  const dispatch = useDispatch();
  const {
    displayItems: _displayItems,
    loading: {list: loading},
    numberOfItems,
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery,
  } = useSelector((state) => state.bill);
  const count = numberOfItems[status ? status : 'all'] || 0
  const {
    user: {
      id: userId,
      bill_list_tab: billListTabPreference,
    } = {},
  } = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};
  const fromDate = fromDateStr ? new Date(fromDateStr) : '';
  const toDate = toDateStr ? new Date(toDateStr) : '';
  // endregion

  // region React States
  const displayItems = _displayItems.filter((item) => status === '' || item.status.toLowerCase() === status);
  const [queryRange, setQueryRange] = useState([fromDate, toDate]);
  const [pageCount, setPageCount] = useState(0);
  const billCanUpdatePermission = hasPermission(company, userId, 'bill_can_update');
  const billCanDeletePermission = hasPermission(company, userId, 'bill_can_delete');
  // endregion

  // region Effects
  useEffect(() => {
    dispatch(getLedgerInformation({}))
  }, [dispatch])
  useEffect(() => {
    let fromDate = '';
    let toDate = '';
    if(queryRange?.length === 2) {
      // from date reset minute and seconds to 0 rounding down
      fromDate = formatDateForAPItoISO(queryRange[0], 'start')
      // to date reset minute and seconds to 59 rounding up
      toDate = formatDateForAPItoISO(queryRange[1], 'end')
    }
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDate,
      to: toDate,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status === '' ? 'all' : status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    companyId,
    status,
    currentPage,
    queryRange,
    searchQuery,
    orderBy,
    filterAmountStr,
    filterContact,
    pageSize,
  ]);
  useEffect(() => {
    dispatch(setBillStatus(billListTabPreference || 'draft'))
    dispatch(setBillFromDateStr(addDays(new Date(), -365)
    .toISOString()))
    dispatch(setBillToDateStr(new Date().toISOString()))
    dispatch(setBillCurrentPage(1))
    dispatch(setBillPageSize(10))
    dispatch(setBillOrderBy('-created'))
    dispatch(setFilterAmountStr(''))
    dispatch(setFilterContact(''))
    dispatch(setBillSearchQuery(''))
  }, [dispatch, billListTabPreference])
  useEffect(() => {
    const parseDate = (dateStr) => {
      const date = new Date(dateStr);
      return isNaN(date.getTime()) ? null : date; // If invalid, return null
    };
    const newRange = [parseDate(fromDateStr), parseDate(toDateStr)];
    const currentRange = queryRange || [null, null];
    const isDifferentRange = !isSameDate(currentRange[0], newRange[0]) || !isSameDate(currentRange[1], newRange[1]);
    if(isDifferentRange) setQueryRange(newRange);
  }, [fromDateStr, toDateStr, queryRange]);

  const columns = React.useMemo(() => [
    {
      Header: 'Bill',
      accessor: 'name',
      detailUrl: PAYVY_URL.BILLS.DETAILS,
      Cell: CellDetailNameAndLedgerInformation,
    }, {
      Header: 'Contact',
      accessor: 'contact.name',
    }, {
      Header: 'Bill Date',
      accessor: 'created',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'Due',
      accessor: 'due_date',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'Amount',
      accessor: 'amount',
      Cell: CellBillCurrency,
      sortDescFirst: true,
    }, {
      Header: 'Status',
      accessor: 'status',
      Cell: CellStatus,
      class: 'error',
    }, {
      Header: 'Actions',
      Cell: CellActions,
      canUpdate: billCanUpdatePermission,
      canDelete: billCanDeletePermission,
    },
  ], [billCanUpdatePermission, billCanDeletePermission]);
  // region Callbacks
  const fetchData = React.useCallback(({
    pageSize,
    pageIndex,
  }) => {
    dispatch(setBillCurrentPage(pageIndex + 1));
    dispatch(setBillPageSize(pageSize));
    setPageCount(Math.ceil(count / pageSize));
  }, [dispatch, count]);
  const handleSort = React.useCallback(sortBy => {
    dispatch(setBillOrderBy(sortBy.length > 0 ? sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id : ''));
  }, [dispatch]);
  // endregion
  return <>
    <PayvyPageHeader parents={[{name: 'Payables'}]}>Bills</PayvyPageHeader>
    <PayvySubmenu
      activeItem={status === '' ? 'all' : status.replace(' ', '-')}
      setActiveItem={(value) => {
        const oldStatus = status === '' ? 'all' : status.replace(' ', '-')
        const newVal = value === '' ? 'all' : value.replace('-', ' ');
        if(!loading && oldStatus !== value) dispatch(setBillStatus(newVal))
      }}
      options={[
        {
          name: 'Draft',
          value: 'draft',
        }, {
          name: 'Awaiting Approval',
          value: 'awaiting-approval',
        }, {
          name: 'Awaiting Payment',
          value: 'awaiting-payment',
        }, {
          name: 'Paid',
          value: 'paid',
        }, {
          name: 'All',
          value: 'all',
        },
      ]}
    />
    <div className={'bg-neutral-50 p-4 w-full'}>
      <div className={'flex flex-row gap-x-2'}>
        <div className={'inline-flex grow'}>
          <PayvySearchBar
            loading={loading}
            onSearch={(value) => dispatch(setBillSearchQuery(value))}
            placeholder={'Search table by contact or bill name'}
            autoComplete={'bill-search'}
          />
        </div>
        <div className={'flex flex-row'}>
          <DropdownFilter
            loading={loading}
            activeFilters={!!filterAmountStr || !!filterContact}
            onFilterChange={({
              amount,
              contact,
            }) => {
              dispatch(setFilterAmountStr(amount));
              dispatch(setFilterContact(contact));
            }}/>
          <ModalCreateOrUpdateBill
            buttonText={'Create New Bill'}
          />
        </div>
      </div>
      <div className={'flex flex-row mt-2'}>
        <div className={'w-32'}>
          <PayvyDateRangePicker
            onChange={(value) => {
              const [from = null, to = null] = value || [];
              dispatch(setBillFromDateStr(from?.toISOString()))
              dispatch(setBillToDateStr(to?.toISOString()))
              setQueryRange(value)
            }}
            value={queryRange}
          />
        </div>
      </div>
      <PayvyTable
        columns={columns}
        data={displayItems}
        fetchData={fetchData}
        loading={loading}
        onSort={handleSort}
        count={count}
        pageCount={pageCount}
        defaultPageSize={pageSize}
        currentPage={currentPage - 1}
        pageSize={pageSize}
        sizeOptions={[20, 50, 100]}
        rowProps={({original}) => ({error: !!original.return_code})}
      />
    </div>
  </>;
};

export default BillListPage;
