import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {getQuickbooksClasses} from '../../../slices/newQuickbooks';

export const SelectAccountingClass = ({
  value,
  setField,
  readOnly,
}) => {
  setField = setField || noop;
  const [valueObject, setValueObject] = React.useState(null);
  const dispatch = useDispatch();
  const {
    loading: {list: loading},
    classes,
  } = useSelector((state) => state.integrationQuickbooks);

  useEffect(() => {
    if(!!value) setValueObject(classes.find(account => account.id === value) || {});
  }, [value, classes]);

  useEffect(() => {
    dispatch(getQuickbooksClasses({
      page: 1,
      page_size: 1000,
      show_deleted: false,
    }));
  }, [dispatch]);

  const triggerLocalFieldChange = (option) => {
    setField(option.id);
    setValueObject(option);
  };

  return <>
    <Select
      className={'w-full'}
      placeholder="Select class"
      isLoading={loading}
      isDisabled={readOnly}
      value={valueObject}
      onChange={(option) => triggerLocalFieldChange(option)}
      options={classes}
      menuPlacement={'top'}
    />
  </>;
};
