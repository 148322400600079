import {noop} from "lodash";
import React, {useCallback} from "react";
import {AiOutlineLoading} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {useToasts} from "react-toast-notifications";
import {companiesSelector, updateMemberPermission} from "../../../slices/companies";
import {userSelector} from "../../../slices/user";
import {CompanyPendingMembers} from "./CompanyPendingMembers";
import {ModalChangeOwnership} from "./ModalChangeOwnership";
import {ModalRemoveMember} from "./ModalRemoveMember";

export const CompanyMembers = ({
  company,
  memberList,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const {
    loadingMembers: loading,
    permissionList,
  } = useSelector(companiesSelector);
  const {user} = useSelector(userSelector);
  const processMemberPermissionChange = useCallback((value, id, permissionList, companyId) => {
    let permissionId = 0;
    permissionList.map((item, index) => {
      if(item.name === value) {
        permissionId = index + 1;
      }
      return null;
    });
    dispatch(updateMemberPermission({
      companyId: companyId,
      memberId: id,
      permissionId: permissionId,
      success: () => {
        addToast('Member\'s permission changed successfully.', {appearance: 'success'});
        refreshInfo();
      },
      failure: (error) => {
        addToast(error.toString() || 'Permission change failed.', {appearance: 'error'});
      }
    }));
  }, [dispatch, addToast, refreshInfo]);
  if(loading) return <div className={'w-full h-full relative pt-5'}>
    <AiOutlineLoading className={'animate-spin centered text-center'} size={36}/>
  </div>;
  if(memberList[company.id]) {
    return <table className={'table-auto mt-2 drop-shadow-2xl w-full border border-slate-300'}>
      <thead>
      <tr className={'border border-slate-300 text-center bg-slate-50'}>
        <th colSpan={5} className={'w-12'}>
          <div className={'flex justify-center py-2 shadow-inner'}>
            {company.name}
          </div>
        </th>
      </tr>
      </thead>
      <tbody className={'bg-neutral-0'}>
      {memberList[company.id].map((member, index) => {
        const permissionOptions = permissionList.map((item, index) => ({
          key: index,
          value: item.name,
          label: item.name,
        }))
        const currentUserPermissions = memberList[company.id].find(item => item.id === user.id).permission
        return <tr key={index} className={'hover:bg-red-100 hover-border hover:border-red-300'}>
          <td className={'py-1 px-4 w-16'}>
            <ModalChangeOwnership
              company={company}
              member={member}
              currentUserPermissions={currentUserPermissions}
              refreshInfo={refreshInfo}
            />
          </td>
          <td className={'py-1 px-4'}>@{member.username}</td>
          <td className={'py-1 px-4'}>{member.email}</td>
          <td className={'py-1 px-4 w-64'}>
            <Select
              value={permissionOptions.find(item => item.label = member.permission.name)}
              options={permissionOptions}
              onChange={option => processMemberPermissionChange(option.value, member.id, permissionList, company.id)}
            />
          </td>
          <td className={'py-1 px-4 w-32'}>
            {user.id !== member.id && company.creator.id !== member.id ?
              <ModalRemoveMember
                member={member}
                company={company}
                refreshInfo={refreshInfo}
              /> : ''}
          </td>
        </tr>
      })}
      <CompanyPendingMembers company={company} refreshInfo={refreshInfo}/>
      </tbody>
    </table>;
  } else {
    return <div className={'w-full h-full relative pt-5'}>
      <AiOutlineLoading className={'animate-spin centered text-center'} size={36}/>
    </div>;
  }
};
