import React from 'react';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import {PAYVY_URL} from '../../../constants';
import {build_url} from '../../../utils/Utility';

export function CellDetailLink({
  value,
  row: {original: {bill} = {}}
}) {
  const {id} = bill || {};
  return <Link to={build_url(PAYVY_URL.BILLS.DETAILS, {id})}><Moment date={value} format={'ll'}/></Link>;
}
