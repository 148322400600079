import {useField} from "formik";
import React from 'react';

export const PayvyTextArea = ({
  field,
  form,
  onChange,
  ...props
}) => {
  const [formikField] = useField(props);
  const noStyling = props.noStyling || false;
  const noWidth = props.noWidth || false;
  props.defaultValue = props.value || '';
  delete props.noStyling;
  delete props.noWidth;
  delete props.helpText
  delete props.controlClassName
  delete props.containerClassName
  delete props.loading
  delete props.options
  delete props.maxLength
  delete props.value
  return <textarea
    {...field}
    {...props}
    onChange={(e) => {
      const {value = ''} = e.target.value;
      formikField.onChange(e);
      onChange(value);
    }}
    className={`${props.className} ${noStyling ? '' : 'rounded-md bg-neutral-50'} ${noWidth ? '' : 'w-full'}`}
  />;
};
