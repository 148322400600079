import React from 'react';
import {useNavigate} from "react-router-dom";
import {LedgerIcons} from "../../../comps/elements/LedgerIcons/LedgerIcons";
import {build_url} from "../../../utils/Utility";

export function CellDetailNameAndLedgerInformation({
  value,
  column: {detailUrl},
  row: {original}
}) {
  const {id} = original;
  const navigate = useNavigate();
  const url = build_url(detailUrl, {id})
  const result = []
  if(original.quickbooks_invoice_id) result.push({
    ledger: 'quickbooks',
    ledger_id: original?.quickbooks_invoice_id
  })
  return <span
    onClick={() => {
      navigate(url)
    }}
    className={'cursor-pointer hover:text-slate-600 flex flex-row'}>
    <div>
      {value}
    </div>
    <LedgerIcons ledgerInfo={result}/>
  </span>;
}
