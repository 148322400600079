import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from '../../../slices/auth';
import {userSelector} from '../../../slices/user';

export const LoggedInNotYou = () => {
  const dispatch = useDispatch();
  const {user} = useSelector(userSelector);
  const {
    username = '',
    first_name: firstName,
    last_name: lastName,
  } = user || {};
  const name = firstName && lastName ? `${firstName} ${lastName}` : username;
  return <p className={'mb-5'}>
    Logged in as <b>{name}</b>, <span onClick={() => dispatch(logoutUser())}
                                      className={'text-red-400 font-bold cursor-pointer'}>not you?</span>
  </p>;
};
