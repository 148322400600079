import React, {useState} from 'react';
import {BsFillTrashFill} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {removeBillPage} from '../../../slices/newBill';
import {payvyToast} from "../../../utils/Utility";

export const ModalRemovePage = ({
  billId,
  page,
  success,
  noExtraStyles = false,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {loading: {deleting: processing}} = useSelector((state) => state.bill);
  const confirmRemoveBillPage = () => {
    dispatch(removeBillPage({
      id: billId,
      page: page.id,
      successCallback: () => {
        success();
        setModalOpen(false);
        payvyToast('Page removed successfully.', {appearance: 'success'});
      },
      errorCallback: (error) => {
        if('message' in error) payvyToast(error.message || 'Page removal failed.', {appearance: 'error'});
      }
    }));
  };
  return <>
    <PayvyIconButton
      Icon={BsFillTrashFill}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Page'}
      mainColor={noExtraStyles ? 'none' : undefined}
      type={noExtraStyles ? 'a' : undefined}
      iconSize={noExtraStyles ? 16 : undefined}
      iconPaddingX={noExtraStyles ? 0.5 : undefined}
      iconPaddingY={noExtraStyles ? 0.5 : undefined}
      borderColor={noExtraStyles ? 'none' : undefined}
      iconColor={noExtraStyles ? 'neutral-500' : undefined}
      hoverTextColor={noExtraStyles ? 'neutral-700' : undefined}
    />
    <ModalPayvyBase
      title={`Removing "Page ${page.page_number}"`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, remove page'}
      confirmButtonAction={confirmRemoveBillPage}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to remove <b>"Page {page.page_number}"</b>?</p>
    </ModalPayvyBase>
  </>;
};
