import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {NumericFormat} from 'react-number-format';

export const TableInvoice = ({invoice}) => {
  const tdBody = 'p-2 bg-neutral-0 border border-slate-400 text-center'
  const tdHeader = `${tdBody} text-left bg-slate-200`
  const thHeader = 'text-center border border-slate-400 text-center bg-slate-200 p-2'
  const thHeaderFirst = `border border-slate-400 text-center bg-slate-200 w-2/3 text-left px-4 py-2`
  return <div className={'flex flex-col'}>
    <table className={'table-auto mt-2 w-full border border-slate-400'}>
      <thead>
      <tr>
        <th className={thHeaderFirst}>Item details</th>
        <th className={thHeader}>Qty</th>
        <th className={thHeader}>Rate</th>
        <th className={thHeader}>Amount</th>
      </tr>
      </thead>
      <tbody>
      {invoice.items.map((item, index) => (
        <tr key={item.id} className={`${invoice.items.length - 1 === index ? '' : 'border-b border-slate-400'}`}>
          <td className={'p-2'}>{item.description}</td>
          <td className={'p-2 text-center'}>{item.quantity}</td>
          <td className={'p-2 text-center'}>
            <CurrencyFormat value={item.rate / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                            decimalScale={2} fixedDecimalScale={true}/>
          </td>
          <td className={'p-2 text-center'}>
            <CurrencyFormat value={item.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                            decimalScale={2} fixedDecimalScale={true}/>
          </td>
        </tr>))}
      </tbody>
    </table>
    <table className={'w-2/3 lg:w-128 self-end'}>
      <tbody>
      <tr>
        <td className={`${tdHeader} border-t-0`}>Subtotal</td>
        <td className={`${tdBody} border-t-0`}>
          <CurrencyFormat value={invoice.subtotal / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                          decimalScale={2} fixedDecimalScale={true}/>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Discount</td>
        <td className={tdBody}>{invoice.discount_type === 'amount' ?
          <CurrencyFormat value={invoice.discount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                          decimalScale={2} fixedDecimalScale={true}/> :
          <NumericFormat
            suffix={'%'}
            displayType={'text'}
            decimalScale={2}
            value={invoice.discount}
          />}</td>
      </tr>
      <tr>
        <td className={tdHeader}>Shipping</td>
        <td className={tdBody}>
          <CurrencyFormat value={invoice.shipping / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                          decimalScale={2} fixedDecimalScale={true}/>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Adjustment</td>
        <td className={tdBody}>
          <CurrencyFormat value={invoice.adjustment / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                          decimalScale={2} fixedDecimalScale={true}/>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Total</td>
        <td className={tdBody}>
          <CurrencyFormat value={invoice.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'$'}
                          decimalScale={2} fixedDecimalScale={true}/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>;
};
