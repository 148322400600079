import {Form, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledCheckbox,
  PayvyLabeledInput,
  PayvySelectComponent,
  PayvyTextArea
} from '../../../comps/forms/';
import {
  BUSINESS_TYPES,
  COMPANY_BUSINESS_STRUCTURES,
  FORM_ERROR_MESSAGES,
  GOVERNMENT_ENTITY_BUSINESS_STRUCTURES,
  MERCHANT_CATEGORY_CODES,
  NON_PROFIT_BUSINESS_STRUCTURES,
} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, getCompanyDetails, saveCompanyInformation} from '../../../slices/companies';
import {dwollaSelector, getDwollaClassifications} from '../../../slices/dwolla';
import {build_error_message, formatDateForAPItoYMD, handleErrors} from '../../../utils/Utility';
import {ButtonLogoUpload} from './ButtonLogoUpload';
import {CompanyAddress} from './CompanyAddress';
import {ModalVerificationDwollaDocument,} from './ModalVerificationDwollaDocument';
import {OwnerVerification} from './OwnerVerification';
import {StripeVerificationButton} from './StripeVerificationButton';

// TODO: Add functionality to the checkbox to certify ownership of the business
export const FormCompany = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [businessStructureDisabled, setBusinessStructureDisabled] = useState(true);
  const [businessStructureOptions, setBusinessStructureOptions] = useState(COMPANY_BUSINESS_STRUCTURES);
  const {
    loading,
    processing,
    company: {
      id,
      brand_logo: brandLogo,
      business_type: businessType,
      ein_ssn: einSsn,
      legal_name: legalName,
      name,
      payvy_address: payvyAddress,
      description,
      website,
      industry,
      structure,
      incorporation_date,
      business_phone: businessPhone,
      business_classification: businessClassification,
      dwolla_status: dwollaStatus,
      annual_card_volume: annualCardVolume,
      max_transaction_amount: maxTransactionAmount,
      default_statement_descriptor: defaultStatementDescriptor,
    },
  } = useSelector(companiesSelector);
  const {
    loading: dwollaLoading,
    classifications
  } = useSelector(dwollaSelector);
  let hasEinSsn = !!einSsn;

  const businessTypeChange = React.useCallback(value => {
    if(value === 'individual') {
      setBusinessStructureDisabled(true);
    } else {
      if(value === 'company') {
        setBusinessStructureOptions(COMPANY_BUSINESS_STRUCTURES);
      }
      if(value === 'non_profit') {
        setBusinessStructureOptions(NON_PROFIT_BUSINESS_STRUCTURES);
      }
      if(value === 'government_entity') {
        setBusinessStructureOptions(GOVERNMENT_ENTITY_BUSINESS_STRUCTURES);
      }
      setBusinessStructureDisabled(false);
    }
  }, []);

  useEffect(() => {
    businessTypeChange(businessType);
  }, [businessTypeChange, businessType]);
  useEffect(() => {
    if(!dwollaLoading && classifications.length === 0) dispatch(getDwollaClassifications());
  }, [dispatch, id, dwollaLoading, classifications]);

  return <div className={'flex flex-col w-full xl:w-6/12'}>
    <h1 className={'flex pt-3 mx-3 w-11/12 font-light text-xl text-neutral-700 border-b border-neutral-700'}>Company
      Settings</h1>
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        legalName: legalName || '',
        displayName: name || '',
        businessType: businessType || '',
        payvyAddress: payvyAddress || '',
        einSsn: '',
        incorporationDate: incorporation_date || '',
        businessPhone: businessPhone || '',
        businessStructure: structure || '',
        industry: industry || '',
        businessClassification: businessClassification || '',
        displayDescription: false,
        website: website || '',
        description: description || '',
        annualCardVolume: annualCardVolume / 100 || 0,
        maxTransactionAmount: maxTransactionAmount / 100 || 0,
        defaultStatementDescriptor: defaultStatementDescriptor || '',
        certifyCorrectInformation: false,
      }}
      validationSchema={Yup.object({
        legalName: Yup.string()
                      .required(),
        displayName: Yup.string()
                        .required(),
        businessType: Yup.string()
                         .required(),
        payvyAddress: Yup.string()
                         .required(),
        einSsn: Yup.string()
                   .when([], {
                     is: () => !hasEinSsn,
                     then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                   }),
        businessStructure: Yup.string()
                              .when(['businessType'], {
                                is: (businessType = '') => businessType !== 'individual',
                                then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                              }),
        industry: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        businessClassification: Yup.string()
                                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        website: Yup.string()
                    .max(200, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 200}))
                    .when(['displayDescription'], {
                      is: (displayDescription) => !displayDescription,
                      then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                    }),
        description: Yup.string()
                        .when(['displayDescription'], {
                          is: (displayDescription) => displayDescription,
                          then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                        }),
        incorporationDate: Yup.string()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        businessPhone: Yup.string()
                          .nullable()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                          .test('is-valid-phone', 'Invalid phone number', value => (!value || (!!value && isPossiblePhoneNumber(value)))),
      })}
      onSubmit={(values, helpers) => {
        let date = ''
        try {
          date = formatDateForAPItoYMD(values.incorporationDate);
        } catch {
          date = '';
        }
        dispatch(saveCompanyInformation({
          legal_name: values.legalName,
          display_name: values.displayName,
          business_type: values.businessType,
          payvy_address: values.payvyAddress,
          ein_ssn: values.einSsn === '' ? undefined : values.einSsn,
          structure: values.businessStructure,
          industry: values.industry,
          business_classification: values.businessClassification,
          business_phone: values.businessPhone,
          incorporation_date: date,
          website: values.displayDescription ? '' : values.website,
          description: values.displayDescription ? values.description : '',
          annual_card_volume: values.annualCardVolume,
          max_transaction_amount: values.maxTransactionAmount,
          default_statement_descriptor: values.defaultStatementDescriptor,
          success: () => {
            dispatch(getCompanyDetails({id}));
            addToast('Saved company information.', {appearance: 'success'});
          },
          failure: (data) => {
            handleErrors(data, helpers);
          }
        }));
      }}
    >
      {props => <Form onSubmit={props.handleSubmit} className={'mx-3 flex flex-col gap-1 w-11/12'}>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Business Type'}
              name={'businessType'}
              component={PayvySelectComponent}
              options={BUSINESS_TYPES}
              onChange={(value) => {
                const previousState = props.values.businessType;
                props.setFieldValue('businessType', value);
                if(previousState !== value) props.setFieldValue('businessStructure', '');
                businessTypeChange(value);
              }}
              disabled={!!businessType}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Business Structure'}
              name={'businessStructure'}
              component={PayvySelectComponent}
              options={businessStructureOptions}
              disabled={businessStructureDisabled}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Business Legal Name'}
              name={'legalName'}
              as={PayvyInput}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Business Display Name'}
              name={'displayName'}
              as={PayvyInput}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Payvy Email Address'}
              name={'payvyAddress'}
              appendRight={'@payvy.com'}
              as={PayvyInput}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Incorporation Date'}
              name={'incorporationDate'}
            >
              <PayvyDatePicker
                value={props.values.incorporationDate}
                onChange={(value) => props.setFieldValue('incorporationDate', value)}
                clearIcon={null}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            {!hasEinSsn ? <PayvyLabeledInput
              label={'EIN / SSN'}
              name={'einSsn'}
              as={PayvyInput}
            /> : <div className="py-1 w-full">
              <label>
                <span className="block text-left text-xs pl-1 pt-1">EIN / SSN</span>
                <div className="flex flex-row">
                  <input
                    readOnly={true}
                    disabled={true}
                    className="my-0.5 px-1 py-2.5 bg-neutral-50 cursor-not-allowed pointer-events-none rounded-md w-full border border-neutral-500"
                    type="text"
                    value={`●●● ●● ${einSsn.substring(einSsn.length - 4)}`}
                  />
                </div>
              </label>
            </div>}
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Industry'}
              name={'industry'}
              component={PayvySelectComponent}
              options={MERCHANT_CATEGORY_CODES}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full'}>
            <PayvyLabeledInput
              label={'IRS Business Classification'}
              name={'businessClassification'}
              component={PayvySelectComponent}
              options={classifications}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Annual Card Volume'}
              name={'annualCardVolume'}
            >
              <CurrencyFormat
                placeholder={'$0.00'}
                value={props.values.annualCardVolume}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={({value}) => props.setFieldValue('annualCardVolume', value)}
                className={'my-0.5 px-1 py-2.5 rounded-md w-full border border-neutral-500'}
              />
            </PayvyLabeledInput>
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Maximum Transaction Amount'}
              name={'maxTransactionAmount'}
            >
              <CurrencyFormat
                placeholder={'$0.00'}
                value={props.values.maxTransactionAmount}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={({value}) => props.setFieldValue('maxTransactionAmount', value)}
                className={'my-0.5 px-1 py-2.5 rounded-md w-full border border-neutral-500'}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Default Statement Descriptor'}
              name={'defaultStatementDescriptor'}
              as={PayvyInput}
              maxLength={14}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Business Phone'}
              name={'businessPhone'}
            >
              <PhoneInput
                international={true}
                defaultCountry="US"
                placeholder="Enter business's phone number"
                value={props.values.businessPhone}
                onChange={(value) => props.setFieldValue('businessPhone', value)}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-3/12'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Company Logo</span>
              <div className={'w-24 h-24 flex border border-neutral-500 rounded-md centered'}>
                {!!brandLogo && <img alt={'company logo'} src={brandLogo}/>}
              </div>
              <ButtonLogoUpload companyId={id} hasLogo={!!brandLogo} processing={processing}
                                loading={loading}/>
            </label>
          </div>
          <div className={'flex-inline w-full md:w-9/12'}>
            {!props.values.displayDescription ?
              <PayvyLabeledInput
                label={'Website'}
                name={'website'}
                type={'url'}
                as={PayvyInput}
              /> :
              <PayvyLabeledInput
                label={'Description'}
                name={'description'}
                as={PayvyTextArea}
              />}
            <PayvyIconButton
              buttonText={props.values.displayDescription ? 'I have a website' : 'I don\'t have a website'}
              onClick={() => props.setFieldValue('displayDescription', !props.values.displayDescription)}
              type={'button'}
              iconPaddingX={0}
              iconPaddingY={0}
              mainColor={'none'}
              textColor={'slate-900'}
              hoverTextColor={'slate-700'}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2 my-6'}>
          <div className={'flex-inline w-full'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Company Address</span>
              <CompanyAddress loading={loading} processing={loading}/>
            </label>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2 my-6'}>
          <div className={'flex-inline w-full'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Owner Verification</span>
              <OwnerVerification companyId={id} loading={loading} processing={loading}/>
            </label>
          </div>
        </div>
        {dwollaStatus === 'document' && <div className={'flex flex-col md:flex-row gap-2 my-6'}>
          <div className={'flex-inline w-full'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Verification required</span>
              <div className={'flex flex-col ml-2 py-1'}>
                <p>Please upload a document that verifies your company account.</p>
                <p>It can take up to 1-2 workdays to verify your company account.</p>
              </div>
              <ModalVerificationDwollaDocument companyId={id} big={true}/>
            </label>
          </div>
        </div>}
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline flex-row flex-nowrap w-full'}>
            <PayvyLabeledCheckbox name={'certifyCorrectInformation'}>
              <span
                className={'text-xs'}>I hereby certify that the information provided for {props.values.legalName} Controller and Beneficial Owners is complete and correct.</span>
            </PayvyLabeledCheckbox>
          </div>
        </div>
        <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        <div className={'flex flex-row gap-2 justify-end mt-2'}>
          <StripeVerificationButton formik={props}/>
          <PayvyIconButton
            buttonText={'Save Company Settings'}
            iconPaddingY={3}
            iconPaddingX={6}
            disabled={loading || processing}
            loading={loading || processing}
            onClick={props.handleSubmit}
          />
        </div>
      </Form>}
    </Formik>
  </div>;
};
