import moment from 'moment';
import React from 'react';
import {AiOutlineFileAdd} from 'react-icons/ai';
import {BiListMinus, BiListPlus} from 'react-icons/bi';
import {CgReorder} from 'react-icons/cg';
import {
  FaBan,
  FaCommentDots,
  FaEnvelope,
  FaEnvelopeSquare,
  FaHistory,
  FaLevelDownAlt,
  FaLevelUpAlt,
  FaMoneyBillAlt,
  FaPhone,
  FaSms,
} from 'react-icons/fa';
import {FiMove} from 'react-icons/fi';
import {SiQuickbooks} from 'react-icons/si';
import {NumericFormat} from 'react-number-format';
import {camelCaseToHyphens} from '../../../utils/Utility';
import {cleanCommentFromMentions} from "../utils/CleanCommentFromMentions";
import {getActionName} from "../utils/GetActionName";
import './HistoryItem.scss';

export const HistoryItem = ({historyItem}) => {
  const displayMessage = ({
    action,
    old_status,
    new_status,
    comment,
  }) => {
    const actionName = getActionName(action);
    switch(actionName) {
      case 'changedStatus':
        return `Changed status ${old_status} to ${new_status}`;
      case 'movedAPage':
        return 'Moved a page';
      case 'removedAPage':
        return 'Removed a page';
      case 'reorderedBill':
        return 'Reordered a page';
      case 'created':
        return 'Invoice created';
      case 'addedAPage':
        return 'Added a page';
      case 'paid':
        return <>paid <NumericFormat value={comment} displayType={'text'} prefix={'$'} decimalScale={2}
                                     thousandSeparator fixedDecimalScale/></>;
      case 'paymentIssue':
        let amount = 0;
        let reason = 'No reason was given.';
        try {
          comment = JSON.parse(comment);
          amount = comment.amount;
          reason = comment.reason;
        } catch(e) {
        }
        return <>Payment for <NumericFormat value={amount} displayType={'text'} prefix={'$'} decimalScale={2}
                                            thousandSeparator fixedDecimalScale/> has failed. Reason: {reason}</>;
      case 'commented':
      case 'commentedViaSms':
      case 'commentedViaEmail':
      case 'commentedViaSmsAndEmail':
        return cleanCommentFromMentions(comment);
      case 'syncedFromQuickbooks':
        return `Synced to QuickBooks with TxnID ${comment}`;
      case 'calledViaPhone':
        return `Phone call!`;
      default:
        return historyItem.action;
    }
  };
  const displaySender = ({
    action,
    sender,
  }) => {
    const actionName = getActionName(action);
    switch(actionName) {
      case 'syncedFromQuickbooks':
        return 'QuickBooks';
      case 'paymentIssue':
        return 'Payvy';
      default:
        return sender ? sender.first_name : 'Unknown';
    }
  };
  const displayIcon = ({
    action,
    old_status_id,
    new_status_id,
  }) => {
    const actionName = getActionName(action);
    switch(actionName) {
      case 'reorderedBill':
        return CgReorder;
      case 'commented':
        return FaCommentDots;
      case 'commentedViaSms':
        return FaSms;
      case 'commentedViaEmail':
        return FaEnvelope;
      case 'commentedViaSmsAndEmail':
        return FaEnvelopeSquare;
      case 'calledViaPhone':
        return FaPhone;
      case 'paid':
        return FaMoneyBillAlt;
      case 'paymentIssue':
        return FaBan;
      case 'changedStatus':
        if(new_status_id > old_status_id) {
          return FaLevelUpAlt;
        } else {
          return FaLevelDownAlt;
        }
      case 'movedAPage':
        return FiMove;
      case 'removedAPage':
        return BiListMinus;
      case 'created':
        return AiOutlineFileAdd;
      case 'addedAPage':
        return BiListPlus;
      case 'syncedFromQuickbooks':
        return SiQuickbooks;
      default:
        return FaHistory;
    }
  };
  const Icon = displayIcon(historyItem);
  return <div className={`flex flex-column my-4 history-item ${camelCaseToHyphens(getActionName(historyItem.action))}`}>
    <div className={'self-center px-3'}>
      <Icon size={36} className={getActionName(historyItem.action)}/>
    </div>
    <div className={'flex-row'}>
      <div className={'text-sm text-neutral-700'}>{moment(historyItem.date)
      .format('h:mm A')} - <span className={'text-base font-bold text-neutral-800'}>{displaySender(historyItem)}</span>
      </div>
      <div>{displayMessage(historyItem)}</div>
    </div>
  </div>;
};
