export function CellSender({
  row: {
    original: {
      mail_content,
      uploaded_by
    } = {}
  }
}) {
  const {sender = null} = mail_content || {};
  const {
    username = null,
    first_name = null,
    last_name = null
  } = uploaded_by || {};
  if(sender) {
    return sender;
  }
  if(first_name && last_name) {
    return `${first_name} ${last_name}`;
  }
  if(username) {
    return username;
  }
}
