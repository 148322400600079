import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {AiOutlineLoading} from 'react-icons/ai';
import {HiRefresh} from 'react-icons/hi';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {getBillHistory} from '../../../slices/newBill';
import {HistoryComment} from './HistoryComment';
import {HistoryItem} from './HistoryItem';

export const History = ({bill}) => {
  const dispatch = useDispatch();
  const {
    loading: {history: loading},
    history,
    historyNumberOfItems,
  } = useSelector((state) => state.bill);
  const billHistory = history[bill.id] || [];
  const billHistoryNumberOfItems = historyNumberOfItems[bill.id] || 0
  const [currentPage, setCurrentPage] = useState(1);
  let previousDate = '';
  useEffect(() => {
    dispatch(getBillHistory({
      id: bill.id,
      page: currentPage,
      page_size: 10
    }));
  }, [dispatch, bill, currentPage]);
  const historyHasNext = billHistory.length < billHistoryNumberOfItems;
  return <div className={`payvy-bill-history ${loading ? 'm-0 overflow-hidden overflow-y-hidden' : 'overflow-y-auto'}`}>
    {billHistory.length === 0 && loading && <div className={'w-full h-full relative pt-5'}>
      <AiOutlineLoading className={'animate-spin centered text-center'} size={36}/>
    </div>}
    <HistoryComment bill={bill}/>
    <div className={'payvy-history'}>
      {loading && <AiOutlineLoading className={'animate-spin centered text-center py-2'} size={36}/>}
      {billHistory.map((history, index) => {
        const currentDate = moment(history.date)
        .format('YYYY-MM-DD');
        const label_name = moment(currentDate)
        .calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'DD/MM/YYYY',
        });
        if(currentDate !== previousDate) {
          previousDate = currentDate;
          return <React.Fragment key={index}>
            <div className={'new-day my-1 text-center mb-2'}>
              <div className={'w-auto inline-block centered px-2 py-1 bg-slate-200 rounded-lg'}>{label_name}</div>
              <div className={'border-b border-neutral-500 -mt-4'}/>
            </div>
            <HistoryItem historyItem={history}/>
          </React.Fragment>;
        } else {
          return <HistoryItem key={index} historyItem={history}/>;
        }
      })}
      {historyHasNext ? <div className={'block text-center my-1'}>
        <PayvyIconButton
          Icon={HiRefresh}
          loading={loading}
          disabled={loading}
          onClick={() => setCurrentPage(currentPage + 1)}
          buttonText={'Load more'}
        />
      </div> : null}
    </div>
  </div>;
};
