import {noop} from 'lodash';
import Mousetrap from 'mousetrap';
import React, {useEffect} from 'react';
import {AiOutlineLoading} from 'react-icons/ai';
import './PayvyTooltip.scss';
import {Tooltip} from "react-tooltip";

const ButtonWithIcon = ({
  Icon = null,
  iconRight = false,
  iconColor = 'white',
  iconSize = 16,
  onClick = noop,
  type = 'button',
  disabled = false,
  loading = false,
  centered = false,
  buttonText = '',
  mainColor = 'red-200',
  hoverColor = 'red-300',
  borderColor = 'neutral-100',
  textColor = 'neutral-0',
  hoverTextColor = 'neutral-0',
  borderSize = 1,
  iconPaddingX = 1,
  iconPaddingY = 2,
  buttonMarginX = 0.5,
  roundedLeft = 'md',
  roundedRight = 'md',
  cursor = 'pointer',
  href = undefined,
  fullWidth = false,
  tooltipFor = '',
  allowPointerEvents = true,
  tooltipContent = '',
  target = '_blank',
  shortcutKey = undefined,
}) => {
  let iconPart = Icon === null ? null : <span className={`
        flex items-center rounded-md h-full
        py-${iconPaddingY} pl-${iconPaddingX} ${(buttonText === '' || iconRight) && `px-${iconPaddingX}`}
        `}>
        {loading ?
          <AiOutlineLoading className={`animate-spin flex-initial text-${iconColor} hover:text-${hoverTextColor}`}
                            color={iconColor} size={iconSize}/> :
          <Icon className={`flex-initial text-${iconColor} hover:text-${hoverTextColor}`} color={iconColor}
                size={iconSize}/>}
    </span>;
  if(loading && Icon === null) iconPart = <span
    className={`flex items-center rounded-md py-${iconPaddingY} px-${iconPaddingX}`}
  >
      <AiOutlineLoading className={`animate-spin flex-initial text-${iconColor} hover:text-${hoverTextColor}`}
                        color={iconColor} size={iconSize}/>
    </span>;

  const CustomButtonHTMLElement = href ? 'a' : 'button';
  useEffect(() => {
    if(shortcutKey) Mousetrap.bind(shortcutKey, (e) => {
      e.preventDefault();
      onClick();
    });
  }, [shortcutKey, onClick]);

  return <CustomButtonHTMLElement
    onClick={() => onClick()}
    href={href}
    target={target}
    type={type}
    disabled={disabled || loading}
    data-tip
    data-for={tooltipFor}
    data-tooltip-content={tooltipContent || buttonText}
    className={`
        ${allowPointerEvents ? 'pointer-events-auto' : 'pointer-events-none'}
        ${tooltipFor}
        inline-flex
        rounded-l-${roundedLeft} rounded-r-${roundedRight}
        mx-${buttonMarginX}
        transition ease-in-out duration-750
        cursor-${cursor}
        ${mainColor !== 'none' ? `bg-${mainColor} hover:bg-${hoverColor}` : ''}
        ${borderColor !== 'none' ? `border-${borderColor} border-${borderSize}` : ''}
        text-${textColor} hover:text-${hoverTextColor} text-sm text-normal
        ${disabled ? 'opacity-50 cursor-not-allowed' : ''} 
        ${loading ? 'cursor-wait' : ''}
        ${centered ? 'centered' : ''}
        ${fullWidth ? 'w-full justify-center' : ''}
    `}>
    {!iconRight ? iconPart : null}
    {buttonText ? <span
      className={`py-${iconPaddingY} px-${iconPaddingX} flex items-center text-sm text-normal`}>
            {buttonText}
        </span> : null}
    {iconRight ? iconPart : null}
  </CustomButtonHTMLElement>;
};

export const PayvyIconButton = ({
  Icon = null,
  iconRight = false,
  iconColor = 'white',
  iconSize = 16,
  iconPaddingX = 2,
  iconPaddingY = 2,
  buttonMarginX = 0.5,
  roundedLeft = 'md',
  roundedRight = 'md',
  cursor = 'pointer',
  onClick = noop,
  type = 'button',
  disabled = false,
  loading = false,
  centered = false,
  buttonText = '',
  tooltipContent = '',
  tooltipPlace = 'top',
  mainColor = 'slate-900 bg-slate-900',
  hoverColor = 'slate-600 hover:bg-slate-600',
  textColor = 'neutral-0',
  hoverTextColor = 'neutral-0',
  borderColor = 'neutral-100',
  borderSize = 1,
  wrapperClassName = '',
  href = undefined,
  fullWidth = false,
  tooltipFor = '',
  allowPointerEvents = true,
  target = '_blank',
  shortcutKey = undefined,
}) => {
  const uniqueTooltipID = tooltipFor ? tooltipFor : `payvy-tooltip-${Math.floor(Math.random() * 1000000)}`;
  const button = <ButtonWithIcon
    Icon={Icon}
    iconRight={iconRight}
    iconSize={iconSize}
    iconColor={iconColor}
    iconPaddingX={iconPaddingX}
    iconPaddingY={iconPaddingY}
    onClick={onClick}
    type={type}
    disabled={disabled}
    loading={loading}
    centered={centered}
    buttonText={buttonText}
    mainColor={mainColor}
    hoverColor={hoverColor}
    hoverTextColor={hoverTextColor}
    textColor={textColor}
    borderColor={borderColor}
    borderSize={borderSize}
    href={href}
    target={target}
    cursor={cursor}
    buttonMarginX={buttonMarginX}
    roundedLeft={roundedLeft}
    roundedRight={roundedRight}
    tooltipContent={tooltipContent}
    fullWidth={fullWidth}
    tooltipFor={uniqueTooltipID}
    allowPointerEvents={allowPointerEvents}
    shortcutKey={shortcutKey}
  />;
  if(!tooltipContent) return <div className={`inline-flex ${wrapperClassName}`}>{button}</div>;
  return <div className={`inline-flex ${wrapperClassName}`}>
    {button}
    <Tooltip place={tooltipPlace} anchorSelect={`.${uniqueTooltipID}`} type="dark" effect="solid">
      {tooltipContent}
    </Tooltip>
  </div>;
};
