import React from 'react';
import {Link} from 'react-router-dom';
import {PAYVY_LOGOS, PAYVY_URL} from '../../constants';
import {FormRegistration} from './components/FormRegistration';


const RegisterAccountPage = ({urlToNavigateAfterLogin}) => {
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <h1 className={'flex text-xl mb-2 font-bold justify-center'}>Create your account</h1>
        <FormRegistration urlToNavigateAfterLogin={urlToNavigateAfterLogin}/>
        <div className={'text-neutral-700 flex flex-row justify-between mt-10'}>
          <div className={'flex'}>
            Have an account?
            <Link
              to={PAYVY_URL.LOGIN}
              className={'text-red-300 hover:text-red-400 pl-1'}
            >
              Sign In
            </Link>
          </div>
          <div className={'flex'}>
            Forgot your password?
            <Link
              to={PAYVY_URL.FORGOTTEN_PASSWORD}
              className={'text-red-300 hover:text-red-400 pl-1'}
            >
              Reset here
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default RegisterAccountPage;
