import {noop} from 'lodash';
import React from 'react';
import {ModalSyncedBankAccountRemove} from "./ModalSyncedBankAccountRemove";

export function CellSyncedActions({
  column: {RefreshInfo},
  row: {original}
}) {
  RefreshInfo = RefreshInfo || noop;
  if(original.active) return <ModalSyncedBankAccountRemove bankAccount={original}
                                                           refreshInfo={RefreshInfo}/>;
  return '';
}
