import React from 'react';
import {PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {ActiveSubscriptions} from "./components/ActiveSubscriptions";
import {BillingInvoices} from "./components/BillingInvoices";
import {BillingPaymentMethod} from "./components/BillingPaymentMethod";

const SettingsBillingPage = () => {
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Billing</PayvyPageHeader>
    <SettingsMenu active="billing"/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2'}>
      <ActiveSubscriptions/>
      <BillingPaymentMethod/>
      <BillingInvoices/>
    </div>
  </>;
};

export default SettingsBillingPage;
