import {Form, Formik} from "formik";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import * as Yup from "yup";
import {PayvyGlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput} from "../../../comps/forms";
import {ModalPayvyBase} from "../../../comps/modals";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, getMembersList, inviteTeamMember} from "../../../slices/companies";
import {handleErrors} from "../../../utils/Utility";
import {SelectCompany} from "./SelectCompany";
import {SelectRole} from "./SelectRole";

export const ModalInviteMember = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const submitForm = React.createRef();
  const {
    loadingMembers: loading,
    processing,
  } = useSelector(companiesSelector);
  const [modalOpen, setModalOpen] = useState(false);

  return <>
    <PayvyIconButton
      buttonText={'Invite Team Member'}
      onClick={() => setModalOpen(true)}
      wrapperClassName={'w-full justify-end'}
    />
    <ModalPayvyBase
      title={`Inviting Team Member`}
      isOpen={modalOpen}
      confirmButtonText={'Add Member'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          email: '',
          role: 0,
          company: 0,
        }}
        validationSchema={Yup.object({
          email: Yup.string()
                    .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL)
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          role: Yup.number()
                   .integer()
                   .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          company: Yup.number()
                      .integer()
                      .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          dispatch(inviteTeamMember({
            companyId: values.company,
            email: values.email,
            permissionId: values.role,
            success: () => {
              dispatch(getMembersList({id: values.company}));
              setModalOpen(false);
              addToast('Member invited', {appearance: 'success'});
            },
            failure: (data) => {
              handleErrors(data, helpers);
            }
          }));
        }}
      >
        {props => <Form>
          <PayvyGlobalFormError/>
          <PayvyLabeledInput
            label={'Email address'}
            as={PayvyInput}
            type={'email'}
            name={'email'}
          />
          <PayvyLabeledInput
            label={'Role'}
            name={'role'}
            alwaysLabel={true}
          >
            <SelectRole
              setField={value => props.setFieldValue('role', value)}
              value={props.values.role}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Company'}
            name={'company'}
            alwaysLabel={true}
          >
            <SelectCompany
              setValue={value => props.setFieldValue('company', value)}
              value={props.values.company}
            />
          </PayvyLabeledInput>
          <button ref={submitForm} type="submit" className={'hidden'}>Submit
          </button>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
