import _ from 'lodash';
import React from 'react';
import {useSelector} from "react-redux";
import {usePermissions} from "../../../hooks/usePermissions";
import {companiesSelector} from "../../../slices/companies";
import Debugger from "../../../utils/Debug";
import {setAccessValue} from "../../../utils/Utility";
import FullPageAccountHasNotEnoughPermissions from "./components/FullPageAccountHasNotEnoughPermissions";
import FullPageSubscriptionRequiredForFeature from "./components/FullPageSubscriptionRequiredForFeature";

export const AccessCheck = ({
  children,
  company = [],
  feature = [],
}) => {
  const permissions = usePermissions();
  const {featureAccess} = useSelector(companiesSelector);
  let allowedCompanyAccess = true;
  if(company.length > 0) {
    allowedCompanyAccess = undefined;
    for(let i = 0; i < company.length; i++)
      allowedCompanyAccess = setAccessValue(allowedCompanyAccess, _.get(permissions, company[i], false));
  }
  let allowedFeatureAccess = true;
  if(feature.length > 0) {
    allowedFeatureAccess = undefined;
    for(let i = 0; i < feature.length; i++) {
      if(featureAccess) allowedFeatureAccess = setAccessValue(allowedFeatureAccess, featureAccess.includes(feature[i]));
    }
  }
  if(allowedCompanyAccess !== true) {
    Debugger.debug('🌐 ROUTER', 'Missing permissions, access blocked.');
    return <FullPageAccountHasNotEnoughPermissions companyAccess={company}/>;
  } else if(allowedFeatureAccess !== true) {
    Debugger.debug('🌐 ROUTER', 'Missing subscription, access blocked.');
    return <FullPageSubscriptionRequiredForFeature/>;
  }
  return <>{children}</>;
};
