import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {companiesSelector, getCompaniesMembers, getGroupPermissionList} from "../../../slices/companies";
import {CompanyMembers} from "./CompanyMembers";
import {ModalInviteMember} from "./ModalInviteMember";

export const CompanyList = () => {
  const dispatch = useDispatch();
  const {
    memberList,
    company,
    companies,
  } = useSelector(companiesSelector);
  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => {
    _setRefreshInfo(new Date());
  };
  useEffect(() => {
    dispatch(getCompaniesMembers());
  }, [dispatch, refreshInfo]);
  useEffect(() => {
    dispatch(getGroupPermissionList());
  }, [dispatch]);
  return <div>
    <div className={'w-full h-10'}>
      <ModalInviteMember/>
    </div>
    <div>
      {[company, ...companies].map((company, index) =>
        <div className={'mt-4'} key={index}>
          <CompanyMembers
            company={company}
            memberList={memberList}
            refreshInfo={setRefreshInfo}
          />
        </div>
      )}
    </div>
  </div>;
};
