export function _stringify(obj) {
  if(Array.isArray(obj)) {
    return `[${obj.map(el => _stringify(el))
                  .join(',')}]`
  } else if(typeof obj === 'object' && obj !== null) {
    return `{${Object.keys(obj)
                     .sort()
                     .map(key => `"${key}": ${_stringify(obj[key])}`)
                     .join(', ')}}`
  }
  return JSON.stringify(obj)
}

export const calculateHashForDictionary = (arr) => {
  return btoa(_stringify(arr))
}

export const restoreDictionaryFromHash = (hash) => {
  try {
    if(hash) return JSON.parse(atob(hash))
  } catch(e) {}
  return ''
}

