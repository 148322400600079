import { useSelector } from 'react-redux';
import { userSelector } from '../slices/user';

export const useIsUnverifiedAccount = () => {
    const {
        user: {
            registration_state: registrationState,
            email_verified: emailVerified,
        } = {},
    } = useSelector(userSelector);
    if (registrationState === undefined && emailVerified === undefined) return undefined;
    return registrationState === 'account_created' && emailVerified === false;
};
