import React, {useEffect, useState} from 'react';
import {BsExclamationCircle} from "react-icons/bs";
import {FaChevronDown, FaChevronRight, FaHistory, FaSearch} from "react-icons/fa";
import {FaPencil} from "react-icons/fa6";
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import {useDispatch, useSelector} from "react-redux";
import {BigCenteredLoadingBlock} from "../../../comps/elements";
import {getOCR, ocrSelector, resetOCR, updateOCRField} from '../../../slices/ocr';

const OCRInformationLineItem = ({
  inboxItemId,
  inboxItemPageId,
  items,
  editHistory
}) => {
  const [open, setOpen] = useState(false);
  const findField = (labels) => {
    const field = items.find(item => (labels.includes(item.field_label?.toLowerCase()) || labels.includes(item.field_type?.toLowerCase())));
    return field ? field.field_value : "N/A";
  };
  const productCode = findField(["product code", "sku", "item code", "product_code"]);
  const description = findField(["description", "item name"]);
  const quantity = findField(["quantity", "qty"]) || "1"; // Default to 1
  const amount = findField(["unit price", "amount", "price", "total"]) || "0"; // Default to 0

  // Calculate the lowest confidence from relevant fields
  const confidences = items
  .filter(item => [
    "product code",
    "sku",
    "item code",
    "product_code",
    "description",
    "item name",
    "quantity",
    "qty",
    "amount",
    "price",
    "total"
  ].some(label => (label.includes(item.field_label?.toLowerCase()) || label.includes(item.field_type?.toLowerCase()))))
  .map(item => item.field_value_confidence || 100);

  const lowestConfidence = confidences.length > 0 ? Math.min(...confidences) : 100;

  return <>
    <tr className="border-b hover:bg-slate-50">
      <td className="px-1 py-1 border-r">
        <button className="p-0.5 hover:bg-slate-100 rounded" onClick={() => setOpen(!open)}>
          {open ? <FaChevronDown/> : <FaChevronRight/>}
        </button>
      </td>
      <td className="px-2 py-1 border-r relative group">
        <div className="flex items-center"><span className="flex-grow">{productCode}</span></div>
      </td>
      <td className="px-2 py-1 border-r relative group">
        <div className="flex items-center"><span
          className="flex-grow">{description}</span></div>
      </td>
      <td className="px-2 py-1 border-r relative group">
        <div className="flex items-center"><span className="flex-grow">{quantity}</span></div>
      </td>
      <td className="px-2 py-1 border-r relative group">
        <div className="flex items-center"><span className="flex-grow">{amount}</span></div>
      </td>
      <td className="px-2 py-1 border-r">
        <div className="flex items-center">
          {lowestConfidence < 90 ? <BsExclamationCircle className={'mr-1 text-yellow-500'}/> :
            <IoMdCheckmarkCircleOutline className={'mr-1 text-green-500'}/>}
          {lowestConfidence.toFixed(2)}%
        </div>
      </td>
    </tr>
    {open && <tr>
      <td colSpan="6" className="p-0 bg-slate-50">
        <div className="p-2">
          <div className="text-xs font-medium mb-2 text-slate-700">OCR Details</div>
          <table className="w-full border-collapse text-xs">
            <thead>
            <tr className="bg-slate-100">
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">Field</th>
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">OCR Value</th>
              <th className="px-2 py-1 text-left font-medium text-slate-600 border w-24">Confidence</th>
            </tr>
            </thead>
            <tbody>
            {items.map((field, index) => {
              const {
                id,
                field_type: fieldType,
                field_label: fieldLabel,
                field_value: fieldValue,
                field_value_confidence: fieldValueConfidence
              } = field;
              const label = (fieldLabel || fieldType)
              .replace(/_/g, ' ')
              .toLowerCase()
              .split(' ')
              .map(word => word.charAt(0)
                               .toUpperCase() + word.slice(1))
              .join(' ');
              return <OCRInformationSummaryField
                key={index}
                id={id}
                inboxItemId={inboxItemId}
                inboxItemPageId={inboxItemPageId}
                field={field}
                fieldValue={fieldValue}
                fieldValueConfidence={fieldValueConfidence}
                label={label}
                editHistory={editHistory}
                highlight={'hover:bg-white'}
              />
            })}
            </tbody>
          </table>
        </div>
      </td>
    </tr>}
  </>
}

const OCRInformationSummaryFieldInput = ({
  inboxItemId,
  inboxItemPageId,
  id,
  value = ''
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    if(editMode) {
      const input = document.getElementById(`ocr-summary-field-input-${id}`);
      input.focus();
    }
  }, [editMode, id]);
  useEffect(() => {
    if(editMode) {
      const input = document.getElementById(`ocr-summary-field-input-${id}`);
      const listener = (e) => {
        if(e.key === 'Enter') {
          input.blur();
        }
      }
      input.addEventListener('keydown', listener);
      return () => {
        input.removeEventListener('keydown', listener);
      }
    }
  }, [editMode, id]);
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  return <div className={'flex flex-col'}>
    {editMode ? <input
      className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
      id={`ocr-summary-field-input-${id}`}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={() => {
        setEditMode(false);
        if(inputValue !== value) {
          dispatch(updateOCRField({
            id: inboxItemId,
            field_id: id,
            value: inputValue,
            successCallback: () => {
              dispatch(resetOCR());
              dispatch(getOCR({
                id: inboxItemId,
                page: inboxItemPageId
              }));
            }
          }));
        }
      }}
    /> : <div className={'flex items-center gap-2'}>
      <div className={'flex-grow'} onClick={() => setEditMode(true)}>{value}</div>
      <button className={'p-1 hover:bg-slate-100 rounded'} onClick={() => setEditMode(true)}>
        <FaPencil/>
      </button>
    </div>
    }
  </div>
}

const OCRInformationSummaryField = ({
  editHistory,
  inboxItemId,
  inboxItemPageId,
  label,
  fieldValue,
  fieldValueConfidence,
  id,
  highlight
}) => {
  const [open, setOpen] = useState(false)
  const itemEditHistory = editHistory.filter(item => item.field.id === id);
  return <>
    <tr className={`border-b ${highlight}`}>
      <td className="px-0.5 border-r text-xs">{itemEditHistory.length > 0 &&
        <FaHistory size={12} className={'cursor-pointer'} onClick={() => setOpen(!open)}/>}</td>
      <td className="px-2 py-1 border-r font-medium text-slate-600 w-32">{label}</td>
      <td className="px-2 py-1 border-r">
        <OCRInformationSummaryFieldInput
          inboxItemId={inboxItemId}
          inboxItemPageId={inboxItemPageId}
          id={id}
          value={fieldValue}
        />
      </td>
      <td className="px-2 py-1 border-r">
        <div className="flex items-center">
          {fieldValueConfidence < 90 ? <BsExclamationCircle className={'mr-1 text-yellow-500'}/> :
            <IoMdCheckmarkCircleOutline className={'mr-1 text-green-500'}/>}
          {fieldValueConfidence.toFixed(2)}%
        </div>
      </td>
    </tr>
    {open && <tr>
      <td colSpan="6" className="p-0 bg-slate-50">
        <div className="p-2">
          <div className="text-xs font-medium mb-2 text-slate-700">Change history</div>
          <table className="w-full border-collapse text-xs">
            <thead>
            <tr className="bg-slate-100">
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">Previous Value</th>
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">New Value</th>
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">User</th>
              <th className="px-2 py-1 text-left font-medium text-slate-600 border">Change Date</th>
            </tr>
            </thead>
            <tbody>
            {itemEditHistory.map((historyItem, index) => {
              const {
                old_value: oldValue,
                new_value: newValue,
                user,
                created_at: createdAt
              } = historyItem;
              const {
                first_name: firstName,
                last_name: lastName,
                username
              } = user;
              return <tr key={index} className="border-b hover:bg-white">
                <td className="px-2 py-1 border font-medium text-slate-600">{oldValue}</td>
                <td className="px-2 py-1 border">{newValue}</td>
                <td className="px-2 py-1 border">{firstName && lastName ? `${firstName} ${lastName}` : username}</td>
                <td className="px-2 py-1 border">{new Date(createdAt).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })}</td>
              </tr>
            })}
            </tbody>
          </table>
        </div>
      </td>
    </tr>}
  </>
}

const OCRInformation = ({
  inboxItem,
  currentPage,
  pages = [],
}) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = React.useState('');
  const {
    suggestions = {},
    loading,
  } = useSelector(ocrSelector);
  const {
    id: inboxItemId,
  } = inboxItem;
  const {
    ocr_processed: ocrProcessed = null,
  } = inboxItem;
  const {id: inboxItemPageId} = pages[currentPage] || {};
  useEffect(() => {
    dispatch(resetOCR());
    if(inboxItemId && inboxItemPageId) dispatch(getOCR({
      id: inboxItemId,
      page: inboxItemPageId
    }));
  }, [dispatch, inboxItemId, inboxItemPageId, ocrProcessed]);
  const {
    ocr_fields: ocrFields = [],
    edit_count: editCount = 0,
    edit_history: editHistory
  } = suggestions || {};
  const summaryFields = ocrFields.filter(field => field.line_item_group === null)
                                 .filter(field => field.field_label?.toLowerCase()
                                                       .includes(searchQuery.toLowerCase()) || field.field_value?.toLowerCase()
                                                                                                    .includes(searchQuery.toLowerCase()) || field.field_type?.toLowerCase()
                                                                                                                                                 .includes(searchQuery.toLowerCase()));
  const lineItemGroups = ocrFields.filter(field => field.line_item_group !== null)
  const lineItemGroupsList = lineItemGroups.reduce((acc, field) => {
    const {line_item_group: lineItemGroup} = field;
    if(!acc[lineItemGroup]) {
      acc[lineItemGroup] = [];
    }
    acc[lineItemGroup].push(field);
    return acc;
  }, {});
  return <div
    className={`flex flex-col w-full md:w-192 border-l-2 border-neutral-100 shadow-inner px-2`}>
    {loading && <BigCenteredLoadingBlock text={'Looking up OCR information'}/>}
    {!loading && <div className={'flex flex-col h-full bg-white text-xs'}>
      <div className={'p-2 border-b flex justify-between items-center bg-slate-50'}>
        <div className={'flex items-center gap-4'}>
          <h2 className={'text-sm font-semibold text-slate-700'}>Invoice Details</h2>
          <div className={'text-slate-500'}>
            {editCount} edits
          </div>
        </div>
        <div className="relative w-32">
          <FaSearch className={'absolute left-2 top-1.5 h-3 w-3 text-slate-400'}/>
          <input
            placeholder="Search..."
            name='OCR-data-search'
            className="pl-7 pr-2 py-1 w-full border rounded text-xs"
            type="text"
            value={searchQuery}
            autoComplete={'off'}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div className={'border-b'}>
        <div className="bg-slate-50 px-4 py-2 font-medium text-slate-700">Summary Fields</div>
        <table className={'w-full border-collapse'}>
          <tbody>
          {summaryFields.map((field, index) => {
            const {
              id,
              field_type: fieldType,
              field_label: fieldLabel,
              field_value: fieldValue,
              field_value_confidence: fieldValueConfidence
            } = field;
            const label = (fieldLabel || fieldType)
            .replace(/_/g, ' ')
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0)
                             .toUpperCase() + word.slice(1))
            .join(' ');
            return <OCRInformationSummaryField
              key={index}
              editHistory={editHistory}
              inboxItemId={inboxItemId}
              inboxItemPageId={inboxItemPageId}
              label={label}
              fieldValue={fieldValue}
              fieldValueConfidence={fieldValueConfidence}
              id={id}
              highlight={'hover:bg-slate-50'}
            />
          })}
          {summaryFields.length === 0 && <tr>
            <td colSpan="3" className="px-2 py-1 border-r text-slate-500 text-center">No summary fields found</td>
          </tr>}
          </tbody>
        </table>
      </div>
      <div className="flex-1">
        <table className="w-full border-collapse">
          <thead>
          <tr className="bg-slate-50 border-b">
            <th className="w-8 px-1 py-1 border-r"></th>
            <th className="px-2 py-1 text-left font-medium text-slate-600 border-r">Product Code</th>
            <th className="px-2 py-1 text-left font-medium text-slate-600 border-r">Description</th>
            <th className="px-2 py-1 text-left font-medium text-slate-600 border-r">Quantity</th>
            <th className="px-2 py-1 text-left font-medium text-slate-600 border-r">Price</th>
            <th className="px-2 py-1 text-left font-medium text-slate-600">Confidence</th>
          </tr>
          </thead>
          <tbody>
          {Object.keys(lineItemGroupsList)
                 .map((lineItemGroup, index) => <OCRInformationLineItem
                   key={index}
                   inboxItemId={inboxItemId}
                   inboxItemPageId={inboxItemPageId}
                   items={lineItemGroupsList[lineItemGroup]}
                   editHistory={editHistory}
                 />)}
          {lineItemGroups.length === 0 && <tr>
            <td colSpan="6" className="px-2 py-1 border-r text-slate-500 text-center">No line items found</td>
          </tr>}
          </tbody>
        </table>
      </div>
    </div>}
  </div>
};

export default OCRInformation;
