import {useEffect} from "react";

const useFormikHotkeys = (formikRef) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if(e.ctrlKey && (e.key === "Enter" || e.key === "s")) {
        e.preventDefault();
        if(formikRef?.current) {
          formikRef.current.submitForm();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [formikRef]);
};

export default useFormikHotkeys;
