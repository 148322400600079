import React from 'react';
import {useNavigate} from "react-router-dom";
import {build_url} from "../../../../utils/Utility";

export function CellDetailLink({
  value,
  column: {detailUrl},
  row: {original: {id}}
}) {
  const navigate = useNavigate();
  const url = build_url(detailUrl, {id})
  return <span onClick={() => {
    navigate(url)
  }}
               className={'cursor-pointer hover:text-slate-700'}>{value}</span>;
}
