import React from "react";
import {AiFillWarning} from "react-icons/ai";
import {FaInfoCircle} from "react-icons/fa";
import {IoMdCheckmarkCircle} from "react-icons/io";
// import {ReactTooltip as Tooltip} from "react-tooltip-advance";
import {Tooltip} from 'react-tooltip';
import {STRIPE_FIELD_CODES} from "../../../constants";
import {RepresentativeIssue} from "./RepresentativeIssue";

export const RepresentativeBadge = ({
  hasIssues,
  status,
  problems,
  dwollaStatus,
  dwollaError,
}) => {
  let Icon;
  const uniqueTooltipID = `payvy-representative-${Math.floor(Math.random() * 1000000)}`;
  if(hasIssues || dwollaError.length > 0 || dwollaStatus === 'document' || dwollaStatus === 'rejected') {
    Icon = <AiFillWarning className={`text-red-800 ${uniqueTooltipID}`}/>;
  } else if(status === 'verified' && dwollaStatus === 'verified') {
    Icon = <IoMdCheckmarkCircle className={`text-green-500 ${uniqueTooltipID}`}/>;
  } else if(status === 'pending' || dwollaStatus === 'pending') {
    Icon = <FaInfoCircle className={`text-orange-500 ${uniqueTooltipID}`}/>;
  } else {
    Icon = <FaInfoCircle className={`text-neutral-500 ${uniqueTooltipID}`}/>;
  }
  const hasDwollaIssues = dwollaError && dwollaError.length > 0;
  return <>
    {Icon}
    <Tooltip place={'right-end'} anchorSelect={`.${uniqueTooltipID}`} type="dark" effect="solid">
      <div className={'px-4 py-1 text-left'}>
        <div className={'flex flex-row'}>
          <div className={'inline-flex capitalize'}>Stripe Status:
            <span className={'pl-1 capitalize font-bold'}>{status}</span>
          </div>
        </div>
        <div className={`flex ${hasIssues ? 'flex-col' : 'flex-row'} mb-2`}>
          <div className={'flex capitalize whitespace-nowrap'}>Stripe Issues:
            {hasIssues ? <>
              <div className={'flex flex-col w-full'}>
                {Object.entries(problems)
                       .map((problem, index) => {
                         const [field, issue] = problem;
                         let field_name = field.replaceAll('_', ' ')
                                               .replaceAll('.', ' ');
                         if(field in STRIPE_FIELD_CODES) field_name = STRIPE_FIELD_CODES[field];
                         return <RepresentativeIssue
                           key={`finix-${index}`}
                           field_name={field_name}
                           issues={issue}
                         />;
                       })}
              </div>
            </> : <span className={'pl-1 capitalize font-bold'}>No issues.</span>}
          </div>
        </div>
        <div className={'flex flex-row'}>
          <div className={'flex capitalize'}>Dwolla Status:</div>
          <div className={'flex pl-1 capitalize font-bold'}>{dwollaStatus}</div>
        </div>
        <div className={`flex mb-2`}>
          <div className={'flex capitalize'}>Dwolla Issues:</div>
          {hasDwollaIssues ? <>
            <div className={'block flex-col'}>
              {Object.entries(dwollaError)
                     .map((field, index) => {
                       return <RepresentativeIssue
                         key={`dwolla-${index}`}
                         issues={[field[1].message]}
                       />;
                     })}
            </div>
          </> : <div className={'pl-1 capitalize font-bold'}>No issues.</div>}
        </div>
      </div>
    </Tooltip>
  </>;
};
