import {clamp, noop} from 'lodash';
import Mousetrap from 'mousetrap';
import React, {useEffect, useState} from 'react';
import {SortableList} from "./SortableList";

export const PageList = ({
  inboxItem,
  pages = [],
  currentPage = 0,
  setCurrentPage = noop,
  setInboxItemPageId,
  canDelete = false,
  canAddBill = false,
}) => {
  const [pageList, setPageList] = useState(pages);
  useEffect(() => {
    setPageList(pages);
  }, [pages]);
  // region Hotkeys
  useEffect(() => {
    Mousetrap.bind(['j', 'left'], () => setCurrentPage(clamp(currentPage - 1, 0, pages.length - 1)))
    Mousetrap.bind(['k', 'right'], () => setCurrentPage(clamp(currentPage + 1, 0, pages.length - 1)))
  }, [pages.length, setCurrentPage, currentPage]);
  // endregion
  return <div className={'payvy-page-list overflow-y-auto'}>
    {pages.length === 0 ? <p className={'px-2 py-2'}>No pages for item.</p> : <SortableList
      items={pageList}
      useDragHandle
      lockAxis={'y'}
      helperClass={'moving'}
      inboxItem={inboxItem}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setInboxItemPageId={setInboxItemPageId}
      canDelete={canDelete}
      canAddBill={canAddBill}
    >
    </SortableList>}
  </div>;
};
