import {Form, Formik} from 'formik';
import {noop} from 'lodash';
import React, {useRef, useState} from 'react';
import {AiOutlineFork} from 'react-icons/ai';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {moveBillPage} from '../../../slices/newBill';
import {handleErrors, payvyToast} from '../../../utils/Utility';
import {SelectBill} from './SelectBill';

export const ModalMovePage = ({
  bill,
  page,
  success,
}) => {
  success = success || noop;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {loading: {posting: processing}} = useSelector((state) => state.bill);
  const submitForm = React.useRef(null);
  return <>
    <PayvyIconButton
      Icon={AiOutlineFork}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Move Page'}
      tooltipPlace={'top-start'}
      type={'a'}
      iconSize={16}
      iconPaddingX={0.5}
      iconPaddingY={0.5}
      mainColor={'none'}
      borderColor={'none'}
      iconColor={'neutral-500'}
      hoverTextColor={'neutral-700'}
    />
    <ModalPayvyBase
      title={`Move "${page.invoice_number ? page.invoice_number : `Page ${page.page_number}`}"`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, move page'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          newBill: undefined,
        }}
        validationSchema={Yup.object({
          newBill: Yup.number()
                      .integer()
                      .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          dispatch(moveBillPage({
            id: bill.id,
            page: page.id,
            newBill: values.newBill,
            successCallback: () => {
              success();
              setModalOpen(false);
              payvyToast('Page moved successfully.', {appearance: 'success'});
            },
            errorCallback: (error) => {
              const data = error.data || {};
              if('message' in error) {
                payvyToast(error.message, {appearance: 'error'});
              }
              handleErrors(data, helpers);
            }
          }));
        }}
      >
        {props => <Form>
          <PayvyLabeledInput
            label={'Select destination'}
            name={'newBill'}
          >
            <SelectBill
              contactId={bill.contact.id}
              defaultValue={props.values.newBill}
              onSelect={value => props.setFieldValue('newBill', value)}
              excludeId={bill.id}
              billStatus={'draft,awaiting approval,awaiting payment'}
            />
          </PayvyLabeledInput>
          <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
