import {createAsyncThunk} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import Debugger from "../utils/Debug";
import {build_url, djangoPatchify, stringify} from "../utils/Utility";
import {handleCache, resetListCacheForStore, updateCache, updateItemCache} from "./db";

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if(!refreshToken) {
    return false;
  }
  try {
    const response = await fetch(PAYVY_API.TOKEN.REFRESH, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({refresh: refreshToken}),
    });

    const data = await response.json();
    if(response.status === 200) {
      localStorage.setItem('accessToken', data.access);
      localStorage.setItem('refreshToken', data.refresh);
      return true;
    } else {
      if(data && typeof data === "object" && data.code === "token_not_valid") {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
      }
      return false;
    }
  } catch(error) {
    return false;
  }
}

export async function makeApiCall(
  endpoint, isUnauthorizedEndpoint, method, params, body, successCallback, errorCallback, retrying = false) {
  let queryParams = stringify(params, {skipEmptyString: true});
  const url = body ? endpoint : `${endpoint}?${queryParams}`;
  let {noJson = false} = params;
  if(!body) noJson = true;
  const patchify = body?.patchify ?? false;
  delete body?.patchify;

  const headers = noJson ? {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  } : {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Content-Type': 'application/json',
  }
  if(isUnauthorizedEndpoint) {
    delete headers.Authorization;
  }
  try {
    const response = await fetch(url, {
      method,
      headers: headers,
      body: body ? (noJson ? body : JSON.stringify(body, patchify ? djangoPatchify : undefined)) : undefined,
    });

    let data;
    try {
      data = await response.json();
    } catch {
      data = {};
    }

    if(response.ok) {
      successCallback(data)
      return data;
    } else {
      if(response.status === 401) {
        if(!retrying && data && typeof data === "object" && data.code === "token_not_valid") {
          const success = await refreshAccessToken();
          if(success) {
            return await makeApiCall(endpoint, isUnauthorizedEndpoint, method, params, body, successCallback, errorCallback, true);
          }
        }
      }
      // noinspection ExceptionCaughtLocallyJS
      throw {
        message: data.detail || response.statusText,
        data,
      };
    }
  } catch(error) {
    errorCallback({
      message: error.message || "An unexpected error occurred",
      data: error.data || {}
    });
    Debugger.error('API CALL FAILED', error)
    throw {
      message: JSON.stringify({
        message: error.message || "An unexpected error occurred",
        data: error.data || null,
      })
    };
  }
}

export function createApiThunk(
  typePrefix, dataType, apiEndpoint, storeName, resetList = [], method = 'GET', cacheExpirationTime = undefined,
  isUnauthorizedEndpoint = false
) {
  return createAsyncThunk(
    `${typePrefix}/${dataType}/${method.toLowerCase()}`,
    async(
      params = {}) => {
      const {
        id,
        forceUpdate = false,
        successCallback = () => {},
        errorCallback = () => {}
      } = params;
      const queryParams = {
        active: params.active,
        amount: params.amount,
        category: params.category,
        contact: params.contact,
        contact_type: params.contact_type,
        from: params.from,
        has_bill: params.has_bill,
        has_contact: params.has_contact,
        has_dwolla: params.has_dwolla,
        hash: params.hash,
        is_bank: params.is_bank,
        is_expense: params.is_expense,
        noJson: params.noJson,
        order_by: params.order_by,
        page: params.page,
        page_size: params.page_size,
        q: params.q,
        show_deleted: params.show_deleted,
        status: params.status,
        to: params.to,
        type: params.type,
        verified: params.verified,
      }
      let apiEndpointURL = build_url(apiEndpoint, params)

      resetList.forEach(store => resetListCacheForStore(store));
      Debugger.info(`📡 API: ${storeName.toUpperCase()}`, `${method} ${apiEndpointURL}`, `ID: ${id || 'N/A'}`);
      const cacheKey = `${typePrefix}-${dataType}-${method}-${JSON.stringify(queryParams)}`;
      if(method === 'GET') {
        const cachedData = await handleCache(storeName, cacheKey, params, forceUpdate, cacheExpirationTime);
        if(cachedData) {
          Debugger.info(`💾 CACHE: ${storeName.toUpperCase()}`, `${method} ${apiEndpointURL}`, `ID: ${id || 'N/A'}`);
          return cachedData;
        }
      }
      const data = await makeApiCall(apiEndpointURL, isUnauthorizedEndpoint, method, queryParams, params.body, successCallback, errorCallback);

      if(method === 'GET') {
        if(dataType === 'list') {
          await updateCache(storeName, cacheKey, params, data);
        }
      } else if(method === 'PUT' || method === 'PATCH' || method === 'POST') {
        if(dataType === 'list') {
          await updateCache(storeName, cacheKey, params, data);
        } else if(dataType === 'detail') {
          await updateItemCache(storeName, id, cacheKey, data)
        }
      } else if(method === 'DELETE') {
        // await deleteFromCache(storeName, cacheKey);
      }
      Debugger.log(`📡 API: ${storeName.toUpperCase()}`, `${method} ${apiEndpointURL}`, `ID: ${id || 'N/A'}`);
      return data;
    }
  );
}
