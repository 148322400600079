import React from 'react';
import {MdKeyboardArrowDown, MdKeyboardArrowRight} from "react-icons/md";
import {Tooltip} from "react-tooltip";
import {PayvySidebarItem} from './PayvySidebarItem';

export const PayvySidebarGroup = ({
  name,
  Icon,
  hasAccess = true,
  items = [],
  minimized
}) => {
  const [expanded, setExpanded] = React.useState(false);
  if(hasAccess === false) return null;
  const visible = minimized || !name || expanded;
  return <div className={'flex flex-col w-full select-none'}>
    {!minimized && name && <div className={'flex ml-3 my-1 cursor-pointer'} onClick={() => setExpanded(!expanded)}>
      {Icon && (
        <div className={'flex items-center mr-2'} data-tooltip-id="sidebar-tooltip" data-tooltip-content={name}>
          <Icon className={`${minimized ? 'w-7 h-7 p-1' : 'w-5 h-5'}`}/>
          {minimized && <Tooltip id="sidebar-tooltip"/>}
        </div>
      )}
      <div className={'flex grow font-light'}>{name}</div>
      <div className={'flex items-center justify-center mr-3'}>{expanded ? <MdKeyboardArrowDown size={24}/> :
        <MdKeyboardArrowRight size={24}/>}</div>
    </div>}
    {visible && items.map((item, index) => <PayvySidebarItem key={index} hasParent={!!name}
                                                             minimized={minimized} {...item} />)}
  </div>;
};
