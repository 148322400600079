import React from 'react';
import {Link} from 'react-router-dom';
import {PAYVY_STATIC_IMAGES, PAYVY_URL} from '../../../../constants';

const FullPageSubscriptionRequiredForFeature = () => {
  return <div className={'flex flex-col h-dvh -mt-32 grow items-center justify-center'}>
    <img src={PAYVY_STATIC_IMAGES.SUBSCRIPTION} alt={'Subscription required'} className={'h-64'}/>
    <h1 className={'font-bold text-2xl'}>Subscription is required to access this feature!</h1>
    <div className={'text-center'}>
      <p><Link to={PAYVY_URL.SETTINGS.BILLING} className={'text-red-300'}>Click here</Link> to start or update your
        subscription</p>
    </div>
  </div>;
};

export default FullPageSubscriptionRequiredForFeature;
