import DOMPurify from 'dompurify';
import React from "react";


export const CleanedHTML = ({
  html,
  ...props
}) => {
  return <div {...props} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html)}}></div>;
};
