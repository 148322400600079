import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {PayvyIconButton} from "../../comps/forms";
import {PAYVY_LOGOS, PAYVY_URL} from "../../constants";
import {
  acceptCompanyInvite,
  checkCompanyInviteStatus,
  companiesSelector,
  declineCompanyInvite,
  getCompanyDetails,
  getUserCompanies,
  switchCompanies
} from "../../slices/companies";
import {resetBillToInitialState} from "../../slices/newBill";
import {resetBoxToInitialState} from "../../slices/newBox";
import {resetContactToInitialState} from "../../slices/newContact";
import {resetDropboxToInitialState} from "../../slices/newDropbox";
import {resetInboxToInitialState} from "../../slices/newInbox";
import {resetIntegrationToInitialState} from "../../slices/newIntegration";
import {resetQuickbooksToInitialState} from "../../slices/newQuickbooks";
import {userSelector} from "../../slices/user";
import {payvyToast} from "../../utils/Utility";
import FullPageLoader from "../FullPageLoader/FullPageLoader";


const InvitedCompanyUserLandingPage = () => {
  const dispatch = useDispatch();
  const {hash} = useParams();
  const navigate = useNavigate();
  const {processing} = useSelector(companiesSelector);
  const {user: {username}} = useSelector(userSelector);
  const [companyName, setCompanyName] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [groupName, setGroupName] = useState(null);
  useEffect(() => {
    dispatch(checkCompanyInviteStatus({
      hash,
      success: (data) => {
        if(data?.invited) navigate(PAYVY_URL.DASHBOARD)
        setCompanyName(data?.company?.name)
        setCompanyId(data?.company?.id)
        setGroupName(data?.group?.name)
      },
      failure: () => {
        navigate(PAYVY_URL.DASHBOARD)
      }
    }))
  }, [dispatch, hash, navigate])
  if(processing) return <FullPageLoader/>
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <h1 className={'flex text-xl mb-2 font-bold justify-center'}>You have been invited to join "{companyName}"</h1>
        <p className={'flex justify-center'}>Your assigned role will be "{groupName}".</p>
        {username && <p className={'flex justify-center'}>You are currently logged in as {username}.</p>}
        <div className={'flex justify-center items-center mt-4 space-x-4'}>
          <PayvyIconButton
            onClick={() => {
              dispatch(declineCompanyInvite({
                hash,
                success: () => {
                  navigate(PAYVY_URL.DASHBOARD)
                },
                failure: () => {
                  payvyToast('Error accepting invite', {appearance: 'error'});
                  navigate(PAYVY_URL.DASHBOARD)
                }
              }))
            }}
            buttonText="Decline"
            mainColor="red-400"
            shortcutKey={'escape'}
          />
          <PayvyIconButton
            onClick={() => {
              dispatch(acceptCompanyInvite({
                hash,
                success: () => {
                  dispatch(switchCompanies({
                    companyId,
                    success: () => {
                      dispatch(resetInboxToInitialState());
                      dispatch(resetBillToInitialState());
                      dispatch(resetContactToInitialState());
                      dispatch(resetBoxToInitialState());
                      dispatch(resetDropboxToInitialState());
                      dispatch(resetIntegrationToInitialState());
                      dispatch(resetQuickbooksToInitialState());
                      dispatch(getCompanyDetails({id: companyId}));
                      dispatch(getUserCompanies({}))
                      navigate(PAYVY_URL.DASHBOARD)
                    },
                    failure: () => {
                      navigate(PAYVY_URL.DASHBOARD)
                    }
                  }))
                },
                failure: () => {
                  payvyToast('Error accepting invite', {appearance: 'error'});
                  navigate(PAYVY_URL.DASHBOARD)
                }
              }))
            }}
            buttonText="Accept"
            mainColor="green-300"
            hoverColor={"green-200"}
            shortcutKey={['ctrl+enter', 'ctrl+s']}
            type={'submit'}
          />
        </div>
      </div>
    </div>
  </div>
};

export default InvitedCompanyUserLandingPage;
