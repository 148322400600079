import React, {useCallback, useEffect} from 'react';
import CurrencyFormat from 'react-currency-format';
import {BsTrash} from "react-icons/bs";
import {MdAddCircle} from "react-icons/md";
import {NumericFormat} from 'react-number-format';
import Select from "react-select";
import {PayvyIconButton} from "../../../comps/forms";

const BASE_ITEM = {
  id: null,
  description: '',
  quantity: 1,
  rate: '',
  amount: 0,
};

export const DISCOUNT_TYPE_OPTIONS = [
  {
    key: 'amount',
    value: 'amount',
    text: '$',
    label: '$',
  }, {
    key: 'percentage',
    value: 'percentage',
    text: '%',
    label: '%',
  },
];

export const TableInvoiceCreate = ({
  invoice = {},
  formProps = {},
}) => {
  const [lastID, setLastID] = React.useState(0);
  const [items, setItems] = React.useState(JSON.parse(JSON.stringify(invoice.items || [] || [BASE_ITEM])));
  const calculatedSubtotal = items.reduce((acc, item) => acc + item.amount, 0);
  const [subtotal, setSubtotal] = React.useState(calculatedSubtotal);
  const [discountAmount, setDiscountAmount] = React.useState(invoice?.discount || '0.00');
  const [shippingAmount, setShippingAmount] = React.useState(invoice?.shipping || '0.00');
  const [adjustmentAmount, setAdjustmentAmount] = React.useState(invoice?.adjustment || '0.00');
  const [discountMethod, setDiscountMethod] = React.useState(invoice?.discount_type || 'amount');
  const [total, setTotal] = React.useState(0);
  const [lastChangeState, _setLastChange] = React.useState("{}");
  const propagateChanges = useCallback(() => {
    let formattedDiscountAmount = 0;
    if(discountMethod === 'amount') formattedDiscountAmount = isNaN(parseFloat(discountAmount)) ? 0 : Math.round(discountAmount * 100); else if(discountMethod === 'percentage') formattedDiscountAmount = isNaN(parseFloat(discountAmount)) ? 0 : discountAmount;
    const formattedShippingAmount = isNaN(parseFloat(shippingAmount)) ? 0 : Math.round(shippingAmount * 100);
    const formattedAdjustmentAmount = isNaN(adjustmentAmount) ? 0 : Math.round(adjustmentAmount * 100);
    const formattedSubtotal = isNaN(subtotal) ? 0 : Math.round(subtotal * 100);
    const formattedTotal = isNaN(total) ? 0 : Math.round(total * 100);
    if(formProps?.setFieldValue) {
      formProps.setFieldValue('items', items);
      formProps.setFieldValue('discount', formattedDiscountAmount);
      formProps.setFieldValue('discount_type', discountMethod);
      formProps.setFieldValue('shipping', formattedShippingAmount);
      formProps.setFieldValue('adjustment', formattedAdjustmentAmount);
      formProps.setFieldValue('subtotal', formattedSubtotal);
      formProps.setFieldValue('total', formattedTotal);
    }
  }, [
    items,
    discountAmount,
    shippingAmount,
    adjustmentAmount,
    discountMethod,
    subtotal,
    total,
    lastChangeState,
  ]);
  const recalculateInvoice = useCallback(() => {
    let subtotal = 0;
    if(items.length === 0) setItems([JSON.parse(JSON.stringify(BASE_ITEM))]);
    items.forEach((item, index) => {
      recalculateItem(items, index);
      subtotal += item.amount * 100;
    });
    subtotal /= 100;
    let discount;
    if(discountMethod === 'amount') {
      discount = parseFloat(discountAmount);
    } else {
      discount = (subtotal * parseFloat(discountAmount)) / 100;
    }
    setSubtotal(subtotal);
    setTotal(subtotal - discount + parseFloat(shippingAmount) + parseFloat(adjustmentAmount));
  }, [items, discountAmount, discountMethod, shippingAmount, adjustmentAmount]);

  useEffect(() => {
    const amount = parseFloat(discountAmount);
    const invoiceSubtotal = parseFloat(subtotal);
    if(amount < 0) {
      setDiscountAmount('0.00');
    } else if(amount > 100 && discountMethod === 'percentage') {
      setDiscountAmount('100.00');
    } else if(amount > invoiceSubtotal && discountMethod === 'amount') {
      setDiscountAmount(`${subtotal}`);
    }
  }, [discountAmount, discountMethod, subtotal]);
  useEffect(() => {
    recalculateInvoice();
    propagateChanges();
  }, [items, recalculateInvoice, propagateChanges, discountAmount, discountMethod, shippingAmount, adjustmentAmount]);
  useEffect(recalculateInvoice, [recalculateInvoice]);
  useEffect(() => {
    if(invoice.id && invoice.id !== lastID) {
      setLastID(invoice.id);
      setDiscountAmount((invoice?.discount_type === 'amount' ? invoice.discount / 100 : invoice.discount).toString());
      setShippingAmount((invoice.shipping / 100).toString());
      setAdjustmentAmount((invoice.adjustment / 100).toString());
      setDiscountMethod(invoice.discount_type);
      setSubtotal(invoice.subtotal / 100);
      setTotal(invoice.total / 100);
      const newItems = [];
      for(let i = 0; i < invoice?.items?.length; i++) {
        const item = invoice?.items[i];
        const newItem = {...item};
        newItem.rate = parseFloat(item.rate) / 100;
        newItems.push(newItem);
      }
      setItems(JSON.parse(JSON.stringify(newItems || [])));
      recalculateInvoice();
    }
  }, [invoice, lastID, recalculateInvoice]);


  const recalculateItem = (newItems, index) => {
    const calculatedItems = [...newItems];
    let amount;
    const {
      quantity,
      rate,
    } = calculatedItems[index];
    amount = quantity * rate;
    if(isNaN(amount)) amount = 0;
    calculatedItems[index].amount = amount;
    return calculatedItems;
  };

  const updateItem = (index, item, value) => {
    let newItems = [...items];
    newItems[index][item] = value;
    setItems(recalculateItem(newItems, index));
  };

  const addNewLine = () => {
    let newItems = [...items];
    newItems.push(JSON.parse(JSON.stringify(BASE_ITEM)));
    setItems(newItems);
  };

  const removeLine = (index) => {
    let newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };
  const tdBody = 'p-2 bg-neutral-0 border border-slate-400 text-center'
  const tdHeader = `${tdBody} text-left bg-slate-200`
  const thHeader = 'text-center border border-slate-400 text-center bg-slate-200 p-2'
  const thHeaderFirst = `border border-slate-400 text-center bg-slate-200 w-2/3 text-left px-4 py-2`
  return <div className={'flex flex-col'}>
    <table className={'table-auto mt-2 w-full border border-slate-400'}>
      <thead>
      <tr>
        <th className={thHeaderFirst}>Item details</th>
        <th className={thHeader}>Qty</th>
        <th className={thHeader}>Rate</th>
        <th className={thHeader}>Amount</th>
        {items.length <= 1 ? null : <th className={thHeader}></th>}
      </tr>
      </thead>
      <tbody>
      {items.map((item, index) => {
        const touchedItems = formProps?.touched?.items || [];
        const errorItems = formProps?.errors?.items || [];
        const touched = touchedItems[index] || {};
        const errors = errorItems[index] || {};
        return <tr key={index} className={`${items.length - 1 === index ? '' : 'border-b border-slate-400'}`}>
          <td className={'pt-4'}>
            <div className={`flex flex-col w-full`}>
              <input
                value={item.description}
                onChange={(e) => updateItem(index, 'description', e.target.value)}
                className={`my-0.5 px-1 py-2 rounded-md w-full ${touched.description && errors.description ? 'border-2 border-slate-200' : 'border border-neutral-500'}`}/>
              <span className={'text-red-300 text-xs block text-left pl-1'}>
                                {touched.description && errors.description ? errors.description : <>&nbsp;</>}
                            </span>
            </div>
          </td>
          <td className={'pt-4'}>
            <div className={`flex flex-col w-full`}>
              <NumericFormat
                decimalScale={0}
                value={item.quantity}
                onValueChange={(value) => updateItem(index, 'quantity', value.floatValue)}
                className={`my-0.5 px-1 py-2 rounded-md w-full ${touched.quantity && errors.quantity ? 'border-2 border-slate-200' : 'border border-neutral-500'}`}
              />
              <span className={'text-red-300 text-xs block text-left pl-1'}>
                                {touched.quantity && errors.quantity ? errors.quantity : <>&nbsp;</>}
                            </span>
            </div>
          </td>
          <td className={'pt-4'}>
            <div className={`flex flex-col w-full`}>
              <CurrencyFormat
                placeholder={'$0.00'}
                value={item.rate}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={({value}) => updateItem(index, 'rate', value)}
                className={`my-0.5 px-1 py-2 rounded-md w-full ${touched.rate && errors.rate ? 'border-2 border-slate-200' : 'border border-neutral-500'}`}
              />
              <span className={'text-red-300 text-xs block text-left pl-1'}>
                                {touched.rate && errors.rate ? errors.rate : <>&nbsp;</>}
                            </span>
            </div>
          </td>
          <td className={'pt-4'}>
            <div className={`flex flex-col w-full`}>
              <CurrencyFormat
                value={item.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                className={`mb-4 px-1 py-2 w-full`}
              />
            </div>
          </td>
          {items.length <= 1 ? null : <td className={'pt-4'}>
            <PayvyIconButton
              Icon={BsTrash}
              mainColor={'none'}
              iconColor={'red-300'}
              hoverTextColor={'red-900'}
              onClick={() => removeLine(index)}
              wrapperClassName={'mb-4'}
            />
          </td>}
        </tr>;
      })}
      </tbody>
    </table>
    <div className={'relative'}>
      <PayvyIconButton
        Icon={MdAddCircle}
        buttonText={'Add Line'}
        onClick={addNewLine}
        wrapperClassName={'absolute p-2'}
      />
    </div>
    <table className={'w-2/3 lg:w-128 self-end'}>
      <tbody>
      <tr>
        <td className={`${tdHeader} border-t-0`}>Subtotal</td>
        <td className={`${tdBody} border-t-0`}>
          <CurrencyFormat
            name={'subtotal'}
            value={subtotal}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Discount</td>
        <td className={tdBody}>
          <div className={'flex flex-row w-full'}>
            <div className={'flex grow w-full'}>
              {discountMethod === 'amount' ? <CurrencyFormat
                name={'discountAmount'}
                placeholder={'$0.00'}
                value={discountAmount}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
                onValueChange={({value}) => setDiscountAmount(value)}
                className={`my-0.5 px-1 py-2 rounded-md w-full border border-neutral-500`}
              /> : <NumericFormat
                suffix={'%'}
                name={'discountPercentage'}
                decimalScale={2}
                placeholder={'25%'}
                value={discountAmount}
                onValueChange={(value) => setDiscountAmount(value.floatValue)}
                className={`my-0.5 px-1 py-2 rounded-md w-full border border-neutral-500`}
              />}
            </div>
            <div className={'w-1/3 min-w-min'}>
              <Select
                name="discountType"
                onChange={({value}) => setDiscountMethod(value)}
                options={DISCOUNT_TYPE_OPTIONS}
                selection
                value={DISCOUNT_TYPE_OPTIONS.find(item => item.value === discountMethod)}
                className={'ml-1'}
                style={{minWidth: '1em'}}
              />
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Shipping</td>
        <td className={tdBody}>
          <div className={`flex flex-col w-full`}>
            <CurrencyFormat
              value={shippingAmount}
              name={'shipping'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={({value}) => setShippingAmount(value)}
              className={`my-0.5 px-1 py-2 rounded-md w-full border border-neutral-500`}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Adjustment</td>
        <td className={tdBody}>
          <div className={`flex flex-col w-full`}>
            <CurrencyFormat
              value={adjustmentAmount}
              name={'adjustment'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              onValueChange={({value}) => setAdjustmentAmount(value)}
              className={`my-0.5 px-1 py-2 rounded-md w-full border border-neutral-500`}
            />
          </div>
        </td>
      </tr>
      <tr>
        <td className={tdHeader}>Total</td>
        <td className={tdBody}>
          <div className={`flex flex-col w-full`}>
            <CurrencyFormat
              value={total}
              name={'total'}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
              className={`mb-0.5 px-1 py-2 w-full`}
            />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>;
};
