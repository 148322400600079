import {noop} from "lodash";
import React from "react";
import {Tooltip} from 'react-tooltip';

export const PayvyToggleCheckbox = ({
  name,
  labelText,
  className = 'sr-only py-2',
  containerClassName = '',
  checked = false,
  onChange = noop,
  required = false,
  disabled = false,
  tooltipContent = '',
  tooltipFor = '',
  tooltipPlace = 'top',
}) => {
  const uniqueTooltipID = tooltipFor ? tooltipFor : `payvy-tooltip-${Math.floor(Math.random() * 1000000)}`;
  const LabelAs = <label
    className={`flex items-center cursor-pointer py-2`}
  >
        <span className={`relative block ${uniqueTooltipID}`}>
            <input
              name={name}
              type={'checkbox'}
              className={className}
              onChange={onChange}
              checked={checked}
              disabled={disabled}
              required={required}
            />
            <span className="block w-10 h-4 bg-neutral-300 rounded-full shadow-inner"></span>
            <span
              className="block dot absolute w-6 h-6 bg-neutral-0 rounded-full shadow -left-1 -top-1 transition"></span>
        </span>
    {labelText && <span className="block ml-3 text-neutral-700 font-medium">
            {labelText}
        </span>}
  </label>
  if(!tooltipContent) return <div className={`py-2 w-full ${containerClassName}`}>{LabelAs}</div>;
  return <div className={`py-2 w-full ${containerClassName}`}>
    {LabelAs}
    <Tooltip place={tooltipPlace}
             anchorSelect={`.${uniqueTooltipID}`}
             type="dark" effect="solid">
      {tooltipContent}
    </Tooltip>
  </div>
};
