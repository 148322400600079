import React from 'react';
import {useSelector} from 'react-redux';
import {PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {companiesSelector, hasPermission} from '../../slices/companies';
import {userSelector} from '../../slices/user';
import {FormAccount} from './components/FormAccount';
import {FormCompany} from './components/FormCompany';

const SettingsAccountPage = () => {
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const companyAdministrationPermission = hasPermission(company, userId, 'company_administration');
  const hasCompanyFormAccess = !!company && companyAdministrationPermission;
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Account</PayvyPageHeader>
    <SettingsMenu active="account"/>
    <div className={'w-full pb-2 bg-neutral-50 flex flex-col xl:flex-row-reverse gap-2'}>
      <FormAccount/>
      {hasCompanyFormAccess ? <FormCompany/> : <div className={'flex flex-col w-full xl:w-6/12'}>
        <h1 className={'flex pt-3 mx-3 w-11/12 font-light text-xl text-neutral-700 border-b border-neutral-700'}>Company
          Settings</h1>
        <p className={'text-sm text-red-500 italic mx-3 mt-2'}>
          You don't have permission to view the company settings.
        </p>
      </div>}
    </div>
  </>;
};

export default SettingsAccountPage;
