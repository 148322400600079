import React, {useEffect, useState} from 'react';
import CurrencyFormat from 'react-currency-format';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from "react-router-dom";
import {PayvyIconButton} from "../../comps/forms";
import {PAYVY_LOGOS} from '../../constants';
import {getInvoiceIdentities} from "../../slices/finix";
import {getInvoiceDetails, receivablesSelector} from '../../slices/receivables';
import {downloadInvoice} from '../../utils/Utility';
import {InvoiceLabeledData} from "../InvoiceDetailPage/components/InvoiceLabeledData";
import {TableInvoice} from '../InvoiceDetailPage/components/TableInvoice';
import {BlockSetUpPaymentMethod} from './components/BlockSetUpPaymentMethod';
import {BlockTermsAndConditions} from './components/BlockTermsAndConditions';
import {InvoicePaid} from './components/InvoicePaid';

const InvoiceLandingPage = () => {
  const {
    loading,
    invoiceDetails,
  } = useSelector(receivablesSelector);
  const dispatch = useDispatch();
  const {id} = useParams();
  const [paidInvoice, setPaidInvoice] = React.useState(false);
  const [refreshQuery, _setRefreshQuery] = useState(0);
  const setRefreshQuery = () => _setRefreshQuery(new Date().getTime());
  const paidSuccessfully = () => {
    setPaidInvoice(true);
    setRefreshQuery();
  };
  useEffect(() => {
    if(!!id) {
      dispatch(getInvoiceDetails({
        invoiceId: id,
        publicEP: true,
      }));
      dispatch(getInvoiceIdentities({invoiceID: id}));
    }
  }, [dispatch, id, refreshQuery]);
  const invoice = invoiceDetails[id];
  if(loading) return (<>Loading...</>);
  if(!invoice) return (<>Invoice not found</>);
  if(invoice.is_paid || paidInvoice || invoice.is_authorized) return <InvoicePaid invoice={invoice}/>;
  return <div className={'flex flex-col'}>
    <div className={'w-full flex bg-neutral-50 sticky top-0 h-20 z-10'}>
      <div className={'hidden sm:flex h-full'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'h-full py-2'}
        />
      </div>
    </div>
    <div className={'flex flex-row'}>
      <div className="bg-neutral-0 w-full h-screen overflow-x-scroll p-0 pb-10">
        <div className={'payvy-main-content px-10 pb-10'}>
          <h1 className="p-4 w-full text-3xl">
            <CurrencyFormat
              value={invoice.amount / 100}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </h1>
          <div className={'flex flex-row flex-wrap mb-10'}>
            <InvoiceLabeledData label={'Due'} data={invoice.due_date}/>
            <InvoiceLabeledData label={'To'} data={invoice.contact.name}/>
            <InvoiceLabeledData label={'From'} data={invoice.company.name}/>
            <InvoiceLabeledData label={'Invoice No'} data={invoice.invoice_number}/>
            <div className={'inline-flex grow flex-col items-end'}>
              <div className={'flex flex-row gap-2'}></div>
              <div>
                <PayvyIconButton
                  buttonText={'Download Invoice'}
                  wrapperClassName={'my-1'}
                  onClick={() => downloadInvoice(invoice.id)}
                />
              </div>
            </div>
          </div>
          <TableInvoice invoice={invoice}/>
          <div className={'flex flex-col w-1/2'}>
            <BlockTermsAndConditions terms={invoice.terms_and_conditions}/>
            <BlockSetUpPaymentMethod
              invoice={invoice}
              contactID={invoice.contact.id}
              paidSuccessfully={paidSuccessfully}
            />
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default InvoiceLandingPage;
