import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {BigCenteredLoadingBlock, PayvyPageHeader} from "../../comps/elements";
import {PAYVY_URL} from "../../constants";
import {companiesSelector, hasPermission} from '../../slices/companies';
import {getUserInboxItem} from "../../slices/newInbox";
import {userSelector} from "../../slices/user";
import {payvyToast} from "../../utils/Utility";
import MenuBar from './components/MenuBar';
import Page from './components/Page';
import TitleBar from './components/TitleBar';

const InboxDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: inboxItemId} = useParams()
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};
  const {
    loading: {item: loadingItem},
    items
  } = useSelector((state) => state.inbox)
  const inboxPermissions = {
    canAddBill: hasPermission(company, userId, 'bill_can_add'),
    canDelete: hasPermission(company, userId, 'inbox_can_delete'),
  };
  const [OCRMode, setOCRMode] = React.useState(false);
  const [inboxItem, setInboxItem] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [inboxItemPageId, setInboxItemPageId] = useState(inboxItem?.pages?.[0]?.id);
  const [showDeletedPages, _setShowDeletedPages] = useState(false);
  const setShowDeletedPages = (show) => {
    const setting = show === undefined ? !showDeletedPages : show;
    _setShowDeletedPages(setting);
  };
  useEffect(() => {
    setInboxItem(Object.values(items)
                       .flat()
                       .find(item => item.id === parseInt(inboxItemId)));
  }, [items, inboxItemId, loadingItem])
  useEffect(() => {
    dispatch(getUserInboxItem({
      id: inboxItemId,
      successCallback: () => payvyToast('Item information is refreshed', {'appearance': 'success'}),
      errorCallback: () => navigate(PAYVY_URL.INBOX.RECEIVED)
    }))
  }, [dispatch, inboxItemId, companyId, navigate])
  useEffect(() => {
    setInboxItemPageId(inboxItem?.pages?.[0]?.id);
  }, [inboxItem, setInboxItemPageId])
  return <div className={'flex flex-col w-full h-full md:h-full-minus-bar'}>
    {loadingItem && <BigCenteredLoadingBlock text={'Loading inbox item information'}/>}
    {!!inboxItem?.id && !loadingItem && <>
      <PayvyPageHeader parents={[
        {
          name: 'Inbox',
          href: PAYVY_URL.INBOX.RECEIVED
        }
      ]}>#{inboxItemPageId}</PayvyPageHeader>
      <MenuBar
        inboxItemPageId={inboxItemPageId}
        inboxItem={inboxItem}
        setShowDeletedPages={setShowDeletedPages}
        showDeletedPages={showDeletedPages}
      />
      <TitleBar
        inboxItem={inboxItem}
        OCRMode={OCRMode}
        setOCRMode={setOCRMode}
      />
      <Page
        inboxItem={inboxItem}
        inboxPermissions={inboxPermissions}
        setInboxItemPageId={setInboxItemPageId}
        showDeletedPages={showDeletedPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        OCRMode={OCRMode}
      />
    </>}
  </div>;
};

export default InboxDetailPage;
