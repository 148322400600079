import moment from "moment/moment";
import React from "react";

export const CurrentSubscriptionDateRange = ({
  status,
  periodStart,
  periodEnd,
  cancelling,
}) => {
  const start = moment(periodStart);
  const end = moment(periodEnd);
  return <span className={'flex'}>
                {!!status ? <>
                  {start.year() === end.year() ? <>
                    {start.format('MMM D')} - {end.format('MMM D')} Fees
                  </> : <>
                    {start.format('ll')} - {end.format('ll')} Fees
                  </>}
                  {status === 'active' && cancelling ? ' - Cancelled at the end of this period.' : ''}
                  {status !== 'active' ? ` - ${status}` : ''}
                </> : <>
                  Inactive
                </>}
            </span>;
};
