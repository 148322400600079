import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {
  getPaymentServiceInformation,
  paymentSystemSelector,
  unlinkContactFromRecipient
} from "../../../slices/paymentSystem";
import {BasePaymentForm} from "./BasePaymentForm";
import {ContactAssignmentForm} from "./ContactAssignmentForm";
import RefreshContactFromAPIButton from "./RefreshContactFromAPIButton";

export const RevolutPaymentForm = ({
  contactId,
  formProps,
  processing,
  loading,
  bankAccounts,
  amount,
  emailOptions,
  handleCreate,
  checkboxFieldsSet,
  addAndSelectVendor,
  addAndSelectUser,
}) => {
  const dispatch = useDispatch();
  const {services} = useSelector(paymentSystemSelector);
  const {
    internal_name: internalName,
    active_configuration: {enabled = false},
    recipients = []
  } = services.find(service => service.name === 'Revolut');
  if(enabled === false) {
    return <div>
      Revolut is not enabled. You can enable it from the <Link to={PAYVY_URL.SETTINGS.BANKING}
                                                               className={'text-red-300'}>Banking
      Settings</Link> page.
    </div>
  }
  const contact = recipients.find((recipient) => recipient.contact?.id === contactId);
  if(contact === undefined) {
    return <ContactAssignmentForm
      contactId={contactId}
      services={services}
      internalName={internalName}
    />
  }
  const filteredBankAccounts = bankAccounts.filter(account => account.type === 'Revolut' && account?.label?.endsWith('(USD)'));
  if(filteredBankAccounts.length === 0) {
    return <div>
      No Revolut accounts found.
    </div>
  }
  const recipientAccounts = contact.recipient_details.accounts.filter(account => account.currency === 'USD')
                                   .map(account => ({
                                     key: account.id,
                                     value: account.id,
                                     label: `${account.name} (${account.currency})`
                                   }));
  return <>
    <div className={'text-neutral-700 mt-2'}>
      This bill's contact is associated with <b>{contact?.name}</b> on Revolut.
      <span
        className={'px-1 text-xs text-red-300 cursor-pointer'}
        onClick={() => dispatch(unlinkContactFromRecipient({
          contact: contactId,
          service_name: internalName,
          success: () => {dispatch(getPaymentServiceInformation({}))}
        }))}>
        Change
      </span>
    </div>
    {recipientAccounts.length === 0 ? <div className={'text-neutral-700 mt-2'}>
        You can't pay this contact yet because they need a USD account. Add one for them on your payment system, and try
        refreshing here: <RefreshContactFromAPIButton currentPaymentType={'revolut'} textOnly={true}/>
      </div> :
      <BasePaymentForm
        recipientAccounts={recipientAccounts}
        formProps={formProps}
        processing={processing}
        loading={loading}
        bankAccounts={filteredBankAccounts}
        amount={amount}
        emailOptions={emailOptions}
        handleCreate={handleCreate}
        checkboxFieldsSet={checkboxFieldsSet}
        addAndSelectVendor={addAndSelectVendor}
        addAndSelectUser={addAndSelectUser}
      />}
  </>
}
