import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {
  getPaymentServiceInformation,
  paymentSystemSelector,
  unlinkContactFromRecipient
} from "../../../slices/paymentSystem";
import {BasePaymentForm} from "./BasePaymentForm";
import {ContactAssignmentForm} from "./ContactAssignmentForm";

export const BrexPaymentForm = ({
  contactId,
  formProps,
  processing,
  loading,
  bankAccounts,
  amount,
  emailOptions,
  handleCreate,
  checkboxFieldsSet,
  addAndSelectVendor,
  addAndSelectUser,
}) => {
  const dispatch = useDispatch();
  const {services} = useSelector(paymentSystemSelector);
  const {
    internal_name: internalName,
    active_configuration: {enabled = false},
    recipients = []
  } = services.find(service => service.name === 'Brex');
  if(enabled === false) {
    return <div>
      Brex is not linked. You can link the service on the <Link to={PAYVY_URL.SETTINGS.BANKING}
                                                                className={'text-red-300'}>Banking Settings</Link> page.
    </div>
  }
  const contact = recipients.find((recipient) => recipient.contact?.id === contactId);
  if(contact === undefined) {
    return <ContactAssignmentForm
      contactId={contactId}
      services={services}
      internalName={internalName}
    />
  }
  const filteredBankAccounts = bankAccounts.filter(account => account.type === "Brex");
  if(filteredBankAccounts.length === 0) {
    return <div>
      No Brex accounts found.
    </div>
  }
  const recipientPaymentAccounts = contact?.recipient_details?.payment_accounts || [];
  const recipientAccounts = recipientPaymentAccounts.map(account => (
    {
      key: account.details.payment_instrument_id,
      value: account.details.payment_instrument_id,
      label: `${account.details.account_type} ${account.details.account_class} (${account.details.account_number.slice(-4)})`
    }
  ));
  return <>
    <div className={'text-neutral-700 mt-2'}>
      This bill's contact is associated with <b>{contact?.name}</b> on Brex.
      <span
        className={'px-1 text-xs text-red-300 cursor-pointer'}
        onClick={() => dispatch(unlinkContactFromRecipient({
          contact: contactId,
          service_name: internalName,
          success: () => {dispatch(getPaymentServiceInformation({}))}
        }))}>
        Change
      </span>
    </div>
    {recipientAccounts.length === 0 ? <div className={'text-neutral-700 mt-2'}>
        No payment accounts found for this contact on Brex. Please add a payment account on Brex and try again.
      </div> :
      <BasePaymentForm
        formProps={formProps}
        processing={processing}
        loading={loading}
        bankAccounts={filteredBankAccounts}
        recipientAccounts={recipientAccounts}
        amount={amount}
        emailOptions={emailOptions}
        handleCreate={handleCreate}
        checkboxFieldsSet={checkboxFieldsSet}
        addAndSelectVendor={addAndSelectVendor}
        addAndSelectUser={addAndSelectUser}
      />}
  </>
}
