export const fileFormatAndSizeValidator = (file) => {
  const validFileFormatSize = [
    {
      type: 'image/tiff',
      size: 1024 * 1024,
    }, {
      type: 'image/pdf',
      size: 1024 * 1024,
    }, {
      type: 'application/pdf',
      size: 1024 * 1024,
    }, {
      type: 'image/jpg',
      size: 50 * 1024,
    }, {
      type: 'image/png',
      size: 50 * 1024,
    }, {
      type: 'image/jpeg',
      size: 50 * 1024,
    },
  ];
  const validFileSizeForFormat = validFileFormatSize.find(f => f.type === file.type);
  if(!validFileSizeForFormat) return {
    code: 'INVALID_FILE_FORMAT',
    message: 'Invalid file format, accepted formats are JPG, PNG, PDF, TIFF',
  };
  if(file.size > validFileSizeForFormat.size) return {
    code: 'INVALID_FILE_SIZE',
    message: `File size exceeds ${validFileSizeForFormat.size} bytes`,
  };
  return null;
};
