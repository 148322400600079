import {Form, Formik} from 'formik';
import React, {useRef} from 'react';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {
  GlobalFormError,
  PayvyIconButton,
  PayvyIconButtonGroup,
  PayvyInput,
  PayvyLabeledCheckbox,
  PayvyLabeledInput,
  PayvySelectComponent
} from '../../comps/forms';
import {CONTACT_ACCOUNT_TYPES, FORM_ERROR_MESSAGES, PAYVY_URL} from '../../constants';
import useFormikHotkeys from "../../hooks/useFormikHotkeys";
import {createContact, sendInvitation} from '../../slices/newContact';
import {handleErrors, payvyToast} from '../../utils/Utility';
import {ModalVendorInvite} from './components/ModalVendorInvite';

const ContactCreateVendorForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    loading: {
      list: loading,
      posting: processing
    }
  } = useSelector((state) => state.contact);
  return <div className={'grid grid-cols-1 lg:grid-cols-2 gap-6 mx-4'}>
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        quickbooks: '',
        createNew: '',
        customerType: 'business',
        contactType: 'Vendor',
        vendor1099: false,
        paymentType: '',
        inviteBody: '',
        inviteSubject: '',
        addPaymentMethod: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string()
                 .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        email: Yup.string()
                  .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL),
        phone: Yup.string()
                  .nullable()
                  .test('is-valid-phone', 'Invalid phone number', (value) => (!value || (!!value && isPossiblePhoneNumber(value)))),
        contactType: Yup.string()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        customerType: Yup.string()
                         .oneOf(['individual', 'business'])
                         .required(),
        vendor1099: Yup.string()
                       .oneOf(['true', 'false'])
                       .required(),
      })}
      onSubmit={(values, helpers) => {
        dispatch(createContact({
          body: {
            account_number: values.accountNumber,
            routing_number: values.routingNumber,
            account_type: values.accountType,
            customer_type: values.customerType,
            payment_type: values.activePaymentType,
            city: values.addressCity,
            line1: values.addressLine1,
            line2: values.addressLine2,
            state: values.addressState,
            zip: values.addressZip,
            email: values.email,
            name: values.name,
            phone: values.phone,
            send_to_contact: values.sendToContact,
            contact_type: values.contactType,
            patchify: true
          },
          successCallback: (data) => {
            if(values.inviteBody) {
              dispatch(sendInvitation({
                id: data.id,
                body: {
                  subject: values.inviteSubject,
                  body: values.inviteBody,
                },
                successCallback: () => {
                  payvyToast('Vendor invited successfully.', {appearance: 'success'});
                  navigate(PAYVY_URL.CONTACTS.LIST);
                },
                errorCallback: (error) => {
                  const data = error.data || {};
                  if('message' in error) {
                    payvyToast(error.message, {appearance: 'error'});
                  }
                  handleErrors(data, helpers);
                },
              }));
            } else {
              payvyToast('Contact created successfully.', {appearance: 'success'});
              navigate(PAYVY_URL.CONTACTS.LIST);
            }
          },
          errorCallback: (error) => {
            const data = error.data || {};
            if('message' in error) {
              payvyToast(error.message, {appearance: 'error'});
            }
            handleErrors(data, helpers);
          },
        }));
      }}
    >
      {props => <Form>
        <PayvyLabeledInput
          label={'Name'}
          name={'name'}
          as={PayvyInput}
        />
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'Vendor Type'}
            LabelAs={'span'}
            name={'customerType'}
            alwaysLabel={true}
          >
            <PayvyIconButtonGroup
              value={props.values.customerType}
              onChange={(value) => props.setFieldValue('customerType', value)}
              choices={[
                {
                  label: 'Company',
                  value: 'business',
                },

                {
                  label: 'Individual',
                  value: 'individual',
                },
              ]}/>
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'1099 Vendor'}
            LabelAs={'span'}
            name={'vendor1099'}
            alwaysLabel={true}
          >
            <PayvyIconButtonGroup
              value={props.values.vendor1099}
              onChange={(value) => props.setFieldValue('vendor1099', value)}
              choices={[
                {
                  label: 'Yes',
                  value: true,
                }, {
                  label: 'No',
                  value: false,
                },
              ]}/>
          </PayvyLabeledInput>
        </div>
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'Email'}
            name={'email'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Phone number'}
            name={'phone'}
            alwaysLabel={true}
          >
            <PhoneInput
              international={true}
              defaultCountry="US"
              placeholder="Enter phone number"
              value={props.values.phone}
              onChange={(value) => props.setFieldValue('phone', value)}
            />
          </PayvyLabeledInput>
        </div>
        <PayvyLabeledInput
          label={'Add Payment Method'}
          LabelAs={'span'}
          alwaysLabel={true}
        >
          <ModalVendorInvite
            parentForm={props}
          />
          <PayvyIconButton
            buttonText={props.values.addPaymentMethod === 'now' ? 'Enter Details Later' : 'Enter Details Now'}
            icon={'credit-card'}
            onClick={() => {
              props.setFieldValue('addPaymentMethod', props.values.addPaymentMethod === 'now' ? '' : 'now');
              props.setFieldValue('activePaymentType', props.values.addPaymentMethod === 'now' ? '' : 'outside');
            }}
            mainColor={props.values.addPaymentMethod === 'now' ? 'red-300' : 'neutral-100'}
            textColor={props.values.addPaymentMethod === 'now' ? 'neutral-0' : 'neutral-900'}
          />
        </PayvyLabeledInput>
        {props.values.addPaymentMethod === 'now' ? <div className={'flex flex-row'}>
          <div>
            <label className={'flex mt-5 mb-2'}>Payment Type</label>
            <PayvyIconButtonGroup
              value={props.values.activePaymentType}
              onChange={(value) => props.setFieldValue('activePaymentType', value)}
              choices={[
                {
                  label: 'ACH',
                  value: 'ach',
                }, {
                  label: 'Check',
                  value: 'check',
                }, {
                  label: 'Outside Payvy',
                  value: 'outside',
                },
              ]}
            />
            {props.values.activePaymentType === 'ach' ? <>
              <div className={'payment-type-tab'}>
                <PayvyLabeledInput
                  label={'Account Type'}
                  component={PayvySelectComponent}
                  name={'accountType'}
                  options={CONTACT_ACCOUNT_TYPES}
                />
                <PayvyLabeledInput
                  label={'Account Number'}
                  name={'accountNumber'}
                  as={PayvyInput}
                />
                <PayvyLabeledInput
                  label={'Routing Number'}
                  name={'routingNumber'}
                  as={PayvyInput}
                />
              </div>
            </> : null}
            {props.values.activePaymentType === 'check' ? <>
              <div className={'payment-type-tab'}>
                <PayvyLabeledCheckbox name={'sendToContact'} required={false}>
                                    <span className={'text-xs'}>
                                        Send to contact address
                                    </span>
                </PayvyLabeledCheckbox>
              </div>
            </> : null}
            {props.values.activePaymentType === 'outside' ? <>
              <div className={'payment-type-tab'}>
                Associate a payment account from your accounting program, and will be marked as paid.
              </div>
            </> : null}
          </div>
        </div> : null}
        <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        <div className={'w-full flex flex-row justify-end gap-5 mt-5'}>
          <PayvyIconButton
            buttonText={'Cancel'}
            onClick={() => navigate(PAYVY_URL.CONTACTS.LIST)}
            disabled={processing || loading}
            loading={processing || loading}
            borderSize={2}
            borderColor={'red-300'}
            mainColor={'neutral-0'}
            textColor={'red-300'}
            hoverTextColor={'neutral-0'}
            shortcutKey={'escape'}
          />
          <PayvyIconButton
            buttonText={'Create vendor'}
            disabled={processing || loading}
            loading={processing || loading}
            type={'submit'}
          />
        </div>
      </Form>}
    </Formik>
  </div>;
};

export default ContactCreateVendorForm;
