import {Formik} from "formik";
import {noop} from "lodash";
import React, {useRef, useState} from "react";
import {FaRegStar, FaStar} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import * as Yup from "yup";
import {PayvyGlobalFormError, PayvyInput, PayvyLabeledInput} from "../../../comps/forms/";
import {ModalPayvyBase} from "../../../comps/modals";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, companyPassOwnership} from "../../../slices/companies";
import {userSelector} from "../../../slices/user";
import {handleErrors} from "../../../utils/Utility";

export const ModalChangeOwnership = ({
  company,
  member,
  currentUserPermissions,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const changeOwnershipForm = React.createRef();
  const [modalOpen, setModalOpen] = useState(false);
  const {processing} = useSelector(companiesSelector);
  const {user} = useSelector(userSelector);
  const canChangeOwner = currentUserPermissions.company_administration === true
  if(company.creator.id === user.id) {
    if(company.creator.id === member.id) {
      return <FaStar className={'text-red-200'}/>;
    } else if(canChangeOwner) {
      return <>
        <FaRegStar
          className={'text-red-200 cursor-pointer'}
          onClick={() => setModalOpen(true)}
          title={'Account Ownership Transfer'}
        />
        <ModalPayvyBase
          title={`Transfer Account Ownership`}
          isOpen={modalOpen}
          confirmButtonText={'Transfer Ownership'}
          confirmButtonAction={() => changeOwnershipForm.current.click()}
          confirmButtonLoading={processing}
          dismissButtonText={'Cancel'}
          dismissButtonAction={() => setModalOpen(false)}
          dismissButtonDisabled={processing}
        >
          <Formik
            enableReinitialize={true}
            innerRef={formikRef}
            initialValues={{
              email: user.email,
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                        .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL)
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
              password: Yup.string()
                           .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            })}
            onSubmit={(values, helpers) => {
              dispatch(companyPassOwnership({
                companyId: company.id,
                memberId: member.id,
                password: values.password,
              }, () => {
                refreshInfo(new Date());
                setModalOpen(false);
                addToast('Ownership changed', {appearance: 'success'});
              }, (data) => {
                handleErrors(data, helpers);
              }));
            }}
          >
            {props => <>
              <PayvyGlobalFormError/>
              <div className={'flex'}>Ownership of this Payvy account will be permanently transferred
                to {member.email}. You will become an administrator of this account.
              </div>
              <PayvyLabeledInput
                label={'Email'}
                as={PayvyInput}
                name={'email'}
                placeholder={'Email'}
                disabled={true}
              />
              <span>Additional authentication required. To continue, please enter your password.</span>
              <PayvyLabeledInput
                label={'Password'}
                name={'password'}
                as={PayvyInput}
                type={'password'}
              />
              <button ref={changeOwnershipForm} type="hidden" onClick={() => props.handleSubmit()}/>
            </>}
          </Formik>
        </ModalPayvyBase>
      </>;
    }
  }
  return '';
};
