import {Form, Formik} from "formik";
import moment from "moment/moment";
import React, {useEffect, useRef, useState} from 'react';
import {MdAddCircle} from "react-icons/md";
import {NumericFormat} from "react-number-format";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {BigCenteredLoadingBlock, OpenBillsOfContact, SuggestionBolt} from "../../../comps/elements";
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyIconButtonGroup,
  PayvyInput,
  PayvyLabeledInput,
  SearchContact,
  SelectAccountingCategory,
  SelectAccountingClass,
  SelectApproval
} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES, PAYVY_LOGOS, PAYVY_URL} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector} from '../../../slices/companies';
import {createBill, getUserBills, resetBillItems, resetBillStateToInitial} from '../../../slices/newBill';
import {getContactDetails, resetContactToInitialState} from "../../../slices/newContact";
import {
  assignUserInboxItemPageToBill,
  getInboxItemProcessingInformation,
  getUserInboxItem,
  updateInboxPageData
} from '../../../slices/newInbox'
import {getOCR, ocrSelector, resetOCR} from '../../../slices/ocr';
import {build_error_message, formatDateForAPItoISO, handleErrors, payvyToast} from '../../../utils/Utility';
import {DuplicateContentPageInformation} from "./DuplicateContentPageInformation";

const PageInformation = ({
    inboxItem,
    currentPage,
    pages = [],
  }) => {
    const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
    const navigate = useNavigate();
    const [tries, _setTries] = React.useState(1);
  const [newContactMode, setNewContactMode] = React.useState(false);
    const {
      loading: {
        list: inboxListLoading,
        item: inboxLoading,
        posting: inboxProcessing,
        documentProcessing,
      },
    } = useSelector((state) => state.inbox)

    const {
      loading: {
        list: billListLoading,
        item: billLoading,
        posting: billProcessing
      },
    } = useSelector((state) => state.bill);
    const {company: {has_qb_integration: hasQBIntegration}} = useSelector(companiesSelector);
    const {
      loading: OCRLoading,
      suggestions = {},
    } = useSelector(ocrSelector);
    const {items} = useSelector((state) => state.contact);
    const {
      id: inboxItemId,
      contact,
      processed = false,
      ocr_processed: ocrProcessed = null,
    } = inboxItem;
    useEffect(() => {
      const timer = setInterval(() => {
        if(processed === null || ocrProcessed === null) {
          if(!documentProcessing) {
            dispatch(getInboxItemProcessingInformation({
              id: inboxItem.id,
              forceUpdate: true
            }))
            .then(() => _setTries(tries + 1));
          }
        }
      }, 5 * 1000);
      return () => {
        clearInterval(timer);
      };
    }, [processed, ocrProcessed, documentProcessing, inboxItem.id, dispatch, tries]);
    const [contactID, setContactID] = useState(contact?.id);
    const {id: contactId} = contact || {};
    const {
      invoice_number: ocrInvoiceNumber,
      invoice_amount: ocrAmount,
      contact: ocrContact,
      invoice_date: ocrInvoiceDate,
      payment_due_date: ocrPaymentDueDate,
      invoice_tax: ocrTax,
      invoice_subtotal: ocrSubtotal,
    } = suggestions || {};
    const {id: ocrContactId} = ocrContact || {};
    const {
      id: inboxItemPageId,
      invoice_number: invoiceNumber,
      total_amount: totalAmount,
      assigned_to_bill: assignedToBill,
      invoice_date,
      due_date,
      tax,
      subtotal,
      quickbooks_account,
      quickbooks_class,
      deleted,
      is_duplicate: isDuplicate,
      duplicate_of: duplicateOf,
    } = pages[currentPage] || {};
    useEffect(() => {
      if(!contact?.id) {
        if(ocrContact?.id) {
          setContactID(ocrContact.id);
        } else {
          setContactID(null);
        }
      } else {
        setContactID(contact?.id);
      }
    }, [currentPage, setContactID, contact, ocrContact])
    const invoiceDate = invoice_date ? new Date(invoice_date).toLocaleDateString('en-US') : '',
      invoiceDueDate = due_date ? new Date(due_date).toLocaleDateString('en-US') : '',
      ocrDate = ocrInvoiceDate ? new Date(ocrInvoiceDate).toLocaleDateString('en-US') : '',
      ocrDueDate = ocrPaymentDueDate ? new Date(ocrPaymentDueDate).toLocaleDateString('en-US') : '';
    const selectedContact = Object.values(items)
                                  .flat()
                                  .find(item => parseInt(`${item.id}`) === parseInt(contactID))
    useEffect(() => {
      dispatch(resetOCR());
      if(inboxItemId && inboxItemPageId) dispatch(getOCR({
        id: inboxItemId,
        page: inboxItemPageId,
      }));
    }, [dispatch, inboxItemId, inboxItemPageId, ocrProcessed]);
    useEffect(() => {
      if(contactID && !newContactMode) dispatch(getUserBills({
        contact: contactID,
        status: 'draft,awaiting approval',
        forceUpdate: true,
      }));
    }, [dispatch, contactID, newContactMode])

    useEffect(() => {
      if(contactID && !newContactMode) dispatch(getContactDetails({id: contactID}))
    }, [dispatch, contactID, newContactMode]);
    // smooth out loading state changes
    const [loading, setLoading] = useState(inboxLoading || OCRLoading || inboxListLoading);
    const [processing, setProcessing] = useState(inboxProcessing || billProcessing);

    useEffect(() => {
      if(inboxLoading || OCRLoading || inboxListLoading) {
        setLoading(true);
      } else {
        const timeout = setTimeout(() => setLoading(false), 200);
        return () => clearTimeout(timeout);
      }
    }, [inboxLoading, OCRLoading, inboxListLoading]);

    useEffect(() => {
      if(inboxProcessing || billProcessing) {
        setProcessing(true);
      } else {
        const timeout = setTimeout(() => setProcessing(false), 500);
        return () => clearTimeout(timeout);
      }
    }, [inboxProcessing, billProcessing]);
    const readOnlyForm = assignedToBill;
    return <div
      className={`flex flex-col w-full md:w-192 border-l-2 border-neutral-100 shadow-inner px-2 h-full overflow-y-auto pb-10`}>
      {processing && <BigCenteredLoadingBlock text={'Saving page information'}/>}
      {loading && <BigCenteredLoadingBlock text={'Looking up page information'}/>}
      {!loading && !processing && ocrProcessed === null &&
        <BigCenteredLoadingBlock text={'Payvy is still processing document'}/>}
      {isDuplicate && !loading && !processing && <DuplicateContentPageInformation
        inboxItemId={inboxItemId}
        inboxItemPageId={inboxItemPageId}
        deleted={deleted}
        duplicateOf={duplicateOf}
        invoiceNumber={invoiceNumber}
        invoiceDate={invoiceDate}
        invoiceDueDate={invoiceDueDate}
        tax={tax}
        ocrInvoiceNumber={ocrInvoiceNumber}
        ocrDate={ocrDate}
        ocrDueDate={ocrDueDate}
        ocrTax={ocrTax}
        subtotal={subtotal}
        ocrSubtotal={ocrSubtotal}
        totalAmount={totalAmount}
        ocrAmount={ocrAmount}
      />}
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          saveInvoice: true,
          createNewBillForm: false,
          billToAdd: null,
          billName: '',
          billDate: '',
          billDueDate: '',
          billApprovers: '',
          invoiceContact: selectedContact ? selectedContact.id : contactId ? contactId : ocrContact?.id,
          invoiceContactIsNew: false,
          invoiceContactType: 'vendor',
          invoiceNumber: invoiceNumber ? invoiceNumber : ocrInvoiceNumber || '',
          invoiceTotal: totalAmount ? totalAmount / 100 : ocrAmount,
          invoiceDate: invoiceDate !== '' ? invoiceDate : ocrDate,
          invoiceDueDate: invoiceDueDate !== '' ? invoiceDueDate : ocrDueDate,
          invoiceTax: tax ? tax / 100 : ocrTax || 0,
          invoiceSubtotal: subtotal ? subtotal / 100 : ocrSubtotal || 0,
          quickbooksAccount: quickbooks_account,
          quickbooksClass: quickbooks_class,
          quickbooksContactId: selectedContact?.quickbooks_id
        }}
        validationSchema={Yup.object({
          saveInvoice: Yup.boolean(),
          createNewBillForm: Yup.boolean(),
          billToAdd: Yup.number()
                        .integer()
                        .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                        .when(['saveInvoice', 'createNewBillForm'], {
                          is: false,
                          then: (schema) => schema
                          .integer()
                          .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                          otherwise: (schema) => schema.nullable(),
                        }),
          billName: Yup.string()
                       .when(['saveInvoice', 'createNewBillForm'], {
                         is: (saveInvoice, createNewBillForm) => !saveInvoice && createNewBillForm,
                         then: (schema) => schema
                         .max(21, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 21}))
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                         otherwise: (schema) => schema.nullable(),
                       }),
          billDate: Yup.date()
                       .nullable()
                       .when(['saveInvoice', 'createNewBillForm'], {
                         is: (saveInvoice, createNewBillForm) => !saveInvoice && createNewBillForm,
                         then: (schema) => schema.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                         otherwise: (schema) => schema.nullable(),
                       }),
          billDueDate: Yup.date()
                          .nullable()
                          .when(['saveInvoice', 'createNewBillForm'], {
                            is: (saveInvoice, createNewBillForm) => !saveInvoice && createNewBillForm,
                            then: (schema) => schema.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                            otherwise: (schema) => schema.nullable(),
                          })
                          .when('billDate', (
                            fields,
                            schema
                          ) => fields[0] ? schema.min(moment(fields[0], 'MM/DD/YYYY')
                            .toDate()
                            , FORM_ERROR_MESSAGES.MUST_BE_BIGGER_THAN_DATE) : schema),
          billApprovers: Yup.number()
                            .when(['saveInvoice', 'createNewBillForm'], {
                              is: (saveInvoice, createNewBillForm) => !saveInvoice && createNewBillForm,
                              then: (schema) => schema.integer()
                                                      .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER),
                              otherwise: (schema) => schema.nullable(),
                            }),
          invoiceContactIsNew: Yup.boolean(),
          invoiceContact: Yup.mixed()
                             .when('invoiceContactIsNew', {
                               is: true,
                               then: () => Yup.string()
                                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                               otherwise: () => Yup.number()
                                                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                             }),
          invoiceContactType: Yup.string()
                                 .oneOf(['vendor', 'customer'])
                                 .when('invoiceContactIsNew', {
                                   is: true,
                                   then: (schema) => schema
                                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                                   otherwise: (schema) => schema.nullable(),
                                 }),
          invoiceNumber: Yup.string()
                            .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255}))
                            .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          invoiceTotal: Yup.number()
                           .min(0, FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                           .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          invoiceDate: Yup.date()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          invoiceDueDate: Yup.date()
                             .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                             .when('invoiceDate', (
                               fields,
                               schema
                             ) => fields[0] ? schema.min(moment(fields[0], 'MM/DD/YYYY')
                               .toDate()
                               , FORM_ERROR_MESSAGES.MUST_BE_BIGGER_THAN_DATE) : schema),
          invoiceTax: Yup.number()
                         .min(0, FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          invoiceSubtotal: Yup.number()
                              .min(0, FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          quickbooksAccount: Yup.number()
                                .nullable(),
          quickbooksClass: Yup.number()
                              .nullable(),
        })}
        onSubmit={(values, helpers) => {
          const page = pages[currentPage];
          dispatch(updateInboxPageData({
            id: inboxItemId,
            page: inboxItemPageId,
            body: {
              invoice_number: values.invoiceNumber,
              total_amount: values.invoiceTotal,
              invoice_date: values?.invoiceDate ? formatDateForAPItoISO(values.invoiceDate) : undefined,
              due_date: values?.invoiceDueDate ? formatDateForAPItoISO(values.invoiceDueDate) : undefined,
              invoice_contact: values.invoiceContact,
              invoice_contact_new: values.invoiceContactIsNew,
              invoice_contact_type: values.invoiceContactType,
              tax: values.invoiceTax,
              subtotal: values.invoiceSubtotal,
              quickbooks_account: values.quickbooksAccount,
              quickbooks_class: values.quickbooksClass,
            },
            successCallback: () => {
              if(values.invoiceContactIsNew) {
                dispatch(resetContactToInitialState())
              }
              dispatch(getUserInboxItem({
                id: inboxItemId,
                errorCallback: () => payvyToast('Failed to refresh item information', {appearance: 'error'}),
              }))
              if(!values.saveInvoice) {
                if(!values.billToAdd) {
                  dispatch(createBill({
                    body: {
                      name: values.billName,
                      created: formatDateForAPItoISO(values.billDate),
                      due_date: formatDateForAPItoISO(values.billDueDate),
                      contact_id: values.invoiceContact,
                      approvers: values.billApprovers,
                    },
                    successCallback: (data) => {
                      dispatch(assignUserInboxItemPageToBill({
                          id: inboxItem.id,
                          page: page.id,
                          body: {
                            bill: data.id,
                            contact: values.invoiceContact,
                          },
                          successCallback: () => {
                            dispatch(getUserInboxItem({
                              id: inboxItem.id,
                              successCallback: () => {
                                payvyToast('Bill created successfully.', {appearance: 'success'})
                                resetBillItems();
                                navigate(PAYVY_URL.INBOX.RECEIVED);
                              },
                              errorCallback: () => payvyToast('Failed to refresh item information', {appearance: 'error'}),
                            }))
                          },
                          errorCallback: (error = {}) => {
                            const data = error.data || {};
                            if(error?.message) payvyToast(error.message, {appearance: 'error'});
                            handleErrors(data, helpers);
                          },
                        }
                      ));
                    },
                    errorCallback: (error) => {
                      const data = error.data || {};
                      if('message' in error) {
                        payvyToast(error.message, {appearance: 'error'});
                      }
                      handleErrors(data, helpers);
                    }
                  }));
                } else {
                  dispatch(assignUserInboxItemPageToBill({
                      id: inboxItem.id,
                      page: page.id,
                      body: {
                        bill: values.billToAdd,
                        contact: values.invoiceContact,
                      },
                      successCallback: () => {
                        dispatch(getUserInboxItem({
                          id: inboxItem.id,
                          successCallback: () => {
                            payvyToast('Page attached successfully to bill.', {appearance: 'success'})
                            resetBillStateToInitial();
                          },
                          errorCallback: () => payvyToast('Failed to refresh item information', {appearance: 'error'}),
                        }))
                      },
                      errorCallback: (error = {}) => {
                        const data = error.data || {};
                        if(error?.message) payvyToast(error.message, {appearance: 'error'});
                        handleErrors(data, helpers);
                      },
                    }
                  ));
                }
              } else {
                payvyToast('Page has been saved.', {appearance: 'success'});
              }
            },
            errorCallback: (error) => {
              const data = error.data || {};
              if('message' in error) {
                let message = error.message;
                if(message === 'Bad Request') {
                  message = 'Failed to save page information.';
                }
                payvyToast(message, {appearance: 'error'});
              }
              handleErrors(data, helpers);
            }
          }));
        }}
      >
        {props => {
          if(props.values.quickbooksContactId === 'Pending save') {
            props.setFieldValue('quickbooksContactId', selectedContact?.quickbooks_id)
            props.setFieldValue('invoiceContact', selectedContact?.id)
          }
          if(contactID === null && props.values.invoiceContact !== null) {
            props.setFieldValue('invoiceContact', null);
          }
          return <Form
            className={`flex flex-col ${loading || processing || ocrProcessed === null || isDuplicate ? 'hidden' : ''}`}>
            <h1
              className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>Vendor
              Association</h1>
            <div className={'flex flex-col md:flex-row gap-2 px-2'}>
              <div className={'flex-inline w-full'}>
                <PayvyLabeledInput
                  label={<>Contact <SuggestionBolt
                    suggested={ocrContactId === props.values.invoiceContact}/></>}
                  name={'invoiceContact'}
                >
                  <SearchContact
                    setField={({
                      value,
                      __isNew__: isNew = false
                    }) => {
                      props.setValues({
                        ...props.values,
                        invoiceContact: value,
                        invoiceContactIsNew: isNew,
                        invoiceContactType: 'vendor',
                      })
                      setContactID(value);
                      setNewContactMode(isNew);
                    }}
                    value={contactID}
                    readOnly={readOnlyForm}
                    isClearable={true}
                    allowAddition={true}
                  />
                </PayvyLabeledInput>
              </div>
            </div>
            {props.values.invoiceContactIsNew && <>
              <h1
                className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>
                Assign type for contact</h1>
              <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                <div className={'flex-inline w-full m-2'}>
                  <PayvyIconButtonGroup
                    value={props.values.invoiceContactType}
                    onChange={(value) => props.setFieldValue('invoiceContactType', value)}
                    choices={[
                      {
                        label: 'Vendor',
                        value: 'vendor',
                      },
                      {
                        label: 'Customer',
                        value: 'customer',
                      },
                    ]}/>
                </div>
              </div>
            </>}
            {!!props.values.invoiceContact && !props.values.invoiceContactIsNew && <>
              <OpenBillsOfContact
                contactId={props.values.invoiceContact}
                readOnly={readOnlyForm}
                loading={billListLoading || billLoading}
                onClick={(billId) => {
                  props.setFieldValue('billToAdd', billId);
                  props.setFieldValue('saveInvoice', false);
                  props.handleSubmit();
                }}/>
            </>}
            {!!props.values.invoiceContact && !props.values.invoiceaContactIsNew && !props.values.createNewBillForm && !readOnlyForm && <>
              <PayvyIconButton
                Icon={MdAddCircle}
                buttonText={'Create New Bill'}
                onClick={() => props.setFieldValue('createNewBillForm', true)}
              />
            </>}
            {!!props.values.invoiceContact && props.values.createNewBillForm && <>
              <h1
                className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>
                Create New Bill
              </h1>
              <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                <div className={'flex-inline w-full md:w-6/12'}>
                  <PayvyLabeledInput
                    label={<>Bill Name <SuggestionBolt
                      suggested={ocrInvoiceNumber === props.values.billName}/></>}
                    name={'billName'}
                    placeholder={'Bill Name'}
                    as={PayvyInput}
                    readOnly={readOnlyForm}
                  />
                </div>
                <div className={'flex-inline w-full md:w-6/12'}>
                  <div className={'flex-inline w-full'}>
                    <PayvyLabeledInput
                      label={'Select Approval'}
                      name={'billApprovers'}
                      alwaysLabel={true}
                    >
                      <SelectApproval
                        setField={value => props.setFieldValue('billApprovers', [value])}
                        value={props.values.billApprovers}
                        readOnly={readOnlyForm}
                      />
                    </PayvyLabeledInput>
                  </div>
                </div>
              </div>
              <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                <div className={'flex-inline w-full md:w-6/12'}>
                  <PayvyLabeledInput
                    label={<>Bill Date <SuggestionBolt suggested={ocrDate === props.values.billDate}/></>}
                    name={'billDate'}
                    alwaysLabel={true}
                  >
                    <PayvyDatePicker
                      value={props.values.billDate}
                      name={'billDate'}
                      onChange={(value) => props.setFieldValue('billDate', value)}
                      clearIcon={null}
                      disabled={readOnlyForm}
                    />
                  </PayvyLabeledInput>
                </div>
                <div className={'flex-inline w-full md:w-6/12'}>
                  <PayvyLabeledInput
                    label={<>Due Date <SuggestionBolt suggested={ocrDate === props.values.billDueDate}/></>}
                    name={'billDueDate'}
                    alwaysLabel={true}
                  >
                    <PayvyDatePicker
                      value={props.values.billDueDate}
                      name={'billDueDate'}
                      onChange={(value) => props.setFieldValue('billDueDate', value)}
                      clearIcon={null}
                      disabled={readOnlyForm}
                    />
                  </PayvyLabeledInput>
                </div>
              </div>
              <div className={'flex flex-col md:flex-row gap-2 px-2 justify-end'}>
                <PayvyIconButton
                  buttonText={'Cancel'}
                  onClick={() => props.setFieldValue('createNewBillForm', false)}
                  loading={processing || loading}
                  disabled={processing || loading}
                  borderSize={2}
                  borderColor={'slate-300'}
                  mainColor={'neutral-0'}
                  textColor={'slate-300'}
                  hoverTextColor={'neutral-900'}
                  shortcutKey={'escape'}
                />
                <PayvyIconButton
                  buttonText={'Create Bill'}
                  onClick={() => {
                    props.setFieldValue('billToAdd', null);
                    props.setFieldValue('saveInvoice', false);
                    props.handleSubmit();
                  }}
                  loading={processing || loading}
                  disabled={processing || loading}
                />
              </div>
            </>}
            <h1 className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>
              Invoice Details
            </h1>
            <div className={'flex flex-col md:flex-row gap-2 px-2'}>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Bill Name <SuggestionBolt
                    suggested={ocrInvoiceNumber === props.values.invoiceNumber}/></>}
                  name={'invoiceNumber'}
                  placeholder={'Invoice Number'}
                  as={PayvyInput}
                  readOnly={readOnlyForm}
                />
              </div>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Invoice Date <SuggestionBolt suggested={!!ocrDate}/></>}
                  name={'invoiceDate'}
                  alwaysLabel={true}
                >
                  <PayvyDatePicker
                    value={props.values.invoiceDate}
                    name={'invoiceDate'}
                    onChange={(value) => props.setFieldValue('invoiceDate', value)}
                    clearIcon={null}
                    disabled={readOnlyForm}
                  />
                </PayvyLabeledInput>
              </div>
            </div>
            <div className={'flex flex-col md:flex-row gap-2 px-2'}>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Total <SuggestionBolt suggested={ocrAmount === props.values.invoiceTotal}/></>}
                  name={'invoiceTotal'}
                  alwaysLabel={true}
                >
                  <NumericFormat
                    value={props.values.invoiceTotal}
                    onValueChange={(value) => props.setFieldValue('invoiceTotal', value.floatValue)}
                    readOnly={readOnlyForm}
                    thousandSeparator={true}
                    prefix={'$'}
                    placeholder="$0.00"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className={`w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500`}
                  />
                </PayvyLabeledInput>
              </div>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Due Date <SuggestionBolt suggested={!!ocrDueDate}/></>}
                  name={'invoiceDueDate'}
                  alwaysLabel={true}
                >
                  <PayvyDatePicker
                    value={props.values.invoiceDueDate}
                    name={'invoiceDueDate'}
                    onChange={(value) => props.setFieldValue('invoiceDueDate', value)}
                    clearIcon={null}
                    disabled={readOnlyForm}
                  />
                </PayvyLabeledInput>
              </div>
            </div>
            <div className={'flex flex-col md:flex-row gap-2 px-2'}>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Tax <SuggestionBolt suggested={!!ocrTax}/></>}
                  name={'invoiceTax'}
                  alwaysLabel={true}
                >
                  <NumericFormat
                    value={props.values.invoiceTax}
                    onValueChange={(value) => props.setFieldValue('invoiceTax', value.floatValue)}
                    readOnly={readOnlyForm}
                    thousandSeparator={true}
                    prefix={'$'}
                    placeholder="$0.00"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                  />
                </PayvyLabeledInput>
              </div>
              <div className={'flex-inline w-full md:w-6/12'}>
                <PayvyLabeledInput
                  label={<>Subtotal <SuggestionBolt suggested={!!ocrSubtotal}/></>}
                  name={'invoiceSubtotal'}
                  alwaysLabel={true}
                >
                  <NumericFormat
                    value={props.values.invoiceSubtotal}
                    onValueChange={(value) => props.setFieldValue('invoiceSubtotal', value.floatValue)}
                    readOnly={readOnlyForm}
                    thousandSeparator={true}
                    prefix={'$'}
                    placeholder="$0.00"
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                  />
                </PayvyLabeledInput>
              </div>
            </div>
            {hasQBIntegration && <>
              <h1
                className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700 p-1'}>
                General Ledger <img alt={'QuickBooks logo'} src={PAYVY_LOGOS.QBO} className={'w-5 h-5 mx-1'}/>
              </h1>
              {selectedContact?.quickbooks_id == null ?
                <h2
                  className={'flex flex-col w-full pt-1 mx-1 mt-1 mb-2 font-light text-red-400 justify-center text-center'}>
                  <div>
                    {selectedContact ? <><span className={'font-bold'}>{selectedContact?.name}</span> has no linked ledger
                      vendor.</> : <>Select a contact to see their ledger vendor.</>}
                  </div>
                  <div>This invoice will not upload to General Ledger.</div>
                </h2>
                :
                <>
                  <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                    <div className={'flex-inline w-full'}>
                      <PayvyLabeledInput
                        label={'Category'}
                        name={'quickbooksAccount'}
                        placeholder={'Category'}
                      >
                        <SelectAccountingCategory
                          value={props.values.quickbooksAccount}
                          setField={value => props.setFieldValue('quickbooksAccount', value)}
                        />
                      </PayvyLabeledInput>
                    </div>
                  </div>
                  <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                    <div className={'flex-inline w-full md:w-4/12'}>
                      <PayvyLabeledInput
                        label={'GL Vendor'}
                        name={'quickbooksContactId'}
                        placeholder={'Vendor ID'}
                        as={PayvyInput}
                        readOnly={true}
                        className={'text-sm py-2 px-1'}
                      />
                    </div>
                    <div className={'flex-inline w-full md:w-8/12'}>
                      <PayvyLabeledInput
                        label={'Class'}
                        name={'quickbooksClass'}
                        placeholder={'Class'}
                      >
                        <SelectAccountingClass
                          value={props.values.quickbooksClass}
                          setField={value => props.setFieldValue('quickbooksClass', value)}
                        />
                      </PayvyLabeledInput>
                    </div>
                  </div>
                </>}
            </>}
            <GlobalFormError errors={props.errors['nonFieldErrors']}/>
            {!readOnlyForm && <PayvyIconButton
              buttonText={'Save Changes'}
              onClick={() => {
                props.setFieldValue('billToAdd', null);
                props.setFieldValue('saveInvoice', true);
                props.handleSubmit();
              }}
              fullWidth={true}
              loading={loading || inboxLoading}
              disabled={processing || loading}
            />}
          </Form>
        }}
      </Formik>
    </div>
  }
;

export default PageInformation;
