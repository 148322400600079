import React from 'react';
import {AiOutlineCloudDownload, AiOutlineDownload} from 'react-icons/ai';
import {BiHide} from 'react-icons/bi';
import {FaRedo, FaUndo} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalInboxRemove} from '../../../comps/modals';
import {hasPermission} from '../../../slices/companies';
import {getUserInboxItem, rotateInboxPage} from "../../../slices/newInbox";
import {userSelector} from '../../../slices/user';
import {payvyToast} from "../../../utils/Utility";

const MenuBar = ({
  inboxItem,
  inboxItemPageId,
  setShowDeletedPages,
  showDeletedPages,
}) => {
  const dispatch = useDispatch();
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector((state) => state.companies);
  const {
    loading: {posting: processing},
  } = useSelector((state) => state.inbox)
  const inboxCanUpdatePermission = hasPermission(company, userId, 'inbox_can_update');
  const inboxCanDeletePermission = hasPermission(company, userId, 'inbox_can_delete');

  const {
    id: inboxItemId,
    original_attachment: originalAttachment = {},
    attachment = {},
  } = inboxItem || {};

  const toggleDisplayDeletedPages = () => {
    setShowDeletedPages();
  };
  const rotatePage = (reverse) => {
    if(!!inboxItemId && inboxItemPageId) {
      dispatch(rotateInboxPage({
        id: inboxItemId,
        page: inboxItemPageId,
        body: {reverse},
        successCallback: () => {
          dispatch(getUserInboxItem({
            id: inboxItemId,
            successCallback: () => payvyToast('Item information is refreshed', {'appearance': 'success'}),
            errorCallback: () => payvyToast('Failed to refresh item information', {'appearance': 'error'}),
          }))
        },
        errorCallback: (error = {}) => {
          if(error?.message) payvyToast(error.message, {appearance: 'error'});
        }
      }));
    }
  };
  return <div className="flex divide-x w-full py-2 m-0 bg-neutral-50 border-b border-neutral-200">
    <div className={'flex-none'}>
      <PayvyIconButton
        Icon={BiHide}
        onClick={toggleDisplayDeletedPages}
        tooltipContent={showDeletedPages ? 'Hide Deleted Pages' : 'Show Deleted Pages'}
        tooltipPlace={'bottom-start'}
        mainColor={showDeletedPages ? 'none' : 'neutral-100'}
        hoverColor={showDeletedPages ? 'slate-300' : 'neutral-100'}
        hoverTextColor={'neutral-700'}
        borderColor={'none'}
        iconColor={showDeletedPages ? 'neutral-500' : 'slate-300'}
        iconSize={22}
      />
    </div>
    <div className={'flex-none'}>
      {attachment ? <PayvyIconButton
        Icon={AiOutlineCloudDownload}
        type={'a'}
        href={attachment.url}
        tooltipContent={'Download Attachment'}
        tooltipPlace={'bottom-start'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      /> : null}
      {originalAttachment ? <PayvyIconButton
        Icon={AiOutlineDownload}
        type={'a'}
        href={originalAttachment.url}
        tooltipContent={'Download Original Attachment'}
        tooltipPlace={'bottom'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      /> : null}
    </div>
    {inboxCanDeletePermission ? <div className={'flex-none'}>
      <ModalInboxRemove
        item={inboxItem}
        detailStyle={true}
      />
    </div> : null}
    {inboxCanUpdatePermission ? <div className={'flex-none'}>
      <PayvyIconButton
        Icon={FaUndo}
        onClick={() => rotatePage(true)}
        disabled={processing || !(!!inboxItemId && !!inboxItemPageId)}
        tooltipContent={'Rotate Left'}
        tooltipPlace={'bottom'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      />
      <PayvyIconButton
        Icon={FaRedo}
        onClick={() => rotatePage(false)}
        disabled={processing || !(!!inboxItemId && !!inboxItemPageId)}
        tooltipContent={'Rotate Right'}
        tooltipPlace={'bottom'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      />
    </div> : null}
    <div className={'flex w-full justify-end'}>
      <div className={'px-2 self-center text-lg text-neutral-300'}>#{inboxItem.id}</div>
    </div>
  </div>;
};

export default MenuBar;
