import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {PAYVY_LOGOS, PAYVY_URL} from '../../constants';
import {activateUserAccount} from '../../slices/auth';
import {getUserCompanies} from '../../slices/companies';
import {getUser} from '../../slices/user';

const AccountVerifyLandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {hash} = useParams();
  useEffect(() => {
    dispatch(activateUserAccount({
      hash,
      success: () => {
        dispatch(getUserCompanies({
          success: () => {
            dispatch(getUser({
              success: () => {
                navigate(PAYVY_URL.DASHBOARD);
              }
            }));
          }
        }));
      },
      failure: () => {
        navigate(PAYVY_URL.DASHBOARD);
      }
    }));
  }, [dispatch, navigate, hash]);
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <p className={'text-center mb-3'}>
          Activating account...
        </p>
      </div>
    </div>
  </div>;
};

export default AccountVerifyLandingPage;
