import {noop} from 'lodash';
import React from 'react';
import {ModalTransactionRemove} from "./ModalTransactionRemove";

export function CellActions({
  column: {
    refreshList,
    canDelete = false,
  },
  row: {original},
}) {
  refreshList = refreshList || noop;
  return <>
    {canDelete ? <ModalTransactionRemove transaction={original} refreshList={refreshList}/> : null}
  </>;
}
