import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from "../../../comps/forms";
import {FINIX_APPLICATION_ID, FINIX_ENVIRONMENT, FINIX_PROCESSOR} from '../../../constants';
import {
  addPaymentInstrumentFromToken,
  finixSelector,
  resetFinixState,
  setInvoiceIdentities
} from '../../../slices/finix';
import Debugger from '../../../utils/Debug';
import {BlockPayvyError} from '../../InvoiceLandingPage/components/BlockPayvyError';

let PaymentForm;
const updateState = (state, setState, values) => setState({...state, ...values});

const postPayment = async({
  dispatch,
  data,
  merchant_id: merchantId,
  state,
  setState,
  paidSuccessfully,
}) => {
  const {
    fraud_session_id,
    processor,
    token,
  } = data;
  dispatch(addPaymentInstrumentFromToken({
    merchantId: merchantId,
    token,
    fraudSessionId: fraud_session_id,
    processor,
    publicEP: false,
    success: paidSuccessfully,
    failure: (data) => updateState(state, setState, {
      showCheckout: false,
      errors: data?.errors || [],
    }),
  }));
};


const fetchMerchant = async(dispatch, merchantId, buyerId, state, setState) => {
  if(merchantId || buyerId) {
    dispatch(setInvoiceIdentities({
      merchantId,
      buyerId,
    }));
  }
  updateState(state, setState, {
    showSpinner: false,
  });

  // initialize FinixAuth after fetching the merchant
  if(window.Finix) {
    window.FinixAuth = window.Finix.Auth('live', merchantId);
  }
};

const submitPaymentForm = ({
  dispatch,
  merchant_id,
  state,
  setState,
  paidSuccessfully,
}) => {
  updateState(state, setState, {
    errors: [],
  });
  let submitData = {};
  if(state.mode === 'ach') {
    submitData = {
      'account_type': state.account_type,
    };
  }
  if(PaymentForm)
    PaymentForm.submitWithData(FINIX_ENVIRONMENT, FINIX_APPLICATION_ID, submitData, function (err, res) {
      if(err) {
        const errorMessages = res.data?._embedded?.errors ?? [{}];
        const errors = [];

        for(const error of errorMessages) {
          const message = error.message || '';
          const code = error.code || '';
          const field = error.field || '';
          const newMessage = {
            message,
            code,
            field,
          };
          errors.push(newMessage);
        }
        updateState(state, setState, {
          errors,
        });
      } else {
        const token = res?.data?.id;
        const sessionKey = window.FinixAuth.getSessionKey();
        const data = {
          token,
          processor: FINIX_PROCESSOR,
          fraud_session_id: sessionKey,
        };
        if(token) {
          postPayment({
            dispatch,
            data,
            merchant_id,
            state,
            setState,
            paidSuccessfully,
          });
          updateState(state, setState, {
            loading: false,
            success: true,
          });
        } else {
          updateState(state, setState, {
            loading: false,
            success: false,
          });
        }
      }
    });
};

const initTokenizeForm = (card = true) => {
  const formFunction = (state, binInformation) => {
    if(state === undefined) {
      let brand = document.getElementById('brand');
      if(brand) brand.innerHTML = binInformation.cardBrand;
    }
  };
  PaymentForm = card ? window.PaymentForm.card(formFunction) : window.PaymentForm.bankAccount(formFunction);

  function defineField(elementId, type, placeholder) {
    const field = PaymentForm.field(type, {
      placeholder,
      styles: {
        default: {
          fontFamily: 'Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif',
          margin: 0,
          outline: 0,
          tapHighlightColor: 'rgba(255,255,255,0)',
          lineHeight: '1em',
          padding: '.5em',
          fontSize: '1em',
          background: '#fff',
          border: '1px solid rgba(34,36,38,.15)',
          color: 'rgba(0,0,0,.87)',
          borderRadius: '.3rem',
          boxShadow: '0 0 0 0 transparent inset',
          transition: 'color .1s ease,border-color .1s ease',
        },
        success: {
          color: '#5cb85c',
        },
        error: {
          color: '#d9534f',
        },
      },
    });
    document.getElementById(elementId)
            .appendChild(field);
  }

  if(card) {
    try {
      defineField('cc_number', 'number', 'Credit card number');
      defineField('cc_name', 'name', 'Name of card holder');
      defineField('cc_expiration_date', 'expiration_date', 'MM / YYYY');
      defineField('cc_security_code', 'security_code', 'CVC');
      defineField('cc_address_line1', 'address.line1', '430 S Jones St', 'required');
      defineField('cc_address_line2', 'address.line2', 'APT 202');
      defineField('cc_address_region', 'address.region', 'CA', 'required');
      defineField('cc_address_postal_code', 'address.postal_code', '94108', 'required');
      defineField('cc_address_city', 'address.city', 'San Francisco', 'required');
    } catch {
      Debugger.error('Clicking too fast, stopping form rendering...');
    }
  } else {
    try {
      defineField('ba_name', 'name', 'John Smith', 'required');
      defineField('ba_account_number', 'account_number', '000000000', 'required');
      defineField('ba_bank_code', 'bank_code', '123456789', 'required');
      defineField('ba_account_type', 'account_type', '123456789', 'required');
    } catch {
      Debugger.error('Clicking too fast, stopping form rendering...');
    }
  }
};

export const FinixAddPaymentMethod = ({
  merchantId,
  buyerId,
  addedSuccessfully,
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    flowStatus,
    merchant = {id: merchantId},
    buyer = {id: buyerId},
  } = useSelector(finixSelector);
  const [isACHPossible, setIsACHPossible] = useState(false);
  const [isCardPossible, setIsCardPossible] = useState(false);

  const [state, setState] = useState({
    showCheckout: true,
    formInitialized: false,
    loading: true,
    mode: 'initial',
    account_type: 'CHECKING',
    errors: [],
  });
  const {
    showCheckout,
    formInitialized,
    errors,
    success,
  } = state;
  useEffect(() => {
    if(flowStatus === 'fetched' && buyerId !== buyer?.id) dispatch(resetFinixState());
  }, [dispatch, flowStatus, buyerId, buyer]);
  useEffect(() => {
    // const achPossible = !!buyer?.id && !!merchant?.id;
    const achPossible = false;
    const cardPossible = !!merchant?.id;
    if(isACHPossible !== achPossible) setIsACHPossible(achPossible);
    if(isCardPossible !== cardPossible) setIsCardPossible(cardPossible);
    if(state.mode === 'initial') updateState(state, setState, {mode: achPossible ? 'ach' : 'card'});
  }, [buyer.id, merchant.id, isACHPossible, isCardPossible, state]);
  if(flowStatus === 'started' && !loading) fetchMerchant(dispatch, merchantId, buyerId, state, setState);
  if(flowStatus === 'fetching' || loading) return <div className={'invoice-setup-payment-method'}>Loading...</div>;
  if(success) return <div className={'invoice-setup-payment-method'}>
    <div className={'success'}>Payment Successful</div>
  </div>;

  if(flowStatus === 'fetched' && showCheckout && !formInitialized) {
    setTimeout(() => {
      initTokenizeForm(state.mode === 'card');
    }, 200);
    updateState(state, setState, {formInitialized: true});
  }
  const purchaseClicked = () => {
    updateState(state, setState, {loading: true});
    submitPaymentForm({
      dispatch,
      merchant_id: buyer?.id || merchant.id,
      state,
      setState,
      paidSuccessfully: addedSuccessfully,
    });
  };

  if(!isACHPossible && !isCardPossible) return <div className={''}>
    <div className={''}>
      <p>No payment methods available for this invoice.</p>
      <p>Please contact your merchant for more information.</p>
    </div>
  </div>;
  return <div className={''}>
    <BlockPayvyError errors={errors}/>
    {state.mode === 'card' ? <div>
      {loading ? <span>Loading...</span> : <form onSubmit={purchaseClicked}>
        <div className={'flex flex-col'}>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Name</label>
              <div id={'cc_name'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Card number</label>
              <div id={'cc_number'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>Expiration</label>
              <div id={'cc_expiration_date'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>CVC</label>
              <div id={'cc_security_code'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Line 1</label>
              <div id={'cc_address_line1'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Line 2</label>
              <div id={'cc_address_line2'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>City</label>
              <div id={'cc_address_city'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>State</label>
              <div id={'cc_address_region'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>Zip</label>
              <div id={'cc_address_postal_code'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
        </div>
      </form>}
    </div> : null}
    <div className={'flex justify-center mt-2'}>
      <PayvyIconButton
        buttonText={`Add Card`}
        onClick={purchaseClicked}
        type={'submit'}
        shortcutKey={['ctrl+enter', 'ctrl+s']}
      />
    </div>
  </div>;
};
