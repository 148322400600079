import {Form, Formik} from "formik";
import React from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput, PayvyTextArea} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import {getPaymentServiceInformation, saveServiceConfiguration} from "../../../slices/paymentSystem";

export const RevolutPaymentServiceForm = ({
  service,
  loading,
  formikRef
}) => {
  const dispatch = useDispatch();
  const {
    id,
    active_configuration: activeConfiguration,
  } = service;
  const {
    client_id: clientId = '',
    private_key: privateKey = ''
  } = activeConfiguration || {};
  return <Formik
    innerRef={formikRef}
    initialValues={{
      clientId: clientId,
      privateKey: privateKey
    }}
    validationSchema={Yup.object({
      clientId: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      privateKey: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
    })}
    onSubmit={values => {
      const trimmedPrivateKey = values.privateKey.trim();
      if(values.clientId === clientId && values.privateKey === trimmedPrivateKey) return;
      dispatch(saveServiceConfiguration({
        serviceId: id,
        configuration: {
          client_id: values.clientId,
          private_key: trimmedPrivateKey
        },
        success: () => {
          dispatch(getPaymentServiceInformation({}))
        }
      }))
    }}
  >
    {props => <Form className={'flex flex-col'}>
      <div className={'flex flex-col md:flex-row gap-2 px-2'}>
        <div className={'flex-inline w-full'}>
          <PayvyLabeledInput
            label={'Client ID'}
            name={'clientId'}
            placeholder={'Client ID'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Private Key'}
            name={'privateKey'}
            placeholder={'-----BEGIN RSA PRIVATE KEY-----\n...\n-----END RSA PRIVATE KEY-----'}
            as={PayvyTextArea}
            className={'h-24 resize-none'}
          />
        </div>
      </div>
      <GlobalFormError errors={props.errors['nonFieldErrors']}/>
      <PayvyIconButton
        buttonText={'Save Service Configuration'}
        onClick={props.handleSubmit}
        fullWidth={true}
        loading={loading}
        disabled={loading || (!props.dirty)}
        wrapperClassName={'mt-4'}
      />
    </Form>}
  </Formik>
}
