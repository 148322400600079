import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../constants";
import {linkRevolutAccount, paymentSystemSelector} from "../../slices/paymentSystem";

const RevolutLinkingLandingPage = () => {
  const {
    loading,
    processing,
  } = useSelector(paymentSystemSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const [processed, setProcessed] = React.useState(false);
  const [error, setError] = React.useState(null);
  useEffect(() => {
    if(code && !loading && !processing && !processed) {
      setProcessed(true);
      dispatch(linkRevolutAccount({
        code,
        success: () => {
          navigate(PAYVY_URL.SETTINGS.BANKING)
        },
        failure: (e) => {
          setError(e);
        }
      }))
    }
  }, [code, loading, processing, processed, dispatch, navigate]);
  const hasError = error !== null;
  return (
    <div className={'flex flex-col m-4'}>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        {hasError && <p className={'text-center'}>
          There was an error linking your account. The following error occurred:<br/>
          <small>{error?.non_field_errors?.[0]}</small>
        </p>}
        {!hasError && (processing || !processed) &&
          <p className={'text-center'}>
            Finishing linking your account. Please wait while we redirect you back to the app.
          </p>}
      </div>
    </div>
  );
};

export default RevolutLinkingLandingPage;
