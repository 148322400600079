import {useSelector} from 'react-redux';
import {authSelector} from '../slices/auth';
import {companiesSelector} from '../slices/companies';
import {dwollaSelector} from '../slices/dwolla';
import {kycSelector} from '../slices/kyc';
import {ocrSelector} from '../slices/ocr';
import {plaidSelector} from '../slices/plaid';
import {twilioSelector} from '../slices/twilio';
import {userSelector} from '../slices/user';

export const useIsEverythingLoaded = (apps = []) => {
  if(apps.length === 0) {
    apps = [
      'auth',
      'bill',
      'box',
      'companies',
      'contact',
      'dropbox',
      'dwolla',
      'inboxes',
      'integration',
      'kyc',
      'ocr',
      'plaid',
      'quickbooks',
      'transaction',
      'twilio',
      'user'
    ];
  }
  const {
    loading: authLoading = false,
    refreshLoading: authRefreshLoading = false,
    processing: authProcessing = false,
  } = useSelector(authSelector);
  const {
    loading: {
      list: billLoading = false,
      item: billProcessing = false,
      posting: billSendCommentLoading = false,
      deleting: billSavingPageLoading = false,
      failedTransactions: billTransactionsLoading = false,
      history: billHistoryLoading = false
    },
  } = useSelector((state) => state.bill);
  const {
    loading: {
      item: boxLoading = false,
      posting: boxProcessing = false
    }
  } = useSelector((state) => state.integrationBox);
  const {
    loading: companyLoading = false,
    loadingDetails: companyLoadingDetails = false,
    subscriptionsLoading: companySubscriptionsLoading = false,
    addressesLoading: companyAddressesLoading = false,
    representativesLoading: companyRepresentativesLoading = false,
    permissionsLoading: companyPermissionsLoading = false,
    processing: companyProcessing = false,
  } = useSelector(companiesSelector);
  const {
    loading: {
      list: contactLoading = false,
      posting: contactProcessing = false
    }
  } = useSelector((state) => state.contact);
  const {
    loading: {
      item: dropboxLoading = false,
      posting: dropboxProcessing = false
    }
  } = useSelector((state) => state.integrationDropbox);
  const {
    loading: dwollaLoading = false,
    processing: dwollaProcessing = false,
  } = useSelector(dwollaSelector);
  const {
    loading: {
      list: inboxListLoading = false,
      item: inboxItemLoading = false,
      posting: inboxProcessing = false
    },
  } = useSelector((state) => state.inbox);
  const inboxLoading = inboxListLoading && inboxItemLoading
  const {
    loading: {
      list: integrationListLoading = false,
      item: integrationItemLoading = false,
      posting: integrationProcessing = false
    },
  } = useSelector((state) => state.integration);
  const integrationLoading = integrationListLoading && integrationItemLoading;
  const {
    loading: kycLoading = false,
    processing: kycProcessing = false,
  } = useSelector(kycSelector);
  const {
    loading: ocrLoading = false,
  } = useSelector(ocrSelector);
  const {
    loading: plaidLoading = false,
    processing: plaidProcessing = false,
    loadingBankAccounts: plaidLoadingBankAccounts = false,
    loadingPlaidUserTokens: plaidLoadingPlaidUserTokens = false,
  } = useSelector(plaidSelector);
  const {
    loading: {
      item: quickbooksLoading = false,
      posting: quickbooksProcessing = false
    }
  } = useSelector((state) => state.integrationQuickbooks);
  const {
    loading: {
      list: transactionLoading = false,
      deleting: transactionProcessing = false
    },
  } = useSelector((state) => state.transactions);
  const {
    loading: twilioLoading = false,
    processing: twilioProcessing = false,
  } = useSelector(twilioSelector);
  const {
    loading: userLoading = false,
    processing: userProcessing = false,
    loadingNotificationSettings: userLoadingNotificationSettings = false,
    loadingPaymentMethods: userLoadingPaymentMethods = false,
    loadingInvoices: userLoadingInvoices = false,
    loadingSubscriptionPlans: userLoadingSubscriptionPlans = false,
    loadingAchFeePlans: userLoadingAchFeePlans = false,
    loadingCheckIssuingPlans: userLoadingCheckIssuingPlans = false,
    loadingTwilioNumberPlans: userLoadingTwilioNumberPlans = false,
    loadingTFA: userLoadingTFA = false,
  } = useSelector(userSelector);
  let authApp = authLoading || authRefreshLoading || authProcessing;
  let billApp = billLoading || billHistoryLoading || billSendCommentLoading || billSavingPageLoading || billTransactionsLoading || billProcessing;
  let boxApp = boxLoading || boxProcessing;
  let companyApp = companyLoading || companyLoadingDetails || companySubscriptionsLoading || companyAddressesLoading || companyRepresentativesLoading || companyPermissionsLoading || companyProcessing;
  let contactApp = contactLoading || contactProcessing;
  let dropboxApp = dropboxLoading || dropboxProcessing;
  let dwollaApp = dwollaLoading || dwollaProcessing;
  let inboxApp = inboxLoading || inboxProcessing;
  let integrationApp = integrationLoading || integrationProcessing;
  let kycApp = kycLoading || kycProcessing;
  let ocrApp = ocrLoading;
  let plaidApp = plaidLoading || plaidProcessing || plaidLoadingBankAccounts || plaidLoadingPlaidUserTokens;
  let quickbooksApp = quickbooksLoading || quickbooksProcessing;
  let transactionApp = transactionLoading || transactionProcessing;
  let twilioApp = twilioLoading || twilioProcessing;
  let userApp = userLoading || userProcessing || userLoadingNotificationSettings || userLoadingPaymentMethods || userLoadingInvoices || userLoadingSubscriptionPlans || userLoadingAchFeePlans || userLoadingCheckIssuingPlans || userLoadingTwilioNumberPlans || userLoadingTFA;
  let allApps = authApp || billApp || boxApp || companyApp || contactApp || dropboxApp || dwollaApp || inboxApp || integrationApp || kycApp || ocrApp || plaidApp || quickbooksApp || transactionApp || twilioApp || userApp;
  let queriedApps = false;
  if(apps.includes('auth')) queriedApps = queriedApps || authApp;
  if(apps.includes('bill')) queriedApps = queriedApps || billApp;
  if(apps.includes('box')) queriedApps = queriedApps || boxApp;
  if(apps.includes('companies')) queriedApps = queriedApps || companyApp;
  if(apps.includes('contact')) queriedApps = queriedApps || contactApp;
  if(apps.includes('dropbox')) queriedApps = queriedApps || dropboxApp;
  if(apps.includes('dwolla')) queriedApps = queriedApps || dwollaApp;
  if(apps.includes('inboxes')) queriedApps = queriedApps || inboxApp;
  if(apps.includes('integration')) queriedApps = queriedApps || integrationApp;
  if(apps.includes('kyc')) queriedApps = queriedApps || kycApp;
  if(apps.includes('ocr')) queriedApps = queriedApps || ocrApp;
  if(apps.includes('plaid')) queriedApps = queriedApps || plaidApp;
  if(apps.includes('quickbooks')) queriedApps = queriedApps || quickbooksApp;
  if(apps.includes('transaction')) queriedApps = queriedApps || transactionApp;
  if(apps.includes('twilio')) queriedApps = queriedApps || twilioApp;
  if(apps.includes('user')) queriedApps = queriedApps || userApp;
  return {
    all: allApps,
    queriedApps: queriedApps,
    auth: authApp,
    bill: billApp,
    box: boxApp,
    companies: companyApp,
    contact: contactApp,
    dropbox: dropboxApp,
    dwolla: dwollaApp,
    inboxes: inboxApp,
    integration: integrationApp,
    kyc: kycApp,
    ocr: ocrApp,
    plaid: plaidApp,
    quickbooks: quickbooksApp,
    transaction: transactionApp,
    twilio: twilioApp,
    user: userApp,
  };
};
