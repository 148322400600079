import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_LOGOS} from '../../../constants';
import {downloadInvoice} from '../../../utils/Utility';

export const InvoicePaid = ({invoice}) => {
  const invoiceAmount = invoice.amount / 100;
  return <div className={'flex flex-col'}>
    <div className={'w-full flex bg-neutral-50 sticky top-0 h-20 z-10'}>
      <div className={'hidden sm:flex h-full'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'h-full py-2'}
        />
      </div>
    </div>
    <div className={'flex flex-row'}>
      <div className="bg-neutral-0 w-full h-screen overflow-x-scroll p-0 pb-10">
        <div className={'payvy-main-content px-10 pb-10'}>
          <h1 className="p-4 w-full text-3xl">Success!</h1>
          <div className={'flex flex-row flex-wrap mb-10'}>
            <div className={'ml-4'}>
              <div className={'py-6'}>
                A payment of
                <CurrencyFormat
                  value={invoiceAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  className={'px-1 font-bold'}
                />
                has been made to {invoice.company.name}.
              </div>
              <div className={''}>
                <PayvyIconButton
                  buttonText={'Download Invoice'}
                  onClick={() => downloadInvoice(invoice.id)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};
