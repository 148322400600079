import React from 'react';
import {FaAngleRight} from "react-icons/fa";
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import {OCRInformation} from "../../../comps/elements/OCRInformation/OCRInformation";
import {PayvyIconButton} from "../../../comps/forms";

const TitleBar = ({
  inboxItem,
  OCRMode,
  setOCRMode
}) => {
  const {
    id,
    attachment: {
      name = ''
    } = {},
    ocr_processed: ocrProcessed = null,
    ocr_progress: ocrProgress = 0
  } = inboxItem;
  return <div className={'flex flex-col md:flex-row py-4 px-3 border-b border-neutral-500 gap-2'}>
    <div className={'flex grow'}>
      <div className={'flex flex-row text-xl items-center'}>
        <div className={'flex text-neutral-100'}>Inbox</div>
        <div className={'flex text-neutral-100 mt-1.5'}><FaAngleRight/></div>
        {id && <div className={'flex font-bold'}>{name}</div>}
      </div>
    </div>
    <div className={'flex flex-row'}>
      <OCRInformation ocrProcessed={ocrProcessed} ocrProgress={ocrProgress}/>
    </div>
    <div className={'flex flex-row'}>
      <PayvyIconButton
        Icon={OCRMode ? IoEyeOffOutline : IoEyeOutline}
        buttonText={OCRMode ? 'Hide Extracted Data' : 'View Extracted Data'}
        iconPaddingX={2}
        iconPaddingY={1}
        onClick={() => setOCRMode(!OCRMode)}
      />
    </div>
  </div>;
};

export default TitleBar;
