import moment from 'moment';

export function CellInvoicePeriod({
  value,
  column: {detailUrl},
  row: {
    original: {
      payment_period_start,
      payment_period_end
    }
  }
}) {
  return `${moment(payment_period_start)
  .format('ll')} - ${moment(payment_period_end)
  .format('ll')}`;
}
