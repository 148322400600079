import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import Debugger from "../utils/Debug";
import {build_url, refreshAccessToken, stringify} from '../utils/Utility';
import {getInvoicesList} from "./receivables";

export const initialState = {
  // Users
  loading: false,
  hasErrors: false,
  processing: false,
  user: {
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    avatar: '',
    is_superuser: false,
  },

  // Notification Settings
  loadingNotificationSettings: false,
  notificationSettingsHasErrors: false,
  notificationSettings: {
    new_inbox_document_email: false,
    new_inbox_document_sms: false,
    new_inbox_document_push: false,
    vendor_email_response_email: false,
    vendor_email_response_sms: false,
    vendor_email_response_push: false,
    vendor_sms_response_email: false,
    vendor_sms_response_sms: false,
    vendor_sms_response_push: false,
    bill_mentions_email: false,
    bill_mentions_sms: false,
    bill_mentions_push: false,
    ach_bill_payments_email: false,
    ach_bill_payments_sms: false,
    ach_bill_payments_push: false,
    check_bill_payments_email: false,
    check_bill_payments_sms: false,
    check_bill_payments_push: false,
    external_bill_payments_email: false,
    external_bill_payments_sms: false,
    external_bill_payments_push: false,
    my_bill_approvals_email: false,
    my_bill_approvals_sms: false,
    my_bill_approvals_push: false,
    overdue_bills_email: false,
    overdue_bills_sms: false,
    overdue_bills_push: false,
  },

  // Payment Methods
  loadingPaymentMethods: false,
  paymentMethods: [],

  // Invoices
  loadingInvoices: false,
  invoices: [],
  invoicesCount: 0,

  // Subscription Plans
  loadingSubscriptionPlans: false,
  subscriptionPlans: [],

  // ACH Fee Plans
  loadingAchFeePlans: false,
  achFeePlans: [],

  // Check Issuing Plans
  loadingCheckIssuingPlans: false,
  checkIssuingPlans: [],

  // Twilio Number Plans
  loadingTwilioNumberPlans: false,
  twilioNumberPlans: [],

  // Two-Factor Auth information
  tfaAppUrl: '',
  loadingTFA: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getCurrentUser: (state) => {
      state.loading = true;
    },
    getCurrentUserSuccess: (state, {payload}) => {
      state.user = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getCurrentUserFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getNotificationSettingsStart: (state) => {
      state.loadingNotificationSettings = true;
    },
    getNotificationSettingsSuccess: (state, {payload}) => {
      state.notificationSettings = payload;
      state.loadingNotificationSettings = false;
      state.notificationSettingsHasErrors = false;
    },
    getNotificationSettingsFailure: (state) => {
      state.loadingNotificationSettings = false;
      state.notificationSettingsHasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
    paymentMethodStart: (state) => {
      state.loadingPaymentMethods = true;
    },
    paymentMethodSuccess: (state, {payload}) => {
      state.loadingPaymentMethods = false;
      state.paymentMethods = payload;
    },
    paymentMethodFailure: (state) => {
      state.loadingPaymentMethods = false;
    },
    invoicesStart: (state) => {
      state.loadingInvoices = true;
    },
    invoicesSuccess: (state, {payload}) => {
      state.loadingInvoices = false;
      state.invoices = payload.results;
      state.invoicesCount = payload.count;
    },
    invoicesFailure: (state) => {
      state.loadingInvoices = false;
    },
    subscriptionPlansStart: (state) => {
      state.loadingSubscriptionPlans = true;
    },
    subscriptionPlansSuccess: (state, {payload}) => {
      state.loadingSubscriptionPlans = false;
      state.subscriptionPlans = payload;
    },
    subscriptionPlansFailure: (state) => {
      state.loadingSubscriptionPlans = false;
    },
    AchFeePlansStart: (state) => {
      state.loadingAchFeePlans = true;
    },
    AchFeePlansSuccess: (state, {payload}) => {
      state.loadingAchFeePlans = false;
      state.achFeePlans = payload;
    },
    AchFeePlansFailure: (state) => {
      state.loadingAchFeePlans = false;
    },
    CheckIssuingPlansStart: (state) => {
      state.loadingCheckIssuingPlans = true;
    },
    CheckIssuingPlansSuccess: (state, {payload}) => {
      state.loadingCheckIssuingPlans = false;
      state.checkIssuingPlans = payload;
    },
    CheckIssuingPlansFailure: (state) => {
      state.loadingCheckIssuingPlans = false;
    },
    TwilioNumberPlansStart: (state) => {
      state.loadingTwilioNumberPlans = true;
    },
    TwilioNumberPlansSuccess: (state, {payload}) => {
      state.loadingTwilioNumberPlans = false;
      state.twilioNumberPlans = payload;
    },
    TwilioNumberPlansFailure: (state) => {
      state.loadingTwilioNumberPlans = false;
    },
    TFAAppUrlStart: (state) => {
      state.loadingTFA = true;
    },
    TFAAppUrlSuccess: (state, {payload}) => {
      state.loadingTFA = false;
      state.tfaAppUrl = payload.url;
    },
    TFAAppUrlFailure: (state) => {
      state.loadingTFA = false;
    },
  },
});

export const {
  getCurrentUser,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  processingStart,
  processingFinish,
  paymentMethodStart,
  paymentMethodFailure,
  paymentMethodSuccess,
  invoicesStart,
  invoicesFailure,
  invoicesSuccess,
  subscriptionPlansStart,
  subscriptionPlansFailure,
  subscriptionPlansSuccess,
  AchFeePlansStart,
  AchFeePlansFailure,
  AchFeePlansSuccess,
  CheckIssuingPlansStart,
  CheckIssuingPlansFailure,
  CheckIssuingPlansSuccess,
  TwilioNumberPlansStart,
  TwilioNumberPlansFailure,
  TwilioNumberPlansSuccess,
  getNotificationSettingsStart,
  getNotificationSettingsFailure,
  getNotificationSettingsSuccess,
  TFAAppUrlStart,
  TFAAppUrlFailure,
  TFAAppUrlSuccess,
} = userSlice.actions;
export const userSelector = (state) => state.user;
export default userSlice.reducer;

export function getUser({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    if(!localStorage.getItem('accessToken')) {
      Debugger.info('📡 API: USER', 'No access token found, cannot load user.')
      failure();
      return;
    }
    dispatch(getCurrentUser());
    try {
      const response = await fetch(PAYVY_API.V1.USER.CURRENT, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getCurrentUserSuccess(data));
        success();
      } else if(response.status === 401) {
        if(data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getUser({
              success,
              failure
            }));
          }
        }
        localStorage.setItem('accessToken', '');
        localStorage.setItem('refreshToken', '');
        dispatch(getCurrentUserFailure());
        failure();
      } else {
        dispatch(getCurrentUserFailure());
        failure();
      }
    } catch(error) {
      dispatch(getCurrentUserFailure());
      failure();
    }
  };
}

export function changePassword({
  current_password,
  new_password,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.CHANGE_PASSWORD, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          current_password,
          new_password,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(changePassword({
              current_password,
              new_password,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function updateUserInformation({
  id,
  first_name,
  last_name,
  email,
  phone,
  username,
  job_title,
  bill_list_tab,
  date_of_birth,
  social_security_number,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.USER.UPDATE_USER, {id}), {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          email,
          phone,
          username,
          job_title,
          bill_list_tab,
          date_of_birth,
          social_security_number,
        }),
      });
      if(response.status === 200) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(updateUserInformation({
              id,
              first_name,
              last_name,
              email,
              phone,
              username,
              job_title,
              bill_list_tab,
              date_of_birth,
              social_security_number,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function changeUserAddress({
  line1,
  line2,
  city,
  state,
  zip,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.CHANGE_ADDRESS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          line1,
          line2,
          city,
          state,
          zip,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(changeUserAddress({
              line1,
              line2,
              city,
              state,
              zip,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function addPaymentMethod({
  payment_method_id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.ADD_PAYMENT_METHOD, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({payment_method_id}),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(addPaymentMethod({
              payment_method_id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function getUserPaymentMethods({
  success = noop,
  failure = noop
} = {}) {
  return async(dispatch) => {
    dispatch(paymentMethodStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.LIST_PAYMENT_METHODS, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(paymentMethodSuccess(data));
        success()
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getUserPaymentMethods({
              success,
              failure
            }));
          }
        }
        dispatch(paymentMethodFailure());
        failure()
      }
    } catch(error) {
      dispatch(paymentMethodFailure());
      failure()
    }
  };
}


export function removePaymentMethod({
  payment_method_id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.REMOVE_PAYMENT_METHOD, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({payment_method_id}),
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removePaymentMethod({
              payment_method_id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}


export function getUserInvoices({
  page = 1,
  page_size = 10,
} = {}) {
  return async(dispatch) => {
    dispatch(invoicesStart());
    try {
      const params = stringify({
        page,
        page_size,
      }, {skipEmptyString: true});
      const response = await fetch(`${PAYVY_API.V1.USER.LIST_INVOICES}?${params}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(invoicesSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getInvoicesList({
              page,
              page_size
            }));
          }
        }
        dispatch(invoicesFailure());
      }
    } catch(error) {
      dispatch(invoicesFailure());
    }
  };
}


export function getSubscriptionPlans() {
  return async(dispatch) => {
    dispatch(subscriptionPlansStart());
    try {
      const response = await fetch(`${PAYVY_API.V1.USER.LIST_SUBSCRIPTION_PLANS}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(subscriptionPlansSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getSubscriptionPlans());
          }
        }
        dispatch(subscriptionPlansFailure());
      }
    } catch(error) {
      dispatch(subscriptionPlansFailure());
    }
  };
}

export function getAchFeePlans() {
  return async(dispatch) => {
    dispatch(AchFeePlansStart());
    try {
      const response = await fetch(`${PAYVY_API.V1.USER.LIST_ACH_FEES}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(AchFeePlansSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getAchFeePlans());
          }
        }
        dispatch(AchFeePlansFailure());
      }
    } catch(error) {
      dispatch(AchFeePlansFailure());
    }
  };
}

export function getCheckIssuingPlans() {
  return async(dispatch) => {
    dispatch(CheckIssuingPlansStart());
    try {
      const response = await fetch(`${PAYVY_API.V1.USER.LIST_CHECK_ISSUING}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(CheckIssuingPlansSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getCheckIssuingPlans());
          }
        }
        dispatch(CheckIssuingPlansFailure());
      }
    } catch(error) {
      dispatch(CheckIssuingPlansFailure());
    }
  };
}

export function getTwilioNumberPlans() {
  return async(dispatch) => {
    dispatch(TwilioNumberPlansStart());
    try {
      const response = await fetch(`${PAYVY_API.V1.USER.LIST_TWILIO_NUMBERS}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(TwilioNumberPlansSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getTwilioNumberPlans());
          }
        }
        dispatch(TwilioNumberPlansFailure());
      }
    } catch(error) {
      dispatch(TwilioNumberPlansFailure());
    }
  };
}

export function updateSubscription({
  plan_id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.UPDATE_SUBSCRIPTION, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({plan_id}),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(updateSubscription({
              plan_id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}


export function startSubscription({
  plan_id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.START_SUBSCRIPTION, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({plan_id}),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(startSubscription({
              plan_id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function endSubscription({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.END_SUBSCRIPTION, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(endSubscription({
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}


export function getNotificationSettings({
  success = noop,
  failure = noop
} = {}) {
  return async(dispatch) => {
    dispatch(getNotificationSettingsStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.NOTIFICATION_SETTINGS, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        success(data);
        dispatch(getNotificationSettingsSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getNotificationSettings({
              success,
              failure
            }));
          }
        }
        failure(data);
        dispatch(getNotificationSettingsFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(getNotificationSettingsFailure());
    }
  };
}

export function saveNotificationSettings(
  {
    new_inbox_document_email,
    new_inbox_document_sms,
    new_inbox_document_push,
    vendor_email_response_email,
    vendor_email_response_sms,
    vendor_email_response_push,
    vendor_sms_response_email,
    vendor_sms_response_sms,
    vendor_sms_response_push,
    bill_mentions_email,
    bill_mentions_sms,
    bill_mentions_push,
    ach_bill_payments_email,
    ach_bill_payments_sms,
    ach_bill_payments_push,
    check_bill_payments_email,
    check_bill_payments_sms,
    check_bill_payments_push,
    external_bill_payments_email,
    external_bill_payments_sms,
    external_bill_payments_push,
    my_bill_approvals_email,
    my_bill_approvals_sms,
    my_bill_approvals_push,
    overdue_bills_email,
    overdue_bills_sms,
    overdue_bills_push,
    success = noop,
    failure = noop
  }) {
  success = success || noop;
  failure = failure || noop;
  return async(dispatch) => {
    dispatch(getNotificationSettingsStart());
    try {
      const response = await fetch(PAYVY_API.V1.USER.NOTIFICATION_SETTINGS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          new_inbox_document_email,
          new_inbox_document_sms,
          new_inbox_document_push,
          vendor_email_response_email,
          vendor_email_response_sms,
          vendor_email_response_push,
          vendor_sms_response_email,
          vendor_sms_response_sms,
          vendor_sms_response_push,
          bill_mentions_email,
          bill_mentions_sms,
          bill_mentions_push,
          ach_bill_payments_email,
          ach_bill_payments_sms,
          ach_bill_payments_push,
          check_bill_payments_email,
          check_bill_payments_sms,
          check_bill_payments_push,
          external_bill_payments_email,
          external_bill_payments_sms,
          external_bill_payments_push,
          my_bill_approvals_email,
          my_bill_approvals_sms,
          my_bill_approvals_push,
          overdue_bills_email,
          overdue_bills_sms,
          overdue_bills_push,
        }),
      });
      const data = await response.json();
      if(response.status === 200) {
        success(data);
        dispatch(getNotificationSettingsSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(saveNotificationSettings({
              new_inbox_document_email,
              new_inbox_document_sms,
              new_inbox_document_push,
              vendor_email_response_email,
              vendor_email_response_sms,
              vendor_email_response_push,
              vendor_sms_response_email,
              vendor_sms_response_sms,
              vendor_sms_response_push,
              bill_mentions_email,
              bill_mentions_sms,
              bill_mentions_push,
              ach_bill_payments_email,
              ach_bill_payments_sms,
              ach_bill_payments_push,
              check_bill_payments_email,
              check_bill_payments_sms,
              check_bill_payments_push,
              external_bill_payments_email,
              external_bill_payments_sms,
              external_bill_payments_push,
              my_bill_approvals_email,
              my_bill_approvals_sms,
              my_bill_approvals_push,
              overdue_bills_email,
              overdue_bills_sms,
              overdue_bills_push,
              success,
              failure
            }));
          }
        }
        failure(data);
        dispatch(getNotificationSettingsFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(getNotificationSettingsFailure());
    }
  };
}


export function getTFAAppUrl({
  success = noop,
  failure = noop
} = {}) {
  return async(dispatch) => {
    dispatch(TFAAppUrlStart());
    try {
      const response = await fetch(PAYVY_API.V1.TFA.CREATE_TOTP, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(TFAAppUrlSuccess(data));
        success();
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getTFAAppUrl({
              success,
              failure
            }));
          }
        }
        dispatch(TFAAppUrlFailure());
        failure();
      }
    } catch(error) {
      dispatch(TFAAppUrlFailure());
      failure();
    }
  };
}

export function setTFAMethod({
  method,
  code,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.TFA.VALIDATE, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          method,
          code,
        }),
      });
      if(response.status === 200) {
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(setTFAMethod({
                method,
                code,
                success,
                failure
              }));
            }
          }
        }
        failure("not 200");
      }
    } catch(error) {
      failure(error);
    }
    dispatch(processingFinish());
  };
}

export function createPhoneTFA({
  number,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.TFA.CREATE_SMS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({number}),
      });
      if(response.status === 200) {
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(createPhoneTFA({
                number,
                success,
                failure
              }));
            }
          }
        }
        failure();
      }
    } catch(error) {
      failure();
    }
    dispatch(processingFinish());
  };
}


export function removeTFA({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.TFA.REMOVE, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removeTFA({
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}
