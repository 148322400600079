import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {CellCurrency, CellDetailLink, CellFormattedDate} from '../../../comps/elements/PayvyTable';
import {PAYVY_URL} from '../../../constants';
import {getInvoicesList, receivablesSelector} from '../../../slices/receivables';

export const ListInvoice = () => {
  const dispatch = useDispatch();
  const {
    loading,
    categorizedInvoiceList,
  } = useSelector(receivablesSelector);
  const category = 'paid';
  const invoiceList = categorizedInvoiceList[category]?.results || [];
  const isEmpty = invoiceList.length > 0;
  useEffect(() => {
    dispatch(getInvoicesList({
      category: category,
      backgroundSync: isEmpty,
    }));
  }, [dispatch, isEmpty]);

  const columns = React.useMemo(() => [
    {
      Header: 'Contact',
      accessor: 'contact.name',
      detailUrl: PAYVY_URL.INVOICE.DETAILS,
      Cell: CellDetailLink,
    }, {
      Header: 'Invoice',
      accessor: 'invoice_number',
    }, {
      Header: 'Due',
      accessor: 'due_date',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'Amount',
      accessor: 'amount',
      Cell: CellCurrency,
      sortDescFirst: true,
      inCents: true,
    },
  ], []);
  return <PayvyTable
    columns={columns}
    data={invoiceList}
    fetchData={noop}
    loading={loading}
    canScroll={false}
  />;
};
