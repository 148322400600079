import React, {useCallback, useEffect} from 'react';
import {TbRefresh} from "react-icons/tb";
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from "../../../comps/elements";
import {PayvyIconButton} from "../../../comps/forms";
import {
  getPaymentServiceInformation,
  paymentSystemSelector,
  refreshPaymentSystemConnectionData
} from "../../../slices/paymentSystem";
import {getLinkToken} from "../../../slices/plaid";
import {ModalAddPaymentService} from "./ModalAddPaymentService";
import {WiseUnlinkedAlert} from "./WiseUnlinkedAlert";

export const LinkedPaymentServiceAccounts = ({
  companyId,
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    processing,
    services = []
  } = useSelector(paymentSystemSelector);
  const [syncDisabled, setSyncDisabled] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const [pageSize, setPageSize] = React.useState(5);
  const [pageCount, setPageCount] = React.useState(Math.ceil(accounts.length / pageSize));
  const [paginatedAccounts, setPaginatedAccounts] = React.useState(accounts.slice(0, pageSize));

  useEffect(() => {
    const newAccounts = services.reduce((acc, service) => {
      return [
        ...acc,
        ...service.accounts.map(account => ({
          ...account,
          serviceName: service.name,
        }))
      ]
    }, [])
    if(JSON.stringify(newAccounts) !== JSON.stringify(accounts)) {
      setAccounts(newAccounts);
    }
  }, [services, accounts])
  useEffect(() => {
    dispatch(getPaymentServiceInformation({}))
  }, [dispatch, companyId])
  useEffect(() => {
    dispatch(getLinkToken({}));
  }, [dispatch, companyId]);
  const columns = React.useMemo(() => [
    {
      Header: 'Account Name',
      accessor: 'name',
      disableSortBy: true,
    },
    {
      Header: 'Service',
      accessor: 'serviceName',
      disableSortBy: true,
    },
    {
      Header: 'Account Type',
      accessor: 'type',
      disableSortBy: true,
    },
  ], []);
  const fetchData = useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setPageSize(pageSize);
    setPageCount(Math.ceil(accounts.length / pageSize));
    setPaginatedAccounts(accounts.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize));
  }, [accounts]);
  return <>
    <div className={'flex flex-row text-sm text-payvyBlue-950 font-bold mt-2'}>
      <div className={'flex items-center'}>
        Paired Payment Service Accounts
      </div>
      <div className={'flex grow justify-end'}>
        <PayvyIconButton
          buttonText={'Manual Sync'}
          Icon={TbRefresh}
          tooltipContent={syncDisabled ? "Please wait before syncing again." : "Sync with the payment service providers."}
          onClick={() => {
            setSyncDisabled(true)
            dispatch(refreshPaymentSystemConnectionData({
              success: () => {
                dispatch(getPaymentServiceInformation({}))
                setTimeout(() => setSyncDisabled(false), 15 * 60 * 1000)
              },
              failure: () => {
                setSyncDisabled(false)
              }
            }))
          }}
          wrapperClassName={'justify-end mt-2'}
          disabled={loading || processing || syncDisabled}
        />
        <ModalAddPaymentService/>
      </div>
    </div>
    <WiseUnlinkedAlert/>
    <PayvyTable
      columns={columns}
      data={paginatedAccounts}
      fetchData={fetchData}
      loading={loading}
      count={accounts.length}
      pageCount={pageCount}
      defaultPageSize={pageSize}
      pageSize={pageSize}
      sizeOptions={[5, 20, 50]}
    />
  </>;
};
