import React from "react";
import {FaCcAmex, FaCcDiscover, FaCcMastercard, FaCcVisa, FaCreditCard} from "react-icons/fa";

export const SinglePaymentMethodOption = ({
  innerProps,
  innerRef,
  data,
}) => {
  const {
    brand = '',
    expiration = '',
  } = data;
  let CardIcon = FaCreditCard;
  if(brand === 'MASTERCARD') CardIcon = FaCcMastercard; else if(brand === 'DISCOVER') CardIcon = FaCcDiscover; else if(brand === 'VISA') CardIcon = FaCcVisa; else if(brand === 'AMEX') CardIcon = FaCcAmex;
  const cardName = data.title.substring(data.title.length - 10);
  return <div
    className={`p-2 inline-flex w-full flex-row items-center gap-2 text-neutral-500 ${!!innerProps ? 'hover:bg-red-100 hover:text-neutral-900' : ''}`}
    style={!!innerProps ? {} : {gridArea: '1/1/2/3'}}
    {...innerRef}
    {...innerProps}>
    <div className={'flex '}>
      <CardIcon/>
    </div>
    <div className={'flex grow text-neutral-900'}>
      {cardName}
    </div>
    <div className={'flex'}>
      {expiration}
    </div>
  </div>;
};
