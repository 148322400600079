import {noop} from 'lodash';
import toast from "react-hot-toast";
import {API_BASE_URL, PAYVY_API} from '../constants';
import {calculateHashForDictionary} from "./Checksum";
import Debugger from './Debug';

export function getLastDottedStringToCamelCase(str) {
  try {
    if(str.indexOf('.') === -1) return CamelCaseToTitleCase(hyphensToCamelCase(str));
    return CamelCaseToTitleCase(hyphensToCamelCase(str.slice((str.lastIndexOf('.') - 1 >>> 0) + 2)));
  } catch(e) {
    Debugger.log(e);
    return str;
  }
}

export function hyphensToCamelCase(str) {
  const arr = str.split(/[_-]/);
  let newStr = '';
  for(let i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0)
                    .toUpperCase() + arr[i].slice(1);
  }
  return arr[0] + newStr;
}

export function CamelCaseToTitleCase(str) {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0)
               .toUpperCase() + result.slice(1);
}

export function camelCaseToHyphens(str) {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-')
            .toLowerCase();
}

export function handleErrors(data = {}, formik = undefined, addToast = noop) {
  if(typeof data === 'object') {
    if(data.data) {
      data = data.data;
    }
    if(Array.isArray(data)) {
      let errorMessage = '';
      let preLine = data.length === 1 ? '' : '- ';
      data.forEach(item => {
        if(item) {
          errorMessage += preLine + item + '\n';
        }
      });
      if(formik) {
        formik.setFieldError(hyphensToCamelCase('nonFieldErrors'), errorMessage.trim());
        formik.setFieldTouched('nonFieldErrors', true);
      }
      addToast(errorMessage.trim(), {appearance: 'error'});
    } else {
      for(const [key, value] of Object.entries(data)) {
        let errorMessage = '';
        let valueArray = Array.isArray(value) ? value : [value];
        let preLine = valueArray.length === 1 ? '' : '- ';
        valueArray.forEach(item => {
          if(item) {
            errorMessage += preLine + item + '\n';
          }
        });
        if(formik) {
          formik.setFieldError(hyphensToCamelCase(key), errorMessage.trim());
        }
        addToast(errorMessage.trim(), {appearance: 'error'});
      }
    }
  }
}

export async function refreshAccessToken() {
  const refreshToken = localStorage.getItem('refreshToken');
  if(!refreshToken) {
    return false;
  }
  try {
    const response = await fetch(PAYVY_API.TOKEN.REFRESH, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({refresh: refreshToken}),
    });

    const data = await response.json();
    if(response.status === 200) {
      localStorage.setItem('accessToken', data.access);
      return true;
    } else {
      return false;
    }
  } catch(error) {
    return false;
  }
}

export function build_url(url, parameters) {
  if(!parameters) return url;
  for(const [key, value] of Object.entries(parameters)) {
    url = url.replace(`:${key}`, value);
  }
  return url;
}

export function generate_dynamic_url(url) {
  if(url && !url.includes('http')) {
    return `${API_BASE_URL}${url}`;
  }
  return url;
}

export function generate_full_url(url) {
  //generate full url with http(s) domain and append url from the current window.location
  if(url && !url.includes('http')) {
    return `${window.location.origin}${url}`;
  }
}

export function build_error_message(message, parameters) {
  for(const [key, value] of Object.entries(parameters)) {
    message = message.replace(`:${key}`, value);
  }
  return message;
}

export async function zipCodeInfo(zip_code, country = 'US') {
  const url = `https://zip.getziptastic.com/v2/${country}/${zip_code}`;
  return await fetch(url, {'method': 'GET'})
  .then((response) => response.json())
  .catch((err) => Debugger.log('Error: ', err));
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email)
  .toLowerCase());
}

export const dateFormat = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - (offset * 60 * 1000));
  const [y, m, d] = date.toISOString()
                        .split('T')[0].split('-');
  return `${m}/${d}/${y}`;
};

export async function fetchWithTimeout(resource, options = {}, defaultTimeout = 5000) {
  const {timeout = defaultTimeout} = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  // If the response status is 408 (Request Timeout), throw an error
  if(response.status === 408) {
    throw new Error('The operation was aborted.');
  }

  return response;
}

export const setAccessValue = (currentValue, newValue) => {
  if(currentValue === null || currentValue === undefined || currentValue === true) {
    return newValue;
  }
  return false;
};

export const formatCurrency = (value, thousandSeparator, decimalScale) => {
  const dollars = value / 100;
  return dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalScale,
    minimumFractionDigits: 0,
    useGrouping: true,
  });
};

export const djangoPatchify = (key, value) => {
  /*
   This function is used to convert values to null when they are empty strings ("").
   If value should be unchanged use undefined.
   */
  if(value === '') return null;
  return value;
};

export const removeEmptyData = (obj, keysToCheck = []) => {

  keysToCheck.forEach((key) => {
    if(!obj[key]) delete obj[key];
  });

  return obj;
};

export const downloadInvoice = (invoiceId) => {
  const link = document.createElement('a');
  link.href = build_url(PAYVY_API.V1.RECEIVABLES.INVOICE_DOWNLOAD, {id: invoiceId});
  link.setAttribute('download', 'download');
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const renameDictionaryKeys = (dictionary, keyMapping) => {
  const result = {};
  if(!dictionary) return result;
  Object.keys(dictionary)
        .forEach((key) => {
          const newKey = keyMapping[key] || key;
          result[newKey] = dictionary[key];
        });
  return result;
};

export const parseValueFromIntOrObj = (rawValue) => {
  if(typeof rawValue === 'object' && rawValue?.value !== undefined) return parseInt(rawValue.value);
  if(typeof rawValue === 'number' || (!isNaN(rawValue) && !isNaN(parseInt(rawValue)))) return parseInt(rawValue);
  return undefined;
}

export const compareDicts = (dict1, dict2) => {
  return calculateHashForDictionary(dict1) === calculateHashForDictionary(dict2)
}

export const stringify = (params, options = {}) => {
  const {skipEmptyString = false} = options;
  const filteredParams = {};

  for(const key in params) {
    if((params[key] !== undefined) && (!skipEmptyString || params[key] !== '')) {
      filteredParams[key] = params[key];
    }
  }

  const searchParams = new URLSearchParams(filteredParams);
  return searchParams.toString();
};

export const parsify = (queryString) => {
  if(!queryString || typeof queryString !== 'string') {
    return {};
  }
  const queryStringWithoutQuestionMark = queryString.replace('?', '');
  const keyValuePairs = queryStringWithoutQuestionMark.split('&');
  const parsedQueryString = {};
  keyValuePairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    parsedQueryString[decodeURIComponent(key)] = decodeURIComponent(value);
  });
  return parsedQueryString;
};

export const relocateItem = (array, startIdx, endIdx) => {
  const startIndex = startIdx < 0 ? array.length + startIdx : startIdx;

  if(startIndex >= 0 && startIndex < array.length) {
    const endIndex = endIdx < 0 ? array.length + endIdx : endIdx;

    const item = array.splice(startIdx, 1)[0];
    array.splice(endIndex, 0, item);
  }

  return array;
}

export const relocateItemWithCopy = (array, startIdx, endIdx) => {
  const clonedArray = [...array];
  relocateItem(clonedArray, startIdx, endIdx);
  return clonedArray;
}

export const mergeItemIntoList = (oldList, item, allowAddition = false, addToBeginning = false) => {
  const index = oldList.findIndex(oldItem => oldItem.id === item.id);

  if(index !== -1) {
    oldList[index] = {...oldList[index], ...item};
  } else if(allowAddition) {
    if(addToBeginning) {
      oldList.unshift(item);
    } else {
      oldList.push(item);
    }
  }

  return oldList;
};

export const mergeItemsIntoList = (oldList, items, allowAddition = false, addToBeginning = false) => {
  const newList = [...oldList];
  items.forEach(item => mergeItemIntoList(newList, item, allowAddition, addToBeginning));
  return newList;
};

export const formatDateForAPItoYMD = (dateStr) => {
  const date = new Date(dateStr);

  // Check if date is valid
  if(isNaN(date.getTime())) {
    throw new Error('Invalid date format');
  }

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString()
                                     .padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate()
                  .toString()
                  .padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const formatDateForAPItoMDY = (dateStr) => {
  const date = new Date(dateStr);

  // Check if date is valid
  if(isNaN(date.getTime())) {
    throw new Error('Invalid date format');
  }

  // Extract year, month, and day
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString()
                                     .padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate()
                  .toString()
                  .padStart(2, '0');

  return `${month}/${day}/${year}`;
};

export const formatDateForAPItoISO = (dateStr, mode = 'default') => {
  const date = new Date(dateStr);

  // Check if date is valid
  if(isNaN(date.getTime())) {
    return undefined
  }

  // Adjust to start or end of day if specified
  if(mode === 'start') {
    date.setHours(0, 0, 0, 0);
  } else if(mode === 'end') {
    date.setHours(23, 59, 59, 999);
  }

  return date.toISOString();
};

export const payvyToast = (message, data = {}) => {
  let style = {
    borderRadius: '10px',
    background: '#333',
    color: '#fff',
  }
  const duration = data.duration || 5000;
  if(data.appearance === 'error') style.background = '#f44336';
  if(data.appearance === 'success') style.background = '#4caf50';
  if(data.appearance === 'info') style.background = '#2196f3';
  return toast(message, {
    position: "bottom-center",
    style: style,
    duration: duration
  })
}

export const parseAmountFilter = (amountStr) => {
  if(!amountStr || amountStr.length < 1) {
    return [null, null];
  }

  let minValue = null;
  let maxValue = null;

  if(amountStr.includes('-')) {
    [minValue, maxValue] = amountStr.split('-')
                                    .map(parseFloat);
  } else if(amountStr[0] === '<') {
    maxValue = parseFloat(amountStr.slice(1));
  } else if(amountStr[0] === '>') {
    minValue = parseFloat(amountStr.slice(1));
  } else {
    minValue = parseFloat(amountStr);
    maxValue = parseFloat(amountStr);
  }

  return [minValue, maxValue];
};

const isValidDate = (date) => date instanceof Date && !isNaN(date);

export const isSameDate = (date1, date2) => {
  if(date1 === null && date2 === null) return true;
  if(date1 === null || date2 === null) return false;
  if(!isValidDate(date1) || !isValidDate(date2)) return false;
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const orderItems = (items, orderBy) => {
  if(!orderBy) return items;

  const [sortField, sortOrder] = orderBy.startsWith('-')
    ? [orderBy.slice(1), 'desc']
    : [orderBy, 'asc'];

  return items.sort((a, b) => {
    const aValue = sortField.split('.')
                            .reduce((obj, field) => obj[field], a);
    const bValue = sortField.split('.')
                            .reduce((obj, field) => obj[field], b);

    if(aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
    if(aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });
}
