import {Form, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';
import {BigCenteredLoadingBlock, PayvyPageHeader} from '../../comps/elements';
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyIconButtonGroup,
  PayvyInput,
  PayvyLabeledCheckbox,
  PayvyLabeledInput,
  PayvySelectComponent,
  PayvyTextArea,
  SearchContact
} from '../../comps/forms';
import {FORM_ERROR_MESSAGES, INVOICE_TERM_OPTIONS, PAYVY_URL} from '../../constants';
import useFormikHotkeys from "../../hooks/useFormikHotkeys";
import {companiesSelector} from '../../slices/companies';
import {getContactPaymentMethods} from '../../slices/newContact';
import {createInvoice, getInvoiceDetails, receivablesSelector} from '../../slices/receivables';
import {handleErrors} from '../../utils/Utility';
import {ModalAddPaymentMethod} from './components/ModalAddPaymentMethod';
import {SinglePaymentMethodOption} from "./components/SinglePaymentMethodOption";
import {TableInvoiceCreate} from "./components/TableInvoiceCreate";

const InvoiceCreatePage = () => {
  const {
    processing,
    loading,
    invoiceDetails,
  } = useSelector(receivablesSelector);
  const {
    searchList,
    paymentMethods,
  } = useSelector((state) => state.contact);
  const {company: {finix_merchant_id: finixMerchantId} = {}} = useSelector(companiesSelector);
  const navigate = useNavigate();
  const {id: invoiceID} = useParams();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const invoice = invoiceDetails[invoiceID];
  const [formattedPaymentMethods, setFormattedPaymentMethods] = React.useState([]);
  const [paymentOptionMethod, setPaymentOptionMethod] = React.useState('request');
  const [selectedBuyerId, setSelectedBuyerId] = React.useState(null);
  useEffect(() => {
    if(!!invoiceID) dispatch(getInvoiceDetails({invoiceId: invoiceID}));
  }, [dispatch, invoiceID]);
  useEffect(() => {
    if(!!paymentMethods) {
      const updatedPaymentMethods = [];
      paymentMethods.forEach(method => {
        if(method.instrumentType !== 'Payment card' || method.service !== 'Finix') return;
        updatedPaymentMethods.push({
          key: method.id,
          value: method.id,
          text: method.name,
          label: method.name, ...method,
        })
      });
      setFormattedPaymentMethods(updatedPaymentMethods);
    }
  }, [paymentMethods]);
  const [initDate] = React.useState(new Date());
  if(loading) return <BigCenteredLoadingBlock text={'Loading'}/>;

  return (<>
    <PayvyPageHeader parents={[
      {name: 'Receivables'},
      {
        name: 'Invoices',
        href: PAYVY_URL.INVOICE.LIST
      }
    ]}>{invoiceID ? 'Edit invoice' : 'Create new invoice'}</PayvyPageHeader>
    {processing ? <BigCenteredLoadingBlock text={'Saving invoice'}/> : <></>}
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        customer: invoice?.contact?.id || '',
        invoiceNumber: invoice?.invoice_number || '',
        invoiceDate: moment(invoice?.invoice_date || initDate)
        .format('MM/DD/YYYY'),
        terms: invoice?.terms || INVOICE_TERM_OPTIONS[0].value,
        dueDate: moment(invoice?.due_date || initDate)
        .format('MM/DD/YYYY'),
        items: JSON.parse(JSON.stringify(invoice?.items || [])),
        discount: invoice?.discount_type === 'percentage' ? (invoice?.discount || 0) : (invoice?.discount || 0),
        discountType: invoice?.discount_type || 'percentage',
        shipping: invoice?.shipping || 0,
        adjustment: invoice?.adjustment || 0,
        total: invoice?.total || 0,
        termsAndConditions: invoice?.terms_and_conditions || '',
        captureFundsLater: invoice?.capture_funds_later || false,
        ccEmail: '',
        toEmail: '',
        paymentMethod: null,
        isDraft: false,
      }}
      validationSchema={Yup.object({
        customer: Yup.number()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        invoiceNumber: Yup.string()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        invoiceDate: Yup.date()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        terms: Yup.string()
                  .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        dueDate: Yup.date()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        items: Yup.array()
                  .of(Yup.object({
                    id: Yup.number()
                           .nullable(),
                    description: Yup.string()
                                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                    quantity: Yup.number()
                                 .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                    rate: Yup.number()
                             .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                    amount: Yup.number()
                               .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                  }))
                  .min(1, FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        discount: Yup.number()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        discountType: Yup.string()
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        shipping: Yup.number()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        adjustment: Yup.number()
                       .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        total: Yup.number()
                  .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        ccEmail: Yup.string()
                    .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL),
        toEmail: Yup.string()
                    .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL),
        paymentMethod: Yup.object()
                          .nullable(),
      })}
      onSubmit={(values, helpers) => {
        const newItems = [];
        values.items.forEach(item => {
          newItems.push({
            ...item,
            amount: Math.round(item.amount * 100),
            rate: Math.round(parseFloat(item.rate)
            .toFixed(2) * 100),
          });
        });
        dispatch(createInvoice({
          ...values,
          items: newItems,
          invoiceID: invoiceID,
          paymentMethod: values.paymentMethod ? values.paymentMethod.value : null,
          success: () => {
            navigate(PAYVY_URL.INVOICE.LIST);
          },
          failure: (error) => {
            handleErrors(error, helpers);
          },
        }));
      }}
    >{(props) => <Form>
      <div className={`flex flex-row gap-2 w-full ${processing || loading ? 'hidden' : ''}`}>
        <div className={'flex flex-col grow px-4'}>
          <div className={'w-full'}>
            <PayvyLabeledInput
              label={'Customer'}
              name={'customer'}
            >
              <SearchContact
                setField={({value}) => {
                  const selectedContact = searchList.find(contact => contact.contact_id === value);
                  props.setFieldValue('customer', value);
                  props.setFieldValue('invoiceNumber', selectedContact?.next_invoice_number || '');
                  setSelectedBuyerId(selectedContact?.finix_id);
                  dispatch(getContactPaymentMethods({id: value}));
                }}
                value={props.values.customer}
                placeholder={'Find customer'}
                onlyCustomers={true}
              />
            </PayvyLabeledInput>
          </div>
          <div className={'w-full'}>
            <PayvyLabeledInput
              label={'Invoice Number'}
              name={'invoiceNumber'}
              as={PayvyInput}
            />
          </div>
          <div className={'w-full grid grid-cols-3 gap-5'}>
            <PayvyLabeledInput
              label={'Invoice Date'}
              name={'invoiceDate'}
            >
              <PayvyDatePicker
                value={props.values.invoiceDate}
                onChange={(value) => props.setFieldValue('invoiceDate', value)}
              />
            </PayvyLabeledInput>
            <PayvyLabeledInput
              label={'Terms'}
              name={'terms'}
              component={PayvySelectComponent}
              options={INVOICE_TERM_OPTIONS}
            />
            <PayvyLabeledInput
              label={'Due Date'}
              name={'dueDate'}
            >
              <PayvyDatePicker
                value={props.values.dueDate}
                onChange={(value) => props.setFieldValue('dueDate', value)}
              />
            </PayvyLabeledInput>
          </div>
          {!loading && <TableInvoiceCreate
            invoice={invoice}
            formProps={props}
          />}
          <div className={'w-full'}>
            <PayvyLabeledInput
              label={<>Terms and Conditions <span className={'text-neutral-500'}>- optional</span></>}
              placeholder={'Terms and Conditions'}
              name={'termsAndConditions'}
              as={PayvyTextArea}
              alwaysLabel={true}
            />
          </div>
        </div>
        <div className={'flex w-96 mr-2 flex-col'}>
          <div className={'flex flex-col'}>
            <div className={'bg-neutral-0 w-full border border-neutral-500 rounded-xl p-6 shadow-xl'}>
              <div className={'flex flex-col'}>
                <h1 className={'border-b-2 border-neutral-500 pb-0.5 mb-1'}>Payment Options</h1>
                <div className={'mb-2'}>
                  <PayvyIconButtonGroup
                    value={paymentOptionMethod}
                    onChange={(value) => setPaymentOptionMethod(value)}
                    fullWidth={true}
                    wrapperClassName={'w-full grow justify-end'}
                    choices={[
                      {
                        label: 'Request',
                        value: 'request',
                      }, {
                        label: 'Charge',
                        value: 'charge',
                      },
                    ]}
                  />
                </div>
                {paymentOptionMethod === 'request' && <div className={'flex flex-col'}>
                  <PayvyLabeledInput
                    label={'To'}
                    name={'toEmail'}
                    as={PayvyInput}
                  />
                  <PayvyLabeledInput
                    label={'CC'}
                    name={'ccEmail'}
                    as={PayvyInput}
                  />
                </div>}
                {paymentOptionMethod === 'charge' && <div className={'flex flex-col'}>
                  <Select
                    options={formattedPaymentMethods}
                    components={{
                      SingleValue: SinglePaymentMethodOption,
                      Option: SinglePaymentMethodOption,
                    }}
                    onChange={(value) => props.setFieldValue('paymentMethod', value)}
                    value={props.values.paymentMethod}
                    placeholder={'Select payment method'}
                    className={'w-full'}
                    isSearchable={false}
                  />
                  <div>
                    <ModalAddPaymentMethod
                      merchantId={finixMerchantId}
                      buyerId={selectedBuyerId}
                      setSelectedBuyerId={setSelectedBuyerId}
                      contactId={props.values.customer}
                      addedSuccessfully={() => {
                        dispatch(getContactPaymentMethods({id: props.values.customer}));
                      }}
                    />
                  </div>
                </div>}
                <div className={'grid grid-cols-2 gap-2 items-center my-1'}>
                  <PayvyLabeledCheckbox name={'captureFundsLater'} required={false}>
                    <span className={'text-xs self-center'}>Capture funds later</span>
                  </PayvyLabeledCheckbox>
                  <PayvyIconButton
                    buttonText={'Send'}
                    onClick={() => {
                      props.setFieldValue('isDraft', false);
                      props.handleSubmit();
                    }}
                    fullWidth={true}
                  />
                </div>
              </div>
              <div className={'flex flex-col mt-2'}>
                <h1 className={'border-b-2 border-neutral-500 pb-0.5 mb-1'}>Invoice Options</h1>
                <div className={'grid grid-cols-1 gap-2 mb-2'}>
                  {/*<PayvyIconButton*/}
                  {/*  buttonText={'Preview'}*/}
                  {/*  onClick={clickedPreviewInvoice}*/}
                  {/*  mainColor={'none'}*/}
                  {/*  borderSize={2}*/}
                  {/*  borderColor={'red-200'}*/}
                  {/*  textColor={'red-200'}*/}
                  {/*  hoverTextColor={'red-300'}*/}
                  {/*  fullWidth={true}*/}
                  {/*/>*/}
                  <PayvyIconButton
                    buttonText={'Save Draft'}
                    onClick={() => {
                      props.setFieldValue('isDraft', true);
                      props.handleSubmit();
                    }}
                    mainColor={'none'}
                    borderSize={2}
                    borderColor={'slate-700'}
                    textColor={'slate-900'}
                    hoverTextColor={'slate-700'}
                    fullWidth={true}
                  />
                </div>
                <PayvyIconButton
                  buttonText={'Cancel'}
                  onClick={() => navigate(PAYVY_URL.INVOICE.LIST)}
                  mainColor={'none'}
                  borderSize={2}
                  borderColor={'slate-700'}
                  textColor={'slate-900'}
                  hoverTextColor={'slate-700'}
                  fullWidth={true}
                  shortcutKey={'escape'}
                />
              </div>
            </div>
            <div>
              <GlobalFormError
                title={'Following fields have an issue:'}
                multipleFromDict={true}
                errors={props.errors}
                visible={props.submitCount >= 1}
                removeFields={['items']}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>}
    </Formik>
  </>);
};

export default InvoiceCreatePage;
