import React from 'react';
import {PayvyPageHeader, PayvyPageUnderProgress, SettingsMenu} from '../../comps/elements';

const SettingsVirtualBoxPage = () => {
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Virtual Box</PayvyPageHeader>
    <SettingsMenu active="virtual-box"/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2 h-dvh'}>
      <PayvyPageUnderProgress nameOfPage={'Virtual Box'}/>
    </div>
  </>;
};

export default SettingsVirtualBoxPage;
