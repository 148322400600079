import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {useNavigate} from 'react-router-dom';
import {PAYVY_URL} from "../../../constants";
import {displayAmount} from '../../../utils/BillHelpers';
import {ModuleBlock} from './ModuleBlock';

export const ModuleBills = ({bills = []}) => {
  const navigate = useNavigate();
  return <ModuleBlock
    big={true}
    title={'Bills'}
    buttons={[
      {
        label: 'View All',
        onClick: () => navigate(PAYVY_URL.BILLS.LIST)
      },
    ]}
  >
    {bills.length === 0 ? <span
        className={'font-light'}
      >
                No bills have been added.
        </span> :
      <table className="table-auto w-full">
        <thead>
        <tr className={'border-2 border-neutral-150 text-neutral-150 font-light'}>
          <th>Amount</th>
          <th>Name</th>
          <th>Due Date</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        {bills.map((bill, index) => {
          const {
            amount,
            name,
            due_date,
            status,
          } = bill;
          const dueDate = new Date(due_date).toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
          });
          const dueDateFull = new Date(due_date).toLocaleString();
          let formattedStatus = <span className={'text-neutral-150'}>OPEN</span>;
          if(status.toLowerCase() === 'paid') formattedStatus = <span className={'text-green-500'}>PAID</span>;
          else if(new Date(due_date) < new Date()) formattedStatus = <span className={'text-red-800'}>PAST DUE</span>;
          return <tr key={index} className={'text-center font-bold text-neutral-700'}>
            <td>
              <CurrencyFormat value={displayAmount(amount)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
            </td>
            <td>{name}</td>
            <td title={dueDateFull}>{dueDate}</td>
            <td>{formattedStatus}</td>
          </tr>;
        })}
        </tbody>
      </table>}
  </ModuleBlock>;
};
