import Mousetrap from 'mousetrap';
import React, {useEffect} from 'react';
import {FiUsers} from "react-icons/fi";
import {HiArrowsUpDown} from "react-icons/hi2";
import {
  RiDashboardLine,
  RiFileTextLine,
  RiInboxLine,
  RiMoneyDollarBoxLine,
  RiRobot2Line,
  RiTestTubeLine
} from "react-icons/ri";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {LOCAL_ENVIRONMENT, PAYVY_URL} from '../../../../constants';
import {usePermissions} from '../../../../hooks/usePermissions';
import {getCacheRefreshInformation} from "../../../../slices/companies";
import {getNotificationsCount} from "../../../../slices/newInbox";
import {build_url} from "../../../../utils/Utility";
import {PayvySidebarGroup} from './PayvySidebarGroup';

export const PayvySidebarItems = ({
  setChatOpen,
  minimized,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    inbox: {see: hasInboxAccess = false} = {},
    bill: {see: hasBillsAccess = false} = {},
    contact: {see: hasContactsAccess = false} = {},
    transaction: {see: hasTransactionAccess = false} = {},
    receivables: {see: hasReceivablesAccess = false} = {},
  } = usePermissions();
  const {notificationsCount} = useSelector((state) => state.inbox);

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getNotificationsCount({}));
    }, 60000);
    return () => clearInterval(interval);
  }, [dispatch]);
  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(getCacheRefreshInformation());
    }, 10000);
    return () => clearInterval(interval);
  }, [dispatch]);
  const menuSystem = [
    {
      items: [
        {
          name: 'Dashboard',
          Icon: RiDashboardLine,
          hotkey: 'g d',
          command: 'g d',
          url: '/',
        },
      ],
    }, {
      items: [
        {
          hasAccess: hasInboxAccess,
          name: 'Inbox',
          Icon: RiInboxLine,
          notificationCount: notificationsCount,
          hotkey: 'g i',
          command: 'g i',
          url: '/inbox/received',
        },
      ],
    }, {
      name: 'Receivables',
      hasAccess: hasReceivablesAccess,
      Icon: RiFileTextLine,
      items: [
        {
          hasAccess: hasReceivablesAccess,
          Icon: RiFileTextLine,
          name: 'Invoices',
          hotkey: 'g r',
          command: 'g r',
          url: '/invoices',
        },
      ],
    }, {
      name: 'Payables',
      Icon: RiMoneyDollarBoxLine,
      items: [
        {
          hasAccess: hasBillsAccess,
          Icon: RiMoneyDollarBoxLine,
          name: 'Bills',
          hotkey: 'g p',
          command: 'g p',
          url: '/bills',
        },
      ],
    }, {
      items: [
        {
          hasAccess: hasContactsAccess,
          name: 'Contacts',
          Icon: FiUsers,
          hotkey: 'g c',
          command: 'g c',
          url: '/contacts',
        },
      ],
    }, {
      items: [
        {
          hasAccess: hasTransactionAccess,
          name: 'Transactions',
          Icon: HiArrowsUpDown,
          hotkey: 'g t',
          command: 'g t',
          url: '/transactions',
        },
      ],
    }, {
      items: [
        {
          hasAccess: LOCAL_ENVIRONMENT,
          name: 'Test',
          Icon: RiTestTubeLine,
          hotkey: 'g x',
          command: 'g x',
          url: '/test',
        },
      ],
    }, {
      items: [
        {
          name: 'PayvyGPT',
          Icon: RiRobot2Line,
          hotkey: 'g a',
          command: 'g a',
          onClick: setChatOpen
        }
      ]
    }
  ];
  useEffect(() => {
    Mousetrap.bind('n c', () => navigate(build_url(PAYVY_URL.CONTACTS.CREATE_CUSTOMER, {type: 'customer'})))
    Mousetrap.bind('n v', () => navigate(build_url(PAYVY_URL.CONTACTS.CREATE_CUSTOMER, {type: 'vendor'})))
    Mousetrap.bind('n b', () => navigate(PAYVY_URL.BILLS.CREATE))
    Mousetrap.bind('n i', () => navigate(PAYVY_URL.INVOICE.CREATE))
  }, [navigate])
  return <div className={`flex flex-col h-full ${minimized ? 'w-14' : 'w-64'} mt-3`}>
    {menuSystem.map((menu, index) => <PayvySidebarGroup key={index} {...menu} minimized={minimized}/>)}
  </div>;
};
