import React from "react";
import {FaEllipsisV} from "react-icons/fa";
import {sortableHandle} from "react-sortable-hoc";
import {PayvyIconButton} from "../../../comps/forms";

export const SortableHandle = sortableHandle(() => <PayvyIconButton
  Icon={FaEllipsisV}
  iconSize={16}
  iconPaddingX={0}
  iconPaddingY={0}
  mainColor={'none'}
  borderColor={'none'}
  iconColor={'neutral-500'}
  hoverTextColor={'neutral-700'}
  cursor={'move'}
/>);
