import React, {useCallback} from 'react';
import {TbRefresh} from 'react-icons/tb';
import {useDispatch} from 'react-redux';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {getAnalytics, updateAnalytics} from '../../../slices/companies';
import {formatCurrency} from '../../../utils/Utility';
import {CustomTooltip} from './CustomTooltip';

export const MonthlySpendingGraph = ({
  monthlySpendingData,
  loading,
  companyId,
}) => {
  const dispatch = useDispatch();
  const refreshAnalytics = useCallback(() => {
    dispatch(updateAnalytics({
      id: companyId,
      success: () => dispatch(getAnalytics({
        id: companyId,
        backgroundSync: false
      }))
    }));
  }, [dispatch, companyId]);
  return <div className={'grid grid-cols-1 my-5'}>
    <div className={'p-2'}>
      <div className={'text-2xl'}>Monthly Spending <TbRefresh onClick={refreshAnalytics}
                                                              className={`refresh-analytics inline ${loading ? 'animate-spin' : ''}`}/>
      </div>
      <div className={'text-gray-300'}>Last 12 Months</div>
    </div>
    <ResponsiveContainer width="100%" height={250} className="grid">
      {loading ? (
        <div className="my-5 mx-3">Loading...</div>
      ) : (
        <AreaChart
          data={monthlySpendingData}
          margin={{
            top: 5,
            right: 20,
            bottom: 5,
            left: 0
          }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#000102" stopOpacity={1}/>
              <stop offset="95%" stopColor="#000000" stopOpacity={0.5}/>
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="value"
            stroke="#000000"
            fill="url(#colorValue)"
          />
          <CartesianGrid stroke="#ccc" strokeDasharray="3 3"/>
          <XAxis dataKey="label"/>
          <YAxis tickFormatter={(value) => formatCurrency(value)}/>
          <Tooltip content={<CustomTooltip/>}/>
        </AreaChart>
      )}
    </ResponsiveContainer>
  </div>;
};
