import React from 'react';
import {PayvyIconButton} from "../../../comps/forms";
import {FormBankDetails} from './FormBankDetails';
import {FormMailingAddress} from './FormMailingAddress';

export const ProcessBanking = ({
  formProps,
  toggleFinanceStep,
  currentBankingStep,
  processing,
}) => {
  return <>
    {currentBankingStep === 'ach' ? <FormBankDetails/> : <FormMailingAddress formProps={formProps}/>}
    <div className={'mt-10 flex w-full justify-end'}>
      <PayvyIconButton
        buttonText={currentBankingStep === 'ach' ? 'Request Mailed Check' : 'Add Bank Account'}
        onClick={toggleFinanceStep}
        disabled={processing}
        loading={processing}
        borderSize={2}
        borderColor={'red-300'}
        mainColor={'neutral-0'}
        textColor={'red-300'}
        hoverTextColor={'neutral-0'}
      />
      <PayvyIconButton
        buttonText={'Finish'}
        onClick={formProps.handleSubmit}
        disabled={processing}
        loading={processing}
        shortcutKey={['ctrl+enter', 'ctrl+s']}
        type={'submit'}
      />
    </div>
  </>;
};

export default ProcessBanking;
