import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {checkIfSiteIsLive} from '../slices/auth';

export const useIsSiteLiveChecker = () => {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const {siteIsLive} = useSelector((state) => state.auth);
  useEffect(() => {
    if(firstLoad) {
      dispatch(checkIfSiteIsLive());
      setFirstLoad(false);
    }
    const interval = setInterval(() => {
      dispatch(checkIfSiteIsLive());
    }, siteIsLive ? 300000 : 10000);
    return () => clearInterval(interval);
  }, [dispatch, firstLoad, siteIsLive]);
  return siteIsLive;
};
