import {Field, useFormikContext} from 'formik';
import {noop} from "lodash";
import React from 'react';
import {PayvyInput} from '../PayvyInput/PayvyInput';

export const PayvyLabeledCheckbox = ({
  name,
  children,
  className = 'm-1',
  containerClassName = '',
  controlClassName = '',
  onChange = noop,
  required = true,
}) => {
  const {
    errors,
    touched,
  } = useFormikContext();
  const error = errors[name];
  const touchedError = touched[name];
  return <div className={'py-2 w-full'}>
    <label className={`${touchedError && error ? 'text-red' : ''} flex flex-row`}>
      <Field
        as={PayvyInput}
        name={name}
        type={'checkbox'}
        className={className}
        noStyling={true}
        noWidth={true}
        onChange={onChange}
        required={required}
      />
      {children}
      {touchedError && error && <span className={'text-red text-xs block text-left pl-1'}>{error}</span>}
    </label>
  </div>;
};
