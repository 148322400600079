import React from 'react';

export const InvoiceLabeledData = ({
  label,
  data,
}) => {
  if(data === null || data === undefined) return null;
  return <div className={'inline-flex w-5/12 flex-col p-2 pb-0'}>
    <div className={'text-neutral-700'}>{label}</div>
    <div className={'font-bold'}>{data}</div>
  </div>;
};
