import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {companiesSelector} from '../../../slices/companies';

export const SelectRole = ({
  value,
  setField,
  readOnly,
}) => {
  setField = setField || noop;
  const {permissionListOptions: options} = useSelector(companiesSelector);
  const [valueObject, setValueObject] = React.useState(null);
  useEffect(() => {
    if(!!value && value.length > 0) setValueObject(options.find(option => option.value === value[0]) || null);
  }, [value, options]);


  const triggerLocalFieldChange = (option) => {
    setField(option.value);
    setValueObject(option);
  };

  return <Select
    className={'w-full'}
    placeholder="Role"
    isDisabled={readOnly}
    value={valueObject}
    onChange={(option) => triggerLocalFieldChange(option)}
    options={options}
  />;
};
