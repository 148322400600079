import {InputMask} from "@react-input/mask";
import {Formik} from 'formik';
import {noop} from 'lodash';
import moment from 'moment';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {PayvyDatePicker, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getUser, updateUserInformation, userSelector} from '../../../slices/user';

export const ModalChangeDobSSN = ({success}) => {
  success = success || noop;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading,
    processing,
    user: {
      id,
      date_of_birth: dateOfBirth,
      social_security_number: ssn,
    } = {},
  } = useSelector(userSelector);
  const changeDoBSSNForm = React.createRef();
  return <>
    <PayvyIconButton
      buttonText={'Edit'}
      onClick={() => setModalOpen(true)}
      mainColor={'none'}
      textColor={'slate-900'}
      hoverTextColor={'slate-700'}
      wrapperClassName={'grow justify-end mt-8'}
    />
    <ModalPayvyBase
      title={`Edit Date of Birth and SSN`}
      isOpen={modalOpen}
      confirmButtonText={'Save'}
      confirmButtonAction={() => changeDoBSSNForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          dateOfBirth: !!dateOfBirth ? moment(dateOfBirth)
          .format('MM / DD / YYYY') : '',
          ssn: '',
        }}
        validationSchema={Yup.object({
          dateOfBirth: Yup.string()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          ssn: Yup.string()
                  .when([], {
                    is: () => !ssn,
                    then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                  }),
        })}
        onSubmit={(values) => {
          const formattedDoB = moment(values.dateOfBirth, 'MM / DD / YYYY')
          .format('YYYY-MM-DD');
          const cleanedSSN = values.ssn.replaceAll(' ', '');
          dispatch(updateUserInformation({
            id,
            date_of_birth: formattedDoB,
            social_security_number: cleanedSSN,
            success: () => {
              dispatch(getUser({success}));
            }
          }));
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'Date of Birth'}
            name={'dateOfBirth'}
          >
            <PayvyDatePicker
              value={props.values.dateOfBirth}
              onChange={(dateOfBirth) => props.setFieldValue('dateOfBirth', dateOfBirth)}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={`Social Security Number ${ssn ? `(●●● ●● ${ssn})` : ''}`}
            name={'ssn'}
            alwaysLabel={true}
          >
            <InputMask
              mask="___ __ ____"
              replacement={{_: /\d/}}
              value={props.values.ssn}
              placeholder={ssn ? 'keep empty if no change is required' : ''}
              onChange={({target}) => props.setFieldValue('ssn', target.value)}
              className={'my-0.5 px-1 py-2.5 rounded-md w-full border border-neutral-500'}
            />
          </PayvyLabeledInput>
          <button type={'submit'} ref={changeDoBSSNForm} hidden={true} onClick={props.handleSubmit}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
