import React from "react";
import {SortableElement} from "react-sortable-hoc";
import {DuplicateOfDisplay} from "./DuplicateOfDisplay";
import {ModalRemovePage} from "./ModalRemovePage";

export const SortableItem = SortableElement(({
  className,
  inboxItem,
  value,
  currentPage,
  currentIndex,
  setCurrentPage,
  setInboxItemPageId,
  canDelete = false,
  canAddBill = false,
}) => {
  const {
    id,
    assigned_to_bill: assignedToBill = null,
    page_number: pageNumber = 1,
    invoice_number: invoiceNumber = null,
    deleted = false,
    is_duplicate: isDuplicate = false,
    duplicate_of: duplicateOf = {}
  } = value;
  className = deleted ? `${className} deleted` : className;
  return <li
    className={`flex flex-row items-center px-2 py-1 w-full ${className} ${`${currentPage}` === `${currentIndex}` ? 'bg-slate-100 font-bold border border-slate-200' : 'border border-neutral-0'}`}>
    {canDelete && !assignedToBill ? <ModalRemovePage
      inboxItemId={inboxItem.id}
      deleted={deleted}
      page={value}
      noExtraStyles={true}
    /> : null}
    <span className={'flex grow'} onClick={() => {
      setCurrentPage(currentIndex);
      setInboxItemPageId(id);
    }}>
        {invoiceNumber ? invoiceNumber : `Page ${pageNumber}`}
      <span className={'ml-1 self-center text-xs text-neutral-300'}>
        <DuplicateOfDisplay
          isDuplicate={isDuplicate}
          duplicateOf={duplicateOf}
          isCurrentPage={`${currentPage}` === `${currentIndex}`}
        />
        {assignedToBill ? ' (assigned)' : null}
        {deleted ? ' (deleted)' : null}
      </span>
    </span>
  </li>;
});
