import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import {build_url, stringify} from '../utils/Utility';
import {refreshAccessToken} from "./api";

export const initialState = {
  loading: false,
  hasErrors: false,
  processing: false,
  count: 0,
  bankAccounts: [],
  bankAccountsDropdownFormat: [],
};

const kycSlice = createSlice({
  name: 'kyc',
  initialState,
  reducers: {
    getBankAccountsStart: (state) => {
      state.loading = true;
    },
    getBankAccountsSuccess: (state, {payload}) => {
      state.loading = false;
      state.hasErrors = false;
      state.bankAccounts = payload.results;
      const accountSelector = [];
      payload.results.forEach((item => accountSelector.push({
        key: item.id,
        text: item.name,
        label: item.name,
        value: item.id,
        type: item.type,
      })));
      state.bankAccountsDropdownFormat = accountSelector;
      state.count = payload.count;
    },
    getBankAccountsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  getBankAccountsStart,
  getBankAccountsSuccess,
  getBankAccountsFailure,
  processingStart,
  processingFinish,
} = kycSlice.actions;
export const kycSelector = (state) => state.kyc;
export default kycSlice.reducer;


export function getBankAccounts({
  page = 1,
  page_size = 10,
  active = true,
  type = undefined,
  verified = undefined,
  hasDwolla = undefined,
}) {
  return async(dispatch) => {
    dispatch(getBankAccountsStart());
    try {
      const params = stringify({
        page,
        page_size,
        active,
        type,
        verified,
        has_dwolla: hasDwolla,
      }, {skipEmptyString: true});
      const response = await fetch(`${PAYVY_API.V1.KYC.BANK_ACCOUNT_LIST}?${params}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getBankAccountsSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getBankAccounts({
              page,
              page_size,
              active,
              type,
              verified,
              hasDwolla
            }));
          }
        }
        dispatch(getBankAccountsFailure());
      }
    } catch(error) {
      dispatch(getBankAccountsFailure());
    }
  };
}

export function removeBankAccount({
  id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.KYC.REMOVE_BANK_ACCOUNT, {id}), {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removeBankAccount({
              id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function addBankAccount({
  formData,
  success,
  failure
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.KYC.ADD_BANK_ACCOUNT, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });
      const rData = await response.json();
      if(response.status === 201) {
        success()
      } else {
        if(response.status === 401 && rData.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(addBankAccount({
              formData,
              success,
              failure
            }));
          }
        }
        failure(rData)
      }
    } catch(error) {
      failure(error)
    }
    dispatch(processingFinish());
  };
}
