import React, {useEffect} from 'react';
import {ModuleBlock} from './ModuleBlock';
import {ModuleCompanyInformationEdit} from './ModuleCompanyInformationEdit';
import {VendorPortalInvite} from './VendorPortalInvite';

export const ModuleCompanyInformation = ({
  contact,
  isVendor,
}) => {
  const {
    name,
    first_name,
    last_name,
    email,
    is_locked: isLocked,
    address = {},
  } = contact;
  const [editMode, setEditMode] = React.useState(new URLSearchParams(window.location.search).get('editMode') === 'true');
  useEffect(() => {
    if(isLocked) setEditMode(false);
  }, [
    editMode, isLocked
  ])
  const {
    id: addressId,
    line1,
    line2,
    city,
    state,
    zip,
  } = address || {};
  return editMode ? <ModuleCompanyInformationEdit contact={contact} setEditMode={setEditMode}/> :
    <ModuleBlock
      small={true}
      title={'Company Information'}
      buttons={isLocked ? [] : [
        {
          label: 'Edit',
          onClick: () => {
            setEditMode(!editMode);
          },
        },
      ]}
    >
      <div className={'grid'}>
        <span>{name} {(first_name || last_name) && <>({first_name}{first_name && last_name && ' '}{last_name})</>}</span>
        <span>{email}</span>
        {isVendor ? <VendorPortalInvite vendor={contact}/> : null}
        {addressId ? <>
          <h2 className={'mt-4 mb-0.5 text-neutral-150'}>Billing Address</h2>
          <span>{line1}</span>
          {line2 ? <span>{line2}</span> : null}
          <span>{city}, {state} {zip}</span>
        </> : null}
      </div>
    </ModuleBlock>;
};
