import React, {useEffect, useState} from 'react';
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authSelector} from "../../../../slices/auth";
import Debugger from "../../../../utils/Debug";
import {payvyToast} from "../../../../utils/Utility";
import {CommandMenu} from "../../../elements/CommandMenu/CommandMenu";
import {ChatPanel} from "./ChatPanel";
import {ModalKeyboardShortcuts} from "./ModalKeyboardShortcuts";
import {PayvySidebar} from './PayvySidebar';

export const SidebarAndTopBar = ({
  minimized,
  setMinimized,
  children
}) => {
  const navigate = useNavigate();
  const {siteIsLive} = useSelector(authSelector);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [chatOpen, _setChatOpen] = useState(false);
  const setChatOpen = () => _setChatOpen(!chatOpen)
  const [lastSiteLiveCheckResult, setLastSiteLiveCheckResult] = useState(true);
  const [lastSiteLiveCheckToasterId, setLastSiteLiveCheckToasterId] = useState("");
  useEffect(() => {
    if(siteIsLive === undefined) return;
    if(siteIsLive !== lastSiteLiveCheckResult) {
      setLastSiteLiveCheckResult(siteIsLive);
      if(lastSiteLiveCheckToasterId) {
        toast.dismiss(lastSiteLiveCheckToasterId);
        setLastSiteLiveCheckToasterId("");
      }
      if(!siteIsLive) {
        Debugger.info('🌐 PAYVY', 'Site is not live.')
        const toasterId = payvyToast('Server connection lost. The site is temporarily unavailable while connection is being restored.', {
          appearance: 'error',
          duration: Infinity
        });
        setLastSiteLiveCheckToasterId(toasterId);
      } else {
        Debugger.info('🌐 PAYVY', 'Site is live.')
        payvyToast('Server connection restored. You\'re back online.', {
          appearance: 'info',
          duration: 4000
        });
      }
    }
  }, [siteIsLive, lastSiteLiveCheckResult, lastSiteLiveCheckToasterId, navigate]);

  return <div
    className={`flex flex-col ${siteIsLive === false ? 'pointer-events-none opacity-50 select-none global-disable' : ''}`}>
    <CommandMenu/>
    <ChatPanel chatOpen={chatOpen} toggleChatPanel={setChatOpen}/>
    <ModalKeyboardShortcuts/>
    <div className={'flex flex-row'}>
      <PayvySidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} setChatOpen={setChatOpen}
                    minimized={minimized} setMinimized={setMinimized}/>
      <div className="bg-neutral-0 w-full h-screen overflow-auto p-0 pb-10">
        {children}
      </div>
    </div>
  </div>;
};
