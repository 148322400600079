import {Form, Formik} from 'formik';
import React, {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import * as Yup from 'yup';
import {PayvyPageHeader} from "../../comps/elements";
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  SearchContact,
  SelectApproval
} from "../../comps/forms";
import {FORM_ERROR_MESSAGES, PAYVY_URL} from '../../constants';
import useFormikHotkeys from "../../hooks/useFormikHotkeys";
import {createBill, getUserBills} from "../../slices/newBill";
import {build_error_message, formatDateForAPItoISO, handleErrors, payvyToast} from "../../utils/Utility";

const BillCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    loading: {posting: processing},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const updateBills = useCallback(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    status,
    searchQuery
  ]);
  return <div className="container max-w-full border-b-2 border-gray-200">
    <PayvyPageHeader parents={[
      {name: 'Payables'},
      {
        name: 'Bills',
        href: PAYVY_URL.BILLS.LIST
      }
    ]}>Create New Bill</PayvyPageHeader>
    <div className="container max-w-full bg-neutral-50 m-0 p-0 pb-10">
      <div className={'grid grid-cols-2 gap-6 mx-4'}>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            contactId: '',
            name: '',
            created: '',
            dueDate: '',
            approvers: '',
          }}
          validationSchema={Yup.object({
            contactId: Yup.number()
                          .integer()
                          .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            name: Yup.string()
                     .max(21, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 21}))
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            created: Yup.date()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            dueDate: Yup.date()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            approvers: Yup.array(Yup.number()
                                    .integer()
                                    .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)),
          })}
          onSubmit={(values, helpers) => {
            dispatch(createBill({
              body: {
                name: values.name,
                contact_id: values.contactId,
                created: formatDateForAPItoISO(values.created),
                due_date: formatDateForAPItoISO(values.dueDate),
                approvers: values.approvers,
              },
              successCallback: () => {
                payvyToast('Bill created successfully', {appearance: 'success'})
                updateBills();
              },
              errorCallback: (error) => {
                const data = error.data || {};
                if('message' in error) {
                  payvyToast(error.message, {appearance: 'error'});
                }
                handleErrors(data, helpers);
              }
            }))
          }}
        >
          {props => <Form>
            <div className={'w-full grid grid-cols-2 gap-5'}>
              <PayvyLabeledInput
                label={'Contact'}
                name={'contactId'}
                alwaysLabel={true}
              >
                <SearchContact
                  setField={({value}) => props.setFieldValue('contactId', value)}
                  value={props.values.contactId}
                />
              </PayvyLabeledInput>
              <PayvyLabeledInput
                label={'Bill Name'}
                as={PayvyInput}
                name={'name'}
                placeholder={'Bill Name'}
              />
            </div>
            <div className={'w-full grid grid-cols-2 gap-5'}>
              <PayvyLabeledInput
                label={'Bill date'}
                name={'created'}
                alwaysLabel={true}
              >
                <PayvyDatePicker
                  value={props.values.created}
                  name={'created'}
                  onChange={(created) => props.setFieldValue('created', created)}
                  clearIcon={null}
                />
              </PayvyLabeledInput>
              <PayvyLabeledInput
                label={'Due date'}
                name={'dueDate'}
                alwaysLabel={true}
              >
                <PayvyDatePicker
                  value={props.values.dueDate}
                  name={'dueDate'}
                  onChange={(dueDate) => props.setFieldValue('dueDate', dueDate)}
                  clearIcon={null}
                />
              </PayvyLabeledInput>
            </div>
            <PayvyLabeledInput
              label={'Select approver'}
              name={'approvers'}
              alwaysLabel={true}
            >
              <SelectApproval
                setField={value => props.setFieldValue('approvers', [value])}
                value={props.values.approvers}
              />
            </PayvyLabeledInput>
            <GlobalFormError errors={props.errors['nonFieldErrors']}/>
            <div className={'w-full flex flex-row justify-end gap-5 mt-5'}>
              <PayvyIconButton
                buttonText={'Cancel'}
                onClick={() => navigate(PAYVY_URL.BILLS.LIST)}
                disabled={processing}
                loading={processing}
                borderSize={2}
                borderColor={'red-300'}
                mainColor={'neutral-0'}
                textColor={'red-300'}
                hoverTextColor={'neutral-0'}
                shortcutKey={'escape'}
              />
              <PayvyIconButton
                buttonText={'Create bill'}
                disabled={processing}
                loading={processing}
                type={'submit'}
              />
            </div>
          </Form>}
        </Formik>
      </div>
    </div>
  </div>;
};

export default BillCreatePage;
