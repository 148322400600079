import {marked} from "marked";
import {Resizable} from "re-resizable";
import React, {useEffect, useRef, useState} from "react";
import {FaCopy, FaSearch, FaTrash} from "react-icons/fa";
import {FaX} from "react-icons/fa6";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../../constants";
import {
  addMessageToRoom,
  createNewRoom,
  deleteRoom,
  getConnectionInfo,
  getRooms,
  getSpecificRoom,
  switchSelectedThread
} from "../../../../slices/payvygpt";
import {PayvyIconButton} from "../../../forms";

export const Markdown = ({children}) => {
  const html = marked(children || '');
  return <div dangerouslySetInnerHTML={{__html: html}}/>;
};

export const ChatPanelMessage = ({message}) => {
  const [showCopy, setShowCopy] = useState(false);
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(message.message);
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000);
  }
  if(message.role === 'user') {
    return <div className="mb-2 p-2 ml-4 bg-slate-800 text-slate-100 rounded-lg self-end">
      <Markdown>{message.message}</Markdown>
    </div>
  } else if(message.role === 'assistant') {
    return <div className="flex flex-col justify-end bg-gray-100 text-slate-800 rounded-lg self-start p-2"
                onMouseEnter={() => setShowCopy(true)}
                onMouseLeave={() => setShowCopy(false)}
    >
      {message.message !== '...' && <div className={'flex justify-between pr-1'}>
        <div className={'font-bold'}>PayvyGPT</div>
        {copied ? <div className={'text-xs text-gray-400'}>Copied!</div> :
          <FaCopy className={`cursor-pointer text-xs text-gray-400 hover:text-gray-600 ${showCopy ? '' : 'opacity-0'}`}
                  onClick={copyToClipboard}/>}
      </div>}
      <Markdown>{message.message}</Markdown>
    </div>
  }
}

export const ChatPanel = ({
  chatOpen,
  toggleChatPanel
}) => {
  const dispatch = useDispatch();
  const {
    loading: {
      item: loading,
      posting
    },
    selectedThread,
    threads = [],
    enabled,
    openiApiKey,
    geminiApiKey,
    llmModel
  } = useSelector((state) => state.payvygpt)
  useEffect(() => {
    dispatch(getConnectionInfo({forceUpdate: true}));
  }, [dispatch]);
  const navigate = useNavigate();
  const activeThread = threads.find((thread) => thread.unique_hash === selectedThread);
  const [waitingForAnswerMode, setWaitingForAnswerMode] = useState(false);
  const [waitingForAnswerTimeout, setWaitingForAnswerTimeout] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [searchedThreads, setSearchedThreads] = useState([]);
  const [messages, setMessages] = useState(activeThread?.messages || [
    {
      role: 'assistant',
      message: 'Hi, I am PayvyGPT. How can I help you today?'
    }
  ]);
  const [input, setInput] = useState("");
  const [answerModeInterval, setAnswerModeInterval] = useState(0);
  const messagesEndRef = useRef(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    if(messages && messages.length > 0 && messages[messages.length - 1].role === 'assistant') {
      setWaitingForAnswerMode(false);
      clearInterval(answerModeInterval);
      clearTimeout(waitingForAnswerTimeout);
    }
    if(messages && messages.length === 0) {
      setMessages([
        {
          role: 'assistant',
          message: 'Hi, I am PayvyGPT. How can I help you today?'
        }
      ])
    }
  }, [messages, chatOpen, waitingForAnswerMode, answerModeInterval, waitingForAnswerTimeout]);
  useEffect(() => {
    if(activeThread?.messages) setMessages(activeThread.messages)
  }, [activeThread])
  useEffect(() => {
    dispatch(getRooms({forceUpdate: true}))
  }, [dispatch])
  useEffect(() => {
    if(search.trim()) {
      setSearchedThreads(threads.filter((thread) => (thread.name || 'Unnamed chat').toLowerCase()
                                                                                   .includes(search.toLowerCase())))
    } else {
      setSearchedThreads(threads)
    }
  }, [dispatch, search, threads])
  useEffect(() => {
    if((!selectedThread || !threads.some(thread => thread.unique_hash === selectedThread)) && threads.length > 0) {
      dispatch(switchSelectedThread(threads[0].unique_hash));
    }
  }, [threads, selectedThread, dispatch])
  const pollForAssistantResponse = () => {
    const timer = 2000;
    const interval = setInterval(() => {
      dispatch(getSpecificRoom({
        roomId: activeThread.unique_hash,
        forceUpdate: true
      }))
    }, timer)
    setAnswerModeInterval(interval);
    const timeout = setTimeout(() => {
      clearInterval(interval);
      setMessages([
        ...messages,
        {
          role: 'assistant',
          message: 'The assistant is taking too long to respond. Please try again later.'
        }
      ]);
    }, timer * 3);
    setWaitingForAnswerTimeout(timeout);
  };
  const chatEnabled = enabled && (openiApiKey || geminiApiKey) && llmModel;
  const sendMessage = () => {
    setWaitingForAnswerMode(true)
    if(input.trim()) {
      setMessages([
        ...messages,
        {
          role: 'user',
          message: input
        }
      ]);
      dispatch(addMessageToRoom({
        roomId: activeThread.unique_hash,
        body: {
          message: input
        },
        successCallback: () => {
          setWaitingForAnswerMode(true)
          pollForAssistantResponse()
        }
      }))
      setInput("");
    }
    document.querySelector('textarea.chat-input')
            .focus();
  };

  return (
    <Resizable
      defaultSize={{
        width: 300,
        height: 600
      }}
      minWidth={300}
      minHeight={600}
      enable={{
        left: true,
        top: true,
        topLeft: true
      }}
      className={`fixed flex flex-col bottom-2 right-2 bg-white shadow-lg border bg-neutral-0 border-neutral-300 rounded-lg overflow-hidden ${chatOpen ? '' : 'hidden'} z-50`}
      style={{
        position: 'fixed',
        bottom: '2rem',
        right: '2rem'
      }}
    >
      {chatEnabled ? <>
          <div className="flex items-center justify-between bg-neutral-800 text-white p-3">
            <div className="relative">
              <button onClick={() => {
                setDropdownOpen(!dropdownOpen)
                // only refresh when opening
                if(!dropdownOpen) dispatch(getRooms({forceUpdate: true}))
              }} className="flex flex-row font-semibold items-center justify-center">
                Chat {!dropdownOpen ? <MdKeyboardArrowDown size={24}/> : <MdKeyboardArrowUp size={24}/>}
              </button>
            </div>
            <div>
              {activeThread && <PayvyIconButton
                buttonText={'New chat'}
                mainColor={'slate-700 bg-slate-700'}
                hoverColor={'slate-800 bg-slate-800'}
                textColor={'neutral-0'}
                onClick={() => {
                  dispatch(createNewRoom({
                    successCallback: () => {
                      setMessages([])
                      dispatch(getRooms({forceUpdate: true}))
                    }
                  }))
                }}
                disabled={waitingForAnswerMode || loading || posting}
                loading={waitingForAnswerMode || loading || posting}
              />}
              <button onClick={toggleChatPanel} className="text-white hover:text-red-400">
                <FaX size={16}/>
              </button>
            </div>
          </div>
          {dropdownOpen && (
            <div className="w-full bg-neutral-0 shadow-md border mt-1 p-2">
              <div className="flex items-center border-b pb-2">
                <FaSearch className="text-gray-400"/>
                <input type="text" className="ml-2 w-full outline-none" placeholder="Search history..." value={search}
                       onChange={(e) => setSearch(e.target.value)}/>
              </div>
              <div className="max-h-40 overflow-y-auto mt-2 text-sm">
                {searchedThreads.map(thread => (
                  <div key={thread.unique_hash} className="p-2 hover:bg-gray-200 flex">
                    <div
                      className={`flex grow cursor-pointer ${thread?.unique_hash === activeThread?.unique_hash ? "font-bold text-slate-900" : ""} ${thread.name ? "text-slate-700" : "text-slate-600 font-light italic"}`}
                      onClick={() => {
                        dispatch(switchSelectedThread(thread.unique_hash));
                        setDropdownOpen(false)
                      }}
                    >
                      {thread?.unique_hash === activeThread?.unique_hash && '> '}{thread.name || "Unnamed chat"}
                    </div>
                    <div className="text-slate-600 hover:text-red-400 cursor-pointer"
                         onClick={() => {
                           dispatch(deleteRoom({
                             roomId: thread.unique_hash,
                             successCallback: () => {
                               dispatch(getRooms({forceUpdate: true}))
                             }
                           }))
                         }}>
                      <FaTrash/>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeThread ? <div className="flex flex-col h-[80%] overflow-y-auto p-3 chat-messages flex-grow break-words">
              {messages.map((msg, index) => <ChatPanelMessage message={msg} key={index}/>)}
              {waitingForAnswerMode && <ChatPanelMessage message={{
                role: 'assistant',
                message: '...'
              }}/>}
              <div ref={messagesEndRef}/>
            </div> :
            <div className="flex flex-col h-[80%] overflow-y-auto p-3 items-center justify-center flex-grow break-all">
              Start chatting with the Assistant!
              <PayvyIconButton
                buttonText={'Start Chatting'}
                mainColor={'slate-700 bg-slate-700'}
                hoverColor={'slate-800 bg-slate-800'}
                textColor={'neutral-0'}
                onClick={() => {
                  dispatch(createNewRoom({
                    successCallback: () => {
                      setMessages([])
                      dispatch(getRooms({forceUpdate: true}))
                    }
                  }))
                }}
                disabled={waitingForAnswerMode || loading || posting}
                loading={waitingForAnswerMode || loading || posting}
              />
            </div>}
          <div className="p-3 border-t flex items-center gap-2 ">
            <textarea
              className="chat-input flex-grow p-2 border rounded-lg resize-none h-12"
              value={input}
              disabled={activeThread === undefined}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && (e.preventDefault(), sendMessage())}
              placeholder="Type a message..."
            />
            <PayvyIconButton
              buttonText={'Send'}
              mainColor={'slate-700 bg-slate-700'}
              hoverColor={'slate-800 bg-slate-800'}
              textColor={'neutral-0'}
              onClick={sendMessage}
              disabled={waitingForAnswerMode || activeThread === undefined || loading || posting}
              loading={waitingForAnswerMode || loading || posting}
            />
          </div>
        </> :
        <>
          <div className="flex items-center justify-between bg-neutral-800 text-white p-3">
            <span className="font-semibold">Chat</span>
            <button onClick={toggleChatPanel} className="text-white hover:text-red-400">
              <FaX size={20}/>
            </button>
          </div>
          <div className={'flex flex-col overflow-y-auto p-3 flex-grow break-all'}>
            <div className={'flex flex-col items-center justify-center h-full'}>
              <div className={'text-center'}>
                <div className={'text-lg'}>Please enable the PayvyGPT Integration and configure your API keys and
                  selected model to proceed.
                </div>
                <div className={'mt-4'}>
                  <PayvyIconButton
                    buttonText={'Go to settings'}
                    onClick={() => navigate(PAYVY_URL.SETTINGS.INTEGRATIONS)}
                    fullWidth={true}
                    wrapperClassName={'w-full'}
                  />
                </div>
              </div>
            </div>
          </div>
        </>}
    </Resizable>
  );
};
