import {noop} from 'lodash';
import moment from 'moment';
import React, {useState} from 'react';
import {BsFillTrashFill} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from "../../../comps/modals";
import {removeTransaction} from '../../../slices/newTransaction';
import {payvyToast} from "../../../utils/Utility";

export const ModalTransactionRemove = ({
  transaction: {
    id,
    date,
    contact: {name: contactName} = {},
  } = {},
  refreshList,
}) => {
  refreshList = refreshList || noop;
  const dispatch = useDispatch();
  const {
    loading: {
      list: loading,
      deleting: processing
    },
  } = useSelector((state) => state.transactions);

  const [modalOpen, setModalOpen] = useState(false);
  const confirmRemoveTransaction = () => {
    dispatch(removeTransaction({
      id,
      successCallback: () => {
        refreshList(new Date());
        setModalOpen(false);
        payvyToast('Transaction removed successfully.', {appearance: 'success'});
      },
      errorCallback: (error) => {
        payvyToast(error.toString() || 'Transaction removal failed.', {appearance: 'error'});
      },
    }));
  };
  return <>
    <PayvyIconButton
      Icon={BsFillTrashFill}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Transaction'}
      type={'a'}
      iconSize={16}
      iconPaddingX={0.5}
      iconPaddingY={0.5}
      mainColor={'none'}
      borderColor={'none'}
      iconColor={'neutral-500'}
      hoverTextColor={'red-300'}
    />
    <ModalPayvyBase
      title={`Removing transaction`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, remove transaction'}
      confirmButtonAction={confirmRemoveTransaction}
      confirmButtonLoading={loading || processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={loading || processing}
    >
      <p>Are you sure you want to remove <b>"{contactName} on {moment(date)
      .format('ll')}"</b> transactions?</p>
    </ModalPayvyBase>
  </>;
};
