import React from "react";
import {FaFile, FaFileImage, FaFilePdf} from "react-icons/fa";

export const FileDisplay = ({
  file,
  errors,
}) => {
  if(!file) return null;
  const Icon = file.type.includes('pdf') ? FaFilePdf : file.type.includes('image') ? FaFileImage : FaFile;
  return (<div className={'flex flex-col items-center'} title={file.name}>
    <Icon className={'w-8 h-8 mb-2'}/>
    <div className={'flex flex-col'}>
      <p
        className={'text-xs centered'}>{file.name.length > 10 ? file.name.substring(0, 10) + '...' : file.name} ({file.size} bytes)</p>
      {errors && <p className={'text-2xs text-red-950'}>{errors.map(e => e.message)}</p>}
    </div>
  </div>);
};
