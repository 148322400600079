import React from "react";
import {Tooltip} from "react-tooltip";

const statusConfig = {
  false: {
    bgClass: "bg-red-50",
    textClass: "text-red-900",
    tooltipId: "ocr-error",
    tooltipContent: (ocrProgress) => `OCR failed (${ocrProgress}% completed)`,
  },
  null: {
    bgClass: "bg-orange-50",
    textClass: "text-orange-900",
    tooltipId: "ocr-in-progress",
    tooltipContent: (ocrProgress) => `OCR In progress (${ocrProgress}%)`,
  },
  true: {
    bgClass: "bg-blue-50",
    textClass: "text-blue-900",
    tooltipId: "ocr-completed",
    tooltipContent: () => `OCR completed`,
  },
};

export const OCRInformation = ({
  ocrProcessed,
  ocrProgress
}) => {
  const status = ocrProcessed === null ? "null" : ocrProcessed === false ? "false" : "true";
  const {
    bgClass,
    textClass,
    tooltipId,
    tooltipContent
  } = statusConfig[status];

  return (
    <div className={`flex ${bgClass} ${textClass} px-1 py-0.5 rounded`}>
      <div className="flex" data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent(ocrProgress)}>
        OCR
      </div>
      <Tooltip id={tooltipId}/>
    </div>
  );
};
