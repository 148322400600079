import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {createContactIdentity, finixSelector, resetFinixState} from '../../../slices/finix';
import {FinixAddPaymentMethod} from './FinixAddPaymentMethod';

export const ModalAddPaymentMethod = ({
  merchantId,
  buyerId,
  setSelectedBuyerId,
  contactId,
  addedSuccessfully,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const {
    processing,
    loading,
  } = useSelector(finixSelector);
  useEffect(() => {
    setMessage('');
  }, [modalOpen]);
  const addedSuccessfullyPreHook = () => {
    setModalOpen(false);
    addedSuccessfully();
  };
  return <>
    <PayvyIconButton
      buttonText={'Add new card'}
      onClick={() => {
        dispatch(resetFinixState());
        setModalOpen(true);
      }}
      mainColor={'none'}
      textColor={'neutral-900'}
      hoverTextColor={'neutral-700'}
      wrapperClassName={'w-full justify-end'}
    />
    <ModalPayvyBase
      title={`Add new card`}
      isOpen={modalOpen}
      noButtons={true}
      dismissButtonAction={() => setModalOpen(false)}
      position={'top-1/5 left-1/3'}
    >
      {!!buyerId ? <FinixAddPaymentMethod
        merchantId={merchantId}
        buyerId={buyerId}
        contactId={contactId}
        addedSuccessfully={addedSuccessfullyPreHook}
      /> : <>
        <p>Customer is not yet created on payment service.</p>
        {message ? <>
            <p className={'text-red-300 font-bold m-2 my-0 py-1'}>{message}</p>
            {message === 'Validation is not possible.' && <p className={'text-red-300 font-light m-2 py-1'}>
              Make sure your contact has all the following information filled out: name, email, phone number, address
            </p>}
          </> :
          <PayvyIconButton
            buttonText={'Create customer'}
            loading={loading || processing}
            onClick={() => {
              if(contactId) dispatch(createContactIdentity({
                contactId,
                success: (data) => {
                  const {
                    success,
                    message,
                    customer_id: customerId,
                  } = data;
                  if(success && customerId) setSelectedBuyerId(customerId);
                  else setMessage(message);
                },
                failure: (data) => {
                  const {
                    message,
                  } = data;
                  setMessage(message);
                },
              }));
              else setMessage('Please select a contact.');
            }}
            shortcutKey={['ctrl+enter', 'ctrl+s']}
            type={'submit'}
          />}
      </>}
    </ModalPayvyBase>
  </>;
};
