import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import {build_url} from "../utils/Utility";
import {refreshAccessToken} from "./api";

export const initialState = {
  loading: false,
  processing: false,
  services: []
};

const paymentSystemSlice = createSlice({
  name: 'paymentSystem',
  initialState,
  reducers: {
    getPaymentServiceStart: (state) => {
      state.loading = true;
    },
    getPaymentServiceSuccess: (state, {payload}) => {
      state.loading = false;
      state.services = payload;
    },
    getPaymentServiceFailure: (state) => {
      state.loading = false;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  getPaymentServiceStart,
  getPaymentServiceSuccess,
  getPaymentServiceFailure,
  processingStart,
  processingFinish,
} = paymentSystemSlice.actions;
export const paymentSystemSelector = (state) => state.paymentSystem;
export default paymentSystemSlice.reducer;

export function getPaymentServiceInformation({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(getPaymentServiceStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.LIST, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getPaymentServiceSuccess(data));
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getPaymentServiceInformation({
              success,
              failure
            }));
          }
        }
        failure(data);
        dispatch(getPaymentServiceFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(getPaymentServiceFailure());
    }
  };
}

export function saveServiceConfiguration({
  configuration = {},
  serviceId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.SAVE_CONFIGURATION, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          payment_service: serviceId,
          configuration,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(saveServiceConfiguration({
              configuration,
              serviceId,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function linkContactToServiceRecipient(data) {
  const {
    success = () => {},
    failure = () => {},
    ...requestData
  } = data;
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.LINK_RECIPIENT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(requestData),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(linkContactToServiceRecipient(data));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function unlinkContactFromRecipient({
  contact,
  service_name,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.UNLINK_RECIPIENT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          contact,
          service_name,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(unlinkContactFromRecipient({
              contact,
              service_name,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function refreshPaymentSystemConnectionData({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.REFRESH_INFORMATION, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(refreshPaymentSystemConnectionData({
                success,
                failure
              }));
            }
          }
        }
        failure('Failed to refresh.');
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function linkWiseAccount({
  profileId,
  code,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.WISE_LINK, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          profile_id: profileId,
          code,
        }),
      });
      if(response.status >= 200 && response.status < 300) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(linkWiseAccount({
              profileId,
              code,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function linkRevolutAccount({
  code,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PAYMENT_SYSTEM.REVOLUT_LINK, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          code,
        }),
      });
      if(response.status >= 200 && response.status < 300) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(linkRevolutAccount({
              code,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}

export function refreshPaymentServiceInformation({
  internalName,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.PAYMENT_SYSTEM.REFRESH_INFORMATION_FOR, {name: internalName}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status >= 200 && response.status < 300) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(refreshPaymentServiceInformation({
              internalName,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    } finally {
      dispatch(processingFinish());
    }
  };
}
