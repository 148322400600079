import {InputMask} from "@react-input/mask";
import {Form, Formik} from 'formik';
import React, {useRef} from 'react';
import PhoneInput from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  PayvySelectComponent
} from '../../../comps/forms';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, getCompanyDetails} from '../../../slices/companies';
import {getUser, updateUserInformation, userSelector} from '../../../slices/user';
import {ModalChangeAddress} from "./ModalChangeAddress";
import {ModalChangeDobSSN} from "./ModalChangeDobSSN";
import {ModalChangePassword} from "./ModalChangePassword";
import {ModalSetupTFA} from "./ModalSetupTFA"

export const FormAccount = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const {company: {id: companyId} = {}} = useSelector(companiesSelector);
  const {
    loading,
    processing,
    user: {
      id,
      email,
      phone,
      address,
      new_email: newEmail,
      first_name: firstName,
      last_name: lastName,
      username,
      job_title: jobTitle,
      date_of_birth: dateOfBirth,
      bill_list_tab: billListTabPreference,
      social_security_number: ssn,
    } = {},
  } = useSelector(userSelector);
  const {
    city,
    state,
    line1,
    line2,
    zip,
  } = address || {};

  const viewOptions = [
    {
      key: 'all',
      value: 'all',
      text: 'All',
      label: 'All',
    },
    {
      key: 'draft',
      value: 'draft',
      text: 'Draft',
      label: 'Draft',
    },
    {
      key: 'awaiting-approval',
      value: 'awaiting-approval',
      text: 'Awaiting approval',
      label: 'Awaiting approval',
    },
    {
      key: 'awaiting-payment',
      value: 'awaiting-payment',
      text: 'Awaiting payment',
      label: 'Awaiting payment',
    },
    {
      key: 'paid',
      value: 'paid',
      text: 'Paid',
      label: 'Paid',
    },
  ];
  return <div className={'flex flex-col w-full xl:w-6/12'}>
    <h1 className={'flex pt-3 mx-3 w-11/12 font-light text-xl text-neutral-700 border-b border-neutral-700'}>User
      Settings</h1>
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        username: username,
        jobTitle: jobTitle,
        billListTabPreference: billListTabPreference,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string()
                      .required(),
        lastName: Yup.string()
                     .required(),
        email: Yup.string()
                  .email()
                  .required(),
        phone: Yup.string()
                  .nullable()
                  .required(),
        username: Yup.string()
                     .required(),
        jobTitle: Yup.string()
                     .required(),
        billListTabPreference: Yup.string()
                                  .oneOf(['all', 'draft', 'awaiting-approval', 'awaiting-payment', 'paid'])
                                  .required(),
      })}
      onSubmit={(values) => {
        dispatch(updateUserInformation({
          id,
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          phone: values.phone,
          username: values.username,
          job_title: values.jobTitle,
          bill_list_tab: values.billListTabPreference,
          success: () => {
            dispatch(getUser({
              success: () => {
                dispatch(getCompanyDetails({id: companyId}));
                addToast('Saved user information.', {appearance: 'success'});
              }
            }));
          }
        }));
      }}
    >
      {props => <Form onSubmit={props.handleSubmit} className={'mx-3 flex flex-col gap-1 w-11/12'}>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'First Name'}
              name={'firstName'}
              as={PayvyInput}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Last Name'}
              name={'lastName'}
              as={PayvyInput}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Email'}
              name={'email'}
              as={PayvyInput}
              type={'email'}
              helpText={newEmail && newEmail !== email ? `Currently, awaiting confirmation for ${newEmail}` : undefined}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Username'}
              name={'username'}
              as={PayvyInput}
              helpText={'So other agents can @mention you in notes.'}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Phone number'}
              name={'phone'}
            >
              <PhoneInput
                international={true}
                defaultCountry="US"
                placeholder="Enter phone number"
                value={props.values.phone}
                onChange={(value) => props.setFieldValue('phone', value)}
              />
            </PayvyLabeledInput>
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Job Title'}
              name={'jobTitle'}
              as={PayvyInput}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full'}>
            <PayvyLabeledInput
              label={'Default Bill List View'}
              component={PayvySelectComponent}
              name={'billListTabPreference'}
              options={viewOptions}
              placeholder={'Select Bill View Preference'}
            />
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={'Date of Birth'}
              name={'dateOfBirth'}
              alwaysLabel={true}
            >
              <PayvyDatePicker
                value={dateOfBirth}
                disabled={true}
              />
            </PayvyLabeledInput>
          </div>
          <div className={'flex flex-row w-full md:w-6/12'}>
            <div className={'flex-inline w-10/12'}>
              <PayvyLabeledInput
                label={`Social Security Number`}
                name={'ssn'}
                alwaysLabel={true}
              >
                <InputMask
                  mask="___ __ ____"
                  replacement={{_: /\d/}}
                  readOnly={true}
                  disabled={true}
                  placeholder={`●●● ●● ${!!ssn ? ssn : '●●●●'}`}
                  className={'my-0.5 px-1 py-2.5 bg-neutral-50 cursor-not-allowed rounded-md w-full border border-neutral-500'}
                />
              </PayvyLabeledInput>
            </div>
            <div className={'flex-inline w-2/12'}>
              <ModalChangeDobSSN/>
            </div>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2'}>
          <div
            className={'flex flex-row w-full my-0.5 px-1 py-2.5 bg-neutral-50 rounded-md border border-neutral-500'}>
                        <span
                          className={'flex h-full items-center pointer-event-none text-neutral-600'}>{line1} {line2} {city} {state} {zip}</span>
            <ModalChangeAddress/>
          </div>
        </div>
        <div className={'flex flex-col sm:flex-row gap-2'}>
          <div className={'flex-inline w-full sm:w-6/12'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Update Password</span>
              <ModalChangePassword/>
            </label>
          </div>
          <div className={'flex-inline w-full sm:w-6/12'}>
            <label>
              <span className={'block text-left text-xs pl-1 pt-1 mb-1'}>Two-Factor Authentication</span>
              <ModalSetupTFA/>
            </label>
          </div>
        </div>
        <div className={'flex flex-row gap-2 justify-end mt-2'}>
          <PayvyIconButton
            buttonText={'Save User Settings'}
            iconPaddingY={3}
            iconPaddingX={6}
            disabled={loading || processing}
            loading={loading || processing}
            onClick={props.handleSubmit}
          />
        </div>
      </Form>}
    </Formik>
  </div>;
};
