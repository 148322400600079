import {Formik} from 'formik';
import {noop} from 'lodash';
import React, {useRef, useState} from 'react';
import {FaUpload} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyLabeledInput, PayvySelectComponent} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {DWOLLA_DOCUMENT_TYPES, FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, dwollaUploadDocumentVerification, getCompanyDetails} from '../../../slices/companies';
import {build_error_message} from '../../../utils/Utility';
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from '../../../utils/Validators';

export const ModalVerificationDwollaDocument = ({
  companyId = 0,
  id = 0,
  open = false,
  refreshInfo,
  big = false,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(open);
  const dwollaVerificationForm = React.createRef();
  const {
    processing,
    loading,
  } = useSelector(companiesSelector);
  return <>
    <PayvyIconButton
      buttonText={big ? 'Upload Company Verification Document' : undefined}
      Icon={FaUpload}
      onClick={() => setModalOpen(true)}
      mainColor={big ? 'none' : undefined}
      borderSize={big ? 2 : undefined}
      borderColor={big ? 'red-200' : undefined}
      textColor={big ? 'red-200' : undefined}
      iconColor={big ? 'red-200' : undefined}
      hoverTextColor={big ? 'red-300' : undefined}
      wrapperClassName={big ? 'grow justify-end' : undefined}
    />
    <ModalPayvyBase
      title={`Verification Documents`}
      isOpen={modalOpen}
      confirmButtonText={'Upload'}
      confirmButtonAction={() => dwollaVerificationForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          type: 'idCard',
          file: '',
        }}
        validationSchema={Yup.object({
          type: Yup.string()
                   .oneOf(['passport', 'license', 'idCard', 'other'])
                   .required(),
          file: Yup.mixed()
                   .nullable()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                   .test('is-correct-file', build_error_message(FORM_ERROR_MESSAGES.INCORRECT_FILE_TYPE, {types: 'jpg, jpeg, png, pdf'}), checkIfFilesAreCorrectType)
                   .test('is-big-file', build_error_message(FORM_ERROR_MESSAGES.FILE_SIZE_TOO_BIG, {size: '10 MB'}), checkIfFilesAreTooBig),
        })}
        onSubmit={(values) => {
          refreshInfo();
          const data = new FormData();
          data.append('file', values.file);
          data.append('type', values.type);
          if(id > 0) data.append('representative', id);
          dispatch(dwollaUploadDocumentVerification({
              formData: data,
              success: () => {
              setModalOpen(false);
              dispatch(getCompanyDetails({id: companyId}));
              addToast('Verification document uploaded successfully.', {appearance: 'success'});
              },
              failure: () => {
              setModalOpen(false);
              dispatch(getCompanyDetails({id: companyId}));
              addToast('Account is already verified.', {appearance: 'error'});
            },
            },
          ));
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'Document'}
            name={'file'}
            alwaysLabel={true}
          >
            <input
              type={'file'}
              name={'file'}
              accept={'image/*, application/pdf'}
              onChange={(e) => props.setFieldValue('file', e.target.files[0])}
              className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Select type of document'}
            component={PayvySelectComponent}
            name={'type'}
            options={DWOLLA_DOCUMENT_TYPES}
            placeholder={'Select type of document'}
          />
          <input type={'hidden'} ref={dwollaVerificationForm} onClick={props.handleSubmit}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
