import React from "react";
import {formatPhoneNumberIntl} from "react-phone-number-input";

export const FaxNumberOption = ({
  phoneNumber,
  isSelected,
  selectNumber,
}) => {
  return <span
    className={`cursor-pointer my-0.5 px-2 rounded-xl py-0.5 border border-neutral-50 hover:bg-neutral-50 ${isSelected ? 'bg-red-300 hover:bg-red-400' : ''}`}
    onClick={() => selectNumber(phoneNumber)}>
        {formatPhoneNumberIntl(phoneNumber)}
    </span>;
};
