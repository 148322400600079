import {createSlice} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import {createApiThunk} from "./api";
import {DB_DROPBOX_STORAGE, resetCacheForStore} from "./db";

export const integrationDropboxSliceInitialState = {
  loading: {
    item: false,
    posting: false,
  },
  importFolder: '',
  exportFolder: '',
  lastSync: null
}

const IntegrationDropboxSlice = createSlice({
  name: 'integrationDropbox',
  initialState: integrationDropboxSliceInitialState,
  reducers: {
    resetDropboxStateToInitial: (state, action) => {
      state.loading.item = false;
      state.loading.posting = false;
      state.importFolder = '';
      state.exportFolder = '';
      state.lastSync = null;
    },
    setImportFolder: (state, action) => {
      state.importFolder = action.payload;
    },
    setExportFolder: (state, action) => {
      state.exportFolder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(verifyOAuthConnection.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(verifyOAuthConnection.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(verifyOAuthConnection.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(renameDropboxImportFolder.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(renameDropboxImportFolder.fulfilled, (state, action) => {
      if(action?.payload?.payvy_import_folder) state.importFolder = action.payload.payvy_import_folder;
      state.loading.posting = false;
    })
    .addCase(renameDropboxImportFolder.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(renameDropboxExportFolder.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(renameDropboxExportFolder.fulfilled, (state, action) => {
      if(action?.payload?.payvy_export_folder) state.exportFolder = action.payload.payvy_export_folder;
      state.loading.posting = false;
    })
    .addCase(renameDropboxExportFolder.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(getConnectionInfo.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getConnectionInfo.fulfilled, (state, action) => {
      if(action?.payload?.payvy_import_folder) state.importFolder = action.payload.payvy_import_folder;
      if(action?.payload?.payvy_export_folder) state.exportFolder = action.payload.payvy_export_folder;
      if(action?.payload?.last_sync_time) state.lastSync = action.payload.last_sync_time;
      state.loading.item = false;
    })
    .addCase(getConnectionInfo.rejected, (state) => {
      state.loading.item = false;
    })
  }
})

export const {
  resetDropboxStateToInitial,
  setImportFolder,
  setExportFolder,
} = IntegrationDropboxSlice.actions;
export default IntegrationDropboxSlice.reducer;

// Specific Dropbox Thunks
export const getConnectionInfo = createApiThunk('dropbox', 'getConnectionInfo', PAYVY_API.V1.DROPBOX.CONNECTION_INFO, DB_DROPBOX_STORAGE, [], 'GET');
export const verifyOAuthConnection = createApiThunk('dropbox', 'verifyOAuthConnection', PAYVY_API.V1.DROPBOX.VERIFY, DB_DROPBOX_STORAGE, [], 'POST');
export const renameDropboxImportFolder = createApiThunk('dropbox', 'importFolder', PAYVY_API.V1.DROPBOX.RENAME_IMPORT_FOLDER, DB_DROPBOX_STORAGE, [], 'POST');
export const renameDropboxExportFolder = createApiThunk('dropbox', 'exportFolder', PAYVY_API.V1.DROPBOX.RENAME_EXPORT_FOLDER, DB_DROPBOX_STORAGE, [], 'POST');

export const resetDropboxToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetDropboxStateToInitial());
    await resetCacheForStore(DB_DROPBOX_STORAGE);
  }
}
