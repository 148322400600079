import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {displayAmount} from '../../../utils/BillHelpers';

export const CustomTooltip = ({
  active,
  payload,
  label,
}) => {
  if(active && payload && payload.length) {
    return (<div className="bg-slate-200 bg-opacity-50 border-2 border-slate-400 focus:ring-0 p-2 rounded-xl">
      <p className="label">{label}</p>
      <p className="desc text-center">
        <CurrencyFormat value={displayAmount(payload[0].value / 100)} displayType={'text'} thousandSeparator={true}
                        prefix={'$'}/></p>
    </div>);
  }
  return null;
};
