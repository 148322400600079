import {noop} from "lodash";
import React, {useState} from "react";
import {MdForwardToInbox} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {PayvyIconButton} from "../../../comps/forms";
import {ModalPayvyBase} from "../../../comps/modals";
import {companiesSelector, resendTeamMemberInvitation} from "../../../slices/companies";

export const ModalResendInvitation = ({
  email,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const {processing} = useSelector(companiesSelector);

  const confirmResendInvite = () => {
    dispatch(resendTeamMemberInvitation({
      email,
      success: () => {
        refreshInfo(new Date());
        setModalOpen(false);
        addToast('Invitation resent.', {appearance: 'success'});
      },
      failure: (error) => {
        addToast(error.toString() || 'Invitation resend failed.', {appearance: 'error'});
      }
    }));
  };
  return <>
    <PayvyIconButton
      Icon={MdForwardToInbox}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Resend invitation'}
      type={'a'}
      iconSize={16}
      iconPaddingX={0.5}
      iconPaddingY={0.5}
      mainColor={'none'}
      borderColor={'none'}
      iconColor={'neutral-500'}
      hoverTextColor={'red-300'}
    />
    <ModalPayvyBase
      title={`Resending invitation`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, resend invitation'}
      confirmButtonAction={confirmResendInvite}
      confirmButtonLoading={processing}
      dismissButtonText={'No'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to resend invitation to <b>{email}</b>?</p>
    </ModalPayvyBase>
  </>;
};
