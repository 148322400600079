import {Form, Formik} from "formik";
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import * as Yup from "yup";
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput} from "../../comps/forms";
import {FORM_ERROR_MESSAGES, PAYVY_LOGOS, PAYVY_URL} from '../../constants';
import useFormikHotkeys from "../../hooks/useFormikHotkeys";
import {authSelector, resetPasswordWithHash} from '../../slices/auth';
import {handleErrors} from "../../utils/Utility";

const PasswordResetLandingPage = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {hash} = useParams();
  let [submitted, setSubmitted] = useState(false);
  const {
    loading,
    processing,
  } = useSelector(authSelector);
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <h1 className={'flex text-xl mb-2 font-bold justify-center'}>Reset Password</h1>
        {submitted ? <div className={'text-center my-4'}>
          <p className={'text-green-300'}>Password has been reset!</p>
          <p>
            You can
            <a href={PAYVY_URL.LOGIN} className={'text-red-300 hover:text-red-400 px-1'}>login</a>
            now.
          </p>
        </div> : <div className={'text-neutral-700 flex flex-row justify-between mt-1'}>
          <Formik
            enableReinitialize={false}
            innerRef={formikRef}
            initialValues={{
              newPassword: '',
              newPasswordConfirmation: '',
            }}
            validationSchema={Yup.object({
              newPassword: Yup.string()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
              newPasswordConfirmation: Yup.string()
                                          .oneOf([Yup.ref('newPassword')], FORM_ERROR_MESSAGES.PASSWORD_DOES_NOT_MATCH)
                                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            })}
            onSubmit={(values, helpers) => {
              dispatch(resetPasswordWithHash({
                hash,
                password: values.newPassword,
                success: () => {
                  setSubmitted(true);
                },
                failure: (error) => {
                  handleErrors(error, helpers);
                }
              }));
            }}
          >
            {props => <Form
              onSubmit={props.handleSubmit}
              onKeyDown={(e) => {
                if(e.key === 'Enter') props.handleSubmit();
              }}
              className={'flex flex-col w-full'}
            >
              <GlobalFormError errors={props.errors['nonFieldErrors']}/>
              <PayvyLabeledInput
                label={'New Password'}
                name={'newPassword'}
                as={PayvyInput}
                type={'password'}
              />
              <PayvyLabeledInput
                label={'Confirm New Password'}
                name={'newPasswordConfirmation'}
                as={PayvyInput}
                type={'password'}
              />
              <PayvyIconButton
                loading={loading || processing}
                disabled={loading || processing}
                buttonText={'Reset password'}
                wrapperClassName={'my-6 w-full px-20'}
                fullWidth={true}
                type={'submit'}
              />
            </Form>}
          </Formik>
        </div>}
      </div>
    </div>
  </div>;
};

export default PasswordResetLandingPage;
