import React from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PayvyIconButtonGroup, PayvySearchBar} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";
import {companiesSelector, hasPermission} from "../../../slices/companies";
import {userSelector} from "../../../slices/user";
import {build_url} from "../../../utils/Utility";

const SearchBar = ({
  setQueryTerm,
  loading
}) => {
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const navigate = useNavigate();
  const contactCanAddPermission = hasPermission(company, userId, "contact_can_add");

  return (
    <div className="flex flex-row gap-x-2">
      <div className="inline-flex grow">
        <PayvySearchBar
          loading={loading}
          onSearch={setQueryTerm}
          placeholder="Search by name, phone, fax, tax number, or email"
          autoComplete="contact-search"
        />
      </div>
      {contactCanAddPermission && (
        <div className="flex flex-row">
          <PayvyIconButtonGroup
            onChange={(value) => navigate(build_url(PAYVY_URL.CONTACTS.CREATE_CUSTOMER, {type: value}))}
            choices={[
              {
                label: "New Vendor",
                value: "vendor"
              },
              {
                label: "New Customer",
                value: "customer"
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
