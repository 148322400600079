import React from 'react';
import {ModuleBills} from './ModuleBills';
import {ModuleCompanyInformation} from './ModuleCompanyInformation';
import {ModuleGeneralLedger} from "./ModuleGeneralLedger";
import {ModuleInvoices} from './ModuleInvoices';
import {ModuleOverview} from './ModuleOverview';
import {ModulePayments} from './ModulePayments';
import {ModulePaymentServiceLinking} from "./ModulePaymentServiceLinking";
import {ModuleQuotes} from './ModuleQuotes';

export const Overview = ({
  contact = {},
  overview = {},
  invoices = [],
  bills = [],
}) => {
  const {
    id,
    contact_type,
  } = contact || {};
  const isVendor = contact_type === 'Vendor';
  return <div className={'grid grid-cols-3 gap-6 pt-6 mx-4'}>
    <ModuleCompanyInformation contact={contact} isVendor={isVendor}/>
    <ModuleOverview contact={contact} overview={overview}/>
    <ModulePaymentServiceLinking contactId={id}/>
    <ModuleGeneralLedger contactId={id}/>
    {isVendor ? <ModuleBills bills={bills}/> : <ModuleInvoices invoices={invoices} contact={contact}/>}
    {isVendor ? <ModulePayments contactId={id}/> : <ModuleQuotes/>}
  </div>;
};
