import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PayvyPageHeader, PayvySubmenu} from "../../comps/elements";
import {PayvyIconButton} from "../../comps/forms";
import {companiesSelector, hasPermission} from "../../slices/companies";
import {getUserInboxes, setInboxCategory, setInboxCurrentPage} from "../../slices/newInbox";
import {userSelector} from "../../slices/user";
import {CamelCaseToTitleCase, hyphensToCamelCase, payvyToast} from "../../utils/Utility";
import {Item} from "./components/Item";
import {ModalInboxUpload} from "./components/ModalInboxUpload";

const InboxReceivedPage = () => {
  const dispatch = useDispatch();
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const {
    loading: {
      list: loadingList,
      deleting: deletingItem
    },
    category,
    displayItems,
    currentPage,
    pageSize,
    numberOfItems
  } = useSelector((state) => state.inbox)
  const loading = loadingList || deletingItem;
  const inboxNumberOfItems = numberOfItems[category] || 0;
  const inboxCanAddPermission = hasPermission(company, userId, 'inbox_can_add');
  const inboxCanSeePermission = hasPermission(company, userId, 'inbox_can_see');
  const billCanAddPermission = hasPermission(company, userId, 'bill_can_add');
  const inboxCanDeletePermission = hasPermission(company, userId, 'inbox_can_delete');
  useEffect(() => {
    dispatch(getUserInboxes({
      page: currentPage,
      page_size: pageSize,
      category: category,
      errorCallback: (data) => {
        payvyToast(data.message, {appearance: 'error'});
      },
    }));
  }, [dispatch, currentPage, pageSize, category]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if(entry.isIntersecting && inboxNumberOfItems > displayItems.length && !loading) {
          dispatch(setInboxCurrentPage(currentPage + 1));
        }
      },
      {threshold: 1.0}
    );

    const loadMoreButton = document.querySelector('.w-full.flex.justify-center button');
    if(loadMoreButton) {
      observer.observe(loadMoreButton);
    }

    return () => {
      if(loadMoreButton) {
        observer.unobserve(loadMoreButton);
      }
    };
  }, [displayItems, loading, inboxNumberOfItems, dispatch, currentPage]);

  return <div className={"w-full"}>
    <PayvyPageHeader>Inbox</PayvyPageHeader>
    <PayvySubmenu
      loading={loading}
      activeItem={category}
      setActiveItem={(value) => {
        if(!loading) dispatch(setInboxCategory(value));
      }}
      options={[
        {
          name: 'Inbox',
          value: 'inbox',
        }, {
          name: 'Expiring Soon',
          value: 'expiring-soon',
        }, {
          name: 'Starred',
          value: 'starred',
        }, {
          name: 'Trash',
          value: 'trash'
        }
      ]}
      extraCustomChildren={<>{inboxCanAddPermission && <ModalInboxUpload/>}</>}
    />
    <div className={'flex flex-col w-full'}>
      <div className={'flex flex-col overflow-hidden h-fit max-h-1/2 w-full'}>
        {displayItems.length > 0 ? <>
          <div className={'grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 p-2 w-full'}>
            {displayItems.map((inboxContentItem) => (
              <Item key={inboxContentItem.id}
                    item={inboxContentItem}
                    canAddBill={billCanAddPermission}
                    canDelete={inboxCanDeletePermission}
                    canSee={inboxCanSeePermission}
              />))
            }
          </div>
          {inboxNumberOfItems > displayItems.length && (
            <PayvyIconButton
              buttonText={'Load more'}
              loading={loading}
              disabled={loading}
              onClick={() => {
                if(!loading) dispatch(setInboxCurrentPage(currentPage + 1));
              }}
              wrapperClassName={"w-full flex justify-center"}
            />
          )}
        </> : <div className={'flex flex-col w-full h-full'}>
          {loading ?
            <span className={"p-5"}>Loading your <b>{CamelCaseToTitleCase(hyphensToCamelCase(category))}</b>...</span> :
            <span className={"p-5"}>No inbox item found in <b>{CamelCaseToTitleCase(hyphensToCamelCase(category))}</b>!</span>}
        </div>}
      </div>
    </div>
  </div>
};

export default InboxReceivedPage;
