import React from "react";
import {useDropzone} from "react-dropzone";
import {FileDisplay} from "./FileDisplay";
import {fileFormatAndSizeValidator} from "./FileFormatAndSizeValidator";

export const EvidenceUploading = (props) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 8,
    validator: fileFormatAndSizeValidator, ...props,
  });

  return (<section className="w-full border border-red-950 rounded-lg bg-red-100 p-2">
    <div {...getRootProps({className: 'dropzone'})}>
      <input {...getInputProps()} />
      <p>Collect all your evidence and select them together.</p>
      <em className={'text-xs'}>(8 files are the maximum number of files you can drop here)</em>
    </div>
    <div className={'flex flex-row w-full'}>
      {acceptedFiles.length > 0 && <div className={'flex flex-col mx-1 grow'}>
        <h4>Accepted files</h4>
        <div className={'grid grid-cols-4 gap-2'}>
          {acceptedFiles.map(file => <FileDisplay key={file.path} file={file}/>)}
        </div>
      </div>}
      {fileRejections.length > 0 && <div className={'flex flex-col mx-1 grow'}>
        <h4>Rejected files</h4>
        <div className={'grid grid-cols-4 gap-2'}>
          {fileRejections.map(({
            file,
            errors,
          }) => <FileDisplay key={file.path} file={file} errors={errors}/>)}
        </div>
      </div>}
    </div>
  </section>);
};
