import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {CellCurrency, CellDetailLink, CellFormattedDate} from '../../../comps/elements/PayvyTable';
import {PAYVY_URL} from '../../../constants';
import {
  getUserBills,
  setBillCurrentPage,
  setBillFromDateStr,
  setBillOrderBy,
  setBillPageSize,
  setBillSearchQuery,
  setBillStatus,
  setBillToDateStr,
  setFilterAmountStr,
  setFilterContact
} from '../../../slices/newBill';

export const ListBill = () => {
  const dispatch = useDispatch();
  const {
    displayItems,
    loading: {list: billsLoading},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  useEffect(() => {
    dispatch(setBillCurrentPage(1))
    dispatch(setBillPageSize(10))
    dispatch(setBillStatus('awaiting payment'))
    dispatch(setBillOrderBy('-created'))
    dispatch(setFilterAmountStr(''))
    dispatch(setFilterContact(''))
    dispatch(setBillSearchQuery(''))
    dispatch(setBillFromDateStr(''))
    dispatch(setBillToDateStr(''))
  }, [dispatch]);
  useEffect(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    status,
    currentPage,
    searchQuery,
    orderBy,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    pageSize,
  ]);
  const columns = React.useMemo(() => [
    {
      Header: 'Contact',
      accessor: 'contact.name',
      detailUrl: PAYVY_URL.BILLS.DETAILS,
      Cell: CellDetailLink,
    }, {
      Header: 'Bill',
      accessor: 'name',
    }, {
      Header: 'Due',
      accessor: 'due_date',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'Amount',
      accessor: 'amount',
      Cell: CellCurrency,
      sortDescFirst: true,
    },
  ], []);
  return <PayvyTable
    columns={columns}
    data={displayItems.slice(0, 10)}
    fetchData={noop}
    loading={billsLoading}
    canScroll={false}
  />;
};
