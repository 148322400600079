import React from 'react';
import {useCountdown} from '../../../hooks/useCountdown';
import {WaitMessage} from '../../../utils/WaitMessage';

export const LoginLockOut = ({
  finishCallback,
}) => {
  const lockoutEnds = localStorage.getItem('lockoutEnds');
  const [hours, minutes, seconds] = useCountdown(lockoutEnds);
  React.useEffect(() => {
    if(hours + minutes + seconds <= 0) finishCallback(false);
  }, [hours, minutes, seconds, finishCallback]);
  return <div className={'flex flex-col items-center border-2 border-red-950 bg-red-100 px-20 py-5 mt-5'}>
    <h1 className={'flex text-xl font-bold text-red-950'}>You are locked out!</h1>
    <span className={'flex text-red-950 ml-2 mt-3 text-sm'}>You are locked out for {WaitMessage({
      hours,
      minutes,
      seconds,
      prefix: '',
    })}.</span>
  </div>;
};
