import React from "react";
import {SortableContainer} from "react-sortable-hoc";
import {SortableItem} from "./SortableItem";

export const SortableList = SortableContainer(({
  bill,
  items = [],
  currentPage,
  setCurrentPage,
  canUpdate,
  canDelete,
}) => {
  return (<ul>
    {items.map((value, index) => (<SortableItem
      key={`item-${index}`}
      bill={bill}
      index={index}
      value={value}
      disabled={items.length <= 1 || !canUpdate}
      className={items.length <= 1 || !canUpdate ? 'disabled' : ''}
      canUpdate={canUpdate}
      canDelete={canDelete}
      currentIndex={index}
      currentPage={index === currentPage}
      setCurrentPage={setCurrentPage}
    />))}
  </ul>);
});
