import {noop} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {PayvyIconButton, PayvyIconButtonGroup} from "../../../comps/forms";
import {FINIX_APPLICATION_ID, FINIX_ENVIRONMENT, FINIX_PROCESSOR} from '../../../constants';
import {addPaymentInstrumentFromToken, finixSelector} from '../../../slices/finix';
import Debugger from '../../../utils/Debug';
import {formatCurrency} from '../../../utils/Utility';
import {BlockPayvyError} from './BlockPayvyError';

let PaymentForm;
const updateState = (state, setState, values) => setState({...state, ...values});

const setNotificationFromResponseSuccess = (response, state, setState, paidSuccessfully = noop) => {
  updateState(state, setState, {
    showCheckout: false,
    errors: [],
  });
  paidSuccessfully();
};

const setNotificationFromResponseFailed = (response, state, setState) => {
  updateState(state, setState, {
    showCheckout: false,
    errors: response?.errors || [],
  });
  // window.location.reload();
};

const postPayment = async({
  dispatch,
  data,
  merchant_id: merchantId,
  invoiceId,
  state,
  setState,
  paidSuccessfully,
}) => {
  const {
    fraud_session_id,
    processor,
    token,
  } = data;
  dispatch(addPaymentInstrumentFromToken({
    merchantId: merchantId,
    token,
    fraudSessionId: fraud_session_id,
    processor,
    publicEP: true,
    invoiceId: invoiceId,
    success: (data) => setNotificationFromResponseSuccess(data, state, setState, paidSuccessfully),
    failure: (data) => setNotificationFromResponseFailed(data, state, setState),
  }));
};


const fetchMerchant = async(dispatch, merchantId, invoiceId, state, setState) => {
  updateState(state, setState, {
    showSpinner: false,
  });

  // initialize FinixAuth after fetching the merchant
  if(window.Finix) {
    window.FinixAuth = window.Finix.Auth('live', merchantId);
  }
};

const submitPaymentForm = ({
  dispatch,
  merchant_id,
  invoiceId,
  state,
  setState,
  paidSuccessfully,
}) => {
  updateState(state, setState, {
    errors: [],
  });
  let submitData = {};
  if(state.mode === 'ach') {
    submitData = {
      'account_type': state.account_type,
    };
  }

  if(PaymentForm)
    PaymentForm.submitWithData(FINIX_ENVIRONMENT, FINIX_APPLICATION_ID, submitData, function (err, res) {
      if(err) {
        const errorMessages = res.data?._embedded?.errors ?? [{}];
        const errors = [];

        for(const error of errorMessages) {
          const message = error.message || '';
          const code = error.code || '';
          const field = error.field || '';
          const newMessage = {
            message,
            code,
            field,
          };
          errors.push(newMessage);
        }
        updateState(state, setState, {
          errors,
        });
      } else {
        const token = res?.data?.id;
        const sessionKey = window.FinixAuth.getSessionKey();
        const data = {
          token,
          processor: FINIX_PROCESSOR,
          fraud_session_id: sessionKey,
        };
        if(token) {
          postPayment({
            dispatch,
            data,
            merchant_id,
            invoiceId,
            state,
            setState,
            paidSuccessfully,
          });
          updateState(state, setState, {
            loading: false,
            success: true,
          });
        } else {
          updateState(state, setState, {
            loading: false,
            success: false,
          });
        }
      }
    });
};

const initTokenizeForm = (card = true) => {
  const formFunction = (state, binInformation) => {
    if(state === undefined) {
      let brand = document.getElementById('brand');
      if(brand) brand.innerHTML = binInformation.cardBrand;
    }
  };

  PaymentForm = card ? window.PaymentForm.card(formFunction) : window.PaymentForm.bankAccount(formFunction);

  function defineField(elementId, type, placeholder) {
    const element = document.getElementById(elementId);
    if(element && element.childElementCount > 0) return;
    const field = PaymentForm.field(type, {
      placeholder,
      styles: {
        default: {
          fontFamily: 'Lato,\'Helvetica Neue\',Arial,Helvetica,sans-serif',
          margin: 0,
          outline: 0,
          tapHighlightColor: 'rgba(255,255,255,0)',
          lineHeight: '1em',
          padding: '.5em',
          fontSize: '1em',
          background: '#fff',
          border: '1px solid rgba(34,36,38,.15)',
          color: 'rgba(0,0,0,.87)',
          borderRadius: '.3rem',
          boxShadow: '0 0 0 0 transparent inset',
          transition: 'color .1s ease,border-color .1s ease',
        },
        success: {
          color: '#5cb85c',
        },
        error: {
          color: '#d9534f',
        },
      },
    });
    element.appendChild(field);
  }

  if(card) {
    try {
      defineField('cc_number', 'number', 'Credit card number');
      defineField('cc_name', 'name', 'Name of card holder');
      defineField('cc_expiration_date', 'expiration_date', 'MM / YYYY');
      defineField('cc_security_code', 'security_code', 'CVC');
      defineField('cc_address_line1', 'address.line1', '430 S Jones St', 'required');
      defineField('cc_address_line2', 'address.line2', 'APT 202');
      defineField('cc_address_region', 'address.region', 'CA', 'required');
      defineField('cc_address_postal_code', 'address.postal_code', '94108', 'required');
      defineField('cc_address_city', 'address.city', 'San Francisco', 'required');
    } catch {
      Debugger.error('Clicking too fast, stopping form rendering...');
    }
  } else {
    try {
      defineField('ba_name', 'name', 'John Smith', 'required');
      defineField('ba_account_number', 'account_number', '000000000', 'required');
      defineField('ba_bank_code', 'bank_code', '123456789', 'required');
      defineField('ba_account_type', 'account_type', '123456789', 'required');
    } catch {
      Debugger.error('Clicking too fast, stopping form rendering...');
    }
  }
};

export const BlockSetUpPaymentMethod = ({
  invoice,
  paidSuccessfully,
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    flowStatus,
    merchant = {},
    buyer = {},
  } = useSelector(finixSelector);
  const [isACHPossible, setIsACHPossible] = useState(false);
  const [isCardPossible, setIsCardPossible] = useState(false);

  const [state, setState] = useState({
    showCheckout: true,
    formInitialized: false,
    loading: true,
    mode: 'initial',
    account_type: 'CHECKING',
    errors: [],
  });
  const {
    showCheckout,
    formInitialized,
    errors,
    success,
  } = state;
  useEffect(() => {
    const achPossible = !!buyer?.id && !!merchant?.id;
    const cardPossible = !!merchant?.id;
    if(isACHPossible !== achPossible) setIsACHPossible(achPossible);
    if(isCardPossible !== cardPossible) setIsCardPossible(cardPossible);
    if(state.mode === 'initial') updateState(state, setState, {mode: achPossible ? 'ach' : 'card'});
  }, [buyer.id, merchant.id, isACHPossible, isCardPossible, state]);
  if(flowStatus === 'started' && !loading) fetchMerchant(dispatch, merchant.id, invoice.id, state, setState);
  if(flowStatus === 'fetching' || loading) return <div className={'invoice-setup-payment-method'}>Loading...</div>;
  if(success) return <div className={'invoice-setup-payment-method'}>
    <div className={'success'}>Payment Successful</div>
  </div>;

  const toggleCheckout = (mode = 'card') => {
    updateState(state, setState, {
      showCheckout: true,
      formInitialized: mode === state.mode,
      mode: mode,
      errors: [],
    });
  };
  if(flowStatus === 'fetched' && showCheckout && !formInitialized) {
    updateState(state, setState, {formInitialized: true});
    const timeoutId = setTimeout(() => {
      initTokenizeForm(state.mode === 'card');
      return () => clearTimeout(timeoutId)
    }, 200);
  }
  const purchaseClicked = () => {
    updateState(state, setState, {loading: true});
    submitPaymentForm({
      dispatch,
      merchant_id: buyer?.id || merchant.id,
      invoiceId: invoice.id,
      state,
      setState,
      paidSuccessfully,
    });
  };
  if(!isACHPossible && !isCardPossible) return <div className={'invoice-setup-payment-method'}>
    <div className={'no-payment-methods-available'}>
      <p>No payment methods available for this invoice.</p>
      <p>Please contact your merchant for more information.</p>
    </div>
  </div>;
  return <div className={'invoice-setup-payment-method'}>
    <PayvyIconButtonGroup
      value={state.mode}
      onChange={(value) => toggleCheckout(value)}
      fullWidth={true}
      wrapperClassName={'w-full grow justify-end mb-1'}
      choices={[
        {
          label: 'ACH',
          value: 'ach',
        }, {
          label: 'Credit Card',
          value: 'card',
        },
      ]}
    />
    <BlockPayvyError errors={errors}/>
    {state.mode === 'card' ? <div>
      {loading ? <span>Loading...</span> : <form onSubmit={purchaseClicked}>
        <div className={'flex flex-col'}>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Name</label>
              <div id={'cc_name'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Card number</label>
              <div id={'cc_number'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>Expiration</label>
              <div id={'cc_expiration_date'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>CVC</label>
              <div id={'cc_security_code'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Line 1</label>
              <div id={'cc_address_line1'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Line 2</label>
              <div id={'cc_address_line2'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>City</label>
              <div id={'cc_address_city'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>State</label>
              <div id={'cc_address_region'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>Zip</label>
              <div id={'cc_address_postal_code'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
        </div>
      </form>}
    </div> : null}
    {state.mode === 'ach' ? <div>
      {loading ? <span>Loading...</span> : <form onSubmit={purchaseClicked}>
        <div className={'flex flex-col'}>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Name</label>
              <div id={'ba_name'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Account number</label>
              <div id={'ba_account_number'} className={'w-full h-10 finix-iframe'}/>
            </div>
            <div className={'flex flex-col w-full px-2'}>
              <label>Bank code</label>
              <div id={'ba_bank_code'} className={'w-full h-10 finix-iframe'}/>
            </div>
          </div>
          <div className={'flex flex-row'}>
            <div className={'flex flex-col w-full px-2'}>
              <label>Account type</label>
              <div id={'account_type'} className={'w-full h-10 finix-iframe'}>
                <Select
                  options={[
                    {
                      value: 'CHECKING',
                      label: 'Checking',
                    }, {
                      value: 'SAVINGS',
                      label: 'Savings',
                    },
                  ]}
                  name={'account_type'}
                  defaultValue={{
                    value: 'CHECKING',
                    label: 'Checking',
                  }}
                  setValue={(option) => updateState(state, setState, {account_type: option.value})}
                />
              </div>
            </div>
          </div>
        </div>
      </form>}
    </div> : null}
    <PayvyIconButton
      buttonText={`Pay ${formatCurrency(invoice.amount)}`}
      onClick={purchaseClicked}
      fullWidth={true}
      wrapperClassName={'mt-4 w-full'}
      shortcutKey={['ctrl+enter', 'ctrl+s']}
      type={'submit'}
    />
  </div>;
};
