import {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {useIsUnverifiedAccount} from "../../../hooks/useIsUnverifiedAccount";

export const UnverifiedAccountRoute = () => {
  const navigate = useNavigate();
  const isUnverifiedAccount = useIsUnverifiedAccount();
  useEffect(() => {
    if(!isUnverifiedAccount) navigate(PAYVY_URL.DASHBOARD, {replace: true})
  }, [navigate, isUnverifiedAccount]);
  return (
    <Outlet/>
  );
};
