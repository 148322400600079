import {useFormikContext} from 'formik';
import React from 'react';

export const PayvyGlobalFormError = () => {
  const {errors: {nonFieldErrors} = {}} = useFormikContext() || {};
  if(!nonFieldErrors) return null;
  const errors = nonFieldErrors.split('\n')
                               .map(error => error.trim());
  if(!errors.length) return null;
  return <div className={'text-red-800 text-sm flex flex-col'}>
    {errors.map((error, index) => <div key={index}>{error}</div>)}
  </div>;
};
