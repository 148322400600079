import {createSlice} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import {mergeItemsIntoList} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_QUICKBOOKS_STORAGE, resetCacheForStore} from "./db";

export const integrationQuickbooksSliceInitialState = {
  loading: {
    item: false,
    posting: false,
  },
  suspended: false,
  enabled: false,

  invoicesSyncInProgress: '',
  payvyExpenseAccountName: '',
  payvyExpenseAccountId: null,

  lastSync: null,
  accountsLastUpdated: null,
  classesLastUpdated: null,
  contactsLastUpdated: null,
  invoicesLastUpdated: null,
  paymentsLastUpdated: null,

  accounts: [],
  bankAccounts: [],
  expenseAccounts: [],
  classes: [],
}

const IntegrationQuickbooksSlice = createSlice({
  name: 'integrationQuickbooks',
  initialState: integrationQuickbooksSliceInitialState,
  reducers: {
    resetQuickbooksStateToInitial: (state, action) => {
      state.loading = integrationQuickbooksSliceInitialState.loading;
      state.suspended = integrationQuickbooksSliceInitialState.suspended;
      state.enabled = integrationQuickbooksSliceInitialState.enabled;

      state.invoicesSyncInProgress = integrationQuickbooksSliceInitialState.invoicesSyncInProgress;
      state.payvyExpenseAccountName = integrationQuickbooksSliceInitialState.payvyExpenseAccountName;
      state.payvyExpenseAccountId = integrationQuickbooksSliceInitialState.payvyExpenseAccountId;

      state.lastSync = integrationQuickbooksSliceInitialState.lastSync;
      state.accountsLastUpdated = integrationQuickbooksSliceInitialState.accountsLastUpdated;
      state.classesLastUpdated = integrationQuickbooksSliceInitialState.classesLastUpdated;
      state.contactsLastUpdated = integrationQuickbooksSliceInitialState.contactsLastUpdated;
      state.invoicesLastUpdated = integrationQuickbooksSliceInitialState.invoicesLastUpdated;
      state.paymentsLastUpdated = integrationQuickbooksSliceInitialState.paymentsLastUpdated;

      state.accounts = integrationQuickbooksSliceInitialState.accounts;
      state.bankAccounts = integrationQuickbooksSliceInitialState.bankAccounts;
      state.expenseAccounts = integrationQuickbooksSliceInitialState.expenseAccounts;
      state.classes = integrationQuickbooksSliceInitialState.classes;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(verifyOAuthConnection.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(verifyOAuthConnection.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(verifyOAuthConnection.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(getConnectionInfo.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getConnectionInfo.fulfilled, (state, action) => {
      state.suspended = action.payload.suspended;
      state.enabled = action.payload.enabled;
      state.invoicesSyncInProgress = action.payload.invoices_sync_in_progress;
      state.payvyExpenseAccountName = action.payload.payvy_expense_account_name;
      state.payvyExpenseAccountId = action.payload.payvy_expense_account_id;
      state.accountsLastUpdated = action.payload.accounts_last_updated;
      state.classesLastUpdated = action.payload.classes_last_updated;
      state.contactsLastUpdated = action.payload.contacts_last_updated;
      state.invoicesLastUpdated = action.payload.invoices_last_updated;
      state.paymentsLastUpdated = action.payload.payments_last_updated;
      state.loading.item = false;
    })
    .addCase(getConnectionInfo.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(getQuickbooksAccounts.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getQuickbooksAccounts.fulfilled, (state, action) => {
      const results = action?.payload?.results || [];
      const {
        is_bank = false,
        is_expense = false
      } = action?.meta?.arg || {};
      let accounts = [];
      results.forEach(item => {
        accounts.push({
          id: item.id,
          key: item.id,
          value: item.quickbooks_id,
          label: item.name,
        });
      });
      state.accounts = mergeItemsIntoList(state.accounts, results, true);
      if(is_bank) state.bankAccounts = mergeItemsIntoList(state.bankAccounts, accounts, true);
      else if(is_expense) state.expenseAccounts = mergeItemsIntoList(state.expenseAccounts, accounts, true);
      state.loading.item = false;
    })
    .addCase(getQuickbooksAccounts.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(linkQuickbooksAccounts.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(linkQuickbooksAccounts.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(linkQuickbooksAccounts.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(setQuickbooksExpenseAccount.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(setQuickbooksExpenseAccount.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(setQuickbooksExpenseAccount.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(getQuickbooksClasses.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getQuickbooksClasses.fulfilled, (state, action) => {
      const results = action?.payload?.results || [];
      const classes = []
      results.forEach(item => {
        classes.push({
          id: item.id,
          key: item.id,
          value: item.name,
          label: item.name,
        });
      });
      state.classes = mergeItemsIntoList(state.classes, classes, true);
      state.loading.item = false;
    })
    .addCase(getQuickbooksClasses.rejected, (state) => {
      state.loading.item = false;
    })
  }
})

export const {
  resetQuickbooksStateToInitial
} = IntegrationQuickbooksSlice.actions;
export default IntegrationQuickbooksSlice.reducer;

// Specific Box Thunks
export const getConnectionInfo = createApiThunk('quickbooks', 'getConnectionInfo', PAYVY_API.V1.QUICKBOOKS.CONNECTION_INFO, DB_QUICKBOOKS_STORAGE, [], 'GET');
export const verifyOAuthConnection = createApiThunk('quickbooks', 'verifyOAuthConnection', PAYVY_API.V1.QUICKBOOKS.VERIFY, DB_QUICKBOOKS_STORAGE, [], 'POST');
export const getQuickbooksAccounts = createApiThunk('quickbooks', 'getQuickbooksAccounts', PAYVY_API.V1.QUICKBOOKS.ACCOUNT_LIST, DB_QUICKBOOKS_STORAGE, [], 'GET');
export const linkQuickbooksAccounts = createApiThunk('quickbooks', 'linkQuickbooksAccounts', PAYVY_API.V1.QUICKBOOKS.ACCOUNT_LINK, DB_QUICKBOOKS_STORAGE, [], 'POST');
export const setQuickbooksExpenseAccount = createApiThunk('quickbooks', 'setQuickbooksExpenseAccount', PAYVY_API.V1.QUICKBOOKS.SET_EXPENSE_ACCOUNT, DB_QUICKBOOKS_STORAGE, [DB_QUICKBOOKS_STORAGE], 'POST');
export const getQuickbooksClasses = createApiThunk('quickbooks', 'getQuickbooksClasses', PAYVY_API.V1.QUICKBOOKS.CLASS_LIST, DB_QUICKBOOKS_STORAGE, [], 'GET');

export const resetQuickbooksToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetQuickbooksStateToInitial());
    await resetCacheForStore(DB_QUICKBOOKS_STORAGE);
  }
}
