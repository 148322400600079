import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {noop} from "lodash";
import React from 'react';
import './PayvyDateRangePicker.scss';

export const PayvyDateRangePicker = ({
  value,
  onChange = noop,
  className = '',
}) => {
  return <DateRangePicker
    onChange={onChange}
    value={value}
    className={className}
  />;
};
