import React from 'react';
import {ACH_RETURN_CODES} from '../../../constants';
import '../../../comps/forms/PayvyIconButton/PayvyTooltip.scss';

export function CellStatus({row: {original}}) {
  const reason = ACH_RETURN_CODES[original.return_code];
  const uniqueTooltipID = `payvy-tooltip-1`;
  return <span className={reason ? `text-red-400` : null}>
        {original.status}
    {reason && <>
      <br/>
      <span className={`${uniqueTooltipID} font-bold`}>({reason})</span>
    </>}
    </span>;
}
