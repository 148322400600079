import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {FaRedo, FaUndo} from 'react-icons/fa';
import {MdPayment} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ModalPairToLedgerBill} from "../../../comps/elements/ModalPairToLedgerBill/ModalPairToLedgerBill";
import {PayvyIconButton} from '../../../comps/forms';
import {ModalBillApproval, ModalBillRemove, ModalBillRequestApproval} from '../../../comps/modals';
import {PAYVY_URL} from '../../../constants';
import {getBill} from '../../../slices/newBill';
import {rotateInboxPage} from '../../../slices/newInbox';
import {getLedgerInformation} from "../../../slices/newIntegration";
import {build_url, payvyToast} from '../../../utils/Utility';
import {ModalDownloadBill} from "./ModalDownloadBill";
import {ModalUnapproveBill} from './ModalUnapproveBill';

const MenuBar = ({
  bill,
  currentPage,
  billPermission,
  setRefreshQuery,
}) => {
  setRefreshQuery = setRefreshQuery || noop;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {ledgers} = useSelector((state) => state.integration)
  const {
    canUpdate = false,
    canDelete = false,
  } = billPermission;
  const {
    id,
    status,
    can_approve: canApprove,
  } = bill;
  const {pages = []} = bill || {};
  const {loading: {posting: processing}} = useSelector((state) => state.inbox);
  const {inbox_item} = pages[currentPage] || {};
  const {
    id: inboxItemId,
    page_id: inboxItemPageId,
  } = inbox_item || {};
  useEffect(() => {
    dispatch(getLedgerInformation({}))
  }, [dispatch])
  const rotatePage = (reverse) => {
    if(!!inboxItemId && !!inboxItemPageId) {
      dispatch(rotateInboxPage({
        id: inboxItemId,
        page: inboxItemPageId,
        body: {reverse},
        successCallback: () => {
          dispatch(getBill({id}));
        },
        errorCallback: ({nonFieldErrors}) => {
          payvyToast(nonFieldErrors, {appearance: 'error'});
        }
      }));
    }
  };
  return <div className="flex divide-x w-full py-2 m-0 bg-neutral-50 border-b border-neutral-200">
    <div className={'flex-none'}>
      {canUpdate && (status === 'Awaiting payment' || status === 'Awaiting approval') ?
        <ModalUnapproveBill
          bill={bill}
          refreshList={setRefreshQuery}
          detailStyle={true}
        /> : null}
      {canUpdate && status === 'Draft' ? <ModalBillRequestApproval
        bill={bill}
        refreshList={setRefreshQuery}
        detailStyle={true}
      /> : null}
      {canUpdate && status === 'Awaiting approval' && canApprove ?
        <ModalBillApproval
          bill={bill}
          refreshList={setRefreshQuery}
          detailStyle
        /> : null}
      {canUpdate && status === 'Awaiting payment' ?
        <PayvyIconButton
          Icon={MdPayment}
          onClick={() => navigate(build_url(PAYVY_URL.BILLS.PAY, {'id': id}))}
          tooltipContent={'Pay bill'}
          tooltipPlace={'bottom'}
          mainColor={'none'}
          borderColor={'none'}
          iconColor={'neutral-700'}
          iconSize={22}
          hoverTextColor={'neutral-500'}
        /> : null}
      {canDelete && status !== 'Paid' ?
        <ModalBillRemove
          bill={bill}
          success={() => navigate(PAYVY_URL.BILLS.LIST)}
          detailStyle
        /> : null}
      <ModalDownloadBill bill={bill} buttonIcon={'download'} buttonClass={'item'}/>
    </div>
    {canUpdate ? <div className={'flex-none'}>
      <PayvyIconButton
        Icon={FaUndo}
        onClick={() => {
          rotatePage(true)
        }}
        disabled={processing || !(!!inboxItemId && !!inboxItemPageId)}
        tooltipContent={'Rotate Left'}
        tooltipPlace={'bottom'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      />
      <PayvyIconButton
        Icon={FaRedo}
        onClick={() => rotatePage(false)}
        disabled={processing || !(!!inboxItemId && !!inboxItemPageId)}
        tooltipContent={'Rotate Right'}
        tooltipPlace={'bottom'}
        mainColor={'none'}
        borderColor={'none'}
        iconColor={'neutral-700'}
        iconSize={22}
        hoverTextColor={'neutral-500'}
      />
    </div> : null}
    <div className={'flex-none'}>
      {ledgers.map((ledger, index) => <ModalPairToLedgerBill key={index} item={bill} ledger={ledger}
                                                             detailStyle={true}/>)}
    </div>
    <div className={'flex w-full justify-end'}>
      <div className={'px-2 self-center text-lg text-neutral-300'}>#{id}</div>
    </div>
  </div>;
};

export default MenuBar;
