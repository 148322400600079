import React, {useState} from 'react';
import {PayvyInput, PayvyLabeledInput, PayvySelectComponent} from "../../../comps/forms";
import {USStatesWithLabel} from "../../../utils/USStates";
import {zipCodeInfo} from '../../../utils/Utility';

export const FormMailingAddress = ({
  formProps,
}) => {
  const [zipProcessing, setZipProcessing] = useState(false);
  const [queryTermTimeout, setQueryTermTimeout] = useState(0);
  const queryZip = async(value) => {
    setZipProcessing(true);
    const {
      city = '',
      state = ''
    } = await zipCodeInfo(value) || {};
    await formProps.setFieldValue('mailingAddressState', state);
    await formProps.setFieldValue('mailingAddressCity', city);
    setZipProcessing(false);
  };
  return <>
    <h2 className={'text-2xl font-medium p-2 pb-12'}>Checking - Confirm your mailing address</h2>
    <p className={'text-base'}>Confirm your mailing address to receive checks.</p>
    <div className={'w-full gap-5'}>
      <PayvyLabeledInput label={'Payee'} as={PayvyInput} name={'payee'}/>
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'Address'}
        name={'mailingAddressLine1'}
        as={PayvyInput}
      />
      <PayvyLabeledInput
        label={'Address Line 2'}
        name={'mailingAddressLine2'}
        as={PayvyInput}
      />
    </div>
    <div className={'flex flex-row gap-2'}>
      <div className={'flex-inline w-3/12'}>
        <PayvyLabeledInput
          label={'Zip Code'}
          name={'mailingAddressZip'}
          as={PayvyInput}
          disabled={zipProcessing}
          onChange={(value) => {
            if(queryTermTimeout) clearTimeout(queryTermTimeout);
            formProps.setFieldValue('mailingAddressZip', value);
            setQueryTermTimeout(setTimeout(async() => {
              await queryZip(value);
            }, 1000));
          }}
        />
      </div>
      <div className={'flex-inline w-4/12'}>
        <PayvyLabeledInput
          label={'City'}
          name={'mailingAddressCity'}
          loading={zipProcessing}
          disabled={zipProcessing}
          as={PayvyInput}
        />
      </div>
      <div className={'flex-inline w-5/12'}>
        <PayvyLabeledInput
          label={'State'}
          component={PayvySelectComponent}
          name={'mailingAddressState'}
          options={USStatesWithLabel}
        />
      </div>
    </div>
  </>;
};

export default FormMailingAddress;
