import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from "../../../comps/elements";
import {getIntegrations} from "../../../slices/newIntegration";
import {CellBoolean} from "./CellBoolean";
import {CellHTML} from "./CellHTML";
import {CellLogoName} from "./CellLogoName";

export const IntegrationList = () => {
  const dispatch = useDispatch();
  const {
    loading: {list: loading},
    items = [],
  } = useSelector((state) => state.integration);
  useEffect(() => {
    dispatch(getIntegrations({}));
  }, [dispatch]);
  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: CellLogoName,
      disableSortBy: true,
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: CellHTML,
      disableSortBy: true,
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      Cell: CellBoolean,
      disableSortBy: true,
    },
  ], []);
  return <div>
    <PayvyTable
      columns={columns}
      fetchData={() => {
      }}
      data={items}
      loading={loading}
      canScroll={false}
    />
  </div>;
};
