import React, {useEffect, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {useToasts} from 'react-toast-notifications';
import {PayvyIconButton, PayvyIconButtonGroup} from '../../../comps/forms';
import {ERROR_MESSAGES} from '../../../constants';
import {companiesSelector, getCompanyDetails} from '../../../slices/companies';
import {
  endSubscription,
  getAchFeePlans,
  getCheckIssuingPlans,
  getSubscriptionPlans,
  getTwilioNumberPlans,
  startSubscription,
  updateSubscription,
  userSelector,
} from '../../../slices/user';
import {getPlanId} from "../utility/GetPlanId";
import {getPrices} from "../utility/GetPrices";
import {CurrentSubscriptionDateRange} from "./CurrentSubscriptionDateRange";
import {DisplayFaxNumber} from "./DisplayFaxNumber";
import {ModalBuyFaxNumber} from "./ModalBuyFaxNumber";

export const ActiveSubscriptions = () => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const {
    loadingDetails: loadingCompanyDetails,
    company: {
      id: companyId,
      members,
      twilio_numbers: twilioNumbers,
      active_plan: activePlan,
    } = {},
  } = useSelector(companiesSelector);
  const {
    processing,
    paymentMethods = [],
    loadingAchFeePlans = [],
    loadingSubscriptionPlans = [],
    loadingCheckIssuingPlans = [],
    loadingTwilioNumberPlans = [],
    achFeePlans = [],
    subscriptionPlans = [],
    checkIssuingPlans = [],
    twilioNumberPlans = [],
  } = useSelector(userSelector);
  const {
    name = subscriptionPlans && subscriptionPlans.length > 0 ? subscriptionPlans[0].name : 'Starter',
    interval = 'month',
    cancelling,
    status,
    period_start: periodStart,
    period_end: periodEnd,
    ach_usage: achUsage = 0,
    check_issuing_usage: checkIssuingUsage = 0,
    twilio_number_usage: twilioNumberUsage = 0,
  } = activePlan || {};
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');
  const [dirty, setDirty] = useState(false);
  const [currentUnitPrice, setCurrentUnitPrice] = useState(0);
  const [currentAchUnitPrice, setCurrentAchUnitPrice] = useState(0);
  const [currentCheckIssuingUnitPrice, setCurrentCheckIssuingUnitPrice] = useState(0);
  const [currentTwilioNumberUnitPrice, setCurrentTwilioNumberUnitPrice] = useState(0);
  const [currentPlanId, setCurrentPlanId] = useState('');
  useEffect(() => setDirty(!(name === selectedPlan && interval === paymentFrequency)), [
    selectedPlan,
    paymentFrequency,
    name,
    interval
  ]);
  useEffect(() => {
    setPaymentFrequency(interval);
    setSelectedPlan(name);
  }, [name, interval]);
  useEffect(() => {
    dispatch(getSubscriptionPlans());
    dispatch(getCheckIssuingPlans());
    dispatch(getAchFeePlans());
    dispatch(getTwilioNumberPlans());
  }, [dispatch]);
  useEffect(() => {
    const membersCount = !!members ? members.length : 1;
    setCurrentUnitPrice(getPrices(subscriptionPlans, membersCount, selectedPlan, paymentFrequency));
    setCurrentPlanId(getPlanId(subscriptionPlans, selectedPlan, paymentFrequency));
    setCurrentAchUnitPrice(getPrices(achFeePlans, membersCount));
    setCurrentCheckIssuingUnitPrice(getPrices(checkIssuingPlans, membersCount));
    setCurrentTwilioNumberUnitPrice(getPrices(twilioNumberPlans, membersCount));
  }, [subscriptionPlans, achFeePlans, checkIssuingPlans, twilioNumberPlans, members, selectedPlan, paymentFrequency]);
  const loading = loadingCompanyDetails || loadingSubscriptionPlans || loadingAchFeePlans || loadingCheckIssuingPlans || loadingTwilioNumberPlans || !members;
  const handleSubscriptionUpdate = () => {
    dispatch(updateSubscription({
      plan_id: currentPlanId,
      success: () => {
        dispatch(getCompanyDetails({id: companyId}));
      },
      failure: () => {
        addToast(ERROR_MESSAGES.SERVER_SIDE_ERROR, {appearance: 'error'});
      }
    }));
  };
  const handleSubscriptionEnding = () => {
    dispatch(endSubscription({
      success: () => {
        dispatch(getCompanyDetails({id: companyId}));
      },
      failure: () => {
        addToast(ERROR_MESSAGES.SERVER_SIDE_ERROR, {appearance: 'error'});
      }
    }));
  };
  const handleSubscriptionStart = () => {
    dispatch(startSubscription({
      plan_id: currentPlanId,
      success: () => {
        dispatch(getCompanyDetails({id: companyId}));
      },
      failure: () => {
        addToast(ERROR_MESSAGES.SERVER_SIDE_ERROR, {appearance: 'error'});
      }
    }));
  };
  if(loading) return <div className={'flex justify-center items-center h-full'}
                          style={{minHeight: '20vh'}}>
    <span className={'ml-2'}>Loading...</span>
  </div>
  return <>
    <div className={'flex flex-row text-sm text-payvyBlue-950 font-bold mt-4 -mb-2'}>
      <div className={'flex grow items-center'}>
        <CurrentSubscriptionDateRange status={status} periodStart={periodStart} periodEnd={periodEnd}
                                      cancelling={cancelling}/>
      </div>
      <div className={'flex justify-end'}>
        <PayvyIconButtonGroup
          value={paymentFrequency}
          onChange={(value) => setPaymentFrequency(value)}
          wrapperClassName={'grow justify-end'}
          choices={[
            {
              label: 'Monthly',
              value: 'month',
            }, {
              label: 'Yearly',
              value: 'year',
            },
          ]}/>
      </div>
    </div>
    <div className={'flex flex-col'}>
      <table className={'table-auto mt-2 drop-shadow-2xl w-full border border-slate-300'}>
        <thead>
        <tr className={'border border-slate-300 text-center bg-slate-50'}>
          <th className={'w-48'}>
            <div className={'flex justify-center py-2 shadow-inner'}>Product</div>
          </th>
          <th>
            <div className={'flex justify-center py-2 shadow-inner'}>Plan</div>
          </th>
          <th className={'w-32'}>
            <div className={'flex justify-center py-2 shadow-inner'}>Product Price</div>
          </th>
          <th className={'w-48'}>
            <div className={'flex justify-center py-2 shadow-inner'}>Quantity</div>
          </th>
          <th className={'w-24'}>
            <div className={'flex justify-center py-2 shadow-inner'}>Price</div>
          </th>
        </tr>
        </thead>
        <tbody className={'bg-neutral-0'}>
        <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
          <td className={'px-4 py-2'}>Platform Fee</td>
          <td className={'px-4 py-2'}>
            <Select
              options={subscriptionPlans.map((item) => ({
                key: item.name,
                value: item.name,
                label: item.name,
                text: item.name,
              }))}
              value={{
                key: selectedPlan,
                selectedPlan,
                label: selectedPlan,
                text: selectedPlan,
              }}
              onChange={(option) => setSelectedPlan(option?.value)}/>
          </td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentUnitPrice} displayType={'text'} prefix={'$'} decimalScale={2}
                           thousandSeparator fixedDecimalScale/>
          </td>
          <td className={'px-4 py-2'}>{!!members ? members.length : 0} users</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentUnitPrice * (members ? members.length : 0)} displayType={'text'}
                           prefix={'$'} decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
        </tr>
        <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
          <td className={'px-4 py-2'}>ACH Fee</td>
          <td className={'px-4 py-2'}>Per Transaction</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentAchUnitPrice} displayType={'text'} prefix={'$'} decimalScale={2}
                           thousandSeparator fixedDecimalScale/>
          </td>
          <td className={'px-4 py-2'}>{achUsage} transactions</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentAchUnitPrice * achUsage} displayType={'text'} prefix={'$'}
                           decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
        </tr>
        <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
          <td className={'px-4 py-2'}>Check Issuing</td>
          <td className={'px-4 py-2'}>Per Transaction</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentCheckIssuingUnitPrice} displayType={'text'} prefix={'$'}
                           decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
          <td className={'px-4 py-2'}>{checkIssuingUsage} transactions</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentCheckIssuingUnitPrice * checkIssuingUsage} displayType={'text'}
                           prefix={'$'} decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
        </tr>
        <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
          <td className={'px-4 py-2'}>Fax Number</td>
          <td className={'px-4 py-2'}>
            <div className={'flex flex-row gap-5'}>
              <ModalBuyFaxNumber buttonDisabled={paymentMethods.length === 0}/>
              {!!twilioNumbers && twilioNumbers.map((item, index) => <DisplayFaxNumber item={item} key={index}/>)}
            </div>
          </td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentTwilioNumberUnitPrice} displayType={'text'} prefix={'$'}
                           decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
          <td className={'px-4 py-2'}>{twilioNumberUsage} numbers</td>
          <td className={'px-4 py-2'}>
            <NumericFormat value={currentTwilioNumberUnitPrice * twilioNumberUsage} displayType={'text'}
                           prefix={'$'} decimalScale={2} thousandSeparator fixedDecimalScale/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div className={'flex justify-end'}>
      {dirty && ((status === 'active' && !cancelling) || status === 'trialing') &&
        <PayvyIconButton
          loading={processing}
          disabled={processing}
          buttonText={'Update Subscription'}
          onClick={handleSubscriptionUpdate}/>}
      {((cancelling) || (status !== 'active' && status !== 'trialing')) &&
        <PayvyIconButton
          loading={processing}
          disabled={processing || paymentMethods.length === 0}
          buttonText={'Start Subscription'}
          onClick={handleSubscriptionStart}/>}
      {((status === 'active' && !cancelling) || status === 'trialing') && <PayvyIconButton
        loading={processing}
        disabled={processing}
        buttonText={'Cancel Subscription'}
        className={'payvy-action payvy-secondary'}
        onClick={handleSubscriptionEnding}/>}
    </div>
  </>
};
