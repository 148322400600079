import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PayvyTable} from "../../../comps/elements";
import {CellCurrency} from "../../../comps/elements/PayvyTable";
import {getTransactions} from "../../../slices/newTransaction";
import {CellDetailLink} from "../../TransactionListPage/components/CellDetailLink";

export const Transaction = ({contact: {id: contactID} = {}}) => {
  const dispatch = useDispatch()
  const {
    loading: {list: loading},
    displayItems,
    numberOfItems
  } = useSelector((state) => state.transactions)
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [pageCount, setPageCount] = React.useState(0);
  const [customSorting, setCustomSorting] = React.useState('');

  useEffect(() => {
    dispatch(getTransactions({
      page: currentPage + 1,
      page_size: pageSize,
      order_by: customSorting,
      contact: contactID,
    }));
  }, [currentPage, pageSize, customSorting, contactID, dispatch])

  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: CellDetailLink,
      sortDescFirst: true,
    }, {
      Header: 'Name',
      accessor: 'contact.name',
    }, {
      Header: 'Account',
      accessor: 'bank_account.name',
    }, {
      Header: 'Amount',
      accessor: 'paid_amount',
      Cell: CellCurrency,
      sortDescFirst: true,
    }, {
      Header: 'Status',
      accessor: 'status',
    }
  ], []);

  // region Callbacks
  const fetchData = React.useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setCurrentPage(pageIndex);
    setPageSize(pageSize);
    setPageCount(Math.ceil(numberOfItems / pageSize));
  }, [numberOfItems]);
  const handleSort = React.useCallback(sortBy => {
    setCustomSorting(sortBy.length > 0 ? sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id : '');
  }, []);
  // endregion

  return <div className={`${loading ? 'm-0 p-4 overflow-hidden overflow-y-hidden' : 'm-0 p-4 overflow-y-auto'}`}>
    <PayvyTable
      columns={columns}
      data={displayItems}
      fetchData={fetchData}
      loading={loading}
      onSort={handleSort}
      count={numberOfItems}
      pageCount={pageCount}
      defaultPageSize={pageSize}
      pageSize={pageSize}
      currentPage={currentPage}
      sizeOptions={[20, 50, 100]}
    />
  </div>
};
