import moment from 'moment';
import React from 'react';
import {SiQuickbooks} from 'react-icons/si';
import {TbCircleLetterPFilled} from "react-icons/tb";
import {getActionName} from "../utils/GetActionName";
import './HistoryItem.scss';

export const LedgerItem = ({ledgerItem}) => {
  const displayIcon = ({user}) => {
    const actionName = getActionName(user);
    switch(actionName) {
      case 'quickbooksSync':
        return SiQuickbooks;
      default:
        return TbCircleLetterPFilled;
    }
  };
  const mainStyleColor = ({user}) => {
    const actionName = getActionName(user);
    switch(actionName) {
      case 'quickbooksSync':
        return 'text-green-300';
      default:
        return 'text-red-400';
    }
  };
  const subStyleColor = ({user}) => {
    const actionName = getActionName(user);
    switch(actionName) {
      case 'quickbooksSync':
        return 'text-green-200';
      default:
        return 'text-red-300';
    }
  };
  const Icon = displayIcon(ledgerItem);
  return <div className={`flex flex-column my-4 history-item ${mainStyleColor(ledgerItem)}`}>
    <div className={'self-center px-3'}>
      <Icon size={36}/>
    </div>
    <div className={'flex-row'}>
      <div className={`text-sm text-neutral-700`}>{moment(ledgerItem.timestamp)
      .format('h:mm A')} - <span className={`text-base font-bold ${subStyleColor(ledgerItem)}`}>{ledgerItem.user}</span>
      </div>
      <div className={'text-neutral-900'}>{ledgerItem.message}</div>
    </div>
  </div>;
};
