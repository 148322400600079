import {noop} from 'lodash';
import React from 'react';
import {BsEnvelope, BsPencilSquare, BsTrash} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PayvyIconButton} from '../../../comps/forms';
import {PAYVY_URL} from '../../../constants';
import {receivablesSelector, removeInvoice, sendOutInvoiceViaEmail} from '../../../slices/receivables';
import {build_url} from '../../../utils/Utility';

export function CellActions({
  column: {
    RefreshInfo,
  },
  row: {original},
}) {
  RefreshInfo = RefreshInfo || noop;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sendOutInvoiceError, setSendOutInvoiceError] = React.useState(false);
  const {
    processing,
    loading,
  } = useSelector(receivablesSelector);
  let {
    is_paid: isPaid,
    is_draft: isDraft,
    is_deleted: isDeleted,
    is_archived: isArchived,
  } = original;
  const removeContent = isPaid ? 'Archive' : 'Remove';
  return <>
    {!isPaid && isDraft && <PayvyIconButton
      Icon={BsPencilSquare}
      onClick={() => navigate(build_url(PAYVY_URL.INVOICE.EDIT, {id: original.id}))}
      tooltipContent={'Edit Invoice'}
    />}
    {!isPaid && isDraft && <PayvyIconButton
      Icon={BsEnvelope}
      loading={processing || loading}
      onClick={() => {
        setSendOutInvoiceError(false);
        dispatch(sendOutInvoiceViaEmail({
          invoiceId: original.id,
          success: RefreshInfo,
          failure: () => setSendOutInvoiceError(true),
        }));
      }}
      tooltipContent={sendOutInvoiceError ? 'Failed, because contact is not connected to Finix.' : 'Send Invoice via Email'}
    />}
    {!isDeleted && !isArchived && <PayvyIconButton
      Icon={BsTrash}
      loading={processing || loading}
      onClick={() => dispatch(removeInvoice({
        invoiceId: original.id,
        success: RefreshInfo,
      },))}
      tooltipContent={removeContent}
    />}
  </>;
}
