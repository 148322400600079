import React from 'react';
import {AiFillWarning} from 'react-icons/ai';

export const RepresentativeIssue = ({
  field_name,
  issues = [],
}) => {
  return <div className={'flex flex-row'}>
    <div className={'flex flex-row items-center'}>
      <AiFillWarning className={'text-red-900'}/>
      <div className={'flex grow'}>
        <div className={'flex'}>
          {!!field_name && <span className={'font-semibold mx-1'}>{field_name}:</span>}
          <span className={''}>{issues.map((field, index) => <p key={index}>{field}</p>)}</span>
        </div>
      </div>
    </div>
  </div>;
};
