import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import Dropzone from 'react-dropzone';
import {FaEye, FaFile} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {createInboxItem} from "../../../slices/newInbox";

import {handleErrors, payvyToast} from '../../../utils/Utility';

export const ModalInboxUpload = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    loading: {posting: processing},
  } = useSelector((state) => state.inbox)
  const [modalOpen, setModalOpen] = useState(false);
  const submitForm = useRef(null);

  return <>
    <PayvyIconButton
      Icon={FaEye}
      buttonText={'Upload invoices'}
      onClick={() => setModalOpen(true)}
    />
    <ModalPayvyBase
      title={`Upload Invoices`}
      isOpen={modalOpen}
      confirmButtonText={`Upload invoices`}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          attachments: [],
        }}
        validationSchema={Yup.object({
          attachments: Yup.mixed()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          if(values.attachments.length !== 0) {
            values.attachments.forEach(function (attachment) {
              const data = new FormData();
              data.append('attachment', attachment, attachment.name);
              dispatch(createInboxItem({
                  body: data,
                  noJson: true,
                  successCallback: () => {
                    setModalOpen(false);
                    payvyToast('Item uploaded successfully.', {appearance: 'success'});
                  },
                  errorCallback: (error = {}) => {
                    const data = error.data || {};
                    if('message' in error) {
                      payvyToast(error.message, {appearance: 'error'});
                    }
                    handleErrors(data, helpers);
                  }
                }
              ));
            });
          }
        }}
      >
        {props => <Form>
          <PayvyLabeledInput
            name={'attachments'}
          >
            <Dropzone onDrop={(acceptedFiles) => {
              props.setFieldValue('attachments', acceptedFiles);
            }}>
              {({
                getInputProps,
              }) => (
                <div className={'bg-red-100 w-full h-60 border border-red-950 rounded-lg'}>
                  <input {...getInputProps()} />
                  <div className={'flex flex-col h-full justify-center items-center'}>
                    <FaFile size={32} className={'m-2'}/>
                    <p>Drag files here or click this area to select invoices</p>
                    <div>
                      {props.values.attachments.length !== 0 ? <p>Selected files:</p> : ''}
                      <ul className={'list-none'}>
                        {props.values.attachments ? props.values.attachments.map(file => (
                          <li key={file.path}>
                            {file.path}
                          </li>
                        )) : ''}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </PayvyLabeledInput>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
          <button ref={submitForm} type="hidden" onClick={props.handleSubmit}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
