import React, {useEffect} from 'react';
import CurrencyFormat from 'react-currency-format';
import {FaExclamationTriangle, FaExternalLinkAlt} from "react-icons/fa";
import Moment from 'react-moment';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {PayvyPageHeader} from "../../comps/elements";
import {PayvyIconButton} from "../../comps/forms";
import {PAYVY_URL} from '../../constants';
import {companiesSelector} from '../../slices/companies';
import {getInvoiceDetails, receivablesSelector} from '../../slices/receivables';
import {build_url} from '../../utils/Utility';
import {InvoiceBlock} from './components/InvoiceBlock';
import {InvoiceLabeledData} from './components/InvoiceLabeledData';
import {InvoiceTransaction} from './components/InvoiceTransaction';
import {TableInvoice} from './components/TableInvoice';

const InvoiceDetailPage = () => {
  const {
    processing,
    loading,
    invoiceDetails,
  } = useSelector(receivablesSelector);
  const {id} = useParams();
  const {company} = useSelector(companiesSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if(!!id) dispatch(getInvoiceDetails({invoiceId: id}));
  }, [dispatch, id]);
  const invoice = invoiceDetails[id];
  if(loading) return (<>Loading...</>);
  if(!invoice) return (<>Invoice not found</>);
  const {disputes = []} = invoice;
  let hasDisputedPayment = false;
  let disputeLink = <></>;
  disputes.map(dispute => {
    const {
      id: disputeId,
      response_state: responseState,
    } = dispute;
    if(responseState.toLowerCase() === 'needs_response') {
      hasDisputedPayment = true;
      disputeLink = <Link to={build_url(PAYVY_URL.DISPUTES.DETAILS, {
        invoiceId: id,
        disputeId,
      })}>Handle</Link>;
    }
    return null;
  });

  return (<>
    <PayvyPageHeader parents={[
      {name: 'Receivables'},
      {
        name: 'Invoices',
        href: PAYVY_URL.INVOICE.LIST
      }
    ]}>Invoice {invoice.invoice_number}</PayvyPageHeader>
    <div className={'bg-neutral-50 px-4 py-2 text-payvyBlue-950 flex flex-col'}>
      <div className={'flex'}>
        <div className={'inline-flex grow flex-col'}>
          <h1 className={'flex text-2xl'}>
            <CurrencyFormat value={invoice.amount / 100} displayType={'text'} thousandSeparator={true}
                            prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>
          </h1>
          <div className={'flex flex-row flex-wrap mb-10'}>
            <InvoiceLabeledData label={'Customer'} data={<Link
              to={build_url(PAYVY_URL.CONTACTS.DETAILS, {id: invoice.contact.id})}
              className={'flex flex-row items-center'}
            >
              {invoice.contact.is_finix_connection_possible ? null : <PayvyIconButton
                Icon={FaExclamationTriangle}
                tooltipContent={'Contact is missing information'}
                tooltipPlace={'right'}
                iconColor={'red-300'}
                mainColor={'none'}
              />}
              <span>{invoice.contact.name}</span>
              <PayvyIconButton
                Icon={FaExternalLinkAlt}
                mainColor={'none'}
                iconColor={'neutral-900'}
                hoverTextColor={'blue-700'}
                iconSize={12}
              />
            </Link>}/>
            <InvoiceLabeledData label={'Payment Method'} data={invoice.customer_payment_method}/>
            <InvoiceLabeledData label={'Due Date'} data={invoice.due_date}/>
            {hasDisputedPayment && <InvoiceLabeledData label={'Disputed Payment'} data={disputeLink}/>}
          </div>
        </div>
        <InvoiceTransaction invoice={invoice} company={company} loading={processing || loading}/>
      </div>
      <TableInvoice invoice={invoice}/>
      <InvoiceBlock blockName={'Payment method'}>
        <div
          className={'bg-neutral-100 mt-2 py-4 px-2 font-bold rounded-xl border border-neutral-300'}>
          {invoice.merchant_payment_method}
        </div>
      </InvoiceBlock>
      <InvoiceBlock blockName={'Events and logs'}>
        <div>
          {invoice.events.map(event => {
            let outcome = 'bg-neutral-150';
            if(event.outcome === 'positive') outcome = 'bg-green-150';
            if(event.outcome === 'negative') outcome = 'bg-red-150';
            return <div className={`flex flex-row p-2 my-2 rounded-2xl ${outcome}`} key={event.id}>
              <div className={'pr-4 font-bold min-w-fit whitespace-nowrap'}>
                <Moment date={event.created_at} format={'LLL'}/>
              </div>
              <div className={'dash-before-content'}>
                {event.event_details}
                {event.event_more_details &&
                  <span className={'pl-6 text-xs flex text-neutral-400 flex-wrap'}>
                                {event.event_more_details}
                            </span>}
              </div>
            </div>
          })}
        </div>
      </InvoiceBlock>
    </div>
  </>);
};

export default InvoiceDetailPage;
