import React, {useCallback} from 'react';
import {usePlaidLink} from 'react-plaid-link';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyIconButton} from "../../../comps/forms";
import {createAccessToken, getLinkToken, getPlaidAccounts, plaidSelector} from '../../../slices/plaid';

export const SettingsBankingAddAccountModal = ({setModalOpen}) => {
  const dispatch = useDispatch();
  const {
    processing,
    loading,
    linkToken
  } = useSelector(plaidSelector);
  const onSuccess = useCallback((token, metadata) => {
    dispatch(createAccessToken({
      public_token: token,
      metadata,
      success: () => {
        setModalOpen(false)
        dispatch(getLinkToken({
          success: () => {
            dispatch(getPlaidAccounts());
          }
        }));
      }
    }));
  }, [dispatch, setModalOpen]);
  const config = {
    token: linkToken,
    onSuccess,
  };
  const {
    open,
    ready
  } = usePlaidLink(config);
  return <div
    className={`service-details flex flex-col border border-neutral-300 rounded-lg p-2 my-2`}
  >
    <div className={'flex flex-row items-center'}>
      <div className={'font-bold text-lg'}>
        Connect with Plaid
      </div>
      <PayvyIconButton
        buttonText={'Connect'}
        onClick={() => open()}
        disabled={!ready || loading || processing}
        wrapperClassName={'ml-auto'}
      />
    </div>
    <div className={'text-sm font-light'}>
      Securely connect your bank account using Plaid's trusted platform
    </div>
  </div>;
};
