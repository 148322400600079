import _ from 'lodash';
import React from 'react';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import Select from 'react-select';
import {PayvyIconButton} from '../../../forms';

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-flex',
    width: 'fit-content',
    margin: '0 .375rem',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 'none',
    border: 'none',
    borderRadius: '0.375rem',
    padding: '0.2rem 0.5rem',
    backgroundColor: '#f6f1f2',
    textAlign: 'center',
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:focus': {
      outline: 'none',
      boxShadow: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '0',
    zIndex: '99999',
  }),
  valueContainer: (provided) => ({
    padding: '2px 5px',
  }),
};
export const ScrollPagination = ({
  sizeOptions = [20, 50, 100],
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageIndex,
  pageSize,
  setPageSize,
  count,
  defaultPageSize,
}) => {
  const defaultPages = [];
  sizeOptions.map(item => defaultPages.push({
    key: item,
    value: item,
    label: item,
  }));
  const pageSizeOptions = _.uniqBy(_.sortBy(defaultPages, ['value']), item => item.key);
  const getRange = (index, size, count) => {
    const start = _.min([index * size + 1, count]);
    const end = _.min([(index + 1) * size, count]);
    return start === end ? `${end}` : `${start}-${end}`;
  };

  return <div className={'flex justify-end items-center'}>
    <Select
      value={{
        key: defaultPageSize,
        text: defaultPageSize,
        value: defaultPageSize,
      }}
      options={pageSizeOptions}
      onChange={option => setPageSize(option.value)}
      styles={customStyles}
      isSearchable={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        SingleValue: () => <span>{getRange(pageIndex, pageSize, count)}</span>,
      }}
    />
    <span className={'m-1 inline-flex'}>of {count}</span>
    <PayvyIconButton
      Icon={FaChevronLeft}
      onClick={previousPage}
      disabled={!canPreviousPage}
      tooltipContent={'Previous Page'}
    />
    <PayvyIconButton
      Icon={FaChevronRight}
      onClick={nextPage}
      disabled={!canNextPage}
      tooltipContent={'Next Page'}
    />
  </div>;
};
