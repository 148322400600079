import React from "react";
import {BasePaymentForm} from "./BasePaymentForm";

export const OutsidePaymentForm = ({
  formProps,
  processing,
  loading,
  bankAccounts,
  amount,
  emailOptions,
  handleCreate,
  checkboxFieldsSet,
  addAndSelectVendor,
  addAndSelectUser,
}) => {
  const filteredBankAccounts = bankAccounts.filter(account =>
    account.type !== "Mercury" && account.type !== "Brex" && account.type !== "Revolut" && account.type !== "Wise" && account.type !== "Airwallex"
  );
  return <BasePaymentForm
    formProps={formProps}
    processing={processing}
    loading={loading}
    bankAccounts={filteredBankAccounts}
    amount={amount}
    emailOptions={emailOptions}
    handleCreate={handleCreate}
    checkboxFieldsSet={checkboxFieldsSet}
    addAndSelectVendor={addAndSelectVendor}
    addAndSelectUser={addAndSelectUser}
  />
}
