import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";
import {captureInvoice, chargeInvoice, getInvoiceDetails, voidInvoice} from '../../../slices/receivables';
import Debugger from '../../../utils/Debug';
import {InvoiceLabeledData} from './InvoiceLabeledData';
import {ModalRefund} from './ModalRefund';

export const InvoiceTransaction = ({
  invoice,
  company,
  loading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    transfer_id: transferId,
    is_authorized: isAuthorized,
    is_archived: isArchived,
    is_deleted: isDeleted,
    is_paid: isPaid,
    has_payment_method: hasPaymentMethod,
    authorizations = [],
    refundable_amount: refundableAmount,
    payment_method_type: paymentMethodType,
  } = invoice;
  const [authorization, setAuthorization] = React.useState({});
  const bankAccount = paymentMethodType === 'BANK_ACCOUNT';
  const {
    id: authorizationId = null,
    is_captured: isCaptured = bankAccount,
    is_void: isVoid = false,
  } = authorization;

  const canCapture = isAuthorized === true && isCaptured === false && isVoid === false && isPaid === false;
  const canVoid = (isCaptured === false && isVoid === false && isAuthorized === true && isPaid === false) || (isDeleted === false && isArchived === false && isAuthorized === false && isPaid === false);
  const canCharge = hasPaymentMethod === true && isAuthorized === false && isCaptured === false && isVoid === false && isDeleted === false && isArchived === false && isPaid === false;
  const canRefund = isAuthorized === true && isCaptured === true && isVoid === false && refundableAmount > 0;
  const canSendReceipt = isAuthorized === true && isCaptured === true && isVoid === false && isDeleted === false && isArchived === false && isPaid === true;

  const buttonSuccess = () => {
    dispatch(getInvoiceDetails({
      invoiceId: invoice.id,
    }));
  };
  const buttonFailure = () => {
    dispatch(getInvoiceDetails({
      invoiceId: invoice.id,
      backgroundSync: true,
    }));
  };
  const clickedChargeInvoice = () => {
    dispatch(chargeInvoice({
      invoiceId: invoice.id,
      success: buttonSuccess,
      failure: buttonFailure,
    }));
  };
  const clickedCaptureInvoice = () => {
    dispatch(captureInvoice({
      invoiceId: invoice.id,
      success: buttonSuccess,
      failure: buttonFailure,
    }));
  };
  const clickedVoidInvoice = () => {
    dispatch(voidInvoice({
      invoiceId: invoice.id,
      success: buttonSuccess,
      failure: buttonFailure,
    }));
  };
  const clickedSendReceipt = () => {
    Debugger.log('send receipt');
  };
  const clickedRefund = () => {
    Debugger.log('refund clicked');
    buttonSuccess();
  };

  React.useEffect(() => {
    if(isAuthorized) {
      setAuthorization(authorizations[0] || {});
    }
  }, [isAuthorized, authorizations]);

  const merchantHasFinixConnection = company?.is_finix_verified || false;
  const invoiceTransactionStyle = 'inline-flex grow flex-col items-end';
  const invoiceTransactionActionsStyle = 'flex flex-row gap-2';
  if(loading) {
    return <div className={invoiceTransactionStyle}>
      <div className={invoiceTransactionActionsStyle}></div>
      <div>Loading...</div>
    </div>;
  }
  if(merchantHasFinixConnection === false) {
    return <div className={invoiceTransactionStyle}>
      <div className={invoiceTransactionActionsStyle}></div>
      <div>
        <PayvyIconButton
          buttonText={'Finix connection not setup'}
          wrapperClassName={'my-1'}
          onClick={() => navigate(PAYVY_URL.SETTINGS.ACCOUNT)}
        />
      </div>
    </div>;
  }
  if(authorization === null || authorization === undefined) {
    return <div className={invoiceTransactionActionsStyle}>
      <div className={invoiceTransactionActionsStyle}></div>
      <div></div>
    </div>;
  }
  return <div className={invoiceTransactionStyle}>
    <div className={invoiceTransactionActionsStyle}>
      {merchantHasFinixConnection && hasPaymentMethod ? <>
        {canRefund ? <ModalRefund
          invoiceID={invoice.id}
          refundableAmount={refundableAmount / 100}
          onClick={clickedRefund}
        /> : null}
        {canSendReceipt ? <PayvyIconButton
          buttonText={'Send Receipt'}
          wrapperClassName={'my-1'}
          onClick={clickedSendReceipt}
        /> : null}
        {canCharge ? <PayvyIconButton
          buttonText={'Charge'}
          wrapperClassName={'my-1'}
          onClick={clickedChargeInvoice}
        /> : null}
        {canCapture ? <PayvyIconButton
          buttonText={'Capture'}
          wrapperClassName={'my-1'}
          onClick={clickedCaptureInvoice}
        /> : null}
        {canVoid ? <PayvyIconButton
          buttonText={'Cancel'}
          borderSize={2}
          borderColor={'slate-900'}
          mainColor={'neutral-0'}
          textColor={'slate-900'}
          hoverTextColor={'slate-700'}
          wrapperClassName={'my-1'}
          onClick={clickedVoidInvoice}
        /> : null}
      </> : null}
      {isPaid ? <span
        className={'mx-4 font-bold text-2xl text-green-300 rounded-lg border-4 border-green-300 px-2 py-1'}>
                PAID
            </span> : null}
    </div>
    <div>
      {hasPaymentMethod === false ?
        <span className={'p-2'}>No payment method provided by contact yet.</span> : null}
      <InvoiceLabeledData label={'ID'} data={isVoid ? null : transferId || authorizationId}/>
    </div>
  </div>;
};
