import {noop} from 'lodash';
import React from 'react';
import {PayvyIconButton} from './PayvyIconButton';
import './PayvyTooltip.scss';

export const PayvyIconButtonGroup = ({
    choices,
    value,
    onChange = noop,
    ...options
}) => {
    return <div className={`inline-flex ${options.wrapperClassName}`}>
        {choices.map(({
            label,
            value: choiceValue,
        }, index) => <PayvyIconButton
            key={index}
            buttonText={label}
            onClick={() => onChange(choiceValue)}
            value={choiceValue}
            buttonMarginX={0}
            mainColor={choiceValue === value ? 'slate-700' : 'neutral-0'}
            borderColor={'slate-700 border-slate-700'}
            borderSize={2}
            iconPaddingY={0.5}
            textColor={choiceValue === value ? 'neutral-0' : 'neutral-900'}
            hoverTextColor={choiceValue === value ? 'neutral-200' : 'neutral-700'}
            roundedLeft={index === 0 ? 'md' : 'none'}
            roundedRight={index === choices.length - 1 ? 'md' : 'none'}
            {...options}
        />)}
    </div>;
};
