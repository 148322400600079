import React from "react";
import {SortableContainer} from "react-sortable-hoc";
import {SortableItem} from "./SortableItem";

export const SortableList = SortableContainer(({
  inboxItem,
  items = [],
  currentPage,
  setCurrentPage,
  setInboxItemPageId,
  canDelete = false,
  canAddBill = false,
}) => {
  return (<ul>
    {items.map((value, index) => (<SortableItem
      key={`item-${index}`}
      inboxItem={inboxItem}
      index={index}
      value={value}
      disabled={true}
      canDelete={canDelete}
      canAddBill={canAddBill}
      className={'disabled'}
      currentIndex={index}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setInboxItemPageId={setInboxItemPageId}
    />))}
  </ul>);
});
