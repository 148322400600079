import {format} from "date-fns";
import React from 'react';
import {formatCurrency} from '../../../utils/Utility';
import {ModuleBlock} from './ModuleBlock';
import {ModuleOverviewColumn} from './ModuleOverviewColumn';

export const ModuleOverview = ({
  overview = {},
}) => {
  const {
    average_invoice: averageInvoice = 0,
    customer_since: customerSince,
    drafts: {
      quantity: draftsQuantity = 0,
      amount: draftsAmount = 0,
    } = {},
    last_payment: lastPayment,
    paid: {
      quantity: paidQuantity = 0,
      amount: paidAmount = 0,
    } = {},
    outstanding: {
      quantity: outstandingQuantity = 0,
      amount: outstandingAmount = 0,
    } = {},
  } = overview;
  const formattedLastPayment = lastPayment ? format(new Date(lastPayment), 'MMM d, y') : '-';
  const formattedCustomerSince = customerSince ? format(new Date(customerSince), 'MMM y') : '-';
  return <ModuleBlock title={'Overview'}>
    <div className={'grid grid-cols-3 justify-evenly h-90%'}>
      <ModuleOverviewColumn title={`Paid (${paidQuantity})`} value={formatCurrency(paidAmount)}/>
      <ModuleOverviewColumn title={`Outstanding (${outstandingQuantity})`}
                            value={formatCurrency(outstandingAmount)}/>
      <ModuleOverviewColumn title={'Last Payment'} value={formattedLastPayment}/>
      <ModuleOverviewColumn title={'Customer Since'} value={formattedCustomerSince}/>
      <ModuleOverviewColumn title={`Drafts (${draftsQuantity})`} value={formatCurrency(draftsAmount)}/>
      <ModuleOverviewColumn title={'Avg Invoice'} value={formatCurrency(averageInvoice)}/>
    </div>
  </ModuleBlock>;
};
