import React from 'react';
import {useSelector} from "react-redux";
import {paymentSystemSelector} from "../../../slices/paymentSystem";

export const WiseUnlinkedAlert = () => {
  const {
    loading,
    processing,
    services = []
  } = useSelector(paymentSystemSelector);
  const {
    active_configuration: {
      enabled,
      authorization_url: authorizationUrl,
      wise_user_id: wiseUserId,
      authentication_info: {refresh_token: refreshToken} = {}
    } = {}
  } = services.find(s => s.internal_name === 'wise') || {};
  if(!enabled || refreshToken || loading || processing || !wiseUserId) return null;
  return <div className={'flex grow bg-orange-200 border-orange-300 border px-4 py-2 my-2'}>
    Please <a href={authorizationUrl} className="text-red-300 underline mx-1">link your Wise
    account</a> to pay bills.
  </div>;
};
