import {Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {FaFileInvoiceDollar} from "react-icons/fa6";
import {SiQuickbooks} from "react-icons/si";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getBill, getUserBills} from "../../../slices/newBill";
import {getLedgerInformation, ledgerLinkBill} from "../../../slices/newIntegration";
import {formatDateForAPItoISO, payvyToast} from "../../../utils/Utility";
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput, PayvySelectComponent} from "../../forms";
import {ModalPayvyBase} from "../../modals";
import {BigCenteredLoadingBlock} from "../BigCenteredLoadingBlock/BigCenteredLoadingBlock";

export const ModalPairToLedgerBill = ({
  item,
  ledger,
  detailStyle
}) => {
  const dispatch = useDispatch();
  const {
    loading: {item: loadingItem},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {loading: {ledger: loading}} = useSelector((state) => state.integration)
  const [modalOpen, setModalOpen] = useState(false);
  const submitForm = React.useRef(null);
  let ledgerIcon = FaFileInvoiceDollar;
  let mainColor;
  let hoverColor;
  let connectionAllowed = true;
  let contactNotPaired = false;
  useEffect(() => {
    if(modalOpen && item.pages === undefined) {
      dispatch(getBill({id: item.id}));
    }
  }, [modalOpen, item.pages, item.id, dispatch]);
  const errorDetails = [];
  if(ledger?.enabled === false) return null;
  if(ledger?.codename === 'quickbooks') {
    if(item.quickbooks_invoice_id) return null;
    if(!item?.contact?.quickbooks_id) {
      contactNotPaired = true;
      connectionAllowed = false;
    }
    if(item?.pages && Array.isArray(item.pages)) {
      const pageIssues = item.pages.map((page) => {
                               const issues = [];
                               if(!item.due_date) issues.push('missing due date');
                               if(!item.created) issues.push('missing date');
                               if(!page.invoice_number) issues.push('missing invoice number');
                               if(!page.quickbooks_account) issues.push('missing quickbooks account');
                               // Ignore removed pages and use invoice_number if it exists for identification
                               if(page.removed) return null;
                               if(page.allow_ledger_sync === false) return null;
                               return {
                                 pageIdentifier: page.invoice_number || `Page ${item.pages.indexOf(page) + 1}`,
                                 issues,
                               };
                             })
                             .filter(page => page !== null && page.issues.length > 0);
      if(pageIssues.length > 0) {
        pageIssues.forEach(({
          pageIdentifier,
          issues
        }) => {
          errorDetails.push({
            pageIdentifier,
            issues,
          });
        });
        connectionAllowed = false;
      }
    }
    ledgerIcon = SiQuickbooks
    mainColor = 'green-300 bg-green-300';
    hoverColor = 'green-200 hover:bg-green-200';
  }
  const fromDate = fromDateStr ? new Date(fromDateStr) : '';
  const toDate = toDateStr ? new Date(toDateStr) : '';
  const queryRange = [fromDate, toDate];
  return <>
    <PayvyIconButton
      Icon={ledgerIcon}
      onClick={() => setModalOpen(true)}
      tooltipContent={`Pair with ${ledger?.name}`}
      tooltipPlace={detailStyle ? 'bottom' : undefined}
      mainColor={detailStyle ? 'none' : mainColor}
      hoverColor={detailStyle ? 'green-200' : hoverColor}
      iconColor={detailStyle ? 'green-300' : undefined}
      iconSize={detailStyle ? 22 : undefined}
      hoverTextColor={detailStyle ? 'neutral-700' : undefined}
    />
    <ModalPayvyBase
      title={`Pair with ${ledger?.name}`}
      isOpen={modalOpen}
      confirmButtonText={'Pair'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={loading}
      confirmButtonDisabled={!connectionAllowed}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={loading}
    >
      {loadingItem ? <BigCenteredLoadingBlock text={'Loading bill information'}/> : <>
        {connectionAllowed && <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            ledgerId: null
          }}
          validationSchema={Yup.object({
            ledgerId: Yup.string()
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          })}
          onSubmit={(values, helpers) => {
            dispatch(ledgerLinkBill({
              body: {
                ledger: ledger.codename,
                ledger_id: values.ledgerId,
                payvy_id: item.id
              },
              successCallback: () => {
                dispatch(getLedgerInformation({forceUpdate: true}))
                let fromDate = '';
                let toDate = '';
                if(queryRange?.length === 2) {
                  fromDate = formatDateForAPItoISO(queryRange[0], 'start')
                  toDate = formatDateForAPItoISO(queryRange[1], 'end')
                }
                dispatch(getBill({
                  id: item.id,
                  forceUpdate: true
                }))
                dispatch(getUserBills({
                  amount: filterAmountStr,
                  contact: filterContact,
                  from: fromDate,
                  to: toDate,
                  order_by: orderBy,
                  page: currentPage,
                  page_size: pageSize,
                  status: status === '' ? 'all' : status,
                  q: searchQuery,
                  forceUpdate: true
                }));
              },
              errorCallback: () => {
                payvyToast('Error linking bill to ledger.', {appearance: 'error'})
              }
            }))
          }}
        >
          {props => <Form>
            <p>Pair with {ledger?.name}</p>
            <PayvyLabeledInput
              alwaysLabel={true}
              label={`${ledger.name} Bill`}
              component={PayvySelectComponent}
              placeholder={`bill from ${ledger.name}`}
              name={'ledgerId'}
              options={ledger?.bills.filter(bill => bill.payvy_id === null)
                             .map(bill => ({
                               value: bill.ledger_id,
                               label: bill.name
                             }))}
            />
            <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
            <GlobalFormError errors={props.errors['nonFieldErrors']}/>
          </Form>}
        </Formik>}
        {!connectionAllowed && <>
          {contactNotPaired && <p className={'text-red-300'}>Bill contact is not paired with {ledger?.name}.</p>}
          <p className="text-red-300 mt-4 font-medium">The following issues must be resolved before pairing:</p>
          <ul className="text-red-300 ml-5 list-disc mt-2">
            {errorDetails &&
              errorDetails.map(({
                pageIdentifier,
                issues
              }) => (
                <li key={pageIdentifier} className="mb-2">
                  <span className="font-bold">{pageIdentifier}:</span>
                  <ul className="ml-5 list-decimal">
                    {issues.map((issue, index) => (
                      <li key={index}>{issue}</li>
                    ))}
                  </ul>
                </li>
              ))}
          </ul>
        </>}
      </>}
    </ModalPayvyBase>
  </>;
};
