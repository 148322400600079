import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {FaComment, FaEnvelope, FaPhone, FaPhoneVolume} from 'react-icons/fa';
import {IoSend} from 'react-icons/io5';
import {Mention, MentionsInput} from 'react-mentions';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {PayvyIconButton} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import {companiesSelector} from '../../../slices/companies';
import {getBillHistory, sendBillComment} from "../../../slices/newBill";
import {build_error_message, handleErrors, payvyToast} from '../../../utils/Utility';
import './HistoryComment.scss';

export const HistoryComment = ({bill}) => {
  const dispatch = useDispatch();
  const {
    company: {
      members,
    },
  } = useSelector(companiesSelector);
  const {loading: {posting: sendCommentLoading}} = useSelector((state) => state.bill);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const [smsDisabled, setSMSDisabled] = useState(false);
  const [callDisabled, setCallDisabled] = useState(false);
  const [currentLength, setCurrentLength] = useState(0);
  const can_call = !sendCommentLoading && !callDisabled && bill && bill.contact ? !!bill.contact.phone : false;
  const can_sms = !sendCommentLoading && !smsDisabled && bill && bill.contact ? !!bill.contact.phone : false;
  const can_email = !sendCommentLoading && !emailDisabled && bill && bill.contact ? bill.contact.has_email : false;
  const charLimit = 640;
  const formik = useFormik({
    initialValues: {
      message: '',
      displayedMessage: '',
      email: false,
      sms: false,
      call: false,
    },
    validationSchema: Yup.object({
      displayedMessage: Yup.string()
                           .max(charLimit, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: charLimit})),
      message: Yup.string()
                  .when('call', {
                    is: true,
                    otherwise: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                  }),
      email: Yup.boolean()
                .oneOf([false, can_email])
                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      sms: Yup.boolean()
              .oneOf([false, can_sms])
              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      call: Yup.boolean()
               .oneOf([false, can_call])
               .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
    }),
    onSubmit: values => {
      dispatch(sendBillComment({
        id: bill.id,
        body: {
          comment: values.message,
          displayed_message: values.displayedMessage,
          sms: values.sms,
          email: values.email,
          call: values.call,
        },
        successCallback: () => {
          formik.resetForm();
          setCurrentLength(0);
          dispatch(getBillHistory({
            id: bill.id,
            page: 1,
            page_size: 10,
            forceUpdate: true
          }));
        },
        errorCallback: (error) => {
          const data = error.data || {};
          if('message' in error) {
            payvyToast(error.message, {appearance: 'error'});
          }
          handleErrors(data, formik);
        }
      }))
    },
  });
  useEffect(() => {
    formik.values.sms || formik.values.email ? setCallDisabled(true) : setCallDisabled(false);
  }, [formik.values.sms, formik.values.email]);
  useEffect(() => {
    if(formik.values.call) {
      setEmailDisabled(true);
      setSMSDisabled(true);
    } else {
      setEmailDisabled(false);
      setSMSDisabled(false);
    }
  }, [formik.values.call]);
  const mentionList = () => {
    const users = [];
    if(members) {
      members.map((member) => {
        users.push({
          id: member.id,
          display: `${member.first_name} ${member.last_name}`,
        });
        return true;
      });
    }
    return users;
  };
  const mentionUsers = mentionList();
  const handleData = (search) => {
    return mentionUsers.filter(user => user.display.includes(search));
  };
  const getPlaceholder = () => {
    if(formik.values.sms && formik.values.email) {
      return 'Email and SMS vendor about this invoice';
    } else if(formik.values.sms) {
      return 'Text vendor about this invoice';
    } else if(formik.values.email) {
      return 'Email vendor about this invoice';
    }
    return 'Comment on this invoice';
  };
  return <div
    className={`payvy-comment-block ${formik.values.sms ? 'sms' : ''} ${formik.values.email ? 'email' : ''} ${formik.values.call ? 'call' : ''} ${!!(formik.touched.displayedMessage && formik.errors.displayedMessage) ? 'error' : ''}`}>
    {!formik.values.call ?
      <MentionsInput disabled={formik.values.call || sendCommentLoading} placeholder={getPlaceholder()}
                     className={'payvy-comment-input'} onKeyDown={(e) => {
        if(e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          formik.handleSubmit();
        }
      }} value={formik.values.message} onChange={(e, value, valueDisplayed) => {
        setCurrentLength(valueDisplayed.length);
        formik.setFieldValue('message', value);
        formik.setFieldValue('displayedMessage', valueDisplayed);
      }}>
        <Mention trigger="@" data={handleData}/>
      </MentionsInput> : <div className={'payvy-comment-input'}>
        <div className={'payvy-comment-input__control'}>
          <textarea className={'payvy-comment-input__input'} disabled={true}
                    placeholder={bill && bill.contact && bill.contact.phone ? bill.contact.phone : 'Unknown phone number'}/>
        </div>
      </div>}
    <div className={'tabs'}>
      <div className={`character-count ${charLimit - currentLength < 0 ? 'error' : ''}`}>
        {charLimit - currentLength} char left
      </div>
      <div className={'float-right'}>
        <PayvyIconButton
          Icon={FaEnvelope}
          onClick={() => {
            formik.setFieldValue('email', !formik.values.email)
          }}
          mainColor={formik.values.email ? formik.values.sms ? 'neutral-900' : 'red-300' : 'none'}
          hoverColor={formik.values.sms ? 'neutral-900' : 'red-300'}
          tooltipContent={'Email'}
          iconColor={formik.values.email && !formik.values.sms ? 'red-900' : 'neutral-700'}
          hoverTextColor={'red-900'}
        />
        <PayvyIconButton
          Icon={FaComment}
          disabled={!can_sms}
          onClick={() => {
            formik.setFieldValue('sms', !formik.values.sms)
          }}
          mainColor={formik.values.sms ? formik.values.email ? 'neutral-900' : 'purple-300' : 'none'}
          hoverColor={formik.values.email ? 'neutral-900' : 'purple-300'}
          iconColor={formik.values.sms && !formik.values.email ? 'purple-500' : 'neutral-700'}
          tooltipContent={'SMS'}
          hoverTextColor={'purple-500'}
        />
        <PayvyIconButton
          Icon={FaPhone}
          disabled={!can_call}
          onClick={() => {
            formik.setFieldValue('call', !formik.values.call)
          }}
          mainColor={formik.values.call ? 'orange-300' : 'none'}
          hoverColor={'orange-300'}
          iconColor={formik.values.call ? 'orange-500' : 'neutral-700'}
          tooltipContent={'Call'}
          hoverTextColor={'orange-900'}
        />
        <PayvyIconButton
          Icon={formik.values.call ? FaPhoneVolume : IoSend}
          loading={sendCommentLoading}
          onClick={formik.handleSubmit}
          mainColor={'none'}
          iconColor={'neutral-700'}
          tooltipContent={formik.values.call ? 'Start calling' : 'Send'}
          hoverTextColor={'neutral-900'}
        />
      </div>
      {!!(formik.touched.message && formik.errors.message) ?
        <div className={'error-message'}>{formik.errors.message}</div> : null}
      {!!(formik.touched.displayedMessage && formik.errors.displayedMessage) ?
        <div className={'error-message'}>{formik.errors.displayedMessage}</div> : null}
      {!!(formik.touched.email && formik.errors.email) ?
        <div className={'error-message'}>{formik.errors.email}</div> : null}
      {!!(formik.touched.sms && formik.errors.sms) ? <div className={'error-message'}>{formik.errors.sms}</div> : null}
      {!!(formik.touched.call && formik.errors.call) ?
        <div className={'error-message'}>{formik.errors.call}</div> : null}
      {!!formik.errors.nonFieldErrors ? <div className={'error-message'}>{formik.errors.nonFieldErrors}</div> : null}
    </div>
  </div>;
};
