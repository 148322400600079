import React from 'react';
import {useSelector} from 'react-redux';
import {PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {companiesSelector} from '../../slices/companies';
import {LinkedPaymentServiceAccounts} from "./components/LinkedPaymentServiceAccounts";
import {SyncedBankAccounts} from "./components/SyncedBankAccounts";

const SettingsBankingPage = () => {
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Banking</PayvyPageHeader>
    <SettingsMenu active="banking"/>
    <div className={'w-full px-2 pb-2 bg-neutral-50 flex flex-col gap-2'}>
      <LinkedPaymentServiceAccounts companyId={companyId}/>
      <SyncedBankAccounts companyId={companyId}/>
    </div>
  </>;
};

export default SettingsBankingPage;
