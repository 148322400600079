import React from 'react';

export const InvoiceBlock = ({
  children,
  blockName,
}) => {
  return <div className={'my-2 min-h-48'}>
    <h1 className={'pt-2 pb-1 border-b border-neutral-200'}>{blockName}</h1>
    <div>
      {children}
    </div>
  </div>;
};
