import {Form, Formik} from 'formik';
import React, {useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES, LOGIN_EMAIL, LOGIN_PASSWORD} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {authSelector, userHasTFA} from '../../../slices/auth';
import {resetBillToInitialState} from "../../../slices/newBill";
import {resetBoxToInitialState} from "../../../slices/newBox";
import {resetContactToInitialState} from "../../../slices/newContact";
import {resetDropboxToInitialState} from "../../../slices/newDropbox";
import {resetInboxToInitialState} from "../../../slices/newInbox";
import {resetIntegrationToInitialState} from "../../../slices/newIntegration";
import {resetQuickbooksToInitialState} from "../../../slices/newQuickbooks";
import {resetTransactionToInitialState} from "../../../slices/newTransaction";
import {handleErrors} from '../../../utils/Utility';

export const FormLogin = ({setActiveLockout}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    loginLoading: loading,
    method,
  } = useSelector(authSelector);
  const codeEntryState = !!method;

  return <Formik
    enableReinitialize={false}
    innerRef={formikRef}
    initialValues={{
      email: '' || LOGIN_EMAIL,
      password: '' || LOGIN_PASSWORD,
      code: '',
    }}
    validationSchema={Yup.object({
      email: Yup.string()
                .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL)
                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      password: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      code: codeEntryState ? Yup.string()
                                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED) : Yup.string(),
    })}
    onSubmit={(values, helpers) => {
      dispatch(resetInboxToInitialState());
      dispatch(resetBillToInitialState());
      dispatch(resetContactToInitialState());
      dispatch(resetBoxToInitialState());
      dispatch(resetDropboxToInitialState());
      dispatch(resetIntegrationToInitialState());
      dispatch(resetQuickbooksToInitialState());
      dispatch(resetTransactionToInitialState());
      dispatch(userHasTFA({
        email: values.email,
        password: values.password,
        code: values.code,
        success: () => {
        },
        failure: (error) => {
          const {too_many_login_attempts = false} = error || {};
          if(too_many_login_attempts) {
            localStorage.setItem('lockoutEnds', too_many_login_attempts || null);
            setActiveLockout(true);
          }
          handleErrors(error, helpers);
        },
      }));
    }}
  >
    {props => <Form onSubmit={props.handleSubmit} onKeyDown={(e) => {
      if(e.key === 'Enter') props.handleSubmit();
    }}>
      <GlobalFormError errors={props.errors['nonFieldErrors']}/>
      <div className={`${codeEntryState ? '' : 'hidden'}`}>
        <PayvyLabeledInput
          label={'Verification code'}
          name={'code'}
          as={PayvyInput}
          type={'text'}
        />
      </div>
      <div className={`${codeEntryState ? 'hidden' : ''}`}>
        <PayvyLabeledInput
          label={'Email address'}
          name={'email'}
          as={PayvyInput}
          type={'email'}
        />
        <PayvyLabeledInput
          label={'Password'}
          name={'password'}
          as={PayvyInput}
          type={'password'}
        />
      </div>
      <PayvyIconButton
        loading={loading}
        disabled={loading}
        buttonText={'Login'}
        onClick={props.handleSubmit}
        wrapperClassName={'mt-6 w-full px-20'}
        fullWidth={true}
        shortcutKey={'enter'}
      />
    </Form>}
  </Formik>;
};
