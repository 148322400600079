import React, {useEffect} from 'react';
import {AiOutlineDownload,} from 'react-icons/ai';
import {FaRegClock, FaRegStar, FaStar} from 'react-icons/fa';
import {IoEyeOutline} from "react-icons/io5";
import Moment from 'react-moment';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {OCRInformation} from "../../../comps/elements/OCRInformation/OCRInformation";
import {PayvyIconButton} from '../../../comps/forms';
import {ModalInboxRemove} from '../../../comps/modals';
import {PAYVY_URL} from '../../../constants';
import {getInboxItemProcessingInformation, toggleInboxItemStar} from "../../../slices/newInbox";
import {build_url} from '../../../utils/Utility';
import {ItemImage} from "./ItemImage";

function parseNameEmail(input) {
  const emailRegex = /<([^>]+)>|([\w.-]+@[\w.-]+\.\w+)/;
  const match = input.match(emailRegex);
  let email = match ? (match[1] || match[2]) : null;
  let name = input.replace(emailRegex, "")
                  .trim();
  if(name === email || name === "") name = null;
  return {
    name,
    email
  };
}

const ItemName = ({
  uploadedBy,
  mailContent
}) => {
  let name = 'Unknown'
  let subName = ''
  if(uploadedBy) name = uploadedBy.first_name && uploadedBy.last_name ? `${uploadedBy.first_name} ${uploadedBy.last_name}` : uploadedBy.username;
  if(mailContent) {
    const parsed = parseNameEmail(mailContent.sender);
    if(parsed.name) name = parsed.name;
    subName = parsed.email || '';
  }
  const truncatedName = name?.length > 30 ? name.substring(0, 30) + '...' : name;
  return <div className={'px-2 pt-2 flex flex-col text-slate-900 text-sm'}>
    <div className={'items-center font-bold truncate'} title={name}>{truncatedName}</div>
    <div
      className={'items-center text-xs font-light text-slate-500'}>{subName ? subName : <>&nbsp;</>}</div>
  </div>
}

const ItemVendor = ({contact}) => {
  const name = contact?.name || 'Vendor not assigned';
  return <div className={'px-2 py-1 flex flex-row text-xs font-light select-none'}>
    <div
      className={`flex rounded-lg px-1 py-0.5 items-center font-normal ${contact?.name ? 'text-green-900 bg-green-50' : 'text-slate-700 bg-slate-100'}`}>
      {name}
    </div>
  </div>
}
export const Item = ({
  item,
  canSee = false,
  canDelete = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tries, _setTries] = React.useState(1);
  const {
    loading: {
      posting: processing,
      documentProcessing = false,
    },
  } = useSelector((state) => state.inbox)
  const {
    id,
    processed = null,
    ocr_processed: ocrProcessed = null,
    ocr_progress: ocrProgress = 0,
    deleted = false,
    original_attachment: originalAttachment = {},
    first_page: firstPage,
    mail_content: mailContent,
    uploaded_by: uploadedBy,
    received_on: receivedOn,
    number_of_pages: numberOfPages = 0,
    starred,
  } = item;
  useEffect(() => {
    const timer = setInterval(() => {
      if(processed === null || ocrProcessed === null) {
        if(!documentProcessing) {
          dispatch(getInboxItemProcessingInformation({
            id: id,
            forceUpdate: true
          }))
          .then(() => _setTries(tries + 1));
        }
      }
    }, 5 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [processed, ocrProcessed, documentProcessing, id, dispatch, tries]);
  return <div className={'flex flex-col rounded-xl bg-neutral-0 border-neutral-100 border'}>
    <div
      className={`flex flex-col relative ${!canSee || !processed ? '' : 'cursor-pointer'}`}
      onClick={() => {
        if(!canSee || !processed) return;
        navigate(build_url(PAYVY_URL.INBOX.DETAILS, {'id': item.id}))
      }}
    >
      {starred && <div className={'absolute top-2 right-2'}>
        <FaStar size={20} color="orange"/>
      </div>}
      <ItemImage processed={processed} firstPage={firstPage}/>
      <div className={"absolute bottom-2 right-2 text-sm font-light select-none"}>
        <div className={"px-2 py-1 rounded-lg bg-slate-800 text-slate-100"}>{numberOfPages} pages</div>
      </div>
    </div>
    <ItemName uploadedBy={uploadedBy} mailContent={mailContent}/>
    <ItemVendor contact={item?.contact}/>
    <div className={'flex text-sm flex-row px-2 pb-2 w-full self-center justify-between text-slate-500'}>
      <div className={'flex'}>
        <div className={'flex pr-0.5 h-full items-center font-light'}><FaRegClock size={12}/></div>
        <div className={'flex pl-0.5 text-xs items-center font-light'}><Moment date={receivedOn} format={'ll'}/></div>
      </div>
      <OCRInformation ocrProcessed={ocrProcessed} ocrProgress={ocrProgress}/>
    </div>
    <div className={'flex m-1 w-full self-center border-t border-slate-200'}>
      <div className={'flex w-full'}>
        {canSee && processed ? <PayvyIconButton
          tooltipContent={'View Details'}
          Icon={IoEyeOutline}
          mainColor={'none'}
          iconColor={'slate-400 text-slate-400'}
          hoverTextColor={'slate-700 hover:text-slate-700'}
          iconSize={22}
          buttonMarginX={1}
          iconPaddingX={2}
          iconPaddingY={2}
          onClick={() => navigate(build_url(PAYVY_URL.INBOX.DETAILS, {'id': item.id}))}
        /> : null}
      </div>
      <div className={'flex'}>
        <PayvyIconButton
          tooltipContent={starred ? "Click to remove from favorites" : "Click to add to favorites"}
          Icon={starred ? FaStar : FaRegStar}
          mainColor={'none'}
          iconColor={'slate-400 text-slate-400'}
          hoverTextColor={'slate-700 hover:text-slate-700'}
          iconSize={22}
          buttonMarginX={1}
          iconPaddingX={2}
          iconPaddingY={2}
          onClick={() => {
            if(processing) return;
            dispatch(toggleInboxItemStar({
              id: item.id,
              body: {
                starred: !starred
              }
            }))
          }}
        />
        {originalAttachment && <PayvyIconButton
          tooltipContent={'Download Original Attachment'}
          Icon={AiOutlineDownload}
          mainColor={'none'}
          iconColor={'slate-400 text-slate-400'}
          hoverTextColor={'slate-700 hover:text-slate-700'}
          iconSize={22}
          buttonMarginX={1}
          iconPaddingX={2}
          iconPaddingY={2}
          onClick={() => window.open(originalAttachment.url, '_blank')}
        />}
        {canDelete && <ModalInboxRemove item={item} detailStyle={true} cardPadding={true} deleted={deleted}/>}
      </div>
    </div>
  </div>;
};
