import {useState} from 'react';
import {BiCog, BiExpandVertical, BiHelpCircle} from 'react-icons/bi';
import {FaUserCog} from "react-icons/fa";
import {FiLogOut} from 'react-icons/fi';
import {HiArrowPath} from "react-icons/hi2";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../../constants";
import {logoutUser} from "../../../../slices/auth";
import {resetBillToInitialState} from "../../../../slices/newBill";
import {resetBoxToInitialState} from "../../../../slices/newBox";
import {resetContactToInitialState} from "../../../../slices/newContact";
import {resetDropboxToInitialState} from "../../../../slices/newDropbox";
import {resetInboxToInitialState} from "../../../../slices/newInbox";
import {resetIntegrationToInitialState} from "../../../../slices/newIntegration";
import {resetQuickbooksToInitialState} from "../../../../slices/newQuickbooks";
import {userSelector} from "../../../../slices/user";
import {DarkFakeLetterAvatar} from "./DarkFakeLetterAvatar";


export const UserDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const {user} = useSelector(userSelector);
  const isSuperuser = !!user?.is_superuser
  const userName = user?.username ? `@${user?.username}` : '@unknown';
  const userHandle = user?.email || 'unknown@payvy.com';

  return (
    <div className={'w-full flex flex-col grow border-t-slate-300 border-t py-4'}>
      <div className={'mt-auto'}>
        <div
          className={'my-2 mx-4 py-0.5 px-1 gap-2 rounded-lg cursor-pointer'}
          onClick={() => setOpen(!open)}
        >
          <div className={'flex flex-row'}>
            <div className={'flex flex-col justify-center'}>
              <DarkFakeLetterAvatar letter={userName[1]}/>
            </div>
            <div className={'flex flex-col grow pl-2'}>
              <div className={'text-sm font-normal'}>{userName}</div>
              <div className={'text-xs text-slate-700 font-light'}>{userHandle}</div>
            </div>
            <div className={'flex flex-col items-center justify-center'}>
              <BiExpandVertical size={'16'}/>
            </div>
          </div>
        </div>
        {/* Dropdown Menu */}
        <div className={`absolute bottom-20 left-0 w-full z-10 ${open ? 'block' : 'hidden'}`}>
          <div className={'flex flex-col bg-neutral-0 rounded border border-slate-200 mx-3 p-2 shadow-xl font-light'}>
            <div
              className={'flex flex-row p-2 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none items-center'}
              onClick={() => window.open(PAYVY_URL.HELP_SITE, "_blank")}
            >
              <BiHelpCircle className={'mr-2'} size={16}/>
              <span>Help</span>
            </div>
            <div
              className={'flex flex-row p-2 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none items-center'}
              onClick={() => navigate(PAYVY_URL.SETTINGS.ACCOUNT)}
            >
              <BiCog className={'mr-2'} size={16}/>
              <span>Settings</span>
            </div>
            {isSuperuser && <div
              className={'flex flex-row p-2 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none items-center'}
              onClick={() => window.open(PAYVY_URL.ADMIN_SITE, "_blank")}
            >
              <FaUserCog className={'mr-2'} size={16}/>
              <span>Admin Site</span>
            </div>}
            <div
              className={'flex flex-row p-2 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none items-center'}
              onClick={() => {
                dispatch(resetInboxToInitialState());
                dispatch(resetBillToInitialState());
                dispatch(resetContactToInitialState());
                dispatch(resetBoxToInitialState());
                dispatch(resetDropboxToInitialState());
                dispatch(resetIntegrationToInitialState());
                dispatch(resetQuickbooksToInitialState());
                navigate(PAYVY_URL.DASHBOARD)
                window.location.reload();
              }}
            >
              <HiArrowPath className={'mr-2'} size={16}/>
              <span>Reset Cache</span>
            </div>
            <div
              className={'flex flex-row p-2 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none items-center'}
              onClick={() => dispatch(logoutUser())}
            >
              <FiLogOut className={'mr-2'} size={16}/>
              <span>Log out</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
