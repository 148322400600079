import {Form, Formik} from "formik";
import React from "react";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES} from "../../../constants";
import {getPaymentServiceInformation, saveServiceConfiguration} from "../../../slices/paymentSystem";

export const DefaultPaymentServiceForm = ({
  service,
  loading,
  formikRef
}) => {
  const dispatch = useDispatch();
  const {
    id,
    active_configuration: activeConfiguration,
  } = service;
  const {
    api_key: apiKey = ''
  } = activeConfiguration || {};
  return <Formik
    innerRef={formikRef}
    initialValues={{
      apiKey: apiKey,
    }}
    validationSchema={Yup.object({
      apiKey: Yup.string()
                 .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
    })}
    onSubmit={values => {
      if(values.apiKey === apiKey) return;
      dispatch(saveServiceConfiguration({
        serviceId: id,
        configuration: {
          api_key: values.apiKey
        },
        success: () => {
          dispatch(getPaymentServiceInformation({}))
        }
      }))
    }}
  >
    {props => <Form className={'flex flex-col'}>
      <div className={'flex flex-col md:flex-row gap-2 px-2'}>
        <div className={'flex-inline w-full'}>
          <PayvyLabeledInput
            label={'API Key'}
            name={'apiKey'}
            placeholder={'API Key'}
            as={PayvyInput}
          />
        </div>
      </div>
      <GlobalFormError errors={props.errors['nonFieldErrors']}/>
      <PayvyIconButton
        buttonText={'Save Service Configuration'}
        onClick={props.handleSubmit}
        fullWidth={true}
        loading={loading}
        disabled={loading || (!props.dirty)}
        wrapperClassName={'mt-4'}
      />
    </Form>}
  </Formik>
}
