import {useFormikContext} from "formik";
import {noop} from "lodash";
import React from 'react';
import DatePicker from 'react-date-picker';
import './PayvyDatePicker.scss';

export const PayvyDatePicker = ({
  value,
  onChange = noop,
  className = '',
  ...props
}) => {
  const {
    errors,
    touched,
  } = useFormikContext();
  const uniqueID = Math.random()
                       .toString(36)
                       .substr(2, 9);
  const name = props.name || `date-${uniqueID}`;
  const error = errors[name];
  const touchedError = touched[name];
  if(touchedError && error) className += ' border-2 border-red-900 rounded-md'; else className += ' border border-neutral-500';
  return <DatePicker
    onChange={onChange}
    value={value ? new Date(value) : undefined}
    className={`${props.disabled ? `disabled` : `rounded-md bg-neutral-50`}${className ? ` ${className}` : ''}`}
    dayPlaceholder='dd'
    monthPlaceholder='mm'
    yearPlaceholder='yyyy'
    name={name}
    autocomplete="off"
    autocorrect="off"
    {...props}
  />;
};
