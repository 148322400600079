import {noop} from 'lodash';
import React, {useState} from 'react';
import {BsFillTrashFill} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {kycSelector, removeBankAccount} from '../../../slices/kyc';

export const ModalSyncedBankAccountRemove = ({
  bankAccount,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const {processing} = useSelector(kycSelector);
  const {
    name,
    id,
  } = bankAccount;
  const confirmRemoveBankAccount = () => {
    dispatch(removeBankAccount({
      id,
      success: () => {
        refreshInfo();
        setModalOpen(false);
        addToast('Bank account removed successfully.', {appearance: 'success'});
      },
      failure: (error) => {
        addToast(error.toString() || 'Bank account removal failed.', {appearance: 'error'});
      }
    }));
  };
  return <>
    <PayvyIconButton
      Icon={BsFillTrashFill}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Bank Account'}
      iconColor={'neutral-300'}
      hoverTextColor={'neutral-300'}
      hoverColor={'neutral-0'}
      borderColor={'red-300'}
      borderSize={1}
    />
    <ModalPayvyBase
      title={`Removing "${name}" bank account`}
      isOpen={modalOpen}
      confirmButtonText={'Yes,  remove bank account'}
      confirmButtonAction={confirmRemoveBankAccount}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to remove <b>"{name}"</b> bank account?</p>
    </ModalPayvyBase>
  </>;
};
