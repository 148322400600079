import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {noop} from 'lodash';
import React, {useState} from 'react';
import {FaPlus} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalFormError, PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {addPaymentMethod, userSelector} from '../../../slices/user';

export const ModalAddPaymentMethod = ({
  refreshList,
}) => {
  refreshList = refreshList || noop;
  const stripe = useStripe();
  const dispatch = useDispatch();
  const {
    user: {
      address,
      first_name: firstName,
      last_name: lastName,
      email,
    },
  } = useSelector(userSelector);
  const {
    street,
    city,
    state,
    zip,
  } = address || {};
  const elements = useElements();
  const [modalOpen, setModalOpen] = useState(false);
  const [stripeError, setStripeError] = useState({});
  const [processing, setProcessing] = useState(false);
  const handleSubmit = async(event) => {
    event.preventDefault();
    setStripeError({});
    setProcessing(true);
    if(!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    const {
      error,
      paymentMethod,
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          city: city,
          country: 'US',
          line1: street,
          postal_code: zip,
          state: state,
        },
        name: `${firstName} ${lastName}`,
        email: email,
      },
    });
    if(error) {
      setStripeError(error);
      setProcessing(false);
    } else {
      dispatch(addPaymentMethod({
        payment_method_id: paymentMethod.id,
        success: () => {
          setModalOpen(false);
          refreshList(new Date());
          setProcessing(false);
        },
        failure: (error) => {
          setProcessing(false);
          setStripeError({message: error});
        }
      }));
    }
  };
  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#fce883'},
        '::placeholder': {color: '#888888'},
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };
  return <>
    <PayvyIconButton
      Icon={FaPlus}
      buttonText={'Add Card'}
      onClick={() => setModalOpen(true)}
      wrapperClassName={'grow justify-end'}
    />
    <ModalPayvyBase
      title={`Add new Payment Method`}
      isOpen={modalOpen}
      confirmButtonText={'Add Payment Method'}
      confirmButtonAction={handleSubmit}
      confirmButtonLoading={!stripe || !elements || processing}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={!stripe || !elements || processing}
    >
      <GlobalFormError errors={stripeError?.message}/>
      <form>
        <CardElement options={CARD_OPTIONS}/>
      </form>
    </ModalPayvyBase>
  </>;
};
