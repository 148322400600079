import React from "react";
import {SiQuickbooks} from "react-icons/si";
import {NumericFormat} from "react-number-format";
import {useDispatch, useSelector} from "react-redux";
import {SortableElement} from "react-sortable-hoc";
import {Tooltip} from "react-tooltip";
import {companiesSelector} from "../../../slices/companies";
import {getBill} from "../../../slices/newBill";
import {ModalMovePage} from "./ModalMovePage";
import {ModalRemovePage} from "./ModalRemovePage";
import {SortableHandle} from "./SortableHandle";

export const SortableItem = SortableElement(({
  className,
  bill,
  value,
  currentPage,
  currentIndex,
  setCurrentPage,
  canUpdate = false,
  canDelete = false,
}) => {
  const dispatch = useDispatch();
  const {
    loading: {item: loadingInboxItem},
  } = useSelector((state) => state.inbox);
  const {company: {has_qb_integration: hasQBIntegration}} = useSelector(companiesSelector);
  const errors = []
  if(hasQBIntegration) {
    if(!value.quickbooks_account) {
      errors.push("QuickBooks account is missing. Please provide one.");
    }
  }
  return <li
    className={`flex flex-row items-center px-2 py-1 w-full ${className} ${currentPage ? 'bg-slate-100 font-bold border border-slate-200' : 'border border-neutral-0'}`}>
    {canUpdate ? <SortableHandle/> : null}
    {canUpdate ? <ModalMovePage
      bill={bill}
      page={value}
      success={() => dispatch(getBill({id: bill.id}))}/> : null}
    {canDelete ? <ModalRemovePage
      billId={bill.id}
      page={value}
      success={() => dispatch(getBill({id: bill.id}))}
      noExtraStyles={true}
    /> : null}
    <div className={'flex grow'} onClick={() => setCurrentPage(currentIndex)}>
      <div>{value.invoice_number ? value.invoice_number : `Page ${value.page_number}`}</div>
      {errors.length >= 1 && <div className={`ml-2 text-red-400 flex items-center missing-data-tip-${currentIndex}`}>
        <SiQuickbooks/>
        <Tooltip anchorSelect={`.missing-data-tip-${currentIndex}`} place={'right-center'} type="dark" effect="solid">
          <div>
            <strong className="text-base text-neutral-0">The following issues need to be addressed:</strong>
            {errors.map((error, index) => (<div key={index} className="text-red-200 text-sm flex">
              - {error}
            </div>))}
          </div>
        </Tooltip>
      </div>}
      {hasQBIntegration && errors.length === 0 && !!bill?.quickbooks_invoice_id &&
        <div className={`ml-2 text-green-200 flex items-center sync-ok-tip-${currentIndex}`}>
          <SiQuickbooks/>
          <Tooltip anchorSelect={`.sync-ok-tip-${currentIndex}`} place={'right-center'} type="dark" effect="solid">
            <div>
              <strong className="text-base text-neutral-0">Everything looks good. No issues detected.</strong>
            </div>
          </Tooltip>
        </div>}
    </div>
    <span className={'flex'}>
      {loadingInboxItem ? '...' : <>$<NumericFormat decimalScale={2} fixedDecimalScale={true}
                                                    displayType={'text'}
                                                    value={value.total_amount}/></>}
        </span>
  </li>;
});
