import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {displayAmount} from '../../../utils/BillHelpers';

export const BlockAnalytics = ({
  title,
  amount,
  subInfo,
  loading,
}) => {
  if(!title && !amount && !subInfo) {
    return <div className={''}></div>
  }
  return <div className="mx-5 p-2 border-2 rounded bg-neutral-50 border-neutral-100 text-neutral-300 analytics-block">
    {loading ? <>
      Loading...
    </> : <>
      <div>{title}</div>
      <div className={'text-3xl my-2 font-bold text-slate-700'}>
        <CurrencyFormat value={displayAmount(amount / 100)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>
      </div>
      <div className={'text-sm'}>{subInfo}</div>
    </>}
  </div>;
};
