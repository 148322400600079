import React from 'react';
import {AiOutlineLoading} from "react-icons/ai";

const FullPageLoader = () => {
  const [needsRefresh, setNeedsRefresh] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setNeedsRefresh(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  if(needsRefresh) {
    window.location.reload();
  }
  return <div className="flex items-center justify-center h-screen">
    <div className={'absolute top-0 left-0 opacity-80 bg-red-999 h-screen w-screen'}></div>
    <div className={'flex flex-col z-10 text-neutral-0'}>
      <AiOutlineLoading className={'animate-spin centered text-center'} size={72}/>
      <div className={'flex'}>Loading...</div>
    </div>
  </div>;
};

export default FullPageLoader;
