import {noop} from 'lodash';
import React, {useState} from 'react';
import {BsTrash} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {ModalPayvyBase} from '../';
import {getUserBills, removeBill} from '../../../slices/newBill';
import {payvyToast} from "../../../utils/Utility";
import {PayvyIconButton} from '../../forms';

export const ModalBillRemove = ({
  bill,
  detailStyle = false,
  success,
}) => {
  success = success || noop;
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {deleting: processing},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const confirmRemoveBill = () => {
    dispatch(removeBill({
      id: bill.id,
      successCallback: () => {
        success();
        setModalOpen(false);
        payvyToast('Bill removed successfully.', {appearance: 'success'});
        dispatch(getUserBills({
          amount: filterAmountStr,
          contact: filterContact,
          from: fromDateStr,
          to: toDateStr,
          order_by: orderBy,
          page: currentPage,
          page_size: pageSize,
          status: status,
          q: searchQuery,
        }));
      },
      failure: (error) => {
        payvyToast(error.toString() || 'Bill removal failed.', {appearance: 'error'});
      }
    }));
  };
  return <>
    <PayvyIconButton
      Icon={BsTrash}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Bill'}
      tooltipPlace={'bottom'}
      mainColor={detailStyle ? 'none' : undefined}
      borderColor={detailStyle ? 'none' : undefined}
      iconColor={detailStyle ? 'neutral-700' : undefined}
      iconSize={detailStyle ? 22 : undefined}
      hoverTextColor={detailStyle ? 'slate-300' : undefined}
    />
    <ModalPayvyBase
      title={`Request approval for "${bill.name}" bill`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, remove bill'}
      confirmButtonAction={confirmRemoveBill}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to remove <b>"{bill.name}"</b> bill?</p>
    </ModalPayvyBase>
  </>;
};
