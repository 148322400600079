import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CleanedHTML} from "../../../comps/elements";
import {PayvyIconButton} from "../../../comps/forms";
import {disableIntegration, enableIntegration, getIntegration} from '../../../slices/newIntegration';

export const DefaultSettings = ({
  integration: {
    id,
    name,
    logo,
    description,
    enabled
  }
}) => {
  const dispatch = useDispatch();
  const {
    loading: {
      item: loading,
      posting: processing
    },
  } = useSelector((state) => state.integration);
  const connectBox = () => {
    dispatch(enableIntegration({
      id,
      success: (data) => {
        if(data['method'] === 'redirect') {
          window.location = data.url;
        }
      }
    }));
  };
  const disconnectBox = () => {
    dispatch(disableIntegration({
      id,
      success: () => {
        dispatch(getIntegration({id}));
      }
    }));
  };
  return <div className={'flex flex-col'}>
    <div className={'flex flex-row py-2'}>
      <h1 className={'flex h-24 w-24'}>{logo ?
        <img src={logo} alt={name} className={'w-24 max-h-24 rounded self-center'}/> : name}</h1>
      <CleanedHTML html={description} className={'justify-center flex flex-col mx-5'}/>
      <div className={'flex grow justify-end self-center'}>
        {enabled ? <div className={'flex flex-col items-end'}>
          <PayvyIconButton
            buttonText={'Disconnect'}
            onClick={disconnectBox}
            loading={loading || processing}
            fullWidth={true}
            wrapperClassName={'w-full'}
          />
        </div> : <div className={'flex flex-col items-end'}>
          <PayvyIconButton
            buttonText={'Connect'}
            onClick={connectBox}
            loading={loading || processing}
            fullWidth={true}
            wrapperClassName={'w-full'}
          />
        </div>}
      </div>
    </div>
  </div>;
};
