import {useField} from 'formik';
import {noop} from "lodash";
import React from 'react';

export const PayvyInput = ({
  field,
  onChange = noop,
  form,
  loading,
  controlClassName,
  containerClassName,
  helpText,
  maxLength,
  ...props
}) => {
  const [formikField] = useField(props);
  const noStyling = props.noStyling || false;
  delete props.noStyling;
  const noWidth = props.noWidth || false;
  delete props.noWidth;
  delete props.selectOnlyOption;
  return <div className={`flex flex-col ${noWidth ? '' : 'w-full'}`}>
    <input
      onChange={(e) => {
        const value = maxLength ? e.target.value.slice(0, maxLength) : e.target.value;
        e.target.value = value;
        formikField.onChange(e);
        onChange(value);
      }}
      {...field}
      {...props}
      className={`${props.className} ${props.readOnly ? 'bg-neutral-50' : ''} ${props.disabled ? 'bg-neutral-50 cursor-not-allowed' : ''} ${noStyling ? '' : 'rounded-md bg-neutral-50'} ${noWidth ? '' : 'w-full'}`}/>
    <span className={'text-xs'}>{helpText}</span>
  </div>;
};
