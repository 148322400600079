import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES, LOGIN_EMAIL} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {authSelector, sendPasswordResetAccount} from '../../../slices/auth';

export const FormPasswordReset = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  let [submitted, setSubmitted] = useState(false);
  const {
    loading,
    passwordResetProcessing: processing,
  } = useSelector(authSelector);

  return <Formik
    enableReinitialize={false}
    innerRef={formikRef}
    initialValues={{
      email: '' || LOGIN_EMAIL,
    }}
    validationSchema={Yup.object({
      email: Yup.string()
                .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL)
                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
    })}
    onSubmit={(values, helpers) => {
      dispatch(sendPasswordResetAccount({email: values.email}));
      setSubmitted(true);
    }}
  >
    {props => <Form>
      <GlobalFormError errors={props.errors['nonFieldErrors']}/>
      {submitted ? <>
        <div className={'flex flex-col items-center border-2 border-red-950 bg-red-100 px-20 py-5 mt-5'}>
          <h1 className={'flex text-xl font-bold text-red-950'}>Password Reset Link Sent Successfully!</h1>
          <span className={'flex text-red-950 ml-2 mt-3 text-sm'}>A password reset link has been sent to your email address, provided it is valid.</span>
        </div>
      </> : <>
        <PayvyLabeledInput
          label={'Email address'}
          name={'email'}
          as={PayvyInput}
          type={'email'}
        />
        <PayvyIconButton
          loading={loading || processing}
          disabled={loading || processing}
          buttonText={'Reset password'}
          fullWidth={true}
          wrapperClassName={'mt-6 w-full px-20'}
          type={'submit'}
        />
      </>}
    </Form>}
  </Formik>;
};
