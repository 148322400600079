import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {authSelector} from "../../../slices/auth";

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const {isAuthenticated} = useSelector(authSelector);
  const INACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes in milliseconds
  const DISPLAY_TIMEOUT = 5 * 60 * 1000;
  const LAST_INTERACTION_KEY = "lastInteractionTime";
  const ALREADY_ON_INACTIVITY_PAGE = window.location.pathname === PAYVY_URL.INACTIVITY

  const [remainingTime, setRemainingTime] = useState(INACTIVITY_TIMEOUT);

  useEffect(() => {
    if(!isAuthenticated) navigate(PAYVY_URL.LOGIN, {replace: true});
    if(!localStorage.getItem('accessToken')
      && !localStorage.getItem('refreshToken')
      && window.location.pathname !== PAYVY_URL.LOGIN
      && window.location.pathname !== PAYVY_URL.INACTIVITY
    )
      window.location.reload();
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    const checkInactivity = () => {
      const lastInteraction = localStorage.getItem(LAST_INTERACTION_KEY);
      const currentTime = Date.now();
      if(
        lastInteraction &&
        currentTime - parseInt(lastInteraction, 10) > INACTIVITY_TIMEOUT &&
        !ALREADY_ON_INACTIVITY_PAGE
      ) {
        navigate(PAYVY_URL.INACTIVITY);
      }
    };

    const updateLastInteraction = () => localStorage.setItem(LAST_INTERACTION_KEY, Date.now()
                                                                                       .toString());

    const updateTimer = () => {
      const lastInteraction = localStorage.getItem(LAST_INTERACTION_KEY);
      const currentTime = Date.now();
      if(lastInteraction) {
        const elapsed = currentTime - parseInt(lastInteraction, 10);
        setRemainingTime(Math.max(INACTIVITY_TIMEOUT - elapsed, 0));
      }
      checkInactivity()
    };

    // Check inactivity on component mount
    checkInactivity();

    // Add event listeners to track user activity
    document.addEventListener("mousemove", updateLastInteraction);
    document.addEventListener("keydown", updateLastInteraction);
    document.addEventListener("scroll", updateLastInteraction);

    // Update the timer every second
    const timerInterval = setInterval(updateTimer, 1000);

    return () => {
      // Clean up event listeners and interval
      document.removeEventListener("mousemove", updateLastInteraction);
      document.removeEventListener("keydown", updateLastInteraction);
      document.removeEventListener("scroll", updateLastInteraction);
      clearInterval(timerInterval);
    };
  }, [navigate, INACTIVITY_TIMEOUT, ALREADY_ON_INACTIVITY_PAGE]);

  const formatTime = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `${minutes}:${seconds.toString()
                                .padStart(2, "0")}`;
  };

  return (
    <>
      <Outlet/>
      {(1 <= remainingTime && remainingTime <= DISPLAY_TIMEOUT) && !ALREADY_ON_INACTIVITY_PAGE && (
        <div className={`fixed top-5 right-5 bg-red-400 bg-opacity-80 text-neutral-0 p-3 rounded text-sm z-50`}>
          <div className={'flex flex-col'}>
            <div className={'flex justify-center text-xs'}>Automatic logout occurs in</div>
            <div className={'flex justify-center'}>{formatTime(remainingTime + 1000)}</div>
          </div>
        </div>
      )}
    </>
  );
};
