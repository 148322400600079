import {noop} from "lodash";
import React from "react";
import {FaFax, FaPhone, FaTrash} from "react-icons/fa";
import {formatPhoneNumberIntl} from "react-phone-number-input";
import {useDispatch} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {PayvyIconButton} from "../../../comps/forms";
import {removeFaxNumber} from "../../../slices/twilio";

export const DisplayFaxNumber = ({
  refreshList,
  item,
}) => {
  refreshList = refreshList || noop;
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const {
    phone_number: phoneNumber,
    fax,
  } = item;
  const handleRemoval = () => {
    dispatch(removeFaxNumber({
      number: phoneNumber,
      success: () => {
        refreshList();
      },
      failure: () => {
        addToast('Failed to remove number', {appearance: 'error'});
      }
    }));
  };
  return <div className={'flex flex-row items-center'}>
    <div className={'flex p-1'}>
      {fax ? <FaFax className={'icon-fax'}/> : <FaPhone className={'icon-phone'}/>}
    </div>
    <div className={'flex items-center'}>
      {formatPhoneNumberIntl(phoneNumber)}
    </div>
    <div className={'flex'}>
      <PayvyIconButton
        tooltipContent={"Remove"}
        Icon={FaTrash}
        onClick={handleRemoval}
        mainColor={'none'}
        iconColor={'red-300'}
        hoverTextColor={'red-900'}
      />
    </div>
  </div>;
};
