import React, {useState} from 'react';
import {BsTrash} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {getUserInboxes, getUserInboxItem, removeInboxItem} from "../../../slices/newInbox";
import {payvyToast} from "../../../utils/Utility";
import {PayvyIconButton} from '../../forms';
import {ModalPayvyBase} from '../../modals';


export const ModalInboxRemove = ({
  item,
  detailStyle = false,
  cardPadding = false,
  deleted = false,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {deleting: processing},
    currentPage,
    pageSize,
    category
  } = useSelector((state) => state.inbox)
  const confirmRemoveItem = () => {
    dispatch(removeInboxItem({
      id: item.id,
      successCallback: () => {
        dispatch(getUserInboxes({
          page: currentPage,
          page_size: pageSize,
          order_by: '',
          category: category,
          forceUpdate: true,
          successCallback: () => {
            dispatch(getUserInboxItem({id: item.id}))
            setModalOpen(false);
            payvyToast(`Item ${deleted ? 'restored' : 'removed'} successfully.`, {appearance: 'success'});
          },
          errorCallback: (data) => {
            payvyToast(data.message, {appearance: 'error'});
          },
        }));
      },
      errorCallback: (error = {}) => {
        payvyToast(error?.message || `Item ${deleted ? 'restoration' : 'removal'} failed.`, {appearance: 'error'});
      }
    }));
  };

  const username = item?.mail_content?.sender || item?.uploaded_by?.username || 'Undefined';

  return <>
    <PayvyIconButton
      Icon={BsTrash}
      onClick={() => setModalOpen(true)}
      tooltipContent={`${deleted ? 'Restore' : 'Remove'} Inbox Item`}
      mainColor={detailStyle ? 'none' : undefined}
      iconColor={detailStyle ? 'slate-400 text-slate-400' : undefined}
      iconSize={detailStyle ? 22 : undefined}
      hoverTextColor={detailStyle ? 'slate-700 hover:text-slate-700' : undefined}
      buttonMarginX={cardPadding ? 1 : undefined}
      iconPaddingX={cardPadding ? 2 : undefined}
      iconPaddingY={cardPadding ? 2 : undefined}
    />
    <ModalPayvyBase
      title={`${deleted ? 'Restore' : 'Remove'} item from ${username}?`}
      isOpen={modalOpen}
      confirmButtonText={`Yes, ${deleted ? 'restore' : 'remove'} it`}
      confirmButtonAction={confirmRemoveItem}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to {deleted ? 'restore' : 'remove'} it?</p>
    </ModalPayvyBase>
  </>;
};
