import {isEmpty} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Tooltip} from 'react-tooltip';
import {PayvyIconButton} from '../../../comps/forms';
import {STRIPE_FIELD_CODES} from '../../../constants';
import {useCountdown} from '../../../hooks/useCountdown';
import {
  companiesSelector,
  getCompanyRepresentatives,
  submitCompanyForFinancialVerification
} from '../../../slices/companies';
import {getUser} from '../../../slices/user';
import {getLastDottedStringToCamelCase} from '../../../utils/Utility';
import {WaitMessage} from '../../../utils/WaitMessage';
import {RepresentativeIssue} from './RepresentativeIssue';

export const StripeVerificationButton = ({formik}) => {
  const dispatch = useDispatch();
  const {
    processing,
    loading,
    company,
  } = useSelector(companiesSelector);
  const {
    id: companyId,
    financial_verification_submitted_on: financialVerificationSubmittedOn,
    is_stripe_verified: isStripeVerified,
    is_finix_verified: isFinixVerified,
    is_stripe_connect_possible: isStripeConnectPossible,
    is_finix_connect_possible: isFinixConnectPossible,
    is_verification_possible: isVerificationPossible,
    stripe_connect_issues: stripeConnectIssues,
    finix_status: finixStatus,
    finix_error: finixError,
  } = company || {};
  const date = new Date(new Date(financialVerificationSubmittedOn).getTime() + 60 * 60 * 24 * 1000).toLocaleString();
  const [hours, minutes, seconds] = useCountdown(date);
  const status = isStripeVerified ? 'Verified' : 'Unverified';

  const submitToVerify = () => {
    formik.validateForm()
          .then((errors) => {
            if(formik.values.certifyCorrectInformation === false) {
              formik.setErrors({certifyCorrectInformation: 'You must certify the information is correct.'});
            } else if(isEmpty(errors)) {
              formik.submitForm()
                    .then(() => {
                      dispatch(submitCompanyForFinancialVerification({
                        companyId: companyId,
                        success: () => {
                          dispatch(getUser({}));
                          dispatch(getCompanyRepresentatives({id: companyId}));
                        },
                      }));
                    });
            }
          });
  };
  return <>
    <PayvyIconButton
      buttonText={isStripeVerified ? 'Verified' : 'Submit for Verification'}
      disabled={hours + minutes + seconds > 0 || !isVerificationPossible || (isStripeVerified && isFinixVerified) || processing || loading}
      loading={processing || loading}
      onClick={submitToVerify}
      mainColor={'none'}
      borderColor={isStripeVerified ? 'green-200' : 'slate-700'}
      borderSize={2}
      textColor={'slate-700'}
      hoverTextColor={'slate-500'}
      wrapperClassName={'company-verification'}
    />
    <Tooltip anchorSelect={'.company-verification'} type="dark" effect="solid" place={'bottom-start'}>
      <div className={'px-4 py-1 text-left'}>
        <div className={'flex flex-col mb-2'}>
          <h1 className={'flex'}>Verification Data</h1>
          <div className={'flex'}>You can only retry verification every 24 hours, make sure all fields are correctly
            filled out.
          </div>
        </div>
        {hours + minutes + seconds > 0 ? <div className={'flex flex-col'}>
          <b>{WaitMessage({
            hours,
            minutes,
            seconds,
            prefix: 'Locked for ',
          })}</b>
        </div> : <div className={'flex flex-col'}>
          <div className={'flex flex-row'}>
            <div className={'inline-flex capitalize'}>Stripe Status:
              <span className={'pl-1 capitalize font-bold'}>{status}</span>
              <span className={'pl-1'}>{isStripeConnectPossible ? <small>(Can submit)</small> : null}</span>
            </div>
          </div>
          <div className={`flex ${!isStripeConnectPossible ? 'flex-col' : 'flex-row'} mb-2`}>
            <div className={'flex capitalize'}>Backup payment method authorization:</div>
            <div className={'flex capitalize'}>
              {!isStripeConnectPossible ? <>
                <div className={'flex flex-col w-full'}>
                  {Object.entries(stripeConnectIssues)
                         .map((problem, index) => {
                           const [field, issue] = problem;
                           let field_name = field.replaceAll('_', ' ')
                                                 .replaceAll('.', ' ');
                           if(field in STRIPE_FIELD_CODES) field_name = STRIPE_FIELD_CODES[field];
                           return <RepresentativeIssue
                             key={`finix-${index}`}
                             field_name={field_name}
                             issues={issue}
                           />;
                         })}
                </div>
              </> : <span className={'pl-1 capitalize font-bold'}>No issues.</span>}
            </div>
          </div>

          <div className={'flex flex-row'}>
            <div className={'inline-flex capitalize'}>Finix Status:
              <span className={'pl-1 capitalize font-bold'}>{finixStatus}</span>
              <span className={'pl-1'}>{isFinixConnectPossible ? <small>(Can submit)</small> : null}</span>
            </div>
          </div>
          <div className={`flex ${finixError && finixError.length > 0 ? 'flex-col' : 'flex-row'} mb-2`}>
            <div className={'flex capitalize'}>Inbound payments authorization:</div>
            <div className={'flex capitalize'}>
              {finixError && finixError.length > 0 ? <>
                <div className={'flex flex-col w-full'}>
                  {Object.entries(finixError)
                         .map((problem, index) => {
                           return <RepresentativeIssue
                             key={`finix-${index}`}
                             field_name={getLastDottedStringToCamelCase(problem[1].field)}
                             issues={[problem[1].message]}
                           />;
                         })}
                </div>
              </> : <span className={'pl-1 capitalize font-bold'}>No issues.</span>}
            </div>
          </div>
        </div>}
      </div>
    </Tooltip>
  </>;
};
