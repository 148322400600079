import {Form, Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {FaCreditCard} from "react-icons/fa";
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {BigCenteredLoadingBlock, PayvyPageHeader} from "../../comps/elements";
import {PayvyGlobalFormError, PayvyIconButton, PayvyIconButtonGroup} from "../../comps/forms";
import {FORM_ERROR_MESSAGES, PAYVY_URL} from '../../constants';
import useFormikHotkeys from "../../hooks/useFormikHotkeys";
import {companiesSelector} from '../../slices/companies';
import {getBankAccounts, kycSelector} from "../../slices/kyc";
import {getBill, payBill, resetBillToInitialState} from "../../slices/newBill";
import {getPaymentServiceInformation, paymentSystemSelector} from "../../slices/paymentSystem";
import {userSelector} from "../../slices/user";
import {
  build_error_message,
  dateFormat,
  formatDateForAPItoYMD,
  handleErrors,
  payvyToast,
  validateEmail
} from "../../utils/Utility";
import {AirwallexPaymentForm} from "./components/AirwallexPaymentForm";
import {BrexPaymentForm} from "./components/BrexPaymentForm";
import {MercuryPaymentForm} from "./components/MercuryPaymentForm";
import {OutsidePaymentForm} from "./components/OutsidePaymentForm";
import {RevolutPaymentForm} from "./components/RevolutPaymentForm";
import {WisePaymentForm} from "./components/WisePaymentForm";

const BillPayPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {id} = useParams();
  const {
    loading: paymentSystemLoading,
    services
  } = useSelector(paymentSystemSelector);
  const {
    bankAccountsDropdownFormat: bankAccounts,
    loading: bankAccountLoading,
  } = useSelector(kycSelector);
  const {user: {email: userEmail}} = useSelector(userSelector);
  const {
    loading: {
      item: billLoading,
      posting: processing
    },
    items,
  } = useSelector((state) => state.bill);
  const {
    company,
  } = useSelector(companiesSelector);
  const {
    id: companyId,
  } = company || {};
  const [bill, setBill] = useState({});
  const {
    name = '',
    contact: {
      id: contactId,
      name: contactName = '',
      email: contactEmail,
    } = {},
    amount,
    remaining
  } = bill;
  const paymentTypeChoices = [
    ...services.map(service => ({
      label: service.name,
      value: service.internal_name
    })), {
      label: 'Mark as Paid',
      value: 'outside'
    },
  ];
  useEffect(() => {
    setBill(Object.values(items)
                  .flat()
                  .find(item => item.id === parseInt(id)));
  }, [items, id])
  useEffect(() => {
    dispatch(getBill({
      id,
      errorCallback: () => {
        navigate(PAYVY_URL.BILLS.LIST);
      }
    }));
    dispatch(getPaymentServiceInformation({}));
    dispatch(getBankAccounts({
      page: 1,
      page_size: 100,
      active: true,
      type: '',
      verified: '',
      hasDwolla: ''
    }));
  }, [dispatch, companyId, navigate, id]);
  const paymentType = 'outside';
  const loading = bankAccountLoading || billLoading || paymentSystemLoading;
  const [emailOptions, setEmailOptions] = useState([]);
  const [currentPaymentType, setCurrentPaymentType] = useState('outside');
  const isMercuryPayment = currentPaymentType === 'mercury'
  const isBrexPayment = currentPaymentType === 'brex'
  const isWisePayment = currentPaymentType === 'wise'
  const isRevolutPayment = currentPaymentType === 'revolut'
  const isAirwallexPayment = currentPaymentType === 'airwallex'
  const isOutsidePayment = currentPaymentType === 'outside'
  const checkboxFieldsSet = (props, emails) => {
    const hasUserEmail = emails.find(i => i.value === userEmail);
    const hasContactEmail = emails.find(i => i.value === contactEmail);
    props.setFieldValue('emailToUser', !!hasUserEmail);
    props.setFieldValue('emailToVendor', !!hasContactEmail);
  };
  const handleCreate = (props, value) => {
    let emails = props.values.emailConfirmationsTo;
    if(validateEmail(value)) {
      emails.push({
        label: value,
        value,
      });
      props.setFieldValue('emailConfirmationsTo', emails);
      setEmailOptions(emails);
    }
  };
  const addAndSelectVendor = (props, value) => {
    let emails = props.values.emailConfirmationsTo;
    emails = emails.filter(i => i.value !== contactEmail);
    if(value && !emails.find(i => i.value === contactEmail)) emails.push({
      value: contactEmail,
      label: contactEmail
    });
    props.setFieldValue('emailConfirmationsTo', emails);
    checkboxFieldsSet(props, emails);
  };
  const addAndSelectUser = (props, value) => {
    let emails = props.values.emailConfirmationsTo;
    emails = emails.filter(i => i.value !== userEmail);
    if(value && !emails.find(i => i.value === userEmail)) emails.push({
      value: userEmail,
      label: userEmail
    });
    props.setFieldValue('emailConfirmationsTo', emails);
    checkboxFieldsSet(props, emails);
  };
  return <div className={'flex flex-col w-full h-full'}>
    <PayvyPageHeader parents={[
      {name: 'Payables'},
      {
        name: 'Bills',
        href: PAYVY_URL.BILLS.LIST
      }
    ]}>Paying {bill.name}</PayvyPageHeader>
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        activePaymentType: paymentType,
        paymentDate: dateFormat(new Date()),
        paymentMethod: '',
        swiftChargeOption: '', // airwallex only, when payment method is SWIFT, only options are PAYER, SHARED
        account: undefined,
        internalDescription: '',
        paymentAmount: remaining,
        emailConfirmationsTo: [],
        emailToVendor: false,
        emailToUser: false,
      }}
      validationSchema={Yup.object({
        activePaymentType: Yup.string()
                              .oneOf(['airwallex', 'mercury', 'brex', 'wise', 'revolut', 'outside'])
                              .required(),
        account: Yup.number(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                    .positive(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        recipientAccount: Yup.string()
                             .when('activePaymentType', {
                               is: (value) => value === 'revolut' || value === 'brex',
                               then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                             }),
        paymentMethod: Yup.string()
                          .when('activePaymentType', {
                            is: 'airwallex',
                            then: s => s.oneOf(['LOCAL', 'SWIFT'])
                                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                            otherwise: s => s.nullable()
                                             .notRequired(),
                          }),
        swiftChargeOption: Yup.string()
                              .when(['activePaymentType', 'paymentMethod'], {
                                is: (
                                  paymentType,
                                  paymentMethod
                                ) => paymentType === 'airwallex' && paymentMethod === 'SWIFT',
                                then: s => s.oneOf(['PAYER', 'SHARED'])
                                            .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                                otherwise: s => s.nullable()
                                                 .notRequired(),
                              }),
        paymentDate: Yup.date()
                        .required(),
        internalDescription: Yup.string()
                                .max(255)
                                .when('activePaymentType', {
                                  is: 'outside',
                                  then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                                }),
        paymentAmount: Yup.number()
                          .when('activePaymentType', {
                            is: 'outside',
                            then: (schema) => schema.min(0, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: bill.remaining})),
                            otherwise: (schema) => schema.positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                          })
                          .max(bill.remaining, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: bill.remaining}))
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      })}
      onSubmit={(values, helpers) => {
        const data = {
          id,
          body: {
            payment_type: values.activePaymentType,
            account: values.account,
            recipient_account: values.recipientAccount,
            payment_date: formatDateForAPItoYMD(values.paymentDate),
            accounting_class: values.accountingClass,
            payment_amount: values.paymentAmount,
            emails_to_send: values.emailConfirmationsTo.map(i => i.value),
            internal_description: values.internalDescription,
            payment_method: values.paymentMethod,
            swift_charge_option: values.swiftChargeOption,
          },
          successCallback: () => {
            payvyToast('Bill paid successfully.', {appearance: 'success'});
            dispatch(resetBillToInitialState());
            navigate(PAYVY_URL.BILLS.LIST);
          },
          errorCallback: (data) => {
            if(data.message === 'You do not have permission to perform this action.') {
              data.data.nonFieldErrors = [
                'You must have Two Factor Authentication enabled to pay a bill.',
                'If you have enabled Two Factor Authentication, you might not have permission to pay.'
              ];
            }
            handleErrors(data.data, helpers);
          }
        };
        dispatch(payBill(data));
      }}
    >
      {formProps => <Form className={'h-full'}>
        {loading ? <BigCenteredLoadingBlock text={'Loading payment options'}/> : <>
          <div className={' w-full px-2 py-4 flex flex-row'}>
            <h1 className={'text-2xl font-light text-neutral-500'}>Pay Bill {name} to {contactName}</h1>
            <div className={'flex grow justify-end'}>
              <PayvyIconButton
                buttonText={'Pay Bill'}
                Icon={FaCreditCard}
                loading={processing || loading}
                disabled={processing || loading}
                type={'submit'}
              />
            </div>
          </div>
          <div className={'flex flex-col w-full bg-neutral-50 grow p-2'}>
            <div className={'w-full'}>
              <PayvyIconButtonGroup
                value={formProps.values.activePaymentType}
                onChange={async(value) => {
                  setCurrentPaymentType(value);
                  await formProps.setFieldValue('activePaymentType', value);
                }}
                wrapperClassName={''}
                choices={paymentTypeChoices}/>
            </div>
            <PayvyGlobalFormError/>
            {isMercuryPayment && <MercuryPaymentForm
              contactId={contactId}
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
            {isBrexPayment && <BrexPaymentForm
              contactId={contactId}
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
            {isWisePayment && <WisePaymentForm
              contactId={contactId}
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
            {isRevolutPayment && <RevolutPaymentForm
              contactId={contactId}
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
            {isAirwallexPayment && <AirwallexPaymentForm
              contactId={contactId}
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
            {isOutsidePayment && <OutsidePaymentForm
              formProps={formProps}
              processing={processing}
              loading={loading}
              bankAccounts={bankAccounts}
              amount={amount}
              emailOptions={emailOptions}
              handleCreate={handleCreate}
              checkboxFieldsSet={checkboxFieldsSet}
              addAndSelectVendor={addAndSelectVendor}
              addAndSelectUser={addAndSelectUser}
            />}
          </div>
        </>}
      </Form>}
    </Formik>
  </div>;
};

export default BillPayPage;
