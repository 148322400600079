import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from "react-select";
import Creatable from "react-select/creatable";
import {getContacts} from '../../../slices/newContact';

export const SearchContact = ({
  value,
  setField,
  readOnly = false,
  placeholder = 'Search',
  onlyCustomers = false,
  onlyVendors = false,
  isClearable = false,
  allowAddition = false,
}) => {
  setField = setField || noop;
  const [valueObject, setValueObject] = React.useState(undefined);
  const contactType = onlyCustomers ? 'customer' : onlyVendors ? 'vendor' : '';
  const dispatch = useDispatch();
  const {
    searchList = [],
    loading: {list: loading},
  } = useSelector((state) => state.contact);
  const options = searchList
  useEffect(() => {
    if(value === null) {
      setValueObject(null);
    } else if(!!value && !valueObject) {
      setValueObject(searchList?.find(contact => contact.value === value));
    }
  }, [value, searchList, valueObject]);
  useEffect(() => {
    dispatch(getContacts({
      page: 1,
      page_size: 1000,
      contact_type: contactType
    }));
  }, [dispatch, contactType]);
  const triggerLocalFieldChange = (option) => {
    setField(option || {value: undefined});
    setValueObject(option);
  };
  return <>
    {allowAddition ?
      <Creatable
        className={'w-full'}
        placeholder={placeholder}
        isLoading={loading}
        isDisabled={readOnly}
        value={valueObject}
        onChange={(option) => triggerLocalFieldChange(option)}
        options={options}
        isClearable={isClearable}
      />
      :
      <Select
        className={'w-full'}
        placeholder={placeholder}
        isLoading={loading}
        isDisabled={readOnly}
        value={valueObject}
        onChange={(option) => triggerLocalFieldChange(option)}
        options={options}
        isClearable={isClearable}
      />}
  </>;
}
