import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BigCenteredLoadingBlock, PayvyPageHeader, SettingsMenu} from '../../comps/elements';
import {PayvyIconButton} from "../../comps/forms";
import {getNotificationSettings, saveNotificationSettings, userSelector} from '../../slices/user';
import {NotificationTableRow} from "./components/NotificationTableRow";


// This class is fine, but should be DRY.
const SettingsNotificationsPage = () => {
  const dispatch = useDispatch();
  const [newInboxDocumentEmail, setNewInboxDocumentEmail] = useState(false);
  const [newInboxDocumentSms, setNewInboxDocumentSms] = useState(false);
  const [newInboxDocumentPush, setNewInboxDocumentPush] = useState(false);

  const [vendorEmailResponseEmail, setVendorEmailResponseEmail] = useState(false);
  const [vendorEmailResponseSms, setVendorEmailResponseSms] = useState(false);
  const [vendorEmailResponsePush, setVendorEmailResponsePush] = useState(false);

  const [vendorSmsResponseEmail, setVendorSMSResponseEmail] = useState(false);
  const [vendorSmsResponseSms, setVendorSMSResponseSms] = useState(false);
  const [vendorSmsResponsePush, setVendorSMSResponsePush] = useState(false);

  const [billMentionsEmail, setBillMentionsEmail] = useState(false);
  const [billMentionsSms, setBillMentionsSms] = useState(false);
  const [billMentionsPush, setBillMentionsPush] = useState(false);

  const [achBillPaymentsEmail, setAchBillPaymentsEmail] = useState(false);
  const [achBillPaymentsSms, setAchBillPaymentsSms] = useState(false);
  const [achBillPaymentsPush, setAchBillPaymentsPush] = useState(false);

  const [checkBillPaymentsEmail, setCheckBillPaymentsEmail] = useState(false);
  const [checkBillPaymentsSms, setCheckBillPaymentsSms] = useState(false);
  const [checkBillPaymentsPush, setCheckBillPaymentsPush] = useState(false);

  const [externalBillPaymentsEmail, setExternalBillPaymentsEmail] = useState(false);
  const [externalBillPaymentsSms, setExternalBillPaymentsSms] = useState(false);
  const [externalBillPaymentsPush, setExternalBillPaymentsPush] = useState(false);

  const [myBillApprovalsEmail, setMyBillApprovalsEmail] = useState(false);
  const [myBillApprovalsSms, setMyBillApprovalsSms] = useState(false);
  const [myBillApprovalsPush, setMyBillApprovalsPush] = useState(false);

  const [overdueBillsEmail, setOverdueBillsEmail] = useState(false);
  const [overdueBillsSms, setOverdueBillsSms] = useState(false);
  const [overdueBillsPush, setOverdueBillsPush] = useState(false);

  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => _setRefreshInfo(new Date());
  const {
    loadingNotificationSettings,
    notificationSettings
  } = useSelector(userSelector);
  useEffect(() => {
    dispatch(getNotificationSettings());
  }, [dispatch, refreshInfo]);
  useEffect(() => {
    const {
      new_inbox_document_email: newInboxDocumentEmail,
      new_inbox_document_sms: newInboxDocumentSms,
      new_inbox_document_push: newInboxDocumentPush,
      vendor_email_response_email: vendorEmailResponseEmail,
      vendor_email_response_sms: vendorEmailResponseSms,
      vendor_email_response_push: vendorEmailResponsePush,
      vendor_sms_response_email: vendorSmsResponseEmail,
      vendor_sms_response_sms: vendorSmsResponseSms,
      vendor_sms_response_push: vendorSmsResponsePush,
      bill_mentions_email: billMentionsEmail,
      bill_mentions_sms: billMentionsSms,
      bill_mentions_push: billMentionsPush,
      ach_bill_payments_email: achBillPaymentsEmail,
      ach_bill_payments_sms: achBillPaymentsSms,
      ach_bill_payments_push: achBillPaymentsPush,
      check_bill_payments_email: checkBillPaymentsEmail,
      check_bill_payments_sms: checkBillPaymentsSms,
      check_bill_payments_push: checkBillPaymentsPush,
      external_bill_payments_email: externalBillPaymentsEmail,
      external_bill_payments_sms: externalBillPaymentsSms,
      external_bill_payments_push: externalBillPaymentsPush,
      my_bill_approvals_email: myBillApprovalsEmail,
      my_bill_approvals_sms: myBillApprovalsSms,
      my_bill_approvals_push: myBillApprovalsPush,
      overdue_bills_email: overdueBillsEmail,
      overdue_bills_sms: overdueBillsSms,
      overdue_bills_push: overdueBillsPush,
    } = notificationSettings || {};
    setNewInboxDocumentEmail(newInboxDocumentEmail);
    setNewInboxDocumentSms(newInboxDocumentSms);
    setNewInboxDocumentPush(newInboxDocumentPush);
    setVendorEmailResponseEmail(vendorEmailResponseEmail);
    setVendorEmailResponseSms(vendorEmailResponseSms);
    setVendorEmailResponsePush(vendorEmailResponsePush);
    setVendorSMSResponseEmail(vendorSmsResponseEmail);
    setVendorSMSResponseSms(vendorSmsResponseSms);
    setVendorSMSResponsePush(vendorSmsResponsePush);
    setBillMentionsEmail(billMentionsEmail);
    setBillMentionsSms(billMentionsSms);
    setBillMentionsPush(billMentionsPush);
    setAchBillPaymentsEmail(achBillPaymentsEmail);
    setAchBillPaymentsSms(achBillPaymentsSms);
    setAchBillPaymentsPush(achBillPaymentsPush);
    setCheckBillPaymentsEmail(checkBillPaymentsEmail);
    setCheckBillPaymentsSms(checkBillPaymentsSms);
    setCheckBillPaymentsPush(checkBillPaymentsPush);
    setExternalBillPaymentsEmail(externalBillPaymentsEmail);
    setExternalBillPaymentsSms(externalBillPaymentsSms);
    setExternalBillPaymentsPush(externalBillPaymentsPush);
    setMyBillApprovalsEmail(myBillApprovalsEmail);
    setMyBillApprovalsSms(myBillApprovalsSms);
    setMyBillApprovalsPush(myBillApprovalsPush);
    setOverdueBillsEmail(overdueBillsEmail);
    setOverdueBillsSms(overdueBillsSms);
    setOverdueBillsPush(overdueBillsPush);
  }, [notificationSettings]);
  const handleSaving = () => {
    dispatch(saveNotificationSettings({
      new_inbox_document_email: newInboxDocumentEmail,
      new_inbox_document_sms: newInboxDocumentSms,
      new_inbox_document_push: newInboxDocumentPush,
      vendor_email_response_email: vendorEmailResponseEmail,
      vendor_email_response_sms: vendorEmailResponseSms,
      vendor_email_response_push: vendorEmailResponsePush,
      vendor_sms_response_email: vendorSmsResponseEmail,
      vendor_sms_response_sms: vendorSmsResponseSms,
      vendor_sms_response_push: vendorSmsResponsePush,
      bill_mentions_email: billMentionsEmail,
      bill_mentions_sms: billMentionsSms,
      bill_mentions_push: billMentionsPush,
      ach_bill_payments_email: achBillPaymentsEmail,
      ach_bill_payments_sms: achBillPaymentsSms,
      ach_bill_payments_push: achBillPaymentsPush,
      check_bill_payments_email: checkBillPaymentsEmail,
      check_bill_payments_sms: checkBillPaymentsSms,
      check_bill_payments_push: checkBillPaymentsPush,
      external_bill_payments_email: externalBillPaymentsEmail,
      external_bill_payments_sms: externalBillPaymentsSms,
      external_bill_payments_push: externalBillPaymentsPush,
      my_bill_approvals_email: myBillApprovalsEmail,
      my_bill_approvals_sms: myBillApprovalsSms,
      my_bill_approvals_push: myBillApprovalsPush,
      overdue_bills_email: overdueBillsEmail,
      overdue_bills_sms: overdueBillsSms,
      overdue_bills_push: overdueBillsPush,
      success: () => {
        setRefreshInfo();
      }
    }));
  };
  return <>
    <PayvyPageHeader parents={[{name: 'Settings'}]}>Notifications</PayvyPageHeader>
    <SettingsMenu active='notifications'/>
    <div className={'w-full p-4 bg-neutral-50 flex flex-col gap-2 mx-auto'}>
      {loadingNotificationSettings ? <BigCenteredLoadingBlock text={'Loading'}/> :
        <div className={'flex flex-col w-full lg:w-1/2 mx-auto'}>
          <div className={'flex text-sm text-payvyBlue-950 font-bold mt-4'}>
            <span>Notifications</span>
          </div>
          <table className={'table-auto mt-2 drop-shadow-2xl w-full border border-slate-300'}>
            <thead>
            <tr className={'border border-slate-300 text-center bg-slate-50'}>
              <th>
                <div className={'flex justify-center py-2 shadow-inner'}>Type</div>
              </th>
              <th>
                <div className={'flex justify-center py-2 shadow-inner'}>Email</div>
              </th>
              <th>
                <div className={'flex justify-center py-2 shadow-inner'}>SMS</div>
              </th>
              <th>
                <div className={'flex justify-center py-2 shadow-inner'}>Push</div>
              </th>
            </tr>
            </thead>
            <tbody className={'bg-neutral-0'}>
            <NotificationTableRow
              title={'New Inbox Document'}
              checked={{
                email: newInboxDocumentEmail,
                sms: newInboxDocumentSms,
                push: newInboxDocumentPush,
              }}
              onChange={{
                email: () => setNewInboxDocumentEmail(!newInboxDocumentEmail),
                sms: () => setNewInboxDocumentSms(!newInboxDocumentSms),
                push: () => setNewInboxDocumentPush(!newInboxDocumentPush),
              }}
            />
            <NotificationTableRow
              title={'Vendor Email Response'}
              checked={{
                email: vendorEmailResponseEmail,
                sms: vendorEmailResponseSms,
                push: vendorEmailResponsePush,
              }}
              onChange={{
                email: () => setVendorEmailResponseEmail(!vendorEmailResponseEmail),
                sms: () => setVendorEmailResponseSms(!vendorEmailResponseSms),
                push: () => setVendorEmailResponsePush(!vendorEmailResponsePush),
              }}
            />
            <NotificationTableRow
              title={'Vendor SMS Response'}
              checked={{
                email: vendorSmsResponseEmail,
                sms: vendorSmsResponseSms,
                push: vendorSmsResponsePush,
              }}
              onChange={{
                email: () => setVendorSMSResponseEmail(!vendorSmsResponseEmail),
                sms: () => setVendorSMSResponseSms(!vendorSmsResponseSms),
                push: () => setVendorSMSResponsePush(!vendorSmsResponsePush),
              }}
            />
            <NotificationTableRow
              title={'Bill Mentions'}
              checked={{
                email: billMentionsEmail,
                sms: billMentionsSms,
                push: billMentionsPush,
              }}
              onChange={{
                email: () => setBillMentionsEmail(!billMentionsEmail),
                sms: () => setBillMentionsSms(!billMentionsSms),
                push: () => setBillMentionsPush(!billMentionsPush),
              }}
            />
            <NotificationTableRow
              title={'ACH Bill Payments'}
              checked={{
                email: achBillPaymentsEmail,
                sms: achBillPaymentsSms,
                push: achBillPaymentsPush,
              }}
              onChange={{
                email: () => setAchBillPaymentsEmail(!achBillPaymentsEmail),
                sms: () => setAchBillPaymentsSms(!achBillPaymentsSms),
                push: () => setAchBillPaymentsPush(!achBillPaymentsPush),
              }}
            />
            <NotificationTableRow
              title={'Check Bill Payments'}
              checked={{
                email: checkBillPaymentsEmail,
                sms: checkBillPaymentsSms,
                push: checkBillPaymentsPush,
              }}
              onChange={{
                email: () => setCheckBillPaymentsEmail(!checkBillPaymentsEmail),
                sms: () => setCheckBillPaymentsSms(!checkBillPaymentsSms),
                push: () => setCheckBillPaymentsPush(!checkBillPaymentsPush),
              }}
            />
            <NotificationTableRow
              title={'External Bill Payments'}
              checked={{
                email: externalBillPaymentsEmail,
                sms: externalBillPaymentsSms,
                push: externalBillPaymentsPush,
              }}
              onChange={{
                email: () => setExternalBillPaymentsEmail(!externalBillPaymentsEmail),
                sms: () => setExternalBillPaymentsSms(!externalBillPaymentsSms),
                push: () => setExternalBillPaymentsPush(!externalBillPaymentsPush),
              }}
            />
            <NotificationTableRow
              title={'My Bill Approvals'}
              checked={{
                email: myBillApprovalsEmail,
                sms: myBillApprovalsSms,
                push: myBillApprovalsPush,
              }}
              onChange={{
                email: () => setMyBillApprovalsEmail(!myBillApprovalsEmail),
                sms: () => setMyBillApprovalsSms(!myBillApprovalsSms),
                push: () => setMyBillApprovalsPush(!myBillApprovalsPush),
              }}
            />
            <NotificationTableRow
              title={'Overdue Bills'}
              checked={{
                email: overdueBillsEmail,
                sms: overdueBillsSms,
                push: overdueBillsPush,
              }}
              onChange={{
                email: () => setOverdueBillsEmail(!overdueBillsEmail),
                sms: () => setOverdueBillsSms(!overdueBillsSms),
                push: () => setOverdueBillsPush(!overdueBillsPush),
              }}
            />
            </tbody>
          </table>
          <PayvyIconButton
            buttonText={'Save settings'}
            fullWidth={true}
            wrapperClassName={'w-full mt-4'}
            onClick={handleSaving}
          />
        </div>}
    </div>
  </>;
};

export default SettingsNotificationsPage;
