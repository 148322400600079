import React from 'react';
import Moment from 'react-moment';

export const History = ({inboxItem}) => {
  const labelClassName = 'font-bold text-red-950 text-right';
  const valueClassName = 'font-light pl-2';
  const {
    mail_content,
    uploaded_by,
    attachment,
    received_on,
  } = inboxItem || {};
  const {
    sender,
    recipient,
    received_on: mailContentReceivedOn,
    subject,
    body,
    token
  } = mail_content || {};
  const uploadMethod = mail_content ? 'Email' : 'Web Upload';
  const user = !mail_content ? uploaded_by?.username : null;
  const fileName = !mail_content ? attachment?.name : null;
  const numberOfPages = !mail_content ? attachment?.number_of_pages : null;
  const timestamp = (mailContentReceivedOn || received_on) &&
    <Moment date={mailContentReceivedOn || received_on} format={'ll'}/>;

  const renderRow = (label, value, className = valueClassName) => (
    value && (
      <tr>
        <td className={labelClassName}>{label}</td>
        <td className={className}>{value}</td>
      </tr>
    )
  );

  return (
    <table className="table-auto text-sm m-2 border-spacing-4 whitespace-nowrap">
      <tbody>
      {renderRow('Upload Method', uploadMethod)}
      {renderRow('Sender', sender)}
      {renderRow('Recipient', recipient)}
      {renderRow('Timestamp', timestamp)}
      {renderRow('Subject', subject)}
      {renderRow('Body', body)}
      {renderRow('Token', token)}
      {renderRow('User', user)}
      {renderRow('File Name', <span
        title={fileName}>{fileName?.length > 20 ? `${fileName.substring(0, 20)}...` : fileName}</span>)}
      {renderRow('Pages', numberOfPages)}
      </tbody>
    </table>
  );
};
