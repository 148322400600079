import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {PAYVY_URL} from '../../constants';
import {checkInvitationStatus} from '../../slices/newContact';
import {FormInviteUser} from './components/FormInviteUser';


const InvitedUserLandingPage = () => {
  const dispatch = useDispatch();
  const {hash} = useParams();
  const navigate = useNavigate();
  const {invitationStatus} = useSelector((state) => state.contact);
  const unacceptedStatuses = ['accepted', 'declined', 'already-registered', 'unknown'];
  const acceptedStatuses = ['pending'];
  useEffect(() => {
    if(!invitationStatus) dispatch(checkInvitationStatus({hash}));
  }, [dispatch, hash, invitationStatus]);

  if(!hash || unacceptedStatuses.includes(invitationStatus)) navigate(PAYVY_URL.LOGIN);
  if(acceptedStatuses.includes(invitationStatus)) return <FormInviteUser hash={hash}/>;
  return <FormInviteUser hash={hash}/>;
};

export default InvitedUserLandingPage;
