import React from 'react';
import {displayAmount} from '../../../utils/BillHelpers';

let CurrencyFormat = require('react-currency-format');

export function CellBillCurrency({
  value,
  column: {
    CanBeNull = false,
    inCents = false,
  },
  row: {original: {remaining}}
}) {
  if(CanBeNull && value === null) return 'N/A';
  value = parseFloat(value);
  if(inCents) value = value / 100;
  return <>
    {value === 0 ? <>$</> :
      <CurrencyFormat value={displayAmount(value)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>}
    {remaining && remaining > 0 && remaining !== value &&
      <span className={'block text-xs text-gray-600'}><CurrencyFormat value={displayAmount(remaining)}
                                                                      displayType={'text'} thousandSeparator={true}
                                                                      prefix={'$'}/> remaining</span>}
  </>;
}
