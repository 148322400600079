import {Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms/';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {logoutUser} from "../../../slices/auth";
import {changePassword, userSelector} from '../../../slices/user';
import {handleErrors} from '../../../utils/Utility';

export const ModalChangePassword = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const changePasswordForm = React.useRef(null);
  const {
    processing,
    loading,
  } = useSelector(userSelector);
  return <>
    <PayvyIconButton
      buttonText={'Securely Update Password'}
      onClick={() => setModalOpen(true)}
      mainColor={'none'}
      borderSize={2}
      borderColor={'slate-900'}
      textColor={'slate-900'}
      hoverTextColor={'slate-700'}
      wrapperClassName={'grow justify-end'}
    />
    <ModalPayvyBase
      title={`Changing Password`}
      isOpen={modalOpen}
      confirmButtonText={'Update Password'}
      confirmButtonAction={() => changePasswordForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setTimeout(() => {setModalOpen(false)}, 50)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={Yup.object({
          currentPassword: Yup.string()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          newPassword: Yup.string()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          newPasswordConfirmation: Yup.string()
                                      .oneOf([Yup.ref('newPassword')], FORM_ERROR_MESSAGES.PASSWORD_DOES_NOT_MATCH)
                                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          dispatch(changePassword({
            current_password: values.currentPassword,
            new_password: values.newPassword,
            success: () => {
              helpers.resetForm();
              setModalOpen(false);
              addToast('Password changed successfully.', {appearance: 'success'});
              dispatch(logoutUser())
            },
            failure: (error) => {
              handleErrors(error, helpers);
            }
          }));
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'Current password'}
            name={'currentPassword'}
            loading={processing || loading}
            as={PayvyInput}
            type={'password'}
          />
          <PayvyLabeledInput
            label={'New Password'}
            name={'newPassword'}
            loading={processing || loading}
            as={PayvyInput}
            type={'password'}
          />
          <PayvyLabeledInput
            label={'Confirm New Password'}
            name={'newPasswordConfirmation'}
            loading={processing || loading}
            as={PayvyInput}
            type={'password'}
          />
          <button ref={changePasswordForm} type="hidden" onClick={props.handleSubmit}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
