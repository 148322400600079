import {Formik} from 'formik';
import {noop} from 'lodash';
import React, {useRef, useState} from 'react';
import {FaUpload} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, getCompanyDetails, stripeUploadDocumentVerification} from '../../../slices/companies';
import {build_error_message} from '../../../utils/Utility';
import {checkIfFilesAreCorrectType, checkIfFilesAreTooBig} from '../../../utils/Validators';

export const ModalOwnerVerificationDocument = ({
  companyId = 0,
  id = 0,
  open = false,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(open);
  const {
    processing,
    loading,
  } = useSelector(companiesSelector);
  const verificationFileForm = React.createRef();
  return <>
    <PayvyIconButton
      Icon={FaUpload}
      tooltipContent={'Upload Verification Documents'}
      onClick={() => setModalOpen(true)}
    />
    <ModalPayvyBase
      title={`Verification Documents`}
      isOpen={modalOpen}
      confirmButtonText={'Upload'}
      confirmButtonAction={() => verificationFileForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          idDocumentFront: null,
          idDocumentBack: null,
        }}
        validationSchema={Yup.object({
          idDocumentFront: Yup.mixed()
                              .nullable()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                              .test('is-correct-file', build_error_message(FORM_ERROR_MESSAGES.INCORRECT_FILE_TYPE, {types: 'jpg, png, pdf'}), checkIfFilesAreCorrectType)
                              .test('is-big-file', build_error_message(FORM_ERROR_MESSAGES.FILE_SIZE_TOO_BIG, {size: '10 MB'}), checkIfFilesAreTooBig),
          idDocumentBack: Yup.mixed()
                             .nullable()
                             .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                             .test('is-correct-file', build_error_message(FORM_ERROR_MESSAGES.INCORRECT_FILE_TYPE, {types: 'jpg, png, pdf'}), checkIfFilesAreCorrectType)
                             .test('is-big-file', build_error_message(FORM_ERROR_MESSAGES.FILE_SIZE_TOO_BIG, {size: '10 MB'}), checkIfFilesAreTooBig),
        })}
        onSubmit={(values) => {
          refreshInfo();
          const data = new FormData();
          data.append('front', values.idDocumentFront, values.idDocumentFront.name);
          data.append('back', values.idDocumentBack, values.idDocumentBack.name);
          dispatch(stripeUploadDocumentVerification({
              company_id: companyId,
              representative_id: id,
              formData: data,
              success: () => {
                setModalOpen(false);
                dispatch(getCompanyDetails({id: companyId}));
                addToast('Verification documents uploaded successfully.', {appearance: 'success'});
              },
              failure: () => {
                setModalOpen(false);
                dispatch(getCompanyDetails({id: companyId}));
                addToast('Account is already verified.', {appearance: 'error'});
              },
            }
          ));
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'ID Document Front'}
            name={'idDocumentFront'}
            alwaysLabel={true}
          >
            <input
              type={'file'}
              name={'idDocumentFront'}
              accept={'image/*, application/pdf'}
              onChange={(e) => props.setFieldValue('idDocumentFront', e.target.files[0])}
              className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'ID Document Back'}
            name={'idDocumentBack'}
            alwaysLabel={true}
          >
            <input
              type={'file'}
              name={'idDocumentBack'}
              accept={'image/*, application/pdf'}
              onChange={(e) => props.setFieldValue('idDocumentBack', e.target.files[0])}
              className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
            />
          </PayvyLabeledInput>
          <input type={'hidden'} ref={verificationFileForm} onClick={props.handleSubmit}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
