import {noop} from "lodash";
import React, {useState} from "react";
import {BsFillTrashFill} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import {PayvyIconButton} from "../../../comps/forms";
import {ModalPayvyBase} from "../../../comps/modals";
import {companiesSelector, removeTeamMember} from "../../../slices/companies";

export const ModalRemoveMember = ({
  company,
  member,
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const {processing} = useSelector(companiesSelector);

  const confirmRemoveMember = () => {
    dispatch(removeTeamMember({
      memberId: member.id,
      companyId: company.id,
      success: () => {
        refreshInfo(new Date());
        setModalOpen(false);
        addToast('Team member removed successfully.', {appearance: 'success'});
      },
      failure: (error) => {
        addToast(error.toString() || 'Team member removal failed.', {appearance: 'error'});
      }
    }));
  };
  return <>
    <PayvyIconButton
      Icon={BsFillTrashFill}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Remove Team Member'}
      type={'a'}
      iconSize={16}
      iconPaddingX={0.5}
      iconPaddingY={0.5}
      mainColor={'none'}
      borderColor={'none'}
      iconColor={'neutral-500'}
      hoverTextColor={'red-300'}
    />
    <ModalPayvyBase
      title={`Removing team member`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, remove team member'}
      confirmButtonAction={confirmRemoveMember}
      confirmButtonLoading={processing}
      dismissButtonText={'No, keep it'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to remove <b>@{member.username}</b> team member?</p>
    </ModalPayvyBase>
  </>;
};
