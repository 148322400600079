import React, {useState} from "react";
import PhoneInput from "react-phone-number-input";
import {
  PayvyIconButton,
  PayvyIconButtonGroup,
  PayvyInput,
  PayvyLabeledInput,
  PayvySelectComponent
} from "../../../comps/forms";
import {USStatesWithLabel} from "../../../utils/USStates";
import {zipCodeInfo} from "../../../utils/Utility";

export const ProcessConfirmYourInformation = ({
  increaseStepCount,
  formProps,
}) => {
  const [zipProcessing, setZipProcessing] = useState(false);
  const [queryTermTimeout, setQueryTermTimeout] = useState(0);
  const queryZip = async(value) => {
    setZipProcessing(true);
    const {
      city = '',
      state = '',
    } = await zipCodeInfo(value) || {};
    await formProps.setFieldValue('addressState', state);
    await formProps.setFieldValue('addressCity', city);
    setZipProcessing(false);
  };
  return <>
    <h2 className={'text-2xl font-medium p-2 pb-12'}>Basics - Confirm your information</h2>
    <div className={'w-full gap-5'}>
      <PayvyLabeledInput
        label={'Name'}
        as={PayvyInput}
        name={'name'}
      />
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'First name'}
        as={PayvyInput}
        name={'firstName'}
      />
      <PayvyLabeledInput
        label={'Last name'}
        as={PayvyInput}
        name={'lastName'}
      />
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'Username'}
        as={PayvyInput}
        name={'userName'}
      />
      <PayvyLabeledInput
        label={'Password'}
        as={PayvyInput}
        name={'password'}
        type={'password'}
      />
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'Vendor Type'}
        name={'customerType'}
        alwaysLabel={true}
      >
        <PayvyIconButtonGroup
          value={formProps.values.customerType}
          onChange={(value) => formProps.setFieldValue('customerType', value)}
          choices={[
            {
              label: 'Company',
              value: 'business',
            }, {
              label: 'Individual',
              value: 'individual',
            },
          ]}/>
      </PayvyLabeledInput>
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'Email address'}
        as={PayvyInput}
        name={'email'}
      />
      <PayvyLabeledInput
        label={'Phone number'}
        name={'phone'}
      >
        <PhoneInput
          international={true}
          defaultCountry="US"
          placeholder="Enter phone number"
          value={formProps.values.phone}
          onChange={(value) => formProps.setFieldValue('phone', value)}
        />
      </PayvyLabeledInput>
    </div>
    <div className={'w-full grid grid-cols-2 gap-5'}>
      <PayvyLabeledInput
        label={'Address'}
        name={'addressLine1'}
        as={PayvyInput}
      />
      <PayvyLabeledInput
        label={'Address Line 2'}
        name={'addressLine2'}
        as={PayvyInput}
      />
    </div>
    <div className={'flex flex-row gap-2'}>
      <div className={'flex-inline w-3/12'}>
        <PayvyLabeledInput
          label={'Zip Code'}
          name={'addressZip'}
          as={PayvyInput}
          disabled={zipProcessing}
          onChange={(value) => {
            if(queryTermTimeout) clearTimeout(queryTermTimeout);
            formProps.setFieldValue('addressZip', value);
            setQueryTermTimeout(setTimeout(async() => {
              await queryZip(value);
            }, 1000));
          }}
        />
      </div>
      <div className={'flex-inline w-4/12'}>
        <PayvyLabeledInput
          label={'City'}
          name={'addressCity'}
          loading={zipProcessing}
          disabled={zipProcessing}
          as={PayvyInput}
        />
      </div>
      <div className={'flex-inline w-5/12'}>
        <PayvyLabeledInput
          label={'State'}
          component={PayvySelectComponent}
          name={'addressState'}
          options={USStatesWithLabel}
          alwaysLabel={true}
        />
      </div>
    </div>
    <PayvyIconButton
      buttonText={'Next'}
      wrapperClassName={'mt-10 flex w-full justify-end'}
      onClick={() => {
        formProps.setFieldTouched('userName');
        formProps.setFieldTouched('lastName');
        formProps.setFieldTouched('firstName');
        formProps.setFieldTouched('password');
        formProps.setFieldTouched('customerType');
        formProps.setFieldTouched('vendor1099');
        formProps.setFieldTouched('addressLine1');
        formProps.setFieldTouched('addressCity');
        formProps.setFieldTouched('addressState');
        formProps.setFieldTouched('addressZip');
        if(!formProps.errors.addressCity && !formProps.errors.addressState && !formProps.errors.addressZip && !formProps.errors.vendor1099 && !formProps.errors.customerType && !formProps.errors.password) {
          increaseStepCount();
        }
      }}
      type={'submit'}
    />
  </>;
};
export default ProcessConfirmYourInformation;
