import {InputMask} from "@react-input/mask";
import {Formik} from 'formik';
import {noop} from 'lodash';
import moment from 'moment';
import React, {useRef, useState} from 'react';
import {FaPencilAlt} from 'react-icons/fa';
import {MdAddCircle} from 'react-icons/md';
import PhoneInput from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  PayvySelectComponent
} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES, REPRESENTATIVE_RELATIONSHIPS} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {
  addOrUpdateCompanyRepresentative,
  companiesSelector,
  getCompanyDetails,
  removeCompanyRepresentative
} from '../../../slices/companies';
import {getUser} from '../../../slices/user';
import {USStatesWithLabel} from '../../../utils/USStates';
import {handleErrors, zipCodeInfo} from '../../../utils/Utility';

export const ModalOwnerVerification = ({
  companyId = 0,
  id = 0,
  editMode = false,
  relationshipOption = '',
  representative = false,
  firstName = '',
  lastName = '',
  email = '',
  jobTitle = '',
  dateOfBirth = '',
  addressZip = '',
  addressCity = '',
  addressState = '',
  addressLine1 = '',
  addressLine2 = '',
  phone = '',
  ssn = '',
  open = false,
  fields = [],
  refreshInfo,
}) => {
  refreshInfo = refreshInfo || noop;
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const [modalOpen, setModalOpen] = useState(open);
  const {
    processing,
    loading,
  } = useSelector(companiesSelector);
  const ownerVerificationModalForm = React.createRef();
  const [zipProcessing, setZipProcessing] = useState(false);
  const [queryTermTimeout, setQueryTermTimeout] = React.useState(0);

  const removeRepresentatives = () => {
    dispatch(removeCompanyRepresentative({
      companyId,
      companyRepresentativeId: id,
      success: () => {
        setModalOpen(false);
        refreshInfo();
      }
    }));
  };
  const queryZip = async(value, props) => {
    setZipProcessing(true);
    const {
      city = '',
      state = '',
    } = await zipCodeInfo(value) || {};
    props.setFieldValue('addressState', state);
    props.setFieldValue('addressCity', city);
    setZipProcessing(false);
  };

  const showDateOfBirth = fields.includes('date_of_birth') || !dateOfBirth;
  const showAddress = true;
  const showPhone = true;
  const showSSN = fields.includes('id_number') || !ssn;
  return <>
    <PayvyIconButton
      buttonText={editMode ? undefined : 'Add Owner or Representative'}
      Icon={editMode ? FaPencilAlt : MdAddCircle}
      onClick={() => setModalOpen(true)}
    />
    <ModalPayvyBase
      title={editMode ? 'Edit Individual' : 'Creating Owner or Representative'}
      isOpen={modalOpen}
      confirmButtonText={editMode ? 'Update Person' : 'Add Person'}
      confirmButtonAction={() => {
        ownerVerificationModalForm.current.click()
      }}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
      optionalButtonText={editMode ? 'Remove Person' : undefined}
      optionalButtonAction={editMode ? removeRepresentatives : undefined}
      optionalButtonLoading={processing || loading}
      optionalButtonDisabled={processing || loading || (editMode && representative)}
      optionalButtonHelp={editMode && representative ? 'A company representative cannot be removed.' : undefined}
      position={'top-1/5 left-1/3'}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          relationshipOption: relationshipOption || '',
          firstName: firstName || '',
          lastName: lastName || '',
          email: email || '',
          jobTitle: jobTitle || '',
          dateOfBirth: !!dateOfBirth ? moment(dateOfBirth)
          .format('MM / DD / YYYY') : '',
          addressZip: addressZip || '',
          addressCity: addressCity || '',
          addressState: addressState || '',
          addressLine1: addressLine1 || '',
          addressLine2: addressLine2 || '',
          phone: phone || '',
          ssn: '',
        }}
        validationSchema={Yup.object({
          relationshipOption: Yup.string()
                                 .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          firstName: Yup.string()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          lastName: Yup.string()
                       .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          email: Yup.string()
                    .email()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          jobTitle: Yup.string()
                       .when(['relationshipOption'], {
                         is: (relationshipOption = '') => relationshipOption !== 'owner',
                         then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                       }),
          dateOfBirth: Yup.string()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          addressZip: Yup.string()
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          addressCity: Yup.string()
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          addressState: Yup.string()
                           .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          addressLine1: Yup.string()
                           .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          addressLine2: Yup.string(),
          phone: Yup.string()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          ssn: Yup.string()
                  .when(['relationshipOption'], {
                    is: () => !ssn,
                    then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                  }),
        })}
        onSubmit={(values, helpers) => {
          let formattedDoB = '';
          if(values.dateOfBirth) formattedDoB = moment(values.dateOfBirth, 'MM / DD / YYYY')
          .format('YYYY-MM-DD');
          const cleanedSSN = values.ssn.replaceAll(' ', '');
          dispatch(addOrUpdateCompanyRepresentative({
            companyId,
            representativeId: id,
            relationship: values.relationshipOption,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            job_title: values.jobTitle,
            date_of_birth: formattedDoB,
            line1: values.addressLine1,
            city: values.addressCity,
            state: values.addressState,
            zip: values.addressZip,
            phone: values.phone,
            ssn: cleanedSSN,
            success: () => {
              refreshInfo();
              setModalOpen(false);
              dispatch(getUser({
                success: () => {
                  dispatch(getCompanyDetails({id: companyId}));
                  helpers.resetForm();
                }
              }));
            },
            failure: (data) => {
              handleErrors(data, helpers);
            }
          }));

        }}
      >
        {props => {
          const showJobTitle = props.values.relationshipOption !== 'owner' || fields.includes('relationship.title') || !!jobTitle;
          return <>
            <div className={'flex flex-row gap-2'}>
              <PayvyLabeledInput
                label={'Individual relationship to company'}
                name={'relationshipOption'}
                component={PayvySelectComponent}
                options={REPRESENTATIVE_RELATIONSHIPS}
                placeholder={'Relationship'}
                alwaysLabel={true}
              />
            </div>
            <div className={'flex flex-row gap-2'}>
              <div className={'flex-inline w-1/2'}>
                <PayvyLabeledInput
                  label={'Legal first name'}
                  name={'firstName'}
                  as={PayvyInput}
                />
              </div>
              <div className={'flex-inline w-1/2'}>
                <PayvyLabeledInput
                  label={'Legal last name'}
                  name={'lastName'}
                  as={PayvyInput}
                />
              </div>
            </div>
            <div className={'flex flex-row gap-2'}>
              <PayvyLabeledInput
                label={'Email address'}
                name={'email'}
                type={'email'}
                as={PayvyInput}
              />
            </div>
            {showJobTitle && <div className={'flex flex-row gap-2'}>
              <PayvyLabeledInput
                label={'Job title'}
                name={'jobTitle'}
                as={PayvyInput}
              />
            </div>}
            {showDateOfBirth && <div className={'flex flex-row gap-2'}>
              <PayvyLabeledInput
                label={'Date of Birth'}
                name={'dateOfBirth'}
                alwaysLabel={true}
              >
                <PayvyDatePicker
                  value={props.values.dateOfBirth}
                  name={'dateOfBirth'}
                  onChange={(dateOfBirth) => props.setFieldValue('dateOfBirth', dateOfBirth)}
                  clearIcon={null}
                  placeholder={'Date of Birth'}
                />
              </PayvyLabeledInput>
            </div>}
            {showAddress && <>
              <div className={'flex flex-row gap-2'}>
                <PayvyLabeledInput
                  label={'Address'}
                  name={'addressLine1'}
                  as={PayvyInput}
                />
                <PayvyLabeledInput
                  label={'Address Line 2'}
                  name={'addressLine2'}
                  as={PayvyInput}
                />
              </div>
              <div className={'flex flex-row gap-2'}>
                <div className={'flex-inline w-3/12'}>
                  <PayvyLabeledInput
                    label={'Zip Code'}
                    name={'addressZip'}
                    as={PayvyInput}
                    disabled={zipProcessing}
                    onChange={(value) => {
                      if(queryTermTimeout) clearTimeout(queryTermTimeout);
                      props.setFieldValue('addressZip', value);
                      setQueryTermTimeout(setTimeout(async() => {
                        await queryZip(value, props);
                      }, 1000));
                    }}
                  />
                </div>
                <div className={'flex-inline w-4/12'}>
                  <PayvyLabeledInput
                    label={'City'}
                    name={'addressCity'}
                    loading={zipProcessing}
                    disabled={zipProcessing}
                    as={PayvyInput}
                  />
                </div>
                <div className={'flex-inline w-5/12'}>
                  <PayvyLabeledInput
                    label={'State'}
                    component={PayvySelectComponent}
                    name={'addressState'}
                    options={USStatesWithLabel}
                    alwaysLabel={true}
                  />
                </div>
              </div>
            </>}
            {(showPhone || showSSN) && <div className={'flex flex-row gap-2'}>
              {showPhone && <div className={'flex-inline w-1/2'}>
                <PayvyLabeledInput
                  label={'Phone number'}
                  name={'phone'}
                >
                  <PhoneInput
                    international={true}
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={props.values.phone}
                    onChange={(value) => props.setFieldValue('phone', value)}
                  />
                </PayvyLabeledInput>
              </div>}
              {showSSN && <div className={'flex-inline w-1/2'}>
                <PayvyLabeledInput
                  label={`Social Security Number`}
                  name={'ssn'}
                  alwaysLabel={true}
                >
                  <InputMask
                    mask="___ __ ____"
                    replacement={{_: /\d/}}
                    value={props.values.ssn}
                    placeholder={ssn ? 'keep empty if no change is required' : ''}
                    onChange={({target}) => props.setFieldValue('ssn', target.value)}
                    className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                  />
                </PayvyLabeledInput>
              </div>}
            </div>}
            <GlobalFormError errors={props.errors['nonFieldErrors']}/>
            <input type={'hidden'} ref={ownerVerificationModalForm} onClick={() => {
              props.handleSubmit()
            }}/>
          </>;
        }}
      </Formik>
    </ModalPayvyBase>
  </>;
};
