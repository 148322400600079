import React, {useCallback, useState} from "react";
import {BiExpandVertical} from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";
import {companiesSelector, getCompanyDetails, switchCompanies} from "../../../../slices/companies";
import {resetBillToInitialState} from "../../../../slices/newBill";
import {resetBoxToInitialState} from "../../../../slices/newBox";
import {resetContactToInitialState} from "../../../../slices/newContact";
import {resetDropboxToInitialState} from "../../../../slices/newDropbox";
import {resetInboxToInitialState} from "../../../../slices/newInbox";
import {resetIntegrationToInitialState} from "../../../../slices/newIntegration";
import {resetQuickbooksToInitialState} from "../../../../slices/newQuickbooks";
import {resetTransactionToInitialState} from "../../../../slices/newTransaction";
import {DarkFakeLetterAvatar} from "./DarkFakeLetterAvatar";
import {LightFakeLetterAvatar} from "./LightFakeLetterAvatar";

export const CompanySwitcher = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const {
    company,
    companies,
    loading,
  } = useSelector(companiesSelector);

  const planName = company?.active_plan?.name || 'Free';
  const companyName = company?.name || 'Missing Name';
  const switchCompany = useCallback((companyId) => {
    dispatch(switchCompanies({
      companyId,
      success: () => {
        dispatch(resetInboxToInitialState());
        dispatch(resetBillToInitialState());
        dispatch(resetContactToInitialState());
        dispatch(resetBoxToInitialState());
        dispatch(resetDropboxToInitialState());
        dispatch(resetIntegrationToInitialState());
        dispatch(resetQuickbooksToInitialState());
        dispatch(resetTransactionToInitialState());
        dispatch(getCompanyDetails({id: companyId}));
      }
    }));
  }, [dispatch]);
  if(loading) return <div className={'w-full'}>
    <div className={'my-2 mx-4 py-0.5 px-1 bg-slate-50 gap-2 rounded-lg border border-slate-300 cursor-pointer'}>
      <div className={'flex flex-row'}>
        <div className={'flex flex-col justify-center'}>
          <DarkFakeLetterAvatar letter={''}/>
        </div>
        <div className={'flex flex-col grow pl-2  justify-center items-center'}>
          <div className={'text-sm font-normal'}>
            Loading...
          </div>
        </div>
      </div>
    </div>
  </div>
  return <div className={'w-full'}>
    <div className={'my-2 mx-4 py-0.5 px-1 bg-slate-50 gap-2 rounded-lg border border-slate-300 cursor-pointer'}
         onClick={() => setOpen(!open)}>
      <div className={'flex flex-row'}>
        <div className={'flex flex-col justify-center'}>
          <DarkFakeLetterAvatar letter={companyName[0]}/>
        </div>
        <div className={'flex flex-col grow pl-2'}>
          <div className={'text-sm font-normal'}>
            {companyName}
          </div>
          <div className={'text-xs text-slate-700 font-light'}>
            {planName}
          </div>
        </div>
        <div className={'flex flex-col items-center justify-center'}>
          <BiExpandVertical size={'16'}/>
        </div>
      </div>
    </div>
    {/* floating list of companies */}
    <div className={`absolute top-15 left-0 w-full z-10 ${open ? 'block' : 'hidden'}`}>
      <div className={'flex flex-col bg-neutral-0 rounded border border-slate-200 mx-3 p-2 shadow-xl'}>
        {companies.map((subCompany, index) => {
          return <div key={index}
                      className={'flex flex-row p-1 bg-neutral-0 hover:bg-slate-100 cursor-pointer select-none'}
                      onClick={() => switchCompany(subCompany.id)}>
            <div className={'py-2 flex flex-col justify-center'}>
              <LightFakeLetterAvatar letter={subCompany.name[0]}/>
            </div>
            <div className={'flex flex-col grow pl-2 text-sm font-normal justify-center'}>
              {subCompany.name}
            </div>
          </div>
        })}
      </div>
    </div>
  </div>
}
