import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {companiesSelector, getCompanyRepresentatives} from '../../../slices/companies';
import {ModalOwnerVerification} from './ModalOwnerVerification';
import {ModalOwnerVerificationDocument} from './ModalOwnerVerificationDocument';
import {ModalVerificationDwollaDocument} from './ModalVerificationDwollaDocument';
import {RepresentativeName} from "./RepresentativeName";

export const OwnerVerification = ({companyId}) => {
  const dispatch = useDispatch();
  const {
    representatives,
  } = useSelector(companiesSelector);
  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => {
    _setRefreshInfo(new Date());
  };
  useEffect(() => {
    dispatch(getCompanyRepresentatives({id: companyId}));
  }, [dispatch, companyId, refreshInfo]);
  return <div className={'p-0 m-0'}>
    <div className={'flex flex-col mb-2'}>
      {representatives.map(representative => {
        const {
          id,
          relationship,
          representative: rep = false,
          first_name: firstName = '',
          last_name: lastName = '',
          email,
          job_title: jobTitle = '',
          date_of_birth: dateOfBirth,
          address,
          phone,
          ssn,
          stripe_status: status,
          dwolla_status: dwollaStatus,
          dwolla_error: dwollaError,
          problem_fields: problems = [],
        } = representative;
        const {
          line1,
          city,
          state,
          zip,
        } = address || {};
        return <div className={'w-full flex flex-row rounded border border-neutral-100 shadow-sm bg-neutral-0 p-2'}
                    key={`${id}`}>
          <div className={'flex flex-col grow'}>
            <RepresentativeName
              name={`${firstName} ${lastName}`}
              status={status}
              problems={problems}
              dwollaStatus={dwollaStatus}
              dwollaError={dwollaError}
            />
            <span
              className="text-neutral-700 text-sm">{relationship === 'owner' ? 'Owner' : jobTitle}{rep ? ' / Representative' : ''}</span>
          </div>
          <div className={'flex flex-row'}>
            {'verification.document' in problems ? <div className={'button'}>
              <ModalOwnerVerificationDocument
                companyId={companyId}
                id={id}
                refreshInfo={setRefreshInfo}
              />
            </div> : null}
            {dwollaStatus === 'document' ? <div className={'button'}>
              <ModalVerificationDwollaDocument
                companyId={companyId}
                id={id}
                refreshInfo={setRefreshInfo}
              />
            </div> : null}
            <div className={'button'}>
              <ModalOwnerVerification
                companyId={companyId}
                id={id}
                representative={rep}
                editMode={true}
                refreshInfo={setRefreshInfo}
                relationshipOption={relationship}
                firstName={firstName}
                lastName={lastName}
                email={email}
                jobTitle={jobTitle}
                dateOfBirth={dateOfBirth}
                addressLine1={line1}
                addressCity={city}
                addressState={state}
                addressZip={zip}
                phone={phone}
                ssn={ssn}
                fields={Object.keys(problems)}
              />
            </div>
          </div>
        </div>;
      })}
    </div>
    <div className={'add-representative'}>
      <ModalOwnerVerification companyId={companyId} editMode={false} refreshInfo={setRefreshInfo}/>
    </div>
  </div>;
};
