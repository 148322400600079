import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {PAYVY_LOGOS, PAYVY_URL} from '../../constants';
import {companiesSelector, switchCompanies} from '../../slices/companies';
import {LoggedInNotYou} from './components/LoggedInNotYou';


const SwitchCompanyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    firstTime,
    loading,
    companies,
  } = useSelector(companiesSelector);
  if(companies.length === 0 && firstTime && !loading) navigate(PAYVY_URL.COMPANY_CREATE);
  const switchCompany = (id) => dispatch(switchCompanies({companyId: id}));
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div
        className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md items-center'}>
        <LoggedInNotYou/>
        <h3>Please select a company:</h3>
        <div className={'grid grid-cols-2 mt-4 gap-2 w-full'}>
          {companies.map(company => <div
            className={'flex justify-center py-2 bg-neutral-0 cursor-pointer border border-red-300 hover:bg-red-200 hover:text-neutral-0 rounded-lg'}
            key={company.id}
            onClick={() => switchCompany(company.id)}
          >
            {company.name}
          </div>)}
        </div>
      </div>
    </div>
  </div>
};

export default SwitchCompanyPage;
