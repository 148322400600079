import React from 'react';
import {PAYVY_LOGOS} from "../../../constants";

export const LedgerIcons = ({
  ledgerInfo = []
}) => {
  const IconMap = {
    'quickbooks': PAYVY_LOGOS.QBO
  }
  return <div className={'grow justify-items-end'}>
    {ledgerInfo.map((info, index) => {
      return <img alt={info.ledger_id} src={IconMap[info.ledger]} className={'w-5 h-5 mx-1'} key={index}
                  title={info.ledger_id}/>
    })}
  </div>;
};
