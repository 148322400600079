import {noop} from "lodash";
import React from "react";
import {PayvyToggleCheckbox} from "../../../comps/forms/";

export const NotificationTableRow = ({
  title,
  checked = {
    email: false,
    sms: false,
    push: false,
  },
  onChange = {
    email: noop,
    sms: noop,
    push: noop,
  }
}) => {
  return <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
    <td className={'py-1 px-4'}>{title}</td>
    <td>
      <PayvyToggleCheckbox
        checked={checked.email}
        onChange={() => onChange.email(!checked.email)}
        disabled={true}
        tooltipContent={'Not yet implemented'}
      />
    </td>
    <td>
      <PayvyToggleCheckbox
        checked={checked.sms}
        onChange={() => onChange.sms(!checked.sms)}
        disabled={true}
        tooltipContent={'Not yet implemented'}
      />
    </td>
    <td>
      <PayvyToggleCheckbox
        checked={checked.push}
        onChange={() => onChange.push(!checked.push)}
        disabled={true}
        tooltipContent={'Not yet implemented'}
      />
    </td>
  </tr>
}
