import {createSlice} from '@reduxjs/toolkit';
import {PAYVY_API} from '../constants';
import Debugger from "../utils/Debug";
import {build_url} from '../utils/Utility';
import {refreshAccessToken} from "./api";

export const initialState = {
  loading: false,
  processing: false,
  hasErrors: false,
  ocrData: {},
  suggestions: {
    status: undefined,
    contact: undefined,
    invoice_amount: undefined,
    invoice_date: undefined,
    invoice_number: undefined,
    payment_due_date: undefined,
  },
};

const ocrSlice = createSlice({
  name: 'ocr',
  initialState,
  reducers: {
    startProcessing: (state) => {
      state.processing = true;
    },
    finishProcessing: (state) => {
      state.processing = false;
    },
    getOCRStart: (state) => {
      state.suggestions = initialState.suggestions;
      state.loading = true;
    },
    getOCRReset: (state) => {
      state.suggestions = initialState.suggestions;
    },
    getOCRSuccess: (state, {payload}) => {
      state.suggestions = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getOCRDataSuccess: (state, {payload}) => {
      const {
        id: inboxItemId,
        data
      } = payload;
      const currentData = JSON.stringify(state.ocrData[inboxItemId]);
      if(currentData !== JSON.stringify(data)) {
        state.ocrData[inboxItemId] = data;
      }
      state.loading = false;
      state.hasErrors = false;
    },
    getOCRFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  startProcessing,
  finishProcessing,
  getOCRStart,
  getOCRFailure,
  getOCRSuccess,
  getOCRDataSuccess,
  getOCRReset
} = ocrSlice.actions;
export const ocrSelector = (state) => state.ocr;
export default ocrSlice.reducer;

export function resetOCR() {
  return async(dispatch) => {
    dispatch(getOCRReset());
  };
}

export function getOCRData({
  ocrData,
  id
}) {
  const item = ocrData[id];
  return item && item.length > 0 ? item[0] : initialState.suggestions;
}


export function getOCRForInbox({id}) {
  return async(dispatch) => {
    dispatch(getOCRStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.OCR.OCR_PAGE_LIST, {id}), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getOCRDataSuccess({
          id,
          data
        }));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getOCRForInbox({id}));
          }
        }
        dispatch(getOCRFailure());
      }
    } catch(error) {
      dispatch(getOCRFailure());
    }
  };
}

export function getOCR({
  id,
  page
}) {
  if(!id || !page) {
    Debugger.info('📡 API: OCR', `OCR id or page is missing. id: ${id}, page: ${page} `);
    return;
  }
  Debugger.info('📡 API: OCR', `OCR id: ${id}, page: ${page} `)
  return async(dispatch) => {
    dispatch(getOCRStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.OCR.OCR_PAGE_DETAILS, {
        id,
        page
      }), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getOCRSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getOCR({
              id,
              page
            }));
          }
        }
        dispatch(getOCRFailure());
      }
    } catch(error) {
      dispatch(getOCRFailure());
    }
  };
}

export function updateOCRField({
  id,
  field_id,
  value,
  successCallback,
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.OCR.UPDATE_OCR_FIELD, {
        id,
        field_id
      }), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({value}),
      });
      if(response.status === 202) {
        successCallback()
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(updateOCRField({
              id,
              field_id,
              value,
              successCallback,
            }));
          }
        }
        dispatch(finishProcessing());
      }
    } catch(error) {
      dispatch(finishProcessing());
    }
  };
}
