import React from 'react';
import {IoClose} from 'react-icons/io5';
import {useSelector} from 'react-redux';
import {companiesSelector} from '../../../../slices/companies';
import {CompanySwitcher} from "./CompanySwitcher";
import {PayvySidebarItems} from './PayvySidebarItems';
import {UserDropdown} from "./UserDropdown";

export const PayvySidebar = ({
  sidebarOpen,
  setSidebarOpen,
  setChatOpen,
  minimized,
  setMinimized,
  ...props
}) => {
  const {company: {id: companyId} = {}} = useSelector(companiesSelector);
  if(!companyId) return null;
  return <div
    className={`top-0 flex flex-col transition-all border-r border-r-slate-200 duration-1000 bg-neutral-0 absolute sm:relative h-screen z-10 ease-in-out ${sidebarOpen ? 'left-0' : 'sm:left-0'}`}
    {...props}
  >
    <div className={'flex sm:hidden w-full justify-end py-6 px-2'}>
      <IoClose onClick={() => setSidebarOpen(false)} className={'text-2xl cursor-pointer'}/>
    </div>
    {!minimized && <div className={'flex'}>
      <CompanySwitcher/>
    </div>}
    <div className={'flex flex-col grow'}>
      <PayvySidebarItems setChatOpen={setChatOpen} minimized={minimized}/>
    </div>
    {!minimized && <div className={'flex'}>
      <UserDropdown/>
    </div>}
  </div>;
};
