import React from 'react';
import {PayvySubmenu} from "../../../comps/elements";
import {ListBill} from './ListBill';
import {ListIncoming} from './ListIncoming';
import {ListInvoice} from './ListInvoice';
import {ListOutgoing} from './ListOutgoing';

export const ListTab = () => {
  const [activeItem, setActiveItem] = React.useState('incoming');
  return <div className={'my-10 h-144'}>
    <PayvySubmenu
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      options={[
        {
          name: 'Incoming',
          value: 'incoming',
        }, {
          name: 'Invoices',
          value: 'invoices',
        }, {
          name: 'Outgoing',
          value: 'outgoing',
        }, {
          name: 'Bills',
          value: 'bills',
        },
      ]}
    />
    {activeItem === 'incoming' && <ListIncoming/>}
    {activeItem === 'invoices' && <ListInvoice/>}
    {activeItem === 'outgoing' && <ListOutgoing/>}
    {activeItem === 'bills' && <ListBill/>}
  </div>;
};
