import {Formik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import PhoneInput, {formatPhoneNumberIntl} from 'react-phone-number-input';
import {QRCode} from 'react-qr-svg';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {createPhoneTFA, getTFAAppUrl, getUser, removeTFA, setTFAMethod, userSelector} from '../../../slices/user';

export const ModalSetupTFA = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const setupTFAForm = React.createRef();
  const {
    processing,
    loadingTFA: loading,
    user: {
      has_tfa: hasTFA,
    },
    tfaAppUrl,
  } = useSelector(userSelector);
  const urlParams = new URLSearchParams(tfaAppUrl.split('?')[1]);
  const tfaSecret = urlParams.get('secret');
  const [sentSMS, setSentSMS] = useState(false);
  useEffect(() => {
    if(modalOpen) dispatch(getTFAAppUrl());
  }, [dispatch, modalOpen, hasTFA]);

  const removeTFAClick = () => {
    dispatch(removeTFA({
      success: () => {
        dispatch(getUser({}));
      }
    }));
  };
  const createSMSToken = (props) => {
    const number = props.values.phone;
    props.setFieldTouched('phone', true);
    dispatch(createPhoneTFA({
      number: number,
      success: () => {
        setSentSMS(true);
      },
      failure: () => {
        props.setFieldError('phone', 'Invalid phone number');
      }
    }));
  };

  return <>
    <PayvyIconButton
      buttonText={hasTFA ? 'Remove' : 'Set Two Factor Authentication'}
      onClick={hasTFA ? removeTFAClick : () => setModalOpen(true)}
      loading={loading || processing}
      disabled={loading || processing}
      mainColor={'none'}
      borderSize={2}
      borderColor={'slate-900'}
      textColor={'slate-900'}
      hoverTextColor={'slate-700'}
      wrapperClassName={'grow justify-end'}
    />
    <ModalPayvyBase
      title={`Setup two-factor authentication`}
      isOpen={modalOpen}
      topX={false}
      confirmButtonText={'Set Method'}
      confirmButtonAction={() => setupTFAForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          method: 'App',
          phone: '',
          code: '',
        }}
        validationSchema={Yup.object({
          method: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          code: Yup.string()
                   .test('len', 'Must be exactly 6 characters', val => !!val && val.toString().length === 6)
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          const method = values.method;
          const code = values.code;
          dispatch(setTFAMethod({
            method: method,
            code: code,
            success: () => {
              dispatch(getUser({}));
              helpers.resetForm();
              setModalOpen(false);
              addToast('Two factor authentication has been set up successfully.', {appearance: 'success'});
            },
            failure: () => {
              addToast('Two factor authentication failed, invalid code.', {appearance: 'error'});
            }
          }));
        }}
      >
        {props => <>
          <div className={'grid grid-cols-2 gap-2'}>
            <div
              className={`border border-neutral-500 py-1 px-4 text-center ${props.values.method === 'App' ? 'bg-red-200 border-red-800 font-bold' : ''}`}
              onClick={() => props.setFieldValue('method', 'App')}>
              Authenticator App
            </div>
            <div
              className={`border border-neutral-500 py-1 px-4 text-center ${props.values.method === 'Phone' ? 'bg-red-200 border-red-800 font-bold' : ''}`}
              onClick={() => props.setFieldValue('method', 'Phone')}>
              SMS
            </div>
          </div>
          {loading ? <span>Please wait a second until we generate your information</span> : <>
            {props.values.method === 'App' && <div className={'my-1 p-2'}>
              <p>Use Google Authenticator or Authy to scan barcode, and enter verification code.</p>
              <div className={'flex justify-center'}>
                <div>
                  <QRCode
                    bgColor="#FFFFFF"
                    fgColor="#f9585d"
                    style={{width: 128}}
                    value={tfaAppUrl}
                  />
                </div>
              </div>
              <p>or use the key for your authenticator: <b>{tfaSecret}</b></p>
              <div>
                <PayvyLabeledInput
                  label={'Verification Code'}
                  as={PayvyInput}
                  name={'code'}
                  placeholder={'Verification Code'}
                  onChange={(value) => {
                    if(value.length > 6) value = value.substring(0, 6);
                    value = value.replace(/\D/g, '');
                    props.setFieldValue('code', value);
                  }}
                />
              </div>
            </div>}
            {props.values.method === 'Phone' && <div className={'my-1 p-2'}>
              {!sentSMS ?
                <p>Enter your phone number and click on send.</p> :
                <p>Sent an SMS to <b>{formatPhoneNumberIntl(props.values.phone)}</b>, enter the verification
                  code you have received.</p>}
              {!sentSMS ?
                <div>
                  <PayvyLabeledInput
                    label={'Phone number'}
                    name={'phone'}
                  >
                    <div className={'grid grid-cols-5'}>
                      <PhoneInput
                        international={true}
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        value={props.values.phone}
                        onChange={(value) => props.setFieldValue('phone', value)}
                        className={'col-span-4'}
                      />
                      <PayvyIconButton buttonText={'Send'} onClick={() => createSMSToken(props)}/>
                    </div>
                  </PayvyLabeledInput>
                </div> : <PayvyLabeledInput
                  label={'Verification Code'}
                  as={PayvyInput}
                  name={'code'}
                  placeholder={'Verification Code'}
                  onChange={(value) => {
                    if(value.length > 6) value = value.substring(0, 6);
                    value = value.replace(/\D/g, '');
                    props.setFieldValue('code', value);
                  }}
                />}
            </div>}
          </>}
          <button ref={setupTFAForm} type="hidden" onClick={() => props.handleSubmit()}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
