import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {PayvyPageHeader, PayvySubmenu} from "../../comps/elements";
import {PAYVY_URL} from "../../constants";
import {useIsEverythingLoaded} from '../../hooks/useIsEverythingLoaded';
import {companiesSelector} from '../../slices/companies';
import {
  getUserBills,
  setBillCurrentPage,
  setBillFromDateStr,
  setBillOrderBy,
  setBillPageSize,
  setBillSearchQuery,
  setBillStatus,
  setBillToDateStr,
  setFilterAmountStr,
  setFilterContact
} from '../../slices/newBill';
import {getContactDetails, getContactOverview} from '../../slices/newContact';
import {getLedgerInformation} from "../../slices/newIntegration";
import {getInvoicesList, receivablesSelector} from '../../slices/receivables';
import FullPageLoader from "../FullPageLoader/FullPageLoader";
import {Logs} from './components/Logs';
import {Overview} from './components/Overview';
import {Statement} from './components/Statement';
import {Transaction} from './components/Transaction';

const ContactDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingInfo = useIsEverythingLoaded(['contact']);
  const {id: contactId} = useParams();
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};
  const {items} = useSelector((state) => state.contact);
  const contact = Object.values(items)
                        .flat()
                        .find(item => parseInt(`${item.id}`) === parseInt(contactId))
  const {categorizedInvoiceList = {}} = useSelector(receivablesSelector);
  const {
    displayItems,
    status,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const invoiceList = categorizedInvoiceList['all']?.results || [];
  const [activeItem, setActiveItem] = useState('overview');
  useEffect(() => {
    dispatch(setBillCurrentPage(1))
    dispatch(setBillPageSize(10))
    dispatch(setBillStatus('draft,awaiting payment,awaiting approval'))
    dispatch(setBillOrderBy('-created'))
    dispatch(setFilterAmountStr(''))
    dispatch(setFilterContact(contactId))
    dispatch(setBillSearchQuery(''))
    dispatch(setBillFromDateStr(''))
    dispatch(setBillToDateStr(''))
  }, [dispatch, contactId]);
  useEffect(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    status,
    searchQuery
  ]);
  useEffect(() => {
      dispatch(getContactDetails({
        id: contactId,
        failure: () => navigate(PAYVY_URL.CONTACTS.LIST)
      }));
      dispatch(getContactOverview({id: contactId}));
      dispatch(getInvoicesList({
        page_size: 5,
        contact: contactId,
        order_by: '-invoice_date',
        category: 'all',
      }));
      dispatch(getLedgerInformation({}))
    },
    [dispatch, contactId, companyId, navigate]
  );
  if(loadingInfo.contact) return <FullPageLoader/>;
  return <>
    <div className="max-w-full border-b-2 border-gray-200">
      <PayvyPageHeader parents={[
        {
          name: 'Contacts',
          href: PAYVY_URL.CONTACTS.LIST
        }
      ]}>{contact?.name}</PayvyPageHeader>
      <PayvySubmenu
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        options={[
          {
            name: 'Overview',
            value: 'overview',
          }, {
            name: 'Transactions',
            value: 'transactions',
          }, {
            name: 'Logs',
            value: 'logs',
          }, {
            name: 'Statement',
            value: 'statement',
          },
        ]}
      />
    </div>
    <div className="max-w-full bg-neutral-50 m-0 p-0 pb-10">
      {activeItem === 'overview' &&
        <Overview
          contact={contact}
          overview={contact?.overview}
          invoices={invoiceList}
          bills={displayItems}
        />}
      {activeItem === 'transactions' && <Transaction contact={contact}/>}
      {activeItem === 'logs' && <Logs contact={contact}/>}
      {activeItem === 'statement' && <Statement/>}
    </div>
  </>;
};

export default ContactDetailPage;
