import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";

export const RedirectToDashboardRoute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(PAYVY_URL.DASHBOARD, {replace: true})
  }, [navigate]);
  return <></>
};
