import React from 'react';
import {FaTrash} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";
import {getLedgerInformation, ledgerUnlinkContact} from "../../../slices/newIntegration";
import {payvyToast} from "../../../utils/Utility";
import {ModalLinkToLedger} from "./ModalLinkToLedger";
import {ModuleBlock} from './ModuleBlock';

export const ModuleGeneralLedgerLinkedAccount = ({
  contactId,
  ledger
}) => {
  const {loading: {ledger: loading}} = useSelector((state) => state.integration)
  const dispatch = useDispatch();
  const contact = ledger.contacts.find((contact) => contact.payvy_id === contactId);
  const subtitle = contact ? `Linked to ${contact?.name}` : 'No linked contact';
  return <div className={'w-full'}>
    <div className={'grid grid-cols-12 bg-neutral-50 border border-neutral-100 px-5 py-2'}>
      <div className={'col-span-11 flex flex-col'}>
        <div className={'text-neutral-700 font-bold'}>{ledger?.name}</div>
        <div className={'text-neutral-700'}>{subtitle}</div>
      </div>
      <div className={'col-span-1 flex flex-col'}>
        {contact ? <PayvyIconButton
          tooltip={'Unlink contact'}
          Icon={FaTrash}
          wrapperClassName={'h-full w-full justify-end'}
          loading={loading}
          onClick={() => {
            dispatch(ledgerUnlinkContact({
              body: {
                ledger: ledger.codename,
                ledger_id: contact.ledger_id,
              },
              successCallback: () => {
                dispatch(getLedgerInformation({forceUpdate: true}))
              },
              errorCallback: () => {
                payvyToast('Failed to unlink contact.', {appearance: 'error'})
              }
            }));
          }}
        /> : <ModalLinkToLedger contactId={contactId} ledger={ledger}/>}
      </div>
    </div>
  </div>;
}

export const ModuleGeneralLedger = ({
  contactId,
}) => {
  const navigate = useNavigate();
  const {ledgers} = useSelector((state) => state.integration)
  const ledgersEnabled = ledgers.filter((ledger) => ledger?.enabled === true);
  return <ModuleBlock
    big={true}
    title={'General Ledger'}
    buttons={[]}
  >
    {ledgersEnabled.map((ledger, index) => <ModuleGeneralLedgerLinkedAccount
      key={index}
      contactId={contactId}
      ledger={ledger}
    />)}
    {ledgersEnabled?.length === 0 ?
      <span className={'font-light'}>
        No enabled ledger services. You can enable ledger services in
                      <PayvyIconButton
                        type={'button'}
                        onClick={() => navigate(PAYVY_URL.SETTINGS.INTEGRATIONS)}
                        iconPaddingX={1}
                        iconPaddingY={0}
                        buttonText={'Integration Settings'}
                        mainColor={'none'}
                        borderColor={'none'}
                        textColor={'red-200'}
                        hoverTextColor={'red-300'}
                        wrapperClassName={'whitespace-nowrap'}
                      />
      </span> : null}
  </ModuleBlock>;
};
