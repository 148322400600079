import {Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {changeUserAddress, getUser, userSelector} from '../../../slices/user';
import {handleErrors, zipCodeInfo} from '../../../utils/Utility';

export const ModalChangeAddress = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const {user: {address} = {}} = useSelector(userSelector);
  const {
    line1 = '',
    line2 = '',
    zip = '',
    state = '',
    city = '',
  } = address || {};
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading,
    processing,
  } = useSelector(userSelector);
  const [zipProcessing, setZipProcessing] = useState(false);
  const [queryTermTimeout, setQueryTermTimeout] = useState(0);
  const changeAddressForm = React.createRef();

  const queryZip = async(value, props) => {
    setZipProcessing(true);
    const {
      city = '',
      state = '',
    } = await zipCodeInfo(value) || {};
    props.setFieldValue('addressState', state);
    props.setFieldValue('addressCity', city);
    setZipProcessing(false);
  };
  const handleZip = (value, props) => {
    if(queryTermTimeout) clearTimeout(queryTermTimeout);
    props.setFieldValue('addressZip', value);
    setQueryTermTimeout(setTimeout(async() => {
      await queryZip(value, props);
    }, 1000));
  };
  return <>
    <PayvyIconButton
      buttonText={'Change Address'}
      onClick={() => setModalOpen(true)}
      mainColor={'none'}
      iconPaddingY={1}
      borderSize={2}
      borderColor={'slate-900'}
      textColor={'slate-900'}
      hoverTextColor={'slate-700'}
      wrapperClassName={'grow justify-end'}
    />
    <ModalPayvyBase
      title={`Changing Address`}
      isOpen={modalOpen}
      confirmButtonText={'Change address'}
      confirmButtonAction={() => changeAddressForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'No, do not change'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          addressZip: zip || '',
          addressCity: city || '',
          addressState: state || '',
          addressLine1: line1 || '',
          addressLine2: line2 || '',
        }}
        validationSchema={Yup.object({
          addressZip: Yup.string(),
          addressCity: Yup.string()
                          .when(['addressZip', 'addressLine1'], {
                            is: (
                              addressZip = '',
                              addressLine1 = ''
                            ) => addressZip.length > 0 || addressLine1.length > 0,
                            then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                          }),
          addressState: Yup.string()
                           .when(['addressZip', 'addressLine1'], {
                             is: (
                               addressZip = '',
                               addressLine1 = ''
                             ) => addressZip.length > 0 || addressLine1.length > 0,
                             then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                           }),
          addressLine1: Yup.string()
                           .when('addressZip', {
                             is: (addressZip = '') => addressZip.length > 0,
                             then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                           }),
          addressLine2: Yup.string()
                           .nullable(),
        })}
        onSubmit={(values, helpers) => {
          dispatch(changeUserAddress({
            zip: values.addressZip,
            line1: values.addressLine1,
            line2: values.addressLine2,
            state: values.addressState,
            city: values.addressCity,
            success: () => {
              dispatch(getUser({
                success: () => {
                  setModalOpen(false);
                  addToast('Address changed successfully.', {appearance: 'success'});
                }
              }));
            },
            failure: (error) => {
              handleErrors(error, helpers);
            }
          }));
        }}
      >
        {props => <>
          <div className={'flex flex-row gap-2'}>
            <div className={'flex-inline w-3/12'}>
              <PayvyLabeledInput
                label={'Zip'}
                name={'addressZip'}
                loading={zipProcessing}
                onChange={(zip) => handleZip(zip, props)}
                as={PayvyInput}
              />
            </div>
            <div className={'flex-inline w-9/12'}>
              <PayvyLabeledInput
                label={`Line 1 (${props.values.addressState} > ${props.values.addressCity})`}
                name={'addressLine1'}
                as={PayvyInput}
              />
            </div>
          </div>
          <PayvyLabeledInput
            label={'Line 2'}
            name={'addressLine2'}
            as={PayvyInput}
          />
          <button ref={changeAddressForm} type="hidden" onClick={props.handleSubmit}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
