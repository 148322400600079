import {createSlice} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import Debugger from "../utils/Debug";
import {mergeItemsIntoList, orderItems} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_TRANSACTION_STORAGE, resetCacheForStore} from "./db";

export const transactionSliceInitialState = {
  loading: {
    list: false,
    deleting: false,
  },
  numberOfItems: 0,
  items: [],
  displayItems: [],

  currentPage: 1,
  pageSize: 10,
  orderBy: '',
}

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: transactionSliceInitialState,
  reducers: {
    resetTransactionStateToInitial: (state) => {
      state.loading.list = transactionSliceInitialState.loading.list;
      state.loading.item = transactionSliceInitialState.loading.item;
      state.numberOfItems = transactionSliceInitialState.numberOfItems;
      state.items = transactionSliceInitialState.items;
      state.displayItems = transactionSliceInitialState.displayItems;

      state.currentPage = transactionSliceInitialState.currentPage;
      state.pageSize = transactionSliceInitialState.pageSize;
      state.orderBy = transactionSliceInitialState.orderBy;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getTransactions.pending, (state) => {
      state.loading.list = true;
    })
    .addCase(getTransactions.fulfilled, (state, action) => {
      state.loading.list = false;
      const {
        page = transactionSliceInitialState.currentPage,
        page_size = transactionSliceInitialState.pageSize,
        order_by = transactionSliceInitialState.orderBy,
        from,
        to,
        q,
      } = action?.meta?.arg || {}
      const newItems = action.payload.results || [];

      state.items = mergeItemsIntoList(state.items, newItems, true);
      let items = state.items

      // Filter by fromDateStr and toDateStr
      if(from) {
        Debugger.info(`📡 API: TRANSACTIONS`, ` Filtering items by date range: ${from} - ${to}`);
        items = items.filter(item =>
          new Date(item.date) >= new Date(from)
        );
      }
      if(to) {
        Debugger.info(`📡 API: TRANSACTIONS`, ` Filtering items by date range: ${from} - ${to}`);
        items = items.filter(item =>
          new Date(item.date) <= new Date(to)
        );
      }

      if(q) {
        Debugger.info(`📡 API: BILL`, ` Filtering items by search query: ${q}`);
        items = items.filter(item =>
          item.id === q ||
          (item?.bill?.name && item.bill.name.toLowerCase()
                                   .includes(q.toLowerCase())) ||
          (item?.bank_account?.name && item.bank_account.name.toLowerCase()
                                           .includes(q.toLowerCase())) ||
          (item?.contact?.name && item.contact.name.toLowerCase()
                                      .includes(q.toLowerCase()))
        );
      }

      // Order the filtered items based on the django syntax
      items = orderItems(items, order_by)

      // Slice items for table view
      state.displayItems = items.slice((page - 1) * page_size, page * page_size);

      state.numberOfItems = action.payload.count;
    })
    .addCase(getTransactions.rejected, (state) => {
      state.loading.list = false;
    })

    builder
    .addCase(removeTransaction.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeTransaction.fulfilled, (state) => {
      state.loading.deleting = false;
      state.items = transactionSliceInitialState.items;
      state.displayItems = transactionSliceInitialState.displayItems;
    })
    .addCase(removeTransaction.rejected, (state) => {
      state.loading.deleting = false;
    });
  }

})

export const {
  resetTransactionStateToInitial,
} = transactionSlice.actions;
export default transactionSlice.reducer;

// Specific Box Thunks
export const getTransactions = createApiThunk('transaction', 'list', PAYVY_API.V1.TRANSACTION.TRANSACTION_LIST, DB_TRANSACTION_STORAGE, [], 'GET', 30 * 60 * 1000)
export const removeTransaction = createApiThunk('transaction', 'remove', PAYVY_API.V1.TRANSACTION.TRANSACTION_REMOVE, DB_TRANSACTION_STORAGE, [DB_TRANSACTION_STORAGE], 'DELETE')

export const resetTransactionToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetTransactionStateToInitial());
    await resetCacheForStore(DB_TRANSACTION_STORAGE);
  }
}
