import { useSelector } from 'react-redux';
import { companiesSelector, hasPermission } from '../slices/companies';
import { userSelector } from '../slices/user';

export const usePermissions = () => {
    const { user: { id: userId } = {} } = useSelector(userSelector);
    const { company } = useSelector(companiesSelector);
    return {
        administration: {
            company: hasPermission(company, userId, 'company_administration'),
            integration: hasPermission(company, userId, 'integration_administration'),
        },
        inbox: {
            see: hasPermission(company, userId, 'inbox_can_see'),
            add: hasPermission(company, userId, 'inbox_can_add'),
            update: hasPermission(company, userId, 'inbox_can_update'),
            delete: hasPermission(company, userId, 'inbox_can_delete'),
        },
        bill: {
            see: hasPermission(company, userId, 'bill_can_see'),
            add: hasPermission(company, userId, 'bill_can_add'),
            update: hasPermission(company, userId, 'bill_can_update'),
            delete: hasPermission(company, userId, 'bill_can_delete'),
        },
        contact: {
            see: hasPermission(company, userId, 'contact_can_see'),
            add: hasPermission(company, userId, 'contact_can_add'),
            update: hasPermission(company, userId, 'contact_can_update'),
            delete: hasPermission(company, userId, 'contact_can_delete'),
        },
        transaction: {
            see: hasPermission(company, userId, 'transaction_can_see'),
            delete: hasPermission(company, userId, 'transaction_can_delete'),
        },
        receivables: {
            see: hasPermission(company, userId, 'receivables_can_see'),
        },
    };
};
