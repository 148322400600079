import {noop} from "lodash";
import React, {useState} from 'react';
import {PayvySubmenu} from "../../../comps/elements";
import {History} from './History';
import {PageList} from './PageList';

const Sidebar = ({
  inboxItem,
  pages = [],
  inboxPermissions,
  currentPage,
  setCurrentPage,
  navigatePages,
  setInboxItemPageId,
  showDeletedPages
}) => {
  setCurrentPage = setCurrentPage || noop;
  const [activeItem, setActiveItem] = useState('invoices-included');
  return <div className={`flex flex-col w-full md:w-96 border-r-2 border-neutral-100 shadow-inner`}>
    <PayvySubmenu
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      options={[
        {
          name: 'Invoices Included',
          value: 'invoices-included',
        }, {
          name: 'History',
          value: 'history',
        },
      ]}
    />
    {activeItem === 'invoices-included' ? <PageList
      inboxItem={inboxItem}
      pages={pages.filter((page) => {
        if(page.deleted && !showDeletedPages) return false;
        return page;
      })}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      navigatePages={navigatePages}
      setInboxItemPageId={setInboxItemPageId}
      canDelete={inboxPermissions.canDelete}
      canAddBill={inboxPermissions.canAddBill}
    /> : null}
    {activeItem === 'history' ? <History inboxItem={inboxItem}/> : null}
  </div>;
};
export default Sidebar;
