import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {BigCenteredLoadingBlock, PayvyPageHeader} from '../../comps/elements';
import {PAYVY_URL} from "../../constants";
import {companiesSelector, hasPermission} from '../../slices/companies';
import {getBill} from "../../slices/newBill";
import {userSelector} from '../../slices/user';
import MenuBar from './components/MenuBar';
import Page from './components/Page';
import TitleBar from './components/TitleBar';

const BillDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id: billId} = useParams();
  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const billPermission = {
    canUpdate: hasPermission(company, userId, 'bill_can_update'),
    canDelete: hasPermission(company, userId, 'bill_can_delete'),
  };
  const {
    items,
    loading: {
      item: loadingItem
    },
  } = useSelector((state) => state.bill);
  const loading = loadingItem;
  const [currentPage, setCurrentPage] = useState(0);
  const bill = Object.values(items)
                     .flat()
                     .find(item => item.id === parseInt(billId));
  useEffect(() => {
    const shouldRefreshBill =
      bill?.id !== parseInt(billId) ||
      (bill && !bill.pages);
    if(shouldRefreshBill) {
      dispatch(getBill({
        id: billId,
        errorCallback: () => navigate(PAYVY_URL.BILLS.LIST),
      }));
    }
  }, [dispatch, bill, bill?.id, bill?.pages, billId, navigate]);
  return <div className={'flex flex-col w-full  h-full'}>
    {(!bill || !bill.pages) && loading && <BigCenteredLoadingBlock text={'Loading bill information'}/>}
    {(bill && !!bill.pages) && <>
      <PayvyPageHeader parents={[
        {name: 'Payables'},
        {
          name: 'Bills',
          href: PAYVY_URL.BILLS.LIST
        }
      ]}>{bill.name}</PayvyPageHeader>
      <MenuBar
        bill={bill}
        currentPage={currentPage}
        billPermission={billPermission}
      />
      <TitleBar bill={bill}/>
      <Page
        bill={bill}
        billPermission={billPermission}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>}
  </div>;
};

export default BillDetailPage;
