import {addMonths} from 'date-fns';
import {Form, Formik} from 'formik';
import moment from 'moment';
import React, {useRef, useState} from 'react';
import {FaFileCsv, FaFilePdf} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {PayvyDateRangePicker, PayvyIconButton, PayvyLabeledInput, PayvySelectComponent} from '../../../comps/forms';
import {ModalPayvyBase} from "../../../comps/modals";
import {FORM_ERROR_MESSAGES, PAYVY_API, TRANSACTION_TYPES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector} from '../../../slices/companies';
import {build_url, stringify} from '../../../utils/Utility';

export const ModalTransactionDownload = ({
  type = 'pdf',
}) => {
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {company: {unique_hash} = {}} = useSelector(companiesSelector);
  const [modalOpen, setModalOpen] = useState(false);
  const typeUpper = type.toUpperCase();
  const submitForm = useRef(null);
  const initialStart = addMonths(new Date(), -1);
  const initialEnd = new Date();
  return <>
    <PayvyIconButton
      Icon={type === 'pdf' ? FaFilePdf : FaFileCsv}
      buttonText={`Download ${typeUpper}`}
      onClick={() => setModalOpen(true)}
      wrapperClassName={'grow justify-end'}
    />
    <ModalPayvyBase
      title={`Download Transaction List`}
      isOpen={modalOpen}
      confirmButtonText={`Download ${typeUpper}`}
      confirmButtonAction={() => submitForm.current.click()}
      dismissButtonText={'Close'}
      dismissButtonAction={() => setModalOpen(false)}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          fromDate: moment(initialEnd),
          toDate: moment(initialStart),
          fileType: type,
          transactionType: TRANSACTION_TYPES[0].value,
        }}
        validationSchema={Yup.object({
          fromDate: Yup.date(FORM_ERROR_MESSAGES.MUST_BE_DATE)
                       .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          toDate: Yup.date(FORM_ERROR_MESSAGES.MUST_BE_DATE)
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values, helpers) => {
          const url = build_url(PAYVY_API.V1.TRANSACTION.TRANSACTION_DOWNLOAD, {
            type: values.fileType,
            companyHash: unique_hash,
          });
          const params = stringify({
            from: values.fromDate.format('MM/DD/YYYY'),
            to: values.toDate.format('MM/DD/YYYY'),
            type: values.transactionType,
          }, {skipEmptyString: true});
          window.open(`${url}?${params}`, '_blank');
        }}
      >
        {props => <Form>
          <PayvyLabeledInput
            name={'fromDate'}
            label={'Date Range'}
          >
            <PayvyDateRangePicker
              onChange={(value) => {
                props.setFieldValue('fromDate', moment(value[0]));
                props.setFieldValue('toDate', moment(value[1]));
              }}
              value={[
                moment(props.values.fromDate)
                .toISOString(), moment(props.values.toDate)
                .toISOString()
              ]}
              className={'w-1/2'}
            />
          </PayvyLabeledInput>
          <PayvyLabeledInput
            label={'Transaction Type'}
            name={'transactionType'}
            alwaysLabel={true}
            component={PayvySelectComponent}
            options={TRANSACTION_TYPES}
          />
          <button ref={submitForm} type="hidden" onClick={() => props.handleSubmit()}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
