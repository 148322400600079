import {Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from 'react';
import {FaPlus} from "react-icons/fa";
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from "yup";
import {
  PayvyGlobalFormError,
  PayvyIconButton,
  PayvyIconButtonGroup,
  PayvyInput,
  PayvyLabeledInput
} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {addBankAccount, getBankAccounts} from "../../../slices/kyc";
import {paymentSystemSelector} from "../../../slices/paymentSystem";
import {handleErrors} from "../../../utils/Utility";
import {LinkedService} from "./LinkedService";
import {SettingsBankingAddAccountModal} from "./SettingsBankingAddAccountModal";
import {SettingsBankingAddManualAccountModal} from "./SettingsBankingAddManualAccountModal";


export const ModalAddPaymentService = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const [readyToClose, setReadyToClose] = useState(false);
  const [paymentTab, setPaymentTab] = useState('pp');
  const {
    loading,
    processing,
    services = []
  } = useSelector(paymentSystemSelector);
  useEffect(() => {
    setPaymentTab('pp')
  }, [modalOpen])
  useEffect(() => {
    if(readyToClose) {
      //check if all processing and loading has stopped
      const closingCheckInterval = setInterval(() => {
        if(!processing && !loading) {
          clearInterval(closingCheckInterval);
          setReadyToClose(false);
          setModalOpen(false);
          addToast('API key saved successfully.', {appearance: 'success'});
        }
      }, 250)
    }
  }, [readyToClose, processing, loading, addToast])
  const saveAllActiveAPIKeys = () => {
    document.querySelectorAll('.service-details form button')
            .forEach((button) => {
              button.click();
            })
    setReadyToClose(true);
  };
  return <>
    <PayvyIconButton
      buttonText={'Link Service'}
      Icon={FaPlus}
      onClick={() => setModalOpen(true)}
      wrapperClassName={'justify-end mt-2'}
      disabled={loading || processing}
    />
    <ModalPayvyBase
      title={`Connect Bank Account`}
      isOpen={modalOpen}
      confirmButtonText={'Save Changes'}
      confirmButtonAction={() => {
        if(paymentTab === 'mba') {
          setPaymentTab('dbc');
        } else {
          setPaymentTab('pp');
          setTimeout(saveAllActiveAPIKeys, 100)
        }
      }}
      noButtons={true}
      confirmButtonLoading={processing || loading || readyToClose}
      confirmButtonDisabled={processing || loading || readyToClose}
      dismissButtonAction={() => {
        if(paymentTab === 'mba') {
          setPaymentTab('dbc');
        } else {
          setModalOpen(false)
        }
      }}
      position={'top-1/5 left-1/3'}
    >
      {paymentTab !== 'mba' && <div className={'flex whitespace-nowrap pb-4'}>
        <PayvyIconButtonGroup
          value={paymentTab}
          onChange={(value) => setPaymentTab(value)}
          wrapperClassName={'grow justify-end'}
          choices={[
            {
              label: 'Payment Providers',
              value: 'pp',
            }, {
              label: 'Direct Bank Connection',
              value: 'dbc',
            },
          ]}/>
        <div className={'grow w-full'}></div>
      </div>}
      {(paymentTab === 'pp' || paymentTab === 'all') && <div>
        <p className={'font-light'}>Select the bank accounts you want to connect to add an API key for access.</p>
        {readyToClose ? <p className={'font-bold'}>Saving...</p> :
          <>{services.map((service, index) => {
            return <LinkedService
              key={index}
              service={service}
              loading={loading || processing}
            />;
          })}</>}
      </div>}
      {(paymentTab === 'dbc' || paymentTab === 'all') && <div>
        <p className={'font-light'}>Direct Bank Connection allows you to connect to your bank account directly.</p>
        <SettingsBankingAddAccountModal setModalOpen={setModalOpen}/>
        <SettingsBankingAddManualAccountModal setPaymentTab={setPaymentTab}/>
      </div>}
      {(paymentTab === 'mba') && <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            name: '',
            bankName: '',
            accountNumber: '',
            routingNumber: '',
            paymentsEnabled: false,
            document: null
          }}
          validationSchema={Yup.object({
            name: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            bankName: Yup.string()
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            accountNumber: Yup.string()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            routingNumber: Yup.string()
                              .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
            paymentsEnabled: Yup.boolean(),
            document: Yup.mixed()
                         .when("paymentsEnabled", {
                           is: true,
                           then: (schema) =>
                             schema
                             .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                           otherwise: (schema) => schema.notRequired(),
                         })
          })}
          onSubmit={(values, helpers) => {
            helpers.setSubmitting(true);
            const fdata = new FormData();
            fdata.append("name", values.name);
            fdata.append("bank_name", values.bankName);
            fdata.append("account_number", values.accountNumber);
            fdata.append("routing_number", values.routingNumber);
            fdata.append("payments_enabled", values.paymentsEnabled);
            if(values.document) {
              fdata.append("document", values.document);
            }
            dispatch(addBankAccount({
              formData: fdata,
              success: () => {
                setModalOpen(false)
                dispatch(getBankAccounts({
                  page: 1,
                  page_size: 100,
                }));
                helpers.setSubmitting(false);
              },
              failure: (error) => {
                handleErrors(error, helpers);
                helpers.setSubmitting(false);
              }
            }))
          }}
        >
          {formProps => <Form className={'h-full'}>
            <div className={'flex flex-col w-full grow p-2'}>
              <PayvyGlobalFormError/>
              <PayvyLabeledInput
                label={'Account name'}
                name={'name'}
                as={PayvyInput}
              />
              <PayvyLabeledInput
                label={'Bank name'}
                name={'bankName'}
                as={PayvyInput}
              />
              <PayvyLabeledInput
                label={'Account number'}
                name={'accountNumber'}
                as={PayvyInput}
              />
              <PayvyLabeledInput
                label={'Routing number'}
                name={'routingNumber'}
                as={PayvyInput}
              />
              <div className="border rounded-lg mt-2 p-4 w-full max-w-md shadow-md font-light">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="font-semibold">Enable Payments</h2>
                    <p className="text-sm text-gray-500">Allow this account to send and receive payments</p>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={formProps.values.paymentsEnabled}
                      onChange={() => formProps.setFieldValue('paymentsEnabled', !formProps.values.paymentsEnabled)}
                    />
                    <div
                      className={`w-11 h-6 bg-gray-300 peer-focus:outline-none rounded-full peer ${formProps.values.paymentsEnabled ? 'bg-neutral-900' : ''} relative transition`}>
                      <div
                        className={`absolute left-1 top-1 w-4 h-4 bg-neutral-0 rounded-full transition ${formProps.values.paymentsEnabled ? 'translate-x-5' : ''}`}/>
                    </div>
                  </label>
                </div>
                {formProps.values.paymentsEnabled && (
                  <div className="mt-4 border-t pt-4">
                    <h3 className="font-semibold text-sm">Required Documentation</h3>
                    <p className="text-sm text-gray-500 mb-2">Please provide one of the following:</p>
                    <ul className="text-sm text-gray-500 list-disc pl-5 mb-4">
                      <li>A notarized bank letter</li>
                      <li>A bank statement that verifies the details entered belong to the business owner</li>
                    </ul>
                    <PayvyLabeledInput
                      label={'Documentation'}
                      name={'document'}
                      alwaysLabel={true}
                    >
                      <input
                        type={'file'}
                        name={'document'}
                        onChange={(e) => formProps.setFieldValue('document', e.target.files[0])}
                        className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                      />
                    </PayvyLabeledInput>
                  </div>
                )}
              </div>
              <div className={'flex flex-row justify-end gap-2'}>
                <PayvyIconButton
                  buttonText={'Go Back'}
                  onClick={() => setPaymentTab('dbc')}
                  wrapperClassName={'mt-4 justify-end'}
                  borderSize={2}
                  borderColor={'red-300'}
                  mainColor={'neutral-0'}
                  textColor={'red-300'}
                  hoverTextColor={'neutral-0'}
                />
                <PayvyIconButton
                  buttonText={'Add'}
                  onClick={formProps.handleSubmit}
                  wrapperClassName={'mt-4 justify-end'}
                />
              </div>
            </div>
          </Form>}
        </Formik>
      </div>}
    </ModalPayvyBase>
  </>;
};
