import {Form, Formik} from 'formik';
import React, {useRef} from 'react';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledCheckbox,
  PayvyLabeledInput,
  PayvySelectComponent
} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES, PAYVY_URL} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {authSelector, createUserAccount} from '../../../slices/auth';
import {getUser} from '../../../slices/user';
import {COUNTRY_OPTIONS_WITH_LABEL} from '../../../utils/Countries';
import {handleErrors} from '../../../utils/Utility';

export const FormRegistration = ({urlToNavigateAfterLogin}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const navigate = useNavigate();
  const {signingUpLoading: loading} = useSelector(authSelector);
  return <>
    <Formik
      innerRef={formikRef}
      initialValues={{
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        phone: '+1',
        country: 'US',
        terms: false,
      }}
      validationSchema={Yup.object({
        username: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        email: Yup.string()
                  .email()
                  .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        firstName: Yup.string()
                      .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        lastName: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        password: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        phone: Yup.string()
                  .nullable()
                  .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                  .test('is-valid-phone', 'Invalid phone number', value => (!value || (!!value && isPossiblePhoneNumber(value)))),
        country: Yup.string()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        terms: Yup.bool()
                  .oneOf([true], FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      })}
      onSubmit={(values, helpers) => {
        dispatch(createUserAccount({
          username: values.username,
          email: values.email,
          first_name: values.firstName,
          last_name: values.lastName,
          password: values.password,
          phone: values.phone,
          country: values.country,
          success: () => {
            dispatch(getUser({
              success: () => {
                window.location.href = urlToNavigateAfterLogin || PAYVY_URL.COMPANY_CREATE;
              },
              failure: () => {
                navigate(urlToNavigateAfterLogin || PAYVY_URL.LOGIN);
              }
            }));
          },
          failure: (error) => {
            handleErrors(error, helpers);
          },
        }));
      }}
    >
      {props => (<Form>
        <PayvyLabeledInput
          label={'Username'}
          as={PayvyInput}
          name={'username'}
          placeholder={'Username'}
        />
        <PayvyLabeledInput
          label={'Email address'}
          as={PayvyInput}
          name={'email'}
          placeholder={'Email address'}
        />
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'First name'}
            as={PayvyInput}
            name={'firstName'}
            placeholder={'First name'}
          />
          <PayvyLabeledInput
            label={'Last name'}
            as={PayvyInput}
            name={'lastName'}
            placeholder={'Last name'}
          />
        </div>
        <PayvyLabeledInput
          label={'Password'}
          as={PayvyInput}
          name={'password'}
          type={'password'}
          placeholder={'Password'}
        />
        <PayvyLabeledInput
          label={'Phone number'}
          name={'phone'}
          alwaysLabel={true}
        >
          <PhoneInput
            international={true}
            defaultCountry="US"
            placeholder="Enter phone number"
            value={props.values.phone}
            onChange={(value) => props.setFieldValue('phone', value)}
          />
        </PayvyLabeledInput>
        <PayvyLabeledInput
          label={'Country'}
          component={PayvySelectComponent}
          name={'country'}
          options={COUNTRY_OPTIONS_WITH_LABEL}
          placeholder={'Country'}
          alwaysLabel={true}
        />
        <PayvyLabeledCheckbox name={'terms'}>
                    <span className={'text-xs'}>
                        By checking this box you agree to our <a className={'underline text-blue-500'}
                                                                 href={PAYVY_URL.TOS} target="_blank"
                                                                 rel="noopener noreferrer">Terms of Service</a> and <a
                      className={'underline text-blue-500'} href={PAYVY_URL.PRIVACY} target="_blank"
                      rel="noopener noreferrer">Privacy Policy</a>.
                    </span>
        </PayvyLabeledCheckbox>
        <PayvyIconButton
          loading={loading}
          disabled={loading}
          type={'submit'}
          buttonText={'Create Account'}
          wrapperClassName={'mt-6 w-full px-20'}
          fullWidth={true}
        />
      </Form>)}
    </Formik>
  </>;
};
