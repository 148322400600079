import React from "react";
import {FaRedo} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import {PayvyIconButton} from "../../../comps/forms";
import {
  getPaymentServiceInformation,
  paymentSystemSelector,
  refreshPaymentServiceInformation
} from "../../../slices/paymentSystem";
import {payvyToast} from "../../../utils/Utility";

const RefreshContactFromAPIButton = ({
  currentPaymentType = '',
  textOnly = false
}) => {
  const {
    services = []
  } = useSelector(paymentSystemSelector) || {};
  const paymentTypeChoices = [
    ...services.map(service => ({
      label: service.name,
      value: service.internal_name
    }))
  ];
  const dispatch = useDispatch();
  const userFacingTypeName = paymentTypeChoices.find(i => i.value === currentPaymentType)?.label || '';
  if(!userFacingTypeName) return null;
  return <PayvyIconButton
    Icon={textOnly ? null : FaRedo}
    tooltipContent={textOnly ? null : `Refresh ${userFacingTypeName} data`}
    onClick={() => {
      dispatch(refreshPaymentServiceInformation({
        internalName: currentPaymentType,
        success: () => {
          dispatch(getPaymentServiceInformation({
            success: () => {
              payvyToast('Contact information refreshed', {appearance: 'success'})
            }
          }))
        },
        failure: () => {
          payvyToast('Recently updated, please try again in an hour.', {appearance: 'error'})
        }
      }))
    }}
    buttonText={textOnly ? `Refresh ${userFacingTypeName}` : null}
    mainColor={'none'}
    borderColor={'none'}
    iconColor={'neutral-700'}
    textColor={'neutral-900'}
    iconSize={22}
    hoverTextColor={'neutral-700'}
    hoverColor={'neutral-700'}
    wrapperClassName={textOnly ? '-ml-3' : 'mt-6'}
  />
}

export default RefreshContactFromAPIButton;
