import {noop} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Select from 'react-select';
import {companiesSelector} from '../../../slices/companies';

export const SelectCompany = ({
  value,
  setValue = noop,
}) => {
  const {
    company,
    companies,
  } = useSelector(companiesSelector);
  const [valueObject, setValueObject] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const newOptions = companies.map((company) => ({
      value: company.id,
      label: company.name,
    }));

    if(company) {
      newOptions.unshift({
        value: company.id,
        label: company.name,
      });
    }

    setOptions(newOptions);

    if(value && value.length > 0) {
      setValueObject(newOptions.find((option) => option.value === value[0]) || null);
    }
  }, [company, companies, value]);

  const triggerLocalFieldChange = (option) => {
    setValue(option.value);
    setValueObject(option);
  };

  return (
    <Select
      className={'w-full'}
      placeholder="Select Company"
      options={options}
      onChange={triggerLocalFieldChange}
      value={valueObject}
    />
  );
};
