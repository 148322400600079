import React from 'react';
import {PayvyPageHeader} from "../../comps/elements";

const TestingPage = () => {
  return <>
    <PayvyPageHeader>Testing</PayvyPageHeader>
    <div className={'flex flex-col w-full p-4'}>
      Testing Page
    </div>
  </>
};

export default TestingPage;
