import {Form, Formik} from 'formik';
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {CleanedHTML} from "../../../comps/elements";
import {PayvyIconButton, PayvyInput, PayvyLabeledInput, PayvySelectComponent} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {disableIntegration, enableIntegration, getIntegration} from "../../../slices/newIntegration";
import {addTokenToPayvyGPT, getConnectionInfo} from '../../../slices/payvygpt';
import {build_error_message} from '../../../utils/Utility';


export const PayvyGPTSettings = ({
  integration: {
    id,
    name,
    logo,
    description,
    enabled
  }
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    loading: {
      item: loading,
      posting: processing
    },
    llmModel,
    geminiApiKey,
    openaiApiKey,
  } = useSelector((state) => state.payvygpt);
  useEffect(() => {
    dispatch(getConnectionInfo({forceUpdate: true}));
  }, [dispatch]);
  const gptConnect = () => {
    dispatch(enableIntegration({
      id,
      successCallback: () => dispatch(getIntegration({id}))
    }));
  };
  const gptDisconnect = () => {
    dispatch(disableIntegration({
      id,
      successCallback: () => dispatch(getIntegration({id}))
    }));
  };
  return <div className={'integration'}>
    <div className={'flex flex-row py-2'}>
      <h1 className={'flex h-24 w-24'}>{logo ?
        <img src={logo} alt={name} className={'w-24 max-h-24 rounded self-center'}/> : name}</h1>
      <CleanedHTML html={description} className={'justify-center flex flex-col mx-5'}/>
      <div className={'flex grow justify-end self-center'}>
        {enabled ?
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Disconnect'}
              onClick={gptDisconnect}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full'}
            />
          </div> :
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Connect'}
              onClick={gptConnect}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full'}
            />
          </div>}
      </div>
    </div>
    {enabled ? <div className={'grid grid-cols-1 md:grid-cols-2 gap-5 w-full'}>
      <div className={'flex flex-col bg-neutral-0 shadow-lg px-4 py-2 rounded-xl'}>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            model: '' || llmModel,
            openaiApiKey: '' || openaiApiKey,
            geminiApiKey: '' || geminiApiKey
          }}
          validationSchema={Yup.object({
            model: Yup.string(),
            openaiApiKey: Yup.string()
                             .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255})),
            geminiApiKey: Yup.string()
                             .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255})),
          })}
          onSubmit={(values, helpers) => {
            dispatch(addTokenToPayvyGPT({
              body: {
                llm_model: values.model,
                openai_api_key: values.openaiApiKey,
                gemini_api_key: values.geminiApiKey,
              },
            }))
          }}
        >
          {(props) => <Form>
            <PayvyLabeledInput
              alwaysLabel={true}
              label={`Model`}
              component={PayvySelectComponent}
              placeholder={`Select a model`}
              name={'model'}
              options={[
                {
                  value: 'gemini-2.0-flash',
                  label: 'Gemini 2.0 Flash'
                },
                {
                  value: 'gemini-2.0-flash-lite-preview-02-05',
                  label: 'Gemini 2.0 Flash Lite'
                },
                {
                  value: 'gemini-1.5-flash',
                  label: 'Gemini 1.5 Flash'
                },
                {
                  value: 'gemini-1.5-flash-8b',
                  label: 'Gemini 1.5-8b Flash'
                },
                {
                  value: 'gemini-1.5-pro',
                  label: 'Gemini 1.5 Pro'
                },
                // {value: 'gpt-4o', label: 'OpenAI 4o'},
                // {value: 'gpt-4o-mini', label: 'OpenAI 4o Mini'},
                // {value: 'gpt-4-turbo', label: 'OpenAI 4 Turbo'},
                // {value: 'gpt-4', label: 'OpenAI 4'},
                // {value: 'gpt-3.5-turbo', label: 'OpenAI 3.5 Turbo'},
              ]}
            />
            {/*<PayvyLabeledInput*/}
            {/*  label={'Open API Key'}*/}
            {/*  name={'openaiApiKey'}*/}
            {/*  as={PayvyInput}*/}
            {/*/>*/}
            <PayvyLabeledInput
              label={'Gemini API Key'}
              name={'geminiApiKey'}
              as={PayvyInput}
            />
            <PayvyIconButton
              buttonText={'Save integration'}
              onClick={() => props.handleSubmit()}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full mt-5'}
            />
          </Form>}
        </Formik>
      </div>
    </div> : null}
  </div>
};
