import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {acceptInvitation} from '../../../slices/newContact';
import {handleErrors, payvyToast, removeEmptyData} from '../../../utils/Utility';
import ProcessAcceptPolicy from './ProcessAcceptPolicy';
import ProcessBanking from './ProcessBanking';
import {ProcessConfirmYourInformation} from "./ProcessConfirmYourInformation";
import {ProcessFinished} from "./ProcessFinished";

export const ProcessBlockHolder = ({
  currentStep,
  increaseStepCount,
  setCurrentStep,
  hash,
}) => {
  const {
    loading: {posting: processing},
    invitationVendorDetails,
  } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const [currentBankingStep, setCurrentBankingStep] = useState('ach');
  const toggleFinanceStep = () => setCurrentBankingStep(currentBankingStep === 'ach' ? 'check' : 'ach');
  let Component;
  switch(currentStep) {
    case 1:
      Component = ProcessAcceptPolicy;
      break;
    case 2:
      Component = ProcessConfirmYourInformation;
      break;
    case 3:
      Component = ProcessBanking;
      break;
    case 4:
      Component = ProcessFinished;
      break;
    default:
      Component = React.Fragment;
  }
  return <Formik
    innerRef={formikRef}
    initialValues={{
      name: invitationVendorDetails.name || '',
      firstName: '',
      userName: '',
      lastName: '',
      customerType: invitationVendorDetails.customer_type || '',
      email: invitationVendorDetails.email || '',
      phone: invitationVendorDetails.phone || '',
      address: invitationVendorDetails.address,
      addressZip: '',
      addressCity: '',
      addressState: '',
      addressLine1: '',
      addressLine2: '',
      vendor1099: false,
      accept_terms: false,
      bank_name: '',
      account_number: '',
      routing_number: '',
      password: '',
      payee: '',
      mailingAddressZip: '',
      mailingAddressCity: '',
      mailingAddressState: '',
      mailingAddressLine1: '',
      mailingAddressLine2: '',
    }}
    validateOnChange={true}
    validationSchema={Yup.object({
      name: Yup.string()
               .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      firstName: Yup.string()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      lastName: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      userName: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      password: Yup.string()
                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      phone: Yup.string()
                .nullable()
                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                .test('is-valid-phone', 'Invalid phone number', value => (!value || (!!value && isPossiblePhoneNumber(value)))),
      email: Yup.string()
                .email()
                .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      accept_terms: Yup.bool()
                       .oneOf([true], 'You must accept the terms and conditions.'),
      vendor1099: Yup.bool()
                     .oneOf([true, false])
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      customerType: Yup.string()
                       .oneOf(['individual', 'business'])
                       .required(),
      addressZip: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
      addressCity: Yup.string()
                      .when(['addressZip', 'addressStreet'], {
                        is: (
                          addressZip = '',
                          addressStreet = ''
                        ) => addressZip.length > 0 || addressStreet.length > 0,
                        then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                      }),
      addressState: Yup.string()
                       .when(['addressZip', 'addressStreet'], {
                         is: (
                           addressZip = '',
                           addressStreet = ''
                         ) => addressZip.length > 0 || addressStreet.length > 0,
                         then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                       }),
      addressLine1: Yup.string()
                       .when('addressZip', {
                         is: (addressZip = '') => addressZip.length > 0,
                         then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                       }),
      payee: Yup.string(),
      mailingAddressCity: Yup.string()
                             .when(['mailingAddressZip', 'mailingAddressStreet'], {
                               is: (
                                 mailingAddressZip = '',
                                 mailingAddressStreet = ''
                               ) => mailingAddressZip.length > 0 || mailingAddressStreet.length > 0,
                               then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                             }),
      mailingAddressState: Yup.string()
                              .when(['mailingAddressZip', 'mailingAddressStreet'], {
                                is: (
                                  mailingAddressZip = '',
                                  mailingAddressStreet = ''
                                ) => mailingAddressZip.length > 0 || mailingAddressStreet.length > 0,
                                then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                              }),
      mailingAddressLine1: Yup.string()
                              .when('mailingAddressZip', {
                                is: (mailingAddressZip = '') => mailingAddressZip.length > 0,
                                then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                              }),
      account_number: Yup.string()
                         .when('payee', {
                           is: (payee = '') => payee.length === 0,
                           then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                         }),
      routing_number: Yup.string()
                         .when('payee', {
                           is: (payee = '') => payee.length === 0,
                           then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                         }),
      bank_name: Yup.string(),
    })}
    onSubmit={(values, helpers) => {
      let bodyContent = {
        terms_of_service_accepted: values.accept_terms,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        username: values.userName,
        password: values.password,
        name: values.name,
        business_type: values.customerType,
        vendor_1099: values.vendor1099,
        phone: values.phone,
        address: {
          city: values.addressCity,
          country: 'US',
          line1: values.addressLine1,
          line2: values.addressLine2,
          zip: values.addressZip,
          state: values.addressState,
        },
        account_number: values.account_number,
        routing_number: values.routing_number,
        bank_name: values.bank_name,
        payee: values.payee,
      };
      let mailing_address = {
        city: values.mailingAddressCity,
        country: 'US',
        line1: values.mailingAddressLine1,
        line2: values.mailingAddressLine2,
        zip: values.mailingAddressZip,
        state: values.mailingAddressState,
      };
      const keysToCheck = ['payee', 'account_number', 'routing_number', 'bank_name'];
      bodyContent = removeEmptyData(bodyContent, keysToCheck);

      if(mailing_address.zip || mailing_address.city || mailing_address.line1) {
        bodyContent.mailing_address = mailing_address;
      }
      dispatch(acceptInvitation({
        hash,
        body: bodyContent,

        successCallback: () => {
          payvyToast('Registration success', {appearance: 'success'});
          increaseStepCount();
        },
        errorCallback: (error) => {
          const data = error.data || {};
          if('message' in error) {
            payvyToast(error.message, {appearance: 'error'});
          }
          handleErrors(data, helpers);
        },
      }));
    }}
  >
    {props => <Form role={"process-block-holder"}>
      <Component
        setCurrentStep={setCurrentStep}
        increaseStepCount={increaseStepCount}
        formProps={props}
        processing={processing}
        toggleFinanceStep={toggleFinanceStep}
        currentBankingStep={currentBankingStep}
      />
    </Form>}
  </Formik>
};

export default ProcessBlockHolder;



