import {createSlice} from '@reduxjs/toolkit';
import {PAYVY_API} from "../constants";
import Debugger from "../utils/Debug";
import {mergeItemIntoList, mergeItemsIntoList, orderItems, parseAmountFilter} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_BILL_STORAGE, resetCacheForStore} from "./db";

export const billSliceInitialState = {
  loading: {
    list: false,
    item: false,
    posting: false,
    deleting: false,
    failedTransactions: false,
    history: false,
    ledger: false,
  },
  numberOfItems: {'draft': 0},
  items: {
    'draft': [],
    'all': []
  },
  displayItems: [],
  failedTransactions: {},
  history: {},
  historyNumberOfItems: {},
  ledger: {},

  status: 'draft',
  currentPage: 1,
  pageSize: 10,
  orderBy: '',
  searchQuery: '',
  fromDateStr: '',
  toDateStr: '',
  filterAmountStr: '',
  filterContact: ''
}

const billSlice = createSlice({
  name: 'bill',
  initialState: billSliceInitialState,
  reducers: {
    resetBillItems: (state) => {
      state.items = {'draft': []};
      state.displayItems = [];
      state.numberOfItems = {'draft': 0};
    },
    resetBillStateToInitial: (state) => {
      state.loading = billSliceInitialState.loading;
      state.numberOfItems = billSliceInitialState.numberOfItems;
      state.items = billSliceInitialState.items;
      state.displayItems = billSliceInitialState.displayItems;
      state.failedTransactions = billSliceInitialState.failedTransactions;
      state.history = billSliceInitialState.history;
      state.historyNumberOfItems = billSliceInitialState.historyNumberOfItems;
      state.status = billSliceInitialState.status;
      state.currentPage = billSliceInitialState.currentPage;
      state.pageSize = billSliceInitialState.pageSize;
      state.orderBy = billSliceInitialState.orderBy;
      state.searchQuery = billSliceInitialState.searchQuery;
      state.fromDateStr = billSliceInitialState.fromDateStr;
      state.toDateStr = billSliceInitialState.toDateStr;
      state.filterAmountStr = billSliceInitialState.filterAmountStr;
      state.filterContact = billSliceInitialState.filterContact;
    },
    setBillCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setBillPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setBillStatus: (state, action) => {
      state.status = action.payload === 'all' ? '' : action.payload.replace('-', ' ');
      state.currentPage = 1;
      if(!state.items[state.status]) state.items[state.status] = [];
      state.displayItems = state.items[state.status].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
    },
    setBillOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setFilterAmountStr: (state, action) => {
      state.filterAmountStr = action.payload;
    },
    setFilterContact: (state, action) => {
      state.filterContact = action.payload;
    },
    setBillSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setBillFromDateStr: (state, action) => {
      state.fromDateStr = action.payload;
    },
    setBillToDateStr: (state, action) => {
      state.toDateStr = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
    .addCase(getUserBills.pending, (state) => {
      state.loading.list = true;
    })
    .addCase(getUserBills.fulfilled, (state, action) => {
      const {
        status = '',
        q = billSliceInitialState.searchQuery,
        from = billSliceInitialState.fromDateStr,
        to = billSliceInitialState.toDateStr,
        amount = billSliceInitialState.filterAmountStr,
        contact = billSliceInitialState.filterContact,
        page = billSliceInitialState.currentPage,
        page_size = billSliceInitialState.pageSize,
        order_by = billSliceInitialState.orderBy
      } = action?.meta?.arg || {}
      const newItems = action.payload.results || [];
      if(!state.items[status]) state.items[status] = [];
      // Merge new items into the state
      state.items[status] = mergeItemsIntoList(state.items[status], newItems, true);

      // Apply filters to the items based on comma-separated statuses
      let filteredItems;
      if(status === 'all' || !status) {
        filteredItems = state.items['all'];
      } else {
        let statusesArray = status.split(',')
                                  .map(s => s.trim()
                                             .toLowerCase());
        filteredItems = state.items[status].filter(item =>
          statusesArray.includes(item.status?.toLowerCase())
        );
      }
      // Filter by searchQuery
      if(q) {
        Debugger.info(`📡 API: BILL`, ` Filtering items by search query: ${q}`);
        filteredItems = filteredItems.filter(item =>
          item.id === q ||
          (item.name && item.name.toLowerCase()
                            .includes(q.toLowerCase())) ||
          (item.contact?.name && item.contact.name.toLowerCase()
                                     .includes(q.toLowerCase()))
        );
      }

      // Filter by fromDateStr and toDateStr
      if(from) {
        Debugger.info(`📡 API: BILL`, ` Filtering items by date range: ${from} - ${to}`);
        filteredItems = filteredItems.filter(item =>
          new Date(item.created) >= new Date(from)
        );
      }
      if(to) {
        Debugger.info(`📡 API: BILL`, ` Filtering items by date range: ${from} - ${to}`);
        filteredItems = filteredItems.filter(item =>
          new Date(item.created) <= new Date(to)
        );
      }

      // Filter by filterAmountStr
      if(amount) {
        Debugger.info(`📡 API: BILL`, ` Filtering items by amount: ${amount}`);
        const [minAmount, maxAmount] = parseAmountFilter(amount);

        filteredItems = filteredItems.filter(item => {
          const itemAmount = parseFloat(item.amount);
          return (minAmount === null || itemAmount >= minAmount) && (maxAmount === null || itemAmount <= maxAmount);
        });
      }

      // Filter by filterContact, which is an ID
      if(contact) {
        Debugger.info(`📡 API: BILL`, `Filtering items by contact: ${contact}`);
        filteredItems = filteredItems.filter(item =>
          item.contact?.id && item.contact.id === parseInt(contact)
        );
      }

      // Order the filtered items based on the django syntax
      filteredItems = orderItems(filteredItems, order_by)

      // Paginate the filtered items
      state.displayItems = filteredItems.slice((page - 1) * page_size, page * page_size);

      // Update the number of items for the current status
      state.numberOfItems[status] = action.payload.count;
      state.loading.list = false;
    })
    .addCase(getUserBills.rejected, (state) => {
      state.loading.list = false;
      if(!state.items[state.status]) state.items[state.status] = [];
      state.displayItems = state.items[state.status].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
      state.numberOfItems[state.status] = 0;
    });

    builder
    .addCase(getBill.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getBill.fulfilled, (state, action) => {
      state.loading.item = false;
      state.items = Object.keys(state.items)
                          .reduce(
                            (acc, category) => {
                              acc[category] = mergeItemIntoList(state.items[category], action.payload);
                              return acc;
                            },
                            {all: mergeItemIntoList(state.items['all'] || [], action.payload, true)}
                          );

      state.displayItems = mergeItemIntoList(state.displayItems, action.payload);
    })
    .addCase(getBill.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(createBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createBill.fulfilled, (state, action) => {
      state.loading.posting = false;
      const newItems = action.payload.results || [];
      state.items[state.status] = mergeItemsIntoList(state.items[state.status], newItems, true);
      state.displayItems = state.items[state.status].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
      state.numberOfItems[state.status] = action.payload.count;
    })
    .addCase(createBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(updateBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(updateBill.fulfilled, (state, action) => {
      state.loading.posting = false;
      state.items = billSliceInitialState.items;
      state.displayItems = billSliceInitialState.displayItems;
    })
    .addCase(updateBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(changeBillStatus.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(changeBillStatus.fulfilled, (state, action) => {
      state.loading.posting = false;
    })
    .addCase(changeBillStatus.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(retryBillPayment.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(retryBillPayment.fulfilled, (state, action) => {
      state.loading.posting = false;
    })
    .addCase(retryBillPayment.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(removeBill.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeBill.fulfilled, (state, action) => {
      state.loading.deleting = false;
      state.items = billSliceInitialState.items;
      state.displayItems = billSliceInitialState.displayItems;
    })
    .addCase(removeBill.rejected, (state) => {
      state.loading.deleting = false;
    });

    builder
    .addCase(getFailedTransactionList.pending, (state) => {
      state.loading.failedTransactions = true;
    })
    .addCase(getFailedTransactionList.fulfilled, (state, action) => {
      const billId = action?.meta?.arg?.id;
      state.loading.failedTransactions = false;
      if(billId) state.failedTransactions[billId] = action.payload;
    })
    .addCase(getFailedTransactionList.rejected, (state) => {
      state.loading.failedTransactions = false;
    });

    builder
    .addCase(getBillHistory.pending, (state) => {
      state.loading.history = true;
    })
    .addCase(getBillHistory.fulfilled, (state, action) => {
      state.loading.history = false;
      const billId = action?.meta?.arg?.id;
      if(billId) {
        if(!state.history[billId]) state.history[billId] = [];
        const newList = mergeItemsIntoList(state.history[billId], action.payload.results, true)
        newList.sort((a, b) => new Date(b.date) - new Date(a.date));
        state.history[billId] = newList;
        state.historyNumberOfItems[billId] = action.payload.count;
      }
    })
    .addCase(getBillHistory.rejected, (state) => {
      state.loading.history = false;
    })

    builder
    .addCase(getBillLedgerHistory.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(getBillLedgerHistory.fulfilled, (state, action) => {
      state.loading.ledger = false;
      const billId = action?.meta?.arg?.id;
      if(billId) {
        if(!state.ledger[billId]) state.ledger[billId] = [];
        state.ledger[billId] = action.payload;
      }
    })
    .addCase(getBillLedgerHistory.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(sendBillComment.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(sendBillComment.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(sendBillComment.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(payBill.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(payBill.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(payBill.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(updatePageInformation.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(updatePageInformation.fulfilled, (state) => {
      state.loading.item = false;
    })
    .addCase(updatePageInformation.rejected, (state) => {
      state.loading.item = false;
    });

    builder
    .addCase(moveBillPage.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(moveBillPage.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(moveBillPage.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(removeBillPage.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeBillPage.fulfilled, (state) => {
      state.loading.deleting = false;
    })
    .addCase(removeBillPage.rejected, (state) => {
      state.loading.deleting = false;
    });

    builder
    .addCase(reorderBillPages.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(reorderBillPages.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(reorderBillPages.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(createPageInformation.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createPageInformation.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(createPageInformation.rejected, (state) => {
      state.loading.posting = false;
    });
  }
})


export const {
  resetBillStateToInitial,
  resetBillItems,
  setBillCurrentPage,
  setBillPageSize,
  setBillStatus,
  setBillOrderBy,
  setFilterAmountStr,
  setFilterContact,
  setBillSearchQuery,
  setBillFromDateStr,
  setBillToDateStr,
} = billSlice.actions;
export default billSlice.reducer;

// Specific Bill Thunks
export const getUserBills = createApiThunk('bill', 'list', `${PAYVY_API.V1.BILLS.BILL_LIST}`, DB_BILL_STORAGE, [], 'GET');
export const getBill = createApiThunk('bill', 'get', PAYVY_API.V1.BILLS.BILL_DETAILS, DB_BILL_STORAGE, [], 'GET');
export const createBill = createApiThunk('bill', 'create', PAYVY_API.V1.BILLS.BILL_CREATE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');
export const updateBill = createApiThunk('bill', 'update', PAYVY_API.V1.BILLS.BILL_UPDATE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'PUT');
export const changeBillStatus = createApiThunk('bill', 'changeStatus', PAYVY_API.V1.BILLS.BILL_CHANGE_STATUS, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');
export const retryBillPayment = createApiThunk('bill', 'retryBillPayment', PAYVY_API.V1.BILLS.REPAY_TRANSACTION, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');
export const removeBill = createApiThunk('bill', 'remove', PAYVY_API.V1.BILLS.BILL_REMOVE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'DELETE');
export const getFailedTransactionList = createApiThunk('bill', 'list-failedTransactions', PAYVY_API.V1.BILLS.FAILED_TRANSACTIONS, DB_BILL_STORAGE, [], 'GET');
export const getBillHistory = createApiThunk('bill', 'list-getHistory', PAYVY_API.V1.BILLS.BILL_HISTORY, DB_BILL_STORAGE, [], 'GET');
export const getBillLedgerHistory = createApiThunk('bill', 'list-getLedgerHistory', PAYVY_API.V1.BILLS.BILL_LEDGER_HISTORY, DB_BILL_STORAGE, [], 'GET');
export const sendBillComment = createApiThunk('bill', 'sendComment', PAYVY_API.V1.BILLS.BILL_SEND_COMMENT, DB_BILL_STORAGE, [], 'POST');
export const payBill = createApiThunk('bill', 'payBill', PAYVY_API.V1.BILLS.BILL_PAY, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');

// Specific Page Thunks
export const updatePageInformation = createApiThunk('bill', 'updatePageInformation', PAYVY_API.V1.BILLS.BILL_PAGE_UPDATE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'PATCH');
export const moveBillPage = createApiThunk('bill', 'movePage', PAYVY_API.V1.BILLS.BILL_PAGE_MOVE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');
export const removeBillPage = createApiThunk('bill', 'removePage', PAYVY_API.V1.BILLS.BILL_PAGE_REMOVE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'DELETE');
export const reorderBillPages = createApiThunk('bill', 'reorderPages', PAYVY_API.V1.BILLS.BILL_REORDER_PAGES, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');
export const createPageInformation = createApiThunk('bill', 'createPageInformation', PAYVY_API.V1.BILLS.BILL_PAGE_CREATE, DB_BILL_STORAGE, [DB_BILL_STORAGE], 'POST');


export const resetBillToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetBillStateToInitial());
    await resetCacheForStore(DB_BILL_STORAGE);
  }
}
