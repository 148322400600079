import {Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {finixSelector, refundInvoice} from '../../../slices/finix';

export const ModalRefund = ({
  invoiceID,
  refundableAmount,
  onClick,
}) => {
  onClick = onClick || (() => {
  });
  const {
    loading,
    processing,
  } = useSelector(finixSelector);
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const [modalOpen, setModalOpen] = useState(false);
  const submitForm = React.useRef(null);
  return <>
    <PayvyIconButton
      buttonText={'Refund'}
      onClick={() => setModalOpen(true)}
      mainColor={'neutral-0'}
      borderColor={'red-300'}
      textColor={'red-300'}
      hoverTextColor={'neutral-0'}
      borderSize={2}
      wrapperClassName={'my-1'}
    />
    <ModalPayvyBase
      title={`Refund Invoice`}
      isOpen={modalOpen}
      confirmButtonText={'Refund'}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          amount: refundableAmount,
        }}
        validationSchema={Yup.object({
          amount: Yup.number()
                     .required('Amount is required')
                     .min(0, 'Amount must be greater than 0')
                     .max(refundableAmount, `Amount must be less than or equal to ${refundableAmount}`),
        })}
        onSubmit={(values, helpers) => {
          const amount = values.amount * 100;
          dispatch(refundInvoice({
            invoiceID,
            amount,
            success: () => {
              helpers.resetForm();
              onClick();
              setModalOpen(false);
            },
            failure: (error) => {
              helpers.setErrors({
                nonFieldErrors: [error],
              });
            },
          }));
        }}
      >
        {props => <>
          <PayvyLabeledInput
            label={'Amount'}
            name={'amount'}
          >
            <NumericFormat
              thousandSeparator={true}
              prefix={'$'}
              placeholder="$0.00"
              decimalScale={2}
              fixedDecimalScale={true}
              value={props.values.amount}
              onValueChange={(value) => props.setFieldValue('amount', value.floatValue)}
              className={'w-full p-2 border border-neutral-50 text-lg rounded-md'}
            />
          </PayvyLabeledInput>
          <button ref={submitForm} type="hidden" onClick={() => props.handleSubmit()}/>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
