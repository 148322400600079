import React, {useEffect, useState} from "react";
import {AiOutlineLoading} from "react-icons/ai";
import {FaExclamation} from "react-icons/fa";
import {flavourTexts} from "../utils/FlavourTexts";

export const ItemImage = ({
  processed,
  firstPage
}) => {
  const [flavourId, setFlavourId] = useState(Math.floor(Math.random() * flavourTexts.length));
  const [image, setImage] = useState(null);
  useEffect(() => {
    const timer = setInterval(() => {
      const newId = Math.floor(Math.random() * flavourTexts.length);
      if(processed === null) {
        setFlavourId(newId);
      }
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [setFlavourId, processed]);
  useEffect(() => {
    if(firstPage && !image) {
      setImage(firstPage);
    }
  }, [firstPage, image]);
  let content = <img alt={'Invoice\'s first page'} src={image} className={'h-fit'}/>;
  if(processed === false) content = <>
    <FaExclamation size={64} className={'text-red-900'}/>
    <span className={'p-2 text-sm'}>There was an error processing your upload.</span>
  </>; else if(!processed) content = <div className={'p-1 flex items-center'}>
    <AiOutlineLoading className={`animate-spin`} size={32}/>
    <span className={'pl-1 text-sm'}>{flavourTexts[flavourId]}...</span>
  </div>;
  return <div
    className={'flex flex-row items-center h-72 w-full border border-slate-300 shadow-inner bg-red-100 rounded-lg overflow-hidden'}>
    {content}
  </div>;
};
