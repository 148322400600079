export function checkIfFilesAreTooBig(files) {
  let valid = true;
  if(files) {
    if(!Array.isArray(files)) files = [files];
    files.map(file => {
      const size = file.size / 1024 / 1024;
      if(size > 10) {
        valid = false;
      }
      return undefined;
    });
  }
  return valid;
}

export const checkIfFilesAreCorrectType = (files) => {
  if(!files || (Array.isArray(files) && files.length === 0)) {
    return false;
  }

  const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];

  if(!Array.isArray(files)) {
    return allowedTypes.includes(files.type) || isValidFileExtension(files.name, allowedTypes);
  }

  return files.every((file) => allowedTypes.includes(file.type) || isValidFileExtension(file.name, allowedTypes));
};

const isValidFileExtension = (fileName, allowedTypes) => {
  if(!fileName) {
    return false;
  }

  const extension = fileName.split('.')
                            .pop()
                            .toLowerCase();
  const allowedExtensions = allowedTypes.map((type) => type.split('/')
                                                           .pop());
  return allowedExtensions.includes(extension);
};
