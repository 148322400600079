import {createSlice} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import {mergeItemIntoList} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_BILL_STORAGE, DB_INTEGRATION_STORAGE, resetCacheForStore} from "./db";

export const integrationSliceInitialState = {
  loading: {
    list: false,
    item: false,
    posting: false,
    ledger: false,
  },
  numberOfItems: 0,
  items: [],
  ledgers: []
}
const integrationSlice = createSlice({
  name: 'integration',
  initialState: integrationSliceInitialState,
  reducers: {
    resetIntegrationStateToInitial: (state, action) => {
      state.loading = integrationSliceInitialState.loading;
      state.numberOfItems = integrationSliceInitialState.numberOfItems;
      state.items = integrationSliceInitialState.items;
      state.ledgers = integrationSliceInitialState.ledgers;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getIntegrations.pending, (state) => {
      state.loading.list = true;
    })
    .addCase(getIntegrations.fulfilled, (state, action) => {
      state.loading.list = false;
      state.items = action.payload;
      state.numberOfItems = action.payload.length;
    })
    .addCase(getIntegrations.rejected, (state) => {
      state.loading.list = false;
    });

    builder
    .addCase(getIntegration.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getIntegration.fulfilled, (state, action) => {
      state.loading.item = false;
      state.items = mergeItemIntoList(state.items, action.payload, true);
    })
    .addCase(getIntegration.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(getLedgerInformation.pending, (state) => {
      state.loading.ledger = true;
      state.ledgers = []
    })
    .addCase(getLedgerInformation.fulfilled, (state, action) => {
      state.loading.ledger = false;
      state.ledgers = action.payload
    })
    .addCase(getLedgerInformation.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(enableIntegration.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(enableIntegration.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(enableIntegration.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(disableIntegration.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(disableIntegration.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(disableIntegration.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(ledgerLinkContact.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(ledgerLinkContact.fulfilled, (state) => {
      state.loading.ledger = false;
    })
    .addCase(ledgerLinkContact.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(ledgerUnlinkContact.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(ledgerUnlinkContact.fulfilled, (state) => {
      state.loading.ledger = false;
    })
    .addCase(ledgerUnlinkContact.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(ledgerLinkBill.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(ledgerLinkBill.fulfilled, (state) => {
      state.loading.ledger = false;
    })
    .addCase(ledgerLinkBill.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(ledgerUnlinkBill.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(ledgerUnlinkBill.fulfilled, (state) => {
      state.loading.ledger = false;
    })
    .addCase(ledgerUnlinkBill.rejected, (state) => {
      state.loading.ledger = false;
    })
  },
})
export const {
  resetIntegrationStateToInitial
} = integrationSlice.actions;
export default integrationSlice.reducer;

// Specific Bill Thunks
export const getIntegrations = createApiThunk('integration', 'list', PAYVY_API.V1.INTEGRATIONS.INTEGRATION_LIST, DB_INTEGRATION_STORAGE, [], 'GET');
export const getIntegration = createApiThunk('integration', 'detail', PAYVY_API.V1.INTEGRATIONS.INTEGRATION_DETAILS, DB_INTEGRATION_STORAGE, [], 'GET');
export const enableIntegration = createApiThunk('integration', 'enable', PAYVY_API.V1.INTEGRATIONS.INTEGRATION_ENABLE, DB_INTEGRATION_STORAGE, [DB_INTEGRATION_STORAGE], 'POST');
export const disableIntegration = createApiThunk('integration', 'disable', PAYVY_API.V1.INTEGRATIONS.INTEGRATION_DISABLE, DB_INTEGRATION_STORAGE, [DB_INTEGRATION_STORAGE], 'POST');
export const getLedgerInformation = createApiThunk('ledgers', 'list', PAYVY_API.V1.INTEGRATIONS.LEDGER_INFORMATION, DB_INTEGRATION_STORAGE, [], 'GET');
export const ledgerLinkContact = createApiThunk('ledgers', 'linkContact', PAYVY_API.V1.INTEGRATIONS.LINK_LEDGER_CONTACT, DB_INTEGRATION_STORAGE, [], 'POST');
export const ledgerUnlinkContact = createApiThunk('ledgers', 'unlinkContact', PAYVY_API.V1.INTEGRATIONS.UNLINK_LEDGER_CONTACT, DB_INTEGRATION_STORAGE, [], 'POST');
export const ledgerLinkBill = createApiThunk('ledgers', 'linkBill', PAYVY_API.V1.INTEGRATIONS.LINK_LEDGER_BILL, DB_INTEGRATION_STORAGE, [DB_BILL_STORAGE], 'POST');
export const ledgerUnlinkBill = createApiThunk('ledgers', 'unlinkBill', PAYVY_API.V1.INTEGRATIONS.UNLINK_LEDGER_BILL, DB_INTEGRATION_STORAGE, [DB_BILL_STORAGE], 'POST');

export const resetIntegrationToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetIntegrationStateToInitial());
    await resetCacheForStore(DB_INTEGRATION_STORAGE);
  }
}
