import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {companiesSelector} from "../../../slices/companies";
import {getNotificationsCount} from "../../../slices/newInbox";
import {SidebarAndTopBar} from "./components/SidebarAndTopBar";

export const SideBarAndTopBarRoute = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    loading: companyLoading,
    loadingDetails,
    company: {id: companyId} = {},
    companies = [],
  } = useSelector(companiesSelector);
  // Find the current route based on the path
  const [minimized, _setMinimized] = React.useState(() => {
    return localStorage.getItem('sidebarMinimized') === 'true';
  });
  const setMinimized = () => {
    _setMinimized((prevMinimized) => !prevMinimized);
  };

  React.useEffect(() => {
    localStorage.setItem('sidebarMinimized', `${minimized}`);
  }, [minimized]);
  useEffect(() => {
    if(!companyLoading && !loadingDetails && !companyId) {
      navigate(companies && companies.length > 0 ? PAYVY_URL.COMPANY_SWITCH : PAYVY_URL.COMPANY_CREATE, {replace: true});
    }
  }, [companyLoading, loadingDetails, companyId, companies, navigate]);
  useEffect(() => {
    dispatch(getNotificationsCount({}));
  }, [dispatch])
  return (<SidebarAndTopBar minimized={minimized} setMinimized={setMinimized}>
      <Outlet context={{toggleMinimized: () => setMinimized(!minimized)}}/>
    </SidebarAndTopBar>
  );
};
