import React from "react";
import {Tooltip} from "react-tooltip";

export const DuplicateOfDisplay = ({
  isDuplicate,
  duplicateOf,
  isCurrentPage
}) => {
  if(!isDuplicate) return null;
  const {
    id,
    page: {url: pageUrl} = {},
    invoice_number: invoiceNumber,
  } = duplicateOf;
  return <>
    <div className={`duplicate-side-info-${id}`}> (possible duplicate)</div>
    {isCurrentPage &&
      <Tooltip anchorSelect={`.duplicate-side-info-${id}`} place={'bottom-start'} type="dark" effect="solid">
        <img
          src={pageUrl}
          alt={invoiceNumber}
          style={{
            maxWidth: '600px',
            maxHeight: '800px'
          }}
        />
      </Tooltip>}
  </>;
};
