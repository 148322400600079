import React from 'react';
import {MdPayment} from 'react-icons/md';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {ModalPairToLedgerBill} from "../../../comps/elements/ModalPairToLedgerBill/ModalPairToLedgerBill";
import {PayvyIconButton} from '../../../comps/forms';
import {ModalBillApproval, ModalBillRemove, ModalBillRequestApproval} from '../../../comps/modals';
import {PAYVY_URL} from '../../../constants';
import {build_url, formatDateForAPItoYMD} from '../../../utils/Utility';
import {ModalCreateOrUpdateBill} from "./ModalCreateOrUpdateBill";
import {ModalRetryPayment} from './ModalRetryPayment';

export function CellActions({
  column: {
    canUpdate,
    canDelete,
  },
  row: {original},
}) {
  const navigate = useNavigate();
  const {ledgers} = useSelector((state) => state.integration)

  return <>
    {(canUpdate && original.status !== 'Paid') && <ModalCreateOrUpdateBill
      billId={original.id}
      name={original.name}
      contactId={!!original.contact ? original.contact.id : 0}
      created={formatDateForAPItoYMD(original.created)}
      dueDate={formatDateForAPItoYMD(original.due_date)}
      approvers={original.approvers_list}
      popupText={'Edit bill'}
      editIcon
    />}
    {(canUpdate && original.status === 'Draft') && <ModalBillRequestApproval
      bill={original}
    />}
    {canUpdate && original.status === 'Awaiting approval' && original.can_approve ?
      <ModalBillApproval bill={original}/> : null}
    {canUpdate && original.can_retry ? <ModalRetryPayment billId={original.id}/> : null}
    {canUpdate && original.status === 'Awaiting payment' ?
      <PayvyIconButton
        Icon={MdPayment}
        onClick={() => navigate(build_url(PAYVY_URL.BILLS.PAY, {'id': original.id}))}
        tooltipContent={'Pay bill'}
      /> : null}
    {ledgers.map((ledger, index) => <ModalPairToLedgerBill key={index} item={original} ledger={ledger}/>)}
    {(canDelete && original.status !== 'Paid') &&
      <ModalBillRemove bill={original}/>}
  </>;
}
