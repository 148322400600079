import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import Debugger from '../utils/Debug';
import {build_url, stringify} from '../utils/Utility';
import {refreshAccessToken} from "./api";
import {DB_BILL_STORAGE, DB_INBOX_STORAGE, resetCacheForStore} from "./db";

export const companySliceInitialState = {
  firstTime: false,
  loading: false,
  hasErrors: false,
  company: {
    id: null,
    name: '',
    active: false,
    member_count: 0,
    members: [],
    membersOptions: [],
    active_plan: {},
    twilio_numbers: [],
    website: null,
    creator: '',
  },
  loadingDetails: false,
  companies: [],
  subscriptionsCount: 0,
  subscriptionsLoading: false,
  subscriptionsHasErrors: false,
  subscriptions: [],
  addresses: [],
  addressesLoading: false,
  addressesHasErrors: false,
  representatives: [],
  representativesLoading: false,
  representativesHasErrors: false,
  activeSubscriptions: [],
  processing: false,
  processingCompanyCreation: false,
  featureAccess: undefined,
  memberList: [],
  loadingMembers: false,
  permissionList: [],
  permissionListOptions: [],
  permissionsLoading: false,
  permissionsHasErrors: false,
  analyticsLoading: false,
  analyticsLastUpdate: 0,
  analytics: {
    money_received: 0,
    number_of_payments: 0,
    invoiced: 0,
    number_of_invoices: 0,
    outstanding_bills: 0,
    number_of_bills: 0,
    balance: 0,
    monthly_spending: [],
  },
  paymentMethodsLoading: false,
  paymentMethods: [],
};
const configureFeatureAccess = (payload) => {
  const featureAccess = [];
  if(payload.can_access_dashboard === true) featureAccess.push('dashboard');
  if(payload.can_access_contacts === true) featureAccess.push('contacts');
  if(payload.can_access_transactions === true) featureAccess.push('transactions');
  if(payload.can_access_bills === true) featureAccess.push('bills');
  if(payload.can_receive_bills === true) featureAccess.push('receive_bills');
  if(payload.can_send_bills === true) featureAccess.push('send_bills');
  if(payload.can_access_receivables === true) featureAccess.push('receivables');
  return featureAccess;
};
const companiesSlice = createSlice({
  name: 'companies',
  initialState: companySliceInitialState,
  reducers: {
    getCompanies: (state) => {
      state.loading = true;
      state.firstTime = true;
    },
    getCompaniesSuccess: (state, {payload}) => {
      let companies = payload.results;
      if(!state.company?.id) {
        state.company = companies.find(company => {
          return company.current === true;
        });
      }
      state.companies = companies.filter(company => {
        return company.current === false;
      });
      state.companies.sort((a, b) => (a.id > b.id) ? 1 : -1);
      state.loading = false;
      state.hasErrors = false;
    },
    getCompaniesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getDetailsStart: (state) => {
      state.loadingDetails = true;
    },
    getMembersLoadingStart: (state) => {
      state.loadingMembers = true;
    },
    getMembersLoadingFinish: (state) => {
      state.loadingMembers = false;
    },
    getDetailsSuccess: (state, {payload}) => {
      state.loadingDetails = false;
      for(let k in payload) {
        if(payload.hasOwnProperty(k)) state.company[k] = payload[k];
      }
      state.featureAccess = configureFeatureAccess(payload);
      if(!payload.active_plan.hasOwnProperty('status')) state.company['active_plan'] = {status: 'Incomplete'};
      state.company['membersOptions'] = payload.members.map(member => {
        return {
          key: member.id,
          value: member.id,
          label: `${member.first_name} ${member.last_name} (${member.username})`,
        };
      });

    },
    getDetailsFailure: (state) => {
      state.loadingDetails = false;
    },
    switchCompany: (state, {payload}) => {
      state.companies.push(state.company);
      state.featureAccess = undefined;
      state.company = state.companies.find(company => {
        return !!company ? company.id === payload : false;
      });
      state.companies = state.companies.filter(company => {
        return !!company ? company.id !== payload : false;
      });
      state.companies.sort((a, b) => (a.id > b.id) ? 1 : -1);
      state.loading = false;
    },
    getCompaniesMembersSuccess: (state, {payload}) => {
      state.memberList[payload.id] = payload.members;
      state.company['members'] = payload.members;
      state.company['membersOptions'] = payload.members.map(member => {
        return {
          key: member.id,
          value: member.id,
          label: `${member.first_name} ${member.last_name} (${member.username})`,
        };
      });
      state.loadingMembers = false;
    },
    getCompaniesMembersStart: (state) => {
      state.loadingMembers = true;
    },
    getSubscriptionsStart: (state) => {
      state.subscriptionsLoading = true;
      state.subscriptionsHasErrors = false;
    },
    getSubscriptionsSuccess: (state, {payload}) => {
      state.subscriptionsLoading = false;
      state.subscriptions = payload.results;
    },
    getSubscriptionsFailure: (state) => {
      state.subscriptionsLoading = false;
      state.subscriptionsHasErrors = true;
    },
    getCompanyAddressesStart: (state) => {
      state.addressesLoading = true;
      state.addressesHasErrors = false;
    },
    getCompanyAddressesSuccess: (state, {payload}) => {
      state.addressesLoading = false;
      state.addresses = payload;
    },
    getCompanyAddressesFailure: (state) => {
      state.addressesLoading = false;
      state.addressesHasErrors = true;
    },
    getCompanyRepresentativesStart: (state) => {
      state.representativesLoading = true;
      state.representativesHasErrors = false;
    },
    getCompanyRepresentativesSuccess: (state, {payload}) => {
      state.representativesLoading = false;
      state.representatives = payload;
    },
    getCompanyRepresentativesFailure: (state) => {
      state.representativesLoading = false;
      state.representatives = [];
      state.representativesHasErrors = true;
    },
    getPermissionListStart: (state) => {
      state.permissionsLoading = true;
      state.permissionsHasErrors = false;
    },
    getPermissionListSuccess: (state, {payload}) => {
      state.permissionsLoading = false;
      state.permissionList = payload;
      state.permissionListOptions = payload.map(member => {
        return {
          key: member.id,
          value: member.id,
          label: member.name,
        };
      });
    },
    getPermissionsListFailure: (state) => {
      state.permissionsHasErrors = true;
      state.permissionsLoading = false;
    },
    startProcessing: (state) => {
      state.processing = true;
    },
    finishProcessing: (state) => {
      state.processing = false;
    },
    startProcessingCompanyCreation: (state) => {
      state.processingCompanyCreation = true;
    },
    finishProcessingCompanyCreation: (state) => {
      state.processingCompanyCreation = false;
    },
    getAnalyticStart: (state, {payload: {backgroundSync = false} = {}}) => {
      if(backgroundSync === false) state.analyticsLoading = true;
    },
    getAnalyticSuccess: (state, {payload}) => {
      state.analytics = payload;
      state.analyticsLastUpdate = Date.now();
      state.analyticsLoading = false;
    },
    getAnalyticFailure: (state) => {
      state.analyticsLoading = false;
      state.analytics = companySliceInitialState.analytics;
    },
    startProcessingAnalytic: (state) => {
      state.analyticsLoading = true;
    },
    finishProcessingAnalytic: (state) => {
      state.analyticsLoading = false;
    },
    getCompanyPaymentMethodsStart: (state) => {
      state.paymentMethodsLoading = true;
    },
    getCompanyPaymentMethodsSuccess: (state, {payload}) => {
      state.paymentMethodsLoading = false;
      state.paymentMethods = payload;
    },
    getCompanyPaymentMethodsFailure: (state) => {
      state.paymentMethodsLoading = false;
      state.paymentMethods = [];
    },
  },
});

export const {
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFailure,
  switchCompany,
  getCompaniesMembersSuccess,
  getCompaniesMembersStart,
  getDetailsStart,
  getDetailsFailure,
  getDetailsSuccess,
  getAnalyticStart,
  getAnalyticSuccess,
  getAnalyticFailure,
  getSubscriptionsStart,
  getSubscriptionsFailure,
  getSubscriptionsSuccess,
  getCompanyAddressesStart,
  getCompanyAddressesFailure,
  getCompanyAddressesSuccess,
  getCompanyRepresentativesStart,
  getCompanyRepresentativesSuccess,
  getCompanyRepresentativesFailure,
  getPermissionListStart,
  getPermissionListSuccess,
  getPermissionsListFailure,
  startProcessing,
  finishProcessing,
  startProcessingAnalytic,
  finishProcessingAnalytic,
  startProcessingCompanyCreation,
  finishProcessingCompanyCreation,
  getCompanyPaymentMethodsStart,
  getCompanyPaymentMethodsSuccess,
  getCompanyPaymentMethodsFailure,
  getMembersLoadingStart,
  getMembersLoadingFinish,
} = companiesSlice.actions;
export const companiesSelector = (state) => state.companies;
export default companiesSlice.reducer;

export function getUserCompanies({
  success = noop,
  failure = noop
}) {
  Debugger.info('📡 API: COMPANY', 'Loading companies user is part of.');
  return async(dispatch) => {
    const accessToken = localStorage.getItem('accessToken');
    if(!accessToken) {
      Debugger.info('📡 API: COMPANY', 'No access token found, cannot load companies.');
      return;
    }
    dispatch(getCompanies());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.LIST_MEMBER_OF, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        success();
        dispatch(getCompaniesSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getUserCompanies({
              success,
              failure
            }));
          }
        }
        failure();
        dispatch(getCompaniesFailure());
      }
    } catch(error) {
      failure();
      dispatch(getCompaniesFailure());
    }
  };
}

export function switchCompanies({
  companyId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(getCompanies());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.SWITCH, {'id': companyId}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        dispatch(switchCompany(companyId));
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(switchCompanies({
                companyId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(getCompaniesFailure());
        failure();
      }
    } catch(error) {
      dispatch(getCompaniesFailure());
      failure();
    }
  };
}

export function brandLogoUpload({
  formData,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.UPLOAD_BRAND_LOGO, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(brandLogoUpload({
                formData,
                success,
                failure
              }));
            }
          }
        }
        failure({'nonFieldErrors': 'File upload has failed.'});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function stripeUploadDocumentVerification({
  company_id,
  representative_id,
  formData,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.UPLOAD_REPRESENTATIVE_VERIFICATION_DOCUMENTS, {
        id: company_id,
        representative_id: representative_id,
      }), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else if(response.status === 400) {
        dispatch(finishProcessing());
        failure({'nonFieldErrors': ['Account is already verified.']});
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(stripeUploadDocumentVerification({
                company_id,
                representative_id,
                formData,
                success,
                failure
              }));
            }
          }
        }
        failure({'nonFieldErrors': ['File upload has failed.']});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function dwollaUploadDocumentVerification({
  formData,
  success = noop,
  failure = noop
}) {
  success = success || noop;
  failure = failure || noop;
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(PAYVY_API.V1.DWOLLA.UPLOAD_REPRESENTATIVE_VERIFICATION_DOCUMENTS, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else if(response.status === 400) {
        dispatch(finishProcessing());
        failure({'nonFieldErrors': ['Account is already verified.']});
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(dwollaUploadDocumentVerification({
                formData,
                success,
                failure
              }));
            }
          }
        }
        failure({'nonFieldErrors': ['File upload has failed.']});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function getCompanyDetails({
  id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    if(!id) {
      Debugger.info('📡 API: COMPANY', 'No company id provided.');
      return;
    }
    dispatch(getDetailsStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.DETAILS, {id}), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        success();
        dispatch(getDetailsSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getCompanyDetails({
              id,
              success,
              failure
            }));
          }
        }
        failure();
        dispatch(getDetailsFailure());
      }
    } catch(error) {
      failure();
      dispatch(getDetailsFailure());
    }
  };
}

export function getCompanyAddresses({id}) {
  return async(dispatch) => {
    dispatch(getCompanyAddressesStart());
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.ADDRESS_LIST, {id});
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getCompanyAddressesSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getCompanyAddresses({id}));
          }
        }
        dispatch(getCompanyAddressesFailure());
      }
    } catch(error) {
      dispatch(getCompanyAddressesFailure());
    }
  };
}

export function getCompanyRepresentatives({id}) {
  return async(dispatch) => {
    dispatch(getCompanyRepresentativesStart());
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.REPRESENTATIVE_LIST, {id});
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getCompanyRepresentativesSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getCompanyRepresentatives({id}));
          }
        }
        dispatch(getCompanyRepresentativesFailure());
      }
    } catch(error) {
      dispatch(getCompanyRepresentativesFailure());
    }
  };
}

export function addOrUpdateCompanyRepresentative({
  companyId,
  representativeId,
  relationship,
  first_name,
  last_name,
  email,
  job_title,
  date_of_birth,
  line1,
  line2,
  zip,
  state,
  city,
  phone,
  ssn,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    const d = {
      relationship,
      first_name,
      last_name,
      email,
      job_title,
      date_of_birth,
      line1,
      line2,
      zip,
      state,
      city,
      phone,
      ssn,
    };
    let da = Object.fromEntries(Object.entries(d)
                                      .filter(([_, v]) => v !== ''));
    let url,
      method;
    if(representativeId === 0) {
      url = build_url(PAYVY_API.V1.COMPANY.ADD_REPRESENTATIVE, {id: companyId});
      method = 'POST';
    } else {
      url = build_url(PAYVY_API.V1.COMPANY.UPDATE_REPRESENTATIVE, {
        id: companyId,
        representative_id: representativeId,
      });
      method = 'PATCH';
    }
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify(da),
      });
      const data = await response.json();
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(addOrUpdateCompanyRepresentative({
              companyId,
              representativeId,
              relationship,
              first_name,
              last_name,
              email,
              job_title,
              date_of_birth,
              line1,
              line2,
              zip,
              state,
              city,
              phone,
              ssn,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function removeCompanyRepresentative({
  companyId,
  companyRepresentativeId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.REMOVE_REPRESENTATIVE, {
        id: companyId,
        representative_id: companyRepresentativeId,
      }), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removeCompanyRepresentative({
              companyId,
              companyRepresentativeId,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function saveCompanyInformation({
  legal_name,
  display_name,
  business_type,
  payvy_address,
  ein_ssn,
  structure,
  industry,
  business_classification,
  business_phone,
  incorporation_date,
  website,
  description,
  annual_card_volume = 0,
  max_transaction_amount = 0,
  default_statement_descriptor,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      if(annual_card_volume) annual_card_volume = Number(annual_card_volume * 100);
      if(max_transaction_amount) max_transaction_amount = Number(max_transaction_amount * 100);
      const response = await fetch(PAYVY_API.V1.COMPANY.SAVE_COMPANY_INFORMATION, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          legal_name,
          display_name,
          business_type,
          payvy_address,
          ein_ssn,
          structure,
          industry,
          business_classification,
          business_phone,
          incorporation_date,
          website,
          description,
          annual_card_volume,
          max_transaction_amount,
          default_statement_descriptor,
        }),
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        const data = await response.json();
        if(response.status === 401) {
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(saveCompanyInformation({
                legal_name,
                display_name,
                business_type,
                payvy_address,
                ein_ssn,
                structure,
                industry,
                business_classification,
                business_phone,
                incorporation_date,
                website,
                description,
                annual_card_volume,
                max_transaction_amount,
                default_statement_descriptor,
                success,
                failure
              }));
            }
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function setNewPrimaryAddress({
  companyAddressId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.SET_PRIMARY_ADDRESS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({address: companyAddressId}),
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(setNewPrimaryAddress({
                companyAddressId,
                success,
                failure
              }));
            }
          }
        }
        failure({'nonFieldErrors': 'Something went wrong.'});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function addCompanyAddress({
  line1,
  line2,
  zip,
  state,
  city,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.ADD_COMPANY_ADDRESS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          line1,
          line2,
          zip,
          state,
          city,
        }),
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(addCompanyAddress({
                line1,
                line2,
                zip,
                state,
                city,
                success,
                failure
              }));
            }
          }
        }
        failure({'nonFieldErrors': 'Something went wrong.'});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function createCompany({
  business_type,
  name,
  payvy_address,
  structure,
  industry,
  business_classification,
  website,
  description,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(startProcessingCompanyCreation());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.CREATE, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          business_type,
          name,
          payvy_address,
          structure,
          industry,
          business_classification,
          website,
          description,
        }),
      });
      const data = await response.json();
      if(response.status === 201) {
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(createCompany({
              business_type,
              name,
              payvy_address,
              structure,
              industry,
              business_classification,
              website,
              description,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessingCompanyCreation());
  };
}

export function removeCompanyAddress({
  companyAddressId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.REMOVE_ADDRESS, {id: companyAddressId}), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(removeCompanyAddress({
                companyAddressId,
                success,
                failure
              }));
            }
          }
        }
        const data = await response.json();
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}


export function getCompaniesMembers() {
  return async(dispatch) => {
    dispatch(getCompaniesMembersStart());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.LIST_MEMBER_OF, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        let companies = data.results;
        companies.map((company) => {
          dispatch(getMembersList({id: company.id}));
          return null;
        });
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getCompaniesMembers());
          }
        }
        dispatch(getDetailsFailure());
      }
    } catch(error) {
      dispatch(getDetailsFailure());
    }
  };
}

export function getMembersList({id}) {
  return async(dispatch) => {
    dispatch(getMembersLoadingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.DETAILS, {id}), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) dispatch(getCompaniesMembersSuccess(data));
      if(response.status === 401 && data.code === 'token_not_valid') {
        const tokenRefreshed = await refreshAccessToken();
        if(tokenRefreshed) {
          return await dispatch(getMembersList({id}));
        }
      }

    } catch(error) {
      dispatch(getDetailsFailure());
    }
    dispatch(getMembersLoadingFinish());
  };
}

export function getGroupPermissionList() {
  return async(dispatch) => {
    dispatch(getPermissionListStart());
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.PERMISSION_LIST, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getPermissionListSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getGroupPermissionList());
          }
        }
        dispatch(getPermissionsListFailure());
      }
    } catch(error) {
      dispatch(getPermissionsListFailure());
    }
  };
}

export function updateMemberPermission({
  companyId,
  memberId,
  permissionId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    try {
      dispatch(startProcessing());
      const url = build_url(PAYVY_API.V1.COMPANY.CHANGE_PERMISSION, {id: companyId});
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          member_id: memberId,
          permission_id: permissionId,
        }),
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else if(response.status === 400) {
        failure({'nonFieldErrors': 'User is already the owner'});
      } else if(response.status === 401) {
        const data = await response.json();
        if(data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(updateMemberPermission({
              companyId,
              memberId,
              permissionId,
              success,
              failure,
            }))
          }
        }
        failure({'nonFieldErrors': 'User is not authorised for this action'});
      } else if(response.status === 403) {
        failure({'nonFieldErrors': 'User is not a member of the company'});
      } else if(response.status === 404) {
        failure({'nonFieldErrors': 'User does not exist'});
      } else {
        failure({'nonFieldErrors': 'Something went wrong.'});
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function removeTeamMember({
  memberId,
  companyId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.REMOVE_MEMBER, {
        id: companyId,
        member_id: memberId,
      }), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removeTeamMember({
              memberId,
              companyId,
              success,
              failure,
            }))
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function inviteTeamMember({
  companyId,
  email,
  permissionId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.INVITE_USER, {id: companyId});
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          email: email,
          group: permissionId,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(inviteTeamMember({
              companyId,
              email,
              permissionId,
              success,
              failure,
            }))
          }
        }

        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function companyPassOwnership({
  companyId,
  memberId,
  password,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    try {
      dispatch(startProcessing());
      const url = build_url(PAYVY_API.V1.COMPANY.PASS_OWNERSHIP, {
        id: companyId,
        member_id: memberId,
      });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({password: password}),
      });
      if(response.status >= 200 && response.status < 300) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(companyPassOwnership({
              companyId,
              memberId,
              password,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function resendTeamMemberInvitation({
  email,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.INVITE_RESEND, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({email: email}),
      });
      if(response.status === 202) {
        dispatch(finishProcessing());
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(resendTeamMemberInvitation({
              email,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
  };
}

export function submitCompanyForStripeVerification({
  companyId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.SUBMIT_COMPANY_TO_STRIPE_VERIFICATION, {id: companyId});
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(submitCompanyForStripeVerification({
              companyId,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function submitCompanyForFinancialVerification({
  companyId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.SUBMIT_COMPANY_TO_FINANCIAL_VERIFICATION, {id: companyId});
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(submitCompanyForFinancialVerification({
              companyId,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function getAnalytics({
  id,
  backgroundSync = false,
  failure = noop,
}) {
  return async(dispatch) => {
    if(!id) {
      Debugger.debug('📡 API: COMPANY', 'No company id provided for getAnalytics.');
      return;
    }
    dispatch(getAnalyticStart({backgroundSync}));
    Debugger.info('📡 API: COMPANY', 'Loading company analytics.');
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.ANALYTIC, {id}), {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getAnalyticSuccess(data));
      } else if(response.status === 401 && data.code === 'token_not_valid') {
        const tokenRefreshed = await refreshAccessToken();
        if(tokenRefreshed) {
          return await dispatch(getAnalytics({
            id,
            backgroundSync
          }));
        }
        failure()
      } else {
        failure()
      }
    } catch(error) {
      failure()
      dispatch(getAnalyticFailure());
    }
  };
}

export function updateAnalytics({
  id,
  success = noop,
  failure = noop
}) {
  Debugger.info('📡 API: COMPANY', 'Updating company analytics.');
  return async(dispatch) => {
    dispatch(startProcessingAnalytic());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.UPDATE_ANALYTIC, {id}), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        dispatch(finishProcessingAnalytic());
        success();
      } else if(response.status === 401) {
        const data = await response.json();
        if(data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(updateAnalytics({
              id,
              success,
              failure
            }));
          }
        }
      }
    } catch(error) {
      dispatch(finishProcessingAnalytic());
      failure();
    }
  };
}

export function getCompanyPaymentMethods({
  companyId,
  status = '',
}) {
  return async(dispatch) => {
    dispatch(getCompanyPaymentMethodsStart());
    const params = stringify({
      status: status,
    }, {skipEmptyString: true});
    try {
      const url = build_url(PAYVY_API.V1.COMPANY.PAYMENT_METHODS, {id: companyId});
      const response = await fetch(`${url}?${params}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getCompanyPaymentMethodsSuccess(data));
      } else if(response.status === 401 && data.code === 'token_not_valid') {
        const tokenRefreshed = await refreshAccessToken();
        if(tokenRefreshed) {
          return await dispatch(getCompanyPaymentMethods({
            companyId,
            status,
          }));
        }
      } else {
        dispatch(getCompanyPaymentMethodsFailure());
      }
    } catch(error) {
      dispatch(getCompanyPaymentMethodsFailure());
    }
  };
}


/* Helper functions */

export function hasPermission(company, userId, permissionName) {
  if(!!company && company.hasOwnProperty('members')) {
    const members = company.members;
    const member = members.find((member) => member.id === userId);
    if(member !== undefined && member.hasOwnProperty('permission')) {
      const perm = member.permission[permissionName];
      if(perm === true || perm === false) return perm;
    }
  }
  return false;
}

export function checkCompanyInviteStatus({
  hash,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.INVITE_USER_STATUS, {hash}), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        success(data);
      } else {
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function acceptCompanyInvite({
  hash,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.INVITE_USER_ACCEPT, {hash}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        success();
      } else if(response.status === 401) {
        const data = await response.json();
        if(data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(acceptCompanyInvite({
              hash,
              success,
              failure
            }));
          }
        }
        failure(data);
      } else {
        const data = await response.json();
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function declineCompanyInvite({
  hash,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(startProcessing());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.INVITE_USER_DECLINE, {hash}), {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        success(data);
      } else if(response.status === 401) {
        failure(data);
      } else {
        const data = await response.json();
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(finishProcessing());
  };
}

export function getCacheRefreshInformation() {
  return async(dispatch) => {
    try {
      const response = await fetch(PAYVY_API.V1.COMPANY.REFRESH_CACHE_INFO, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const {
        bills,
        inbox
      } = await response.json();
      if(bills) await resetCacheForStore(DB_BILL_STORAGE);
      if(inbox) await resetCacheForStore(DB_INBOX_STORAGE);
    } catch(error) {
    }
  };
}
