import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {getQuickbooksAccounts} from '../../../slices/newQuickbooks';

export const SelectAccountingCategory = ({
  value,
  setField,
  readOnly = false,
  clearable = true
}) => {
  setField = setField || noop;
  const [valueObject, setValueObject] = React.useState(null);
  const dispatch = useDispatch();
  const {
    loading: {list: loading},
    expenseAccounts,
  } = useSelector((state) => state.integrationQuickbooks);

  useEffect(() => {
    if(!!value) setValueObject(expenseAccounts.find(account => account.id === value) || {});
  }, [value, expenseAccounts]);
  useEffect(() => {
    dispatch(getQuickbooksAccounts({
      is_expense: true
    }));
  }, [dispatch]);

  const triggerLocalFieldChange = (option) => {
    setField(option?.id || null);
    setValueObject(option);
  };

  return <>
    <Select
      className={'w-full'}
      placeholder="Expense Account"
      isLoading={loading}
      isDisabled={readOnly}
      value={valueObject}
      onChange={(option) => triggerLocalFieldChange(option)}
      options={expenseAccounts}
      menuPlacement={'top'}
      isClearable={clearable}
    />
  </>;
};
