import React from 'react';
import Content from './Content';
import Information from './Information';
import Sidebar from './Sidebar';

const Page = ({
  bill,
  billPermission,
  currentPage,
  setCurrentPage,
}) => {
  return <div className={'w-full flex-col md:flex-row flex h-max md:h-full'}>
    <Sidebar
      bill={bill}
      billPermission={billPermission}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
    <Content
      pages={bill.pages}
      currentPage={currentPage}
    />
    <Information
      bill={bill}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      billPermission={billPermission}
    />
  </div>;
};

export default Page;
