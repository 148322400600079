const Debug = (type = 'debug', ...rest) => {
    let params = rest;
    if (rest.length >= 2) {
        const [identifier, ...restWOIdentifier] = rest;
        params = [`%c ${identifier}`, 'color: #ff73fd;font-size: 1.5em;', ...restWOIdentifier];
    }
    if (process.env.NODE_ENV !== 'development') return;
    console[type](...params);
};

const Debugger = {
    debug: (...rest) => Debug('debug', ...rest),
    info: (...rest) => Debug('info', ...rest),
    log: (...rest) => Debug('log', ...rest),
    error: (...rest) => Debug('error', ...rest),
    warn: (...rest) => Debug('warn', ...rest),
    table: (...rest) => Debug('table', ...rest),
};

export default Debugger;