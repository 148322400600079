import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {CleanedHTML} from "../../../comps/elements";
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_URL} from '../../../constants';
import {disableIntegration, enableIntegration, getIntegration} from '../../../slices/newIntegration';
import {getConnectionInfo, verifyOAuthConnection,} from '../../../slices/newQuickbooks';
import {build_url, parsify} from '../../../utils/Utility';
import {BankAccountMapping} from "../components/BankAccountMapping";

export const QuickbooksSettings = ({
  integration: {
    id,
    name,
    logo,
    description,
    enabled
  }
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    loading: {
      item: loading,
      posting: processing
    },
    suspended,
  } = useSelector((state) => state.integrationQuickbooks);
  const {
    state,
    code,
    realmId,
  } = parsify(location.search);
  useEffect(() => {
    dispatch(getConnectionInfo({}));
  }, [dispatch]);
  useEffect(() => {
    if(state && code && realmId && !loading && !processing) {
      dispatch(verifyOAuthConnection({
        body: {
          code,
          state,
          realm_id: realmId,
        },
        successCallback: () => {
          dispatch(getIntegration({
            id,
            successCallback: () => {
              dispatch(getConnectionInfo({}));
            }
          }));
        }
      }));
      navigate(build_url(PAYVY_URL.SETTINGS.INTEGRATION_DETAILS, {id}));
    }
  }, [dispatch, navigate, state, realmId, code, id, loading, processing]);
  const connectIntegration = () => {
    dispatch(enableIntegration({
      id,
      successCallback: (data) => {
        if(data['method'] === 'redirect') {
          window.location = data.url;
        }
      }
    }));
  };
  const disconnectIntegration = () => {
    dispatch(disableIntegration({
      id,
      successCallback: () => {
        dispatch(getIntegration({id}));
      }
    }));
  };
  return <div className={'flex flex-col'}>
    <div className={'flex flex-row py-2'}>
      <h1 className={'flex h-24 w-24'}>{logo ?
        <img src={logo} alt={name} className={'w-24 max-h-24 rounded self-center'}/> : name}</h1>
      <CleanedHTML html={description} className={'justify-center flex flex-col mx-5'}/>
      <div className={'flex grow justify-end self-center'}>
        {enabled ?
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Disconnect from QuickBooks'}
              onClick={disconnectIntegration}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full'}
            />
          </div> :
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Connect to QuickBooks'}
              onClick={connectIntegration}
              loading={loading || processing}
              fullWidth={true}
              mainColor={'green-200'}
              hoverColor={'green-200'}
              iconPaddingY={2}
              iconPaddingX={6}
              wrapperClassName={'w-full quickbooks-button-wrapper'}
            />
            {suspended ? <small className={'text-danger'}>Status: Suspended</small> : null}
          </div>}
      </div>
    </div>
    {enabled ? <div className={'grid grid-cols-1 gap-5 w-full'}>
      <BankAccountMapping/>
    </div> : null}
  </div>;
};
