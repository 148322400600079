import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import {refreshAccessToken} from "../utils/Utility";

export const initialState = {
  loading: false,
  hasErrors: false,
  availableNumbers: [],
  processing: false,
};

const twilioSlice = createSlice({
  name: 'twilio',
  initialState,
  reducers: {
    searchNumberStart: (state) => {
      state.loading = true;
    },
    searchNumberSuccess: (state, {payload}) => {
      state.loading = false;
      state.hasErrors = false;
      state.availableNumbers = payload.numbers;
    },
    searchNumberReset: (state) => {
      state.availableNumbers = [];
    },
    searchNumberFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  searchNumberStart,
  searchNumberSuccess,
  searchNumberReset,
  searchNumberFailure,
  processingStart,
  processingFinish,
} = twilioSlice.actions;
export const twilioSelector = (state) => state.twilio;
export default twilioSlice.reducer;

export function searchFaxNumber({
  area,
  contains,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(searchNumberStart());
    try {
      const response = await fetch(PAYVY_API.V1.TWILIO.SEARCH_NUMBERS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          area,
          contains
        }),
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(searchNumberSuccess(data));
        success(data.numbers);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(searchFaxNumber({
              area,
              contains,
              success,
              failure
            }));
          }
        }
        failure(data);
        dispatch(searchNumberFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(searchNumberFailure());
    }
  };
}

export function resetAvailableNumbers() {
  return async(dispatch) => {
    dispatch(searchNumberReset());
  };
}

export function buyFaxNumber({
  number,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.TWILIO.BUY_NUMBERS, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({number}),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(buyFaxNumber({
              number,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function removeFaxNumber({
  number,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.TWILIO.REMOVE_NUMBERS, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({number}),
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removeFaxNumber({
              number,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}
