import DWOLLA from './static/media/dwolla_gray.svg';
import FINIX from './static/media/finix.png';
import BLUE from './static/media/payvy-logo-blue.png';
import RED from './static/media/payvy-logo-red.png';
import QBO from './static/media/qbo.png';
import STOP from './static/media/stop.svg';
import SUBSCRIPTION from './static/media/subs.png';

function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

let alternativeBaseURL = '';
if(!areWeTestingWithJest()) {
  if(typeof window !== 'undefined' && window.location) {
    alternativeBaseURL = `http://${window.location.hostname}:8000`;
  }
}

export const API_BASE_URL = process.env.REACT_APP_BASE_URL || alternativeBaseURL;
export const LOGIN_EMAIL = process.env.REACT_APP_LOGIN_EMAIL;
export const LOGIN_PASSWORD = process.env.REACT_APP_LOGIN_PASSWORD;
export const LOCAL_ENVIRONMENT = process.env.REACT_APP_LOCAL_ENVIRONMENT === 'true';
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
export const FINIX_APPLICATION_ID = process.env.REACT_APP_FINIX_APPLICATION_ID;
export const FINIX_ENVIRONMENT = process.env.REACT_APP_FINIX_ENVIRONMENT || 'sandbox';
export const FINIX_PROCESSOR = process.env.REACT_APP_FINIX_PROCESSOR || 'LITLE_V1';
export const SENTRY_URL = process.env.REACT_APP_SENTRY_URL;
export const API_V1_URL = `${API_BASE_URL}/api/v1`;
export const JEST_TIMEOUT = 60 * 1000;
const PREFIX = '';

export const PAYVY_URL = {
  ADMIN_SITE: `${API_BASE_URL}/admin`,
  HELP_SITE: `https://help.payvy.com/`,
  TOS: `https://payvy.com/terms`,
  PRIVACY: `https://payvy.com/privacy`,
  DWOLLA_TOS: `https://www.dwolla.com/legal/tos/`,
  DWOLLA_PRIVACY: `https://www.dwolla.com/legal/privacy/`,
  DASHBOARD: `${PREFIX}/`,
  TESTING: `${PREFIX}/test/`,
  INBOX: {
    RECEIVED: `${PREFIX}/inbox/received`,
    HISTORY: `${PREFIX}/inbox/history`,
    DETAILS: `${PREFIX}/inbox/:id`,
  },
  INVOICE: {
    LIST: `${PREFIX}/invoices`,
    CREATE: `${PREFIX}/invoices/new`,
    EDIT: `${PREFIX}/invoices/:id/edit`,
    DETAILS: `${PREFIX}/invoices/:id`,
    PAY: `${PREFIX}/invoices/:id/pay`,
  },
  DISPUTES: {
    DETAILS: `${PREFIX}/invoices/:invoiceId/disputes/:disputeId`,
  },
  BILLS: {
    CREATE: `${PREFIX}/bills/new`,
    APPROVE: `${PREFIX}/bills/approve`,
    LIST: `${PREFIX}/bills`,
    DETAILS: `${PREFIX}/bills/:id`,
    PAY: `${PREFIX}/bills/:id/pay`,
  },
  CONTACTS: {
    CREATE: `${PREFIX}/contacts/new`,
    CREATE_VENDOR: `${PREFIX}/contacts/new/vendor`,
    CREATE_CUSTOMER: `${PREFIX}/contacts/new/:type`,
    LIST: `${PREFIX}/contacts`,
    DETAILS: `${PREFIX}/contacts/:id`,
    UPDATE: `${PREFIX}/contacts/:id/update`,
  },
  TRANSACTION: {
    LIST: `${PREFIX}/transactions`,
    RECEIVE: `${PREFIX}/transactions/receive`,
  },
  SETTINGS: {
    ACCOUNT: `${PREFIX}/settings/account`,
    BILLING: `${PREFIX}/settings/billing`,
    BANKING: `${PREFIX}/settings/banking`,
    NOTIFICATIONS: `${PREFIX}/settings/notifications`,
    VIRTUAL_BOX: `${PREFIX}/settings/virtual-box`,
    INTEGRATIONS: `${PREFIX}/settings/integrations`,
    INTEGRATION_DETAILS: `${PREFIX}/settings/integrations/:id`,
    TEAM: `${PREFIX}/settings/team`,
  },
  LANDING_PAGES: {
    INVOICE_PAYMENT: `${PREFIX}/invoice-payment/:id`,
    WISE_LINKING: `${PREFIX}/wise-linking`,
    REVOLUT_LINKING: `${PREFIX}/revolut-linking`
  },
  LOGIN: `${PREFIX}/login`,
  REGISTER: `${PREFIX}/register`,
  INACTIVITY: `${PREFIX}/inactivity`,
  INVITED_USER: `${PREFIX}/invitation/vendor/:hash/`,
  INVITED_COMPANY_USER: `${PREFIX}/invitation/:hash/`,
  COMPANY_CREATE: `${PREFIX}/company/create`,
  COMPANY_SWITCH: `${PREFIX}/company/switch`,
  ACCOUNT_VERIFICATION: `${PREFIX}/account/created`,
  ACCOUNT_VERIFICATION_WITH_HASH: `${PREFIX}/account/verify/:hash`,
  FORGOTTEN_PASSWORD: `${PREFIX}/password-reset`,
  FORGOTTEN_PASSWORD_WITH_HASH: `${PREFIX}/account/password-reset/:hash`,
};

export const PAYVY_LOGOS = {
  RED,
  BLUE,
  QBO,
  DWOLLA,
  FINIX
};

export const PAYVY_STATIC_IMAGES = {
  STOP,
  SUBSCRIPTION,
};


export const PAYVY_API = {
  TOKEN: {
    AUTH: `${API_BASE_URL}/api/token/auth/`,
    REFRESH: `${API_BASE_URL}/api/token/refresh/`,
  },
  V1: {
    LIVE: `${API_V1_URL}/live/`,
    USER: {
      CURRENT: `${API_V1_URL}/user/current/`,
      NOTIFICATION_SETTINGS: `${API_V1_URL}/user/notifications/settings/`,
      CHANGE_ADDRESS: `${API_V1_URL}/user/address/`,
      CHANGE_PASSWORD: `${API_V1_URL}/user/change-password/`,
      UPDATE_USER: `${API_V1_URL}/user/:id/`,
      ADD_PAYMENT_METHOD: `${API_V1_URL}/user/payment-method/add/`,
      REMOVE_PAYMENT_METHOD: `${API_V1_URL}/user/payment-method/remove/`,
      LIST_PAYMENT_METHODS: `${API_V1_URL}/user/payment-method/`,
      LIST_SUBSCRIPTION_PLANS: `${API_V1_URL}/user/subscription-plans/`,
      LIST_ACH_FEES: `${API_V1_URL}/user/subscription-plans/ach-fee/`,
      LIST_CHECK_ISSUING: `${API_V1_URL}/user/subscription-plans/check-issuing/`,
      LIST_TWILIO_NUMBERS: `${API_V1_URL}/user/subscription-plans/twilio-number/`,
      LIST_INVOICES: `${API_V1_URL}/user/invoices/`,
      UPDATE_SUBSCRIPTION: `${API_V1_URL}/user/subscriptions/update/`,
      START_SUBSCRIPTION: `${API_V1_URL}/user/subscriptions/start/`,
      END_SUBSCRIPTION: `${API_V1_URL}/user/subscriptions/end/`,
    },
    TFA: {
      CHECK_HAS_TFA: `${API_V1_URL}/login/tfa/check/`,
      CREATE_TOTP: `${API_V1_URL}/user/tfa/create/app/`,
      CREATE_SMS: `${API_V1_URL}/user/tfa/create/sms/`,
      VALIDATE: `${API_V1_URL}/user/tfa/validate/`,
      VERIFY: `${API_V1_URL}/user/tfa/verify/`,
      REMOVE: `${API_V1_URL}/user/tfa/remove/`,
    },
    REGISTRATION: {
      ACCOUNT: `${API_V1_URL}/registration/`,
      COMPANY: `${API_V1_URL}/registration/`,
      ACTIVATE: `${API_V1_URL}/registration/activate/:hash/`,
      FORGOTTEN_PASSWORD: `${API_V1_URL}/registration/forgotten-password/`,
      VERIFY_FORGOTTEN_PASSWORD: `${API_V1_URL}/registration/forgotten-password/:hash/`,
    },
    COMPANY: {
      CREATE: `${API_V1_URL}/company/`,
      LIST_MEMBER_OF: `${API_V1_URL}/company/`,
      DETAILS: `${API_V1_URL}/company/:id/`,
      SWITCH: `${API_V1_URL}/company/switch/:id/`,
      UPLOAD_BRAND_LOGO: `${API_V1_URL}/company/brand-logo/`,
      SAVE_COMPANY_INFORMATION: `${API_V1_URL}/company/save-information/`,
      ADDRESS_LIST: `${API_V1_URL}/company/address/`,
      ADD_COMPANY_ADDRESS: `${API_V1_URL}/company/address/add/`,
      REMOVE_ADDRESS: `${API_V1_URL}/company/address/:id/`,
      REPRESENTATIVE_LIST: `${API_V1_URL}/company/:id/representatives/`,
      ADD_REPRESENTATIVE: `${API_V1_URL}/company/:id/representatives/add/`,
      UPDATE_REPRESENTATIVE: `${API_V1_URL}/company/:id/representatives/:representative_id/`,
      UPLOAD_REPRESENTATIVE_VERIFICATION_DOCUMENTS: `${API_V1_URL}/company/:id/representatives/:representative_id/upload-document/`,
      REMOVE_REPRESENTATIVE: `${API_V1_URL}/company/:id/representatives/:representative_id/remove/`,
      ACCEPT_INVITE: `${API_V1_URL}/company/invite/accept/:hash/`,
      SET_PRIMARY_ADDRESS: `${API_V1_URL}/company/primary-address/`,
      PERMISSION_LIST: `${API_V1_URL}/company/permissions/`,
      CHANGE_PERMISSION: `${API_V1_URL}/company/:id/change-permission/`,
      REMOVE_MEMBER: `${API_V1_URL}/company/:id/remove-member/:member_id/`,
      INVITE_USER: `${API_V1_URL}/company/:id/invite/`,
      INVITE_USER_STATUS: `${API_V1_URL}/company/invite/:hash/check/`,
      INVITE_USER_ACCEPT: `${API_V1_URL}/company/invite/:hash/accept/`,
      INVITE_USER_DECLINE: `${API_V1_URL}/company/invite/:hash/decline/`,
      SUBMIT_COMPANY_TO_STRIPE_VERIFICATION: `${API_V1_URL}/company/:id/stripe-verify/`,
      SUBMIT_COMPANY_TO_FINANCIAL_VERIFICATION: `${API_V1_URL}/company/:id/financial-verification/`,
      PASS_OWNERSHIP: `${API_V1_URL}/company/:id/pass-ownership/:member_id/`,
      INVITE_RESEND: `${API_V1_URL}/company/invite/resend/`,
      ANALYTIC: `${API_V1_URL}/company/:id/analytics/`,
      UPDATE_ANALYTIC: `${API_V1_URL}/company/:id/analytics/update/`,
      PAYMENT_METHODS: `${API_V1_URL}/company/:id/payment-methods/`,
      REFRESH_CACHE_INFO: `${API_V1_URL}/company/refresh-cache-info/`,
    },
    KYC: {
      BANK_ACCOUNT_LIST: `${API_V1_URL}/kyc/bank-account/`,
      REMOVE_BANK_ACCOUNT: `${API_V1_URL}/kyc/bank-account/:id/`,
      ADD_BANK_ACCOUNT: `${API_V1_URL}/kyc/bank-account/add/`
    },
    OCR: {
      OCR_PAGE_LIST: `${API_V1_URL}/ocr/item/:id/ocr-pages/`,
      OCR_PAGE_DETAILS: `${API_V1_URL}/ocr/item/:id/ocr-pages/:page/`,
      UPDATE_OCR_FIELD: `${API_V1_URL}/ocr/item/:id/ocr-pages/field/:field_id/`
    },
    INBOX: {
      ITEM_LIST: `${API_V1_URL}/inbox/item/`,
      NOTIFICATIONS_COUNT: `${API_V1_URL}/inbox/item/notifications/`,
      ITEM: `${API_V1_URL}/inbox/item/:id/`,
      ITEM_STARRED: `${API_V1_URL}/inbox/item/:id/toggle-starred/`,
      ITEM_ASSIGN_BILL: `${API_V1_URL}/inbox/item/:id/assign/`,
      ITEM_CREATE_BILL: `${API_V1_URL}/inbox/item/:id/create-bill/`,
      ITEM_PROCESSING_INFO: `${API_V1_URL}/inbox/item/:id/processing-info/`,
      PAGE_ROTATE: `${API_V1_URL}/inbox/item/:id/page/:page/rotate/`,
      PAGE_MARK_AS_NON_DUPLICATE: `${API_V1_URL}/inbox/item/:id/page/:page/mark-as-non-duplicate/`,
      PAGE_REMOVE: `${API_V1_URL}/inbox/item/:id/page/:page/`,
      PAGE_UPDATE: `${API_V1_URL}/inbox/item/:id/page/:page/`,
      PAGE_ASSIGN_BILL: `${API_V1_URL}/inbox/item/:id/page/:page/assign/`,
    },
    INTEGRATIONS: {
      INTEGRATION_LIST: `${API_V1_URL}/integrations/`,
      INTEGRATION_DETAILS: `${API_V1_URL}/integrations/:id/`,
      INTEGRATION_DISABLE: `${API_V1_URL}/integrations/:id/disable/`,
      INTEGRATION_ENABLE: `${API_V1_URL}/integrations/:id/enable/`,
      LEDGER_INFORMATION: `${API_V1_URL}/integrations/ledger-information/`,
      LINK_LEDGER_CONTACT: `${API_V1_URL}/integrations/ledger-link-contact/`,
      UNLINK_LEDGER_CONTACT: `${API_V1_URL}/integrations/ledger-unlink-contact/`,
      LINK_LEDGER_BILL: `${API_V1_URL}/integrations/ledger-link-bill/`,
      UNLINK_LEDGER_BILL: `${API_V1_URL}/integrations/ledger-unlink-bill/`,
    },
    BILLS: {
      BILL_LIST: `${API_V1_URL}/bill/`,
      BILL_CREATE: `${API_V1_URL}/bill/`,
      BILL_UPDATE: `${API_V1_URL}/bill/:id/`,
      BILL_REMOVE: `${API_V1_URL}/bill/:id/`,
      BILL_DETAILS: `${API_V1_URL}/bill/:id/`,
      BILL_HISTORY: `${API_V1_URL}/bill/:id/change-history/`,
      BILL_LEDGER_HISTORY: `${API_V1_URL}/bill/:id/ledger-history/`,
      BILL_PAY: `${API_V1_URL}/bill/:id/pay/`,
      BILL_SEND_COMMENT: `${API_V1_URL}/bill/:id/comment/`,
      BILL_CHANGE_STATUS: `${API_V1_URL}/bill/:id/change-status/`,
      BILL_BY_CONTACT: `${API_V1_URL}/bill/by-contact/:id/`,
      BILL_REORDER_PAGES: `${API_V1_URL}/bill/:id/reorder/`,
      BILL_PAGE_LIST: `${API_V1_URL}/bill/:id/page/`,
      BILL_PAGE_CREATE: `${API_V1_URL}/bill/:id/page/`,
      BILL_PAGE_UPDATE: `${API_V1_URL}/bill/:id/page/:page/`,
      BILL_PAGE_REMOVE: `${API_V1_URL}/bill/:id/page/:page/`,
      BILL_PAGE_MOVE: `${API_V1_URL}/bill/:id/page/:page/move/:newBill/`,
      FAILED_TRANSACTIONS: `${API_V1_URL}/bill/:id/repay/`,
      REPAY_TRANSACTION: `${API_V1_URL}/bill/:billId/repay/:transactionId/`,
    },
    TRANSACTION: {
      TRANSACTION_LIST: `${API_V1_URL}/transactions/`,
      TRANSACTION_REMOVE: `${API_V1_URL}/transactions/:id/`,
      TRANSACTION_DOWNLOAD: `${API_BASE_URL}/transaction/download/:type/:companyHash/`,
    },
    CONTACT: {
      CONTACT_LIST: `${API_V1_URL}/contact/`,
      CONTACT_DETAILS: `${API_V1_URL}/contact/:id/`,
      CONTACT_REMOVE: `${API_V1_URL}/contact/:id/`,
      CONTACT_CREATE: `${API_V1_URL}/contact/`,
      CONTACT_UPDATE: `${API_V1_URL}/contact/:id/`,
      CONTACT_ACH_REMOVE: `${API_V1_URL}/contact/:id/remove-account/`,
      PAYMENTS_FOR_CONTACT: `${API_V1_URL}/contact/:id/payments/`,
      CONTACT_LEDGER_HISTORY: `${API_V1_URL}/contact/:id/ledger-history/`,
      CONTACT_OVERVIEW: `${API_V1_URL}/contact/:id/overview/`,
      LIST_PAYMENT_METHODS: `${API_V1_URL}/contact/:id/payment-methods/`,
      ADD_PAYMENT_METHOD: {
        FINIX: `${API_V1_URL}/contact/:id/payment-methods/add/finix/`,
        DWOLLA: `${API_V1_URL}/contact/:id/payment-methods/add/dwolla/`,
      },
      REMOVE_PAYMENT_METHOD: `${API_V1_URL}/contact/:id/payment-methods/:paymentMethodId/remove/`,
      CONTACT_SEND_INVITATION: `${API_V1_URL}/contact/:id/invite/`,
      CONTACT_CHECK_INVITATION_STATUS: `${API_V1_URL}/contact/invite/:hash/check/`,
      CONTACT_ACCEPT_INVITATION: `${API_V1_URL}/contact/invite/:hash/accept/`,
    },
    TWILIO: {
      SEARCH_NUMBERS: `${API_V1_URL}/twilio/available-numbers/`,
      BUY_NUMBERS: `${API_V1_URL}/twilio/available-numbers/buy/`,
      REMOVE_NUMBERS: `${API_V1_URL}/twilio/available-numbers/remove/`,
    },
    PLAID: {
      GET_LINK_TOKEN: `${API_V1_URL}/plaid/create-link-token/`,
      GET_UPDATE_TOKEN: `${API_V1_URL}/plaid/update-link-token/:id/`,
      CREATE_ACCESS_TOKEN: `${API_V1_URL}/plaid/get-access-token/`,
      BANK_ACCOUNT_LIST: `${API_V1_URL}/plaid/bank-accounts/`,
      LINK_PLAID_ACCOUNT: `${API_V1_URL}/plaid/link-account/`,
      REMOVE_PLAID_ACCOUNT: `${API_V1_URL}/plaid/:id/remove/`,
      LINK_PLAID_ACCOUNT_TO_SERVICE: `${API_V1_URL}/plaid/link-to-service/:service/:id/`,
    },
    BOX: {
      CONNECTION_INFO: `${API_V1_URL}/box/connection/`,
      VERIFY: `${API_V1_URL}/box/verification/`,
      RENAME_IMPORT_FOLDER: `${API_V1_URL}/box/import-rename/`,
      RENAME_EXPORT_FOLDER: `${API_V1_URL}/box/export-rename/`,
    },
    DROPBOX: {
      CONNECTION_INFO: `${API_V1_URL}/dropbox/connection/`,
      VERIFY: `${API_V1_URL}/dropbox/verification/`,
      RENAME_IMPORT_FOLDER: `${API_V1_URL}/dropbox/import-rename/`,
      RENAME_EXPORT_FOLDER: `${API_V1_URL}/dropbox/export-rename/`,
    },
    QUICKBOOKS: {
      ACCOUNT_LIST: `${API_V1_URL}/qb/accounts/`,
      ACCOUNT_LINK: `${API_V1_URL}/qb/link/`,
      CLASS_LIST: `${API_V1_URL}/qb/classes/`,
      SET_EXPENSE_ACCOUNT: `${API_V1_URL}/qb/add/expense-account/`,
      CONNECTION_INFO: `${API_V1_URL}/qb/connection/`,
      VERIFY: `${API_V1_URL}/qb/verification/`,
    },
    DWOLLA: {
      CLASSIFICATION_LIST: `${API_V1_URL}/dwolla/classifications/`,
      UPLOAD_REPRESENTATIVE_VERIFICATION_DOCUMENTS: `${API_V1_URL}/dwolla/verification-document/`,
    },
    FINIX: {
      MERCHANT: `${API_V1_URL}/finix/merchant_id/`,
      CONTACT_IDENTITY_ID: `${API_V1_URL}/finix/contact/:id/identity-id/`,
      ADD_PAYMENT_METHOD: `${API_V1_URL}/finix/payment-instruments/add/`,
      PUBLIC_ADD_PAYMENT_METHOD: `${API_V1_URL}/finix/public/invoice/:id/payment-instruments/add/`,
      REFUND_INVOICE: `${API_V1_URL}/finix/invoice/:id/refund/`,
      INVOICE_IDENTITIES: `${API_V1_URL}/finix/public/invoice/:id/identities/`,
      UPLOAD_EVIDENCE: `${API_V1_URL}/finix/invoice/:invoiceId/dispute/:disputeId/evidence/`,
      ACCEPT_DISPUTE: `${API_V1_URL}/finix/invoice/:invoiceId/dispute/:disputeId/accept/`,
      CREATE_CONTACT_IDENTITY: `${API_V1_URL}/finix/contact/:contactId/create/`,
    },
    RECEIVABLES: {
      INVOICE_LIST: `${API_V1_URL}/receivables/invoices/`,
      INVOICE_CREATE: `${API_V1_URL}/receivables/invoices/`,
      INVOICE_UPDATE: `${API_V1_URL}/receivables/invoices/:id/`,
      SEND_OUT_INVOICE: `${API_V1_URL}/receivables/invoices/:id/send-out/`,
      CAPTURE_INVOICE: `${API_V1_URL}/receivables/invoices/:id/capture/`,
      CHARGE_INVOICE: `${API_V1_URL}/receivables/invoices/:id/charge/`,
      VOID_INVOICE: `${API_V1_URL}/receivables/invoices/:id/void/`,
      DETAILS: `${API_V1_URL}/receivables/invoices/:id/`,
      PUBLIC_DETAILS: `${API_V1_URL}/receivables/public/invoices/:id/`,
      INVOICE_DOWNLOAD: `${API_BASE_URL}/receivables/download/pdf/:id/`,
    },
    PAYVYGPT: {
      ADD_TOKEN: `${API_V1_URL}/payvygpt/add-token/`,
      CONNECTION_INFO: `${API_V1_URL}/payvygpt/connection/`,
      ROOMS: `${API_V1_URL}/payvygpt/rooms/`,
      CREATE_ROOM: `${API_V1_URL}/payvygpt/rooms/create/`,
      LATEST_ROOM: `${API_V1_URL}/payvygpt/rooms/latest/`,
      SPECIFIC_ROOM: `${API_V1_URL}/payvygpt/rooms/:roomId/`,
      ADD_MESSAGE_TO_ROOM: `${API_V1_URL}/payvygpt/rooms/:roomId/add-message/`,
      DELETE_ROOM: `${API_V1_URL}/payvygpt/rooms/:roomId/delete/`
    },
    PAYMENT_SYSTEM: {
      LIST: `${API_V1_URL}/payment-system/`,
      SAVE_CONFIGURATION: `${API_V1_URL}/payment-system/save-configuration/`,
      LINK_RECIPIENT: `${API_V1_URL}/payment-system/link-contact/`,
      UNLINK_RECIPIENT: `${API_V1_URL}/payment-system/unlink-contact/`,
      REFRESH_INFORMATION: `${API_V1_URL}/payment-system/refresh-information/`,
      REFRESH_INFORMATION_FOR: `${API_V1_URL}/payment-system/:name/refresh-information/`,
      WISE_LINK: `${API_V1_URL}/payment-system/wise-link/`,
      REVOLUT_LINK: `${API_V1_URL}/payment-system/revolut-link/`
    }
  },
};

export const ERROR_MESSAGES = {
  SERVER_SIDE_ERROR: 'Server error, please try again later. The team has been notified and is investigating the issue.',
  NO_PERMISSION: 'You do not have permission to perform this action.',
  PAY_NO_PERMISSION: 'You do not have permission to perform this action or you do not have Two Factor Authentication set up for this account.',
};

export const FORM_ERROR_MESSAGES = {
  PASSWORD_DOES_NOT_MATCH: 'The two password does not match',
  FIELD_REQUIRED: 'This field is required.',
  VALID_ID: 'Must be a valid ID.',
  FILE_SIZE_TOO_BIG: 'File is too big. Must be less than :size .',
  INCORRECT_FILE_TYPE: 'File type is not valid. Must be the following: :types .',
  POSITIVE_NUMBER: 'Must be a positive number.',
  CANNOT_PAY_MORE_THAN_DUE: 'You can\'t pay more than what is due. ($:amount)',
  MUST_BE_LESS: 'Must be :number characters or less.',
  MUST_BE_DATE: 'Must be a date. Is it missing?',
  MUST_BE_BIGGER_THAN_DATE: 'Due date must be after Date.',
  MUST_BE_EMAIL: 'Must be a valid email address.',
  INVALID_ZIP: 'The zip address is invalid.',
  NUMBER: 'Must be a number.',
};

export const TRANSACTION_TYPES = [
  {
    key: -1,
    value: '',
    text: 'All',
    label: 'All',
  },
  {
    key: 0,
    value: 0,
    text: 'Check',
    label: 'Check',
  },
  {
    key: 1,
    value: 1,
    text: 'Credit Card',
    label: 'Credit Card',
  },
  {
    key: 2,
    value: 2,
    text: 'ACH Payment (+$0.35)',
    label: 'ACH Payment (+$0.35)',
  },
  {
    key: 3,
    value: 3,
    text: 'Check Issuing ($1.49)',
    label: 'Check Issuing ($1.49)',
  },
];

export const CONTACT_TYPES = [
  {
    key: 0,
    value: 'Vendor',
    text: 'Vendor',
  },
  {
    key: 1,
    value: 'Customer',
    text: 'Customer',
  },
  {
    key: 2,
    value: 'General',
    text: 'General',
  },
];

export const BUSINESS_TYPES = [
  {
    key: 'individual',
    value: 'individual',
    text: 'Individuals',
    label: 'Individuals',
  },
  {
    key: 'company',
    value: 'company',
    text: 'Companies, LLCs, and partnerships',
    label: 'Companies, LLCs, and partnerships',
  },
  {
    key: 'non_profit',
    value: 'non_profit',
    text: 'Non-profits',
    label: 'Non-profits',
  },
  {
    key: 'government_entity',
    value: 'government_entity',
    text: 'Government Entities',
    label: 'Government Entities',
  },
];

export const CONTACT_ACCOUNT_TYPES = [
  {
    key: 'checking',
    value: 'checking',
    text: 'Checking',
    label: 'Checking',
  },
  {
    key: 'savings',
    value: 'savings',
    text: 'Savings',
    label: 'Savings',
  },
];

export const CUSTOMER_TYPES = [
  {
    key: 'individual',
    value: 'individual',
    text: 'Individual',
  },
  {
    key: 'business',
    value: 'business',
    text: 'Business',
  },
];

export const COMPANY_BUSINESS_STRUCTURES = [
  {
    key: 'multi_member_llc',
    value: 'multi_member_llc',
    text: 'Multi Member LLC',
    label: 'Multi Member LLC',
  },
  {
    key: 'private_corporation',
    value: 'private_corporation',
    text: 'Private Corporation',
    label: 'Private Corporation',
  },
  {
    key: 'private_partnership',
    value: 'private_partnership',
    text: 'Private Partnership',
    label: 'Private Partnership',
  },
  {
    key: 'public_corporation',
    value: 'public_corporation',
    text: 'Public Corporation',
    label: 'Public Corporation',
  },
  {
    key: 'public_partnership',
    value: 'public_partnership',
    text: 'Public Partnership',
    label: 'Public Partnership',
  },
  {
    key: 'single_member_llc',
    value: 'single_member_llc',
    text: 'Single Member LLC',
    label: 'Single Member LLC',
  },
  {
    key: 'sole_proprietorship',
    value: 'sole_proprietorship',
    text: 'Sole Proprietorship',
    label: 'Sole Proprietorship',
  },
  {
    key: 'unincorporated_association',
    value: 'unincorporated_association',
    text: 'Unincorporated Association',
    label: 'Unincorporated Association',
  },
];

export const NON_PROFIT_BUSINESS_STRUCTURES = [
  {
    key: 'incorporated_non_profit',
    value: 'incorporated_non_profit',
    text: 'Incorporated Non-profit',
    label: 'Incorporated Non-profit',
  },
  {
    key: 'unincorporated_non_profit',
    value: 'unincorporated_non_profit',
    text: 'Unincorporated Non-profit',
    label: 'Unincorporated Non-profit',
  },
];

export const GOVERNMENT_ENTITY_BUSINESS_STRUCTURES = [
  {
    key: 'government_instrumentality',
    value: 'government_instrumentality',
    text: 'Government Instrumentality',
    label: 'Government Instrumentality',
  },
  {
    key: 'governmental_unit',
    value: 'governmental_unit',
    text: 'Governmental Unit',
    label: 'Governmental Unit',
  },
  {
    key: 'tax_exempt_government_instrumentality',
    value: 'tax_exempt_government_instrumentality',
    text: 'Tax Exempt Unit Government Instrumentality',
    label: 'Tax Exempt Unit Government Instrumentality',
  },
];

export const MERCHANT_CATEGORY_CODES = [
  {
    key: '7623',
    value: '7623',
    text: 'A/C, Refrigeration Repair',
    label: 'A/C, Refrigeration Repair',
  },
  {
    key: '8931',
    value: '8931',
    text: 'Accounting/Bookkeeping Services',
    label: 'Accounting/Bookkeeping Services',
  },
  {
    key: '7311',
    value: '7311',
    text: 'Advertising Services',
    label: 'Advertising Services',
  },
  {
    key: '0763',
    value: '0763',
    text: 'Agricultural Cooperative',
    label: 'Agricultural Cooperative',
  },
  {
    key: '4511',
    value: '4511',
    text: 'Airlines, Air Carriers',
    label: 'Airlines, Air Carriers',
  },
  {
    key: '4582',
    value: '4582',
    text: 'Airports, Flying Fields',
    label: 'Airports, Flying Fields',
  },
  {
    key: '4119',
    value: '4119',
    text: 'Ambulance Services',
    label: 'Ambulance Services',
  },
  {
    key: '7996',
    value: '7996',
    text: 'Amusement Parks/Carnivals',
    label: 'Amusement Parks/Carnivals',
  },
  {
    key: '5937',
    value: '5937',
    text: 'Antique Reproductions',
    label: 'Antique Reproductions',
  },
  {
    key: '5932',
    value: '5932',
    text: 'Antique Shops',
    label: 'Antique Shops',
  },
  {
    key: '7998',
    value: '7998',
    text: 'Aquariums',
    label: 'Aquariums',
  },
  {
    key: '8911',
    value: '8911',
    text: 'Architectural/Surveying Services',
    label: 'Architectural/Surveying Services',
  },
  {
    key: '5971',
    value: '5971',
    text: 'Art Dealers and Galleries',
    label: 'Art Dealers and Galleries',
  },
  {
    key: '5970',
    value: '5970',
    text: 'Artists Supply and Craft Shops',
    label: 'Artists Supply and Craft Shops',
  },
  {
    key: '7531',
    value: '7531',
    text: 'Auto Body Repair Shops',
    label: 'Auto Body Repair Shops',
  },
  {
    key: '7535',
    value: '7535',
    text: 'Auto Paint Shops',
    label: 'Auto Paint Shops',
  },
  {
    key: '7538',
    value: '7538',
    text: 'Auto Service Shops',
    label: 'Auto Service Shops',
  },
  {
    key: '5531',
    value: '5531',
    text: 'Auto and Home Supply Stores',
    label: 'Auto and Home Supply Stores',
  },
  {
    key: '6011',
    value: '6011',
    text: 'Automated Cash Disburse',
    label: 'Automated Cash Disburse',
  },
  {
    key: '5542',
    value: '5542',
    text: 'Automated Fuel Dispensers',
    label: 'Automated Fuel Dispensers',
  },
  {
    key: '8675',
    value: '8675',
    text: 'Automobile Associations',
    label: 'Automobile Associations',
  },
  {
    key: '5533',
    value: '5533',
    text: 'Automotive Parts and Accessories Stores',
    label: 'Automotive Parts and Accessories Stores',
  },
  {
    key: '5532',
    value: '5532',
    text: 'Automotive Tire Stores',
    label: 'Automotive Tire Stores',
  },
  {
    key: '9223',
    value: '9223',
    text: 'Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)',
    label: 'Bail and Bond Payments (payment to the surety for the bond, not the actual bond paid to the government agency)',
  },
  {
    key: '5462',
    value: '5462',
    text: 'Bakeries',
    label: 'Bakeries',
  },
  {
    key: '7929',
    value: '7929',
    text: 'Bands, Orchestras',
    label: 'Bands, Orchestras',
  },
  {
    key: '7230',
    value: '7230',
    text: 'Barber and Beauty Shops',
    label: 'Barber and Beauty Shops',
  },
  {
    key: '7995',
    value: '7995',
    text: 'Betting/Casino Gambling',
    label: 'Betting/Casino Gambling',
  },
  {
    key: '5940',
    value: '5940',
    text: 'Bicycle Shops',
    label: 'Bicycle Shops',
  },
  {
    key: '7932',
    value: '7932',
    text: 'Billiard/Pool Establishments',
    label: 'Billiard/Pool Establishments',
  },
  {
    key: '5551',
    value: '5551',
    text: 'Boat Dealers',
    label: 'Boat Dealers',
  },
  {
    key: '4457',
    value: '4457',
    text: 'Boat Rentals and Leases',
    label: 'Boat Rentals and Leases',
  },
  {
    key: '5942',
    value: '5942',
    text: 'Book Stores',
    label: 'Book Stores',
  },
  {
    key: '5192',
    value: '5192',
    text: 'Books, Periodicals, and Newspapers',
    label: 'Books, Periodicals, and Newspapers',
  },
  {
    key: '7933',
    value: '7933',
    text: 'Bowling Alleys',
    label: 'Bowling Alleys',
  },
  {
    key: '4131',
    value: '4131',
    text: 'Bus Lines',
    label: 'Bus Lines',
  },
  {
    key: '8244',
    value: '8244',
    text: 'Business/Secretarial Schools',
    label: 'Business/Secretarial Schools',
  },
  {
    key: '7278',
    value: '7278',
    text: 'Buying/Shopping Services',
    label: 'Buying/Shopping Services',
  },
  {
    key: '4899',
    value: '4899',
    text: 'Cable, Satellite, and Other Pay Television and Radio',
    label: 'Cable, Satellite, and Other Pay Television and Radio',
  },
  {
    key: '5946',
    value: '5946',
    text: 'Camera and Photographic Supply Stores',
    label: 'Camera and Photographic Supply Stores',
  },
  {
    key: '5441',
    value: '5441',
    text: 'Candy, Nut, and Confectionery Stores',
    label: 'Candy, Nut, and Confectionery Stores',
  },
  {
    key: '7512',
    value: '7512',
    text: 'Car Rental Agencies',
    label: 'Car Rental Agencies',
  },
  {
    key: '7542',
    value: '7542',
    text: 'Car Washes',
    label: 'Car Washes',
  },
  {
    key: '5511',
    value: '5511',
    text: 'Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing',
    label: 'Car and Truck Dealers (New & Used) Sales, Service, Repairs Parts and Leasing',
  },
  {
    key: '5521',
    value: '5521',
    text: 'Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing',
    label: 'Car and Truck Dealers (Used Only) Sales, Service, Repairs Parts and Leasing',
  },
  {
    key: '1750',
    value: '1750',
    text: 'Carpentry Services',
    label: 'Carpentry Services',
  },
  {
    key: '7217',
    value: '7217',
    text: 'Carpet/Upholstery Cleaning',
    label: 'Carpet/Upholstery Cleaning',
  },
  {
    key: '5811',
    value: '5811',
    text: 'Caterers',
    label: 'Caterers',
  },
  {
    key: '8398',
    value: '8398',
    text: 'Charitable and Social Service Organizations - Fundraising',
    label: 'Charitable and Social Service Organizations - Fundraising',
  },
  {
    key: '5169',
    value: '5169',
    text: 'Chemicals and Allied Products (Not Elsewhere Classified)',
    label: 'Chemicals and Allied Products (Not Elsewhere Classified)',
  },
  {
    key: '8351',
    value: '8351',
    text: 'Child Care Services',
    label: 'Child Care Services',
  },
  {
    key: '5641',
    value: '5641',
    text: 'Childrens and Infants Wear Stores',
    label: 'Childrens and Infants Wear Stores',
  },
  {
    key: '8049',
    value: '8049',
    text: 'Chiropodists, Podiatrists',
    label: 'Chiropodists, Podiatrists',
  },
  {
    key: '8041',
    value: '8041',
    text: 'Chiropractors',
    label: 'Chiropractors',
  },
  {
    key: '5993',
    value: '5993',
    text: 'Cigar Stores and Stands',
    label: 'Cigar Stores and Stands',
  },
  {
    key: '8641',
    value: '8641',
    text: 'Civic, Social, Fraternal Associations',
    label: 'Civic, Social, Fraternal Associations',
  },
  {
    key: '7349',
    value: '7349',
    text: 'Cleaning and Maintenance',
    label: 'Cleaning and Maintenance',
  },
  {
    key: '7296',
    value: '7296',
    text: 'Clothing Rental',
    label: 'Clothing Rental',
  },
  {
    key: '8220',
    value: '8220',
    text: 'Colleges, Universities',
    label: 'Colleges, Universities',
  },
  {
    key: '5046',
    value: '5046',
    text: 'Commercial Equipment (Not Elsewhere Classified)',
    label: 'Commercial Equipment (Not Elsewhere Classified)',
  },
  {
    key: '5139',
    value: '5139',
    text: 'Commercial Footwear',
    label: 'Commercial Footwear',
  },
  {
    key: '7333',
    value: '7333',
    text: 'Commercial Photography, Art and Graphics',
    label: 'Commercial Photography, Art and Graphics',
  },
  {
    key: '4111',
    value: '4111',
    text: 'Commuter Transport, Ferries',
    label: 'Commuter Transport, Ferries',
  },
  {
    key: '4816',
    value: '4816',
    text: 'Computer Network Services',
    label: 'Computer Network Services',
  },
  {
    key: '7372',
    value: '7372',
    text: 'Computer Programming',
    label: 'Computer Programming',
  },
  {
    key: '7379',
    value: '7379',
    text: 'Computer Repair',
    label: 'Computer Repair',
  },
  {
    key: '5734',
    value: '5734',
    text: 'Computer Software Stores',
    label: 'Computer Software Stores',
  },
  {
    key: '5045',
    value: '5045',
    text: 'Computers, Peripherals, and Software',
    label: 'Computers, Peripherals, and Software',
  },
  {
    key: '1771',
    value: '1771',
    text: 'Concrete Work Services',
    label: 'Concrete Work Services',
  },
  {
    key: '5039',
    value: '5039',
    text: 'Construction Materials (Not Elsewhere Classified)',
    label: 'Construction Materials (Not Elsewhere Classified)',
  },
  {
    key: '7392',
    value: '7392',
    text: 'Consulting, Public Relations',
    label: 'Consulting, Public Relations',
  },
  {
    key: '8241',
    value: '8241',
    text: 'Correspondence Schools',
    label: 'Correspondence Schools',
  },
  {
    key: '5977',
    value: '5977',
    text: 'Cosmetic Stores',
    label: 'Cosmetic Stores',
  },
  {
    key: '7277',
    value: '7277',
    text: 'Counseling Services',
    label: 'Counseling Services',
  },
  {
    key: '7997',
    value: '7997',
    text: 'Country Clubs',
    label: 'Country Clubs',
  },
  {
    key: '4215',
    value: '4215',
    text: 'Courier Services',
    label: 'Courier Services',
  },
  {
    key: '9211',
    value: '9211',
    text: 'Court Costs, Including Alimony and Child Support - Courts of Law',
    label: 'Court Costs, Including Alimony and Child Support - Courts of Law',
  },
  {
    key: '7321',
    value: '7321',
    text: 'Credit Reporting Agencies',
    label: 'Credit Reporting Agencies',
  },
  {
    key: '4411',
    value: '4411',
    text: 'Cruise Lines',
    label: 'Cruise Lines',
  },
  {
    key: '5451',
    value: '5451',
    text: 'Dairy Products Stores',
    label: 'Dairy Products Stores',
  },
  {
    key: '7911',
    value: '7911',
    text: 'Dance Hall, Studios, Schools',
    label: 'Dance Hall, Studios, Schools',
  },
  {
    key: '7273',
    value: '7273',
    text: 'Dating/Escort Services',
    label: 'Dating/Escort Services',
  },
  {
    key: '8021',
    value: '8021',
    text: 'Dentists, Orthodontists',
    label: 'Dentists, Orthodontists',
  },
  {
    key: '5311',
    value: '5311',
    text: 'Department Stores',
    label: 'Department Stores',
  },
  {
    key: '7393',
    value: '7393',
    text: 'Detective Agencies',
    label: 'Detective Agencies',
  },
  {
    key: '5815',
    value: '5815',
    text: 'Digital Goods Media – Books, Movies, Music',
    label: 'Digital Goods Media – Books, Movies, Music',
  },
  {
    key: '5817',
    value: '5817',
    text: 'Digital Goods – Applications (Excludes Games)',
    label: 'Digital Goods – Applications (Excludes Games)',
  },
  {
    key: '5816',
    value: '5816',
    text: 'Digital Goods – Games',
    label: 'Digital Goods – Games',
  },
  {
    key: '5818',
    value: '5818',
    text: 'Digital Goods – Large Digital Goods Merchant',
    label: 'Digital Goods – Large Digital Goods Merchant',
  },
  {
    key: '5964',
    value: '5964',
    text: 'Direct Marketing - Catalog Merchant',
    label: 'Direct Marketing - Catalog Merchant',
  },
  {
    key: '5965',
    value: '5965',
    text: 'Direct Marketing - Combination Catalog and Retail Merchant',
    label: 'Direct Marketing - Combination Catalog and Retail Merchant',
  },
  {
    key: '5967',
    value: '5967',
    text: 'Direct Marketing - Inbound Telemarketing',
    label: 'Direct Marketing - Inbound Telemarketing',
  },
  {
    key: '5960',
    value: '5960',
    text: 'Direct Marketing - Insurance Services',
    label: 'Direct Marketing - Insurance Services',
  },
  {
    key: '5969',
    value: '5969',
    text: 'Direct Marketing - Other',
    label: 'Direct Marketing - Other',
  },
  {
    key: '5966',
    value: '5966',
    text: 'Direct Marketing - Outbound Telemarketing',
    label: 'Direct Marketing - Outbound Telemarketing',
  },
  {
    key: '5968',
    value: '5968',
    text: 'Direct Marketing - Subscription',
    label: 'Direct Marketing - Subscription',
  },
  {
    key: '5962',
    value: '5962',
    text: 'Direct Marketing - Travel',
    label: 'Direct Marketing - Travel',
  },
  {
    key: '5310',
    value: '5310',
    text: 'Discount Stores',
    label: 'Discount Stores',
  },
  {
    key: '8011',
    value: '8011',
    text: 'Doctors',
    label: 'Doctors',
  },
  {
    key: '5963',
    value: '5963',
    text: 'Door-To-Door Sales',
    label: 'Door-To-Door Sales',
  },
  {
    key: '5714',
    value: '5714',
    text: 'Drapery, Window Covering, and Upholstery Stores',
    label: 'Drapery, Window Covering, and Upholstery Stores',
  },
  {
    key: '5813',
    value: '5813',
    text: 'Drinking Places',
    label: 'Drinking Places',
  },
  {
    key: '5912',
    value: '5912',
    text: 'Drug Stores and Pharmacies',
    label: 'Drug Stores and Pharmacies',
  },
  {
    key: '5122',
    value: '5122',
    text: 'Drugs, Drug Proprietaries, and Druggist Sundries',
    label: 'Drugs, Drug Proprietaries, and Druggist Sundries',
  },
  {
    key: '7216',
    value: '7216',
    text: 'Dry Cleaners',
    label: 'Dry Cleaners',
  },
  {
    key: '5099',
    value: '5099',
    text: 'Durable Goods (Not Elsewhere Classified)',
    label: 'Durable Goods (Not Elsewhere Classified)',
  },
  {
    key: '5309',
    value: '5309',
    text: 'Duty Free Stores',
    label: 'Duty Free Stores',
  },
  {
    key: '5812',
    value: '5812',
    text: 'Eating Places, Restaurants',
    label: 'Eating Places, Restaurants',
  },
  {
    key: '8299',
    value: '8299',
    text: 'Educational Services',
    label: 'Educational Services',
  },
  {
    key: '5997',
    value: '5997',
    text: 'Electric Razor Stores',
    label: 'Electric Razor Stores',
  },
  {
    key: '5065',
    value: '5065',
    text: 'Electrical Parts and Equipment',
    label: 'Electrical Parts and Equipment',
  },
  {
    key: '1731',
    value: '1731',
    text: 'Electrical Services',
    label: 'Electrical Services',
  },
  {
    key: '7622',
    value: '7622',
    text: 'Electronics Repair Shops',
    label: 'Electronics Repair Shops',
  },
  {
    key: '5732',
    value: '5732',
    text: 'Electronics Stores',
    label: 'Electronics Stores',
  },
  {
    key: '8211',
    value: '8211',
    text: 'Elementary, Secondary Schools',
    label: 'Elementary, Secondary Schools',
  },
  {
    key: '7361',
    value: '7361',
    text: 'Employment/Temp Agencies',
    label: 'Employment/Temp Agencies',
  },
  {
    key: '7394',
    value: '7394',
    text: 'Equipment Rental',
    label: 'Equipment Rental',
  },
  {
    key: '7342',
    value: '7342',
    text: 'Exterminating Services',
    label: 'Exterminating Services',
  },
  {
    key: '5651',
    value: '5651',
    text: 'Family Clothing Stores',
    label: 'Family Clothing Stores',
  },
  {
    key: '5814',
    value: '5814',
    text: 'Fast Food Restaurants',
    label: 'Fast Food Restaurants',
  },
  {
    key: '6012',
    value: '6012',
    text: 'Financial Institutions',
    label: 'Financial Institutions',
  },
  {
    key: '9222',
    value: '9222',
    text: 'Fines - Government Administrative Entities',
    label: 'Fines - Government Administrative Entities',
  },
  {
    key: '5718',
    value: '5718',
    text: 'Fireplace, Fireplace Screens, and Accessories Stores',
    label: 'Fireplace, Fireplace Screens, and Accessories Stores',
  },
  {
    key: '5713',
    value: '5713',
    text: 'Floor Covering Stores',
    label: 'Floor Covering Stores',
  },
  {
    key: '5992',
    value: '5992',
    text: 'Florists',
    label: 'Florists',
  },
  {
    key: '5193',
    value: '5193',
    text: 'Florists Supplies, Nursery Stock, and Flowers',
    label: 'Florists Supplies, Nursery Stock, and Flowers',
  },
  {
    key: '5422',
    value: '5422',
    text: 'Freezer and Locker Meat Provisioners',
    label: 'Freezer and Locker Meat Provisioners',
  },
  {
    key: '5983',
    value: '5983',
    text: 'Fuel Dealers (Non Automotive)',
    label: 'Fuel Dealers (Non Automotive)',
  },
  {
    key: '7261',
    value: '7261',
    text: 'Funeral Services, Crematories',
    label: 'Funeral Services, Crematories',
  },
  {
    key: '7641',
    value: '7641',
    text: 'Furniture Repair, Refinishing',
    label: 'Furniture Repair, Refinishing',
  },
  {
    key: '5712',
    value: '5712',
    text: 'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
    label: 'Furniture, Home Furnishings, and Equipment Stores, Except Appliances',
  },
  {
    key: '5681',
    value: '5681',
    text: 'Furriers and Fur Shops',
    label: 'Furriers and Fur Shops',
  },
  {
    key: '1520',
    value: '1520',
    text: 'General Services',
    label: 'General Services',
  },
  {
    key: '5947',
    value: '5947',
    text: 'Gift, Card, Novelty, and Souvenir Shops',
    label: 'Gift, Card, Novelty, and Souvenir Shops',
  },
  {
    key: '5231',
    value: '5231',
    text: 'Glass, Paint, and Wallpaper Stores',
    label: 'Glass, Paint, and Wallpaper Stores',
  },
  {
    key: '5950',
    value: '5950',
    text: 'Glassware, Crystal Stores',
    label: 'Glassware, Crystal Stores',
  },
  {
    key: '7992',
    value: '7992',
    text: 'Golf Courses - Public',
    label: 'Golf Courses - Public',
  },
  {
    key: '9399',
    value: '9399',
    text: 'Government Services (Not Elsewhere Classified)',
    label: 'Government Services (Not Elsewhere Classified)',
  },
  {
    key: '5411',
    value: '5411',
    text: 'Grocery Stores, Supermarkets',
    label: 'Grocery Stores, Supermarkets',
  },
  {
    key: '5251',
    value: '5251',
    text: 'Hardware Stores',
    label: 'Hardware Stores',
  },
  {
    key: '5072',
    value: '5072',
    text: 'Hardware, Equipment, and Supplies',
    label: 'Hardware, Equipment, and Supplies',
  },
  {
    key: '7298',
    value: '7298',
    text: 'Health and Beauty Spas',
    label: 'Health and Beauty Spas',
  },
  {
    key: '5975',
    value: '5975',
    text: 'Hearing Aids Sales and Supplies',
    label: 'Hearing Aids Sales and Supplies',
  },
  {
    key: '1711',
    value: '1711',
    text: 'Heating, Plumbing, A/C',
    label: 'Heating, Plumbing, A/C',
  },
  {
    key: '5945',
    value: '5945',
    text: 'Hobby, Toy, and Game Shops',
    label: 'Hobby, Toy, and Game Shops',
  },
  {
    key: '5200',
    value: '5200',
    text: 'Home Supply Warehouse Stores',
    label: 'Home Supply Warehouse Stores',
  },
  {
    key: '8062',
    value: '8062',
    text: 'Hospitals',
    label: 'Hospitals',
  },
  {
    key: '7011',
    value: '7011',
    text: 'Hotels, Motels, and Resorts',
    label: 'Hotels, Motels, and Resorts',
  },
  {
    key: '5722',
    value: '5722',
    text: 'Household Appliance Stores',
    label: 'Household Appliance Stores',
  },
  {
    key: '5085',
    value: '5085',
    text: 'Industrial Supplies (Not Elsewhere Classified)',
    label: 'Industrial Supplies (Not Elsewhere Classified)',
  },
  {
    key: '7375',
    value: '7375',
    text: 'Information Retrieval Services',
    label: 'Information Retrieval Services',
  },
  {
    key: '6399',
    value: '6399',
    text: 'Insurance - Default',
    label: 'Insurance - Default',
  },
  {
    key: '6300',
    value: '6300',
    text: 'Insurance Underwriting, Premiums',
    label: 'Insurance Underwriting, Premiums',
  },
  {
    key: '9950',
    value: '9950',
    text: 'Intra-Company Purchases',
    label: 'Intra-Company Purchases',
  },
  {
    key: '5944',
    value: '5944',
    text: 'Jewelry Stores, Watches, Clocks, and Silverware Stores',
    label: 'Jewelry Stores, Watches, Clocks, and Silverware Stores',
  },
  {
    key: '0780',
    value: '0780',
    text: 'Landscaping Services',
    label: 'Landscaping Services',
  },
  {
    key: '7211',
    value: '7211',
    text: 'Laundries',
    label: 'Laundries',
  },
  {
    key: '7210',
    value: '7210',
    text: 'Laundry, Cleaning Services',
    label: 'Laundry, Cleaning Services',
  },
  {
    key: '8111',
    value: '8111',
    text: 'Legal Services, Attorneys',
    label: 'Legal Services, Attorneys',
  },
  {
    key: '5948',
    value: '5948',
    text: 'Luggage and Leather Goods Stores',
    label: 'Luggage and Leather Goods Stores',
  },
  {
    key: '5211',
    value: '5211',
    text: 'Lumber, Building Materials Stores',
    label: 'Lumber, Building Materials Stores',
  },
  {
    key: '6010',
    value: '6010',
    text: 'Manual Cash Disburse',
    label: 'Manual Cash Disburse',
  },
  {
    key: '4468',
    value: '4468',
    text: 'Marinas, Service and Supplies',
    label: 'Marinas, Service and Supplies',
  },
  {
    key: '1740',
    value: '1740',
    text: 'Masonry, Stonework, and Plaster',
    label: 'Masonry, Stonework, and Plaster',
  },
  {
    key: '7297',
    value: '7297',
    text: 'Massage Parlors',
    label: 'Massage Parlors',
  },
  {
    key: '8099',
    value: '8099',
    text: 'Medical Services',
    label: 'Medical Services',
  },
  {
    key: '8071',
    value: '8071',
    text: 'Medical and Dental Labs',
    label: 'Medical and Dental Labs',
  },
  {
    key: '5047',
    value: '5047',
    text: 'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
    label: 'Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies',
  },
  {
    key: '8699',
    value: '8699',
    text: 'Membership Organizations',
    label: 'Membership Organizations',
  },
  {
    key: '5611',
    value: '5611',
    text: 'Mens and Boys Clothing and Accessories Stores',
    label: 'Mens and Boys Clothing and Accessories Stores',
  },
  {
    key: '5691',
    value: '5691',
    text: 'Mens, Womens Clothing Stores',
    label: 'Mens, Womens Clothing Stores',
  },
  {
    key: '5051',
    value: '5051',
    text: 'Metal Service Centers',
    label: 'Metal Service Centers',
  },
  {
    key: '5699',
    value: '5699',
    text: 'Miscellaneous Apparel and Accessory Shops',
    label: 'Miscellaneous Apparel and Accessory Shops',
  },
  {
    key: '5599',
    value: '5599',
    text: 'Miscellaneous Auto Dealers',
    label: 'Miscellaneous Auto Dealers',
  },
  {
    key: '7399',
    value: '7399',
    text: 'Miscellaneous Business Services',
    label: 'Miscellaneous Business Services',
  },
  {
    key: '5499',
    value: '5499',
    text: 'Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
    label: 'Miscellaneous Food Stores - Convenience Stores and Specialty Markets',
  },
  {
    key: '5399',
    value: '5399',
    text: 'Miscellaneous General Merchandise',
    label: 'Miscellaneous General Merchandise',
  },
  {
    key: '7299',
    value: '7299',
    text: 'Miscellaneous General Services',
    label: 'Miscellaneous General Services',
  },
  {
    key: '5719',
    value: '5719',
    text: 'Miscellaneous Home Furnishing Specialty Stores',
    label: 'Miscellaneous Home Furnishing Specialty Stores',
  },
  {
    key: '2741',
    value: '2741',
    text: 'Miscellaneous Publishing and Printing',
    label: 'Miscellaneous Publishing and Printing',
  },
  {
    key: '7999',
    value: '7999',
    text: 'Miscellaneous Recreation Services',
    label: 'Miscellaneous Recreation Services',
  },
  {
    key: '7699',
    value: '7699',
    text: 'Miscellaneous Repair Shops',
    label: 'Miscellaneous Repair Shops',
  },
  {
    key: '5999',
    value: '5999',
    text: 'Miscellaneous Specialty Retail',
    label: 'Miscellaneous Specialty Retail',
  },
  {
    key: '5271',
    value: '5271',
    text: 'Mobile Home Dealers',
    label: 'Mobile Home Dealers',
  },
  {
    key: '7832',
    value: '7832',
    text: 'Motion Picture Theaters',
    label: 'Motion Picture Theaters',
  },
  {
    key: '4214',
    value: '4214',
    text: 'Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services',
    label: 'Motor Freight Carriers and Trucking - Local and Long Distance, Moving and Storage Companies, and Local Delivery Services',
  },
  {
    key: '5592',
    value: '5592',
    text: 'Motor Homes Dealers',
    label: 'Motor Homes Dealers',
  },
  {
    key: '5013',
    value: '5013',
    text: 'Motor Vehicle Supplies and New Parts',
    label: 'Motor Vehicle Supplies and New Parts',
  },
  {
    key: '5571',
    value: '5571',
    text: 'Motorcycle Shops and Dealers',
    label: 'Motorcycle Shops and Dealers',
  },
  {
    key: '5561',
    value: '5561',
    text: 'Motorcycle Shops, Dealers',
    label: 'Motorcycle Shops, Dealers',
  },
  {
    key: '5733',
    value: '5733',
    text: 'Music Stores-Musical Instruments, Pianos, and Sheet Music',
    label: 'Music Stores-Musical Instruments, Pianos, and Sheet Music',
  },
  {
    key: '5994',
    value: '5994',
    text: 'News Dealers and Newsstands',
    label: 'News Dealers and Newsstands',
  },
  {
    key: '6051',
    value: '6051',
    text: 'Non-FI, Money Orders',
    label: 'Non-FI, Money Orders',
  },
  {
    key: '6540',
    value: '6540',
    text: 'Non-FI, Stored Value Card Purchase/Load',
    label: 'Non-FI, Stored Value Card Purchase/Load',
  },
  {
    key: '5199',
    value: '5199',
    text: 'Nondurable Goods (Not Elsewhere Classified)',
    label: 'Nondurable Goods (Not Elsewhere Classified)',
  },
  {
    key: '5261',
    value: '5261',
    text: 'Nurseries, Lawn and Garden Supply Stores',
    label: 'Nurseries, Lawn and Garden Supply Stores',
  },
  {
    key: '8050',
    value: '8050',
    text: 'Nursing/Personal Care',
    label: 'Nursing/Personal Care',
  },
  {
    key: '5021',
    value: '5021',
    text: 'Office and Commercial Furniture',
    label: 'Office and Commercial Furniture',
  },
  {
    key: '8043',
    value: '8043',
    text: 'Opticians, Eyeglasses',
    label: 'Opticians, Eyeglasses',
  },
  {
    key: '8042',
    value: '8042',
    text: 'Optometrists, Ophthalmologist',
    label: 'Optometrists, Ophthalmologist',
  },
  {
    key: '5976',
    value: '5976',
    text: 'Orthopedic Goods - Prosthetic Devices',
    label: 'Orthopedic Goods - Prosthetic Devices',
  },
  {
    key: '8031',
    value: '8031',
    text: 'Osteopaths',
    label: 'Osteopaths',
  },
  {
    key: '5921',
    value: '5921',
    text: 'Package Stores-Beer, Wine, and Liquor',
    label: 'Package Stores-Beer, Wine, and Liquor',
  },
  {
    key: '5198',
    value: '5198',
    text: 'Paints, Varnishes, and Supplies',
    label: 'Paints, Varnishes, and Supplies',
  },
  {
    key: '7523',
    value: '7523',
    text: 'Parking Lots, Garages',
    label: 'Parking Lots, Garages',
  },
  {
    key: '4112',
    value: '4112',
    text: 'Passenger Railways',
    label: 'Passenger Railways',
  },
  {
    key: '5933',
    value: '5933',
    text: 'Pawn Shops',
    label: 'Pawn Shops',
  },
  {
    key: '5995',
    value: '5995',
    text: 'Pet Shops, Pet Food, and Supplies',
    label: 'Pet Shops, Pet Food, and Supplies',
  },
  {
    key: '5172',
    value: '5172',
    text: 'Petroleum and Petroleum Products',
    label: 'Petroleum and Petroleum Products',
  },
  {
    key: '7395',
    value: '7395',
    text: 'Photo Developing',
    label: 'Photo Developing',
  },
  {
    key: '7221',
    value: '7221',
    text: 'Photographic Studios',
    label: 'Photographic Studios',
  },
  {
    key: '5044',
    value: '5044',
    text: 'Photographic, Photocopy, Microfilm Equipment, and Supplies',
    label: 'Photographic, Photocopy, Microfilm Equipment, and Supplies',
  },
  {
    key: '7829',
    value: '7829',
    text: 'Picture/Video Production',
    label: 'Picture/Video Production',
  },
  {
    key: '5131',
    value: '5131',
    text: 'Piece Goods, Notions, and Other Dry Goods',
    label: 'Piece Goods, Notions, and Other Dry Goods',
  },
  {
    key: '5074',
    value: '5074',
    text: 'Plumbing, Heating Equipment, and Supplies',
    label: 'Plumbing, Heating Equipment, and Supplies',
  },
  {
    key: '8651',
    value: '8651',
    text: 'Political Organizations',
    label: 'Political Organizations',
  },
  {
    key: '9402',
    value: '9402',
    text: 'Postal Services - Government Only',
    label: 'Postal Services - Government Only',
  },
  {
    key: '5094',
    value: '5094',
    text: 'Precious Stones and Metals, Watches and Jewelry',
    label: 'Precious Stones and Metals, Watches and Jewelry',
  },
  {
    key: '8999',
    value: '8999',
    text: 'Professional Services',
    label: 'Professional Services',
  },
  {
    key: '4225',
    value: '4225',
    text: 'Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage',
    label: 'Public Warehousing and Storage - Farm Products, Refrigerated Goods, Household Goods, and Storage',
  },
  {
    key: '7338',
    value: '7338',
    text: 'Quick Copy, Repro, and Blueprint',
    label: 'Quick Copy, Repro, and Blueprint',
  },
  {
    key: '4011',
    value: '4011',
    text: 'Railroads',
    label: 'Railroads',
  },
  {
    key: '6513',
    value: '6513',
    text: 'Real Estate Agents and Managers - Rentals',
    label: 'Real Estate Agents and Managers - Rentals',
  },
  {
    key: '5735',
    value: '5735',
    text: 'Record Stores',
    label: 'Record Stores',
  },
  {
    key: '7519',
    value: '7519',
    text: 'Recreational Vehicle Rentals',
    label: 'Recreational Vehicle Rentals',
  },
  {
    key: '5973',
    value: '5973',
    text: 'Religious Goods Stores',
    label: 'Religious Goods Stores',
  },
  {
    key: '8661',
    value: '8661',
    text: 'Religious Organizations',
    label: 'Religious Organizations',
  },
  {
    key: '1761',
    value: '1761',
    text: 'Roofing/Siding, Sheet Metal',
    label: 'Roofing/Siding, Sheet Metal',
  },
  {
    key: '7339',
    value: '7339',
    text: 'Secretarial Support Services',
    label: 'Secretarial Support Services',
  },
  {
    key: '6211',
    value: '6211',
    text: 'Security Brokers/Dealers',
    label: 'Security Brokers/Dealers',
  },
  {
    key: '5541',
    value: '5541',
    text: 'Service Stations',
    label: 'Service Stations',
  },
  {
    key: '5949',
    value: '5949',
    text: 'Sewing, Needlework, Fabric, and Piece Goods Stores',
    label: 'Sewing, Needlework, Fabric, and Piece Goods Stores',
  },
  {
    key: '7251',
    value: '7251',
    text: 'Shoe Repair/Hat Cleaning',
    label: 'Shoe Repair/Hat Cleaning',
  },
  {
    key: '5661',
    value: '5661',
    text: 'Shoe Stores',
    label: 'Shoe Stores',
  },
  {
    key: '7629',
    value: '7629',
    text: 'Small Appliance Repair',
    label: 'Small Appliance Repair',
  },
  {
    key: '5598',
    value: '5598',
    text: 'Snowmobile Dealers',
    label: 'Snowmobile Dealers',
  },
  {
    key: '1799',
    value: '1799',
    text: 'Special Trade Services',
    label: 'Special Trade Services',
  },
  {
    key: '2842',
    value: '2842',
    text: 'Specialty Cleaning',
    label: 'Specialty Cleaning',
  },
  {
    key: '5941',
    value: '5941',
    text: 'Sporting Goods Stores',
    label: 'Sporting Goods Stores',
  },
  {
    key: '7032',
    value: '7032',
    text: 'Sporting/Recreation Camps',
    label: 'Sporting/Recreation Camps',
  },
  {
    key: '7941',
    value: '7941',
    text: 'Sports Clubs/Fields',
    label: 'Sports Clubs/Fields',
  },
  {
    key: '5655',
    value: '5655',
    text: 'Sports and Riding Apparel Stores',
    label: 'Sports and Riding Apparel Stores',
  },
  {
    key: '5972',
    value: '5972',
    text: 'Stamp and Coin Stores',
    label: 'Stamp and Coin Stores',
  },
  {
    key: '5111',
    value: '5111',
    text: 'Stationary, Office Supplies, Printing and Writing Paper',
    label: 'Stationary, Office Supplies, Printing and Writing Paper',
  },
  {
    key: '5943',
    value: '5943',
    text: 'Stationery Stores, Office, and School Supply Stores',
    label: 'Stationery Stores, Office, and School Supply Stores',
  },
  {
    key: '5996',
    value: '5996',
    text: 'Swimming Pools Sales',
    label: 'Swimming Pools Sales',
  },
  {
    key: '4723',
    value: '4723',
    text: 'TUI Travel - Germany',
    label: 'TUI Travel - Germany',
  },
  {
    key: '5697',
    value: '5697',
    text: 'Tailors, Alterations',
    label: 'Tailors, Alterations',
  },
  {
    key: '9311',
    value: '9311',
    text: 'Tax Payments - Government Agencies',
    label: 'Tax Payments - Government Agencies',
  },
  {
    key: '7276',
    value: '7276',
    text: 'Tax Preparation Services',
    label: 'Tax Preparation Services',
  },
  {
    key: '4121',
    value: '4121',
    text: 'Taxicabs/Limousines',
    label: 'Taxicabs/Limousines',
  },
  {
    key: '4812',
    value: '4812',
    text: 'Telecommunication Equipment and Telephone Sales',
    label: 'Telecommunication Equipment and Telephone Sales',
  },
  {
    key: '4814',
    value: '4814',
    text: 'Telecommunication Services',
    label: 'Telecommunication Services',
  },
  {
    key: '4821',
    value: '4821',
    text: 'Telegraph Services',
    label: 'Telegraph Services',
  },
  {
    key: '5998',
    value: '5998',
    text: 'Tent and Awning Shops',
    label: 'Tent and Awning Shops',
  },
  {
    key: '8734',
    value: '8734',
    text: 'Testing Laboratories',
    label: 'Testing Laboratories',
  },
  {
    key: '7922',
    value: '7922',
    text: 'Theatrical Ticket Agencies',
    label: 'Theatrical Ticket Agencies',
  },
  {
    key: '7012',
    value: '7012',
    text: 'Timeshares',
    label: 'Timeshares',
  },
  {
    key: '7534',
    value: '7534',
    text: 'Tire Retreading and Repair',
    label: 'Tire Retreading and Repair',
  },
  {
    key: '4784',
    value: '4784',
    text: 'Tolls/Bridge Fees',
    label: 'Tolls/Bridge Fees',
  },
  {
    key: '7991',
    value: '7991',
    text: 'Tourist Attractions and Exhibits',
    label: 'Tourist Attractions and Exhibits',
  },
  {
    key: '7549',
    value: '7549',
    text: 'Towing Services',
    label: 'Towing Services',
  },
  {
    key: '7033',
    value: '7033',
    text: 'Trailer Parks, Campgrounds',
    label: 'Trailer Parks, Campgrounds',
  },
  {
    key: '4789',
    value: '4789',
    text: 'Transportation Services (Not Elsewhere Classified)',
    label: 'Transportation Services (Not Elsewhere Classified)',
  },
  {
    key: '4722',
    value: '4722',
    text: 'Travel Agencies, Tour Operators',
    label: 'Travel Agencies, Tour Operators',
  },
  {
    key: '7511',
    value: '7511',
    text: 'Truck StopIteration',
    label: 'Truck StopIteration',
  },
  {
    key: '7513',
    value: '7513',
    text: 'Truck/Utility Trailer Rentals',
    label: 'Truck/Utility Trailer Rentals',
  },
  {
    key: '2791',
    value: '2791',
    text: 'Typesetting, Plate Making, and Related Services',
    label: 'Typesetting, Plate Making, and Related Services',
  },
  {
    key: '5978',
    value: '5978',
    text: 'Typewriter Stores',
    label: 'Typewriter Stores',
  },
  {
    key: '9405',
    value: '9405',
    text: 'U.S. Federal Government Agencies or Departments',
    label: 'U.S. Federal Government Agencies or Departments',
  },
  {
    key: '5137',
    value: '5137',
    text: 'Uniforms, Commercial Clothing',
    label: 'Uniforms, Commercial Clothing',
  },
  {
    key: '5931',
    value: '5931',
    text: 'Used Merchandise and Secondhand Stores',
    label: 'Used Merchandise and Secondhand Stores',
  },
  {
    key: '4900',
    value: '4900',
    text: 'Utilities',
    label: 'Utilities',
  },
  {
    key: '5331',
    value: '5331',
    text: 'Variety Stores',
    label: 'Variety Stores',
  },
  {
    key: '0742',
    value: '0742',
    text: 'Veterinary Services',
    label: 'Veterinary Services',
  },
  {
    key: '7993',
    value: '7993',
    text: 'Video Amusement Game Supplies',
    label: 'Video Amusement Game Supplies',
  },
  {
    key: '7994',
    value: '7994',
    text: 'Video Game Arcades',
    label: 'Video Game Arcades',
  },
  {
    key: '7841',
    value: '7841',
    text: 'Video Tape Rental Stores',
    label: 'Video Tape Rental Stores',
  },
  {
    key: '8249',
    value: '8249',
    text: 'Vocational/Trade Schools',
    label: 'Vocational/Trade Schools',
  },
  {
    key: '7631',
    value: '7631',
    text: 'Watch/Jewelry Repair',
    label: 'Watch/Jewelry Repair',
  },
  {
    key: '7692',
    value: '7692',
    text: 'Welding Repair',
    label: 'Welding Repair',
  },
  {
    key: '5300',
    value: '5300',
    text: 'Wholesale Clubs',
    label: 'Wholesale Clubs',
  },
  {
    key: '5698',
    value: '5698',
    text: 'Wig and Toupee Stores',
    label: 'Wig and Toupee Stores',
  },
  {
    key: '4829',
    value: '4829',
    text: 'Wires, Money Orders',
    label: 'Wires, Money Orders',
  },
  {
    key: '5631',
    value: '5631',
    text: 'Womens Accessory and Specialty Shops',
    label: 'Womens Accessory and Specialty Shops',
  },
  {
    key: '5621',
    value: '5621',
    text: 'Womens Ready-To-Wear Stores',
    label: 'Womens Ready-To-Wear Stores',
  },
  {
    key: '5935',
    value: '5935',
    text: 'Wrecking and Salvage Yards',
    label: 'Wrecking and Salvage Yards',
  },
];

export const REPRESENTATIVE_RELATIONSHIPS = [
  {
    key: 'owner',
    value: 'owner',
    text: '25% or more owner',
    label: '25% or more owner',
  },
  {
    key: 'manager',
    value: 'manager',
    text: 'Senior manager with significant responsibility',
    label: 'Senior manager with significant responsibility',
  },
  {
    key: 'both',
    value: 'both',
    text: 'Both 25% owner and senior manager',
    label: 'Both 25% owner and senior manager',
  },
];

export const DWOLLA_DOCUMENT_TYPES = [
  {
    key: 'passport',
    value: 'passport',
    text: 'Passport',
    label: 'Passport',
  },
  {
    key: 'license',
    value: 'license',
    text: 'License',
    label: 'License',
  },
  {
    key: 'idCard',
    value: 'idCard',
    text: 'ID Card',
    label: 'ID Card',
  },
  {
    key: 'other',
    value: 'other',
    text: 'Other',
    label: 'Other',
  },
];

export const INVOICE_TERM_OPTIONS = [
  {
    key: 'due_on_receipt',
    value: 'due_on_receipt',
    text: 'Due on receipt',
    label: 'Due on receipt',
  },
];

export const STRIPE_FIELD_CODES = {
  'line1': 'Street address',
  'line2': 'Street address line 2',
  'id_number': 'SSN',
};

export const ACH_RETURN_CODES = {
  'R01': 'Insufficient funds',
  'R02': 'Bank account closed',
  'R03': 'No bank account/unable to locate account',
  'R04': 'Invalid bank account number',
  'R06': 'Returned per ODFI request',
  'R07': 'Authorization revoked by customer',
  'R08': 'Payment stopped',
  'R09': 'Uncollected funds',
  'R10': 'Customer advises not authorized',
  'R11': 'Check truncation entry return',
  'R12': 'Branch sold to another RDFI',
  'R13': 'RDFI not qualified to participate',
  'R14': 'Representative payee deceased or unable to continue in that capacity',
  'R15': 'Beneficiary or bank account holder',
  'R16': 'Bank account frozen',
  'R17': 'File record edit criteria',
  'R18': 'Improper effective entry date',
  'R19': 'Amount field error',
  'R20': 'Non-payment bank account',
  'R21': 'Invalid company ID number',
  'R22': 'Invalid individual ID number',
  'R23': 'Credit entry refused by receiver',
  'R24': 'Duplicate entry',
  'R25': 'Addenda error',
  'R26': 'Mandatory field error',
  'R27': 'Trace number error',
  'R28': 'Transit routing number check digit error',
  'R29': 'Corporate customer advises not authorized',
  'R30': 'RDFI not participant in check truncation program',
  'R31': 'Permissible return entry (CCD and CTX only)',
  'R32': 'RDFI non-settlement',
  'R33': 'Return of XCK entry',
  'R34': 'Limited participation RDFI',
  'R35': 'Return of improper debit entry',
};
