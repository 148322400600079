import {Form, Formik} from 'formik';
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Select from "react-select";
import * as Yup from 'yup';
import {PayvyGlobalFormError, PayvyIconButton, PayvyLabeledInput} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import {PAYVY_URL} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {
  getPaymentServiceInformation,
  linkContactToServiceRecipient,
  paymentSystemSelector
} from "../../../slices/paymentSystem";
import {ModuleBlock} from './ModuleBlock';

export const ModulePaymentServiceLinkingService = ({
  contactId,
  service,
  value,
  setField
}) => {
  const [valueObject, setValueObject] = React.useState(null);
  const {active_configuration: {enabled = false}} = service;
  const recipientsFormatted = service.recipients
                                     .filter(recipient => recipient.contact === null || recipient.contact.id === contactId)
                                     .map((recipient) => {
                                       return {
                                         value: recipient.id,
                                         label: recipient.name
                                       }
                                     });

  useEffect(() => {
    if(value) {
      const recipient = recipientsFormatted.find((recipient) => recipient.value === value);
      if(recipient) setValueObject(recipient);
    } else {
      setValueObject(null);
    }
  }, [value, recipientsFormatted]);

  if(!enabled) return null;

  const triggerLocalFieldChange = (option) => {
    setField(option?.value || null);
    setValueObject(option);
  };

  return <PayvyLabeledInput
    label={`${service.name} Recipient`}
    name={service.internal_name}
  >
    <Select
      className={'w-full'}
      placeholder={`${service.name} Recipient`}
      isDisabled={false}
      value={valueObject}
      isClearable={true}
      onChange={(option) => triggerLocalFieldChange(option)}
      options={recipientsFormatted}
    />
  </PayvyLabeledInput>
}

export const ModulePaymentServiceLinkedAccount = ({
  contactId,
  service
}) => {
  const contact = service.recipients.find((recipient) => recipient.contact?.id === contactId);
  const subtitle = contact ? `Linked to ${contact.name}` : 'No linked account';
  return <div className={'w-full'}>
    <div className={'grid grid-cols-12 bg-neutral-50 border border-neutral-100 px-5 py-2'}>
      <div className={'col-span-10 flex flex-col'}>
        <div className={'text-neutral-700 font-bold'}>{service.name}</div>
        <div className={'text-neutral-700'}>{subtitle}</div>
      </div>
    </div>
  </div>;
}
export const ModulePaymentServiceLinking = ({
  contactId,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const submitForm = React.useRef(null);
  const {
    loading,
    processing,
    services = []
  } = useSelector(paymentSystemSelector);

  useEffect(() => {
    dispatch(getPaymentServiceInformation({}));
  }, [dispatch, contactId])

  const servicesValidation = services.reduce((acc, service) => {
    acc[service.internal_name] = Yup.mixed()
                                    .nullable();
    return acc;
  }, {});

  const servicesInitialValues = services.reduce((acc, service) => {
    const r = service.recipients.find((recipient) => {
      return recipient.contact?.id === contactId;
    }) ?? null;
    acc[service.internal_name] = r?.id ?? null;
    return acc;
  }, {});
  const servicesEnabled = services.filter((service) => service?.active_configuration?.enabled === true);
  return <ModuleBlock
    big={true}
    title={'Payment Services'}
    buttons={servicesEnabled.length > 0 ? [
      {
        label: 'Link',
        onClick: () => setModalOpen(true),
      },
    ] : []}
  >
    <ModalPayvyBase
      title={'Link to Payment Services'}
      isOpen={modalOpen}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={loading || processing}
      confirmButtonDisabled={loading || processing}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={loading || processing}
    >
      <Formik
        initialValues={servicesInitialValues}
        innerRef={formikRef}
        validationSchema={Yup.object(servicesValidation)}
        onSubmit={(values) => {
          const submitValues = {
            ...values,
            contact: contactId,
            success: () => {
              setModalOpen(false);
            }
          };
          dispatch(linkContactToServiceRecipient(submitValues))
        }}
        enableReinitialize={true}
      >
        {props => <Form>
          <PayvyGlobalFormError/>
          {servicesEnabled.map((service, index) => {
            return <ModulePaymentServiceLinkingService
              key={index}
              contactId={contactId}
              service={service}
              value={props.values[service.internal_name]}
              setField={(value) => props.setFieldValue(service.internal_name, value)}
            />
          })}
          <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
        </Form>}
      </Formik>
    </ModalPayvyBase>
    {servicesEnabled.map((service, index) => {
      return <ModulePaymentServiceLinkedAccount
        key={index}
        contactId={contactId}
        service={service}
      />
    })}
    {servicesEnabled?.length === 0 ?
      <span className={'font-light'}>
        No enabled payment services. You can enable payment services in
                      <PayvyIconButton
                        type={'button'}
                        onClick={() => navigate(PAYVY_URL.SETTINGS.BANKING)}
                        iconPaddingX={1}
                        iconPaddingY={0}
                        buttonText={'Banking Settings'}
                        mainColor={'none'}
                        borderColor={'none'}
                        textColor={'red-200'}
                        hoverTextColor={'red-300'}
                        wrapperClassName={'whitespace-nowrap'}
                      />
      </span> : null}
  </ModuleBlock>;
};
