import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import {build_url, stringify} from '../utils/Utility';
import {refreshAccessToken} from "./api";

export const initialState = {
  loading: false,
  hasErrors: false,
  linkToken: '',
  processing: false,
  loadingBankAccounts: false,
  hasErrorsBankAccounts: false,
  bankAccounts: [],
  bankAccountCount: 0,
  loadingPlaidUserTokens: false,
  plaidUserIdToken: {},
};

const plaidSlice = createSlice({
  name: 'plaid',
  initialState,
  reducers: {
    getLinkTokenStart: (state) => {
      state.loading = true;
    },
    getLinkTokenSuccess: (state, {payload}) => {
      state.loading = false;
      state.hasErrors = false;
      state.linkToken = payload.link_token;
    },
    getLinkTokenFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getBankAccountsStart: (state) => {
      state.loadingBankAccounts = true;
    },
    getBankAccountsSuccess: (state, {payload}) => {
      state.loadingBankAccounts = false;
      state.hasErrorsBankAccounts = false;
      state.bankAccounts = payload.results;
      state.bankAccountCount = payload.count;
    },
    getBankAccountsFailure: (state) => {
      state.loadingBankAccounts = false;
      state.hasErrorsBankAccounts = true;
    },
    getPlaidUserTokensStart: (state) => {
      state.loadingPlaidUserTokens = true;
    },
    getPlaidUserTokensSuccess: (state, {payload}) => {
      state.loadingPlaidUserTokens = false;
      state.plaidUserIdToken[payload.plaidUserId] = payload.data.link_token;
    },
    getPlaidUserTokensRemove: (state, {payload}) => {
      if(state.plaidUserIdToken.hasOwnProperty(payload.plaidUserId))
        delete state.plaidUserIdToken[payload.plaidUserId];
    },
    getPlaidUserTokensFailure: (state) => {
      state.loadingPlaidUserTokens = false;
      state.hasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  getLinkTokenStart,
  getLinkTokenFailure,
  getLinkTokenSuccess,
  getBankAccountsStart,
  getBankAccountsFailure,
  getBankAccountsSuccess,
  getPlaidUserTokensStart,
  getPlaidUserTokensFailure,
  getPlaidUserTokensSuccess,
  getPlaidUserTokensRemove,
  processingStart,
  processingFinish,
} = plaidSlice.actions;
export const plaidSelector = (state) => state.plaid;
export default plaidSlice.reducer;

export function getLinkToken({
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(getLinkTokenStart());
    try {
      const response = await fetch(PAYVY_API.V1.PLAID.GET_LINK_TOKEN, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getLinkTokenSuccess(data));
        success(data);
      } else {
        failure(data);
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getLinkToken({
              success,
              failure
            }));
          }
        }
        dispatch(getLinkTokenFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(getLinkTokenFailure());
    }
  };
}

export function createAccessToken({
  public_token,
  metadata,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(PAYVY_API.V1.PLAID.CREATE_ACCESS_TOKEN, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          public_token,
          metadata,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(createAccessToken({
              public_token,
              metadata,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function getPlaidAccounts({
  page = 1,
  page_size = 10
} = {}) {
  return async(dispatch) => {
    dispatch(getBankAccountsStart());
    try {
      const params = stringify({
        page,
        page_size,
      }, {skipEmptyString: true});
      const response = await fetch(`${PAYVY_API.V1.PLAID.BANK_ACCOUNT_LIST}?${params}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getBankAccountsSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getPlaidAccounts({
              page,
              page_size
            }));
          }
        }
        dispatch(getBankAccountsFailure());
      }
    } catch(error) {
      dispatch(getBankAccountsFailure());
    }
  };
}

export function linkPlaidAccount({
  payvy_bank_id,
  plaid_bank_id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    payvy_bank_id = payvy_bank_id === '' ? null : payvy_bank_id;
    try {
      const response = await fetch(PAYVY_API.V1.PLAID.LINK_PLAID_ACCOUNT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          payvy_bank_id,
          plaid_bank_id,
        }),
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(linkPlaidAccount({
              payvy_bank_id,
              plaid_bank_id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function removePlaidAccount({
  id,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.PLAID.REMOVE_PLAID_ACCOUNT, {id}), {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 204) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(removePlaidAccount({
              id,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}

export function getLinkUpdateToken({
  plaidUserId,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(getPlaidUserTokensStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.PLAID.GET_UPDATE_TOKEN, {id: plaidUserId}), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getPlaidUserTokensSuccess({
          plaidUserId,
          data,
        }));
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getLinkUpdateToken({
              plaidUserId,
              success,
              failure
            }));
          }
        }
        failure(data);
        dispatch(getPlaidUserTokensFailure());
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
      dispatch(getPlaidUserTokensFailure());
    }
  };
}

export function removePlaidUserIdFromTokens({
  plaidUserId,
  success = noop
}) {
  return async(dispatch) => {
    dispatch(getPlaidUserTokensRemove({plaidUserId}));
    success();
  };
}

export function linkPlaidAccountToService({
  service,
  plaidAccountId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.PLAID.LINK_PLAID_ACCOUNT_TO_SERVICE, {
        service,
        id: plaidAccountId,
      }), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 202) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(linkPlaidAccountToService({
              service,
              plaidAccountId,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
      failure({nonFieldErrors: [error.toString()]});
    }
    dispatch(processingFinish());
  };
}
