import {useEffect} from "react";
import {useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {companiesSelector} from "../../../slices/companies";

export const MissingCompanyRoute = () => {
  const navigate = useNavigate();
  const {company: {id: companyId} = {}} = useSelector(companiesSelector);
  useEffect(() => {
    const missingCompany = typeof companyId !== 'number'
    if(!missingCompany) navigate(PAYVY_URL.DASHBOARD, {replace: true})
  }, [navigate, companyId]);
  return (
    <Outlet/>
  );
};
