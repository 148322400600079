import {noop} from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {getFailedTransactionList} from "../../../slices/newBill";
import {formatCurrency} from '../../../utils/Utility';

export const SelectFailedTransactions = ({
  billId,
  setValue,
}) => {
  setValue = setValue || noop;
  const dispatch = useDispatch();
  const {
    loading: {
      item: loading,
      posting: processing
    },
    failedTransactions = {}
  } = useSelector((state) => state.bill);
  const [valueObject, setValueObject] = React.useState(null);
  const [transactionList, setTransactionList] = useState([]);
  useEffect(() => {
    const newTransactionList = [];
    failedTransactions[billId]?.map((transaction) => newTransactionList.push({
      key: transaction.id,
      label: `${formatCurrency(transaction.amount)} on ${moment(transaction.created_at)
      .calendar()}`,
      value: transaction.id,
    }));
    setTransactionList(newTransactionList);
  }, [failedTransactions, billId]);

  useEffect(() => {
    if(!!billId) dispatch(getFailedTransactionList({id: billId}));
  }, [dispatch, billId]);

  const handleChange = (value) => {
    setValue(value?.value);
    setValueObject(value);
  };

  return <Select
    className={'payvy-select'}
    placeholder="Select Transaction"
    isLoading={loading || processing}
    value={valueObject}
    onChange={handleChange}
    options={transactionList}
  />;
};
