import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {
  GlobalFormError,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  PayvySelectComponent
} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES, PAYVY_URL} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {createContact} from '../../../slices/newContact';
import {USStatesWithLabel} from '../../../utils/USStates';
import {handleErrors, payvyToast, renameDictionaryKeys, zipCodeInfo} from '../../../utils/Utility';


const ContactCreateCustomerForm = () => {
  const [zipProcessing, setZipProcessing] = useState(false);
  const [queryTermTimeout, setQueryTermTimeout] = useState(0);
  const {
    loading: {
      list: loading,
      posting: processing
    },
  } = useSelector((state) => state.contact);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const queryZip = async(value, props) => {
    setZipProcessing(true);
    const {
      city = '',
      state = '',
    } = await zipCodeInfo(value) || {};
    props.setFieldValue('addressState', state);
    props.setFieldValue('addressCity', city);
    setZipProcessing(false);
  };
  return <div className={'grid grid-cols-2 gap-6 mx-4'}>
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        sendToContact: false,
        activePaymentType: 'ach',
        addressZip: '',
        addressCity: '',
        addressState: '',
        addressLine1: '',
        addressLine2: '',
        accountNumber: '',
        routingNumber: '',
        accountType: '',
        customerType: '',
        contactType: 'Customer',
        invoicePrefix: '',
        sequenceNum: '',
      }}
      validationSchema={Yup.object({
        companyName: Yup.string()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        email: Yup.string()
                  .email(FORM_ERROR_MESSAGES.MUST_BE_EMAIL),
        addressZip: Yup.string(),
        addressCity: Yup.string()
                        .when(['addressZip', 'addressStreet'], {
                          is: (
                            addressZip = '', addressStreet = '') => addressZip.length > 0 || addressStreet.length > 0,
                          then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                        }),
        addressState: Yup.string()
                         .when(['addressZip', 'addressStreet'], {
                           is: (
                             addressZip = '', addressStreet = '') => addressZip.length > 0 || addressStreet.length > 0,
                           then: s => s.required(FORM_ERROR_MESSAGES.INVALID_ZIP),
                         }),
        addressLine1: Yup.string()
                         .when('addressZip', {
                           is: (addressZip = '') => addressZip.length > 0,
                           then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                         }),
        addressLine2: Yup.string()
                         .nullable(),
        phone: Yup.string()
                  .nullable()
                  .test('is-valid-phone', 'Invalid phone number', (value) => (!value || (!!value && isPossiblePhoneNumber(value)))),
      })}
      onSubmit={(values, helpers) => {
        dispatch(createContact({
          body: {
            invoice_prefix: values.invoicePrefix,
            city: values.addressCity,
            line1: values.addressLine1,
            line2: values.addressLine2,
            state: values.addressState,
            zip: values.addressZip,
            email: values.email,
            name: values.companyName,
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            send_to_contact: values.sendToContact,
            contact_type: values.contactType,
            next_sequence_number: values.sequenceNum,
            patchify: true,
          },
          successCallback: () => {
            payvyToast('Customer created successfully.', {appearance: 'success'});
            navigate(PAYVY_URL.CONTACTS.LIST);
          },
          errorCallback: (error) => {
            const data = renameDictionaryKeys(error.data || {}, {
              'name': 'companyName',
              'next_sequence_number': 'sequenceNum',
            });
            if('message' in error) {
              payvyToast(error.message, {appearance: 'error'});
            }
            handleErrors(data, helpers);
          }
        }));
      }}
    >
      {props => <Form>
        <PayvyLabeledInput
          label={'Company name'}
          name={'companyName'}
          as={PayvyInput}
        />
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'First name'}
            name={'firstName'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Last name'}
            name={'lastName'}
            as={PayvyInput}
          />
        </div>
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'Email'}
            name={'email'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Phone number'}
            name={'phone'}
            alwaysLabel={true}
          >
            <PhoneInput
              international={true}
              defaultCountry="US"
              placeholder="Enter phone number"
              value={props.values.phone}
              onChange={(value) => props.setFieldValue('phone', value)}
            />
          </PayvyLabeledInput>
        </div>
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'Address'}
            name={'addressLine1'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Address Line 2'}
            name={'addressLine2'}
            as={PayvyInput}
          />
        </div>
        <div className={'w-full grid grid-cols-3 gap-1'}>
          <PayvyLabeledInput
            label={'Zip Code'}
            name={'addressZip'}
            as={PayvyInput}
            disabled={zipProcessing}
            onChange={(value) => {
              if(queryTermTimeout) clearTimeout(queryTermTimeout);
              props.setFieldValue('addressZip', value);
              setQueryTermTimeout(setTimeout(async() => {
                await queryZip(value, props);
              }, 1000));
            }}
          />
          <PayvyLabeledInput
            label={'City'}
            name={'addressCity'}
            loading={zipProcessing}
            disabled={zipProcessing}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'State'}
            component={PayvySelectComponent}
            name={'addressState'}
            options={USStatesWithLabel}
          />
        </div>
        <div className={'w-full grid grid-cols-2 gap-5'}>
          <PayvyLabeledInput
            label={'Invoice prefix'}
            name={'invoicePrefix'}
            as={PayvyInput}
          />
          <PayvyLabeledInput
            label={'Next sequence number'}
            name={'sequenceNum'}
            as={PayvyInput}
          />
        </div>
        <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        <div className={'w-full flex flex-row justify-end gap-5 mt-5'}>
          <PayvyIconButton
            buttonText={'Cancel'}
            onClick={() => navigate(PAYVY_URL.CONTACTS.LIST)}
            disabled={processing || loading}
            loading={processing || loading}
            borderSize={2}
            borderColor={'red-300'}
            mainColor={'neutral-0'}
            textColor={'red-300'}
            hoverTextColor={'neutral-0'}
            shortcutKey={'escape'}
          />
          <PayvyIconButton
            buttonText={'Create customer'}
            disabled={processing || loading}
            loading={processing || loading}
            type={'submit'}
          />
        </div>
      </Form>}
    </Formik>
  </div>;
};

export default ContactCreateCustomerForm;
