import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {CellFormattedDate, CellSender} from '../../../comps/elements/PayvyTable';
import {getUserInboxes} from "../../../slices/newInbox";

export const ListIncoming = () => {
  const dispatch = useDispatch();
  const {
    loading: {list: inboxesLoading},
    items,
  } = useSelector((state) => state.inbox);
  const {inbox: inboxList} = items || {inbox: []};
  const isEmpty = (inboxList || []).length > 0;
  useEffect(() => {
    dispatch(getUserInboxes({
      page: 1,
      page_size: 10,
      category: 'inbox',
      tableView: false
    }));
  }, [dispatch]);
  const columns = React.useMemo(() => [
    {
      Header: 'Received',
      accessor: 'received_on',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'From',
      accessor: 'mail_content.sender',
      Cell: CellSender,
    }, {
      Header: 'Pages',
      accessor: 'number_of_pages',
      sortDescFirst: true,
    },
  ], []);
  return <>
    {inboxList.length > 0 ? <PayvyTable
      columns={columns}
      data={inboxList.slice(0, 10)}
      fetchData={noop}
      loading={isEmpty ? inboxesLoading : false}
      canScroll={false}
    /> : <span>Nothing to see here.</span>}
  </>;

};
