import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import moment from 'moment';
import {PAYVY_API} from '../constants';
import {calculateHashForDictionary} from "../utils/Checksum";
import Debugger from '../utils/Debug';
import {build_url, refreshAccessToken, stringify} from '../utils/Utility';

export const initialState = {
  invoiceList: [],
  invoiceListQueryChecksum: '',
  lastUpdated: new Date().getTime(),
  loading: false,
  hasErrors: false,
  processing: false,
  next: null,
  previous: null,
  count: null,
  categorizedInvoiceList: {},
  invoiceDetails: {},
};

const receivablesSlice = createSlice({
  name: 'receivables',
  initialState,
  reducers: {
    invoiceListStart: (
      state, {
        payload: {
          backgroundSync = false,
          checksum
        } = {}
      }) => {
      if(backgroundSync === false) state.loading = true;
      state.invoiceListQueryChecksum = checksum;
    },
    invoiceListSuccess: (state, {payload}) => {
      const {
        data,
        calledOn,
        category,
      } = payload;
      if(calledOn > state.lastUpdated) {
        state.invoiceList = data.results;
        state.next = data.next;
        state.previous = data.previous;
        state.count = data.count;
        state.categorizedInvoiceList[category] = {
          results: data.results,
          count: data.count,
        };
        state.lastUpdated = calledOn;
        state.loading = false;
        state.hasErrors = false;
      }
    },
    invoiceListFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    invoiceDetailsStart: (state, {payload: {backgroundSync = false} = {}}) => {
      if(backgroundSync === false) state.loading = true;
    },
    invoiceDetailsSuccess: (state, {payload}) => {
      const {
        data,
        calledOn,
      } = payload;
      if(calledOn > state.lastUpdated) {
        state.invoiceDetails[data.id] = data;
        state.lastUpdated = calledOn;
        state.loading = false;
        state.hasErrors = false;
      }
    },
    invoiceDetailsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  invoiceListStart,
  invoiceListSuccess,
  invoiceListFailure,
  invoiceDetailsStart,
  invoiceDetailsSuccess,
  invoiceDetailsFailure,
  processingStart,
  processingFinish,
} = receivablesSlice.actions;
export const receivablesSelector = (state) => state.receivables;
export default receivablesSlice.reducer;


export function getInvoicesList({
  page = 1,
  page_size = 10,
  q = '',
  order_by = '',
  contact = '',
  amount,
  category = 'draft',
  backgroundSync = false,
  previousChecksum = '',
  success = noop,
  failure = noop,
}) {
  const calledOn = (new Date()).getTime();
  const checksum = calculateHashForDictionary({
    page: page - 1,
    page_size,
    q,
    order_by,
    contact,
    amount,
    category,
  })
  return async(dispatch) => {
    if(previousChecksum === checksum) return;
    Debugger.info('📡 API: RECEIVABLES', 'Getting invoice list.')
    dispatch(invoiceListStart({
      backgroundSync,
      checksum
    }));
    try {
      const params = stringify({
        page: page,
        page_size,
        contact,
        category,
        q,
        amount,
        order_by,
      }, {skipEmptyString: true});
      const response = await fetch(`${PAYVY_API.V1.RECEIVABLES.INVOICE_LIST}?${params}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(invoiceListSuccess({
          data,
          calledOn,
          category,
        }));
        success();
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getInvoicesList({
              page,
              page_size,
              q,
              order_by,
              contact,
              amount,
              category,
              backgroundSync,
              previousChecksum,
              success,
              failure,
            }));
          }
        }
        dispatch(invoiceListFailure());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(invoiceListFailure());
      failure();
    }
  };
}

export function sendOutInvoiceViaEmail({
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.RECEIVABLES.SEND_OUT_INVOICE, {id: invoiceId}), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 200) {
        dispatch(processingFinish());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(sendOutInvoiceViaEmail({
                invoiceId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(processingFinish());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure();
    }
  };
}

export function removeInvoice({
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.RECEIVABLES.DETAILS, {id: invoiceId}), {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(removeInvoice({
                invoiceId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(processingFinish());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure();
    }
  };
}

export function getInvoiceDetails({
  invoiceId,
  publicEP = false,
  backgroundSync = false,
  success = noop,
  failure = noop,
}) {
  const url = publicEP ? PAYVY_API.V1.RECEIVABLES.PUBLIC_DETAILS : PAYVY_API.V1.RECEIVABLES.DETAILS;
  return async(dispatch) => {
    dispatch(invoiceDetailsStart({backgroundSync}));
    try {
      const response = await fetch(build_url(url, {id: invoiceId}), {
        method: 'GET',
        headers: publicEP ? {} : {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status === 200) {
        dispatch(invoiceDetailsSuccess({
          data: await response.json(),
          calledOn: (new Date()).getTime(),
        }));
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(getInvoiceDetails({
                invoiceId,
                publicEP,
                backgroundSync,
                success,
                failure
              }));
            }
          }
        }
        dispatch(invoiceDetailsFailure());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(invoiceDetailsFailure());
      failure();
    }
  };
}

export function captureInvoice({
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.RECEIVABLES.CAPTURE_INVOICE, {id: invoiceId}), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(captureInvoice({
                invoiceId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(processingFinish());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure();
    }
  };
}

export function chargeInvoice({
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.RECEIVABLES.CHARGE_INVOICE, {id: invoiceId}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(chargeInvoice({
                invoiceId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(processingFinish());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure();
    }
  };
}

// void invoice
export function voidInvoice({
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.RECEIVABLES.VOID_INVOICE, {id: invoiceId}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success();
      } else {
        if(response.status === 401) {
          const data = await response.json();
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(voidInvoice({
                invoiceId,
                success,
                failure
              }));
            }
          }
        }
        dispatch(processingFinish());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure();
    }
  };
}

export function createInvoice({
  invoiceID,
  customer,
  invoiceNumber,
  isDraft,
  invoiceDate,
  terms,
  dueDate,
  items,
  discount,
  discountType,
  shipping,
  adjustment,
  total,
  termsAndConditions,
  captureFundsLater,
  ccEmail,
  toEmail,
  paymentMethod,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    const url = invoiceID ? build_url(PAYVY_API.V1.RECEIVABLES.INVOICE_UPDATE, {id: invoiceID}) : PAYVY_API.V1.RECEIVABLES.INVOICE_CREATE;
    try {
      const response = await fetch(url, {
        method: invoiceID ? 'PUT' : 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          customer,
          invoice_number: invoiceNumber,
          is_draft: isDraft,
          invoice_date: moment(invoiceDate, 'MM/DD/YYYY')
          .format('YYYY-MM-DD'),
          terms,
          due_date: moment(dueDate, 'MM/DD/YYYY')
          .format('YYYY-MM-DD'),
          items,
          discount,
          discount_type: discountType,
          shipping,
          adjustment,
          total,
          terms_and_conditions: termsAndConditions,
          capture_funds_later: captureFundsLater,
          email_to: toEmail,
          email_cc: ccEmail,
          payment_method: paymentMethod,
        }),
      });
      if(response.status >= 200 && response.status < 300) {
        success();
      } else {
        const data = await response.json();
        if(response.status === 401) {
          if(data.code === 'token_not_valid') {
            const tokenRefreshed = await refreshAccessToken();
            if(tokenRefreshed) {
              return await dispatch(createInvoice({
                invoiceID,
                customer,
                invoiceNumber,
                isDraft,
                invoiceDate,
                terms,
                dueDate,
                items,
                discount,
                discountType,
                shipping,
                adjustment,
                total,
                termsAndConditions,
                captureFundsLater,
                ccEmail,
                toEmail,
                paymentMethod,
                success,
                failure
              }));
            }
          }
        }
        failure(data);
      }
    } catch(error) {
      Debugger.error('error', error);
      failure(error);
    }
    dispatch(processingFinish());
  };
}
