import {Field, Form, Formik} from 'formik';
import React, {useRef} from 'react';
import {useDispatch} from 'react-redux';
import {PayvyInput} from '../../../comps/forms';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getContactDetails, updateContact} from '../../../slices/newContact';
import {ModuleBlock} from './ModuleBlock';

export const ModuleCompanyInformationEdit = ({
  contact,
  setEditMode,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const submitForm = React.useRef(null);
  const {
    id,
    name,
    first_name,
    last_name,
    email,
    address = {},
  } = contact;
  const {
    line1,
    line2,
    city,
    state,
    zip,
  } = address || {};
  return <ModuleBlock
    small={true}
    title={'Company Information'}
    buttons={[
      {
        label: 'Cancel',
        onClick: () => {
          setEditMode(false);
        },
      }, {
        label: 'Save',
        onClick: () => {
          submitForm.current.click();
        },
      },
    ]}
  >
    <Formik
      enableReinitialize={true}
      innerRef={formikRef}
      initialValues={{
        name: name || '',
        first_name: first_name || '',
        last_name: last_name || '',
        email: email || '',
        zip: zip || '',
        city: city || '',
        state: state || '',
        line1: line1 || '',
        line2: line2 || '',
      }}
      onSubmit={(values) => {
        dispatch(updateContact({
          id,
          body: {
            name: values.name,
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            zip: values.zip,
            city: values.city,
            state: values.state,
            line1: values.line1,
            line2: values.line2,
            patchify: true,
          },
          successCallback: () => {
            dispatch(getContactDetails({
              id,
              successCallback: () => setEditMode(false),
            }));
          }
        }));
      }}
    >
      <Form>
        <div className={'w-full'}>
          <Field as={PayvyInput} name={'name'} type={'text'} placeholder={'Name'} className={'my-0.5 px-1'}/>
        </div>
        <div className={'w-full'}>
          <Field as={PayvyInput} name={'first_name'} type={'text'} placeholder={'First Name'}
                 className={'my-0.5 px-1'}/>
          <Field as={PayvyInput} name={'last_name'} type={'text'} placeholder={'Last Name'} className={'my-0.5 px-1'}/>
        </div>
        <div className={'w-full'}>
          <Field as={PayvyInput} name={'email'} type={'email'} placeholder={'Email address'} className={'my-0.5 px-1'}/>
        </div>
        <h2 className={'mt-2 mb-0.5 text-neutral-150'}>Billing Address</h2>
        <div className={'w-full'}>
          <Field as={PayvyInput} name={'line1'} type={'text'} placeholder={'Line 1'} className={'my-0.5 px-1'}/>
        </div>
        <div className={'w-full'}>
          <Field as={PayvyInput} name={'line2'} type={'text'} placeholder={'Line 2'} className={'my-0.5 px-1'}/>
        </div>
        <div className={'w-full grid grid-cols-5 gap-0.5'}>
          <Field as={PayvyInput} name={'city'} type={'text'} className={'my-0.5 px-1 col-span-2'} placeholder={'City'}/>
          <Field as={PayvyInput} name={'state'} type={'text'} className={'my-0.5 px-1 col-span-2'}
                 placeholder={'State'}/>
          <Field as={PayvyInput} name={'zip'} type={'text'} placeholder={'Zip'} className={'my-0.5 px-1'}/>
        </div>
        <button ref={submitForm} type="submit" className={'hidden'}>Submit</button>
      </Form>
    </Formik>
  </ModuleBlock>;
};
