import React from 'react';
import {CamelCaseToTitleCase} from '../../../utils/Utility';

export const GlobalFormError = ({
  title = 'Something Went Wrong',
  errors,
  multipleFromDict = false,
  visible = true,
  removeFields = [],
}) => {
  if(!errors || !visible) return null;
  let errorsHtml;
  if(multipleFromDict) {
    errorsHtml = Object.entries(errors)
                       .map(([key, value]) => {
                         if(removeFields.includes(key) || !value) return null;
                         return <div key={key} className={'mt-1'}>
                           <h1 className={'text-lg font-bold'}>{CamelCaseToTitleCase(key)}</h1>
                           <p className={'mt-1'}>- {value}</p>
                         </div>;
                       });
  }
  return <div className={'w-full bg-red-50 border border-red-950 rounded-md py-4 px-5 mt-1 shadow-lg'}>
    <h1 className={'text-xl font-bold text-red-950'}>{title}</h1>
    {multipleFromDict ? errorsHtml : <p className={'text-red-950 mt-1'}>{errors}</p>}
  </div>;
};
