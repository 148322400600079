import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {companiesSelector, getCompaniesMembers} from '../../../slices/companies';

export const SelectApproval = ({
  value,
  setField,
  readOnly,
}) => {
  setField = setField || noop;
  const dispatch = useDispatch();
  const [valueObject, setValueObject] = React.useState(null);
  const [calledMemberUpdate, setCalledMemberUpdate] = React.useState(false);
  const {
    company: {
      id: companyId,
      membersOptions: options,
    },
  } = useSelector(companiesSelector);

  useEffect(() => {
    if(!!value && value.length > 0) setValueObject(options.find(option => option.value === value[0]) || null);
  }, [value, options]);

  useEffect(() => {
    if((!!options && options.length === 0) || ((!options || options.length === 0) && !calledMemberUpdate)) {
      dispatch(getCompaniesMembers());
      setCalledMemberUpdate(true);
    }
  }, [dispatch, options, companyId, calledMemberUpdate]);

  const triggerLocalFieldChange = (option) => {
    setField(option.value);
    setValueObject(option);
  };

  return <Select
    className={'w-full'}
    placeholder="Approver"
    isDisabled={readOnly}
    value={valueObject}
    onChange={(option) => triggerLocalFieldChange(option)}
    options={options}
  />;
};
