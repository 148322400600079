import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import Debugger from '../utils/Debug';
import {build_url} from '../utils/Utility';
import {refreshAccessToken} from "./api";

export const initialState = {
  firstTime: true,
  flowStatus: 'started',
  merchant: {},
  buyer: {},
  loading: false,
  hasErrors: false,
  processing: false,
};

const finixSlice = createSlice({
  name: 'finix',
  initialState,
  reducers: {
    resetState: (state) => {
      state.firstTime = true;
      state.flowStatus = 'started';
      state.merchant = {};
      state.buyer = {};
      state.loading = false;
      state.hasErrors = false;
      state.processing = false;
    },
    setIdentityStateStart: (state) => {
      state.loading = true;
    },
    setIdentityState: (state, {payload}) => {
      state.merchant = {id: payload.merchantId};
      state.buyer = {id: payload.buyerId};
      state.flowStatus = 'fetched';
      state.loading = false;
    },
    getMerchantStart: (state) => {
      state.loading = true;
      state.flowStatus = 'fetching';
    },
    getMerchantSuccess: (state, {payload}) => {
      state.firstTime = false;
      state.loading = false;
      state.merchant = payload;
      state.flowStatus = 'fetched';
    },
    getMerchantFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getIdentityStart: (state) => {
      state.loading = true;
      state.flowStatus = 'fetching';
    },
    getIdentitySuccess: (state, {payload}) => {
      state.loading = false;
      state.buyer = payload;
    },
    getIdentityFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    invoiceIdentitiesStart: (state) => {
      state.loading = true;
      state.flowStatus = 'fetching';
      state.merchant = {};
      state.buyer = {};
    },
    invoiceIdentitiesSuccess: (state, {payload}) => {
      state.loading = false;
      state.merchant = {id: payload.merchant_id};
      state.buyer = {id: payload.customer_id};
      state.flowStatus = 'fetched';
    },
    invoiceIdentitiesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
      state.flowStatus = 'failed';
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
  },
});

export const {
  resetState,
  setIdentityStateStart,
  setIdentityState,
  getMerchantStart,
  getMerchantSuccess,
  getMerchantFailure,
  getIdentityStart,
  getIdentitySuccess,
  getIdentityFailure,
  invoiceIdentitiesStart,
  invoiceIdentitiesSuccess,
  invoiceIdentitiesFailure,
  processingStart,
  processingFinish,
} = finixSlice.actions;
export const finixSelector = (state) => state.finix;
export default finixSlice.reducer;


export function getMerchant() {
  return async(dispatch) => {
    dispatch(getMerchantStart());
    try {
      const response = await fetch(`${PAYVY_API.V1.FINIX.MERCHANT}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getMerchantSuccess(data));
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getMerchant());
          }
        }
        dispatch(getMerchantFailure());
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(getMerchantFailure());
    }
  };
}

export function getContactIdentityId({
  id,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(getIdentityStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.FINIX.CONTACT_IDENTITY_ID, {'id': id}), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(getIdentitySuccess(data));
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(getContactIdentityId({
              id,
              success,
              failure
            }));
          }
        }
        dispatch(getIdentityFailure());
        failure();
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(getIdentityFailure());
      failure();
    }
  };
}

export function addPaymentInstrumentFromToken({
  merchantId,
  token,
  fraudSessionId,
  plaidAccountId,
  processor,
  publicEP,
  invoiceId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const url = publicEP ? build_url(PAYVY_API.V1.FINIX.PUBLIC_ADD_PAYMENT_METHOD, {id: invoiceId}) : PAYVY_API.V1.FINIX.ADD_PAYMENT_METHOD;
      const response = await fetch(`${url}`, {
        method: 'POST',
        headers: publicEP ? {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        } : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          merchant_id: merchantId,
          token,
          fraud_session_id: fraudSessionId,
          processor: processor,
          plaid_account_id: plaidAccountId,
        }),
      });
      let data;
      try {
        data = await response.json();
      } catch(error) {
        data = {};
      }
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(addPaymentInstrumentFromToken({
              merchantId,
              token,
              fraudSessionId,
              plaidAccountId,
              processor,
              publicEP,
              invoiceId,
              success,
              failure
            }));
          }
        }
        dispatch(processingFinish());
        failure(data);
      }
    } catch(error) {
      failure(error);
      Debugger.error('error', error);
      dispatch(processingFinish());
    }
  };
}

export function getInvoiceIdentities({
  invoiceID,
}) {
  return async(dispatch) => {
    dispatch(invoiceIdentitiesStart());
    try {
      const response = await fetch(build_url(`${PAYVY_API.V1.FINIX.INVOICE_IDENTITIES}`, {id: invoiceID}), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(invoiceIdentitiesSuccess(data));
      } else {
        dispatch(invoiceIdentitiesFailure());
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(invoiceIdentitiesFailure());
    }
  };
}

export function refundInvoice({
  invoiceID,
  amount,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(`${PAYVY_API.V1.FINIX.REFUND_INVOICE}`, {id: invoiceID}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: JSON.stringify({
          amount,
        }),
      });
      const data = await response.json();
      if(response.status === 202) {
        dispatch(processingFinish());
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(refundInvoice({
              invoiceID,
              amount,
              success,
              failure
            }));
          }
        }
        dispatch(processingFinish());
        failure('Error in refunding invoice.');
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure(error);
    }
  };
}

export function uploadEvidence({
  invoiceId,
  disputeId,
  files,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    const formData = new FormData();
    files.forEach((file) => formData.append('files', file));
    try {
      const response = await fetch(`${build_url(PAYVY_API.V1.FINIX.UPLOAD_EVIDENCE, {
        invoiceId,
        disputeId,
      })}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        body: formData,
      });
      const data = await response.json();
      if(response.status === 202) {
        dispatch(processingFinish());
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(uploadEvidence({
              invoiceId,
              disputeId,
              files,
              success,
              failure
            }));
          }
        }
        dispatch(processingFinish());
        failure('Error in uploading evidence.');
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure(error);
    }
  };
}

// Accept the dispute
export function acceptDispute({
  invoiceId,
  disputeId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(`${PAYVY_API.V1.FINIX.ACCEPT_DISPUTE}`, {
        invoiceId,
        disputeId,
      }), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 202) {
        dispatch(processingFinish());
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(acceptDispute({
              invoiceId,
              disputeId,
              success,
              failure
            }));
          }
        }
        dispatch(processingFinish());
        failure('Error in accepting dispute.');
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure(error);
    }
  };
}

export function createContactIdentity({
  contactId,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(`${PAYVY_API.V1.FINIX.CREATE_CONTACT_IDENTITY}`, {
        contactId,
      }), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status >= 200 && response.status < 300) {
        dispatch(processingFinish());
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(createContactIdentity({
              contactId,
              success,
              failure
            }));
          }
        }
        dispatch(processingFinish());
        failure({
          success: false,
          message: 'Error in creating contact identity.',
        });
      }
    } catch(error) {
      Debugger.error('error', error);
      dispatch(processingFinish());
      failure(error);
    }
  };
}


export function resetFinixState() {
  return (dispatch) => {
    dispatch(resetState());
  };
}

export function setInvoiceIdentities({
  merchantId,
  buyerId,
}) {
  return (dispatch) => {
    dispatch(setIdentityStateStart());
    setTimeout(() => {
      dispatch(setIdentityState({
        merchantId,
        buyerId,
      }));
    }, 500);
  };
}
