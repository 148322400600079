import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {FaPlus} from "react-icons/fa";
import {useDispatch, useSelector} from "react-redux";
import * as Yup from 'yup';
import {GlobalFormError, PayvyIconButton, PayvyLabeledInput, PayvySelectComponent} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {getLedgerInformation, ledgerLinkContact} from "../../../slices/newIntegration";
import {payvyToast} from "../../../utils/Utility";

export const ModalLinkToLedger = ({
  ledger,
  contactId
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const submitForm = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {ledger: loading},
  } = useSelector((state) => state.integration)
  return <>
    <PayvyIconButton
      Icon={FaPlus}
      onClick={() => setModalOpen(true)}
      tooltipContent={`Link to ${ledger.name}`}
      wrapperClassName={'h-full w-full justify-end'}
      loading={loading}
    />
    <ModalPayvyBase
      title={`Link`}
      isOpen={modalOpen}
      confirmButtonText={`Link to ${ledger.name}`}
      confirmButtonAction={() => submitForm.current.click()}
      confirmButtonLoading={loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={loading}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          ledgerId: null
        }}
        validationSchema={Yup.object({
          ledgerId: Yup.string()
                       .required('Required')
        })}
        onSubmit={(values) => {
          dispatch(ledgerLinkContact({
            body: {
              ledger: ledger.codename,
              ledger_id: values.ledgerId,
              payvy_id: contactId
            },
            successCallback: () => {
              dispatch(getLedgerInformation({forceUpdate: true}))
            },
            errorCallback: () => {
              payvyToast('Error linking contact to ledger.', {appearance: 'error'})
            }
          }))
        }}
      >
        {props => <Form>
          <PayvyLabeledInput
            alwaysLabel={true}
            label={`${ledger.name} Vendor`}
            component={PayvySelectComponent}
            placeholder={`vendor from ${ledger.name}`}
            name={'ledgerId'}
            options={ledger?.contacts.filter(contact => contact.payvy_id === null)
                           .map(contact => ({
                             value: contact.ledger_id,
                             label: contact.name
                           }))}
          />
          <button ref={submitForm} type="hidden" onClick={props.handleSubmit}/>
          <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        </Form>}
      </Formik>
    </ModalPayvyBase>
  </>;
};
