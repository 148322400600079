import {Command} from "cmdk";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {getUserBills} from "../../../slices/newBill";
import {getContacts} from "../../../slices/newContact";
import {getUserInboxes} from "../../../slices/newInbox";
import {build_url} from "../../../utils/Utility";

export const CommandMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const {
    items: contactItems = {'all': []},
    loading: {list: contactLoading},
  } = useSelector((state) => state.contact);
  const {
    items: rawBillItems,
    loading: {loading: billLoading}
  } = useSelector((state) => state.bill)
  const {
    items: rawInboxItems,
    loading: {loading: inboxLoading}
  } = useSelector((state) => state.inbox)
  React.useEffect(() => {
    const down = (e) => {
      if(e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((prev) => !prev);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  const navigateTo = (url, parameters) => {
    const toUrl = build_url(url, parameters)
    navigate(toUrl)
    setOpen(false)
  }
  const unfilteredBillItems = Array.from(new Map(Object.values(rawBillItems)
                                                       .flat()
                                                       .map((item) => [item.id, item])).values());
  const billItems = unfilteredBillItems.filter((bill) => {
    if(!searchQuery) {
      return bill.status !== 'paid';
    }
    return true;
  });
  const inboxItems = Array.from(new Map(Object.values(rawInboxItems)
                                              .flat()
                                              .map((item) => [item.id, item])).values());
  const limitedBillItems = searchQuery ? billItems : billItems.slice(0, 5);
  const limitedInboxItems = searchQuery ? inboxItems : inboxItems.slice(0, 5);
  const limitedContactItems = searchQuery ? contactItems['all'] : contactItems['all'].slice(0, 5);
  useEffect(() => {
    if(open) {
      dispatch(getContacts({
        page: 1,
        page_size: 5,
        show_deleted: false,
      }))
      dispatch(getUserBills({
        page: 1,
        page_size: 100,
      }))
      dispatch(getUserInboxes({
        page: 1,
        page_size: 5,
      }))
    }
  }, [dispatch, open])
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(searchQuery) {
        dispatch(getContacts({
          q: searchQuery,
          show_deleted: false,
        }))
        dispatch(getUserBills({
          q: searchQuery,
        }))
      }
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [dispatch, searchQuery])
  const itemStyle = 'p-2 cursor-pointer hover:bg-gray-100 rounded-xl text-base data-[selected=true]:bg-gray-100 flex flex-row gap-2';
  return (<>
    {open && (<div
      className="fixed inset-0 bg-neutral-0/50 backdrop-blur-sm z-20"
      onClick={() => setOpen(false)}
    />)}
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      shouldFilter={!(contactLoading || billLoading || inboxLoading)}
      label="Global Command Menu"
      className="fixed top-1/2 left-1/2 w-[90vw] max-w-lg max-h-[80vh] -translate-x-1/2 -translate-y-1/2 z-30
                   rounded-xl border bg-neutral-0 shadow-xl font-light
                   transition-transform duration-200 ease-in-out"
    >
      <Command.Input
        className="w-full p-3 text-lg border-b outline-none placeholder-gray-400"
        placeholder="Type a command..."
        value={searchQuery}
        onValueChange={(query) => setSearchQuery(query)}
      />
      <Command.List className="p-2 rounded-xl max-h-[60vh] overflow-y-auto">
        <Command.Empty className="p-3 text-gray-500">
          No results found.
        </Command.Empty>
        <Command.Group heading="Menu" className="text-sm font-semibold text-gray-500 px-3">
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.DASHBOARD)}
            className={itemStyle}
          >
            Dashboard
          </Command.Item>
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.INBOX.RECEIVED)}
            className={itemStyle}
          >
            Inbox
          </Command.Item>
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.INVOICE.LIST)}
            className={itemStyle}
          >
            Invoices
          </Command.Item>
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.BILLS.LIST)}
            className={itemStyle}
          >
            Bills
          </Command.Item>
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.CONTACTS.LIST)}
            className={itemStyle}
          >
            Contacts
          </Command.Item>
          <Command.Item
            onSelect={() => navigateTo(PAYVY_URL.TRANSACTION.LIST)}
            className={itemStyle}
          >
            Transactions
          </Command.Item>
        </Command.Group>
        <Command.Group heading='Bills' className={'text-sm font-semibold text-gray-500 px-3'}>
          {billLoading ? <Command.Loading className={'p-2 text-xs'}>Loading…</Command.Loading> : <></>}
          {limitedBillItems.map((item) => (<Command.Item
            key={item.id}
            onSelect={() => navigateTo(PAYVY_URL.BILLS.DETAILS, {id: item.id})}
            className={itemStyle}
          >
                <span
                  className="bg-gray-200 text-gray-700 px-1.5 py-0.5 rounded-md text-xs">
                  {item.status}
                </span>{" "}
            <span className="text-gray-900 grow">{item.name}</span>
            <span className={'text-2xs justify-end'}>#{item.id}</span>
          </Command.Item>))}
        </Command.Group>
        <Command.Group heading='Inbox' className={'text-sm font-semibold text-gray-500 px-3'}>
          {inboxLoading ? <Command.Loading className={'p-2 text-xs'}>Loading…</Command.Loading> : <></>}
          {limitedInboxItems.map((item) => (<Command.Item
            key={item.id}
            onSelect={() => navigateTo(PAYVY_URL.INBOX.DETAILS, {id: item.id})}
            className={itemStyle}
          >
                <span
                  className="bg-gray-200 text-gray-700 px-1.5 py-0.5 rounded-md text-xs">
                  {item.message_type}
                </span>{" "}
            <span
              className="text-gray-900 grow">{item.invoice_number || `${item.name} ${new Date(item.received_on).toLocaleDateString()}`}
              <span className={'pl-1 text-xs text-gray-500'}>{item.number_of_pages} pages</span></span>
            <span className={'text-2xs justify-end'}>#{item.id}</span>
          </Command.Item>))}
        </Command.Group>
        <Command.Group heading='Contacts' className={'text-sm font-semibold text-gray-500 px-3'}>
          {contactLoading ? <Command.Loading className={'p-2 text-xs'}>Loading…</Command.Loading> : <>
            {limitedContactItems.map((item) => (<Command.Item
              key={item.id}
              onSelect={() => navigateTo(PAYVY_URL.CONTACTS.DETAILS, {id: item.id})}
              className={itemStyle}
            >
                <span
                  className="bg-gray-200 text-gray-700 px-1.5 py-0.5 rounded-md text-xs">
                  {item.contact_type}
                </span>{" "}
              <span className="text-gray-900 grow">{item.name}</span>
              <span className={'text-2xs justify-end'}>#{item.id}</span>
            </Command.Item>))}</>}
        </Command.Group>
        {/*<Command.Group heading='Invoices' className={'text-sm font-semibold text-gray-500 px-3'}>*/}
        {/*  {invoiceLoading ? <Command.Loading className={'p-2 text-xs'}>Loading…</Command.Loading> : <></>}*/}
        {/*</Command.Group>*/}
      </Command.List>
    </Command.Dialog>
  </>);
};
