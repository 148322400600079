import {useEffect} from "react";
import {useSelector} from "react-redux";
import {Outlet, useNavigate} from "react-router-dom";
import {PAYVY_URL} from "../../../constants";
import {authSelector} from "../../../slices/auth";

export const AnonRoute = () => {
  const navigate = useNavigate();
  const {isAuthenticated} = useSelector(authSelector);
  useEffect(() => {
    if(isAuthenticated) navigate(PAYVY_URL.DASHBOARD, {replace: true})
  }, [navigate, isAuthenticated]);
  return (
    <Outlet/>
  );
};
