import {Form, Formik} from 'formik';
import React, {useEffect, useRef} from 'react';
import {FaFolder} from "react-icons/fa";
import Moment from 'react-moment';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {CleanedHTML} from "../../../comps/elements";
import {PayvyIconButton, PayvyInput, PayvyLabeledInput} from "../../../comps/forms";
import {FORM_ERROR_MESSAGES, PAYVY_URL} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {
  getConnectionInfo,
  renameDropboxExportFolder,
  renameDropboxImportFolder,
  setExportFolder,
  setImportFolder,
  verifyOAuthConnection
} from '../../../slices/newDropbox';
import {disableIntegration, enableIntegration, getIntegration} from '../../../slices/newIntegration';
import {build_error_message, build_url, handleErrors, parsify, payvyToast} from '../../../utils/Utility';

export const DropboxSettings = ({
  integration: {
    id,
    name,
    logo,
    description,
    enabled
  }
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loading: {
      item: loading,
      posting: processing
    },
    importFolder,
    exportFolder,
    lastSync
  } = useSelector((state) => state.integrationDropbox);
  const {
    state,
    code
  } = parsify(location.search);
  useEffect(() => {
    dispatch(getConnectionInfo({forceUpdate: true}));
  }, [dispatch]);
  useEffect(() => {
    if(state && code && !loading && !processing) {
      dispatch(verifyOAuthConnection({
        body: {
          code,
          state,
        },
        successCallback: () => {
          dispatch(getIntegration({id}));
          dispatch(getConnectionInfo({}));
        }
      }));
      navigate(build_url(PAYVY_URL.SETTINGS.INTEGRATION_DETAILS, {id}));
    }
  }, [dispatch, navigate, state, code, id, loading, processing]);
  const connectDropbox = () => {
    dispatch(enableIntegration({
      id,
      successCallback: (data) => {
        if(data['method'] === 'redirect') {
          window.location = data.url;
        }
      }
    }));
  };
  const disconnectDropbox = () => {
    dispatch(disableIntegration({
      id,
      successCallback: () => dispatch(getIntegration({id}))
    }));
  };

  return <div className={'flex flex-col'}>
    <div className={'flex flex-row py-2'}>
      <h1 className={'flex h-24 w-24'}>{logo ?
        <img src={logo} alt={name} className={'w-24 max-h-24 rounded self-center'}/> : name}</h1>
      <CleanedHTML html={description} className={'justify-center flex flex-col mx-5'}/>
      <div className={'flex grow justify-end self-center'}>
        {enabled ?
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Disconnect'}
              onClick={disconnectDropbox}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full'}
            />
            {lastSync ? <small>Last sync: <Moment date={lastSync} format={'LLL'}/></small> : null}
          </div> :
          <div className={'flex flex-col items-end'}>
            <PayvyIconButton
              buttonText={'Connect'}
              onClick={connectDropbox}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full'}
            />
          </div>}
      </div>
    </div>
    {enabled ? <div className={'grid grid-cols-1 md:grid-cols-2 gap-5 w-full'}>
      <div className={'flex flex-col bg-neutral-0 shadow-lg px-4 py-2 rounded-xl'}>
        <h1 className={'text-xl font-bold text-center'}>Import folder</h1>
        {importFolder ? <div className={'flex flex-row text-2xl self-center items-center h-full py-6'}>
          <div className={'mr-2 self-center'}><FaFolder/></div>
          <div className={'w-full self-center'}>{importFolder}</div>
        </div> : <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            name: '' || importFolder,
          }}
          validationSchema={Yup.object({
            name: Yup.string()
                     .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255}))
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          })}
          onSubmit={(values, helpers) => {
            dispatch(renameDropboxImportFolder({
              body: {
                payvy_import_folder: values.name,
              },
              successCallback: () => {
                setImportFolder(values.name);
              },
              errorCallback: (error) => {
                const data = error.data || {};
                if('message' in error) {
                  payvyToast(error.message, {appearance: 'error'});
                }
                handleErrors(data, helpers);
              }
            }));
          }}
        >
          {(props) => <Form>
            <PayvyLabeledInput
              label={'Import Folder'}
              name={'name'}
              as={PayvyInput}
              helpText={'Customize the folder name for files you want to import into your inbox.'}
            />
            <PayvyIconButton
              buttonText={'Set Import Folder'}
              onClick={props.handleSubmit}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full mt-5'}
            />
          </Form>}
        </Formik>}
      </div>
      <div className={'flex flex-col bg-neutral-0 shadow-lg px-4 py-2 rounded-xl'}>
        <h1 className={'text-xl font-bold text-center'}>Export folder</h1>
        {exportFolder ? <div className={'flex flex-row text-2xl self-center items-center h-full py-6'}>
          <div className={'mr-2 self-center'}><FaFolder/></div>
          <div className={'w-full self-center'}>{exportFolder}</div>
        </div> : <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            name: '' || exportFolder,
          }}
          validationSchema={Yup.object({
            name: Yup.string()
                     .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255}))
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
          })}
          onSubmit={(values, helpers) => {
            dispatch(renameDropboxExportFolder({
              body: {
                payvy_export_folder: values.name,
              },
              successCallback: () => {
                setExportFolder(values.name);
              },
              errorCallback: (error) => {
                const data = error.data || {};
                if('message' in error) {
                  payvyToast(error.message, {appearance: 'error'});
                }
                handleErrors(data, helpers);
              }
            }));
          }}
        >
          {(props) => <Form>
            <PayvyLabeledInput
              label={'Export Folder'}
              name={'name'}
              as={PayvyInput}
              helpText={'Customize the folder name for bill backup files you want to save after they’ve been paid.'}
            />
            <PayvyIconButton
              buttonText={'Set Export Folder'}
              onClick={props.handleSubmit}
              loading={loading || processing}
              fullWidth={true}
              wrapperClassName={'w-full mt-5'}
            />
          </Form>}
        </Formik>}
      </div>
    </div> : null}
  </div>;
};
