import React, {useState} from 'react';
import {MdAddCircle} from "react-icons/md";
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {PayvyPageHeader, PayvySubmenu} from "../../comps/elements";
import {PayvyIconButton, PayvySearchBar} from "../../comps/forms";
import {PAYVY_URL} from '../../constants';
import {receivablesSelector} from '../../slices/receivables';
import {restoreDictionaryFromHash} from "../../utils/Checksum";
import {DropdownFilter} from "../BillListPage/components/DropdownFilter";
import TableInvoiceList from './components/TableInvoiceList';

const InvoiceListPage = () => {
  const {
    loading,
    invoiceListQueryChecksum
  } = useSelector(receivablesSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const {
    category: prevCategory = 'draft',
    q: prevQuery = '',
    amount: prevAmountFilter = '',
    contact: prevContactFilterID = 0,
  } = restoreDictionaryFromHash(invoiceListQueryChecksum)

  const [activeItem, setActiveItem] = useState(params.get('cat') || prevCategory);
  const [q, setQueryTerm] = useState(params.get('q') || prevQuery);
  const [amountFilter, setAmountFilter] = React.useState(params.get('amount') || prevAmountFilter);
  const [contactFilterID, setContactFilterID] = React.useState(params.get('cid') || prevContactFilterID);
  return (<>
      <PayvyPageHeader parents={[{name: 'Receivables'}]}>Invoices</PayvyPageHeader>
      <PayvySubmenu
        activeItem={activeItem}
        setActiveItem={(value) => {
          if(!loading) setActiveItem(value);
        }}
        options={[
          {
            name: 'Draft',
            value: 'draft',
          }, {
            name: 'Outstanding',
            value: 'outstanding',
          }, {
            name: 'Authorized',
            value: 'authorized',
          }, {
            name: 'Past Due',
            value: 'past-due',
          }, {
            name: 'Paid',
            value: 'paid',
          }, {
            name: 'All',
            value: 'all',
          },
        ]}
      />
      <div className={'bg-neutral-50 p-4 w-full'}>
        <div className={'flex flex-row gap-x-2'}>
          <div className={'inline-flex grow'}>
            <PayvySearchBar
              loading={loading}
              value={q}
              onSearch={setQueryTerm}
              placeholder={'Search table by contact or bill name'}
              autoComplete={'bill-search'}
            />
          </div>
          <div className={'flex flex-row'}>
            <DropdownFilter
              loading={loading}
              activeFilters={!!amountFilter || !!contactFilterID}
              onFilterChange={({
                amount,
                contact,
              }) => {
                setAmountFilter(amount);
                setContactFilterID(contact);
              }}/>
            <PayvyIconButton
              Icon={MdAddCircle}
              buttonText={'Create Invoice'}
              onClick={() => navigate(PAYVY_URL.INVOICE.CREATE)}
            />
          </div>
        </div>
        <TableInvoiceList
          category={activeItem}
          query={q}
          amountFilter={amountFilter}
          contactFilterID={contactFilterID}
        />
      </div>
    </>
  );
};

export default InvoiceListPage;
