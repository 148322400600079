export const flavourTexts = [
  'Eating invoices...',
  'Removing all details...',
  'Trying to read your handwriting...',
  'Looking up interesting facts about life.',
  'I ate a clock yesterday, it was very time-consuming.',
  'Velcro—what a rip-off!',
  'Wondering if these numbers are correct.',
  'Grabbing some coffee.',
  'Biting the invoices corners off.',
  'Drawing dragons on the invoices.',
  'Doodling creatures on the invoice.',
  'Worried about numbers...',
  'Hey! I know this contact!',
  'OCR engines are hard at work...',
  'Uploading and scanning your invoices meticulously...',
  'Digitizing your documents for precision...',
  'Parsing your data with care and accuracy...',
  'Transforming paper into digital with diligence...',
  'Converting your invoices into actionable data...',
  'Scanning your documents for financial clarity...',
  'OCR engines are processing your documents diligently...',
  'Ensuring the accuracy of your uploaded invoices...',
  'Document analysis underway for flawless processing...',
  'Your invoices are receiving the digital touch...',
  'Uploading your invoices securely and swiftly...',
  'Data extraction in progress for financial insight...',
  'Transforming paper trails into digital pathways...',
  'Preparing your invoices for seamless integration...',
  'Deciphering cryptic codes...',
  'Making sense of numbers...',
  'Turning chaos into order...',
  'Taming the paper beast...',
  'Brewing financial insights...',
  'Extracting valuable data...',
  'Connecting the dots...',
  'Unraveling the mysteries...',
  'Putting the puzzle together...',
  'Illuminating the path...',
  'Simplifying the complex...',
  'Bridging the gap...',
  'Empowering your decisions...',
  'Fueling your growth...',
  'Optimizing your workflow...',
  'Streamlining your processes...',
  'Boosting your productivity...',
  'Enhancing your efficiency...',
  'Saving you time and money...',
  'Delivering peace of mind...',
  'Crunching the numbers...',
  'Calculating the future...',
  'Forecasting your success...',
  'Analyzing your performance...',
  'Visualizing your progress...',
  'Tracking your achievements...',
  'Celebrating your milestones...',
  'Inspiring your ambition...',
  'Guiding your journey...',
  'Supporting your vision...',
  'Dreaming big with you...',
  'Building a brighter future...',
  'Creating a better world...',
  'Making a positive impact...',
  'Leaving a lasting legacy...',
  'Changing the game...',
  'Breaking the mold...',
  'Pushing the boundaries...',
  'Redefining the possible...',
  'Achieving the extraordinary...',
  'Surpassing expectations...',
  'Exceeding your goals...',
  'Reaching new heights...',
  'Unlocking your potential...',
  'Empowering your dreams...',
  'Fulfilling your aspirations...',
  'Realizing your ambitions...',
  'Manifesting your desires...',
  'Attracting abundance...',
  'Creating prosperity...',
  'Generating wealth...',
  'Sharing your blessings...',
  'Spreading joy and happiness...',
  'Making a difference...',
  'Inspiring others...',
  'Leading by example...',
  'Setting a new standard...',
  'Raising the bar...',
  'Going above and beyond...',
  'Doing the right thing...',
  'Acting with integrity...',
  'Living your values...',
  'Staying true to yourself...',
  'Being the best you can be...',
  'Embracing your uniqueness...',
  'Celebrating your individuality...',
  'Expressing your creativity...',
  'Sharing your gifts with the world...',
  'Shining your light brightly...',
  'Making the world a more beautiful place...',
  'Filling the world with love and light...',
  'Creating a ripple effect of positivity...',
  'Inspiring a chain reaction of kindness...',
  'Making a lasting impact on the world...',
  'Leaving a legacy of love and compassion...',
  'Being a force for good in the world...',
  'Making the world a better place for all...'
];
