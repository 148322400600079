import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {CellCurrency, CellDetailLink, CellFormattedDate} from '../../../comps/elements/PayvyTable';
import {PAYVY_URL} from '../../../constants';
import {getInvoicesList, receivablesSelector} from '../../../slices/receivables';
import {restoreDictionaryFromHash} from "../../../utils/Checksum";
import {CellActions} from "./CellActions";

const TableInvoiceList = ({
  category,
  query,
  amountFilter,
  contactFilterID,
}) => {
  const dispatch = useDispatch();
  const {
    loading,
    categorizedInvoiceList,
    invoiceListQueryChecksum,
    count,
  } = useSelector(receivablesSelector);
  const {
    page: prevPage = 0,
    page_size: prevPageSize = 20,
    order_by: prevCustomSorting = '',
  } = restoreDictionaryFromHash(invoiceListQueryChecksum)
  const [pageCount, setPageCount] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(prevPageSize);
  const [currentPage, setCurrentPage] = React.useState(prevPage);
  const [customSorting, setCustomSorting] = React.useState(prevCustomSorting);
  const [refreshInfo, _setRefreshInfo] = useState(new Date());
  const setRefreshInfo = () => {
    _setRefreshInfo(new Date());
  };
  useEffect(() => {
    dispatch(getInvoicesList({
      page: currentPage + 1,
      page_size: pageSize,
      q: query,
      order_by: customSorting,
      category: category,
      contact: contactFilterID || '',
      amount: amountFilter || '',
      checksum: invoiceListQueryChecksum,
    }));
  }, [
    category,
    query,
    amountFilter,
    contactFilterID,
    currentPage,
    pageSize,
    customSorting,
    dispatch,
    refreshInfo,
    invoiceListQueryChecksum
  ]);

  const columns = React.useMemo(() => [
    {
      Header: 'Invoice No',
      accessor: 'invoice_number',
      detailUrl: PAYVY_URL.INVOICE.DETAILS,
      Cell: CellDetailLink,
    }, {
      Header: 'Customer',
      accessor: 'contact.name',
    }, {
      Header: 'Due',
      accessor: 'due_date',
      Cell: CellFormattedDate,
      sortDescFirst: true,
    }, {
      Header: 'Amount',
      accessor: 'amount',
      Cell: CellCurrency,
      sortDescFirst: true,
      inCents: true,
    }, {
      Header: 'Created',
      accessor: 'created',
      Cell: CellFormattedDate,
    }, {
      Header: 'Actions',
      Cell: CellActions,
      RefreshInfo: setRefreshInfo,
    },
  ], []);
  const fetchData = React.useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setCurrentPage(pageIndex);
    setPageSize(pageSize);
    setPageCount(Math.ceil(count / pageSize));
  }, [count]);
  const handleSort = React.useCallback(sortBy => {
    setCustomSorting(sortBy.length > 0 ? sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id : '');
  }, []);
  return <div className={'mt-2'}>
    <PayvyTable
      columns={columns}
      data={categorizedInvoiceList[category]?.results || []}
      fetchData={fetchData}
      loading={loading}
      onSort={handleSort}
      count={categorizedInvoiceList[category]?.count || 0}
      pageCount={pageCount}
      defaultPageSize={pageSize}
      currentPage={currentPage}
      pageSize={pageSize}
      canScroll={count !== 0}
      sizeOptions={[20, 50, 100]}
      role={'invoice-list'}
    />
  </div>;
};

export default TableInvoiceList;
