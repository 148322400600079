import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {PayvyLabeledInput, PayvySelectComponent} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";
import {
  getPaymentServiceInformation,
  paymentSystemSelector,
  unlinkContactFromRecipient
} from "../../../slices/paymentSystem";
import {BasePaymentForm} from "./BasePaymentForm";
import {ContactAssignmentForm} from "./ContactAssignmentForm";

export const AirwallexPaymentForm = ({
  contactId,
  formProps,
  processing,
  loading,
  bankAccounts,
  amount,
  emailOptions,
  handleCreate,
  checkboxFieldsSet,
  addAndSelectVendor,
  addAndSelectUser,
}) => {
  const dispatch = useDispatch();
  const {services} = useSelector(paymentSystemSelector);
  const {
    internal_name: internalName,
    active_configuration: {enabled = false},
    recipients = []
  } = services.find(service => service.name === 'Airwallex');
  if(enabled === false) {
    return <div>
      Airwallex is not enabled. You can enable it from the <Link to={PAYVY_URL.SETTINGS.BANKING}
                                                                 className={'text-red-300'}>Banking
      Settings</Link> page.
    </div>
  }
  const contact = recipients.find((recipient) => recipient.contact?.id === contactId);
  if(contact === undefined) {
    return <ContactAssignmentForm
      contactId={contactId}
      services={services}
      internalName={internalName}
    />
  }
  const filteredBankAccounts = bankAccounts.filter(account => account.type === 'Airwallex');
  if(filteredBankAccounts.length === 0) {
    return <div>
      No Airwallex accounts found.
    </div>
  }
  const paymentMethodOptions = contact.recipient_details?.payment_methods || [];
  return <>
    <div className={'text-neutral-700 mt-2'}>
      This bill's contact is associated with <b>{contact?.name}</b> on Airwallex.
      <span
        className={'px-1 text-xs text-red-300 cursor-pointer'}
        onClick={() => dispatch(unlinkContactFromRecipient({
          contact: contactId,
          service_name: internalName,
          success: () => {dispatch(getPaymentServiceInformation({}))}
        }))}>
        Change
      </span>
    </div>
    <BasePaymentForm
      formProps={formProps}
      extraPaymentFields={<>
        <div className={'flex-inline w-full'}>
          <PayvyLabeledInput
            alwaysLabel={true}
            label={'Payment method'}
            component={PayvySelectComponent}
            placeholder={'Payment method'}
            name={'paymentMethod'}
            options={paymentMethodOptions.map(method => ({
              value: method,
              label: method
            }))}
          />
        </div>
        {formProps.values.paymentMethod === 'SWIFT' && <div className={'flex-inline w-full'}>
          <PayvyLabeledInput
            alwaysLabel={true}
            label={'Swift charge option'}
            component={PayvySelectComponent}
            placeholder={'Swift charge option'}
            name={'swiftChargeOption'}
            options={[
              {
                key: 'payer',
                value: 'PAYER',
                label: 'Payer'
              },
              {
                key: 'shared',
                value: 'SHARED',
                label: 'Shared'
              }
            ]}
          />
        </div>}
      </>}
      processing={processing}
      loading={loading}
      bankAccounts={filteredBankAccounts}
      amount={amount}
      emailOptions={emailOptions}
      handleCreate={handleCreate}
      checkboxFieldsSet={checkboxFieldsSet}
      addAndSelectVendor={addAndSelectVendor}
      addAndSelectUser={addAndSelectUser}
    />
  </>
}
