import React, {useState} from 'react';
import {BsCheck2Square} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {ModalPayvyBase} from '../';
import {changeBillStatus, getBill, getUserBills} from "../../../slices/newBill";
import {payvyToast} from "../../../utils/Utility";
import {PayvyIconButton} from '../../forms';

export const ModalBillApproval = ({
  bill,
  detailStyle = false,
}) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading: {posting: processing},
    status,
    toDateStr,
    fromDateStr,
    filterAmountStr,
    filterContact,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const approveBill = () => {
    dispatch(changeBillStatus({
      id: bill.id,
      body: {
        status: 'Awaiting payment'
      },
      successCallback: () => {
        payvyToast('Bill approved successfully.', {appearance: 'success'})
        setModalOpen(false);
        dispatch(getUserBills({
          amount: filterAmountStr,
          contact: filterContact,
          from: fromDateStr,
          to: toDateStr,
          order_by: orderBy,
          page: currentPage,
          page_size: pageSize,
          status: status,
          q: searchQuery,
        }));
        dispatch(getBill({id: bill.id}));
      },
      errorCallback: (error) => {
        if('message' in error) payvyToast(error.message || 'Bill approval has failed.', {appearance: 'error'});
      }
    }))
  };
  return <>
    <PayvyIconButton
      Icon={BsCheck2Square}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Approve Bill'}
      tooltipPlace={'bottom-start'}
      mainColor={detailStyle ? 'none' : undefined}
      borderColor={detailStyle ? 'none' : undefined}
      iconColor={detailStyle ? 'neutral-700' : undefined}
      iconSize={detailStyle ? 22 : undefined}
      hoverTextColor={detailStyle ? 'slate-300' : undefined}
    />
    <ModalPayvyBase
      title={`Approve "${bill.name}" bill`}
      isOpen={modalOpen}
      confirmButtonText={'Yes, approve bill'}
      confirmButtonAction={approveBill}
      confirmButtonLoading={processing}
      dismissButtonText={'No, don\'t approve'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing}
    >
      <p>Are you sure you want to approve <b>"{bill.name}"</b> bill?</p>
    </ModalPayvyBase>
  </>;
};
