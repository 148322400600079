import {createSlice} from "@reduxjs/toolkit";
import {PAYVY_API} from "../constants";
import Debugger from "../utils/Debug";
import {mergeItemIntoList, mergeItemsIntoList, orderItems} from "../utils/Utility";
import {createApiThunk} from "./api";
import {DB_CONTACT_STORAGE, resetCacheForStore} from "./db";

export const contactSliceInitialState = {
  loading: {
    list: false,
    item: false,
    posting: false,
    deleting: false,
    paymentMethodProcessing: false,
    invitation: false,
    ledger: false,
  },
  items: {
    vendor: [],
    customer: [],
    all: []
  },
  displayItems: [],
  searchList: [],
  paymentMethods: [],
  numberOfItems: {},
  ledger: {},

  invitationStatus: '',
  invitationMatchedCompanyId: '',
  invitationVendorDetails: {},
  invitationSenderCompany: '',

  contactType: 'vendor',
  currentPage: 1,
  pageSize: 10,
  orderBy: '',
  searchQuery: '',

  contacts: [],
  contactList: {},
  contactListQueryChecksum: '',
};
const contactSlice = createSlice({
  name: 'contact',
  initialState: contactSliceInitialState,
  reducers: {
    resetContactStateToInitial: (state) => {
      state.loading = contactSliceInitialState.loading;
      state.items = contactSliceInitialState.items;
      state.displayItems = contactSliceInitialState.displayItems;
      state.searchList = contactSliceInitialState.searchList;
      state.numberOfItems = contactSliceInitialState.numberOfItems;
      state.contactType = contactSliceInitialState.contactType;
      state.currentPage = contactSliceInitialState.currentPage;
      state.pageSize = contactSliceInitialState.pageSize;
      state.orderBy = contactSliceInitialState.orderBy;
      state.searchQuery = contactSliceInitialState.searchQuery;
      state.contacts = contactSliceInitialState.contacts;
      state.paymentMethods = contactSliceInitialState.paymentMethods;

    },
    setContactCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setContactPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setContactType: (state, action) => {
      state.contactType = action.payload;
      state.currentPage = 1;
      if(!state.items[state.contactType]) state.items[state.type] = [];
      state.displayItems = state.items[state.contactType].slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);
    },
    setContactOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setContactSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getContacts.pending, (state) => {
      state.loading.list = true;
    })
    .addCase(getContacts.fulfilled, (state, action) => {
      const newItems = action.payload.results || [];
      const {
        q = contactSliceInitialState.searchQuery,
        contact_type = contactSliceInitialState.contactType,
        order_by = contactSliceInitialState.orderBy,
      } = action?.meta?.arg || {}
      state.searchQuery = q;
      state.contactType = contact_type;
      if(!state.items[contact_type]) state.items[contact_type] = [];
      state.items[contact_type] = mergeItemsIntoList(state.items[contact_type], newItems, true)
      state.items['all'] = mergeItemsIntoList(state.items['all'], newItems, true)
      let filteredItems = state.items[contact_type];

      // Filter by searchQuery
      if(q) {
        const newQ = typeof q === 'string' ? q.toLowerCase() : q;
        Debugger.info(`📡 API: CONTACT`, `Filtering items by search query: ${q}`);
        filteredItems = filteredItems.filter(item =>
          item.id === newQ ||
          (item.name && item.name.toLowerCase()
                            .includes(newQ)) ||
          (item.first_name && item.first_name.toLowerCase()
                                  .includes(newQ)) ||
          (item.last_name && item.last_name.toLowerCase()
                                 .includes(newQ))
        );
      }

      // Order the filtered items based on the django syntax
      filteredItems = orderItems(filteredItems, order_by)

      // Paginate the filtered items
      state.displayItems = filteredItems.slice((state.currentPage - 1) * state.pageSize, state.currentPage * state.pageSize);

      state.searchList = filteredItems.map(item => ({
        key: item.id,
        value: item.id,
        label: item.name,
        title: item.name,
        contact_id: item.id,
        contact_type: item.contact_type,
        next_invoice_number: item.complete_next_invoice_sequence_number,
        finix_id: item.finix_id,
      }));

      // Update the number of items for the current status
      state.numberOfItems[contact_type] = action.payload.count;
      state.loading.list = false;
    })
    .addCase(getContacts.rejected, (state) => {
      state.loading.list = false;
    });

    builder
    .addCase(removeContact.pending, (state) => {
      state.loading.deleting = true;
    })
    .addCase(removeContact.fulfilled, (state, action) => {
      state.loading.deleting = false;
      const contactId = action?.meta?.arg?.id;

      state.items['vendor'] = state.items['vendor'].filter(item => item.id !== contactId);
      state.items['customer'] = state.items['customer'].filter(item => item.id !== contactId);
      state.items['all'] = state.items['all'].filter(item => item.id !== contactId);
      state.displayItems = state.displayItems.filter(item => item.id !== contactId);
    })
    .addCase(removeContact.rejected, (state) => {
      state.loading.deleting = false;
    });

    builder
    .addCase(getContactDetails.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getContactDetails.fulfilled, (state, action) => {
      state.loading.item = false;
      state.items = Object.keys(state.items)
                          .reduce(
                            (acc, type) => {
                              acc[type] = mergeItemIntoList(state.items[type], action.payload);
                              return acc;
                            },
                            {all: mergeItemIntoList(state.items['all'] || [], action.payload, true)}
                          );
      state.contact = action.payload;
    })
    .addCase(getContactDetails.rejected, (state) => {
      state.loading.item = false;
    });

    builder
    .addCase(getContactOverview.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getContactOverview.fulfilled, (state, action) => {
      state.loading.item = false;
      const contactId = action?.meta?.arg?.id;
      const overview = action.payload
      state.items['vendor'] = state.items['vendor'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        overview
      } : item);
      state.items['customer'] = state.items['customer'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        overview
      } : item);
      state.items['all'] = state.items['all'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        overview
      } : item);
    })
    .addCase(getContactOverview.rejected, (state) => {
      state.loading.item = false;
    });

    builder
    .addCase(getContactPayments.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getContactPayments.fulfilled, (state, action) => {
      state.loading.item = false;
      const contactId = action?.meta?.arg?.id;
      const payments = action.payload
      state.items['vendor'] = state.items['vendor'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        payments
      } : item);
      state.items['customer'] = state.items['customer'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        payments
      } : item);
      state.items['all'] = state.items['all'].map(item => item.id === parseInt(contactId) ? {
        ...item,
        payments
      } : item);
    })
    .addCase(getContactPayments.rejected, (state) => {
      state.loading.item = false;
    });

    builder
    .addCase(getContactPaymentMethods.pending, (state) => {
      state.loading.paymentMethodProcessing = true;
    })
    .addCase(getContactPaymentMethods.fulfilled, (state, action) => {
      state.loading.paymentMethodProcessing = false;
      state.paymentMethods = action.payload;
    })
    .addCase(getContactPaymentMethods.rejected, (state) => {
      state.loading.paymentMethodProcessing = false;
    });

    builder
    .addCase(getContactLedgerHistory.pending, (state) => {
      state.loading.ledger = true;
    })
    .addCase(getContactLedgerHistory.fulfilled, (state, action) => {
      state.loading.ledger = false;
      const contactId = action?.meta?.arg?.id;
      if(contactId) {
        if(!state.ledger[contactId]) state.ledger[contactId] = [];
        state.ledger[contactId] = action.payload;
      }
    })
    .addCase(getContactLedgerHistory.rejected, (state) => {
      state.loading.ledger = false;
    })

    builder
    .addCase(createContact.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createContact.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(createContact.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(updateContact.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(updateContact.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(updateContact.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(sendInvitation.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(sendInvitation.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(sendInvitation.rejected, (state) => {
      state.loading.posting = false;
    });

    builder
    .addCase(checkInvitationStatus.pending, (state) => {
      state.loading.invitation = true;
    })
    .addCase(checkInvitationStatus.fulfilled, (state, action) => {
      state.loading.invitation = false;
      state.invitationMatchedCompanyId = action.payload.matched_company_id;
      state.invitationSenderCompany = action.payload.inviter_company;
      state.invitationVendorDetails = action.payload.vendor_details;
      state.invitationStatus = action.payload.status;
    })
    .addCase(checkInvitationStatus.rejected, (state) => {
      state.loading.invitation = false;
    });

    builder
    .addCase(acceptInvitation.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(acceptInvitation.fulfilled, (state) => {
      state.loading.posting = false;
    })
    .addCase(acceptInvitation.rejected, (state) => {
      state.loading.posting = false;
    });
  }
})

export const {
  resetContactStateToInitial,
  setContactCurrentPage,
  setContactPageSize,
  setContactType,
  setContactOrderBy,
  setContactSearchQuery,
} = contactSlice.actions;
export default contactSlice.reducer;

// Specific Contact Thunks
export const getContacts = createApiThunk('contact', 'list', PAYVY_API.V1.CONTACT.CONTACT_LIST, DB_CONTACT_STORAGE, [], 'GET');
export const removeContact = createApiThunk('contact', 'remove', PAYVY_API.V1.CONTACT.CONTACT_REMOVE, DB_CONTACT_STORAGE, [DB_CONTACT_STORAGE], 'DELETE');
export const getContactDetails = createApiThunk('contact', 'details', PAYVY_API.V1.CONTACT.CONTACT_DETAILS, DB_CONTACT_STORAGE, [], 'GET');
export const getContactOverview = createApiThunk('contact', 'overview', PAYVY_API.V1.CONTACT.CONTACT_OVERVIEW, DB_CONTACT_STORAGE, [], 'GET');
export const getContactPayments = createApiThunk('contact', 'payment', PAYVY_API.V1.CONTACT.PAYMENTS_FOR_CONTACT, DB_CONTACT_STORAGE, [], 'GET');
export const getContactPaymentMethods = createApiThunk('contact', 'paymentMethods', PAYVY_API.V1.CONTACT.LIST_PAYMENT_METHODS, DB_CONTACT_STORAGE, [], 'GET');
export const getContactLedgerHistory = createApiThunk('contact', 'ledgerHistory', PAYVY_API.V1.CONTACT.CONTACT_LEDGER_HISTORY, DB_CONTACT_STORAGE, [], 'GET');
export const createContact = createApiThunk('contact', 'create', PAYVY_API.V1.CONTACT.CONTACT_CREATE, DB_CONTACT_STORAGE, [DB_CONTACT_STORAGE], 'POST');
export const updateContact = createApiThunk('contact', 'update', PAYVY_API.V1.CONTACT.CONTACT_UPDATE, DB_CONTACT_STORAGE, [DB_CONTACT_STORAGE], 'PATCH');
export const sendInvitation = createApiThunk('contact', 'invitation', PAYVY_API.V1.CONTACT.CONTACT_SEND_INVITATION, DB_CONTACT_STORAGE, [DB_CONTACT_STORAGE], 'POST');
export const checkInvitationStatus = createApiThunk('contact', 'checkInvitationStatus', PAYVY_API.V1.CONTACT.CONTACT_CHECK_INVITATION_STATUS, DB_CONTACT_STORAGE, [], 'GET');
export const acceptInvitation = createApiThunk('contact', 'acceptInvitation', PAYVY_API.V1.CONTACT.CONTACT_ACCEPT_INVITATION, DB_CONTACT_STORAGE, [DB_CONTACT_STORAGE], 'POST', undefined, true);

export const resetContactToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetContactStateToInitial());
    await resetCacheForStore(DB_CONTACT_STORAGE);
  }
}
