import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyTable} from '../../../comps/elements';
import {CellCurrency, CellFormattedDate} from '../../../comps/elements/PayvyTable';
import {companiesSelector} from '../../../slices/companies';
import {getUserInvoices, userSelector} from '../../../slices/user';
import {CellInvoiceNumber} from "./CellInvoiceNumber";
import {CellInvoicePeriod} from "./CellInvoicePeriod";

export const BillingInvoices = () => {
  const dispatch = useDispatch();
  const {
    loadingInvoices: loading,
    invoices,
    invoicesCount: count,
  } = useSelector(userSelector);
  const {company: {id: companyId}} = useSelector(companiesSelector);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    dispatch(getUserInvoices({
      page: currentPage + 1,
      page_size: pageSize
    }));
  }, [dispatch, currentPage, pageSize, companyId]);
  const columns = React.useMemo(() => [
    {
      Header: 'Period',
      Cell: CellInvoicePeriod,
      disableSortBy: true,
    },
    {
      Header: 'Date',
      accessor: 'created',
      Cell: CellFormattedDate,
      disableSortBy: true,
    },
    {
      Header: 'Invoice Number',
      accessor: 'invoice_number',
      Cell: CellInvoiceNumber,
      disableSortBy: true,
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: CellCurrency,
      disableSortBy: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
    },
  ], []);
  const fetchData = useCallback(({
    pageSize,
    pageIndex,
  }) => {
    setCurrentPage(pageIndex);
    setPageSize(pageSize);
    setPageCount(Math.ceil(count / pageSize));
  }, [count]);
  return <>
    <div className={'text-sm text-payvyBlue-950 font-bold mt-4 -mb-9'}>
      <span>Invoices</span>
    </div>
    <PayvyTable
      columns={columns}
      data={invoices}
      fetchData={fetchData}
      loading={loading}
      count={count}
      pageCount={pageCount}
      defaultPageSize={pageSize}
      pageSize={pageSize}
    />
  </>;
};
