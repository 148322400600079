import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PAYVY_URL} from '../../../constants';
import {build_url} from '../../../utils/Utility';

export function CellLogoName({row: {original}}) {
  const navigate = useNavigate();
  const {
    logo,
    name,
    id,
  } = original;
  return <div className={'flex h-12 w-12 rounded-full centered cursor-pointer overflow-hidden'}
              onClick={() => navigate(build_url(PAYVY_URL.SETTINGS.INTEGRATION_DETAILS, {id}))}
              style={{backgroundColor: '#f5f5f5'}}>
    {logo ? <img src={logo} alt={name} className={'max-h-12 max-w-12 object-contain object-center'}/> : name}
  </div>;
}
