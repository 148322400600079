import React from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {LedgerIcons} from "../../../comps/elements/LedgerIcons/LedgerIcons";
import {build_url} from '../../../utils/Utility';

export function CellDetailNameAndLedgerInformation({
  value,
  column: {detailUrl},
  row: {original},
}) {
  const navigate = useNavigate();
  const {id} = original || {};
  const {
    ledgers
  } = useSelector((state) => state.integration)
  const result = ledgers
  .filter(ledger => ledger.enabled)
  .flatMap(ledger =>
    ledger?.contacts
          ?.filter(contact => contact.payvy_id === id)
          ?.map(contact => ({
            ledger: ledger.codename,
            ledger_id: contact.ledger_id
          }))
  );
  const url = build_url(detailUrl, {id})
  return <span
    onClick={() => {
      navigate(url)
    }}
    className={'cursor-pointer hover:text-red-300 flex flex-row'}>
    <div>
      {value}
    </div>
    <LedgerIcons ledgerInfo={result}/>
  </span>;
}
