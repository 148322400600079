import React from 'react';
import CurrencyFormat from 'react-currency-format';
import {MdAddCircle} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {displayAmount} from '../../../utils/BillHelpers';
import {PayvyIconButton} from '../../forms';

export const OpenBillsOfContact = ({
  contactId,
  onClick,
  loading,
  readOnly = false,
}) => {
  onClick = onClick || false;
  const {displayItems} = useSelector((state) => state.bill)
  const bills = displayItems.filter((item) => item?.contact?.id === contactId && [
    'Draft',
    'Awaiting approval'
  ].includes(item.status));
  return <div className={'w-full p-1'}>
    <h2 className={'mb-0.5 ml-0.5 font-light text-sm text-neutral-700'}>Open Bills</h2>
    <table className={'w-full table-auto'}>
      <thead>
      <tr className={'bg-slate-700 text-neutral-0'}>
        <th>Invoice No.</th>
        {!onClick && <th>Date</th>}
        <th>Amount</th>
        {onClick && !readOnly && <th>Add to Bill</th>}
      </tr>
      </thead>
      <tbody>
      {loading ? <tr>
        <td colSpan={3}>Loading...</td>
      </tr> : null}
      {!loading && bills.map((item) => (
          <tr key={item.id} className={'text-center'}>
            <td>{item.name}</td>
            {!onClick && <td>{new Date(item.created).toLocaleDateString('en-US')}</td>}
            <td><CurrencyFormat value={displayAmount(item.amount)} displayType={'text'} thousandSeparator={true}
                                prefix={'$'}/></td>
            {onClick && !readOnly && <td>
              <PayvyIconButton
                tooltipContent={'Save and add to bill'}
                Icon={MdAddCircle}
                onClick={() => onClick(item.id)}
                mainColor={'none'}
                borderColor={'none'}
                iconColor={'neutral-500'}
                hoverTextColor={'neutral-700'}
              />
            </td>}
          </tr>
        ),
      )}
      {!loading && bills.length === 0 && <tr>
        <td colSpan={3}>No open bills.</td>
      </tr>}
      </tbody>
    </table>
  </div>;
};
