import {noop} from "lodash";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {parseValueFromIntOrObj} from "../../../utils/Utility";

export const SelectExpenseAccount = ({
  loading,
  options,
  defaultValue,
  onChange = noop
}) => {
  const [option, setOption] = useState(parseValueFromIntOrObj(defaultValue));
  useEffect(() => setOption(parseValueFromIntOrObj(defaultValue)), [defaultValue]);
  return <Select
    isLoading={loading}
    placeholder={'Select an account'}
    isClearable={true}
    options={options}
    value={options.find(item => item.value === `${option}`) || []}
    onChange={(option) => {
      setOption(option?.value);
      onChange(option);
    }}
    className={'w-full'}
  />
}
