import {noop} from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import {
  getUserBills,
  setBillCurrentPage,
  setBillFromDateStr,
  setBillOrderBy,
  setBillPageSize,
  setBillSearchQuery,
  setBillStatus,
  setBillToDateStr,
  setFilterAmountStr,
  setFilterContact
} from '../../../slices/newBill';

export const SelectBill = ({
  onSelect,
  defaultValue,
  setValue,
  contactId = 0,
  excludeId = 0,
  billStatus = '',
}) => {
  setValue = setValue || noop;
  onSelect = onSelect || noop;
  const dispatch = useDispatch();
  const {
    displayItems,
    loading: {list: loading},
    status,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    searchQuery
  } = useSelector((state) => state.bill);
  const [valueObject, setValueObject] = React.useState(null);
  useEffect(() => {
    if(!!defaultValue && defaultValue.length > 0) setValueObject(displayItems.find(option => option.value === defaultValue[0]) || null);
  }, [defaultValue, displayItems]);
  useEffect(() => {
    dispatch(setBillCurrentPage(1))
    dispatch(setBillPageSize(10))
    dispatch(setBillStatus(billStatus))
    dispatch(setBillOrderBy('-created'))
    dispatch(setFilterAmountStr(''))
    dispatch(setFilterContact(contactId))
    dispatch(setBillSearchQuery(''))
    dispatch(setBillFromDateStr(''))
    dispatch(setBillToDateStr(''))
  }, [dispatch, contactId, billStatus]);
  useEffect(() => {
    dispatch(getUserBills({
      amount: filterAmountStr,
      contact: filterContact,
      from: fromDateStr,
      to: toDateStr,
      order_by: orderBy,
      page: currentPage,
      page_size: pageSize,
      status: status,
      q: searchQuery,
    }));
  }, [
    dispatch,
    filterAmountStr,
    filterContact,
    fromDateStr,
    toDateStr,
    orderBy,
    currentPage,
    pageSize,
    status,
    searchQuery
  ]);
  const billListOptions = () => {
    const billList = [];
    displayItems.map((billItem) => {
      if(billItem.id !== excludeId) billList.push({
        key: billItem.id,
        value: billItem.id,
        text: billItem.name,
        label: billItem.name,
      });
      return true;
    });
    return billList;
  };
  const handleSelect = (value) => {
    onSelect(value.value);
    setValue(value);
    setValueObject(value);
  };
  return <Select
    className={'payvy-select'}
    placeholder="Select Bill"
    isLoading={loading}
    value={valueObject}
    onChange={(option) => handleSelect(option)}
    options={billListOptions()}
  />;
};
