import {noop} from 'lodash';
import React from 'react';
import {FaSort, FaSortDown, FaSortUp} from "react-icons/fa6";
import {usePagination, useSortBy, useTable} from 'react-table';
import {ScrollPagination} from './components/ScrollPagination';

export function PayvyTable({
  columns,
  className,
  data,
  fetchData,
  loading,
  onSort,
  currentPage = 0,
  pageCount: controlledPageCount,
  count,
  defaultPageSize = 10,
  sizeOptions = [20, 50, 100],
  canScroll = true,
  emptyMessage = 'Nothing to see here',
  role = 'table',
}) {
  onSort = onSort || noop;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: {
      pageIndex,
      pageSize,
      sortBy,
    },
  } = useTable({
    columns,
    data,
    initialState: {
      pageIndex: currentPage,
      pageSize: defaultPageSize,
    },
    manualPagination: true,
    disableMultiSort: true,
    manualSortBy: true,
    pageCount: controlledPageCount,
  }, useSortBy, usePagination);
  React.useEffect(() => {
    fetchData({
      pageIndex,
      pageSize,
    });
  }, [fetchData, pageIndex, pageSize]);
  React.useEffect(() => {
    gotoPage(currentPage)
  }, [gotoPage, currentPage])
  React.useEffect(() => {
    onSort(sortBy);
  }, [onSort, sortBy]);
  sizeOptions.push(defaultPageSize);
  return (<>
    {canScroll ? <ScrollPagination
      sizeOptions={sizeOptions}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      previousPage={previousPage}
      nextPage={nextPage}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPageSize={setPageSize}
      count={count}
      defaultPageSize={defaultPageSize}
    /> : null}
    {loading ? <span className={'m-1 inline-flex'}>Loading...</span> : null}
    {!loading && data.length <= 0 ? <span className={'m-1 inline-flex'}>{emptyMessage}</span> : null}
    {!loading && data.length > 0 ?
      <table {...getTableProps()} role={role}
             className={`table-auto mt-2 w-full ${className ? className : ''}`}>
        <thead>
        {headerGroups.map(headerGroup => {
          const {
            key,
            ...headerProps
          } = headerGroup.getHeaderGroupProps();
          return <tr className={'border border-slate-300 text-center bg-slate-50'} key={key} {...headerProps}>
            {headerGroup.headers.map(column => {
              const {
                key,
                ...colProps
              } = column.getHeaderProps(column.getSortByToggleProps());
              return <th key={key} {...colProps}>
                <div className={'flex justify-center py-2 shadow-inner'}>
                  {column.render('Header')}
                  {' '}
                  {(column.canSort && !column.isSorted) &&
                    <FaSort className={'inline-flex self-center text-neutral-300'}/>}
                  {column.isSorted ? column.isSortedDesc ?
                    <FaSortDown className={'inline-flex self-center text-neutral-300'}/> :
                    <FaSortUp className={'inline-flex self-center text-neutral-300'}/> : ''}
                </div>
              </th>
            })}
          </tr>
        })}
        </thead>
        <tbody className={'bg-neutral-0'} {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          const {
            key,
            ...rowProps
          } = row.getRowProps();
          return (
            <tr className={'hover:bg-slate-100 hover-border hover:border-slate-300'} key={key} {...rowProps}>
              {row.cells.map(cell => {
                const {
                  key,
                  ...cellProps
                } = cell.getCellProps();
                return <td className={'px-4 py-2'} key={key} {...cellProps}>{cell.render('Cell')}</td>;
              })}
            </tr>);
        })}
        </tbody>
      </table> : null}
  </>);
}

