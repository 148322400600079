import React from 'react';
import {BlockAnalytics} from './BlockAnalytics';

export const BlockAnalyticsHolder = ({
  loading,
  analytics,
}) => {
  const data = [
    {
      'title': 'Money Received',
      'amount': analytics.money_received,
      'subInfo': `${analytics.number_of_payments} Payments`,
    },
    {
      'title': 'Invoiced',
      'amount': analytics.invoiced,
      'subInfo': `${analytics.number_of_invoices} Invoices`,
    }, {
      'title': 'Outstanding Bills',
      'amount': analytics.outstanding_bills,
      'subInfo': `${analytics.number_of_bills} Bills`,
    }, {
      'title': 'Available Balance',
      'amount': analytics.balance,
      'subInfo': 'in USD',
    }
  ];
  return <div className={'grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-5 mt-2 items-center'}>
    {data.map((item, index) => <BlockAnalytics key={index} loading={loading} {...item} />)}
  </div>;
};
