import {createSlice} from '@reduxjs/toolkit';
import {PAYVY_API} from '../constants';
import {createApiThunk} from "./api";
import {DB_PAYVYGPT_STORAGE, resetCacheForStore} from "./db";

export const integrationPayvyGPTSliceInitialState = {
  loading: {
    item: false,
    posting: false,
  },
  selectedThread: '',
  threads: [],
  llmModel: '',
  geminiApiKey: '',
  openaiApiKey: '',
  connectedSince: null
}

const payvygptSlice = createSlice({
  name: 'payvygpt',
  initialState: integrationPayvyGPTSliceInitialState,
  reducers: {
    resetPayvyGPTStateToInitial: (state) => {
      state.loading.item = false;
      state.loading.posting = false;
      state.geminiApiKey = '';
      state.openaiApiKey = '';
      state.connectedSince = null;
    },
    changeSelectedThread: (state, action) => {
      state.selectedThread = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(addTokenToPayvyGPT.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(addTokenToPayvyGPT.fulfilled, (state, action) => {
      state.loading.posting = false;
      state.geminiApiKey = action.payload.gemini_api_key;
      state.openaiApiKey = action.payload.openai_api_key;
      state.llmModel = action.payload.llm_model;
      state.connectedSince = action.payload.connected_since;
    })
    .addCase(addTokenToPayvyGPT.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(getConnectionInfo.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getConnectionInfo.fulfilled, (state, action) => {
      state.loading.item = false;
      state.geminiApiKey = action.payload.gemini_api_key;
      state.openaiApiKey = action.payload.openai_api_key;
      state.llmModel = action.payload.llm_model;
      state.connectedSince = action.payload.connected_since;
      state.enabled = action.payload.enabled;
    })
    .addCase(getConnectionInfo.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(getSpecificRoom.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getSpecificRoom.fulfilled, (state, action) => {
      state.loading.item = false;
      const thread = action.payload;
      state.selectedThread = thread.unique_hash;
      state.threads = state.threads.filter((t) => t.unique_hash !== thread.unique_hash);
      state.threads.unshift(thread);

    })
    .addCase(getSpecificRoom.rejected, (state) => {
      state.loading.item = false;
    })

    builder
    .addCase(getRooms.pending, (state) => {
      state.loading.item = true;
    })
    .addCase(getRooms.fulfilled, (state, action) => {
      state.loading.item = false;
      state.threads = action.payload;
      if(action.payload.length > 0 && !state.selectedThread) state.selectedThread = action.payload[0]?.unique_hash;
    })
    .addCase(getRooms.rejected, (state) => {
      state.loading.item = false;
    })
    builder
    .addCase(addMessageToRoom.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(addMessageToRoom.fulfilled, (state, action) => {
      state.loading.posting = false;
      const thread = action.payload;
      state.selectedThread = thread.unique_hash;
      state.threads = state.threads.filter((t) => t.unique_hash !== thread.unique_hash);
      state.threads.unshift(thread);
    })
    .addCase(addMessageToRoom.rejected, (state) => {
      state.loading.posting = false;
    })

    builder
    .addCase(createNewRoom.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(createNewRoom.fulfilled, (state, action) => {
      state.loading.posting = false;
    })
    .addCase(createNewRoom.rejected, (state) => {
      state.loading.posting = false;
    })
    builder
    .addCase(deleteRoom.pending, (state) => {
      state.loading.posting = true;
    })
    .addCase(deleteRoom.fulfilled, (state, action) => {
      state.loading.posting = false;
    })
    .addCase(deleteRoom.rejected, (state) => {
      state.loading.posting = false;
    })
  }
});

export const {
  resetPayvyGPTStateToInitial,
  changeSelectedThread
} = payvygptSlice.actions;
export default payvygptSlice.reducer;

// Specific PayvyGPT Thunks
export const getConnectionInfo = createApiThunk('payvyGPT', 'getConnectionInfo', PAYVY_API.V1.PAYVYGPT.CONNECTION_INFO, DB_PAYVYGPT_STORAGE, [], 'GET');
export const getRooms = createApiThunk('payvyGPT', 'getRooms', PAYVY_API.V1.PAYVYGPT.ROOMS, DB_PAYVYGPT_STORAGE, [], 'GET');
export const getSpecificRoom = createApiThunk('payvyGPT', 'getSpecificRoom', PAYVY_API.V1.PAYVYGPT.SPECIFIC_ROOM, DB_PAYVYGPT_STORAGE, [], 'GET');
export const addMessageToRoom = createApiThunk('payvyGPT', 'addMessageToRoom', PAYVY_API.V1.PAYVYGPT.ADD_MESSAGE_TO_ROOM, DB_PAYVYGPT_STORAGE, [], 'POST');
export const addTokenToPayvyGPT = createApiThunk('payvyGPT', 'addToken', PAYVY_API.V1.PAYVYGPT.ADD_TOKEN, DB_PAYVYGPT_STORAGE, [], 'POST');
export const createNewRoom = createApiThunk('payvyGPT', 'createNewRoom', PAYVY_API.V1.PAYVYGPT.CREATE_ROOM, DB_PAYVYGPT_STORAGE, [], 'POST');
export const deleteRoom = createApiThunk('payvyGPT', 'deleteRoom', PAYVY_API.V1.PAYVYGPT.DELETE_ROOM, DB_PAYVYGPT_STORAGE, [], 'DELETE');

export const resetPayvyGPTToInitialState = () => {
  return async(dispatch) => {
    dispatch(resetPayvyGPTStateToInitial());
    await resetCacheForStore(DB_PAYVYGPT_STORAGE);
  }
}

export const switchSelectedThread = (thread) => {
  return async(dispatch) => {
    dispatch(changeSelectedThread(thread));
  }
}
