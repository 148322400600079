import React from "react";
import {useNavigate} from "react-router-dom";
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";

export const ProcessFinished = ({...rest}) => {
  const navigate = useNavigate()
  return <>
    <h2 className={'text-2xl font-medium p-2 pb-12'}>Finished - You have created your account</h2>
    <PayvyIconButton
      buttonText={'Login in to your dashboard'}
      wrapperClassName={'ml-2'}
      onClick={() => navigate(PAYVY_URL.LOGIN)}
    />
  </>
}
