import React from 'react';
import {FaAngleRight} from 'react-icons/fa';

export const SettingsBreadcrumb = ({active}) => {
  return <div className={'flex flex-row text-4xl p-4'}>
    <div className={'flex text-neutral-100'}>Settings</div>
    <div className={'flex text-neutral-100 mt-1.5'}><FaAngleRight/></div>
    <div className={'flex font-bold'}>{active}</div>
  </div>;
};
