import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {FaCheck, FaFilter} from 'react-icons/fa';
import {GrPowerReset} from 'react-icons/gr';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyInput, PayvyLabeledInput, SearchContact} from '../../../comps/forms';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";

export const DropdownFilter = ({
  loading = false,
  activeFilters = false,
  onFilterChange,
}) => {
  const [filterOpen, setFilterOpen] = useState(false);
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  return (
    <>
      <PayvyIconButton
        Icon={FaFilter}
        onClick={() => setFilterOpen(!filterOpen)}
        iconRight={true}
        buttonText={'Filter'}
        mainColor={activeFilters ? 'green-500' : undefined}
        hoverColor={activeFilters ? 'green-500' : undefined}
      />
      <div
        className={`${
          filterOpen ? 'block' : 'hidden'
        } absolute z-10 mt-8 origin-top-right bg-neutral-0 border border-neutral-300 rounded-md shadow-lg p-4`}
      >
        <Formik
          innerRef={formikRef}
          initialValues={{
            contact: undefined,
            amount: '',
          }}
          validationSchema={Yup.object({
            contact: Yup.number(),
            amount: Yup.string()
          })}
          onSubmit={(values, helpers) => {
            onFilterChange({
              contact: values.contact,
              amount: values.amount,
            });
          }}
        >
          {props => <Form className={'flex flex-col'}>
            <PayvyLabeledInput
              label={'Amount'}
              name={'amount'}
              placeholder={'e.g. 1000, 1000-5000, >5000'}
              alwaysLabel={true}
              as={PayvyInput}
              loading={loading}
            />
            <PayvyLabeledInput
              label={'Contact'}
              name={'contact'}
              loading={loading}
            >
              <SearchContact
                placeholder={'Search by name'}
                setField={({value}) => props.setFieldValue('contact', value)}
                isClearable={true}
                value={props.values.contact}
              />
            </PayvyLabeledInput>
            <div className={'flex flex-row'}>
              <PayvyIconButton
                Icon={GrPowerReset}
                onClick={() => {
                  props.resetForm();
                  onFilterChange({
                    contact: undefined,
                    amount: undefined,
                  });
                }}
                iconRight={true}
                buttonText={'Reset'}
                mainColor={'none'}
                hoverColor={'none'}
                hoverTextColor={'neutral-900'}
                textColor={'neutral-700'}
                iconColor={'neutral-700'}
                wrapperClassName={'justify-center'}
                focusable={false}
              />
              <PayvyIconButton
                Icon={FaCheck}
                onClick={props.submitForm}
                buttonText={'Set Filter'}
                mainColor={'none'}
                hoverColor={'none'}
                hoverTextColor={'green-700'}
                textColor={'green-500'}
                iconColor={'green-500'}
                wrapperClassName={'justify-center'}
                focusable={false}
              />
            </div>
          </Form>}
        </Formik>
      </div>
    </>
  );
};
