import React, {useState} from 'react';
import {BsDownload} from 'react-icons/bs';
import {PayvyIconButton} from '../../../comps/forms';
import {ModalPayvyBase} from '../../../comps/modals';

export const ModalDownloadBill = ({bill}) => {
  const [modalOpen, setModalOpen] = useState(false);
  return <>
    <PayvyIconButton
      Icon={BsDownload}
      onClick={() => setModalOpen(true)}
      tooltipContent={'Download Attached Files'}
      tooltipPlace={'bottom-start'}
      mainColor={'none'}
      borderColor={'none'}
      iconColor={'neutral-700'}
      iconSize={22}
      hoverTextColor={'slate-300'}
    />
    <ModalPayvyBase
      title={`Download attached files`}
      noButtons={true}
      isOpen={modalOpen}
      dismissButtonText={'Close'}
      dismissButtonAction={() => setModalOpen(false)}
    >
      <div className={'flex flex-row justify-between'}>
        <PayvyIconButton
          buttonText={'CSV Download'}
          Icon={BsDownload}
          href={bill.csv_download}
          mainColor={'slate-800'}
          hoverColor={'slate-400'}
        />
        <PayvyIconButton
          buttonText={'PDF Download'}
          Icon={BsDownload}
          href={bill.pdf_download}
          mainColor={'slate-800'}
          hoverColor={'slate-400'}
        />
      </div>
      {bill && bill.source_download && bill.source_download.length > 0 ? <>
        <h1 className={'text-lg font-bold mt-5 mb-2'}>Source Files:</h1>
        {bill.source_download.map((item, i) => <PayvyIconButton
          buttonText={item.name.slice(0, 50)}
          Icon={BsDownload}
          href={item.url}
          mainColor={'slate-800'}
          hoverColor={'slate-400'}
          wrapperClassName={'my-1'}
          key={i}
        />)}
      </> : null}
    </ModalPayvyBase>
  </>;
};
