import React from 'react';
import {PAYVY_LOGOS} from '../../constants';
import {FormCompanyCreation} from "./components/FormCompanyCreation";


const CreateCompanyPage = () => {
  return <div className="flex items-center justify-center h-screen">
    <div className={'flex flex-col w-11/12 md:w-4/12'}>
      <div className={'flex justify-center mb-5'}>
        <img
          src={PAYVY_LOGOS.RED}
          alt={'logo'}
          className={'py-2'}
        />
      </div>
      <div className={'flex flex-col border border-neutral-500 shadow-md p-2 bg-neutral-50 rounded-md'}>
        <FormCompanyCreation/>
      </div>
    </div>
  </div>;
};

export default CreateCompanyPage;
