import React from "react";
import CurrencyFormat from "react-currency-format";
import {FaExternalLinkAlt} from "react-icons/fa";
import {FiEye} from "react-icons/fi";
import {TbAlertTriangleFilled} from "react-icons/tb";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import {PayvyIconButton} from "../../../comps/forms";
import {PAYVY_URL} from "../../../constants";
import {getUserInboxItem, markAsNonDuplicate, removeInboxPage} from "../../../slices/newInbox";
import {displayAmount} from "../../../utils/BillHelpers";
import {build_url, payvyToast} from "../../../utils/Utility";

export const DuplicateContentPageInformation = ({
  inboxItemId,
  inboxItemPageId,
  duplicateOf,
  invoiceNumber,
  invoiceDate,
  invoiceDueDate,
  tax,
  deleted,
  ocrInvoiceNumber,
  ocrDate,
  ocrDueDate,
  ocrTax,
  subtotal,
  ocrSubtotal,
  totalAmount,
  ocrAmount
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentInvoiceNumber = invoiceNumber || ocrInvoiceNumber || 'N/A';
  let currentInvoiceDate = invoiceDate || ocrDate || 'N/A';
  if(currentInvoiceDate !== 'N/A') currentInvoiceDate = new Date(currentInvoiceDate).toLocaleDateString('en-US')
  let currentInvoiceDueDate = invoiceDueDate || ocrDueDate || 'N/A';
  if(currentInvoiceDueDate !== 'N/A') currentInvoiceDueDate = new Date(currentInvoiceDueDate).toLocaleDateString('en-US')
  const currentTax = tax || ocrTax || 'N/A';
  const currentSubtotal = subtotal || ocrSubtotal || 'N/A';
  const currentTotalAmount = totalAmount || ocrAmount || 'N/A';
  const duplicateInvoiceNumber = duplicateOf.invoice_number || duplicateOf.ocr.invoice_number || 'N/A';
  let duplicateInvoiceDate = duplicateOf.invoice_date || duplicateOf.ocr.invoice_date || 'N/A';
  if(duplicateInvoiceDate !== 'N/A') duplicateInvoiceDate = new Date(duplicateInvoiceDate).toLocaleDateString('en-US')
  let duplicateInvoiceDueDate = duplicateOf.due_date || duplicateOf.ocr.payment_due_date || 'N/A';
  if(duplicateInvoiceDueDate !== 'N/A') duplicateInvoiceDueDate = new Date(duplicateInvoiceDueDate).toLocaleDateString('en-US')
  const duplicateTax = duplicateOf.tax || duplicateOf.ocr.invoice_tax || 'N/A';
  const duplicateSubtotal = duplicateOf.subtotal || duplicateOf.ocr.invoice_subtotal || 'N/A';
  const duplicateTotalAmount = duplicateOf.total_amount || duplicateOf.ocr.invoice_amount || 'N/A';
  const {
    id: duplicateInboxItemId = null,
    deleted: duplicateInboxItemDeleted = null
  } = duplicateOf.inbox_item || {};
  const {
    id: duplicateBillId,
    name: duplicateBillName,
    status: duplicateBillStatus
  } = duplicateOf.bill || {}
  return <div className={'flex flex-col'}>
    <div className="flex items-start m-2 p-4 border border-gray-300 rounded-lg bg-gray-100 max-w-md">
      <TbAlertTriangleFilled className="flex text-yellow-500 w-7 h-7 self-center"/>
      <div className="ml-3">
        <p className="font-semibold text-gray-800">Potential Duplicate Detected</p>
        <p className="text-sm text-gray-600">
          Please review the details below to confirm if this document should be kept.
        </p>
      </div>
    </div>
    <div className="mx-6 mb-6">
      <table className="w-full text-sm">
        <thead>
        <tr className="border-b border-slate-200">
          <th className="py-2 text-left text-slate-600">Existing</th>
          <th className="py-2 text-left text-slate-600">Link</th>
          <th className="py-2 text-left text-slate-600">Status</th>
        </tr>
        </thead>
        <tbody>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Inbox Item</td>
          <td
            className={`py-2 font-medium`}>
            {duplicateInboxItemId && !duplicateInboxItemDeleted ? <a
              href={build_url(PAYVY_URL.INBOX.DETAILS, {'id': duplicateInboxItemId})}
              target={'_blank'}
              rel="noreferrer"
              className={`flex flex-row ${duplicateInboxItemDeleted ? 'text-slate-500' : 'text-slate-900'}`}
            >
              <FaExternalLinkAlt className="pt-1"/>
              #{duplicateInboxItemId}
            </a> : ''}
          </td>
          <td
            className={`py-2 font-medium ${duplicateInvoiceNumber === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{duplicateInboxItemDeleted ? 'Deleted' : duplicateBillId ? 'Assigned to Bill' : 'Active'}</td>
        </tr>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Bill</td>
          <td
            className={`py-2 font-medium`}>
            {duplicateBillId ? <a
              href={build_url(PAYVY_URL.BILLS.DETAILS, {'id': duplicateBillId})}
              target={'_blank'}
              rel="noreferrer"
              className={`flex flex-row text-slate-900`}
            >
              <FaExternalLinkAlt className="pt-1"/>
              {duplicateBillName}
            </a> : ''}
          </td>
          <td
            className={`py-2 font-medium ${duplicateInvoiceNumber === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{duplicateBillStatus || 'Unassigned'}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div className="mx-6 mb-6">
      <table className="w-full text-sm">
        <thead>
        <tr className="border-b border-slate-200">
          <th className="py-2 text-left text-slate-600">Field</th>
          <th className="py-2 text-left text-slate-600">Current</th>
          <th className="py-2 text-left text-slate-600">Existing</th>
        </tr>
        </thead>
        <tbody>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Invoice Number</td>
          <td
            className={`py-2 font-medium ${currentInvoiceNumber === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{currentInvoiceNumber}</td>
          <td
            className={`py-2 font-medium ${duplicateInvoiceNumber === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{duplicateInvoiceNumber}</td>
        </tr>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Date</td>
          <td
            className={`py-2 font-medium ${currentInvoiceDate === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{currentInvoiceDate}</td>
          <td
            className={`py-2 font-medium ${duplicateInvoiceDate === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{duplicateInvoiceDate}</td>
        </tr>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Due Date</td>
          <td
            className={`py-2 font-medium ${currentInvoiceDueDate === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{currentInvoiceDueDate}</td>
          <td
            className={`py-2 font-medium ${duplicateInvoiceDueDate === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>{duplicateInvoiceDueDate}</td>
        </tr>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Tax</td>
          <td className={`py-2 font-medium ${currentTax === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {currentTax !== 'N/A' ?
              <CurrencyFormat value={displayAmount(currentTax)} displayType={'text'} thousandSeparator={true}
                              prefix={'$'}/> : currentTax}
          </td>
          <td className={`py-2 font-medium ${duplicateTax === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {duplicateTax !== 'N/A' ?
              <CurrencyFormat value={displayAmount(duplicateTax)} displayType={'text'} thousandSeparator={true}
                              prefix={'$'}/> : duplicateTax}
          </td>
        </tr>
        <tr className="border-b border-slate-200">
          <td className="py-2 text-slate-600">Subtotal</td>
          <td className={`py-2 font-medium ${currentSubtotal === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {currentSubtotal !== 'N/A' ?
              <CurrencyFormat value={displayAmount(currentSubtotal)} displayType={'text'} thousandSeparator={true}
                              prefix={'$'}/> : currentSubtotal}
          </td>
          <td className={`py-2 font-medium ${duplicateSubtotal === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {duplicateSubtotal !== 'N/A' ?
              <CurrencyFormat value={displayAmount(duplicateSubtotal)} displayType={'text'} thousandSeparator={true}
                              prefix={'$'}/> : duplicateSubtotal}
          </td>
        </tr>
        <tr>
          <td className="py-2 text-slate-600">Total</td>
          <td className={`py-2 font-medium ${currentTotalAmount === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {currentTotalAmount !== 'N/A' ?
              <CurrencyFormat value={displayAmount(currentTotalAmount)} displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}/> : currentTotalAmount}
          </td>
          <td
            className={`py-2 font-medium ${duplicateTotalAmount === 'N/A' ? 'text-slate-500' : 'text-slate-900'}`}>
            {duplicateTotalAmount !== 'N/A' ?
              <CurrencyFormat value={displayAmount(duplicateTotalAmount)} displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}/> : duplicateTotalAmount}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div className={'flex flex-col p-2'}>
      <div className={'flex flex-row'}>
        <div
          className={`duplicate-info-${duplicateOf.id} w-full mb-4 py-2 px-4 bg-slate-100 hover:bg-slate-200 text-slate-700 rounded-lg transition-colors flex items-center justify-center gap-2`}>
          <FiEye/>
          Compare Documents
        </div>
        <Tooltip anchorSelect={`.duplicate-info-${duplicateOf.id}`} place={'bottom-center'} type="dark"
                 effect="solid">
          <img
            src={duplicateOf?.page?.url}
            alt={invoiceNumber}
            style={{
              maxWidth: '700px',
              maxHeight: '850px'
            }}
          />
        </Tooltip>
      </div>
      <div className={'flex flex-row gap-4'}>
        <div className={'w-1/2'}>
          {!deleted && <PayvyIconButton
            buttonText={'Remove Duplicate'}
            onClick={() => {
              dispatch(removeInboxPage({
                id: inboxItemId,
                page: inboxItemPageId,
                successCallback: () => {
                  dispatch(getUserInboxItem({
                    id: inboxItemId,
                    forceUpdate: true,
                    successCallback: () => payvyToast('Item information is refreshed', {'appearance': 'success'}),
                    errorCallback: () => navigate(PAYVY_URL.INBOX.RECEIVED)
                  }))
                  payvyToast(`Page removed successfully.`, {appearance: 'success'});
                },
                errorCallback: (error) => {
                  if('message' in error) payvyToast(error.message || `Page removal failed.`, {appearance: 'error'});
                }
              }));
            }}
            fullWidth={true}
            wrapperClassName={'w-full'}
            mainColor={'slate-100'}
            textColor={'slate-700 text-slate-700'}
            hoverColor={'slate-300 hover:bg-slate-300'}
            hoverTextColor={'neutral-50 hover:text-slate-700'}
          />}
        </div>
        <div className={'w-1/2'}>
          <PayvyIconButton
            buttonText={'Keep Both'}
            onClick={() => {
              dispatch(markAsNonDuplicate({
                id: inboxItemId,
                page: inboxItemPageId,
                successCallback: () => {
                  dispatch(getUserInboxItem({
                    id: inboxItemId,
                    forceUpdate: true,
                    successCallback: () => payvyToast('Item information is refreshed', {'appearance': 'success'}),
                    errorCallback: () => navigate(PAYVY_URL.INBOX.RECEIVED)
                  }))
                  payvyToast(`Page marked as non-duplicate.`, {appearance: 'success'});
                },
                errorCallback: (error) => {
                  if('message' in error) payvyToast(error.message || `Page marking as non-duplicate failed.`, {appearance: 'error'});
                }
              }))
            }}
            fullWidth={true}
            wrapperClassName={'w-full'}
            mainColor={'slate-700 bg-slate-700'}
            hoverColor={'slate-100 hover:bg-slate-100'}
            textColor={'slate-50 text-slate-50'}
            hoverTextColor={'slate-700 hover:text-slate-700'}
          />
        </div>
      </div>
    </div>
  </div>
}
