import React, {useEffect} from 'react';
import CurrencyFormat from 'react-currency-format';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {PAYVY_URL} from "../../../constants";
import {getTransactions} from "../../../slices/newTransaction";
import {displayAmount} from '../../../utils/BillHelpers';
import {ModuleBlock} from './ModuleBlock';

export const ModulePayments = ({contactId}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading: {list: loading},
    displayItems
  } = useSelector((state) => state.transactions);
  useEffect(() => {
    dispatch(getTransactions({
      page: 1,
      page_size: 10,
      contact: contactId,
    }));
  }, [dispatch, contactId])
  return <ModuleBlock
    big={true}
    title={'Payments'}
    buttons={[
      {
        label: 'View All',
        onClick: () => navigate(PAYVY_URL.TRANSACTION.LIST),
      },
    ]}
  >

    {loading ? <div className="text-center font-light py-4">Loading...</div> :
      <>
        {displayItems.length === 0 ? <span
          className={'font-light'}
        >
            No payments have been made.
        </span> : <table className="table-auto w-full">
          <thead>
          <tr className={'border-2 border-neutral-150 text-neutral-150 font-light'}>
            <th>Amount</th>
            <th>Bill Name</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {displayItems.map((payment, index) => {
            const {
              paid_amount: amount,
              bill: {name: billName} = {},
              date,
              status,
            } = payment;
            const paymentDate = new Date(date).toLocaleString('en-us', {
              month: 'short',
              day: 'numeric',
            });
            let formattedStatus = <span className={'text-neutral-150'}>{status}</span>;
            if(status.toLowerCase() === 'paid') formattedStatus =
              <span
                className={'text-green-500'}>PAID</span>; else if(status.toLowerCase() === 'initiated') formattedStatus =
              <span
                className={'text-neutral-150'}>INITIATED</span>; else if(status.toLowerCase() === 'pending') formattedStatus =
              <span
                className={'text-neutral-200'}>PENDING</span>; else if(status.toLowerCase() === 'failed') formattedStatus =
              <span
                className={'text-red-400'}>FAILED</span>; else if(status.toLowerCase() === 'refunded') formattedStatus =
              <span className={'text-red-200'}>REFUNDED</span>;
            return <tr key={index} className={'text-center font-bold text-neutral-700'}>
              <td>
                <CurrencyFormat value={displayAmount(amount)} displayType={'text'} thousandSeparator={true}
                                prefix={'$'}/>
              </td>
              <td>{billName}</td>
              <td>{paymentDate}</td>
              <td>{formattedStatus}</td>
            </tr>;
          })}
          </tbody>
        </table>}
      </>}
  </ModuleBlock>;
};
