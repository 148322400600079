import {noop} from 'lodash';
import Mousetrap from 'mousetrap';
import React, {useEffect} from 'react';
import {TbX} from 'react-icons/tb';
import {PayvyIconButton} from '../../forms';

export const ModalPayvyBase = ({
  title,
  children,
  isOpen = true,
  noButtons = false,
  confirmButtonText = 'Save',
  confirmButtonAction = noop,
  confirmButtonLoading = false,
  confirmButtonDisabled = false,
  dismissButtonText = 'Cancel',
  dismissButtonAction = noop,
  dismissButtonLoading = false,
  dismissButtonDisabled = false,
  optionalButtonText = '',
  optionalButtonAction = noop,
  optionalButtonLoading = false,
  optionalButtonDisabled = false,
  optionalButtonHelp = '',
  position = 'left-1/3 top-1/3',
  size = 'sm:max-w-lg',
  topX = true
}) => {
  useEffect(() => {
    Mousetrap.bind("escape", (e) => {
      e.preventDefault();
      dismissButtonAction();
    });
  }, [dismissButtonAction]);
  if(!isOpen) return null;
  return (<div className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 z-50 overflow-y-auto`}>
    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-10 text-center sm:block sm:p-0"
         role="dialog">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      </div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        className={`absolute ${position} inline-block align-bottom bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle ${size} sm:w-full`}>
        <div className={`bg-neutral-0 px-6 py-4 rounded-lg shadow-lg`}>
          <div className={'grid grid-cols-3 mb-2'}>
            <h1 className={'col-span-2 text-neutral-700 text-xl font-bold capitalize'}>{title}</h1>
            {topX ?
              <div className={'justify-self-end text-xl self-auto cursor-pointer'}><TbX onClick={dismissButtonAction}
                                                                                        data-tooltip-content={'Close'}/>
              </div> : null}
          </div>
          <div className={'grid mb-2'}>
            {children}
          </div>
          {noButtons ? null : <div className={'flex flex-row justify-end gap-2'}>
            {optionalButtonText && <div className={'flex grow flex-col'}>
              <PayvyIconButton
                buttonText={optionalButtonText}
                onClick={optionalButtonAction}
                disabled={optionalButtonDisabled}
                loading={optionalButtonLoading}
                borderSize={2}
                borderColor={'red-300'}
                mainColor={'slate-500'}
                textColor={'slate-900'}
                hoverTextColor={'slate-700'}
              />
              <small className={'ml-0.5 text-2xs flex-wrap'}>{optionalButtonHelp}</small>
            </div>}
            <PayvyIconButton
              buttonText={dismissButtonText}
              onClick={dismissButtonAction}
              disabled={dismissButtonDisabled}
              loading={dismissButtonLoading}
              borderSize={2}
              borderColor={'slate-900'}
              mainColor={'neutral-0'}
              textColor={'slate-900'}
              hoverTextColor={'slate-700'}
              shortcutKey={'escape'}
            />
            <PayvyIconButton
              buttonText={confirmButtonText}
              onClick={confirmButtonAction}
              disabled={confirmButtonDisabled}
              loading={confirmButtonLoading}
              type={'submit'}
            />
          </div>}
        </div>
      </div>
    </div>
  </div>);
};
