import React, {useEffect} from 'react';
import CurrencyFormat from 'react-currency-format';
import {useDispatch, useSelector} from 'react-redux';
import {getContactDetails, getContactPayments} from '../../../slices/newContact';
import {displayAmount} from '../../../utils/BillHelpers';

const PaymentsForContact = ({contact: {id} = {}}) => {
  const dispatch = useDispatch();
  const {items} = useSelector((state) => state.contact);
  const contact = Object.values(items)
                        .flat()
                        .find(item => item.id === parseInt(id)
                        )
  const payments = contact?.payments || {
    total: 0,
    last_12: 0,
    year_to_date: 0
  };
  useEffect(() => {
    dispatch(getContactDetails({
      id,
      successCallback: () => {
        dispatch(getContactPayments({id}));
      }
    }))
  }, [dispatch, id]);
  return <div className={'w-full p-1 pb-24'}>
    <h2 className={'ml-0.5 font-light text-sm text-neutral-700'}>Payments</h2>
    <table className={'w-full table-auto'}>
      <thead>
      <tr className={'bg-slate-700 text-neutral-0'}>
        <th>Total Paid</th>
        <th>Last 12 Mo</th>
        <th>YTD</th>
      </tr>
      </thead>
      <tbody>
      <tr className={'text-center'}>
        <td>
          <CurrencyFormat
            value={displayAmount(payments.total)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </td>
        <td>
          <CurrencyFormat
            value={displayAmount(payments.last_12)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </td>
        <td>
          <CurrencyFormat
            value={displayAmount(payments.year_to_date)}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>;
};

export default PaymentsForContact;
