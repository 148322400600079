import {noop} from 'lodash';
import React, {useState} from 'react';
import {PayvySubmenu} from '../../../comps/elements';
import {History} from './History';
import {LedgerHistory} from "./LedgerHistory";
import {PageList} from './PageList';

const Sidebar = ({
  bill,
  billPermission,
  currentPage = 0,
  setCurrentPage,
}) => {
  setCurrentPage = setCurrentPage || noop;
  const [activeItem, setActiveItem] = useState('invoices-included');
  return <div className={`flex shrink-0 flex-col w-full md:w-90 border-r-2 border-neutral-100 shadow-inner`}>
    <PayvySubmenu
      activeItem={activeItem}
      setActiveItem={setActiveItem}
      options={[
        {
          name: 'Pages',
          value: 'invoices-included',
        }, {
          name: 'History',
          value: 'history',
        }, {
          name: 'Ledger',
          value: 'ledger',
        }
      ]}
    />
    {activeItem === 'invoices-included' && <PageList
      bill={bill}
      pages={bill.pages}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      canUpdate={billPermission.canUpdate}
      canDelete={billPermission.canDelete}
    />}
    {activeItem === 'history' && <History bill={bill}/>}
    {activeItem === 'ledger' && <LedgerHistory bill={bill}/>}
  </div>;
};
export default Sidebar;
