import moment from "moment/moment";
import React, {useEffect} from 'react';
import {AiOutlineLoading} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {getContactLedgerHistory} from "../../../slices/newContact";
import {LedgerItem} from "../../BillDetailPage/components/LedgerItem";

export const Logs = ({contact}) => {
  const dispatch = useDispatch();
  const {
    loading: {ledger: loading},
    ledger
  } = useSelector((state) => state.contact);
  const contactLedger = ledger[contact.id] || [];
  let previousDate = '';
  useEffect(() => {
    dispatch(getContactLedgerHistory({id: contact.id}));
  }, [dispatch, contact]);
  return <div className={`payvy-bill-history ${loading ? 'm-0 overflow-hidden overflow-y-hidden' : 'overflow-y-auto'}`}>
    {contactLedger.length === 0 && loading && <div className={'w-full h-full relative pt-5'}>
      <AiOutlineLoading className={'animate-spin centered text-center'} size={36}/>
    </div>}
    <div className={'payvy-history'}>
      {contactLedger.length > 0 && loading &&
        <AiOutlineLoading className={'animate-spin py-2 centered text-center'} size={36}/>}
      {contactLedger.map((ledgerHistory, index) => {
        const currentDate = moment(ledgerHistory.timestamp)
        .format('YYYY-MM-DD');
        const label_name = moment(ledgerHistory.timestamp)
        .calendar(null, {
          sameDay: '[Today]',
          lastDay: '[Yesterday]',
          lastWeek: '[Last] dddd',
          sameElse: 'DD/MM/YYYY',
        });
        if(currentDate !== previousDate) {
          previousDate = currentDate;
          return <React.Fragment key={index}>
            <div className={'new-day my-1 text-center mb-2'}>
              <div className={'w-auto inline-block centered px-2 py-1 bg-red-50 rounded-lg'}>{label_name}</div>
              <div className={'border-b border-neutral-500 -mt-4'}/>
            </div>
            <LedgerItem ledgerItem={ledgerHistory}/>
          </React.Fragment>;
        } else {
          return <LedgerItem key={index} ledgerItem={ledgerHistory}/>;
        }
      })}
      {contactLedger.length === 0 && !loading ? <div className={'text-center text-neutral-500'}>
        No ledger history found
      </div> : null}
    </div>
  </div>
};
