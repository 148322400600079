export const getPrices = (plans, membersCount, selectedPlan, frequency) => {
  if(!plans || plans.length <= 0) return 0;
  const plan = selectedPlan ? plans.find(item => item.name === selectedPlan) : plans[0];
  if(!plan) return 0;
  if(!plan.prices || plan.prices.length <= 0) return 0;
  const price = frequency ? plan.prices.find(item => item.interval === frequency) : plan.prices[0];
  if(!price) return 0;
  if(!!price.amount) return price.amount;
  if(!price.tiers) return 0;
  const tier = price.tiers.find(item => (item.up_to ? item.up_to : Number.MAX_SAFE_INTEGER) >= membersCount);
  return (tier.flat_amount || tier.unit_amount) / 100; // Stripe uses cents, so divide by 100 to get the real amount
};
