import React from 'react';

export const ModuleOverviewColumn = ({
  title,
  value,
}) => {
  return <div className={'flex flex-col'}>
    <div className={'text-neutral-150'}>{title}</div>
    <div className={'text-neutral-700'}>{value}</div>
  </div>;
};
