import {Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import {FaPlus} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {PayvyIconButton, PayvyInput, PayvyLabeledInput} from '../../../comps/forms/';
import {ModalPayvyBase} from '../../../comps/modals';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {buyFaxNumber, resetAvailableNumbers, searchFaxNumber, twilioSelector} from '../../../slices/twilio';
import {FaxNumberOption} from "./FaxNumberOption";

export const ModalBuyFaxNumber = ({
  buttonDisabled = false,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {addToast} = useToasts();
  const buyNumberForm = React.createRef();
  const searchNumberForm = React.createRef();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    loading,
    processing,
    availableNumbers,
  } = useSelector(twilioSelector);
  const [searched, setSearched] = useState(false);
  return <>
    <PayvyIconButton
      Icon={FaPlus}
      buttonText={'Add Number'}
      disabled={buttonDisabled}
      onClick={() => {
        dispatch(resetAvailableNumbers());
        setModalOpen(true);
      }}
    />
    <ModalPayvyBase
      title={`Buying a Fax Number`}
      isOpen={modalOpen}
      confirmButtonText={searched && availableNumbers.length > 0 ? 'Buy Number' : 'Search Number'}
      confirmButtonAction={() => {
        searched && availableNumbers.length > 0 ? buyNumberForm?.current?.click() : searchNumberForm?.current?.click();
      }}
      confirmButtonLoading={processing || loading}
      dismissButtonText={'Cancel'}
      dismissButtonAction={() => setModalOpen(false)}
      dismissButtonDisabled={processing || loading}
    >
      {!searched && <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={{
          area: '',
          contains: '',
        }}
        validationSchema={Yup.object({
          area: Yup.number(),
          contains: Yup.number(),
        })}
        onSubmit={(values) => {
          dispatch(searchFaxNumber({
            area: values.area,
            contains: values.contains,
            success: () => {
              setSearched(true);
            }
          }));
        }}
      >
        <Form className={'flex flex-row gap-2'}>
          <div className={'flex-inline w-3/12'}>
            <PayvyLabeledInput
              label={'Area code'}
              name={'area'}
              as={PayvyInput}
            />
          </div>
          <div className={'flex-inline w-9/12'}>
            <PayvyLabeledInput
              label={'Contains'}
              name={'contains'}
              as={PayvyInput}
            />
          </div>
          <button ref={searchNumberForm} type="hidden"/>
        </Form>
      </Formik>}
      {searched && <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          number: availableNumbers[0],
        }}
        validationSchema={Yup.object({
          number: Yup.string()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        })}
        onSubmit={(values) => {
          dispatch(buyFaxNumber({
            number: values.number,
            success: () => {
              setModalOpen(false);
            },
            failure: (error) => {
              if(error && error.number && error.number.length > 0) addToast(error.number[0], {appearance: 'error'}); else addToast('Failed to buy fax number. Please try again later.', {appearance: 'error'});
            }
          }));
        }}
      >
        {props => <>
          <Form>
            <span className={'font-bold text-xl w-full'}>Available options:</span>
            {availableNumbers?.length > 0 ? <div className={'grid grid-cols-3'}>
              {availableNumbers.map((item, index) => <FaxNumberOption
                key={index}
                phoneNumber={item}
                selectNumber={value => props.setFieldValue('number', value)}
                isSelected={item === props.values.number}
              />)}
            </div> : <div>No numbers were found matching your desired number.</div>}
            <button ref={buyNumberForm} type="hidden"/>
          </Form>
        </>}
      </Formik>}
    </ModalPayvyBase>
  </>;
};
