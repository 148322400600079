import Mousetrap from "mousetrap";
import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Tooltip} from "react-tooltip";


export const PayvySidebarItem = ({
  hasAccess = true,
  name,
  Icon,
  command,
  minimized,
  onClick,
  url,
  hasParent,
  notificationCount = 0,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    Mousetrap.bind(command || '^9+9', (e) => {
      e.preventDefault();
      if(onClick) onClick();
      else if(url) navigate(url);
    })
  }, [command, onClick, url, navigate]);
  if(hasAccess === false) return <></>;
  const activePath = location.pathname.split('/')[1];
  const active = activePath && url ? url.includes(activePath) : name === 'Dashboard';
  notificationCount = notificationCount > 99 ? '99+' : notificationCount;
  return <div className={`flex flex-row mr-3 my-1 ${hasParent && !minimized ? 'ml-7' : 'ml-3'}`}>
    <div
      className={`relative flex w-full cursor-pointer hover:text-slate-500 ${active ? 'text-slate-900 font-bold' : ' font-light'}`}
         onClick={() => {
           if(onClick) onClick();
           else if(url) navigate(url);
         }}>
      {(Icon && (!hasParent || (hasParent && minimized))) && (
        <div className={'flex items-center mr-2'} data-tooltip-id="sidebar-tooltip" data-tooltip-content={name}>
          <Icon className={`${minimized ? 'w-7 h-7 p-1' : 'w-5 h-5'}`}/>
          {minimized && <Tooltip id="sidebar-tooltip"/>}
        </div>
      )}

      {!minimized && <div className={'whitespace-nowrap text-base flex grow'}>{name}</div>}
      {(notificationCount > 0 || notificationCount === '99+') && !minimized && <div
        className={`flex items-center justify-center self-center w-5 h-5 text-xs text-red-900 bg-red-50 rounded-full`}>{notificationCount}</div>}
    </div>
  </div>;
};
