import React from 'react';
import {displayAmount} from '../../../../utils/BillHelpers';

let CurrencyFormat = require('react-currency-format');

export function CellCurrency({
  value,
  column: {
    CanBeNull = false,
    inCents = false,
  },
}) {
  if(CanBeNull && value === null) return 'N/A';
  value = parseFloat(value);
  if(inCents) value = value / 100;
  return <CurrencyFormat value={displayAmount(value)} displayType={'text'} thousandSeparator={true} prefix={'$'}/>;
}
