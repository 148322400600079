import {useFormik} from 'formik';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useToasts} from 'react-toast-notifications';
import * as Yup from 'yup';
import {FORM_ERROR_MESSAGES} from '../../../constants';
import {brandLogoUpload, getCompanyDetails} from '../../../slices/companies';
import {handleErrors} from '../../../utils/Utility';

export const ButtonLogoUpload = ({
  companyId,
  hasLogo,
  processing,
  loading,
}) => {
  const dispatch = useDispatch();
  const {addToast} = useToasts();
  const formik = useFormik({
    initialValues: {
      brandLogo: null,
    },
    validationSchema: Yup.object({
      brandLogo: Yup.mixed()
                    .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
    }),
    onSubmit: values => {
      const data = new FormData();
      data.append('brand_logo', values.brandLogo, values.brandLogo.name);
      dispatch(brandLogoUpload({
        formData: data,
        success: () => {
          dispatch(getCompanyDetails({id: companyId}));
          addToast('Logo uploaded successfully.', {appearance: 'success'});
        },
        failure: (data) => {
          handleErrors(data, formik);
        }
      }));
    },
  });
  return <div className={'flex p-0 mt-1'}>
    <label htmlFor={'brandLogo'} className={`inline-flex
            rounded-l-md rounded-r-md
            px-4 py-2
            transition ease-in-out duration-750
            cursor-pointer
            bg-slate-600 hover:bg-slate-700
            border-slate-900 border-1
            text-neutral-0 hover:text-neutral-0 text-sm text-normal
            centered
            ${(loading || processing) ? 'cursor-wait' : ''}
        `}>
      {hasLogo ? 'Change Logo' : 'Upload logo'}
    </label>
    <input
      type="file"
      hidden
      name="brandLogo"
      id="brandLogo"
      onChange={(event) => {
        formik.setFieldValue('brandLogo', event.currentTarget.files[0]);
        setTimeout(formik.handleSubmit, 0); // hack for formik to trigger submit
      }}
    />
  </div>;
};
