import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBankAccounts, kycSelector} from "../../../slices/kyc";
import {getQuickbooksAccounts, linkQuickbooksAccounts} from "../../../slices/newQuickbooks";
import {SelectExpenseAccount} from "./SelectExpenseAccount";

export const BankAccountMapping = () => {
  const dispatch = useDispatch();
  const {
    loading: loadingKYC,
    bankAccounts: payvyBankAccounts,
  } = useSelector(kycSelector);
  const {
    loading: {
      item: loading,
    },
    bankAccounts
  } = useSelector((state) => state.integrationQuickbooks);
  const onAccountSelection = (bank_id, account_id) => {
    dispatch(linkQuickbooksAccounts({
      body: {
        account_id: account_id || -1,
        bank_id,
      },
      successCallback: () => {
        dispatch(getBankAccounts({
          page: 1,
          page_size: 100,
          active: 'yes',
          type: '-credit_card',
        }));
      }
    }));
  };
  useEffect(() => {
    dispatch(getBankAccounts({
      page: 1,
      page_size: 100,
      active: 'yes',
      type: '-credit_card',
    }));
    dispatch(getQuickbooksAccounts({
      is_expense: false,
      is_bank: true
    }));
  }, [dispatch]);
  return <div className={'flex flex-col bg-neutral-0 shadow-lg px-4 py-2 rounded-xl'}>
    <h1 className={'text-xl font-bold text-center mb-4'}>Bank Account Mapping</h1>
    <small>
      Map the bank accounts you’ve added to Payvy to the accounts you have setup in QuickBooks so we can post your
      payments correctly.
    </small>
    <table className={'table-auto mt-2 drop-shadow-2xl w-full border border-slate-300'}>
      <thead>
      <tr className={'border border-slate-300 text-center bg-slate-50'}>
        <th className={'w-48'}>
          <div className={'flex justify-center py-2 shadow-inner'}>
            Payvy Account
          </div>
        </th>
        <th className={'w-48'}>
          <div className={'flex justify-center py-2 shadow-inner'}>
            QuickBooks Account
          </div>
        </th>
      </tr>
      </thead>
      <tbody className={'bg-neutral-0'}>
      {payvyBankAccounts.map((item, index) => <tr key={index}
                                                  className={'hover:bg-slate-100 hover-border hover:border-slate-300'}>
        <td className={'px-4 py-2'}>
          {item.name}
        </td>
        <td className={'px-4 py-2'}>
          <SelectExpenseAccount
            loading={loading || loadingKYC}
            options={bankAccounts}
            defaultValue={item.quickbooks_id}
            onChange={(option) => onAccountSelection(item.id, option?.value)}
          />
        </td>
      </tr>)}
      </tbody>
    </table>
  </div>;
};
