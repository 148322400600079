import {noop} from 'lodash';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {companiesSelector} from '../../../slices/companies';
import {getContactDetails, sendInvitation} from '../../../slices/newContact';


export const VendorPortalInvite = ({vendor = {}}) => {
  const {
    id,
    invitation: {
      invitation_status: invitationStatus,
      created_at: createdAt,
      updated_at: updatedAt,
    },
  } = vendor;
  const [alreadyClicked, setAlreadyClicked] = React.useState(false);
  const {company: {name: companyName = ''} = {}} = useSelector(companiesSelector);
  const dispatch = useDispatch();
  let invited = false;
  let accepted = false;
  let date = createdAt;
  if(invitationStatus !== null) {
    invited = true;
    if(invitationStatus.toLowerCase() === 'accepted') {
      accepted = true;
      date = updatedAt;
    }
  }
  const dateOfInvitation = new Date(date).toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const is14DaysOld = new Date(date).getTime() < Date.now() - 1000 * 60 * 60 * 24 * 14; // 14 days
  const nextInviteDate = new Date(new Date(date).getTime() + 1000 * 60 * 60 * 24 * 14).toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const canClick = !accepted && ((date && is14DaysOld) || !date) && !alreadyClicked;
  let message = '';
  if(accepted) message = 'Invited.';
  else if(invited && !accepted && is14DaysOld) message = 'Resend portal invite';
  else if(invited && !accepted && !is14DaysOld) message = 'Invitation pending';
  else if(!invited) message = 'Invite to portal';
  const handleSendingInvitation = () => {
    const subject = `${companyName} has set you up for payments, add a payment method`;
    const body = `${companyName} has invited to share your payment details on Payvy to manage their invoices and make faster payments to you`;
    setAlreadyClicked(true);
    dispatch(sendInvitation({
      id,
      body: {
        subject: subject,
        body: body,
      },
      allowProcessing: false,
      successCallback: () => {
        setAlreadyClicked(false);
        dispatch(getContactDetails({id}));
      },
      errorCallback: () => {
        setAlreadyClicked(false);
      },
    }));
  };
  return <div className={'w-full'}>
        <span
          className={`text-xs text-slate-600 ${canClick ? 'cursor-pointer' : null}`}
          onClick={canClick ? handleSendingInvitation : noop}
          title={invited && !accepted && !is14DaysOld ? `Next time you are allowed to resend invitation: ${nextInviteDate}` : null}
        >
            {message}
        </span>
    {accepted ? <span className={'text-xs text-purple-500 ml-10'}>
                Activated {dateOfInvitation}
            </span> : invited ? <span className={'text-xs text-purple-500 ml-10'}>
                    Invited {dateOfInvitation}, not accepted
                </span> : null}
  </div>;
};
