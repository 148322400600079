export const COUNTRY_OPTIONS_WITH_FLAGS = [
    {
        'text': 'Afghanistan',
        'key': 'AF',
        'value': 'AF',
        'flag': 'AF',
    },
    {
        'text': 'Åland Islands',
        'key': 'AX',
        'value': 'AX',
        'flag': 'AX',
    },
    {
        'text': 'Albania',
        'key': 'AL',
        'value': 'AL',
        'flag': 'AL',
    },
    {
        'text': 'Algeria',
        'key': 'DZ',
        'value': 'DZ',
        'flag': 'DZ',
    },
    {
        'text': 'American Samoa',
        'key': 'AS',
        'value': 'AS',
        'flag': 'AS',
    },
    {
        'text': 'Andorra',
        'key': 'AD',
        'value': 'AD',
        'flag': 'AD',
    },
    {
        'text': 'Angola',
        'key': 'AO',
        'value': 'AO',
        'flag': 'AO',
    },
    {
        'text': 'Anguilla',
        'key': 'AI',
        'value': 'AI',
        'flag': 'AI',
    },
    {
        'text': 'Antarctica',
        'key': 'AQ',
        'value': 'AQ',
        'flag': 'AQ',
    },
    {
        'text': 'Antigua and Barbuda',
        'key': 'AG',
        'value': 'AG',
        'flag': 'AG',
    },
    {
        'text': 'Argentina',
        'key': 'AR',
        'value': 'AR',
        'flag': 'AR',
    },
    {
        'text': 'Armenia',
        'key': 'AM',
        'value': 'AM',
        'flag': 'AM',
    },
    {
        'text': 'Aruba',
        'key': 'AW',
        'value': 'AW',
        'flag': 'AW',
    },
    {
        'text': 'Australia',
        'key': 'AU',
        'value': 'AU',
        'flag': 'AU',
    },
    {
        'text': 'Austria',
        'key': 'AT',
        'value': 'AT',
        'flag': 'AT',
    },
    {
        'text': 'Azerbaijan',
        'key': 'AZ',
        'value': 'AZ',
        'flag': 'AZ',
    },
    {
        'text': 'Bahamas',
        'key': 'BS',
        'value': 'BS',
        'flag': 'BS',
    },
    {
        'text': 'Bahrain',
        'key': 'BH',
        'value': 'BH',
        'flag': 'BH',
    },
    {
        'text': 'Bangladesh',
        'key': 'BD',
        'value': 'BD',
        'flag': 'BD',
    },
    {
        'text': 'Barbados',
        'key': 'BB',
        'value': 'BB',
        'flag': 'BB',
    },
    {
        'text': 'Belarus',
        'key': 'BY',
        'value': 'BY',
        'flag': 'BY',
    },
    {
        'text': 'Belgium',
        'key': 'BE',
        'value': 'BE',
        'flag': 'BE',
    },
    {
        'text': 'Belize',
        'key': 'BZ',
        'value': 'BZ',
        'flag': 'BZ',
    },
    {
        'text': 'Benin',
        'key': 'BJ',
        'value': 'BJ',
        'flag': 'BJ',
    },
    {
        'text': 'Bermuda',
        'key': 'BM',
        'value': 'BM',
        'flag': 'BM',
    },
    {
        'text': 'Bhutan',
        'key': 'BT',
        'value': 'BT',
        'flag': 'BT',
    },
    {
        'text': 'Bolivia, Plurinational State of',
        'key': 'BO',
        'value': 'BO',
        'flag': 'BO',
    },
    {
        'text': 'Bonaire, Sint Eustatius and Saba',
        'key': 'BQ',
        'value': 'BQ',
        'flag': 'BQ',
    },
    {
        'text': 'Bosnia and Herzegovina',
        'key': 'BA',
        'value': 'BA',
        'flag': 'BA',
    },
    {
        'text': 'Botswana',
        'key': 'BW',
        'value': 'BW',
        'flag': 'BW',
    },
    {
        'text': 'Bouvet Island',
        'key': 'BV',
        'value': 'BV',
        'flag': 'BV',
    },
    {
        'text': 'Brazil',
        'key': 'BR',
        'value': 'BR',
        'flag': 'BR',
    },
    {
        'text': 'British Indian Ocean Territory',
        'key': 'IO',
        'value': 'IO',
        'flag': 'IO',
    },
    {
        'text': 'Brunei Darussalam',
        'key': 'BN',
        'value': 'BN',
        'flag': 'BN',
    },
    {
        'text': 'Bulgaria',
        'key': 'BG',
        'value': 'BG',
        'flag': 'BG',
    },
    {
        'text': 'Burkina Faso',
        'key': 'BF',
        'value': 'BF',
        'flag': 'BF',
    },
    {
        'text': 'Burundi',
        'key': 'BI',
        'value': 'BI',
        'flag': 'BI',
    },
    {
        'text': 'Cambodia',
        'key': 'KH',
        'value': 'KH',
        'flag': 'KH',
    },
    {
        'text': 'Cameroon',
        'key': 'CM',
        'value': 'CM',
        'flag': 'CM',
    },
    {
        'text': 'Canada',
        'key': 'CA',
        'value': 'CA',
        'flag': 'CA',
    },
    {
        'text': 'Cape Verde',
        'key': 'CV',
        'value': 'CV',
        'flag': 'CV',
    },
    {
        'text': 'Cayman Islands',
        'key': 'KY',
        'value': 'KY',
        'flag': 'KY',
    },
    {
        'text': 'Central African Republic',
        'key': 'CF',
        'value': 'CF',
        'flag': 'CF',
    },
    {
        'text': 'Chad',
        'key': 'TD',
        'value': 'TD',
        'flag': 'TD',
    },
    {
        'text': 'Chile',
        'key': 'CL',
        'value': 'CL',
        'flag': 'CL',
    },
    {
        'text': 'China',
        'key': 'CN',
        'value': 'CN',
        'flag': 'CN',
    },
    {
        'text': 'Christmas Island',
        'key': 'CX',
        'value': 'CX',
        'flag': 'CX',
    },
    {
        'text': 'Cocos (Keeling) Islands',
        'key': 'CC',
        'value': 'CC',
        'flag': 'CC',
    },
    {
        'text': 'Colombia',
        'key': 'CO',
        'value': 'CO',
        'flag': 'CO',
    },
    {
        'text': 'Comoros',
        'key': 'KM',
        'value': 'KM',
        'flag': 'KM',
    },
    {
        'text': 'Congo',
        'key': 'CG',
        'value': 'CG',
        'flag': 'CG',
    },
    {
        'text': 'Congo, the Democratic Republic of the',
        'key': 'CD',
        'value': 'CD',
        'flag': 'CD',
    },
    {
        'text': 'Cook Islands',
        'key': 'CK',
        'value': 'CK',
        'flag': 'CK',
    },
    {
        'text': 'Costa Rica',
        'key': 'CR',
        'value': 'CR',
        'flag': 'CR',
    },
    {
        'text': 'Côte d\'Ivoire',
        'key': 'CI',
        'value': 'CI',
        'flag': 'CI',
    },
    {
        'text': 'Croatia',
        'key': 'HR',
        'value': 'HR',
        'flag': 'HR',
    },
    {
        'text': 'Cuba',
        'key': 'CU',
        'value': 'CU',
        'flag': 'CU',
    },
    {
        'text': 'Curaçao',
        'key': 'CW',
        'value': 'CW',
        'flag': 'CW',
    },
    {
        'text': 'Cyprus',
        'key': 'CY',
        'value': 'CY',
        'flag': 'CY',
    },
    {
        'text': 'Czech Republic',
        'key': 'CZ',
        'value': 'CZ',
        'flag': 'CZ',
    },
    {
        'text': 'Denmark',
        'key': 'DK',
        'value': 'DK',
        'flag': 'DK',
    },
    {
        'text': 'Djibouti',
        'key': 'DJ',
        'value': 'DJ',
        'flag': 'DJ',
    },
    {
        'text': 'Dominica',
        'key': 'DM',
        'value': 'DM',
        'flag': 'DM',
    },
    {
        'text': 'Dominican Republic',
        'key': 'DO',
        'value': 'DO',
        'flag': 'DO',
    },
    {
        'text': 'Ecuador',
        'key': 'EC',
        'value': 'EC',
        'flag': 'EC',
    },
    {
        'text': 'Egypt',
        'key': 'EG',
        'value': 'EG',
        'flag': 'EG',
    },
    {
        'text': 'El Salvador',
        'key': 'SV',
        'value': 'SV',
        'flag': 'SV',
    },
    {
        'text': 'Equatorial Guinea',
        'key': 'GQ',
        'value': 'GQ',
        'flag': 'GQ',
    },
    {
        'text': 'Eritrea',
        'key': 'ER',
        'value': 'ER',
        'flag': 'ER',
    },
    {
        'text': 'Estonia',
        'key': 'EE',
        'value': 'EE',
        'flag': 'EE',
    },
    {
        'text': 'Ethiopia',
        'key': 'ET',
        'value': 'ET',
        'flag': 'ET',
    },
    {
        'text': 'Falkland Islands (Malvinas)',
        'key': 'FK',
        'value': 'FK',
        'flag': 'FK',
    },
    {
        'text': 'Faroe Islands',
        'key': 'FO',
        'value': 'FO',
        'flag': 'FO',
    },
    {
        'text': 'Fiji',
        'key': 'FJ',
        'value': 'FJ',
        'flag': 'FJ',
    },
    {
        'text': 'Finland',
        'key': 'FI',
        'value': 'FI',
        'flag': 'FI',
    },
    {
        'text': 'France',
        'key': 'FR',
        'value': 'FR',
        'flag': 'FR',
    },
    {
        'text': 'French Guiana',
        'key': 'GF',
        'value': 'GF',
        'flag': 'GF',
    },
    {
        'text': 'French Polynesia',
        'key': 'PF',
        'value': 'PF',
        'flag': 'PF',
    },
    {
        'text': 'French Southern Territories',
        'key': 'TF',
        'value': 'TF',
        'flag': 'TF',
    },
    {
        'text': 'Gabon',
        'key': 'GA',
        'value': 'GA',
        'flag': 'GA',
    },
    {
        'text': 'Gambia',
        'key': 'GM',
        'value': 'GM',
        'flag': 'GM',
    },
    {
        'text': 'Georgia',
        'key': 'GE',
        'value': 'GE',
        'flag': 'GE',
    },
    {
        'text': 'Germany',
        'key': 'DE',
        'value': 'DE',
        'flag': 'DE',
    },
    {
        'text': 'Ghana',
        'key': 'GH',
        'value': 'GH',
        'flag': 'GH',
    },
    {
        'text': 'Gibraltar',
        'key': 'GI',
        'value': 'GI',
        'flag': 'GI',
    },
    {
        'text': 'Greece',
        'key': 'GR',
        'value': 'GR',
        'flag': 'GR',
    },
    {
        'text': 'Greenland',
        'key': 'GL',
        'value': 'GL',
        'flag': 'GL',
    },
    {
        'text': 'Grenada',
        'key': 'GD',
        'value': 'GD',
        'flag': 'GD',
    },
    {
        'text': 'Guadeloupe',
        'key': 'GP',
        'value': 'GP',
        'flag': 'GP',
    },
    {
        'text': 'Guam',
        'key': 'GU',
        'value': 'GU',
        'flag': 'GU',
    },
    {
        'text': 'Guatemala',
        'key': 'GT',
        'value': 'GT',
        'flag': 'GT',
    },
    {
        'text': 'Guernsey',
        'key': 'GG',
        'value': 'GG',
        'flag': 'GG',
    },
    {
        'text': 'Guinea',
        'key': 'GN',
        'value': 'GN',
        'flag': 'GN',
    },
    {
        'text': 'Guinea-Bissau',
        'key': 'GW',
        'value': 'GW',
        'flag': 'GW',
    },
    {
        'text': 'Guyana',
        'key': 'GY',
        'value': 'GY',
        'flag': 'GY',
    },
    {
        'text': 'Haiti',
        'key': 'HT',
        'value': 'HT',
        'flag': 'HT',
    },
    {
        'text': 'Heard Island and McDonald Islands',
        'key': 'HM',
        'value': 'HM',
        'flag': 'HM',
    },
    {
        'text': 'Holy See (Vatican City State)',
        'key': 'VA',
        'value': 'VA',
        'flag': 'VA',
    },
    {
        'text': 'Honduras',
        'key': 'HN',
        'value': 'HN',
        'flag': 'HN',
    },
    {
        'text': 'Hong Kong',
        'key': 'HK',
        'value': 'HK',
        'flag': 'HK',
    },
    {
        'text': 'Hungary',
        'key': 'HU',
        'value': 'HU',
        'flag': 'HU',
    },
    {
        'text': 'Iceland',
        'key': 'IS',
        'value': 'IS',
        'flag': 'IS',
    },
    {
        'text': 'India',
        'key': 'IN',
        'value': 'IN',
        'flag': 'IN',
    },
    {
        'text': 'Indonesia',
        'key': 'ID',
        'value': 'ID',
        'flag': 'ID',
    },
    {
        'text': 'Iran, Islamic Republic of',
        'key': 'IR',
        'value': 'IR',
        'flag': 'IR',
    },
    {
        'text': 'Iraq',
        'key': 'IQ',
        'value': 'IQ',
        'flag': 'IQ',
    },
    {
        'text': 'Ireland',
        'key': 'IE',
        'value': 'IE',
        'flag': 'IE',
    },
    {
        'text': 'Isle of Man',
        'key': 'IM',
        'value': 'IM',
        'flag': 'IM',
    },
    {
        'text': 'Israel',
        'key': 'IL',
        'value': 'IL',
        'flag': 'IL',
    },
    {
        'text': 'Italy',
        'key': 'IT',
        'value': 'IT',
        'flag': 'IT',
    },
    {
        'text': 'Jamaica',
        'key': 'JM',
        'value': 'JM',
        'flag': 'JM',
    },
    {
        'text': 'Japan',
        'key': 'JP',
        'value': 'JP',
        'flag': 'JP',
    },
    {
        'text': 'Jersey',
        'key': 'JE',
        'value': 'JE',
        'flag': 'JE',
    },
    {
        'text': 'Jordan',
        'key': 'JO',
        'value': 'JO',
        'flag': 'JO',
    },
    {
        'text': 'Kazakhstan',
        'key': 'KZ',
        'value': 'KZ',
        'flag': 'KZ',
    },
    {
        'text': 'Kenya',
        'key': 'KE',
        'value': 'KE',
        'flag': 'KE',
    },
    {
        'text': 'Kiribati',
        'key': 'KI',
        'value': 'KI',
        'flag': 'KI',
    },
    {
        'text': 'Korea, Democratic People\'s Republic of',
        'key': 'KP',
        'value': 'KP',
        'flag': 'KP',
    },
    {
        'text': 'Korea, Republic of',
        'key': 'KR',
        'value': 'KR',
        'flag': 'KR',
    },
    {
        'text': 'Kuwait',
        'key': 'KW',
        'value': 'KW',
        'flag': 'KW',
    },
    {
        'text': 'Kyrgyzstan',
        'key': 'KG',
        'value': 'KG',
        'flag': 'KG',
    },
    {
        'text': 'Lao People\'s Democratic Republic',
        'key': 'LA',
        'value': 'LA',
        'flag': 'LA',
    },
    {
        'text': 'Latvia',
        'key': 'LV',
        'value': 'LV',
        'flag': 'LV',
    },
    {
        'text': 'Lebanon',
        'key': 'LB',
        'value': 'LB',
        'flag': 'LB',
    },
    {
        'text': 'Lesotho',
        'key': 'LS',
        'value': 'LS',
        'flag': 'LS',
    },
    {
        'text': 'Liberia',
        'key': 'LR',
        'value': 'LR',
        'flag': 'LR',
    },
    {
        'text': 'Libya',
        'key': 'LY',
        'value': 'LY',
        'flag': 'LY',
    },
    {
        'text': 'Liechtenstein',
        'key': 'LI',
        'value': 'LI',
        'flag': 'LI',
    },
    {
        'text': 'Lithuania',
        'key': 'LT',
        'value': 'LT',
        'flag': 'LT',
    },
    {
        'text': 'Luxembourg',
        'key': 'LU',
        'value': 'LU',
        'flag': 'LU',
    },
    {
        'text': 'Macao',
        'key': 'MO',
        'value': 'MO',
        'flag': 'MO',
    },
    {
        'text': 'Macedonia, the Former Yugoslav Republic of',
        'key': 'MK',
        'value': 'MK',
        'flag': 'MK',
    },
    {
        'text': 'Madagascar',
        'key': 'MG',
        'value': 'MG',
        'flag': 'MG',
    },
    {
        'text': 'Malawi',
        'key': 'MW',
        'value': 'MW',
        'flag': 'MW',
    },
    {
        'text': 'Malaysia',
        'key': 'MY',
        'value': 'MY',
        'flag': 'MY',
    },
    {
        'text': 'Maldives',
        'key': 'MV',
        'value': 'MV',
        'flag': 'MV',
    },
    {
        'text': 'Mali',
        'key': 'ML',
        'value': 'ML',
        'flag': 'ML',
    },
    {
        'text': 'Malta',
        'key': 'MT',
        'value': 'MT',
        'flag': 'MT',
    },
    {
        'text': 'Marshall Islands',
        'key': 'MH',
        'value': 'MH',
        'flag': 'MH',
    },
    {
        'text': 'Martinique',
        'key': 'MQ',
        'value': 'MQ',
        'flag': 'MQ',
    },
    {
        'text': 'Mauritania',
        'key': 'MR',
        'value': 'MR',
        'flag': 'MR',
    },
    {
        'text': 'Mauritius',
        'key': 'MU',
        'value': 'MU',
        'flag': 'MU',
    },
    {
        'text': 'Mayotte',
        'key': 'YT',
        'value': 'YT',
        'flag': 'YT',
    },
    {
        'text': 'Mexico',
        'key': 'MX',
        'value': 'MX',
        'flag': 'MX',
    },
    {
        'text': 'Micronesia, Federated States of',
        'key': 'FM',
        'value': 'FM',
        'flag': 'FM',
    },
    {
        'text': 'Moldova, Republic of',
        'key': 'MD',
        'value': 'MD',
        'flag': 'MD',
    },
    {
        'text': 'Monaco',
        'key': 'MC',
        'value': 'MC',
        'flag': 'MC',
    },
    {
        'text': 'Mongolia',
        'key': 'MN',
        'value': 'MN',
        'flag': 'MN',
    },
    {
        'text': 'Montenegro',
        'key': 'ME',
        'value': 'ME',
        'flag': 'ME',
    },
    {
        'text': 'Montserrat',
        'key': 'MS',
        'value': 'MS',
        'flag': 'MS',
    },
    {
        'text': 'Morocco',
        'key': 'MA',
        'value': 'MA',
        'flag': 'MA',
    },
    {
        'text': 'Mozambique',
        'key': 'MZ',
        'value': 'MZ',
        'flag': 'MZ',
    },
    {
        'text': 'Myanmar',
        'key': 'MM',
        'value': 'MM',
        'flag': 'MM',
    },
    {
        'text': 'Namibia',
        'key': 'NA',
        'value': 'NA',
        'flag': 'NA',
    },
    {
        'text': 'Nauru',
        'key': 'NR',
        'value': 'NR',
        'flag': 'NR',
    },
    {
        'text': 'Nepal',
        'key': 'NP',
        'value': 'NP',
        'flag': 'NP',
    },
    {
        'text': 'Netherlands',
        'key': 'NL',
        'value': 'NL',
        'flag': 'NL',
    },
    {
        'text': 'New Caledonia',
        'key': 'NC',
        'value': 'NC',
        'flag': 'NC',
    },
    {
        'text': 'New Zealand',
        'key': 'NZ',
        'value': 'NZ',
        'flag': 'NZ',
    },
    {
        'text': 'Nicaragua',
        'key': 'NI',
        'value': 'NI',
        'flag': 'NI',
    },
    {
        'text': 'Niger',
        'key': 'NE',
        'value': 'NE',
        'flag': 'NE',
    },
    {
        'text': 'Nigeria',
        'key': 'NG',
        'value': 'NG',
        'flag': 'NG',
    },
    {
        'text': 'Niue',
        'key': 'NU',
        'value': 'NU',
        'flag': 'NU',
    },
    {
        'text': 'Norfolk Island',
        'key': 'NF',
        'value': 'NF',
        'flag': 'NF',
    },
    {
        'text': 'Northern Mariana Islands',
        'key': 'MP',
        'value': 'MP',
        'flag': 'MP',
    },
    {
        'text': 'Norway',
        'key': 'NO',
        'value': 'NO',
        'flag': 'NO',
    },
    {
        'text': 'Oman',
        'key': 'OM',
        'value': 'OM',
        'flag': 'OM',
    },
    {
        'text': 'Pakistan',
        'key': 'PK',
        'value': 'PK',
        'flag': 'PK',
    },
    {
        'text': 'Palau',
        'key': 'PW',
        'value': 'PW',
        'flag': 'PW',
    },
    {
        'text': 'Palestine, State of',
        'key': 'PS',
        'value': 'PS',
        'flag': 'PS',
    },
    {
        'text': 'Panama',
        'key': 'PA',
        'value': 'PA',
        'flag': 'PA',
    },
    {
        'text': 'Papua New Guinea',
        'key': 'PG',
        'value': 'PG',
        'flag': 'PG',
    },
    {
        'text': 'Paraguay',
        'key': 'PY',
        'value': 'PY',
        'flag': 'PY',
    },
    {
        'text': 'Peru',
        'key': 'PE',
        'value': 'PE',
        'flag': 'PE',
    },
    {
        'text': 'Philippines',
        'key': 'PH',
        'value': 'PH',
        'flag': 'PH',
    },
    {
        'text': 'Pitcairn',
        'key': 'PN',
        'value': 'PN',
        'flag': 'PN',
    },
    {
        'text': 'Poland',
        'key': 'PL',
        'value': 'PL',
        'flag': 'PL',
    },
    {
        'text': 'Portugal',
        'key': 'PT',
        'value': 'PT',
        'flag': 'PT',
    },
    {
        'text': 'Puerto Rico',
        'key': 'PR',
        'value': 'PR',
        'flag': 'PR',
    },
    {
        'text': 'Qatar',
        'key': 'QA',
        'value': 'QA',
        'flag': 'QA',
    },
    {
        'text': 'Réunion',
        'key': 'RE',
        'value': 'RE',
        'flag': 'RE',
    },
    {
        'text': 'Romania',
        'key': 'RO',
        'value': 'RO',
        'flag': 'RO',
    },
    {
        'text': 'Russian Federation',
        'key': 'RU',
        'value': 'RU',
        'flag': 'RU',
    },
    {
        'text': 'Rwanda',
        'key': 'RW',
        'value': 'RW',
        'flag': 'RW',
    },
    {
        'text': 'Saint Barthélemy',
        'key': 'BL',
        'value': 'BL',
        'flag': 'BL',
    },
    {
        'text': 'Saint Helena, Ascension and Tristan da Cunha',
        'key': 'SH',
        'value': 'SH',
        'flag': 'SH',
    },
    {
        'text': 'Saint Kitts and Nevis',
        'key': 'KN',
        'value': 'KN',
        'flag': 'KN',
    },
    {
        'text': 'Saint Lucia',
        'key': 'LC',
        'value': 'LC',
        'flag': 'LC',
    },
    {
        'text': 'Saint Martin (French part)',
        'key': 'MF',
        'value': 'MF',
        'flag': 'MF',
    },
    {
        'text': 'Saint Pierre and Miquelon',
        'key': 'PM',
        'value': 'PM',
        'flag': 'PM',
    },
    {
        'text': 'Saint Vincent and the Grenadines',
        'key': 'VC',
        'value': 'VC',
        'flag': 'VC',
    },
    {
        'text': 'Samoa',
        'key': 'WS',
        'value': 'WS',
        'flag': 'WS',
    },
    {
        'text': 'San Marino',
        'key': 'SM',
        'value': 'SM',
        'flag': 'SM',
    },
    {
        'text': 'Sao Tome and Principe',
        'key': 'ST',
        'value': 'ST',
        'flag': 'ST',
    },
    {
        'text': 'Saudi Arabia',
        'key': 'SA',
        'value': 'SA',
        'flag': 'SA',
    },
    {
        'text': 'Senegal',
        'key': 'SN',
        'value': 'SN',
        'flag': 'SN',
    },
    {
        'text': 'Serbia',
        'key': 'RS',
        'value': 'RS',
        'flag': 'RS',
    },
    {
        'text': 'Seychelles',
        'key': 'SC',
        'value': 'SC',
        'flag': 'SC',
    },
    {
        'text': 'Sierra Leone',
        'key': 'SL',
        'value': 'SL',
        'flag': 'SL',
    },
    {
        'text': 'Singapore',
        'key': 'SG',
        'value': 'SG',
        'flag': 'SG',
    },
    {
        'text': 'Sint Maarten (Dutch part)',
        'key': 'SX',
        'value': 'SX',
        'flag': 'SX',
    },
    {
        'text': 'Slovakia',
        'key': 'SK',
        'value': 'SK',
        'flag': 'SK',
    },
    {
        'text': 'Slovenia',
        'key': 'SI',
        'value': 'SI',
        'flag': 'SI',
    },
    {
        'text': 'Solomon Islands',
        'key': 'SB',
        'value': 'SB',
        'flag': 'SB',
    },
    {
        'text': 'Somalia',
        'key': 'SO',
        'value': 'SO',
        'flag': 'SO',
    },
    {
        'text': 'South Africa',
        'key': 'ZA',
        'value': 'ZA',
        'flag': 'ZA',
    },
    {
        'text': 'South Georgia and the South Sandwich Islands',
        'key': 'GS',
        'value': 'GS',
        'flag': 'GS',
    },
    {
        'text': 'South Sudan',
        'key': 'SS',
        'value': 'SS',
        'flag': 'SS',
    },
    {
        'text': 'Spain',
        'key': 'ES',
        'value': 'ES',
        'flag': 'ES',
    },
    {
        'text': 'Sri Lanka',
        'key': 'LK',
        'value': 'LK',
        'flag': 'LK',
    },
    {
        'text': 'Sudan',
        'key': 'SD',
        'value': 'SD',
        'flag': 'SD',
    },
    {
        'text': 'Suriname',
        'key': 'SR',
        'value': 'SR',
        'flag': 'SR',
    },
    {
        'text': 'Svalbard and Jan Mayen',
        'key': 'SJ',
        'value': 'SJ',
        'flag': 'SJ',
    },
    {
        'text': 'Swaziland',
        'key': 'SZ',
        'value': 'SZ',
        'flag': 'SZ',
    },
    {
        'text': 'Sweden',
        'key': 'SE',
        'value': 'SE',
        'flag': 'SE',
    },
    {
        'text': 'Switzerland',
        'key': 'CH',
        'value': 'CH',
        'flag': 'CH',
    },
    {
        'text': 'Syrian Arab Republic',
        'key': 'SY',
        'value': 'SY',
        'flag': 'SY',
    },
    {
        'text': 'Taiwan, Province of China',
        'key': 'TW',
        'value': 'TW',
        'flag': 'TW',
    },
    {
        'text': 'Tajikistan',
        'key': 'TJ',
        'value': 'TJ',
        'flag': 'TJ',
    },
    {
        'text': 'Tanzania, United Republic of',
        'key': 'TZ',
        'value': 'TZ',
        'flag': 'TZ',
    },
    {
        'text': 'Thailand',
        'key': 'TH',
        'value': 'TH',
        'flag': 'TH',
    },
    {
        'text': 'Timor-Leste',
        'key': 'TL',
        'value': 'TL',
        'flag': 'TL',
    },
    {
        'text': 'Togo',
        'key': 'TG',
        'value': 'TG',
        'flag': 'TG',
    },
    {
        'text': 'Tokelau',
        'key': 'TK',
        'value': 'TK',
        'flag': 'TK',
    },
    {
        'text': 'Tonga',
        'key': 'TO',
        'value': 'TO',
        'flag': 'TO',
    },
    {
        'text': 'Trinidad and Tobago',
        'key': 'TT',
        'value': 'TT',
        'flag': 'TT',
    },
    {
        'text': 'Tunisia',
        'key': 'TN',
        'value': 'TN',
        'flag': 'TN',
    },
    {
        'text': 'Turkey',
        'key': 'TR',
        'value': 'TR',
        'flag': 'TR',
    },
    {
        'text': 'Turkmenistan',
        'key': 'TM',
        'value': 'TM',
        'flag': 'TM',
    },
    {
        'text': 'Turks and Caicos Islands',
        'key': 'TC',
        'value': 'TC',
        'flag': 'TC',
    },
    {
        'text': 'Tuvalu',
        'key': 'TV',
        'value': 'TV',
        'flag': 'TV',
    },
    {
        'text': 'Uganda',
        'key': 'UG',
        'value': 'UG',
        'flag': 'UG',
    },
    {
        'text': 'Ukraine',
        'key': 'UA',
        'value': 'UA',
        'flag': 'UA',
    },
    {
        'text': 'United Arab Emirates',
        'key': 'AE',
        'value': 'AE',
        'flag': 'AE',
    },
    {
        'text': 'United Kingdom',
        'key': 'GB',
        'value': 'GB',
        'flag': 'GB',
    },
    {
        'text': 'United States',
        'key': 'US',
        'value': 'US',
        'flag': 'US',
    },
    {
        'text': 'United States Minor Outlying Islands',
        'key': 'UM',
        'value': 'UM',
        'flag': 'UM',
    },
    {
        'text': 'Uruguay',
        'key': 'UY',
        'value': 'UY',
        'flag': 'UY',
    },
    {
        'text': 'Uzbekistan',
        'key': 'UZ',
        'value': 'UZ',
        'flag': 'UZ',
    },
    {
        'text': 'Vanuatu',
        'key': 'VU',
        'value': 'VU',
        'flag': 'VU',
    },
    {
        'text': 'Venezuela, Bolivarian Republic of',
        'key': 'VE',
        'value': 'VE',
        'flag': 'VE',
    },
    {
        'text': 'Viet Nam',
        'key': 'VN',
        'value': 'VN',
        'flag': 'VN',
    },
    {
        'text': 'Virgin Islands, British',
        'key': 'VG',
        'value': 'VG',
        'flag': 'VG',
    },
    {
        'text': 'Virgin Islands, U.S.',
        'key': 'VI',
        'value': 'VI',
        'flag': 'VI',
    },
    {
        'text': 'Wallis and Futuna',
        'key': 'WF',
        'value': 'WF',
        'flag': 'WF',
    },
    {
        'text': 'Western Sahara',
        'key': 'EH',
        'value': 'EH',
        'flag': 'EH',
    },
    {
        'text': 'Yemen',
        'key': 'YE',
        'value': 'YE',
        'flag': 'YE',
    },
    {
        'text': 'Zambia',
        'key': 'ZM',
        'value': 'ZM',
        'flag': 'ZM',
    },
    {
        'text': 'Zimbabwe',
        'key': 'ZW',
        'value': 'ZW',
        'flag': 'ZW',
    },
];
export const COUNTRY_OPTIONS = [
    {
        'text': 'Afghanistan',
        'key': 'AF',
        'value': 'AF',
    },
    {
        'text': 'Åland Islands',
        'key': 'AX',
        'value': 'AX',
    },
    {
        'text': 'Albania',
        'key': 'AL',
        'value': 'AL',
    },
    {
        'text': 'Algeria',
        'key': 'DZ',
        'value': 'DZ',
    },
    {
        'text': 'American Samoa',
        'key': 'AS',
        'value': 'AS',
    },
    {
        'text': 'Andorra',
        'key': 'AD',
        'value': 'AD',
    },
    {
        'text': 'Angola',
        'key': 'AO',
        'value': 'AO',
    },
    {
        'text': 'Anguilla',
        'key': 'AI',
        'value': 'AI',
    },
    {
        'text': 'Antarctica',
        'key': 'AQ',
        'value': 'AQ',
    },
    {
        'text': 'Antigua and Barbuda',
        'key': 'AG',
        'value': 'AG',
    },
    {
        'text': 'Argentina',
        'key': 'AR',
        'value': 'AR',
    },
    {
        'text': 'Armenia',
        'key': 'AM',
        'value': 'AM',
    },
    {
        'text': 'Aruba',
        'key': 'AW',
        'value': 'AW',
    },
    {
        'text': 'Australia',
        'key': 'AU',
        'value': 'AU',
    },
    {
        'text': 'Austria',
        'key': 'AT',
        'value': 'AT',
    },
    {
        'text': 'Azerbaijan',
        'key': 'AZ',
        'value': 'AZ',
    },
    {
        'text': 'Bahamas',
        'key': 'BS',
        'value': 'BS',
    },
    {
        'text': 'Bahrain',
        'key': 'BH',
        'value': 'BH',
    },
    {
        'text': 'Bangladesh',
        'key': 'BD',
        'value': 'BD',
    },
    {
        'text': 'Barbados',
        'key': 'BB',
        'value': 'BB',
    },
    {
        'text': 'Belarus',
        'key': 'BY',
        'value': 'BY',
    },
    {
        'text': 'Belgium',
        'key': 'BE',
        'value': 'BE',
    },
    {
        'text': 'Belize',
        'key': 'BZ',
        'value': 'BZ',
    },
    {
        'text': 'Benin',
        'key': 'BJ',
        'value': 'BJ',
    },
    {
        'text': 'Bermuda',
        'key': 'BM',
        'value': 'BM',
    },
    {
        'text': 'Bhutan',
        'key': 'BT',
        'value': 'BT',
    },
    {
        'text': 'Bolivia, Plurinational State of',
        'key': 'BO',
        'value': 'BO',
    },
    {
        'text': 'Bonaire, Sint Eustatius and Saba',
        'key': 'BQ',
        'value': 'BQ',
    },
    {
        'text': 'Bosnia and Herzegovina',
        'key': 'BA',
        'value': 'BA',
    },
    {
        'text': 'Botswana',
        'key': 'BW',
        'value': 'BW',
    },
    {
        'text': 'Bouvet Island',
        'key': 'BV',
        'value': 'BV',
    },
    {
        'text': 'Brazil',
        'key': 'BR',
        'value': 'BR',
    },
    {
        'text': 'British Indian Ocean Territory',
        'key': 'IO',
        'value': 'IO',
    },
    {
        'text': 'Brunei Darussalam',
        'key': 'BN',
        'value': 'BN',
    },
    {
        'text': 'Bulgaria',
        'key': 'BG',
        'value': 'BG',
    },
    {
        'text': 'Burkina Faso',
        'key': 'BF',
        'value': 'BF',
    },
    {
        'text': 'Burundi',
        'key': 'BI',
        'value': 'BI',
    },
    {
        'text': 'Cambodia',
        'key': 'KH',
        'value': 'KH',
    },
    {
        'text': 'Cameroon',
        'key': 'CM',
        'value': 'CM',
    },
    {
        'text': 'Canada',
        'key': 'CA',
        'value': 'CA',
    },
    {
        'text': 'Cape Verde',
        'key': 'CV',
        'value': 'CV',
    },
    {
        'text': 'Cayman Islands',
        'key': 'KY',
        'value': 'KY',
    },
    {
        'text': 'Central African Republic',
        'key': 'CF',
        'value': 'CF',
    },
    {
        'text': 'Chad',
        'key': 'TD',
        'value': 'TD',
    },
    {
        'text': 'Chile',
        'key': 'CL',
        'value': 'CL',
    },
    {
        'text': 'China',
        'key': 'CN',
        'value': 'CN',
    },
    {
        'text': 'Christmas Island',
        'key': 'CX',
        'value': 'CX',
    },
    {
        'text': 'Cocos (Keeling) Islands',
        'key': 'CC',
        'value': 'CC',
    },
    {
        'text': 'Colombia',
        'key': 'CO',
        'value': 'CO',
    },
    {
        'text': 'Comoros',
        'key': 'KM',
        'value': 'KM',
    },
    {
        'text': 'Congo',
        'key': 'CG',
        'value': 'CG',
    },
    {
        'text': 'Congo, the Democratic Republic of the',
        'key': 'CD',
        'value': 'CD',
    },
    {
        'text': 'Cook Islands',
        'key': 'CK',
        'value': 'CK',
    },
    {
        'text': 'Costa Rica',
        'key': 'CR',
        'value': 'CR',
    },
    {
        'text': 'Côte d\'Ivoire',
        'key': 'CI',
        'value': 'CI',
    },
    {
        'text': 'Croatia',
        'key': 'HR',
        'value': 'HR',
    },
    {
        'text': 'Cuba',
        'key': 'CU',
        'value': 'CU',
    },
    {
        'text': 'Curaçao',
        'key': 'CW',
        'value': 'CW',
    },
    {
        'text': 'Cyprus',
        'key': 'CY',
        'value': 'CY',
    },
    {
        'text': 'Czech Republic',
        'key': 'CZ',
        'value': 'CZ',
    },
    {
        'text': 'Denmark',
        'key': 'DK',
        'value': 'DK',
    },
    {
        'text': 'Djibouti',
        'key': 'DJ',
        'value': 'DJ',
    },
    {
        'text': 'Dominica',
        'key': 'DM',
        'value': 'DM',
    },
    {
        'text': 'Dominican Republic',
        'key': 'DO',
        'value': 'DO',
    },
    {
        'text': 'Ecuador',
        'key': 'EC',
        'value': 'EC',
    },
    {
        'text': 'Egypt',
        'key': 'EG',
        'value': 'EG',
    },
    {
        'text': 'El Salvador',
        'key': 'SV',
        'value': 'SV',
    },
    {
        'text': 'Equatorial Guinea',
        'key': 'GQ',
        'value': 'GQ',
    },
    {
        'text': 'Eritrea',
        'key': 'ER',
        'value': 'ER',
    },
    {
        'text': 'Estonia',
        'key': 'EE',
        'value': 'EE',
    },
    {
        'text': 'Ethiopia',
        'key': 'ET',
        'value': 'ET',
    },
    {
        'text': 'Falkland Islands (Malvinas)',
        'key': 'FK',
        'value': 'FK',
    },
    {
        'text': 'Faroe Islands',
        'key': 'FO',
        'value': 'FO',
    },
    {
        'text': 'Fiji',
        'key': 'FJ',
        'value': 'FJ',
    },
    {
        'text': 'Finland',
        'key': 'FI',
        'value': 'FI',
    },
    {
        'text': 'France',
        'key': 'FR',
        'value': 'FR',
    },
    {
        'text': 'French Guiana',
        'key': 'GF',
        'value': 'GF',
    },
    {
        'text': 'French Polynesia',
        'key': 'PF',
        'value': 'PF',
    },
    {
        'text': 'French Southern Territories',
        'key': 'TF',
        'value': 'TF',
    },
    {
        'text': 'Gabon',
        'key': 'GA',
        'value': 'GA',
    },
    {
        'text': 'Gambia',
        'key': 'GM',
        'value': 'GM',
    },
    {
        'text': 'Georgia',
        'key': 'GE',
        'value': 'GE',
    },
    {
        'text': 'Germany',
        'key': 'DE',
        'value': 'DE',
    },
    {
        'text': 'Ghana',
        'key': 'GH',
        'value': 'GH',
    },
    {
        'text': 'Gibraltar',
        'key': 'GI',
        'value': 'GI',
    },
    {
        'text': 'Greece',
        'key': 'GR',
        'value': 'GR',
    },
    {
        'text': 'Greenland',
        'key': 'GL',
        'value': 'GL',
    },
    {
        'text': 'Grenada',
        'key': 'GD',
        'value': 'GD',
    },
    {
        'text': 'Guadeloupe',
        'key': 'GP',
        'value': 'GP',
    },
    {
        'text': 'Guam',
        'key': 'GU',
        'value': 'GU',
    },
    {
        'text': 'Guatemala',
        'key': 'GT',
        'value': 'GT',
    },
    {
        'text': 'Guernsey',
        'key': 'GG',
        'value': 'GG',
    },
    {
        'text': 'Guinea',
        'key': 'GN',
        'value': 'GN',
    },
    {
        'text': 'Guinea-Bissau',
        'key': 'GW',
        'value': 'GW',
    },
    {
        'text': 'Guyana',
        'key': 'GY',
        'value': 'GY',
    },
    {
        'text': 'Haiti',
        'key': 'HT',
        'value': 'HT',
    },
    {
        'text': 'Heard Island and McDonald Islands',
        'key': 'HM',
        'value': 'HM',
    },
    {
        'text': 'Holy See (Vatican City State)',
        'key': 'VA',
        'value': 'VA',
    },
    {
        'text': 'Honduras',
        'key': 'HN',
        'value': 'HN',
    },
    {
        'text': 'Hong Kong',
        'key': 'HK',
        'value': 'HK',
    },
    {
        'text': 'Hungary',
        'key': 'HU',
        'value': 'HU',
    },
    {
        'text': 'Iceland',
        'key': 'IS',
        'value': 'IS',
    },
    {
        'text': 'India',
        'key': 'IN',
        'value': 'IN',
    },
    {
        'text': 'Indonesia',
        'key': 'ID',
        'value': 'ID',
    },
    {
        'text': 'Iran, Islamic Republic of',
        'key': 'IR',
        'value': 'IR',
    },
    {
        'text': 'Iraq',
        'key': 'IQ',
        'value': 'IQ',
    },
    {
        'text': 'Ireland',
        'key': 'IE',
        'value': 'IE',
    },
    {
        'text': 'Isle of Man',
        'key': 'IM',
        'value': 'IM',
    },
    {
        'text': 'Israel',
        'key': 'IL',
        'value': 'IL',
    },
    {
        'text': 'Italy',
        'key': 'IT',
        'value': 'IT',
    },
    {
        'text': 'Jamaica',
        'key': 'JM',
        'value': 'JM',
    },
    {
        'text': 'Japan',
        'key': 'JP',
        'value': 'JP',
    },
    {
        'text': 'Jersey',
        'key': 'JE',
        'value': 'JE',
    },
    {
        'text': 'Jordan',
        'key': 'JO',
        'value': 'JO',
    },
    {
        'text': 'Kazakhstan',
        'key': 'KZ',
        'value': 'KZ',
    },
    {
        'text': 'Kenya',
        'key': 'KE',
        'value': 'KE',
    },
    {
        'text': 'Kiribati',
        'key': 'KI',
        'value': 'KI',
    },
    {
        'text': 'Korea, Democratic People\'s Republic of',
        'key': 'KP',
        'value': 'KP',
    },
    {
        'text': 'Korea, Republic of',
        'key': 'KR',
        'value': 'KR',
    },
    {
        'text': 'Kuwait',
        'key': 'KW',
        'value': 'KW',
    },
    {
        'text': 'Kyrgyzstan',
        'key': 'KG',
        'value': 'KG',
    },
    {
        'text': 'Lao People\'s Democratic Republic',
        'key': 'LA',
        'value': 'LA',
    },
    {
        'text': 'Latvia',
        'key': 'LV',
        'value': 'LV',
    },
    {
        'text': 'Lebanon',
        'key': 'LB',
        'value': 'LB',
    },
    {
        'text': 'Lesotho',
        'key': 'LS',
        'value': 'LS',
    },
    {
        'text': 'Liberia',
        'key': 'LR',
        'value': 'LR',
    },
    {
        'text': 'Libya',
        'key': 'LY',
        'value': 'LY',
    },
    {
        'text': 'Liechtenstein',
        'key': 'LI',
        'value': 'LI',
    },
    {
        'text': 'Lithuania',
        'key': 'LT',
        'value': 'LT',
    },
    {
        'text': 'Luxembourg',
        'key': 'LU',
        'value': 'LU',
    },
    {
        'text': 'Macao',
        'key': 'MO',
        'value': 'MO',
    },
    {
        'text': 'Macedonia, the Former Yugoslav Republic of',
        'key': 'MK',
        'value': 'MK',
    },
    {
        'text': 'Madagascar',
        'key': 'MG',
        'value': 'MG',
    },
    {
        'text': 'Malawi',
        'key': 'MW',
        'value': 'MW',
    },
    {
        'text': 'Malaysia',
        'key': 'MY',
        'value': 'MY',
    },
    {
        'text': 'Maldives',
        'key': 'MV',
        'value': 'MV',
    },
    {
        'text': 'Mali',
        'key': 'ML',
        'value': 'ML',
    },
    {
        'text': 'Malta',
        'key': 'MT',
        'value': 'MT',
    },
    {
        'text': 'Marshall Islands',
        'key': 'MH',
        'value': 'MH',
    },
    {
        'text': 'Martinique',
        'key': 'MQ',
        'value': 'MQ',
    },
    {
        'text': 'Mauritania',
        'key': 'MR',
        'value': 'MR',
    },
    {
        'text': 'Mauritius',
        'key': 'MU',
        'value': 'MU',
    },
    {
        'text': 'Mayotte',
        'key': 'YT',
        'value': 'YT',
    },
    {
        'text': 'Mexico',
        'key': 'MX',
        'value': 'MX',
    },
    {
        'text': 'Micronesia, Federated States of',
        'key': 'FM',
        'value': 'FM',
    },
    {
        'text': 'Moldova, Republic of',
        'key': 'MD',
        'value': 'MD',
    },
    {
        'text': 'Monaco',
        'key': 'MC',
        'value': 'MC',
    },
    {
        'text': 'Mongolia',
        'key': 'MN',
        'value': 'MN',
    },
    {
        'text': 'Montenegro',
        'key': 'ME',
        'value': 'ME',
    },
    {
        'text': 'Montserrat',
        'key': 'MS',
        'value': 'MS',
    },
    {
        'text': 'Morocco',
        'key': 'MA',
        'value': 'MA',
    },
    {
        'text': 'Mozambique',
        'key': 'MZ',
        'value': 'MZ',
    },
    {
        'text': 'Myanmar',
        'key': 'MM',
        'value': 'MM',
    },
    {
        'text': 'Namibia',
        'key': 'NA',
        'value': 'NA',
    },
    {
        'text': 'Nauru',
        'key': 'NR',
        'value': 'NR',
    },
    {
        'text': 'Nepal',
        'key': 'NP',
        'value': 'NP',
    },
    {
        'text': 'Netherlands',
        'key': 'NL',
        'value': 'NL',
    },
    {
        'text': 'New Caledonia',
        'key': 'NC',
        'value': 'NC',
    },
    {
        'text': 'New Zealand',
        'key': 'NZ',
        'value': 'NZ',
    },
    {
        'text': 'Nicaragua',
        'key': 'NI',
        'value': 'NI',
    },
    {
        'text': 'Niger',
        'key': 'NE',
        'value': 'NE',
    },
    {
        'text': 'Nigeria',
        'key': 'NG',
        'value': 'NG',
    },
    {
        'text': 'Niue',
        'key': 'NU',
        'value': 'NU',
    },
    {
        'text': 'Norfolk Island',
        'key': 'NF',
        'value': 'NF',
    },
    {
        'text': 'Northern Mariana Islands',
        'key': 'MP',
        'value': 'MP',
    },
    {
        'text': 'Norway',
        'key': 'NO',
        'value': 'NO',
    },
    {
        'text': 'Oman',
        'key': 'OM',
        'value': 'OM',
    },
    {
        'text': 'Pakistan',
        'key': 'PK',
        'value': 'PK',
    },
    {
        'text': 'Palau',
        'key': 'PW',
        'value': 'PW',
    },
    {
        'text': 'Palestine, State of',
        'key': 'PS',
        'value': 'PS',
    },
    {
        'text': 'Panama',
        'key': 'PA',
        'value': 'PA',
    },
    {
        'text': 'Papua New Guinea',
        'key': 'PG',
        'value': 'PG',
    },
    {
        'text': 'Paraguay',
        'key': 'PY',
        'value': 'PY',
    },
    {
        'text': 'Peru',
        'key': 'PE',
        'value': 'PE',
    },
    {
        'text': 'Philippines',
        'key': 'PH',
        'value': 'PH',
    },
    {
        'text': 'Pitcairn',
        'key': 'PN',
        'value': 'PN',
    },
    {
        'text': 'Poland',
        'key': 'PL',
        'value': 'PL',
    },
    {
        'text': 'Portugal',
        'key': 'PT',
        'value': 'PT',
    },
    {
        'text': 'Puerto Rico',
        'key': 'PR',
        'value': 'PR',
    },
    {
        'text': 'Qatar',
        'key': 'QA',
        'value': 'QA',
    },
    {
        'text': 'Réunion',
        'key': 'RE',
        'value': 'RE',
    },
    {
        'text': 'Romania',
        'key': 'RO',
        'value': 'RO',
    },
    {
        'text': 'Russian Federation',
        'key': 'RU',
        'value': 'RU',
    },
    {
        'text': 'Rwanda',
        'key': 'RW',
        'value': 'RW',
    },
    {
        'text': 'Saint Barthélemy',
        'key': 'BL',
        'value': 'BL',
    },
    {
        'text': 'Saint Helena, Ascension and Tristan da Cunha',
        'key': 'SH',
        'value': 'SH',
    },
    {
        'text': 'Saint Kitts and Nevis',
        'key': 'KN',
        'value': 'KN',
    },
    {
        'text': 'Saint Lucia',
        'key': 'LC',
        'value': 'LC',
    },
    {
        'text': 'Saint Martin (French part)',
        'key': 'MF',
        'value': 'MF',
    },
    {
        'text': 'Saint Pierre and Miquelon',
        'key': 'PM',
        'value': 'PM',
    },
    {
        'text': 'Saint Vincent and the Grenadines',
        'key': 'VC',
        'value': 'VC',
    },
    {
        'text': 'Samoa',
        'key': 'WS',
        'value': 'WS',
    },
    {
        'text': 'San Marino',
        'key': 'SM',
        'value': 'SM',
    },
    {
        'text': 'Sao Tome and Principe',
        'key': 'ST',
        'value': 'ST',
    },
    {
        'text': 'Saudi Arabia',
        'key': 'SA',
        'value': 'SA',
    },
    {
        'text': 'Senegal',
        'key': 'SN',
        'value': 'SN',
    },
    {
        'text': 'Serbia',
        'key': 'RS',
        'value': 'RS',
    },
    {
        'text': 'Seychelles',
        'key': 'SC',
        'value': 'SC',
    },
    {
        'text': 'Sierra Leone',
        'key': 'SL',
        'value': 'SL',
    },
    {
        'text': 'Singapore',
        'key': 'SG',
        'value': 'SG',
    },
    {
        'text': 'Sint Maarten (Dutch part)',
        'key': 'SX',
        'value': 'SX',
    },
    {
        'text': 'Slovakia',
        'key': 'SK',
        'value': 'SK',
    },
    {
        'text': 'Slovenia',
        'key': 'SI',
        'value': 'SI',
    },
    {
        'text': 'Solomon Islands',
        'key': 'SB',
        'value': 'SB',
    },
    {
        'text': 'Somalia',
        'key': 'SO',
        'value': 'SO',
    },
    {
        'text': 'South Africa',
        'key': 'ZA',
        'value': 'ZA',
    },
    {
        'text': 'South Georgia and the South Sandwich Islands',
        'key': 'GS',
        'value': 'GS',
    },
    {
        'text': 'South Sudan',
        'key': 'SS',
        'value': 'SS',
    },
    {
        'text': 'Spain',
        'key': 'ES',
        'value': 'ES',
    },
    {
        'text': 'Sri Lanka',
        'key': 'LK',
        'value': 'LK',
    },
    {
        'text': 'Sudan',
        'key': 'SD',
        'value': 'SD',
    },
    {
        'text': 'Suriname',
        'key': 'SR',
        'value': 'SR',
    },
    {
        'text': 'Svalbard and Jan Mayen',
        'key': 'SJ',
        'value': 'SJ',
    },
    {
        'text': 'Swaziland',
        'key': 'SZ',
        'value': 'SZ',
    },
    {
        'text': 'Sweden',
        'key': 'SE',
        'value': 'SE',
    },
    {
        'text': 'Switzerland',
        'key': 'CH',
        'value': 'CH',
    },
    {
        'text': 'Syrian Arab Republic',
        'key': 'SY',
        'value': 'SY',
    },
    {
        'text': 'Taiwan, Province of China',
        'key': 'TW',
        'value': 'TW',
    },
    {
        'text': 'Tajikistan',
        'key': 'TJ',
        'value': 'TJ',
    },
    {
        'text': 'Tanzania, United Republic of',
        'key': 'TZ',
        'value': 'TZ',
    },
    {
        'text': 'Thailand',
        'key': 'TH',
        'value': 'TH',
    },
    {
        'text': 'Timor-Leste',
        'key': 'TL',
        'value': 'TL',
    },
    {
        'text': 'Togo',
        'key': 'TG',
        'value': 'TG',
    },
    {
        'text': 'Tokelau',
        'key': 'TK',
        'value': 'TK',
    },
    {
        'text': 'Tonga',
        'key': 'TO',
        'value': 'TO',
    },
    {
        'text': 'Trinidad and Tobago',
        'key': 'TT',
        'value': 'TT',
    },
    {
        'text': 'Tunisia',
        'key': 'TN',
        'value': 'TN',
    },
    {
        'text': 'Turkey',
        'key': 'TR',
        'value': 'TR',
    },
    {
        'text': 'Turkmenistan',
        'key': 'TM',
        'value': 'TM',
    },
    {
        'text': 'Turks and Caicos Islands',
        'key': 'TC',
        'value': 'TC',
    },
    {
        'text': 'Tuvalu',
        'key': 'TV',
        'value': 'TV',
    },
    {
        'text': 'Uganda',
        'key': 'UG',
        'value': 'UG',
    },
    {
        'text': 'Ukraine',
        'key': 'UA',
        'value': 'UA',
    },
    {
        'text': 'United Arab Emirates',
        'key': 'AE',
        'value': 'AE',
    },
    {
        'text': 'United Kingdom',
        'key': 'GB',
        'value': 'GB',
    },
    {
        'text': 'United States',
        'key': 'US',
        'value': 'US',
    },
    {
        'text': 'United States Minor Outlying Islands',
        'key': 'UM',
        'value': 'UM',
    },
    {
        'text': 'Uruguay',
        'key': 'UY',
        'value': 'UY',
    },
    {
        'text': 'Uzbekistan',
        'key': 'UZ',
        'value': 'UZ',
    },
    {
        'text': 'Vanuatu',
        'key': 'VU',
        'value': 'VU',
    },
    {
        'text': 'Venezuela, Bolivarian Republic of',
        'key': 'VE',
        'value': 'VE',
    },
    {
        'text': 'Viet Nam',
        'key': 'VN',
        'value': 'VN',
    },
    {
        'text': 'Virgin Islands, British',
        'key': 'VG',
        'value': 'VG',
    },
    {
        'text': 'Virgin Islands, U.S.',
        'key': 'VI',
        'value': 'VI',
    },
    {
        'text': 'Wallis and Futuna',
        'key': 'WF',
        'value': 'WF',
    },
    {
        'text': 'Western Sahara',
        'key': 'EH',
        'value': 'EH',
    },
    {
        'text': 'Yemen',
        'key': 'YE',
        'value': 'YE',
    },
    {
        'text': 'Zambia',
        'key': 'ZM',
        'value': 'ZM',
    },
    {
        'text': 'Zimbabwe',
        'key': 'ZW',
        'value': 'ZW',
    },
];

// replace text with label
export const COUNTRY_OPTIONS_WITH_LABEL = COUNTRY_OPTIONS.map(country => {
    return {
        key: country.key,
        label: country.text,
        value: country.key,
    };
});
