import React from 'react';

export const ModuleBlock = ({
  children,
  small = false,
  big = false,
  title = 'PAYVY BLOCK MODULE',
  buttons = [],
}) => {
  let size = `col-span-2`;
  if(small) size = 'col-span-1';
  if(big) size = 'col-span-3';
  return <div className={`${size} bg-neutral-0 px-6 pt-2 pb-6 rounded-lg shadow-lg`}>
    <div className={'grid grid-cols-3 mb-2'}>
      <h1 className={'col-span-2 text-neutral-700 text-xl font-bold'}>{title}</h1>
      <div className={'justify-self-end uppercase self-auto'}>
        {buttons.map((button, index) => <button
          onClick={button.onClick}
          disabled={button.disabled}
          className={'text-slate-700 cursor-pointer text-xs ml-3 background-transparent outline-none focus:outline-none ease-linear transition-all duration-150'}
          key={index}
        >
          {button.label}
        </button>)}
      </div>
    </div>
    {children}
  </div>;
};
