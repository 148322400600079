import {Form, Formik} from "formik";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import {PayvyIconButton, PayvyInput, PayvyLabeledInput, PayvyTextArea} from "../../../comps/forms";
import {
  BUSINESS_TYPES,
  COMPANY_BUSINESS_STRUCTURES,
  FORM_ERROR_MESSAGES,
  GOVERNMENT_ENTITY_BUSINESS_STRUCTURES,
  MERCHANT_CATEGORY_CODES,
  NON_PROFIT_BUSINESS_STRUCTURES,
  PAYVY_URL
} from "../../../constants";
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector, createCompany, getUserCompanies} from "../../../slices/companies";
import {dwollaSelector, getDwollaClassifications} from "../../../slices/dwolla";
import {getUser, userSelector} from "../../../slices/user";
import {build_error_message, handleErrors} from "../../../utils/Utility";

export const FormCompanyCreation = () => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const navigate = useNavigate();
  const {processingCompanyCreation: loading} = useSelector(companiesSelector);
  const {
    user: {
      first_name: firstName,
      last_name: lastName,
    },
  } = useSelector(userSelector);
  const {
    loading: dwollaLoading,
    classifications
  } = useSelector(dwollaSelector);
  const [businessStructureDisabled, setBusinessStructureDisabled] = useState(true);
  const [businessStructureOptions, setBusinessStructureOptions] = useState(COMPANY_BUSINESS_STRUCTURES);
  const [displayDescription, setDisplayDescription] = useState(false);
  useEffect(() => {
    if(classifications.length === 0 && !dwollaLoading) dispatch(getDwollaClassifications());
  }, [dispatch, classifications, dwollaLoading]);

  return <div>
    <h1 className={'text-2xl text-center font-bold text-red-300'}>Create a company</h1>
    <Formik
      innerRef={formikRef}
      initialValues={{
        businessType: '',
        entityName: '',
        payvyAddress: '',
        businessStructure: '',
        industry: '',
        website: '',
        description: '',
        businessClassification: '',
      }}
      validationSchema={Yup.object({
        businessType: Yup.object()
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        entityName: Yup.string()
                       .max(100, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 100}))
                       .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        payvyAddress: Yup.string()
                         .max(100, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 100}))
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        businessStructure: Yup.object()
                              .when(['businessType'], {
                                is: (businessType) => businessType?.value !== 'individual',
                                then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                              }),
        industry: Yup.object({
                       value: Yup.string()
                                 .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                       key: Yup.string(),
                     })
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        businessClassification: Yup.object()
                                   .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        website: Yup.string()
                    .max(200, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 200}))
                    .when([], {
                      is: () => !displayDescription,
                      then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                    }),
        description: Yup.string()
                        .when([], {
                          is: () => displayDescription,
                          then: s => s.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
                        }),
      })}
      onSubmit={(values, helpers) => {
        dispatch(createCompany({
          business_type: values.businessType?.value,
          name: values.entityName,
          payvy_address: values.payvyAddress,
          structure: values.businessStructure?.value,
          industry: values.industry?.value,
          business_classification: values.businessClassification?.value,
          website: values.website,
          description: values.description,
          success: () => {
            dispatch(getUserCompanies(() => {
              dispatch(getUser(() => {
                navigate(PAYVY_URL.DASHBOARD);
              }));
            }));
          },
          failure: (data) => {
            handleErrors(data, helpers);
          },
        }));
      }}
    >
      {props => (<Form>
        <PayvyLabeledInput
          label={'Business type'}
          name={'businessType'}
        >
          <Select
            className={'w-full'}
            value={props.values.businessType}
            placeholder={'Select business type'}
            options={BUSINESS_TYPES}
            onChange={(option) => {
              const {value} = option;
              props.setFieldValue('businessType', option);
              props.setFieldValue('businessStructure', '');
              if(value === 'individual') {
                setBusinessStructureDisabled(true);
                if(props.values.entityName === '') {
                  props.setFieldValue('entityName', `${firstName} ${lastName}`);
                }
              } else {
                if(value === 'company') {
                  setBusinessStructureOptions(COMPANY_BUSINESS_STRUCTURES);
                }
                if(value === 'non_profit') {
                  setBusinessStructureOptions(NON_PROFIT_BUSINESS_STRUCTURES);
                }
                if(value === 'government_entity') {
                  setBusinessStructureOptions(GOVERNMENT_ENTITY_BUSINESS_STRUCTURES);
                }
                setBusinessStructureDisabled(false);
                if(props.values.entityName === `${firstName} ${lastName}`) {
                  props.setFieldValue('entityName', '');
                }
              }
            }}
          />
        </PayvyLabeledInput>
        <PayvyLabeledInput
          label={'Entity name'}
          as={PayvyInput}
          name={'entityName'}
          placeholder={'Entity name'}
        />
        <PayvyLabeledInput
          label={'Payvy address'}
          as={PayvyInput}
          name={'payvyAddress'}
          placeholder={'Payvy address'}
          appendRight={'@payvy.com'}
        />
        <PayvyLabeledInput
          label={'Business Structure'}
          name={'businessStructure'}
        >
          <Select
            className={'w-full'}
            isDisabled={businessStructureDisabled}
            value={props.values.businessStructure}
            placeholder={'Select business structure'}
            options={businessStructureOptions}
            onChange={option => props.setFieldValue('businessStructure', option)}
          />
        </PayvyLabeledInput>
        <PayvyLabeledInput
          label={'Industry'}
          name={'industry'}
        >
          <Select
            className={'w-full'}
            search
            value={props.values.industry}
            placeholder={'Select industry'}
            options={MERCHANT_CATEGORY_CODES}
            onChange={option => props.setFieldValue('industry', option)}
          />
        </PayvyLabeledInput>
        <PayvyLabeledInput
          label={'IRS Business Classification'}
          name={'businessClassification'}
        >
          <Select
            className={'w-full'}
            search
            value={props.values.businessClassification}
            placeholder={'Select classification'}
            options={classifications}
            onChange={option => props.setFieldValue('businessClassification', option)}
          />
        </PayvyLabeledInput>
        {!displayDescription ? <>
          <PayvyLabeledInput
            label={'Website'}
            as={PayvyInput}
            name={'website'}
            placeholder={'Website'}
          />
          <span className={'text-xs text-red-300 cursor-pointer'} onClick={() => setDisplayDescription(true)}>I don't have a website</span>
        </> : <>
          <PayvyLabeledInput
            label={'Description'}
            as={PayvyTextArea}
            name={'description'}
            placeholder={'Description'}
          />
          <span className={'text-xs text-red-300 cursor-pointer'}
                onClick={() => setDisplayDescription(false)}>I have a website</span>
        </>}
        <div className={'flex justify-center'}>
          <PayvyIconButton
            loading={loading}
            disabled={loading}
            type={'submit'}
            buttonText={'Create Company'}
          />
        </div>
      </Form>)}
    </Formik>
  </div>;
};
