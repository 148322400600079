import authReducer from './auth';
import companiesReducer from './companies';
import dwollaReducer from './dwolla';
import finixReducer from './finix';
import kycReducer from './kyc';
import billReducer from './newBill';
import boxReducer from './newBox';
import contactReducer from './newContact';
import dropboxReducer from './newDropbox';
import inboxReducer from "./newInbox";
import integrationReducer from './newIntegration';
import quickbooksReducer from './newQuickbooks';
import transactionReducer from './newTransaction';
import ocrReducer from './ocr';
import paymentSystemReducer from './paymentSystem';
import payvygptReducer from './payvygpt';
import plaidReducer from './plaid';
import receivablesReducer from './receivables';
import twilioReducer from './twilio';
import userReducer from './user';

const rootReducer = {
  auth: authReducer,
  user: userReducer,
  companies: companiesReducer,
  inbox: inboxReducer,
  integration: integrationReducer,
  integrationBox: boxReducer,
  integrationDropbox: dropboxReducer,
  integrationQuickbooks: quickbooksReducer,
  contact: contactReducer,
  ocr: ocrReducer,
  bill: billReducer,
  kyc: kycReducer,
  plaid: plaidReducer,
  transactions: transactionReducer,
  twilio: twilioReducer,
  dwolla: dwollaReducer,
  finix: finixReducer,
  receivables: receivablesReducer,
  paymentSystem: paymentSystemReducer,
  payvygpt: payvygptReducer,
};

export default rootReducer;
