export const WaitMessage = ({ hours, minutes, seconds, prefix = 'Wait ' }) => {
    if (hours < 0) hours = 0;
    if (minutes < 0) minutes = 0;
    if (seconds < 0) seconds = 0;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;
    hours = hours === '00' ? '' : `${hours}:`;
    minutes = minutes === '00' && hours === '' ? '' : `${minutes}:`;
    return `${prefix}${hours}${minutes}${seconds} seconds`;
};