import React from "react";
import {RepresentativeBadge} from "./RepresentativeBadge";

export const RepresentativeName = ({
  name,
  status,
  problems,
  dwollaStatus,
  dwollaError,
}) => {
  const hasIssues = Object.keys(problems).length > 0;
  return <span className="flex flex-row">
        {name}
    <span className={'pl-1'}>
            <RepresentativeBadge
              status={status}
              hasIssues={hasIssues}
              problems={problems}
              dwollaStatus={dwollaStatus || 'Unverified'}
              dwollaError={dwollaError}
            />
        </span>
    </span>;
};
