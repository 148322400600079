import {Form, Formik} from 'formik';
import moment from 'moment';
import React, {useEffect, useRef, useState} from 'react';
import {NumericFormat} from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {BigCenteredLoadingBlock, OpenBillsOfContact, SuggestionBolt} from '../../../comps/elements';
import {
  GlobalFormError,
  PayvyDatePicker,
  PayvyIconButton,
  PayvyInput,
  PayvyLabeledInput,
  SelectAccountingCategory,
  SelectAccountingClass,
  SelectApproval
} from '../../../comps/forms';
import {FORM_ERROR_MESSAGES, PAYVY_LOGOS} from '../../../constants';
import useFormikHotkeys from "../../../hooks/useFormikHotkeys";
import {companiesSelector} from '../../../slices/companies';
import {getBill, getUserBills, updatePageInformation} from "../../../slices/newBill";
import {getUserInboxItem} from '../../../slices/newInbox';
import {getOCR, ocrSelector, resetOCR} from '../../../slices/ocr';
import {build_error_message, formatDateForAPItoYMD, handleErrors, payvyToast} from '../../../utils/Utility';
import PaymentsForContact from './PaymentsForContact';


const Information = ({
  bill,
  currentPage,
  setCurrentPage,
  billPermission = {},
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  useFormikHotkeys(formikRef);
  const {
    items,
    loading: {
      item: inboxLoading,
      list: inboxListLoading,
      posting: inboxProcessing
    },
  } = useSelector((state) => state.inbox);
  const [inboxItem, setInboxItem] = useState({});
  const {
    loading: {
      item: billLoading,
      list: billListLoading,
      posting: billProcessing
    }
  } = useSelector((state) => state.bill);
  const {company: {has_qb_integration: hasQBIntegration}} = useSelector(companiesSelector);
  const {
    loading: OCRLoading,
    suggestions = {},
  } = useSelector(ocrSelector);
  const {
    id: billId,
    approvers: billApprovers,
    amount: billTotal,
    name: billName,
    contact: billContact,
    due_date: bill_due_date,
    created: bill_date,
    pages = {},
  } = bill;
  const {
    pages: inboxPages,
  } = inboxItem;
  const {
    invoice_number: ocrInvoiceNumber,
    invoice_amount: ocrAmount,
    invoice_date: ocrInvoiceDate,
    payment_due_date: ocrPaymentDueDate,
    tax: ocrTax,
    subtotal: ocrSubtotal,
  } = suggestions || {};
  const {
    id: pageId,
    total_amount: billTotalAmount,
    invoice_number: billInvoiceNumber,
    inbox_item,
    quickbooks_account,
    quickbooks_class,
    allow_ledger_sync: allowLedgerSync
  } = pages[currentPage] || {};

  const {
    id: inboxItemId,
    page_id: inboxItemPageId,
  } = inbox_item || {};
  const inboxPage = inboxPages?.find(item => item.id === inboxItemPageId);
  const {
    invoice_number: invoiceNumber,
    total_amount: totalAmount,
    invoice_date,
    due_date,
    tax,
    subtotal,
    quickbooks_account: invoiceQuickbooksAccount,
    quickbooks_class: invoiceQuickbooksClass
  } = inboxPage || {};
  const invoiceDate = invoice_date ? invoice_date : '',
    invoiceDueDate = due_date ? due_date : '',
    billDate = bill_date ? bill_date : '',
    billDueDate = bill_due_date ? bill_due_date : '',
    ocrDate = ocrInvoiceDate ? ocrInvoiceDate : '',
    ocrDueDate = ocrPaymentDueDate ? ocrPaymentDueDate : '';
  useEffect(() => {
    dispatch(resetOCR());
    if(inboxItemId && inboxItemPageId) dispatch(getOCR({
      id: inboxItemId,
      page: inboxItemPageId,
    }));
  }, [dispatch, inboxItemId, inboxItemPageId]);
  useEffect(() => {
    dispatch(getUserBills({
      contact: billContact.id,
      status: 'draft,awaiting approval,awaiting payment',
    }));
  }, [dispatch, billContact])

  useEffect(() => {
    setInboxItem(Object.values(items)
                       .flat()
                       .find(item => item.id === parseInt(inboxItemId)) || {});
  }, [items, inboxItemId])
  useEffect(() => {
    if(inboxItemId) dispatch(getUserInboxItem({id: inboxItemId}));
  }, [dispatch, inboxItemId, currentPage]);
  const readOnly = !['Draft', 'Awaiting approval'].includes(bill.status);
  // smooth out loading state changes
  const [loading, setLoading] = useState(inboxLoading || billLoading || OCRLoading || inboxListLoading || billListLoading);
  const [processing, setProcessing] = useState(inboxProcessing || billProcessing);

  useEffect(() => {
    if(inboxLoading || billLoading || OCRLoading || inboxListLoading || billListLoading) {
      setLoading(true);
    } else {
      const timeout = setTimeout(() => setLoading(false), 200);
      return () => clearTimeout(timeout);
    }
  }, [inboxLoading, billLoading, OCRLoading, inboxListLoading, billListLoading]);

  useEffect(() => {
    if(inboxProcessing || billProcessing) {
      setProcessing(true);
    } else {
      const timeout = setTimeout(() => setProcessing(false), 500);
      return () => clearTimeout(timeout);
    }
  }, [inboxProcessing, billProcessing]);
  if(!pageId || !billPermission.canUpdate) return <></>;

  return <div className={`flex shrink-0 flex-col w-full md:w-96 border-l-2 border-neutral-100 shadow-inner px-2`}>
    {processing && <BigCenteredLoadingBlock text={'Saving page information'}/>}
    {!processing && loading && <BigCenteredLoadingBlock text={'Looking up page information'}/>}
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{
        invoiceNumber: inboxPage ? invoiceNumber : billInvoiceNumber ? billInvoiceNumber : ocrInvoiceNumber || '',
        invoiceTotal: totalAmount ? totalAmount / 100 : billTotalAmount ? billTotalAmount : ocrAmount ? ocrAmount / 100 : 0,
        invoiceDate: invoiceDate ? moment(invoiceDate)
        .format('YYYY-MM-DD') : ocrDate,
        invoiceDueDate: invoiceDueDate ? moment(invoiceDueDate)
        .format('YYYY-MM-DD') : ocrDueDate,
        invoiceTax: tax ? tax / 100 : ocrTax || 0,
        invoiceSubtotal: subtotal ? subtotal / 100 : ocrSubtotal || 0,
        billName: billName,
        billDate: billDate,
        billDueDate: billDueDate,
        billApprovers: billApprovers,
        quickbooksInvoiceId: bill?.quickbooks_invoice_id || undefined,
        quickbooksContactId: billContact?.quickbooks_id || undefined,
        quickbooksAccount: quickbooks_account || invoiceQuickbooksAccount,
        quickbooksClass: quickbooks_class || invoiceQuickbooksClass,
        allowLedgerSync: allowLedgerSync
      }}
      validationSchema={Yup.object({
        invoiceNumber: Yup.string()
                          .max(255, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 255}))
                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        invoiceTotal: Yup.number()
                         .min(0, FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                         .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        invoiceDate: Yup.date()
                        .when([], (
                          fields, schema) =>
                          inboxPage !== undefined ? schema.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED) : schema),
        invoiceDueDate: Yup.date()
                           .when([], (
                             fields, schema) =>
                             inboxPage !== undefined ? schema.required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                                                             .when('invoiceDate', (
                                                               fields,
                                                               schema
                                                             ) => fields[0] ? schema.min(moment(fields[0], 'MM/DD/YYYY')
                                                             .toDate(), FORM_ERROR_MESSAGES.MUST_BE_BIGGER_THAN_DATE) : schema) :
                               schema),
        invoiceTax: Yup.number()
                       .when([], (fields, schema) => inboxPage !== undefined ? schema.min(0,
                                                                                       FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                                                                                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED) : schema),
        invoiceSubtotal: Yup.number()
                            .when([], (fields, schema) => inboxPage !== undefined ? schema.min(0,
                                                                                            FORM_ERROR_MESSAGES.POSITIVE_NUMBER)
                                                                                          .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED) : schema),
        billName: Yup.string()
                     .max(21, build_error_message(FORM_ERROR_MESSAGES.MUST_BE_LESS, {number: 21}))
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        billDate: Yup.date()
                     .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED),
        billDueDate: Yup.date()
                        .required(FORM_ERROR_MESSAGES.FIELD_REQUIRED)
                        .when('billDate', (fields, schema) => fields[0] ? schema.min(moment(fields[0], 'MM/DD/YYYY')
                        .toDate(), FORM_ERROR_MESSAGES.MUST_BE_BIGGER_THAN_DATE) : schema),
        billApprovers: Yup.number()
                          .integer()
                          .positive(FORM_ERROR_MESSAGES.POSITIVE_NUMBER),
        allowLedgerSync: Yup.boolean(),
        quickbooksAccount: Yup.number()
                              .nullable(),
        quickbooksClass: Yup.number()
                            .nullable(),
      })}
      onSubmit={(values, helpers) => {
        const body = {
          invoice_number: values.invoiceNumber,
          total_amount: values.invoiceTotal,
          invoice_due_date: values.invoiceDueDate ? formatDateForAPItoYMD(values.invoiceDueDate) : undefined,
          invoice_date: values.invoiceDate ? formatDateForAPItoYMD(values.invoiceDate) : undefined,
          invoice_tax: values.invoiceTax,
          invoice_subtotal: values.invoiceSubtotal,
          bill_name: values.billName,
          bill_date: formatDateForAPItoYMD(values.billDate),
          bill_due_date: formatDateForAPItoYMD(values.billDueDate),
          bill_approvers: values.billApprovers,
          allow_ledger_sync: values.allowLedgerSync
        }
        if(hasQBIntegration) {
          body.quickbooks_account = values.quickbooksAccount || null
          body.quickbooks_class = values.quickbooksClass
        }
        dispatch(updatePageInformation({
          id: billId,
          page: pageId,
          body: body,
          successCallback: () => {
            dispatch(getBill({id: billId}));
            if(pages.length > currentPage + 1) {
              helpers.resetForm();
              setCurrentPage(currentPage + 1);
            }
          },
          errorCallback: (error) => {
            const data = error.data || {};
            if('message' in error) {
              payvyToast(error.message, {appearance: 'error'});
            }
            handleErrors(data, helpers);
          }
        }));
      }}
    >
      {props => <Form className={`flex flex-col ${loading || processing ? 'hidden' : ''}`}>
        <h1
          className={'flex pt-1 mx-1 mt-1 h-full w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>Invoice
          Details</h1>
        <div className={'flex flex-col md:flex-row gap-2 px-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Invoice Number <SuggestionBolt suggested={true}/></>}
              name={'invoiceNumber'}
              placeholder={'Invoice Number'}
              as={PayvyInput}
              readOnly={readOnly}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Total <SuggestionBolt suggested={ocrAmount > 0 && ocrAmount === props.values.invoiceTotal}/></>}
              name={'invoiceTotal'}
              alwaysLabel={true}
            >
              <NumericFormat
                readOnly={readOnly}
                thousandSeparator={true}
                prefix={'$'}
                placeholder="$0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                value={props.values.invoiceTotal}
                onValueChange={(value) => props.setFieldValue('invoiceTotal', value.floatValue)}
                className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        {inboxPage && <>
          <div className={'flex flex-col md:flex-row gap-2 px-2'}>
            <div className={'flex-inline w-full md:w-6/12'}>
              <PayvyLabeledInput
                label={<>Due Date <SuggestionBolt suggested={!!ocrDueDate}/></>}
                name={'invoiceDueDate'}
                alwaysLabel={true}
              >
                <PayvyDatePicker
                  value={props.values.invoiceDueDate}
                  onChange={(value) => props.setFieldValue('invoiceDueDate', value)}
                  clearIcon={null}
                  disabled={readOnly}
                />
              </PayvyLabeledInput>
            </div>
            <div className={'flex-inline w-full md:w-6/12'}>
              <PayvyLabeledInput
                label={<>Invoice Date <SuggestionBolt suggested={!!ocrDate}/></>}
                name={'invoiceDate'}
                alwaysLabel={true}
              >
                <PayvyDatePicker
                  value={props.values.invoiceDate}
                  onChange={(value) => props.setFieldValue('invoiceDate', value)}
                  clearIcon={null}
                  disabled={readOnly}
                />
              </PayvyLabeledInput>
            </div>
          </div>
          <div className={'flex flex-col md:flex-row gap-2 px-2'}>
            <div className={'flex-inline w-full md:w-6/12'}>
              <PayvyLabeledInput
                label={<>Tax <SuggestionBolt suggested={!!ocrTax}/></>}
                name={'invoiceTax'}
                alwaysLabel={true}
              >
                <NumericFormat
                  readOnly={readOnly}
                  thousandSeparator={true}
                  prefix={'$'}
                  placeholder="$0.00"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={props.values.invoiceTax}
                  onValueChange={(value) => props.setFieldValue('invoiceTax', value.floatValue)}
                  className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                />
              </PayvyLabeledInput>
            </div>
            <div className={'flex-inline w-full md:w-6/12'}>
              <PayvyLabeledInput
                label={<>Subtotal <SuggestionBolt suggested={!!ocrSubtotal}/></>}
                name={'invoiceSubtotal'}
                alwaysLabel={true}
              >
                <NumericFormat
                  readOnly={readOnly}
                  thousandSeparator={true}
                  prefix={'$'}
                  placeholder="$0.00"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={props.values.invoiceSubtotal}
                  onValueChange={(value) => props.setFieldValue('invoiceSubtotal', value.floatValue)}
                  className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
                />
              </PayvyLabeledInput>
            </div>
          </div>
        </>}
        <h1
          className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>Bill
          Details</h1>
        <div className={'flex flex-col md:flex-row gap-2 px-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Bill Name <SuggestionBolt suggested={ocrInvoiceNumber === props.values.billName}/></>}
              name={'billName'}
              placeholder={'Bill Name'}
              as={PayvyInput}
              readOnly={readOnly}
            />
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Total Amount <SuggestionBolt/></>}
              name={'billTotal'}
              alwaysLabel={true}
            >
              <NumericFormat
                readOnly={true}
                thousandSeparator={true}
                prefix={'$'}
                placeholder="$0.00"
                decimalScale={2}
                fixedDecimalScale={true}
                value={billTotal}
                className={'w-full my-0.5 px-1 py-2 rounded-md border border-neutral-500'}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2 px-2'}>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Bill Date <SuggestionBolt suggested={ocrDate === props.values.billDate}/></>}
              name={'billDate'}
              alwaysLabel={true}
            >
              <PayvyDatePicker
                value={props.values.billDate}
                onChange={(value) => props.setFieldValue('billDate', value)}
                clearIcon={null}
                disabled={readOnly}
              />
            </PayvyLabeledInput>
          </div>
          <div className={'flex-inline w-full md:w-6/12'}>
            <PayvyLabeledInput
              label={<>Due Date <SuggestionBolt suggested={ocrDueDate === props.values.billDueDate}/></>}
              name={'billDueDate'}
              alwaysLabel={true}
            >
              <PayvyDatePicker
                value={props.values.billDueDate}
                onChange={(value) => props.setFieldValue('billDueDate', value)}
                clearIcon={null}
                disabled={readOnly}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        <div className={'flex flex-col md:flex-row gap-2 px-2'}>
          <div className={'flex-inline w-full'}>
            <PayvyLabeledInput
              label={'Select Approval'}
              name={'billApprovers'}
              alwaysLabel={true}
            >
              <SelectApproval
                setField={value => props.setFieldValue('billApprovers', [value])}
                value={props.values.billApprovers}
                readOnly={readOnly}
              />
            </PayvyLabeledInput>
          </div>
        </div>
        {hasQBIntegration && <>
          <div
            className={'flex flex-row pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700 p-1'}>
            <h1
              className={'flex grow'}>
              General Ledger <img alt={'QuickBooks logo'} src={PAYVY_LOGOS.QBO} className={'w-5 h-5 mx-1'}/>
            </h1>
            <div className={'flex'}>
              <div className={'flex items-center'}>
                <input
                  type="checkbox"
                  id="allowLedgerSync"
                  checked={props.values.allowLedgerSync}
                  onChange={(e) => props.setFieldValue('allowLedgerSync', e.target.checked)}
                  className={'mr-2'}
                  disabled={readOnly}
                />
                <label htmlFor="allowLedgerSync" className={'text-sm text-neutral-700'}>
                  Allow Ledger Sync
                </label>
              </div>
            </div>
          </div>
          {billContact?.quickbooks_id == null ?
            <h2
              className={'flex flex-col w-full pt-1 mx-1 mt-1 mb-2 font-light text-red-400 justify-center text-center'}>
              <div><span className={'font-bold'}>{billContact?.name}</span> has no linked ledger vendor.</div>
              <div>This bill will not upload to General Ledger.</div>
            </h2>
            :
            <>
              {props.values.allowLedgerSync ? <>
                <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                  <div className={'flex-inline w-full md:w-4/12'}>
                    <PayvyLabeledInput
                      label={'Bill ID'}
                      name={'quickbooksInvoiceId'}
                      placeholder={'Bill ID'}
                      as={PayvyInput}
                      readOnly={true}
                    />
                  </div>
                  <div className={'flex-inline w-full md:w-8/12'}>
                    <PayvyLabeledInput
                      label={'Category'}
                      name={'quickbooksAccount'}
                      placeholder={'Category'}
                    >
                      <SelectAccountingCategory
                        value={props.values.quickbooksAccount}
                        setField={value => props.setFieldValue('quickbooksAccount', value)}
                        readOnly={readOnly}
                        clearable={true}
                      />
                    </PayvyLabeledInput>
                  </div>
                </div>
                <div className={'flex flex-col md:flex-row gap-2 px-2'}>
                  <div className={'flex-inline w-full md:w-4/12'}>
                    <PayvyLabeledInput
                      label={'GL Vendor'}
                      name={'quickbooksContactId'}
                      placeholder={'Vendor ID'}
                      as={PayvyInput}
                      readOnly={true}
                    />
                  </div>
                  <div className={'flex-inline w-full md:w-8/12'}>
                    <PayvyLabeledInput
                      label={'Class'}
                      name={'quickbooksClass'}
                      placeholder={'Class'}
                    >
                      <SelectAccountingClass
                        value={props.values.quickbooksClass}
                        setField={value => props.setFieldValue('quickbooksClass', value)}
                        readOnly={readOnly}
                      />
                    </PayvyLabeledInput>
                  </div>
                </div>
              </> : <h2
                className={'flex flex-col w-full pt-1 mx-1 mt-1 mb-2 font-light text-yellow-400 justify-center text-center'}>
                <div><span className={'font-bold'}>Sync Disabled:</span> Ledger sync is currently disabled.</div>
                <div>Changes will not be synchronized to the General Ledger.</div>
              </h2>}
            </>}
        </>}
        <GlobalFormError errors={props.errors['nonFieldErrors']}/>
        <PayvyIconButton
          buttonText={'Save Changes'}
          fullWidth={true}
          loading={processing || loading}
          disabled={(processing || loading) || (!props.dirty && !(ocrInvoiceNumber || ocrAmount))}
          type={'submit'}
        />
      </Form>}
    </Formik>
    <div className={loading || processing ? 'hidden' : ''}>
      <h1
        className={'flex pt-1 mx-1 mt-1 w-11/12 font-light text-sm text-neutral-700 border-b border-neutral-700'}>Vendor
        Details</h1>
      <OpenBillsOfContact/>
      <PaymentsForContact contact={billContact}/>
    </div>
  </div>;
};
export default Information;
