import {createSlice} from '@reduxjs/toolkit';
import {noop} from 'lodash';
import {PAYVY_API} from '../constants';
import {build_url, fetchWithTimeout} from '../utils/Utility';
import {refreshAccessToken} from "./api";
import {resetBillToInitialState} from "./newBill";
import {resetBoxToInitialState} from "./newBox";
import {resetContactToInitialState} from "./newContact";
import {resetDropboxToInitialState} from "./newDropbox";
import {resetInboxToInitialState} from "./newInbox";
import {resetIntegrationToInitialState} from "./newIntegration";
import {resetQuickbooksToInitialState} from "./newQuickbooks";

export const initialState = {
  usedEmail: '',
  usedPassword: '',
  loading: false,
  loginLoading: false,
  signingUpLoading: false,
  siteIsLive: undefined,
  processing: false,
  passwordResetProcessing: false,
  refreshLoading: false,
  hasErrors: false,
  method: null,
  errors: {
    too_many_login_attempts: localStorage.getItem('lockoutEnds'),
  },
  isAuthenticated: false,
  lastAuthenticationCheck: null,
  accessToken: '',
  refreshToken: '',
  invitationHash: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmailAndPassword: (state, {payload}) => {
      state.usedEmail = payload.email;
      state.usedPassword = payload.password;
    },
    resetEmailAndPassword: (state) => {
      state.usedEmail = '';
      state.usedPassword = '';
    },
    authenticateUser: (state) => {
      state.lastAuthenticationCheck = Date.now();
    },
    authenticateUserSuccess: (state, {payload}) => {
      const {
        access,
        refresh,
      } = payload;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      state.accessToken = access;
      state.refreshToken = refresh;
      state.loginLoading = false;
      state.isAuthenticated = true;
      state.hasErrors = false;
      state.errors = {};
    },
    authenticateTFAUserSuccess: (state, {payload}) => {
      const {
        access,
        refresh,
        method,
        has_errors: hasErrors = false,
      } = payload;
      localStorage.setItem('accessToken', access);
      localStorage.setItem('refreshToken', refresh);
      state.accessToken = access;
      state.refreshToken = refresh;
      state.method = method;
      state.loginLoading = false;
      state.isAuthenticated = !!(access && refresh);
      state.hasErrors = hasErrors;
      state.errors = {};
    },
    passwordResetSuccess: (state) => {
      state.hasErrors = false;
      state.errors = {};
    },
    passwordResetFailure: (state) => {
      state.hasErrors = true;
      state.errors = {};
    },
    authenticateUserFailure: (state, {payload}) => {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      state.accessToken = '';
      state.refreshToken = '';
      state.method = null;
      state.loginLoading = false;
      state.isAuthenticated = false;
      state.hasErrors = true;
      state.errors = payload || {};
    },
    tokenRefresh: (state) => {
      state.refreshLoading = true;
      state.tokenLoading = true;
      state.lastAuthenticationCheck = Date.now();
    },
    tokenAborted: (state) => {
      state.refreshLoading = false;
      state.isAuthenticated = false;
    },
    tokenRefreshSuccess: (state, {payload}) => {
      const {access} = payload;
      localStorage.setItem('accessToken', access);
      state.accessToken = access;
      state.refreshLoading = false;
      state.isAuthenticated = true;
    },
    tokenRefreshFailure: (state) => {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      state.accessToken = '';
      state.refreshToken = '';
      state.refreshLoading = false;
      state.isAuthenticated = false;
    },
    saveInvitationHash: (state, {payload}) => {
      state.invitationHash = payload;
    },
    companyInviteProcessed: (state) => {
      state.invitationHash = '';
    },
    userLogout: (state) => {
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      state.accessToken = '';
      state.refreshToken = '';
      state.isAuthenticated = false;
      state.lastAuthenticationCheck = null;
    },
    processingStart: (state) => {
      state.processing = true;
    },
    processingFinish: (state) => {
      state.processing = false;
    },
    processingPasswordResetStart: (state) => {
      state.passwordResetProcessing = true;
    },
    processingPasswordResetFinish: (state) => {
      state.passwordResetProcessing = false;
    },
    backendIsOffline: (state) => {
      state.siteIsLive = false;
    },
    backendIsLive: (state) => {
      state.siteIsLive = true;
    },
    processSignUpStart: (state) => {
      state.signingUpLoading = true;
    },
    processSignUpFinish: (state) => {
      state.signingUpLoading = false;
    },
  },
});

export const {
  setEmailAndPassword,
  resetEmailAndPassword,
  authenticateUser,
  authenticateUserSuccess,
  authenticateTFAUserSuccess,
  authenticateUserFailure,
  passwordResetSuccess,
  passwordResetFailure,
  tokenRefresh,
  tokenRefreshSuccess,
  tokenAborted,
  saveInvitationHash,
  tokenRefreshFailure,
  userLogout,
  companyInviteProcessed,
  processingStart,
  processingFinish,
  backendIsOffline,
  backendIsLive,
  processSignUpStart,
  processSignUpFinish,
  processingPasswordResetStart,
  processingPasswordResetFinish,
} = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;

export function checkIfSiteIsLive() {
  return async(dispatch) => {
    try {
      const response = await fetchWithTimeout(PAYVY_API.V1.LIVE, {method: 'GET'}, 5000);
      if(response.status === 200) dispatch(backendIsLive()); else dispatch(backendIsOffline());
    } catch(error) {
      dispatch(backendIsOffline());
    }
  };
}

export function refreshUserToken({
  refreshToken,
  success = noop,
  failure = noop
} = {}) {
  return async(dispatch) => {
    if(!refreshToken) refreshToken = localStorage.getItem('refreshToken');
    if(!refreshToken) {
      failure();
      return;
    }
    dispatch(tokenRefresh());
    try {
      const response = await fetch(PAYVY_API.TOKEN.REFRESH, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({refresh: refreshToken}),
      });
      const data = await response.json();
      if(response.status === 200) {
        localStorage.setItem('refreshToken', refreshToken);
        dispatch(tokenRefreshSuccess(data));
        success();
      } else {
        dispatch(tokenRefreshFailure());
        failure();
      }
    } catch(error) {
      dispatch(tokenRefreshFailure());
      failure()
    }
  };
}

export function logoutUser() {
  return async(dispatch) => {
    dispatch(resetInboxToInitialState());
    dispatch(resetBillToInitialState());
    dispatch(resetContactToInitialState());
    dispatch(resetBoxToInitialState());
    dispatch(resetDropboxToInitialState());
    dispatch(resetIntegrationToInitialState());
    dispatch(resetQuickbooksToInitialState());
    dispatch(userLogout());
  };
}

export function userHasTFA({
  email,
  password,
  code,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(authenticateUser());
    try {
      const response = await fetch(PAYVY_API.V1.TFA.CHECK_HAS_TFA, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
          code: code,
        }),
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(authenticateTFAUserSuccess(data));
        success(data);
      } else {
        dispatch(authenticateUserFailure(data));
        failure(data);
      }
    } catch(error) {
      dispatch(authenticateUserFailure({}));
      failure();
    }
  };
}

export function createUserAccount({
  username,
  email,
  first_name,
  last_name,
  password,
  phone,
  country,
  success = noop,
  failure = noop,
}) {
  return async(dispatch) => {
    dispatch(processSignUpStart());
    try {
      const response = await fetch(PAYVY_API.V1.REGISTRATION.ACCOUNT, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          email,
          first_name,
          last_name,
          password,
          phone,
          country,
        }),
      });
      const data = await response.json();
      if(response.status === 201) {
        localStorage.setItem('refreshToken', data.refresh);
        localStorage.setItem('accessToken', data.access);
        success(data);
      } else {
        failure(data);
      }
    } catch(error) {
      failure(error);
    }
    dispatch(processSignUpFinish());
  };
}

export function invitationHash({hash}) {
  return async(dispatch) => {
    dispatch(saveInvitationHash(hash));
  };
}

export function invitedMember({
  hash,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    try {
      const response = await fetch(build_url(PAYVY_API.V1.COMPANY.ACCEPT_INVITE, {hash}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = await response.json();
      if(response.status === 202) {
        success(data);
      } else {
        if(response.status === 401 && data.code === 'token_not_valid') {
          const tokenRefreshed = await refreshAccessToken();
          if(tokenRefreshed) {
            return await dispatch(invitedMember({
              hash,
              success,
              failure
            }));
          }
        }
        failure(data);
      }
    } catch(error) {
    }
    dispatch(companyInviteProcessed());
  };
}

export function activateUserAccount({
  hash,
  success = noop,
  failure = noop
}) {
  return async(dispatch) => {
    dispatch(processingStart());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.REGISTRATION.ACTIVATE, {hash}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(authenticateUserSuccess(data));
        success(data);
      } else {
        dispatch(authenticateUserFailure());
        failure(data);
      }
    } catch(error) {
      dispatch(authenticateUserFailure());
      failure();
    }
    dispatch(processingFinish());
  };
}

export function sendPasswordResetAccount({
  email,
  success,
  failure,
}) {
  success = success || noop;
  failure = failure || noop;
  return async(dispatch) => {
    dispatch(processingPasswordResetStart());
    try {
      const response = await fetch(PAYVY_API.V1.REGISTRATION.FORGOTTEN_PASSWORD, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email}),
      });
      const data = await response.json();
      if(response.status === 200) {
        dispatch(passwordResetSuccess(data));
        success(data);
      } else {
        dispatch(passwordResetFailure());
        failure(data);
      }
    } catch(error) {
      dispatch(passwordResetFailure());
      failure();
    }
    dispatch(processingPasswordResetFinish());
  };
}

export function resetPasswordFailed() {
  return async(dispatch) => {
    dispatch(passwordResetFailure());
  };
}

export function resetPasswordWithHash({
  hash,
  password,
  success = noop,
  failure = noop
}) {
  success = success || noop;
  failure = failure || noop;
  return async(dispatch) => {
    dispatch(processingStart());
    dispatch(passwordResetSuccess());
    try {
      const response = await fetch(build_url(PAYVY_API.V1.REGISTRATION.VERIFY_FORGOTTEN_PASSWORD, {hash}), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({password}),
      });
      if(response.status === 202) {
        dispatch(passwordResetSuccess());
        success();
      } else {
        const data = await response.json();
        dispatch(passwordResetFailure());
        failure(data);
      }
    } catch(error) {
      dispatch(passwordResetFailure());
      failure({password: ['Something went wrong.']});
    }
    dispatch(processingFinish());
  };
}

export function setEmailAndPasswordForLogin({
  email,
  password,
}) {
  return (dispatch) => {
    dispatch(setEmailAndPassword({
      email,
      password,
    }));
  };
}

export function resetEmailAndPasswordForLogin() {
  return (dispatch) => {
    dispatch(resetEmailAndPassword());
  };
}
