import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PayvyPageHeader, PayvySubmenu, PayvyTable} from "../../comps/elements";
import {CellCurrency} from '../../comps/elements/PayvyTable';
import {PAYVY_URL} from '../../constants';
import {companiesSelector, hasPermission} from '../../slices/companies';
import {
  getContacts,
  setContactCurrentPage,
  setContactOrderBy,
  setContactPageSize,
  setContactSearchQuery,
  setContactType
} from '../../slices/newContact';
import {getLedgerInformation} from "../../slices/newIntegration";
import {userSelector} from '../../slices/user';
import {CellActions} from "./components/CellActions";
import {CellDetailNameAndLedgerInformation} from "./components/CellDetailNameAndLedgerInformation";
import {CellPhoneNumber} from "./components/CellPhoneNumber";
import SearchBar from "./components/SearchBar";

const ContactListPage = () => {
  const dispatch = useDispatch();

  const {user: {id: userId} = {}} = useSelector(userSelector);
  const {company} = useSelector(companiesSelector);
  const {id: companyId} = company || {};

  const contactCanUpdatePermission = hasPermission(company, userId, 'contact_can_update');
  const contactCanDeletePermission = hasPermission(company, userId, 'contact_can_delete');
  const {
    displayItems,
    currentPage,
    pageSize,
    orderBy,
    searchQuery,
    contactType,
    loading: {list: loading},
    numberOfItems
  } = useSelector((state) => state.contact);

  const [pageCount, setPageCount] = React.useState(0);
  useEffect(() => {
    dispatch(getLedgerInformation({}))
  }, [dispatch])

  // region Effects
  useEffect(() => {
    dispatch(getContacts({
      page: currentPage,
      page_size: pageSize,
      q: searchQuery,
      order_by: orderBy,
      show_deleted: false,
      contact_type: contactType || 'vendor'
    }));
  }, [
    dispatch,
    companyId,
    currentPage,
    pageSize,
    searchQuery,
    orderBy,
    contactType
  ]);
  // endregion


  const columns = React.useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      detailUrl: PAYVY_URL.CONTACTS.DETAILS,
      Cell: CellDetailNameAndLedgerInformation,
      sortDescFirst: true,
    }, {
      Header: 'Phone',
      accessor: 'phone',
      Cell: CellPhoneNumber,
      disableSortBy: true,
    }, {
      Header: 'Email',
      accessor: 'email',
      sortDescFirst: true,
    }, {
      Header: 'Open Balance',
      accessor: 'open_balance',
      Cell: CellCurrency,
      sortDescFirst: true,
    }, {
      Header: 'Actions',
      Cell: CellActions,
      canUpdate: contactCanUpdatePermission,
      canDelete: contactCanDeletePermission,
    },
  ], [contactCanUpdatePermission, contactCanDeletePermission]);

  // region Callbacks
  const fetchData = React.useCallback(({
    pageSize,
    pageIndex
  }) => {
    dispatch(setContactCurrentPage(pageIndex + 1));
    dispatch(setContactPageSize(pageSize));
    setPageCount(Math.ceil(numberOfItems[contactType] / pageSize));
  }, [numberOfItems, contactType, dispatch]);
  const handleSort = React.useCallback(sortBy => {
    dispatch(setContactOrderBy(sortBy.length > 0 ? sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id : ''));
  }, [dispatch]);
  // endregion

  return <div className={'flex flex-col'}>
    <PayvyPageHeader>Contacts</PayvyPageHeader>
    <PayvySubmenu
      activeItem={contactType}
      setActiveItem={(value) => {
        if(!loading) dispatch(setContactType(value))
      }}
      options={[
        {
          name: 'Vendors',
          value: 'vendor',
        }, {
          name: 'Customers',
          value: 'customer',
        },
      ]}
    />
    <div className={'bg-neutral-50 p-4 w-full h-full'}>
      <SearchBar
        setQueryTerm={(value) => dispatch(setContactSearchQuery(value))}
        loading={loading}
      />
      <PayvyTable
        columns={columns}
        data={displayItems}
        fetchData={fetchData}
        loading={loading}
        onSort={handleSort}
        count={numberOfItems[contactType]}
        pageCount={pageCount}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        currentPage={currentPage - 1}
        sizeOptions={[20, 50, 100]}
      />
    </div>
  </div>;
};

export default ContactListPage;
